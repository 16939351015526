import classNames from 'classnames';
import React from 'react';



export enum DragHandlerVisibility {
	Hidden = 'hidden',
	Hoverable = 'hoverable',
	Visible = 'visible',
}

type Props = {
	/** Possibility to set custom visibility of whole elements */
	visibility?: DragHandlerVisibility,
} & React.HTMLAttributes<HTMLSpanElement>;



const DragHandler: React.FC<Props> = (props) => {
	const {
		visibility = DragHandlerVisibility.Visible,
		...restProps
	} = props;

	const componentClasses = classNames({
		'drag-handler': true,
		'drag-handler--is-hidden': visibility === DragHandlerVisibility.Hidden,
		'drag-handler--is-hoverable': visibility === DragHandlerVisibility.Hoverable,
	});

	return (
		<span
			className={componentClasses}
			{...restProps}
		>
			↕
		</span>
	);
};



export default DragHandler;
