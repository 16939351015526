/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RefreshAfterExtendingTrialQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type RefreshAfterExtendingTrialQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly canSignup: boolean, readonly id: CK.AccountId, readonly isTrialBarDisplayed: boolean, readonly isTrialExtensionAllowed: boolean, readonly phase: GraphQL.AccountPhase, readonly state: GraphQL.AccountState, readonly trialExpirationDate: CK.Timestamp | null, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID }> } | null };

export type ExtendTrialMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  feedback: GraphQL.Scalars['String']['input'];
}>;


export type ExtendTrialMutation = { readonly __typename?: 'Mutation', readonly ExtendTrial: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const RefreshAfterExtendingTrialDocument = gql`
    query RefreshAfterExtendingTrial($accountId: AccountId!) {
  account(id: $accountId) {
    canSignup
    features {
      feature
      id
    }
    id
    isTrialBarDisplayed
    isTrialExtensionAllowed
    phase
    state
    trialExpirationDate
  }
}
    `;

/**
 * __useRefreshAfterExtendingTrialQuery__
 *
 * To run a query within a React component, call `useRefreshAfterExtendingTrialQuery` and pass it any options that fit your needs.
 * When your component renders, `useRefreshAfterExtendingTrialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRefreshAfterExtendingTrialQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRefreshAfterExtendingTrialQuery(baseOptions: Apollo.QueryHookOptions<RefreshAfterExtendingTrialQuery, RefreshAfterExtendingTrialQueryVariables> & ({ variables: RefreshAfterExtendingTrialQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefreshAfterExtendingTrialQuery, RefreshAfterExtendingTrialQueryVariables>(RefreshAfterExtendingTrialDocument, options);
      }
export function useRefreshAfterExtendingTrialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefreshAfterExtendingTrialQuery, RefreshAfterExtendingTrialQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefreshAfterExtendingTrialQuery, RefreshAfterExtendingTrialQueryVariables>(RefreshAfterExtendingTrialDocument, options);
        }
export function useRefreshAfterExtendingTrialSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RefreshAfterExtendingTrialQuery, RefreshAfterExtendingTrialQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RefreshAfterExtendingTrialQuery, RefreshAfterExtendingTrialQueryVariables>(RefreshAfterExtendingTrialDocument, options);
        }
export type RefreshAfterExtendingTrialQueryHookResult = ReturnType<typeof useRefreshAfterExtendingTrialQuery>;
export type RefreshAfterExtendingTrialLazyQueryHookResult = ReturnType<typeof useRefreshAfterExtendingTrialLazyQuery>;
export type RefreshAfterExtendingTrialSuspenseQueryHookResult = ReturnType<typeof useRefreshAfterExtendingTrialSuspenseQuery>;
export type RefreshAfterExtendingTrialQueryResult = Apollo.QueryResult<RefreshAfterExtendingTrialQuery, RefreshAfterExtendingTrialQueryVariables>;
export const ExtendTrialDocument = gql`
    mutation ExtendTrial($accountId: AccountId!, $feedback: String!) {
  ExtendTrial(accountId: $accountId, feedback: $feedback) {
    query {
      ping
    }
  }
}
    `;
export type ExtendTrialMutationFn = Apollo.MutationFunction<ExtendTrialMutation, ExtendTrialMutationVariables>;

/**
 * __useExtendTrialMutation__
 *
 * To run a mutation, you first call `useExtendTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtendTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extendTrialMutation, { data, loading, error }] = useExtendTrialMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useExtendTrialMutation(baseOptions?: Apollo.MutationHookOptions<ExtendTrialMutation, ExtendTrialMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtendTrialMutation, ExtendTrialMutationVariables>(ExtendTrialDocument, options);
      }
export type ExtendTrialMutationHookResult = ReturnType<typeof useExtendTrialMutation>;
export type ExtendTrialMutationResult = Apollo.MutationResult<ExtendTrialMutation>;
export type ExtendTrialMutationOptions = Apollo.BaseMutationOptions<ExtendTrialMutation, ExtendTrialMutationVariables>;