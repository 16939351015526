import {
	storeLoadedBillingDetails,
	storeLoadedDiscounts,
	storeLoadedTeams,
} from '~/actions';

import {
	runAction,
} from '~/model/actions';

import {
	loadDiscountDetailsViaRecurly,
	loadTaxDetails,
} from '~/model/pricing/recurly';

import {
	loadTeamBillingDetailsViaApi,
	loadViaApi,
} from '~/model/teams';

import {
	EmptyTeamBilling,
	teamsBillingSelector,
} from '~/state/teams/selectors';



export function loadTeamBillingDetails(teamId) {
	return (dispatch, getState) => {
		if (teamId === null) {
			return Promise.resolve();
		}

		const teamBilling = teamsBillingSelector(getState()).get(teamId);

		const promises = [];

		if (
			!teamBilling
			|| teamBilling === EmptyTeamBilling
			|| !teamBilling.get('details')
		) {
			promises.push(
				loadTeamBillingDetailsViaApi(teamId).then(
					(billingDetails) => {
						return loadTaxDetails(
							billingDetails.country,
							billingDetails.vat_number,
						).then((tax) => {
							dispatch(
								storeLoadedBillingDetails(
									teamId,
									billingDetails,
									undefined,
									tax,
								),
							);
						});
					},
				),
			);
		} else if (!teamBilling.get('tax')) {
			promises.push(
				loadTaxDetails(
					teamBilling.get('details').get('country'),
					teamBilling.get('details').get('vat_number'),
				).then((tax) => {
					dispatch(
						storeLoadedBillingDetails(
							teamId,
							undefined,
							undefined,
							tax,
						),
					);
				}),
			);
		}

		if (
			!teamBilling
			|| teamBilling === EmptyTeamBilling
			|| !teamBilling.get('discounts')
		) {
			promises.push(
				loadDiscountDetailsViaRecurly(teamId).then((discounts) => {
					dispatch(
						storeLoadedDiscounts(
							teamId,
							discounts,
						),
					);
				}),
			);
		}

		return Promise.all(promises);
	};
}



export function synchronizeTeams() {
	return (dispatch) => {
		return loadViaApi()
			.then(
				(data) => {
					dispatch(storeLoadedTeams(data));
				},
			);
	};
}



export function updateBillingDetails(teamId, details) {
	return (dispatch) => {
		const billingDetailsData = Object.assign({}, details);

		return runAction({
			action: 'StoreBillingDetails',
			input: {
				accountId: teamId,
				billingDetails: billingDetailsData,
			},
		}).then(({ billingDetails }) => {
			dispatch(
				storeLoadedBillingDetails(
					teamId,
					billingDetails,
				),
			);

			return billingDetails;
		});
	};
}
