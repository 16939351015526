import classNames from 'classnames';
import React from 'react';

import DropdownIcon from '~/components/patterns/icons/DropdownIcon';



export enum ColumnsCategoryControllerSize {
	Default = 'default',
	Small = 'small',
}

type Props = {
	/** Reserved position in controller of optional checkbox */
	checkboxField?: React.ReactNode,
	children?: React.ReactNode,
	defaultOpen?: boolean,
	label: React.ReactNode,
	name: string,
	size?: ColumnsCategoryControllerSize,
	suffix?: React.ReactNode,
};



const ColumnsCategoryController: React.FC<Props> = (props) => {
	const {
		checkboxField,
		children,
		defaultOpen,
		label,
		name,
		size = ColumnsCategoryControllerSize.Default,
		suffix,
	} = props;

	const dropdownRef = React.useRef<HTMLDivElement | null>(null);
	const [isOpen, setOpen] = React.useState(defaultOpen || false);
	const [dropdownHeight, setDropdownHeight] = React.useState<string | number>('auto');

	React.useEffect(
		() => {
			let height = 0;

			if (dropdownRef.current) {
				for (let step = 0; step < dropdownRef.current.children.length; step++) {
					const element = dropdownRef.current.children[step] as HTMLElement;
					height += element.offsetHeight;
				}
			}

			setDropdownHeight(height || 'auto');
		},
		[
			dropdownRef,
		],
	);

	function handleLabelClick() {
		setOpen(!isOpen);
	}

	const componentClasses = classNames({
		'page-columns-category-controller': true,
		['page-columns-category-controller--' + size + '-size']: true,
		['page-columns-category-controller--style-' + name]: true,
	});

	return (
		<div className={componentClasses}>
			<div className="page-columns-category-controller__draggable-section">
				<div className="page-columns-category-controller__content">
					{checkboxField && (
						<div className="page-columns-category-controller__checkbox-field">
							{checkboxField}
						</div>
					)}

					<div
						className="page-columns-category-controller__label"
						onClick={handleLabelClick}
					>
						<div className="page-columns-category-controller__label-container">
							<span>
								<span className="page-columns-category-controller__label-wrapper">
									{label}
								</span>
							</span>
						</div>
					</div>

					{suffix && (
						<div className="page-columns-category-controller__suffix">
							{suffix}
						</div>
					)}

					<div
						className="page-columns-category-controller__dropdown-toggler"
						onClick={handleLabelClick}
					>
						<DropdownIcon
							isActive={isOpen}
						/>
					</div>
				</div>

				<div
					className={classNames({
						'page-columns-category-controller__dropdown': true,
						'page-columns-category-controller__dropdown--open': isOpen,
					})}
					ref={dropdownRef}
					style={{
						maxHeight: isOpen ? dropdownHeight : 0,
					}}
				>
					{children}
				</div>
			</div>
		</div>
	);
};



export default ColumnsCategoryController;
