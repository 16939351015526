import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AccountSignup from '~/components/logic/account/AccountSignup';
import HeaderTitle from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitle';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';
import ScreenLayout from '~/components/patterns/screens/basicScreen/layouts/ScreenLayout';



const messages = defineMessages({
	title: {
		id: 'ui.teamDetail.billing.menuLabelSignup',
	},
});



const AccountPricingSection: React.FC = () => {
	return (
		<ScreenLayout
			header={(
				<ScreenHeader>
					<HeaderTitleLayout>
						<HeaderTitle baselineAlignment={false}>
							<FormattedMessage {...messages.title} />
						</HeaderTitle>
					</HeaderTitleLayout>
				</ScreenHeader>
			)}
			isHeaderFixed={false}
		>
			<AccountSignup />
		</ScreenLayout>
	);
};



export default AccountPricingSection;
