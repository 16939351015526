/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KingdomSettingsFreeEmailsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type KingdomSettingsFreeEmailsQuery = { readonly __typename?: 'Query', readonly kingdomSettings: { readonly __typename?: 'KingdomSettings', readonly freeEmails: ReadonlyArray<string> } | null };


export const KingdomSettingsFreeEmailsDocument = gql`
    query KingdomSettingsFreeEmails {
  kingdomSettings {
    freeEmails
  }
}
    `;

/**
 * __useKingdomSettingsFreeEmailsQuery__
 *
 * To run a query within a React component, call `useKingdomSettingsFreeEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKingdomSettingsFreeEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKingdomSettingsFreeEmailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKingdomSettingsFreeEmailsQuery(baseOptions?: Apollo.QueryHookOptions<KingdomSettingsFreeEmailsQuery, KingdomSettingsFreeEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KingdomSettingsFreeEmailsQuery, KingdomSettingsFreeEmailsQueryVariables>(KingdomSettingsFreeEmailsDocument, options);
      }
export function useKingdomSettingsFreeEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KingdomSettingsFreeEmailsQuery, KingdomSettingsFreeEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KingdomSettingsFreeEmailsQuery, KingdomSettingsFreeEmailsQueryVariables>(KingdomSettingsFreeEmailsDocument, options);
        }
export function useKingdomSettingsFreeEmailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KingdomSettingsFreeEmailsQuery, KingdomSettingsFreeEmailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<KingdomSettingsFreeEmailsQuery, KingdomSettingsFreeEmailsQueryVariables>(KingdomSettingsFreeEmailsDocument, options);
        }
export type KingdomSettingsFreeEmailsQueryHookResult = ReturnType<typeof useKingdomSettingsFreeEmailsQuery>;
export type KingdomSettingsFreeEmailsLazyQueryHookResult = ReturnType<typeof useKingdomSettingsFreeEmailsLazyQuery>;
export type KingdomSettingsFreeEmailsSuspenseQueryHookResult = ReturnType<typeof useKingdomSettingsFreeEmailsSuspenseQuery>;
export type KingdomSettingsFreeEmailsQueryResult = Apollo.QueryResult<KingdomSettingsFreeEmailsQuery, KingdomSettingsFreeEmailsQueryVariables>;