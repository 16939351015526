/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteAdminNonstandardBehaviorFormQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteAdminNonstandardBehaviorFormQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly analyticsServicesDetectionSource: string, readonly areOtherSearchEnginesNotRelevant: boolean, readonly detectedContentDeliveryNetwork: GraphQL.DetectedContentDeliveryNetwork, readonly discoverAmpPages: boolean, readonly isDiscoveringNewUrlsEnabled: boolean } | null } | null };

export type UpdateWebsiteAdminNonstandardBehaviorMutationVariables = GraphQL.Exact<{
  analyticsServicesDetectionSource: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  areOtherSearchEnginesNotRelevant: GraphQL.Scalars['Boolean']['input'];
  detectedContentDeliveryNetwork: GraphQL.DetectedContentDeliveryNetwork;
  discoverAmpPages: GraphQL.Scalars['Boolean']['input'];
  isDiscoveringNewUrlsEnabled: GraphQL.Scalars['Boolean']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteAdminNonstandardBehaviorMutation = { readonly __typename?: 'Mutation', readonly AdministerWebsiteSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly analyticsServicesDetectionSource: string, readonly areOtherSearchEnginesNotRelevant: boolean, readonly detectedContentDeliveryNetwork: GraphQL.DetectedContentDeliveryNetwork, readonly discoverAmpPages: boolean, readonly isDiscoveringNewUrlsEnabled: boolean } | null } | null } } };


export const WebsiteAdminNonstandardBehaviorFormDocument = gql`
    query WebsiteAdminNonstandardBehaviorForm($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    adminSettings {
      analyticsServicesDetectionSource
      areOtherSearchEnginesNotRelevant
      detectedContentDeliveryNetwork
      discoverAmpPages
      isDiscoveringNewUrlsEnabled
    }
  }
}
    `;

/**
 * __useWebsiteAdminNonstandardBehaviorFormQuery__
 *
 * To run a query within a React component, call `useWebsiteAdminNonstandardBehaviorFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAdminNonstandardBehaviorFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAdminNonstandardBehaviorFormQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteAdminNonstandardBehaviorFormQuery(baseOptions: Apollo.QueryHookOptions<WebsiteAdminNonstandardBehaviorFormQuery, WebsiteAdminNonstandardBehaviorFormQueryVariables> & ({ variables: WebsiteAdminNonstandardBehaviorFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteAdminNonstandardBehaviorFormQuery, WebsiteAdminNonstandardBehaviorFormQueryVariables>(WebsiteAdminNonstandardBehaviorFormDocument, options);
      }
export function useWebsiteAdminNonstandardBehaviorFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteAdminNonstandardBehaviorFormQuery, WebsiteAdminNonstandardBehaviorFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteAdminNonstandardBehaviorFormQuery, WebsiteAdminNonstandardBehaviorFormQueryVariables>(WebsiteAdminNonstandardBehaviorFormDocument, options);
        }
export function useWebsiteAdminNonstandardBehaviorFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteAdminNonstandardBehaviorFormQuery, WebsiteAdminNonstandardBehaviorFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteAdminNonstandardBehaviorFormQuery, WebsiteAdminNonstandardBehaviorFormQueryVariables>(WebsiteAdminNonstandardBehaviorFormDocument, options);
        }
export type WebsiteAdminNonstandardBehaviorFormQueryHookResult = ReturnType<typeof useWebsiteAdminNonstandardBehaviorFormQuery>;
export type WebsiteAdminNonstandardBehaviorFormLazyQueryHookResult = ReturnType<typeof useWebsiteAdminNonstandardBehaviorFormLazyQuery>;
export type WebsiteAdminNonstandardBehaviorFormSuspenseQueryHookResult = ReturnType<typeof useWebsiteAdminNonstandardBehaviorFormSuspenseQuery>;
export type WebsiteAdminNonstandardBehaviorFormQueryResult = Apollo.QueryResult<WebsiteAdminNonstandardBehaviorFormQuery, WebsiteAdminNonstandardBehaviorFormQueryVariables>;
export const UpdateWebsiteAdminNonstandardBehaviorDocument = gql`
    mutation UpdateWebsiteAdminNonstandardBehavior($analyticsServicesDetectionSource: String, $areOtherSearchEnginesNotRelevant: Boolean!, $detectedContentDeliveryNetwork: DetectedContentDeliveryNetwork!, $discoverAmpPages: Boolean!, $isDiscoveringNewUrlsEnabled: Boolean!, $websiteId: WebsiteId!) {
  AdministerWebsiteSettings(
    analyticsServicesDetectionSource: $analyticsServicesDetectionSource
    areOtherSearchEnginesNotRelevant: $areOtherSearchEnginesNotRelevant
    detectedContentDeliveryNetwork: $detectedContentDeliveryNetwork
    discoverAmpPages: $discoverAmpPages
    isDiscoveringNewUrlsEnabled: $isDiscoveringNewUrlsEnabled
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        adminSettings {
          analyticsServicesDetectionSource
          areOtherSearchEnginesNotRelevant
          detectedContentDeliveryNetwork
          discoverAmpPages
          isDiscoveringNewUrlsEnabled
        }
      }
    }
  }
}
    `;
export type UpdateWebsiteAdminNonstandardBehaviorMutationFn = Apollo.MutationFunction<UpdateWebsiteAdminNonstandardBehaviorMutation, UpdateWebsiteAdminNonstandardBehaviorMutationVariables>;

/**
 * __useUpdateWebsiteAdminNonstandardBehaviorMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteAdminNonstandardBehaviorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteAdminNonstandardBehaviorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteAdminNonstandardBehaviorMutation, { data, loading, error }] = useUpdateWebsiteAdminNonstandardBehaviorMutation({
 *   variables: {
 *      analyticsServicesDetectionSource: // value for 'analyticsServicesDetectionSource'
 *      areOtherSearchEnginesNotRelevant: // value for 'areOtherSearchEnginesNotRelevant'
 *      detectedContentDeliveryNetwork: // value for 'detectedContentDeliveryNetwork'
 *      discoverAmpPages: // value for 'discoverAmpPages'
 *      isDiscoveringNewUrlsEnabled: // value for 'isDiscoveringNewUrlsEnabled'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteAdminNonstandardBehaviorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteAdminNonstandardBehaviorMutation, UpdateWebsiteAdminNonstandardBehaviorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteAdminNonstandardBehaviorMutation, UpdateWebsiteAdminNonstandardBehaviorMutationVariables>(UpdateWebsiteAdminNonstandardBehaviorDocument, options);
      }
export type UpdateWebsiteAdminNonstandardBehaviorMutationHookResult = ReturnType<typeof useUpdateWebsiteAdminNonstandardBehaviorMutation>;
export type UpdateWebsiteAdminNonstandardBehaviorMutationResult = Apollo.MutationResult<UpdateWebsiteAdminNonstandardBehaviorMutation>;
export type UpdateWebsiteAdminNonstandardBehaviorMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteAdminNonstandardBehaviorMutation, UpdateWebsiteAdminNonstandardBehaviorMutationVariables>;