import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AffectedButton, {
	AffectedButtonScope,
	AffectedButtonSize,
} from '~/components/logic/issues/detail/components/AffectedButton';
import AnimateHeartbeat from '~/components/logic/score/AnimateHeartbeat';
import IgnoringButton from '~/components/app/IgnoringButton';
import IssueAffectedPagesBar, {
	Scope as AFFECTED_PAGES_SCOPE,
} from '~/components/app/IssueAffectedPagesBar';
import IssueIllustration from '~/components/atoms/issues/components/issueDetail/IssueIllustration';
import IssueInstruction from '~/components/logic/issues/IssueInstruction';
import IssueLayout, {
	STATE_BROKEN,
	STATE_CLOSED,
	STATE_IGNORED,
	STATE_NOT_REQUIRED,
	STATE_UNKNOWN,
} from '~/components/atoms/issues/components/issueDetail/IssueLayout';
import IssueScore, {
	IssueScoreSize,
	IssueScoreState,
} from '~/components/patterns/issues/IssueScore';
import IssueTitle from '~/components/logic/issues/IssueTitle';
import PlatformScreenIssueDetail from '~/components/logic/platform/issues/issueDetails/PlatformScreenIssueDetail';
import SegmentsBarChart, {
	type SegmentsBarChartExtractCallbackInput,
} from '~/components/logic/charts/SegmentsBarChart';

import useCountAffectedLinks from '~/hooks/useCountAffectedLinks';

import {
	openSingleIssueAffectedLinks,
	openSingleIssueAffectedPages,
} from '~/actions/issuesScreen';

import {
	type IssueCategoryName,
	type IssueName,
	getEntryScopeInWebsiteContext,
	getPointsToBeDisplayed,
	isPageIssueWithAffectedLinks,
} from '~/model/issuesNew';

import {
	getCategoryUrlIdentifier,
	getUrlIdentifier,
} from '~/model/issues/identifierMapping';

import {
	issuesFilterSelector,
} from '~/state/ui/selectors';

import matchAndReturn from '~/utilities/matchAndReturn';



type Props = {
	issue: {
		affectedPagesPerState: {
			absoluteNumberOfPages: {
				ignored: number,
				open: number,
			},
			percentage: {
				ignored: number,
				open: number,
			},
		} | null,
		configuration: Record<string, any>,
		context: any,
		ignoringRuleOnCases: {
			isEffective: boolean,
		} | null,
		ignoringRuleOnPages: {
			isEffective: boolean,
		} | null,
		ignoringRuleOnSegments: {
			isEffective: boolean,
		} | null,
		ignoringRuleOnWebsite: {
			isEffective: boolean,
		},
		isActionable: boolean,
		isConfigurationModified: boolean,
		isIgnored: boolean,
		isPresentable: boolean,
		name: IssueName,
		pointsGained: number,
		pointsToGain: number,
		state: GraphQL.IssueState,
	},
	issueCategoryName: IssueCategoryName,
	websiteId: CK.WebsiteId,
};

const CurrentState: React.FC<Props> = (props) => {
	const {
		issue,
		issueCategoryName,
		websiteId,
	} = props;

	const affectedLinksCount = useCountAffectedLinks(issue.name, websiteId, false);
	const dispatch = useDispatch();
	const issuesFilter = useSelector(issuesFilterSelector);

	const [showSegmentsButtonOnHover, setShowSegmentsButtonOnHover] = React.useState(true);

	const issueName = issue.name;

	const handleOnMouseEnter = React.useCallback(
		() => {
			setShowSegmentsButtonOnHover(false);
		},
		[],
	);

	const handleOnMouseLeave = React.useCallback(
		() => {
			setShowSegmentsButtonOnHover(true);
		},
		[],
	);

	const handleOpenBarClick = React.useCallback(
		({ scope }) => {
			let filter: Record<string, any> | null = null;

			if (scope.indexOf('segment/') === 0) {
				filter = {
					segment: scope.substr('segment/'.length),
				};
			}

			dispatch(
				openSingleIssueAffectedPages(
					issueCategoryName,
					issueName,
					filter,
				),
			);
		},
		[
			dispatch,
			issueCategoryName,
			issueName,
		],
	);

	const extractDataForSegmentsChart = React.useCallback(
		(issueCategories: SegmentsBarChartExtractCallbackInput) => {
			const result = issueCategories
				.find((issueCategory) => issueCategory.name === issueCategoryName)
				?.issues
				.find((issue) => issue.name === issueName)
				?.affectedPagesPerState
				?.percentage;

			if (result === undefined) {
				throw new Error(
					`Can't find data for SegmentsChart for issue '${issueName}'`,
				);
			}

			return result;
		},
		[
			issueCategoryName,
			issueName,
		],
	);

	const handleAffectedPagesButtonClick = React.useCallback(
		() => {
			let filter: Record<string, any> | null = null;

			if (issuesFilter.get('segment') !== 'website') {
				filter = {
					segment: issuesFilter.get('segment'),
				};
			}

			dispatch(
				openSingleIssueAffectedPages(
					issueCategoryName,
					issueName,
					filter,
				),
			);
		},
		[
			dispatch,
			issueCategoryName,
			issueName,
			issuesFilter,
		],
	);

	const handleAffectedLinksButtonClick = React.useCallback(
		() => {
			dispatch(
				openSingleIssueAffectedLinks(
					issueCategoryName,
					issueName,
				),
			);
		},
		[
			dispatch,
			issueCategoryName,
			issueName,
		],
	);

	if (issue.affectedPagesPerState === null) {
		return (
			<PlatformScreenIssueDetail
				issue={issue}
				issueCategoryName={issueCategoryName}
				websiteId={websiteId}
			/>
		);
	}

	if (issue.isPresentable === false) {
		return null;
	}

	let issueState = STATE_BROKEN;

	if (issue.state === GraphQL.IssueState.Closed) {
		issueState = STATE_CLOSED;
	}

	if (issue.isIgnored) {
		issueState = STATE_IGNORED;
	}

	if (issue.state === GraphQL.IssueState.NotRequired) {
		issueState = STATE_NOT_REQUIRED;
	}

	if (issue.state === GraphQL.IssueState.Unknown) {
		issueState = STATE_UNKNOWN;
	}

	const hasAffectedPages = (
		issue.state === GraphQL.IssueState.Open
		&& issue.ignoringRuleOnWebsite.isEffective === false
	);

	const hasAffectedLinks = (
		hasAffectedPages
		&& isPageIssueWithAffectedLinks(issue.name)
		&& issue.affectedPagesPerState.absoluteNumberOfPages.open > 0
		&& affectedLinksCount.total > 0
	);

	const scores: Array<React.ReactNode> = [];

	if (hasAffectedLinks) {
		scores.push(
			<AffectedButton
				count={affectedLinksCount.total}
				key="affected-links"
				onClickCallback={handleAffectedLinksButtonClick}
				scope={AffectedButtonScope.Links}
				size={AffectedButtonSize.XSmall}
			/>,
		);
	}

	if (hasAffectedPages) {
		scores.push(
			<AffectedButton
				count={issue.affectedPagesPerState.absoluteNumberOfPages.open}
				key="affected-pages"
				onClickCallback={handleAffectedPagesButtonClick}
				percentage={issue.affectedPagesPerState.percentage.open}
				scope={AffectedButtonScope.Pages}
				size={AffectedButtonSize.XSmall}
			/>,
		);
	}

	scores.push(
		<AnimateHeartbeat
			enabled={issue.isActionable}
			key="score"
		>
			<IssueScore
				isIgnored={issue.isIgnored}
				size={IssueScoreSize.Small}
				state={matchAndReturn(issue.state, {
					[GraphQL.IssueState.Closed]: IssueScoreState.Fixed,
					[GraphQL.IssueState.NotApplicable]: IssueScoreState.NotApplicable,
					[GraphQL.IssueState.NotRequired]: IssueScoreState.NotRequired,
					[GraphQL.IssueState.Open]: IssueScoreState.Broken,
					[GraphQL.IssueState.Unknown]: IssueScoreState.Unknown,
				})}
				value={getPointsToBeDisplayed(issue)}
			/>
		</AnimateHeartbeat>,
	);

	return (
		<IssueLayout
			actionElements={scores}
			ignoreLink={(
				<IgnoringButton
					isIgnored={issue.isIgnored}
					routeName="website.issues.issueCategory.issueIgnoring"
					routeParams={{
						websiteId,
						issuesOverviewCategory: getCategoryUrlIdentifier(issueCategoryName),
						issuesOverviewIssue: getUrlIdentifier(issue.name),
						scope: getEntryScopeInWebsiteContext(issue),
					}}
				/>
			)}
			onMouseEnterCallback={handleOnMouseEnter}
			onMouseLeaveCallback={handleOnMouseLeave}
			state={issueState}
			title={(
				<IssueTitle
					issue={issue}
				/>
			)}
		>
			<IssueInstruction
				issue={issue}
			/>

			{issue.affectedPagesPerState.absoluteNumberOfPages.open > 0 && (
				<IssueIllustration>
					<IssueAffectedPagesBar
						amount={issue.affectedPagesPerState.absoluteNumberOfPages.open}
						onClickCallback={handleAffectedPagesButtonClick}
						percentage={issue.affectedPagesPerState.percentage.open}
						scope={issuesFilter.get('segment') === 'all'
							? AFFECTED_PAGES_SCOPE.Website
							: AFFECTED_PAGES_SCOPE.Segment
						}
					/>
				</IssueIllustration>
			)}

			{issuesFilter && (issue.affectedPagesPerState.percentage.open > 0 || issue.affectedPagesPerState.percentage.ignored > 0) && (
				<IssueIllustration>
					<SegmentsBarChart
						extractCallback={extractDataForSegmentsChart}
						filter={issuesFilter}
						key={websiteId + '/' + issuesFilter.get('segment')}
						numberOfIssues={1}
						onOpenBarClickCallback={handleOpenBarClick}
						showSegmentsButtonOnHover={showSegmentsButtonOnHover}
						websiteId={websiteId}
					/>
				</IssueIllustration>
			)}
		</IssueLayout>
	);
};



export default CurrentState;
