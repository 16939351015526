import React from 'react';
import {
	useSelector,
} from 'react-redux';


import ColumnsConfiguratorFooter from '~/components/logic/columnsConfigurator/ColumnsConfiguratorFooter';
import ColumnsConfiguratorHeader from './ColumnsConfiguratorHeader';
import ColumnsConfiguratorLayout from '~/components/patterns/columnsConfigurator/ColumnsConfiguratorLayout';
import ColumnSetSetup from './ColumnSetSetup';
import ColumnSetsList from './ColumnSetsList';

import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useIsColumnSetModified from '~/hooks/useIsColumnSetModified';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	columnsSetsListVisibilitySelector,
} from '~/state/ui/selectors';



const ColumnsConfigurator: React.FC = () => {
	const effectiveHomeAccountId = useEffectiveHomeAccountId();
	const websiteId = useWebsiteId();

	const columnsSetsListVisible = useSelector(columnsSetsListVisibilitySelector);
	const isColumnSetModified = useIsColumnSetModified();


	return (
		<ColumnsConfiguratorLayout
			footer={(
				<ColumnsConfiguratorFooter
					accountId={effectiveHomeAccountId}
					isColumnSetModified={isColumnSetModified ?? false}
				/>
			)}
			header={(
				<ColumnsConfiguratorHeader
					isColumnSetModified={isColumnSetModified}
				/>
			)}
		>
			{columnsSetsListVisible && (
				<ColumnSetsList />
			)}

			{!columnsSetsListVisible && (
				<ColumnSetSetup websiteId={websiteId} />
			)}
		</ColumnsConfiguratorLayout>
	);
};



export default ColumnsConfigurator;
