import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AccountAccessLevelName from '~/components/names/AccountAccessLevelName';
import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import Emphasis from '~/components/patterns/typography/Emphasis';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import ManagementTable from '~/components/patterns/tables/ManagementTable';
import RevokeAccessForConnectedAccountConfirmationModal from '~/components/app/RevokeAccessForConnectedAccountConfirmationModal';

import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';
import useModals from '~/hooks/useModals';
import useRevokeAgencyAccess from '~/hooks/useRevokeAgencyAccess';



const messages = defineMessages({
	accessRights: {
		id: 'ui.accountAccess.overview.heading.accessRights',
	},
	account: {
		id: 'ui.accountAccess.overview.heading.account',
	},
	action: {
		id: 'ui.accountAccess.overview.heading.action',
	},
	cancelRequest: {
		id: 'ui.accountAccess.overview.actions.cancelRequest',
	},
	isPending: {
		id: 'ui.accountAccess.overview.isPending',
	},
	revokeAccess: {
		id: 'ui.accountAccess.agencyAccessOverview.actions.revokeAccess',
	},
	title: {
		id: 'ui.accountAccess.agencyAccessOverview.title',
	},
	viewRequest: {
		id: 'ui.accountAccess.agencyAccessOverview.actions.viewRequest',
	},
});



type Props = {
	accountId: CK.AccountId | null,
	agencyConnections: ReadonlyArray<{
		agencyAccountId: CK.AccountId,
		agencyAccountName: string,
		isInitiatedByAgency: boolean,
		isPending: boolean,
		level: GraphQL.AccountAccessConnectionLevel,
	}>,
};

const AgencyAccessOverview: React.FC<Props> = (props) => {
	const {
		accountId,
		agencyConnections,
	} = props;

	const modals = useModals();
	const revokeAgencyAccess = useRevokeAgencyAccess();

	const isManagementAllowed = useIsAllowedWithAccount(accountId, GraphQL.ActionWithAccount.ManageAgencyAccess);

	function handleCancelRequest(agencyConnection: {
		agencyAccountId: CK.AccountId,
	}): void {
		if (accountId === null) {
			return;
		}

		revokeAgencyAccess({
			agencyId: agencyConnection.agencyAccountId,
			clientId: accountId,
		});
	}

	function openRevokeAccessModal(agencyConnection: {
		agencyAccountId: CK.AccountId,
		agencyAccountName: string,
	}): void {
		if (accountId === null) {
			return;
		}

		modals.openModal(
			() => (
				<RevokeAccessForConnectedAccountConfirmationModal
					agencyAccountId={agencyConnection.agencyAccountId}
					agencyAccountName={agencyConnection.agencyAccountName}
					clientAccountId={accountId}
				/>
			),
		);
	}

	const showAccessRightsColumn = agencyConnections.some((client) => {
		return client.level === GraphQL.AccountAccessConnectionLevel.ReadOnly;
	});

	return (
		<ManagementTable
			columnLabels={(
				<>
					<FormattedMessage {...messages.account} />
					{showAccessRightsColumn && (
						<FormattedMessage {...messages.accessRights} />
					)}
					<FormattedMessage {...messages.action} />
				</>
			)}
			columnWidths={(showAccessRightsColumn
				? [170, 'auto', 170]
				: [300, 'auto']
			)}
			height={[0, 500]}
			rows={agencyConnections}
			title={(
				<FormattedMessage {...messages.title} />
			)}
			width={[400, 500]}
		>
			{({ row: agencyConnection }) => (
				<>
					<Ellipsis>
						<AttachedElement
							element={agencyConnection.isPending && (
								<Emphasis>
									<FormattedMessage {...messages.isPending} />
								</Emphasis>
							)}
							inline={true}
						>
							{agencyConnection.agencyAccountName}
						</AttachedElement>
					</Ellipsis>

					{showAccessRightsColumn && (
						<Ellipsis>
							<AccountAccessLevelName level={agencyConnection.level} />
						</Ellipsis>
					)}

					<>
						{agencyConnection.isPending && agencyConnection.isInitiatedByAgency === false && (
							<InternalLink
								disabled={isManagementAllowed.yes === false}
								onClickCallback={() => {
									handleCancelRequest(agencyConnection);
								}}
								style={InternalLinkStyle.Decent}
							>
								<FormattedMessage {...messages.cancelRequest} />
							</InternalLink>
						)}

						{agencyConnection.isPending && agencyConnection.isInitiatedByAgency && (
							<InternalLink
								disabled={isManagementAllowed.yes === false}
								routeName="account.settings.agencyAccess.grantAgencyAccess"
								routeParams={{
									agencyId: agencyConnection.agencyAccountId,
								}}
								style={InternalLinkStyle.Decent}
							>
								<FormattedMessage {...messages.viewRequest} />
							</InternalLink>
						)}

						{agencyConnection.isPending === false && (
							<InternalLink
								disabled={isManagementAllowed.yes === false}
								onClickCallback={() => {
									openRevokeAccessModal(agencyConnection);
								}}
								style={InternalLinkStyle.Decent}
							>
								<FormattedMessage {...messages.revokeAccess} />
							</InternalLink>
						)}
					</>
				</>
			)}
		</ManagementTable>
	);
};



export default AgencyAccessOverview;
