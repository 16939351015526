/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteIsHttpAuthenticationEnabledQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteIsHttpAuthenticationEnabledQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isHttpAuthenticationEnabled: boolean } | null };


export const WebsiteIsHttpAuthenticationEnabledDocument = gql`
    query WebsiteIsHttpAuthenticationEnabled($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    isHttpAuthenticationEnabled
  }
}
    `;

/**
 * __useWebsiteIsHttpAuthenticationEnabledQuery__
 *
 * To run a query within a React component, call `useWebsiteIsHttpAuthenticationEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteIsHttpAuthenticationEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteIsHttpAuthenticationEnabledQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteIsHttpAuthenticationEnabledQuery(baseOptions: Apollo.QueryHookOptions<WebsiteIsHttpAuthenticationEnabledQuery, WebsiteIsHttpAuthenticationEnabledQueryVariables> & ({ variables: WebsiteIsHttpAuthenticationEnabledQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIsHttpAuthenticationEnabledQuery, WebsiteIsHttpAuthenticationEnabledQueryVariables>(WebsiteIsHttpAuthenticationEnabledDocument, options);
      }
export function useWebsiteIsHttpAuthenticationEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIsHttpAuthenticationEnabledQuery, WebsiteIsHttpAuthenticationEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIsHttpAuthenticationEnabledQuery, WebsiteIsHttpAuthenticationEnabledQueryVariables>(WebsiteIsHttpAuthenticationEnabledDocument, options);
        }
export function useWebsiteIsHttpAuthenticationEnabledSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteIsHttpAuthenticationEnabledQuery, WebsiteIsHttpAuthenticationEnabledQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteIsHttpAuthenticationEnabledQuery, WebsiteIsHttpAuthenticationEnabledQueryVariables>(WebsiteIsHttpAuthenticationEnabledDocument, options);
        }
export type WebsiteIsHttpAuthenticationEnabledQueryHookResult = ReturnType<typeof useWebsiteIsHttpAuthenticationEnabledQuery>;
export type WebsiteIsHttpAuthenticationEnabledLazyQueryHookResult = ReturnType<typeof useWebsiteIsHttpAuthenticationEnabledLazyQuery>;
export type WebsiteIsHttpAuthenticationEnabledSuspenseQueryHookResult = ReturnType<typeof useWebsiteIsHttpAuthenticationEnabledSuspenseQuery>;
export type WebsiteIsHttpAuthenticationEnabledQueryResult = Apollo.QueryResult<WebsiteIsHttpAuthenticationEnabledQuery, WebsiteIsHttpAuthenticationEnabledQueryVariables>;