import React from 'react';
import flattenChildren from 'react-keyed-flatten-children';

import AppearAnimation, {
	AppearAnimationDirection,
} from '~/components/patterns/animations/AppearAnimation';

import styles from './ColumnsConfiguratorLayout.module.scss';

import {
	notEmpty,
} from '~/utilities/typeCheck';



type Props = {
	children?: React.ReactNode,
	footer?: React.ReactNode,
	header?: React.ReactNode,
};

const ColumnsConfiguratorLayout: React.FC<Props> = (props) => {
	const {
		children,
		footer,
		header,
	} = props;

	const elements = flattenChildren(children).filter(notEmpty);

	return (
		<div className={styles.component}>
			{header}

			<div className={styles.columns}>
				{elements.map((child, index) => (
					<AppearAnimation
						direction={AppearAnimationDirection.InPlace}
						key={index}
					>
						{child}
					</AppearAnimation>
				))}
			</div>

			<div className={styles.footer}>
				{footer}
			</div>
		</div>
	);
};


export default ColumnsConfiguratorLayout;
