import {
	produce,
} from 'immer';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ColumnName from '~/components/names/ColumnName';
import ColumnsList from '~/components/patterns/columnsConfigurator/ColumnsList';
import ColumnsMessage from '~/components/patterns/columnsConfigurator/ColumnsMessage';
import {
	type ColumnsSetup,
} from '~/hooks/useCurrentColumnsSetup';

import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteIsLighthouseMonitored from '~/hooks/useWebsiteIsLighthouseMonitored';
import useWebsiteLogFileAnalysisSetup from '~/hooks/useWebsiteLogFileAnalysisSetup';

import {
	ColumnsWithoutChangeTracking,
	PagesColumnsCategory,
} from '~/model/pagesColumns';
import {
	useSelector,
} from 'react-redux';
import {
	isInHistoricalChangesModeSelector,
} from '~/state/historicalChanges/selectors';



const messages = defineMessages({
	customElementsButtonLabel: {
		id: 'ui.columnsConfigurator.customElements.addButtonLabel',
	},
	customElementsPlaceholder: {
		id: 'ui.columnsConfigurator.messages.noCustomElements',
	},
	enrichmentFieldsButtonLabel: {
		id: 'ui.columnsConfigurator.enrichmentFields.addButtonLabel',
	},
	enrichmentFieldsPlaceholder: {
		id: 'ui.columnsConfigurator.messages.noEnrichmentFields',
	},
	lighthouseButtonLabel: {
		id: 'ui.columnsConfigurator.lighthouse.enableButtonLabel',
	},
	lighthousePlaceholder: {
		id: 'ui.columnsConfigurator.messages.lighthouseNotEnabled',
	},
	logFileAnalysisButtonLabel: {
		id: 'ui.columnsConfigurator.logFileAnalysis.enableButtonLabel',
	},
	logFileAnalysisPlaceholder: {
		id: 'ui.columnsConfigurator.messages.logFileAnalysisNotEnabled',
	},
});



type CategorySetup = ColumnsSetup[number];

type Props = {
	categorySetup: CategorySetup,
	onUpdate: (categorySetup: CategorySetup) => void,
	websiteId: CK.WebsiteId,
};

const ColumnSetSetupColumns: React.FC<Props> = (props) => {
	const {
		categorySetup,
		onUpdate,
		websiteId,
	} = props;

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);
	const isLighthouseMonitored = useWebsiteIsLighthouseMonitored(websiteId);
	const isInHistoricalChangesMode = useSelector(isInHistoricalChangesModeSelector);

	const {
		isFeatureEnabled: isLighthouseFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LighthouseMonitoring);

	const {
		isFeatureEnabled: isLogFileAnalysisFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	const {
		isLogFileAnalysisEnabled,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	const columns = React.useMemo(
		() => {
			return categorySetup.columns
				.filter((column) => {
					if (categorySetup.name === PagesColumnsCategory.CustomElements) {
						return customElementDefinitions.getByColumn(column.name) !== null;
					}

					if (categorySetup.name === PagesColumnsCategory.EnrichmentFields) {
						return enrichmentFieldDefinitions.getByColumn(column.name) !== null;
					}

					return true;
				})
				.map((column) => {
					return {
						...column,
						label: <ColumnName column={column.name} />,
					};
				});
		},
		[
			categorySetup.columns,
			categorySetup.name,
			customElementDefinitions,
			enrichmentFieldDefinitions,
		],
	);

	const handleSelect = React.useCallback(
		(selectedColumn: CK.PagesColumn) => {
			const nextCategorySetup = produce(categorySetup, (draft) => {
				draft.columns.forEach((column) => {
					if (column.name === selectedColumn) {
						column.enabled = !column.enabled;
					}
				});

				return draft;
			});

			onUpdate(nextCategorySetup);
		},
		[
			categorySetup,
			onUpdate,
		],
	);

	const handleSort = React.useCallback(
		(nextColumns: typeof columns) => {
			const nextCategorySetup = produce(categorySetup, (draft) => {
				draft.columns = nextColumns;
			});

			onUpdate(nextCategorySetup);
		},
		[
			categorySetup,
			onUpdate,
		],
	);

	if (
		categorySetup.name === PagesColumnsCategory.CustomElements
		&& customElementDefinitions.isLoaded
		&& customElementDefinitions.count === 0
	) {
		return (
			<ColumnsMessage
				buttonLabel={(
					<FormattedMessage {...messages.customElementsButtonLabel} />
				)}
				linkRouteName="website.detail.customElements"
				linkRouteParams={{
					action: 'create_custom_element',
					websiteId,
				}}
			>
				<FormattedMessage {...messages.customElementsPlaceholder} />
			</ColumnsMessage>
		);
	}

	if (
		categorySetup.name === PagesColumnsCategory.EnrichmentFields
		&& enrichmentFieldDefinitions.isLoaded
		&& enrichmentFieldDefinitions.count === 0
	) {
		return (
			<ColumnsMessage
				buttonLabel={(
					<FormattedMessage {...messages.enrichmentFieldsButtonLabel} />
				)}
				linkRouteName="website.detail.enrichmentFields"
				linkRouteParams={{
					action: 'create_enrichment_field',
					websiteId,
				}}
			>
				<FormattedMessage {...messages.enrichmentFieldsPlaceholder} />
			</ColumnsMessage>
		);
	}

	if (
		categorySetup.name === PagesColumnsCategory.Lighthouse
		&& isLighthouseFeatureEnabled
		&& isLighthouseMonitored === false
	) {
		return (
			<ColumnsMessage
				buttonLabel={(
					<FormattedMessage {...messages.lighthouseButtonLabel} />
				)}
				linkRouteName="website.detail.monitoring"
				linkRouteParams={{
					websiteId,
				}}
			>
				<FormattedMessage {...messages.lighthousePlaceholder} />
			</ColumnsMessage>
		);
	}

	if (
		categorySetup.name === PagesColumnsCategory.SearchEngineActivity
		&& isLogFileAnalysisFeatureEnabled
		&& !isLogFileAnalysisEnabled
	) {
		return (
			<ColumnsMessage
				buttonLabel={(
					<FormattedMessage {...messages.logFileAnalysisButtonLabel} />
				)}
				linkRouteName="website.detail.logFileAnalysis"
				linkRouteParams={{
					websiteId,
				}}
			>
				<FormattedMessage {...messages.logFileAnalysisPlaceholder} />
			</ColumnsMessage>
		);
	}

	return (
		<ColumnsList
			columns={columns}
			onSelect={handleSelect}
			onSort={handleSort}
			unselectableColumns={[
				CK.PagesCommonColumn.Url,
				CK.PagesCommonColumn.Segments,
				...(isInHistoricalChangesMode ? ColumnsWithoutChangeTracking : []),
			]}
			unsortableColumns={[
				CK.PagesCommonColumn.Url,
			]}
		/>
	);
};



export default ColumnSetSetupColumns;
