/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MessagingAppChannelsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type MessagingAppChannelsQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly messagingAppChannels: ReadonlyArray<{ readonly __typename?: 'MessagingAppChannel', readonly id: CK.ID, readonly label: string, readonly messagingAppType: GraphQL.MessagingAppType, readonly ownerAccountId: CK.AccountId }> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } } | null };


export const MessagingAppChannelsDocument = gql`
    query MessagingAppChannels {
  authenticatedSession {
    memberships {
      account {
        id
        messagingAppChannels {
          id
          label
          messagingAppType
          ownerAccountId
        }
      }
      user {
        id
        legacyId
      }
    }
    user {
      id
      legacyId
    }
  }
}
    `;

/**
 * __useMessagingAppChannelsQuery__
 *
 * To run a query within a React component, call `useMessagingAppChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagingAppChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagingAppChannelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessagingAppChannelsQuery(baseOptions?: Apollo.QueryHookOptions<MessagingAppChannelsQuery, MessagingAppChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagingAppChannelsQuery, MessagingAppChannelsQueryVariables>(MessagingAppChannelsDocument, options);
      }
export function useMessagingAppChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagingAppChannelsQuery, MessagingAppChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagingAppChannelsQuery, MessagingAppChannelsQueryVariables>(MessagingAppChannelsDocument, options);
        }
export function useMessagingAppChannelsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MessagingAppChannelsQuery, MessagingAppChannelsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MessagingAppChannelsQuery, MessagingAppChannelsQueryVariables>(MessagingAppChannelsDocument, options);
        }
export type MessagingAppChannelsQueryHookResult = ReturnType<typeof useMessagingAppChannelsQuery>;
export type MessagingAppChannelsLazyQueryHookResult = ReturnType<typeof useMessagingAppChannelsLazyQuery>;
export type MessagingAppChannelsSuspenseQueryHookResult = ReturnType<typeof useMessagingAppChannelsSuspenseQuery>;
export type MessagingAppChannelsQueryResult = Apollo.QueryResult<MessagingAppChannelsQuery, MessagingAppChannelsQueryVariables>;