/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsitePageCapacityQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsitePageCapacityQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageCapacity: number } | null };


export const WebsitePageCapacityDocument = gql`
    query WebsitePageCapacity($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    pageCapacity
  }
}
    `;

/**
 * __useWebsitePageCapacityQuery__
 *
 * To run a query within a React component, call `useWebsitePageCapacityQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsitePageCapacityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsitePageCapacityQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsitePageCapacityQuery(baseOptions: Apollo.QueryHookOptions<WebsitePageCapacityQuery, WebsitePageCapacityQueryVariables> & ({ variables: WebsitePageCapacityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsitePageCapacityQuery, WebsitePageCapacityQueryVariables>(WebsitePageCapacityDocument, options);
      }
export function useWebsitePageCapacityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsitePageCapacityQuery, WebsitePageCapacityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsitePageCapacityQuery, WebsitePageCapacityQueryVariables>(WebsitePageCapacityDocument, options);
        }
export function useWebsitePageCapacitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsitePageCapacityQuery, WebsitePageCapacityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsitePageCapacityQuery, WebsitePageCapacityQueryVariables>(WebsitePageCapacityDocument, options);
        }
export type WebsitePageCapacityQueryHookResult = ReturnType<typeof useWebsitePageCapacityQuery>;
export type WebsitePageCapacityLazyQueryHookResult = ReturnType<typeof useWebsitePageCapacityLazyQuery>;
export type WebsitePageCapacitySuspenseQueryHookResult = ReturnType<typeof useWebsitePageCapacitySuspenseQuery>;
export type WebsitePageCapacityQueryResult = Apollo.QueryResult<WebsitePageCapacityQuery, WebsitePageCapacityQueryVariables>;