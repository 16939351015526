import React from 'react';
import {
	DndContext,
	type DragEndEvent,
	DragOverlay,
	type DragStartEvent,
	closestCenter,
} from '@dnd-kit/core';
import {
	SortableContext,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import ColumnsCategoriesListItem, {
	DragOverlayColumnsCategoriesListItem,
} from './ColumnsCategoriesListItem';
import {
	type AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';

import arrayMove from '~/utilities/arrayMove';

import {
	type PagesColumnsCategory,
} from '~/model/pagesColumns';
import {
	createPortal,
} from 'react-dom';
import {
	restrictToParentElement,
	restrictToVerticalAxis,
} from '@dnd-kit/modifiers';



type CategoryItem = {
	children: React.ReactNode,
	icon: AttributesGroupIconType,
	label: React.ReactNode,
	name: PagesColumnsCategory,
};

type Props = {
	categories: Array<CategoryItem>,
	onSort?: (
		categories: Array<CategoryItem>,
		oldIndex: number,
		newIndex: number,
	) => void,
	/**
	 * Categories that cannot be sorted. This is useful when you want to keep some categories in a
	 * fixed position. These categories should be placed at the beginning of the list.
	 */
	unsortableCategories?: Array<PagesColumnsCategory>,
};

const ColumnsCategoriesList: React.FC<Props> = (props) => {
	const {
		categories,
		onSort = null,
		unsortableCategories = [],
	} = props;

	const [activeItem, setActiveItem] = React.useState<CategoryItem | null>(null);
	const [openCategories, setOpenCategories] = React.useState<Set<PagesColumnsCategory>>(new Set());

	const handleDragStart = React.useCallback(
		(event: DragStartEvent) => {
			const { active } = event;

			const activeItem = categories.find((column) => column.name === active.id);

			if (activeItem !== undefined) {
				setActiveItem(activeItem);
			}
		},
		[
			categories,
		],
	);

	const handleDragEnd = React.useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;

			if (
				onSort !== null
				&& over !== null
				&& active.id !== over.id
			) {
				const oldIndex = categories.findIndex((column) => column.name === active.id);

				// If the "over" column is unsortable, move the active column to the first sortable
				const newIndex = unsortableCategories.includes(over.id as PagesColumnsCategory)
					? categories.findIndex((category) => !unsortableCategories.includes(category.name))
					: categories.findIndex((category) => category.name === over.id);

				const nextColumns = arrayMove(categories, oldIndex, newIndex);

				onSort(nextColumns, oldIndex, newIndex);
			}

			setActiveItem(null);
		},
		[
			categories,
			onSort,
			unsortableCategories,
		],
	);

	const handleToggleCategoryOpen = React.useCallback(
		(category: PagesColumnsCategory) => {
			setOpenCategories((prevOpenCategories) => {
				const nextOpenCategories = new Set(prevOpenCategories);

				if (prevOpenCategories.has(category)) {
					nextOpenCategories.delete(category);
				} else {
					nextOpenCategories.add(category);
				}

				return nextOpenCategories;
			});
		},
		[],
	);

	return (
		<DndContext
			collisionDetection={closestCenter}
			modifiers={[restrictToVerticalAxis, restrictToParentElement]}
			onDragEnd={handleDragEnd}
			onDragStart={handleDragStart}
		>
			<SortableContext
				items={categories.map((column) => ({ ...column, id: column.name }))}
				strategy={verticalListSortingStrategy}
			>
				{categories.map((category) => (
					<ColumnsCategoriesListItem
						icon={category.icon}
						isOpen={openCategories.has(category.name)}
						isSortable={!unsortableCategories.includes(category.name)}
						key={category.name}
						label={category.label}
						name={category.name}
						onControlClick={handleToggleCategoryOpen}
					>
						{category.children}
					</ColumnsCategoriesListItem>
				))}
			</SortableContext>

			{createPortal(
				<DragOverlay>
					{activeItem !== null && (
						<DragOverlayColumnsCategoriesListItem
							icon={activeItem.icon}
							isOpen={openCategories.has(activeItem.name)}
							label={activeItem.label}
							name={activeItem.name}
						>
							{activeItem.children}
						</DragOverlayColumnsCategoriesListItem>
					)}
				</DragOverlay>,
				document.body,
			)}
		</DndContext>
	);
};



export default ColumnsCategoriesList;
