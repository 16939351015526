/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteIsDiscoveredQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteIsDiscoveredQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isDiscovered: boolean } | null };


export const WebsiteIsDiscoveredDocument = gql`
    query WebsiteIsDiscovered($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    isDiscovered
  }
}
    `;

/**
 * __useWebsiteIsDiscoveredQuery__
 *
 * To run a query within a React component, call `useWebsiteIsDiscoveredQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteIsDiscoveredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteIsDiscoveredQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteIsDiscoveredQuery(baseOptions: Apollo.QueryHookOptions<WebsiteIsDiscoveredQuery, WebsiteIsDiscoveredQueryVariables> & ({ variables: WebsiteIsDiscoveredQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIsDiscoveredQuery, WebsiteIsDiscoveredQueryVariables>(WebsiteIsDiscoveredDocument, options);
      }
export function useWebsiteIsDiscoveredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIsDiscoveredQuery, WebsiteIsDiscoveredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIsDiscoveredQuery, WebsiteIsDiscoveredQueryVariables>(WebsiteIsDiscoveredDocument, options);
        }
export function useWebsiteIsDiscoveredSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteIsDiscoveredQuery, WebsiteIsDiscoveredQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteIsDiscoveredQuery, WebsiteIsDiscoveredQueryVariables>(WebsiteIsDiscoveredDocument, options);
        }
export type WebsiteIsDiscoveredQueryHookResult = ReturnType<typeof useWebsiteIsDiscoveredQuery>;
export type WebsiteIsDiscoveredLazyQueryHookResult = ReturnType<typeof useWebsiteIsDiscoveredLazyQuery>;
export type WebsiteIsDiscoveredSuspenseQueryHookResult = ReturnType<typeof useWebsiteIsDiscoveredSuspenseQuery>;
export type WebsiteIsDiscoveredQueryResult = Apollo.QueryResult<WebsiteIsDiscoveredQuery, WebsiteIsDiscoveredQueryVariables>;