import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ArrayGroupField, {
	type ArrayGroupFieldRendererProps,
	type ArrayGroupFieldValidationInput,
} from '~/components/patterns/forms/fields/ArrayGroupField';
import TransformationField from './TransformationField';
import TransformationParameterFields from '~/components/logic/customElements/transformationSteps/TransformationParameterFields';

import {
	type DATA_TYPE_BOOLEAN,
	type DATA_TYPE_DATE,
	type DATA_TYPE_HASH,
	type DATA_TYPE_INTEGER,
	type DATA_TYPE_NUMBER,
	DATA_TYPE_STRING,
	detectTransformationOutputDataType,
	isTransformationCompatibleWithDataType,
} from '~/model/customElements';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';
import {
	isString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	addButton: {
		id: 'ui.customElements.form.fieldsets.transformationSteps.addAnotherStepButton',
	},
	columnParameters: {
		id: 'ui.customElements.transformationSteps.header.parameters',
	},
	columnStep: {
		id: 'ui.customElements.transformationSteps.header.step',
	},
	columnType: {
		id: 'ui.customElements.transformationSteps.header.type',
	},
	deleteStepButtonLabel: {
		id: 'ui.customElements.transformationSteps.actions.deleteStep.button',
	},
	formErrorsStringTransformationIncompatibleWithElement: {
		id: 'ui.customElements.formErrors.stringTransformationIncompatibleWithElement',
	},
	formErrorsStringTransformationIncompatibleWithPreviousTransformation: {
		id: 'ui.customElements.formErrors.stringTransformationIncompatibleWithPreviousTransformation',
	},
	tooMany: {
		id: 'ui.customElements.transformationSteps.tooMany',
	},
});

const defaultParameters = {};

export type TransformationStepsConfiguratorRef = {
	addTransformation: () => void,
	numberOfTransformations: number,
};



type Props = {
	extractedElementDataType:
	| typeof DATA_TYPE_BOOLEAN
	| typeof DATA_TYPE_DATE
	| typeof DATA_TYPE_HASH
	| typeof DATA_TYPE_INTEGER
	| typeof DATA_TYPE_NUMBER
	| typeof DATA_TYPE_STRING
	| null,
	name: string,
};

const TransformationStepsConfigurator: React.FC<Props> = (props) => {
	const {
		extractedElementDataType = DATA_TYPE_STRING,
		name,
	} = props;

	const fields = React.useMemo(
		() => {
			return [
				{
					label: (
						<FormattedMessage {...messages.columnType} />
					),
					name: 'type',
					renderer: ({ fieldName }: ArrayGroupFieldRendererProps) => (
						<TransformationField
							name={fieldName}
							width={180}
						/>
					),
					validation: ({ f, listRows, rowIndex }: ArrayGroupFieldValidationInput) => ([
						f.validateNonEmpty(),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorsStringTransformationIncompatibleWithElement} />
							),
							rule: ({ value }) => {
								if (rowIndex !== 0) {
									return true;
								}

								return isTransformationCompatibleWithDataType(
									extractedElementDataType,
									value,
								);
							},
						}),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorsStringTransformationIncompatibleWithPreviousTransformation} />
							),
							rule: ({ value, values }) => {
								if (rowIndex === 0) {
									return true;
								}

								const rows = listRows(values);
								const precedingRow = getArrayItemAtSafeIndex(rows, rowIndex - 1);
								const precedingType = detectTransformationOutputDataType(
									precedingRow.type,
									precedingRow.options,
								);

								return isTransformationCompatibleWithDataType(
									precedingType,
									value,
								);
							},
						}),
					]),
					width: 200,
				},
				{
					defaultValue: defaultParameters,
					label: (
						<FormattedMessage {...messages.columnParameters} />
					),
					name: 'parameters',
					renderer: ({ fieldName, rowValues }: ArrayGroupFieldRendererProps) => (
						<TransformationParameterFields
							columnWidth={240}
							key={isString(rowValues.type) ? rowValues.type : null}
							name={fieldName}
							transformationType={isString(rowValues.type) ? rowValues.type : null}
						/>
					),
					width: 'auto',
				},
			];
		},
		[
			extractedElementDataType,
		],
	);

	return (
		<ArrayGroupField
			addButtonLabel={(
				<FormattedMessage {...messages.addButton} />
			)}
			fields={fields}
			isSortable={true}
			maximumRows={6}
			name={name}
			rowHeight={72}
			showAddButton={true}
			showHeaderWhenEmpty={false}
			showRowNumbers={false}
		/>
	);
};



export default TransformationStepsConfigurator;
