/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NumberOfActiveIncidentsWidgetQueryVariables = GraphQL.Exact<{
  date: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type NumberOfActiveIncidentsWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly numberOfActiveIncidents: { readonly __typename?: 'WebsiteDashboardNumberOfActiveIncidents', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: { readonly __typename?: 'WebsiteDashboardNumberOfActiveIncidentsData', readonly numberOfActiveIncidentsRegardlessOfScope: number, readonly numberOfActiveIncidentsWithinScope: number } | null } } };


export const NumberOfActiveIncidentsWidgetDocument = gql`
    query NumberOfActiveIncidentsWidget($date: DateYMD!, $scope: String!, $websiteId: WebsiteId!) {
  dashboardData {
    numberOfActiveIncidents(date: $date, scope: $scope, websiteId: $websiteId) {
      data {
        numberOfActiveIncidentsRegardlessOfScope
        numberOfActiveIncidentsWithinScope
      }
      id
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __useNumberOfActiveIncidentsWidgetQuery__
 *
 * To run a query within a React component, call `useNumberOfActiveIncidentsWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumberOfActiveIncidentsWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumberOfActiveIncidentsWidgetQuery({
 *   variables: {
 *      date: // value for 'date'
 *      scope: // value for 'scope'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useNumberOfActiveIncidentsWidgetQuery(baseOptions: Apollo.QueryHookOptions<NumberOfActiveIncidentsWidgetQuery, NumberOfActiveIncidentsWidgetQueryVariables> & ({ variables: NumberOfActiveIncidentsWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NumberOfActiveIncidentsWidgetQuery, NumberOfActiveIncidentsWidgetQueryVariables>(NumberOfActiveIncidentsWidgetDocument, options);
      }
export function useNumberOfActiveIncidentsWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NumberOfActiveIncidentsWidgetQuery, NumberOfActiveIncidentsWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NumberOfActiveIncidentsWidgetQuery, NumberOfActiveIncidentsWidgetQueryVariables>(NumberOfActiveIncidentsWidgetDocument, options);
        }
export function useNumberOfActiveIncidentsWidgetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NumberOfActiveIncidentsWidgetQuery, NumberOfActiveIncidentsWidgetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NumberOfActiveIncidentsWidgetQuery, NumberOfActiveIncidentsWidgetQueryVariables>(NumberOfActiveIncidentsWidgetDocument, options);
        }
export type NumberOfActiveIncidentsWidgetQueryHookResult = ReturnType<typeof useNumberOfActiveIncidentsWidgetQuery>;
export type NumberOfActiveIncidentsWidgetLazyQueryHookResult = ReturnType<typeof useNumberOfActiveIncidentsWidgetLazyQuery>;
export type NumberOfActiveIncidentsWidgetSuspenseQueryHookResult = ReturnType<typeof useNumberOfActiveIncidentsWidgetSuspenseQuery>;
export type NumberOfActiveIncidentsWidgetQueryResult = Apollo.QueryResult<NumberOfActiveIncidentsWidgetQuery, NumberOfActiveIncidentsWidgetQueryVariables>;