import React from 'react';

import GraphQL from '~/types/graphql';

import FormsList from '~/components/atoms/lists/FormsList';
import LogFileAnalysisCloudFlareLogSourceForm from '~/components/app/LogFileAnalysisCloudFlareLogSourceForm';
import LogFileAnalysisForm from '~/components/app/LogFileAnalysisForm';
import LogFileAnalysisLogSourceForm from '~/components/app/LogFileAnalysisLogSourceForm';

import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteLogFileAnalysisSetup from '~/hooks/useWebsiteLogFileAnalysisSetup';



function showLogSourceForm(logSources: ReadonlyArray<GraphQL.LogSource>, provider: GraphQL.LogSourceProvider) {
	const logSource = logSources.find((logSource) => logSource.provider === provider) ?? null;

	if (logSource === null) {
		return false;
	}

	return (
		logSource.status === GraphQL.LogSourceStatus.Available
		|| logSource.status === GraphQL.LogSourceStatus.Enabled
	);
}



const LogFileAnalysisSettingsGroup: React.FC = () => {
	const websiteId = useWebsiteId();

	const {
		isFeatureEnabled: isLogFileAnalysisFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	const {
		isFeatureEnabled: isAkamaiLogSourceFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.AkamaiLogSource);

	const {
		isFeatureEnabled: isCloudfrontLogSourceFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.CloudfrontLogSource);

	const {
		isFeatureEnabled: isFastlyLogSourceFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.FastlyLogSource);

	const {
		hasAvailableLogSources,
		logSources,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	const showAkamaiForm = showLogSourceForm(logSources, GraphQL.LogSourceProvider.Akamai);
	const showCloudflareForm = showLogSourceForm(logSources, GraphQL.LogSourceProvider.Cloudflare);
	const showCloudflareLogpushForm = showLogSourceForm(logSources, GraphQL.LogSourceProvider.CloudflareLogpush);
	const showCloudfrontForm = showLogSourceForm(logSources, GraphQL.LogSourceProvider.Cloudfront);
	const showFastlyForm = showLogSourceForm(logSources, GraphQL.LogSourceProvider.Fastly);

	let showLogFileAnalysisForm = (
		isLogFileAnalysisFeatureEnabled
		|| !hasAvailableLogSources
	);

	if (showAkamaiForm === true && isAkamaiLogSourceFeatureEnabled === false) {
		showLogFileAnalysisForm = false;
	}

	if (showCloudfrontForm === true && isCloudfrontLogSourceFeatureEnabled === false) {
		showLogFileAnalysisForm = false;
	}

	if (showFastlyForm === true && isFastlyLogSourceFeatureEnabled === false) {
		showLogFileAnalysisForm = false;
	}

	const showCloudflareLogSourceForm = (
		isLogFileAnalysisFeatureEnabled
		&& (showCloudflareForm || showCloudflareLogpushForm)
	);

	const showCloudflareUpsellForm = (
		!isLogFileAnalysisFeatureEnabled
		&& showCloudflareForm
	);

	return (
		<FormsList>
			{showLogFileAnalysisForm && (
				<LogFileAnalysisForm />
			)}

			{showCloudflareUpsellForm && (
				<LogFileAnalysisLogSourceForm
					provider={GraphQL.LogSourceProvider.Cloudflare}
				/>
			)}

			{showCloudflareLogSourceForm && (
				<LogFileAnalysisCloudFlareLogSourceForm />
			)}

			{showAkamaiForm && (
				<LogFileAnalysisLogSourceForm
					provider={GraphQL.LogSourceProvider.Akamai}
				/>
			)}

			{showCloudfrontForm && (
				<LogFileAnalysisLogSourceForm
					provider={GraphQL.LogSourceProvider.Cloudfront}
				/>
			)}

			{showFastlyForm && (
				<LogFileAnalysisLogSourceForm
					provider={GraphQL.LogSourceProvider.Fastly}
				/>
			)}
		</FormsList>
	);
};



export default LogFileAnalysisSettingsGroup;
