import React from 'react';

import type IconBuilder from '~/components/patterns/icons/builders/iconsData/IconBuilder';



let googleIconCounter = 0;



const GoogleIconBuilder: IconBuilder = {
	icon: () => {
		const componentID = googleIconCounter++;

		return (
			<>
				<g
					clipPath={`url(#google-icon-${componentID})`}
					opacity="0.8"
				>
					<path
						d="M17.8124 12C17.8134 13.3678 17.3315 14.6921 16.4518 15.7395C15.5721 16.787 14.3509 17.4903 13.0035 17.7257C11.6561 17.9611 10.2688 17.7134 9.08608 17.0262C7.90336 16.3391 7.00109 15.2566 6.53822 13.9694L3.21362 16.6291C4.24739 18.5942 5.90978 20.1563 7.93535 21.0659C9.96092 21.9755 12.2329 22.1802 14.3884 21.6473C16.5439 21.1144 18.4588 19.8746 19.8271 18.1259C21.1955 16.3772 21.9385 14.2204 21.9374 12"
						fill="#00AC47"
					/>
					<path
						d="M17.8126 12C17.8118 12.9248 17.5901 13.8359 17.1659 14.6577C16.7417 15.4794 16.1273 16.1878 15.3739 16.724L18.6607 19.3535C19.6913 18.4255 20.5156 17.2915 21.0801 16.0247C21.6446 14.758 21.9367 13.3868 21.9376 12"
						fill="#4285F4"
					/>
					<path
						d="M6.1875 11.9999C6.18894 11.328 6.30765 10.6616 6.53827 10.0305L3.21367 7.37085C2.45774 8.79673 2.0625 10.3861 2.0625 11.9999C2.0625 13.6138 2.45774 15.2031 3.21367 16.629L6.53827 13.9693C6.30765 13.3383 6.18894 12.6718 6.1875 11.9999Z"
						fill="#FFBA00"
					/>
					<path
						d="M11.9999 6.18751C13.2298 6.18785 14.4275 6.58088 15.4184 7.30936L18.4634 4.46738C17.3331 3.4928 15.9973 2.78608 14.5556 2.39995C13.1139 2.01382 11.6037 1.95826 10.1375 2.23741C8.6714 2.51656 7.28723 3.12322 6.08833 4.01211C4.88943 4.901 3.90675 6.04916 3.21362 7.37093L6.53822 10.0306C6.94368 8.90661 7.68544 7.93477 8.66264 7.24717C9.63984 6.55957 10.8051 6.18959 11.9999 6.18751Z"
						fill="#EA4435"
					/>
					<path
						d="M21.9375 11.25V12L20.25 14.625H12.375V10.5H21.1875C21.3864 10.5 21.5772 10.579 21.7178 10.7197C21.8585 10.8603 21.9375 11.0511 21.9375 11.25Z"
						fill="#4285F4"
					/>
				</g>
				<defs>
					<clipPath id={`google-icon-${componentID}`}>
						<rect
							fill="white"
							height="24"
							width="24"
						/>
					</clipPath>
				</defs>
			</>

		);
	},
	viewBoxHeight: 24,
	viewBoxWidth: 24,
};



export default GoogleIconBuilder;

