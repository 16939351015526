/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlatformHealthQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PlatformHealthQuery = { readonly __typename?: 'Query', readonly platformData: { readonly __typename?: 'PlatformData', readonly health: number | null, readonly id: CK.ID } | null };


export const PlatformHealthDocument = gql`
    query PlatformHealth($websiteId: WebsiteId!) {
  platformData(websiteId: $websiteId) {
    health
    id
  }
}
    `;

/**
 * __usePlatformHealthQuery__
 *
 * To run a query within a React component, call `usePlatformHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformHealthQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePlatformHealthQuery(baseOptions: Apollo.QueryHookOptions<PlatformHealthQuery, PlatformHealthQueryVariables> & ({ variables: PlatformHealthQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlatformHealthQuery, PlatformHealthQueryVariables>(PlatformHealthDocument, options);
      }
export function usePlatformHealthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlatformHealthQuery, PlatformHealthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlatformHealthQuery, PlatformHealthQueryVariables>(PlatformHealthDocument, options);
        }
export function usePlatformHealthSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PlatformHealthQuery, PlatformHealthQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlatformHealthQuery, PlatformHealthQueryVariables>(PlatformHealthDocument, options);
        }
export type PlatformHealthQueryHookResult = ReturnType<typeof usePlatformHealthQuery>;
export type PlatformHealthLazyQueryHookResult = ReturnType<typeof usePlatformHealthLazyQuery>;
export type PlatformHealthSuspenseQueryHookResult = ReturnType<typeof usePlatformHealthSuspenseQuery>;
export type PlatformHealthQueryResult = Apollo.QueryResult<PlatformHealthQuery, PlatformHealthQueryVariables>;