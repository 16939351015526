import {
	captureException,
	setExtra,
} from '@sentry/browser';



class FormError {

	static fromApolloError(apolloError, mappingToFieldValues = {}) {
		if (apolloError.networkError) {
			for (const graphQLError of (apolloError.networkError?.result?.errors ?? [])) {
				if (graphQLError?.extensions?.code === 'actionFailure') {
					return new FormError(
						graphQLError.extensions.failureCode,
						mappingToFieldValues[graphQLError.extensions.failureCode] ?? null,
						graphQLError.extensions.payload,
						graphQLError.message,
					);
				}
			}

			return new FormError(
				'server-error',
				null,
				apolloError.networkError,
			);
		}
	}



	static fromStripeError(stripeError) {
		setExtra('stripeError', stripeError);
		captureException(new Error(`Stripe error: ${stripeError.code}`, {
			cause: stripeError,
		}));

		return new FormError(
			'stripeError',
			null,
			stripeError,
		);
	}



	static fromUnknownError(error) {
		if (error instanceof Error) {
			captureException(error);
		} else {
			setExtra('error', error);
			captureException(new Error('unknown error in Form', {
				cause: error,
			}));
		}

		return new FormError(
			'unknownError',
			null,
			error,
		);
	}



	constructor(name, value, details, message) {
		this.name = name;
		this.value = value ?? null;
		this.details = details || true;
		this.message = message ?? null;
	}



	getName() {
		return this.name;
	}



	getValue() {
		return this.value;
	}



	getDetails() {
		return this.details;
	}



	getMessage() {
		return this.message ?? this.name;
	}

}



export default FormError;
