/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountHasBillingHistoryQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountHasBillingHistoryQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly hasBillingHistory: boolean } | null };


export const AccountHasBillingHistoryDocument = gql`
    query AccountHasBillingHistory($accountId: AccountId!) {
  account(id: $accountId) {
    id
    hasBillingHistory
  }
}
    `;

/**
 * __useAccountHasBillingHistoryQuery__
 *
 * To run a query within a React component, call `useAccountHasBillingHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountHasBillingHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountHasBillingHistoryQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountHasBillingHistoryQuery(baseOptions: Apollo.QueryHookOptions<AccountHasBillingHistoryQuery, AccountHasBillingHistoryQueryVariables> & ({ variables: AccountHasBillingHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountHasBillingHistoryQuery, AccountHasBillingHistoryQueryVariables>(AccountHasBillingHistoryDocument, options);
      }
export function useAccountHasBillingHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountHasBillingHistoryQuery, AccountHasBillingHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountHasBillingHistoryQuery, AccountHasBillingHistoryQueryVariables>(AccountHasBillingHistoryDocument, options);
        }
export function useAccountHasBillingHistorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountHasBillingHistoryQuery, AccountHasBillingHistoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountHasBillingHistoryQuery, AccountHasBillingHistoryQueryVariables>(AccountHasBillingHistoryDocument, options);
        }
export type AccountHasBillingHistoryQueryHookResult = ReturnType<typeof useAccountHasBillingHistoryQuery>;
export type AccountHasBillingHistoryLazyQueryHookResult = ReturnType<typeof useAccountHasBillingHistoryLazyQuery>;
export type AccountHasBillingHistorySuspenseQueryHookResult = ReturnType<typeof useAccountHasBillingHistorySuspenseQuery>;
export type AccountHasBillingHistoryQueryResult = Apollo.QueryResult<AccountHasBillingHistoryQuery, AccountHasBillingHistoryQueryVariables>;