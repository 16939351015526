/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ChangeUserRoleModalQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  legacyUserId: GraphQL.Scalars['String']['input'];
}>;


export type ChangeUserRoleModalQuery = { readonly __typename?: 'Query', readonly userAccountMembership: { readonly __typename?: 'AccountMembership', readonly hasLimitedWebsitesAccess: boolean, readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> } | null };

export type ChangeUserRoleMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  assignedWebsites: GraphQL.InputMaybe<ReadonlyArray<GraphQL.Scalars['WebsiteId']['input']> | GraphQL.Scalars['WebsiteId']['input']>;
  legacyId: GraphQL.Scalars['ID']['input'];
  legacyUserId: GraphQL.Scalars['String']['input'];
  limitedWebsitesAccess: GraphQL.InputMaybe<GraphQL.Scalars['Boolean']['input']>;
  role: GraphQL.UserRole;
}>;


export type ChangeUserRoleMutation = { readonly __typename?: 'Mutation', readonly ChangeUserRole: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly userAccountMembership: { readonly __typename?: 'AccountMembership', readonly hasLimitedWebsitesAccess: boolean, readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly isAllowedToManageMonitoringSettings: boolean, readonly legacyId: string, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }> }, readonly websiteAccess: ReadonlyArray<{ readonly __typename?: 'UserWebsiteAccess', readonly id: CK.ID, readonly managedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly managedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess> | null, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId } }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> } | null } } };


export const ChangeUserRoleModalDocument = gql`
    query ChangeUserRoleModal($accountId: AccountId!, $legacyUserId: String!) {
  userAccountMembership(accountId: $accountId, legacyUserId: $legacyUserId) {
    account {
      id
    }
    hasLimitedWebsitesAccess
    user {
      email
      id
      legacyId
    }
    role
    websites {
      id
    }
  }
}
    `;

/**
 * __useChangeUserRoleModalQuery__
 *
 * To run a query within a React component, call `useChangeUserRoleModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useChangeUserRoleModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChangeUserRoleModalQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useChangeUserRoleModalQuery(baseOptions: Apollo.QueryHookOptions<ChangeUserRoleModalQuery, ChangeUserRoleModalQueryVariables> & ({ variables: ChangeUserRoleModalQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChangeUserRoleModalQuery, ChangeUserRoleModalQueryVariables>(ChangeUserRoleModalDocument, options);
      }
export function useChangeUserRoleModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChangeUserRoleModalQuery, ChangeUserRoleModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChangeUserRoleModalQuery, ChangeUserRoleModalQueryVariables>(ChangeUserRoleModalDocument, options);
        }
export function useChangeUserRoleModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ChangeUserRoleModalQuery, ChangeUserRoleModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ChangeUserRoleModalQuery, ChangeUserRoleModalQueryVariables>(ChangeUserRoleModalDocument, options);
        }
export type ChangeUserRoleModalQueryHookResult = ReturnType<typeof useChangeUserRoleModalQuery>;
export type ChangeUserRoleModalLazyQueryHookResult = ReturnType<typeof useChangeUserRoleModalLazyQuery>;
export type ChangeUserRoleModalSuspenseQueryHookResult = ReturnType<typeof useChangeUserRoleModalSuspenseQuery>;
export type ChangeUserRoleModalQueryResult = Apollo.QueryResult<ChangeUserRoleModalQuery, ChangeUserRoleModalQueryVariables>;
export const ChangeUserRoleDocument = gql`
    mutation ChangeUserRole($accountId: AccountId!, $assignedWebsites: [WebsiteId!], $legacyId: ID!, $legacyUserId: String!, $limitedWebsitesAccess: Boolean, $role: UserRole!) {
  ChangeUserRole(
    accountId: $accountId
    assignedWebsites: $assignedWebsites
    limitedWebsitesAccess: $limitedWebsitesAccess
    role: $role
    userId: $legacyId
  ) {
    query {
      userAccountMembership(accountId: $accountId, legacyUserId: $legacyUserId) {
        account {
          id
        }
        hasLimitedWebsitesAccess
        user {
          id
          isAllowedToManageMonitoringSettings
          legacyId
          websiteEmailSettings {
            alertEmailsSetup
            isWeeklyReportEnabled
            websiteId
          }
        }
        role
        websiteAccess {
          id
          managedActions
          managedActionsForKingdomAdmin
          permittedActions
          permittedActionsForKingdomAdmin
          user {
            id
            legacyId
          }
          website {
            id
          }
        }
        websites {
          id
        }
      }
    }
  }
}
    `;
export type ChangeUserRoleMutationFn = Apollo.MutationFunction<ChangeUserRoleMutation, ChangeUserRoleMutationVariables>;

/**
 * __useChangeUserRoleMutation__
 *
 * To run a mutation, you first call `useChangeUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserRoleMutation, { data, loading, error }] = useChangeUserRoleMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      assignedWebsites: // value for 'assignedWebsites'
 *      legacyId: // value for 'legacyId'
 *      legacyUserId: // value for 'legacyUserId'
 *      limitedWebsitesAccess: // value for 'limitedWebsitesAccess'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useChangeUserRoleMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserRoleMutation, ChangeUserRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserRoleMutation, ChangeUserRoleMutationVariables>(ChangeUserRoleDocument, options);
      }
export type ChangeUserRoleMutationHookResult = ReturnType<typeof useChangeUserRoleMutation>;
export type ChangeUserRoleMutationResult = Apollo.MutationResult<ChangeUserRoleMutation>;
export type ChangeUserRoleMutationOptions = Apollo.BaseMutationOptions<ChangeUserRoleMutation, ChangeUserRoleMutationVariables>;