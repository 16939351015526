/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountTypeQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountTypeQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly type: GraphQL.AccountType } | null };


export const AccountTypeDocument = gql`
    query AccountType($accountId: AccountId!) {
  account(id: $accountId) {
    id
    type
  }
}
    `;

/**
 * __useAccountTypeQuery__
 *
 * To run a query within a React component, call `useAccountTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTypeQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountTypeQuery(baseOptions: Apollo.QueryHookOptions<AccountTypeQuery, AccountTypeQueryVariables> & ({ variables: AccountTypeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountTypeQuery, AccountTypeQueryVariables>(AccountTypeDocument, options);
      }
export function useAccountTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountTypeQuery, AccountTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountTypeQuery, AccountTypeQueryVariables>(AccountTypeDocument, options);
        }
export function useAccountTypeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountTypeQuery, AccountTypeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountTypeQuery, AccountTypeQueryVariables>(AccountTypeDocument, options);
        }
export type AccountTypeQueryHookResult = ReturnType<typeof useAccountTypeQuery>;
export type AccountTypeLazyQueryHookResult = ReturnType<typeof useAccountTypeLazyQuery>;
export type AccountTypeSuspenseQueryHookResult = ReturnType<typeof useAccountTypeSuspenseQuery>;
export type AccountTypeQueryResult = Apollo.QueryResult<AccountTypeQuery, AccountTypeQueryVariables>;