/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteIsIndexNowEnabledQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteIsIndexNowEnabledQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isIndexNowEnabled: boolean } | null };


export const WebsiteIsIndexNowEnabledDocument = gql`
    query WebsiteIsIndexNowEnabled($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    isIndexNowEnabled
  }
}
    `;

/**
 * __useWebsiteIsIndexNowEnabledQuery__
 *
 * To run a query within a React component, call `useWebsiteIsIndexNowEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteIsIndexNowEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteIsIndexNowEnabledQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteIsIndexNowEnabledQuery(baseOptions: Apollo.QueryHookOptions<WebsiteIsIndexNowEnabledQuery, WebsiteIsIndexNowEnabledQueryVariables> & ({ variables: WebsiteIsIndexNowEnabledQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIsIndexNowEnabledQuery, WebsiteIsIndexNowEnabledQueryVariables>(WebsiteIsIndexNowEnabledDocument, options);
      }
export function useWebsiteIsIndexNowEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIsIndexNowEnabledQuery, WebsiteIsIndexNowEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIsIndexNowEnabledQuery, WebsiteIsIndexNowEnabledQueryVariables>(WebsiteIsIndexNowEnabledDocument, options);
        }
export function useWebsiteIsIndexNowEnabledSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteIsIndexNowEnabledQuery, WebsiteIsIndexNowEnabledQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteIsIndexNowEnabledQuery, WebsiteIsIndexNowEnabledQueryVariables>(WebsiteIsIndexNowEnabledDocument, options);
        }
export type WebsiteIsIndexNowEnabledQueryHookResult = ReturnType<typeof useWebsiteIsIndexNowEnabledQuery>;
export type WebsiteIsIndexNowEnabledLazyQueryHookResult = ReturnType<typeof useWebsiteIsIndexNowEnabledLazyQuery>;
export type WebsiteIsIndexNowEnabledSuspenseQueryHookResult = ReturnType<typeof useWebsiteIsIndexNowEnabledSuspenseQuery>;
export type WebsiteIsIndexNowEnabledQueryResult = Apollo.QueryResult<WebsiteIsIndexNowEnabledQuery, WebsiteIsIndexNowEnabledQueryVariables>;