import classNames from 'classnames';
import React from 'react';

import styles from './Spacer.module.scss';



export enum SpacerGap {
	Default = 'default',
	Large = 'large',
	NoGap = 'no-gap',
	Small = 'small',
	XSmall = 'xsmall',

	Growing = 'growing', // responsive (growing) gap
}

type Props = {
	bottom?: SpacerGap,
	children: React.ReactNode,
	gap?: SpacerGap,
	isStretched?: boolean,
	left?: SpacerGap,
	right?: SpacerGap,
	top?: SpacerGap,
};



const Spacer: React.FC<Props> = (props) => {
	const {
		bottom,
		children,
		gap = SpacerGap.NoGap,
		isStretched,
		left,
		right,
		top,
	} = props;

	const componentClasses = classNames({
		[styles.isStretched]: isStretched,
		[styles[`bottom-${bottom}-gap`]]: bottom !== undefined,
		[styles[`bottom-${gap}-gap`]]: bottom === undefined,
		[styles[`left-${gap}-gap`]]: left === undefined,
		[styles[`left-${left}-gap`]]: left !== undefined,
		[styles[`right-${gap}-gap`]]: right === undefined,
		[styles[`right-${right}-gap`]]: right !== undefined,
		[styles[`top-${gap}-gap`]]: top === undefined,
		[styles[`top-${top}-gap`]]: top !== undefined,
	});

	return (
		<div className={componentClasses}>
			{children}
		</div>
	);
};



export default Spacer;
