import classNames from 'classnames';
import React from 'react';



export const TYPE_PRIMARY = 'primary';
export const TYPE_SECONDARY = 'secondary';
export const TYPE_TERNARY = 'ternary';



type Props = {
	children?: React.ReactNode,
	icon?: React.ReactNode,
	isCollapsed?: boolean,
	isCollapsible?: boolean,
	label: React.ReactNode,
	onClickCallback: (e: any) => void,
	type:
	| typeof TYPE_PRIMARY
	| typeof TYPE_SECONDARY
	| typeof TYPE_TERNARY,
	value?: React.ReactNode,
};

const Row: React.FC<Props> = (props) => {
	const {
		children,
		icon,
		isCollapsible,
		isCollapsed,
		label,
		onClickCallback,
		type,
		value,
	} = props;

	const [groupMaxHeight, setGroupMaxHeight] = React.useState<number>();

	const groupBodyRef = React.useRef<HTMLDivElement>(null);

	React.useEffect(
		() => {
			if (isCollapsible) {
				if (isCollapsed) {
					setGroupMaxHeight(0);
				} else {
					setGroupMaxHeight(groupBodyRef.current?.offsetHeight);
				}
			} else {
				setGroupMaxHeight(undefined);
			}
		},
		[
			isCollapsible,
			isCollapsed,
		],
	);

	return (
		<div
			className={classNames({
				'issue-changes-overview-row': true,
				['issue-changes-overview-row--' + type]: true,
				'issue-changes-overview-row--clickable': onClickCallback,
			})}
		>
			<div
				className="issue-changes-overview-row__header"
				onClick={onClickCallback}
			>
				<div className="issue-changes-overview-row__header-content">
					<div className="issue-changes-overview-row__label">
						{icon && (
							<div className="issue-changes-overview-row__icon">
								{icon}
							</div>
						)}
						{label}
					</div>
					{value && (
						<div className="issue-changes-overview-row__value">
							{value}
						</div>
					)}
				</div>
			</div>
			{children && (
				<div
					className={classNames({
						'issue-changes-overview-row__group': true,
						'issue-changes-overview-row__group--is-collapsible': isCollapsible,
						'issue-changes-overview-row__group--is-collapsed': isCollapsible && isCollapsed,
					})}
					style={{
						maxHeight: (isCollapsible && !isCollapsed && groupMaxHeight) ? (groupMaxHeight + 30) : undefined,
					}}
				>
					<div ref={groupBodyRef}>
						{children}
					</div>
				</div>
			)}
		</div>
	);
};



export default Row;
