import React from 'react';

import MissingValue from '~/components/app/MissingValue';



const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;



function pad(number: number, amount = 2) {
	return number.toString().padStart(amount, '0');
}



export enum DurationFormatterUnit {
	Milliseconds = 'milliseconds',
	Seconds = 'seconds',
}



type Props = {
	unit?: DurationFormatterUnit,
	value: number | null,
};

const DurationFormatter: React.FC<Props> = (props) => {
	const {
		unit,
	} = props;

	let value = (props.value ?? null);

	if (value === null) {
		return (
			<MissingValue />
		);
	}

	if (unit === DurationFormatterUnit.Seconds) {
		value = value * SECOND;
	}

	const hours = Math.floor(value / HOUR);
	const minutes = Math.floor((value - hours * HOUR) / MINUTE);
	const seconds = Math.floor((value - hours * HOUR - minutes * MINUTE) / SECOND);
	const milliseconds = value % SECOND;

	if (unit === DurationFormatterUnit.Milliseconds) {
		return ((
			`${pad(hours)}:${pad(minutes)}:${pad(seconds)}.${pad(milliseconds, 3)}`
		) as unknown) as React.ReactElement;
	}

	return ((
		`${pad(hours)}:${pad(minutes)}:${pad(seconds)}`
	) as unknown) as React.ReactElement;
};



export default React.memo(DurationFormatter);
