export enum ExternalLinkDestination {
	AcademyCanonicalFaqNoindex = 'academy_canonicalfaq_noindex',
	AcademyCanonicalLink = 'academy_canonicallink',
	AcademyCoreWebVitals = 'academy_corewebvitals',
	AcademyCoreWebVitalsCumulativeLayoutShift = 'academy_corewebvitals_cls',
	AcademyCoreWebVitalsFirstContentfulPaint = 'academy_corewebvitals_fcp',
	AcademyCoreWebVitalsLargestContentfulPaint = 'academy_corewebvitals_lcp',
	AcademyCoreWebVitalsPerformanceCalculation = 'academy_corewebvitals_performancecalculation',
	AcademyCoreWebVitalsSpeedIndex = 'academy_corewebvitals_si',
	AcademyCoreWebVitalsTotalBlockingTime = 'academy_corewebvitals_tbt',
	AcademyCoreWebVitalsTimeToInteractive = 'academy_corewebvitals_tti',
	AcademyCoreWebVitalsWhatAreThey = 'academy_corewebvitals_whatarethey',
	AcademyCoreWebVitalsWhyCare = 'academy_corewebvitals_whycare',
	AcademyCrawlBudget = 'academy_crawlbudget',
	AcademyCrawlBudgetBrokenLinks = 'academy_crawlbudget_brokenlinks',
	AcademyCrawlBudgetXmlSitemaps = 'academy_crawlbudget_xmlsitemaps',
	AcademyDuplicateContent = 'academy_duplicatecontent',
	AcademyHeadings = 'academy_headings',
	AcademyHeadingsLength = 'academy_headings_length',
	AcademyHeadingsH1Multiple = 'academy_headings_h1_multiple',
	AcademyHreflang = 'academy_hreflang',
	AcademyHreflangAbsoluteUrl = 'academy_hreflang_absolute_url',
	AcademyHreflangLanguageAndRegion = 'academy_hreflang_language_and_region',
	AcademyHreflangSelfReference = 'academy_hreflang_self_reference',
	AcademyHreflangXDefault = 'academy_hreflang_x_default',
	AcademyImageSeo = 'academy_imageseo',
	AcademyImageSeoAltAttribute = 'academy_imageseo_alt_attribute',
	AcademyLinkAuthority = 'academy_linkauthority',
	AcademyMetaDescription = 'academy_metadescription',
	AcademyMetaDescriptionLength = 'academy_metadescription_length',
	AcademyMetaDescriptionUniqueness = 'academy_metadescription_uniqueness',
	AcademyOpenGraph = 'academy_opengraph',
	AcademyOpenGraphDescription = 'academy_opengraph_description',
	AcademyOpenGraphImage = 'academy_opengraph_image',
	AcademyOpenGraphTitle = 'academy_opengraph_title',
	AcademyOpenGraphUrl = 'academy_opengraph_url',
	AcademyRedirects = 'academy_redirects',
	AcademyRedirectsPreferredVersion = 'academy_redirects_preferred_version',
	AcademySchema = 'academy_schema',
	AcademySeoRequirementsNewWebsiteHttps = 'academy_seo_requirements_new_website_https',
	AcademyTitle = 'academy_title',
	AcademyTitleLength = 'academy_title_length',
	AcademyTitleUniqueness = 'academy_title_uniqueness',
	AcademyTwitterCards = 'academy_twittercards',
	AcademyTwitterCardsDescription = 'academy_twittercards_description',
	AcademyTwitterCardsImage = 'academy_twittercards_image',
	AcademyTwitterCardsSite = 'academy_twittercards_site',
	AcademyTwitterCardsTitle = 'academy_twittercards_title',
	AcademyTwitterCardsType = 'academy_twittercards_type',
	AcademyRobotsTxt = 'academy_robotstxt',
	AcademyRobotsTxtCrawlDelay = 'academy_robotstxt_crawldelay',
	AcademyRobotsTxtXmlSitemap = 'academy_robotstxt_xmlsitemap',
	AcademyXmlSitemap = 'academy_xmlsitemap',
	EexternaLSitemapsOrgProtocol = 'external_sitemapsorg_protocol',
	SupportAnalytics = 'support_analytics',
}
