/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdministerAccountEmailSettingsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  areIntercomAutomaticMessagesForSalesEnabled: GraphQL.Scalars['Boolean']['input'];
  areIntercomAutomaticMessagesForTrialRevivalEnabled: GraphQL.Scalars['Boolean']['input'];
  areIntercomAutomaticMessagesForWelcomeEnabled: GraphQL.Scalars['Boolean']['input'];
}>;


export type AdministerAccountEmailSettingsMutation = { readonly __typename?: 'Mutation', readonly AdministerAccountEmailSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly areIntercomAutomaticMessagesForSalesEnabled: boolean, readonly areIntercomAutomaticMessagesForTrialRevivalEnabled: boolean, readonly areIntercomAutomaticMessagesForWelcomeEnabled: boolean } | null } | null } } };

export type EmailSettingsFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type EmailSettingsFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly areIntercomAutomaticMessagesForSalesEnabled: boolean, readonly areIntercomAutomaticMessagesForTrialRevivalEnabled: boolean, readonly areIntercomAutomaticMessagesForWelcomeEnabled: boolean } | null } | null };


export const AdministerAccountEmailSettingsDocument = gql`
    mutation AdministerAccountEmailSettings($accountId: AccountId!, $areIntercomAutomaticMessagesForSalesEnabled: Boolean!, $areIntercomAutomaticMessagesForTrialRevivalEnabled: Boolean!, $areIntercomAutomaticMessagesForWelcomeEnabled: Boolean!) {
  AdministerAccountEmailSettings(
    accountId: $accountId
    areIntercomAutomaticMessagesForSalesEnabled: $areIntercomAutomaticMessagesForSalesEnabled
    areIntercomAutomaticMessagesForTrialRevivalEnabled: $areIntercomAutomaticMessagesForTrialRevivalEnabled
    areIntercomAutomaticMessagesForWelcomeEnabled: $areIntercomAutomaticMessagesForWelcomeEnabled
  ) {
    query {
      account(id: $accountId) {
        adminSettings {
          areIntercomAutomaticMessagesForSalesEnabled
          areIntercomAutomaticMessagesForTrialRevivalEnabled
          areIntercomAutomaticMessagesForWelcomeEnabled
        }
        id
      }
    }
  }
}
    `;
export type AdministerAccountEmailSettingsMutationFn = Apollo.MutationFunction<AdministerAccountEmailSettingsMutation, AdministerAccountEmailSettingsMutationVariables>;

/**
 * __useAdministerAccountEmailSettingsMutation__
 *
 * To run a mutation, you first call `useAdministerAccountEmailSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdministerAccountEmailSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [administerAccountEmailSettingsMutation, { data, loading, error }] = useAdministerAccountEmailSettingsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      areIntercomAutomaticMessagesForSalesEnabled: // value for 'areIntercomAutomaticMessagesForSalesEnabled'
 *      areIntercomAutomaticMessagesForTrialRevivalEnabled: // value for 'areIntercomAutomaticMessagesForTrialRevivalEnabled'
 *      areIntercomAutomaticMessagesForWelcomeEnabled: // value for 'areIntercomAutomaticMessagesForWelcomeEnabled'
 *   },
 * });
 */
export function useAdministerAccountEmailSettingsMutation(baseOptions?: Apollo.MutationHookOptions<AdministerAccountEmailSettingsMutation, AdministerAccountEmailSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdministerAccountEmailSettingsMutation, AdministerAccountEmailSettingsMutationVariables>(AdministerAccountEmailSettingsDocument, options);
      }
export type AdministerAccountEmailSettingsMutationHookResult = ReturnType<typeof useAdministerAccountEmailSettingsMutation>;
export type AdministerAccountEmailSettingsMutationResult = Apollo.MutationResult<AdministerAccountEmailSettingsMutation>;
export type AdministerAccountEmailSettingsMutationOptions = Apollo.BaseMutationOptions<AdministerAccountEmailSettingsMutation, AdministerAccountEmailSettingsMutationVariables>;
export const EmailSettingsFormDocument = gql`
    query EmailSettingsForm($accountId: AccountId!) {
  account(id: $accountId) {
    adminSettings {
      areIntercomAutomaticMessagesForSalesEnabled
      areIntercomAutomaticMessagesForTrialRevivalEnabled
      areIntercomAutomaticMessagesForWelcomeEnabled
    }
    id
  }
}
    `;

/**
 * __useEmailSettingsFormQuery__
 *
 * To run a query within a React component, call `useEmailSettingsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailSettingsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailSettingsFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useEmailSettingsFormQuery(baseOptions: Apollo.QueryHookOptions<EmailSettingsFormQuery, EmailSettingsFormQueryVariables> & ({ variables: EmailSettingsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailSettingsFormQuery, EmailSettingsFormQueryVariables>(EmailSettingsFormDocument, options);
      }
export function useEmailSettingsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailSettingsFormQuery, EmailSettingsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailSettingsFormQuery, EmailSettingsFormQueryVariables>(EmailSettingsFormDocument, options);
        }
export function useEmailSettingsFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EmailSettingsFormQuery, EmailSettingsFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmailSettingsFormQuery, EmailSettingsFormQueryVariables>(EmailSettingsFormDocument, options);
        }
export type EmailSettingsFormQueryHookResult = ReturnType<typeof useEmailSettingsFormQuery>;
export type EmailSettingsFormLazyQueryHookResult = ReturnType<typeof useEmailSettingsFormLazyQuery>;
export type EmailSettingsFormSuspenseQueryHookResult = ReturnType<typeof useEmailSettingsFormSuspenseQuery>;
export type EmailSettingsFormQueryResult = Apollo.QueryResult<EmailSettingsFormQuery, EmailSettingsFormQueryVariables>;