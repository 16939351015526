import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import CancelInvitationConfirmationModal from '~/components/app/CancelInvitationConfirmationModal';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';

import useOpenModal from '~/hooks/useOpenModal';



const messages = defineMessages({
	cancelInvitation: {
		id: 'ui.teamDetail.members.cancelInvitation',
	},
});



type Props = {
	accountId: CK.AccountId,
	email: string,
};

const CancelInvitationLink: React.FC<Props> = (props) => {
	const {
		accountId,
		email,
	} = props;

	const openCancelInvitationModal = useOpenModal(
		() => (
			<CancelInvitationConfirmationModal
				accountId={accountId}
				email={email}
			/>
		),
		[
			accountId,
			email,
		],
	);

	return (
		<InternalLink
			onClickCallback={openCancelInvitationModal}
			style={InternalLinkStyle.Decent}
		>
			<FormattedMessage {...messages.cancelInvitation} />
		</InternalLink>
	);
};



export default CancelInvitationLink;
