import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CookiesField from '~/components/app/CookiesField';
import Form from '~/components/atoms/forms/basis/Form';
import SubmitButton from '~/components/app/SubmitButton';

import {
	useUpdateWebsiteMonitoringCookiesMutation,
} from './WebsiteSettingsCookiesForm.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useWebsiteCookies from '~/hooks/useWebsiteCookies';

import {
	isNonEmptyString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	submitButton: {
		id: 'ui.general.applyChanges',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteSettingsCookiesForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const websiteCookies = useWebsiteCookies(websiteId)
		?.map((cookie) => ({
			name: cookie.name,
			value: cookie.value,
		}));

	const [updateWebsiteMonitoringCookies] = useUpdateWebsiteMonitoringCookiesMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			const cookies = values.cookies.filter(
				(cookie) => isNonEmptyString(cookie.value) && isNonEmptyString(cookie.name),
			);

			await updateWebsiteMonitoringCookies({
				variables: {
					cookies,
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			updateWebsiteMonitoringCookies,
			websiteId,
		],
	);

	return (
		<Form
			defaultValues={{
				cookies: websiteCookies,
			}}
			onSuccess={handleSubmit}
		>
			<CookiesField
				name="cookies"
			/>

			<ButtonsLayout
				alignment={ButtonsLayoutAlignment.Right}
				layout={ButtonsLayoutType.Steps}
			>
				<CancelButton />

				<SubmitButton>
					<FormattedMessage {...messages.submitButton} />
				</SubmitButton>
			</ButtonsLayout>
		</Form>
	);
};



export default WebsiteSettingsCookiesForm;
