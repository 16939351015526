/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IncomingInternalLinksQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  limit: GraphQL.Scalars['Int']['input'];
  offset: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type IncomingInternalLinksQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly legacyId: number | null, readonly incomingInternalLinks: { readonly __typename?: 'PageIncomingInternalLinksConnection', readonly totalCount: number, readonly edges: ReadonlyArray<{ readonly __typename?: 'PageIncomingInternalLinksConnectionEdge', readonly cursor: any, readonly loadedAt: number, readonly node: { readonly __typename?: 'PageIncomingInternalLinksConnectionNode', readonly anchorText: string, readonly anchorTitle: string | null, readonly id: CK.ID, readonly sourceId: CK.UrlId, readonly sourceLegacyId: number | null, readonly sourceUrl: string } } | null> } | null } | null };


export const IncomingInternalLinksDocument = gql`
    query IncomingInternalLinks($legacyUrlId: Int!, $limit: Int!, $offset: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    incomingInternalLinks(limit: $limit, offset: $offset) {
      edges {
        cursor
        loadedAt @client
        node {
          anchorText
          anchorTitle
          id
          sourceId
          sourceLegacyId
          sourceUrl
        }
      }
      totalCount
    }
    legacyId
  }
}
    `;

/**
 * __useIncomingInternalLinksQuery__
 *
 * To run a query within a React component, call `useIncomingInternalLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomingInternalLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomingInternalLinksQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useIncomingInternalLinksQuery(baseOptions: Apollo.QueryHookOptions<IncomingInternalLinksQuery, IncomingInternalLinksQueryVariables> & ({ variables: IncomingInternalLinksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncomingInternalLinksQuery, IncomingInternalLinksQueryVariables>(IncomingInternalLinksDocument, options);
      }
export function useIncomingInternalLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncomingInternalLinksQuery, IncomingInternalLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncomingInternalLinksQuery, IncomingInternalLinksQueryVariables>(IncomingInternalLinksDocument, options);
        }
export function useIncomingInternalLinksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IncomingInternalLinksQuery, IncomingInternalLinksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IncomingInternalLinksQuery, IncomingInternalLinksQueryVariables>(IncomingInternalLinksDocument, options);
        }
export type IncomingInternalLinksQueryHookResult = ReturnType<typeof useIncomingInternalLinksQuery>;
export type IncomingInternalLinksLazyQueryHookResult = ReturnType<typeof useIncomingInternalLinksLazyQuery>;
export type IncomingInternalLinksSuspenseQueryHookResult = ReturnType<typeof useIncomingInternalLinksSuspenseQuery>;
export type IncomingInternalLinksQueryResult = Apollo.QueryResult<IncomingInternalLinksQuery, IncomingInternalLinksQueryVariables>;