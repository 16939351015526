import classNames from 'classnames';
import React from 'react';

import styles from './ColumnsConfiguratorOverlayLayout.module.scss';



type Props = {
	children: React.ReactNode,
	columnsConfigurator: React.ReactNode,
	isOpen: boolean,
	onClose: () => void,
};

const ColumnsConfiguratorOverlayLayout: React.FC<Props> = (props) => {
	const {
		children,
		columnsConfigurator,
		isOpen,
		onClose,
	} = props;

	return (
		<div className={styles.component}>
			<div
				className={(
					classNames({
						[styles.overlay]: true,
						[styles.overlayHidden]: !isOpen,
					})
				)}
				onClickCapture={onClose}
			/>

			<div
				className={(
					classNames({
						[styles.columnsConfigurator]: true,
						[styles.columnsConfiguratorHidden]: !isOpen,
					})
				)}
			>
				{columnsConfigurator}
			</div>

			<div className={styles.content}>
				{children}
			</div>
		</div>
	);
};



export default ColumnsConfiguratorOverlayLayout;
