import React from 'react';

import IntercomPinger from '~/components/atoms/IntercomPinger';

import useRoyalMode from '~/hooks/useRoyalMode';

import {
	Intercom,
} from '~/globals';



const IntercomManager: React.FC = () => {
	const royalMode = useRoyalMode();

	if (royalMode.isReady === false) {
		return null;
	}

	if (royalMode.isActive) {
		return null;
	}

	if (Intercom().blocked !== true) {
		return null;
	}

	return (
		<IntercomPinger />
	);
};



export default IntercomManager;
