import {
	teamsSelector,
} from '../teams/selectors';



export const animateHeartbeatSelector = (state) => state.get('ui').get('animateHeartbeat');

export const appEmptyStateSelector = (state) => state.get('ui').get('appEmptyState');

export const columnsSetsListVisibilitySelector = (state) => state.get('ui').get('columnsSetsList');

export const columnsConfiguratorVisibilitySelector = (state) => state.get('ui').get('columnsConfigurator');

export const filterSelector = (state) => state.get('ui').get('pagesOverviewFilter');

export const lastSelectedWebsiteIdSelector = (state) => state.get('lastSelectedWebsite').get('id');

export const selectedWebsiteSelector = (state) => {
	const teams = teamsSelector(state);

	const websiteIdParameter = state.get('ui').get('urlState').params.websiteId;
	const activeWebsiteId = websiteIdParameter
		? state.get('ui').get('active').get('website')
		: state.get('lastSelectedWebsite').get('id');

	const team = teams.find((team) => {
		return team.get('websites').find((website) => {
			return website.get('id') === activeWebsiteId;
		});
	});

	let foundWebsite;

	if (team) {
		foundWebsite = team.get('websites').find((website) => {
			return website.get('id') === activeWebsiteId;
		});
	}

	if (foundWebsite) {
		return foundWebsite;
	}

	if (
		!foundWebsite
		&& teams.size > 0
		&& teams.find((team) => team.get('is_home')).get('websites').size > 0
	) {
		return teams.find((team) => team.get('is_home')).get('websites').first();
	}

	if (
		!foundWebsite
		&& teams.size > 0
		&& teams.first().get('websites').size > 0
	) {
		return teams.first().get('websites').first();
	}

	return null;
};

export const selectedPageIdSelector = (state) => state.get('ui').get('active').get('page');

export const selectedWebsiteIdSelector = (state) => {
	const selectedWebsite = selectedWebsiteSelector(state);

	if (selectedWebsite) {
		return selectedWebsite.get('id');
	}

	return state.get('ui').get('active').get('website');
};

export const selectedMemberDetailIdSelector = (state) => state.get('ui').get('active').get('memberDetailId');

export const sortBySelector = (state) => state.get('ui').get('pagesOverviewSortBy');

export const filteredIssuesSelector = (state) => state.get('ui').get('filteredIssues');

export const hoveredCategorySelector = (state) => state.get('ui').get('hoveredCategory');

export const issuesFilterSelector = (state) => state.get('ui').get('issuesOverviewFilter');

export const screenSelector = (state) => state.get('ui').get('urlState').name;

export const urlStateSelector = (state) => state.get('ui').get('urlState');

export const homeAccountSelector = (state) => {
	if (state.get('teams').get('basics').size > 0) {
		return state.get('teams').get('basics').find((account) => account.get('is_home'));
	}

	return null;
};

export const teamSelector = (state) => {
	const urlState = urlStateSelector(state);

	if (urlState.params.accountId) {
		return state.get('teams').get('basics').get(urlState.params.accountId.toString());
	}

	const website = selectedWebsiteSelector(state);

	if (website && urlState.name.indexOf('website') === 0) {
		return teamsSelector(state).get(website.get('team_id') + '');
	}

	return homeAccountSelector(state);
};

export const selectedTeamSelector = (state) => {
	const team = teamSelector(state);

	return team ? team.get('id').toString() : null;
};

export const selectedSectionSelector = (state) => state.get('ui').get('active').get('section');
