/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdministerAccountSalesSettingsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  areSalesHandledExternally: GraphQL.Scalars['Boolean']['input'];
  bookDemoOffering: GraphQL.InputMaybe<GraphQL.AccountAdminBookDemoOffering>;
  isImportant: GraphQL.Scalars['Boolean']['input'];
  isInteresting: GraphQL.InputMaybe<GraphQL.Scalars['Boolean']['input']>;
  isSignupOptimized: GraphQL.Scalars['Boolean']['input'];
  isTrialBarDisplayed: GraphQL.InputMaybe<GraphQL.Scalars['Boolean']['input']>;
  logoSignificance: GraphQL.AccountAdminLogoSignificance;
}>;


export type AdministerAccountSalesSettingsMutation = { readonly __typename?: 'Mutation', readonly AdministerAccountSalesSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isSignupOptimized: boolean, readonly isTrialBarDisplayed: boolean, readonly phase: GraphQL.AccountPhase, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly areSalesHandledExternally: boolean, readonly bookDemoOffering: GraphQL.AccountAdminBookDemoOffering | null, readonly isImportant: boolean, readonly isInteresting: boolean, readonly logoSignificance: GraphQL.AccountAdminLogoSignificance, readonly pqlScore: number | null, readonly sqlScore: number | null } | null, readonly discounts: ReadonlyArray<{ readonly __typename?: 'Discount', readonly coupon: string, readonly percentage: CK.Percentage | null, readonly type: GraphQL.DiscountType, readonly amount: ReadonlyArray<{ readonly __typename?: 'DiscountExactAmount', readonly currency: GraphQL.Currency, readonly value: number }> | null }> } | null } } };

export type SalesSettingsFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type SalesSettingsFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isSignupOptimized: boolean, readonly isTrialBarDisplayed: boolean, readonly phase: GraphQL.AccountPhase, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly areSalesHandledExternally: boolean, readonly bookDemoOffering: GraphQL.AccountAdminBookDemoOffering | null, readonly isImportant: boolean, readonly isInteresting: boolean, readonly logoSignificance: GraphQL.AccountAdminLogoSignificance, readonly pqlScore: number | null, readonly sqlScore: number | null } | null } | null };


export const AdministerAccountSalesSettingsDocument = gql`
    mutation AdministerAccountSalesSettings($accountId: AccountId!, $areSalesHandledExternally: Boolean!, $bookDemoOffering: AccountAdminBookDemoOffering, $isImportant: Boolean!, $isInteresting: Boolean, $isSignupOptimized: Boolean!, $isTrialBarDisplayed: Boolean, $logoSignificance: AccountAdminLogoSignificance!) {
  AdministerAccountSalesSettings(
    accountId: $accountId
    areSalesHandledExternally: $areSalesHandledExternally
    bookDemoOffering: $bookDemoOffering
    isImportant: $isImportant
    isInteresting: $isInteresting
    isSignupOptimized: $isSignupOptimized
    isTrialBarDisplayed: $isTrialBarDisplayed
    logoSignificance: $logoSignificance
  ) {
    query {
      account(id: $accountId) {
        adminSettings {
          areSalesHandledExternally
          bookDemoOffering
          isImportant
          isInteresting
          logoSignificance
          pqlScore
          sqlScore
        }
        discounts {
          amount {
            currency
            value
          }
          coupon
          percentage
          type
        }
        id
        isSignupOptimized
        isTrialBarDisplayed
        phase
      }
    }
  }
}
    `;
export type AdministerAccountSalesSettingsMutationFn = Apollo.MutationFunction<AdministerAccountSalesSettingsMutation, AdministerAccountSalesSettingsMutationVariables>;

/**
 * __useAdministerAccountSalesSettingsMutation__
 *
 * To run a mutation, you first call `useAdministerAccountSalesSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdministerAccountSalesSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [administerAccountSalesSettingsMutation, { data, loading, error }] = useAdministerAccountSalesSettingsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      areSalesHandledExternally: // value for 'areSalesHandledExternally'
 *      bookDemoOffering: // value for 'bookDemoOffering'
 *      isImportant: // value for 'isImportant'
 *      isInteresting: // value for 'isInteresting'
 *      isSignupOptimized: // value for 'isSignupOptimized'
 *      isTrialBarDisplayed: // value for 'isTrialBarDisplayed'
 *      logoSignificance: // value for 'logoSignificance'
 *   },
 * });
 */
export function useAdministerAccountSalesSettingsMutation(baseOptions?: Apollo.MutationHookOptions<AdministerAccountSalesSettingsMutation, AdministerAccountSalesSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdministerAccountSalesSettingsMutation, AdministerAccountSalesSettingsMutationVariables>(AdministerAccountSalesSettingsDocument, options);
      }
export type AdministerAccountSalesSettingsMutationHookResult = ReturnType<typeof useAdministerAccountSalesSettingsMutation>;
export type AdministerAccountSalesSettingsMutationResult = Apollo.MutationResult<AdministerAccountSalesSettingsMutation>;
export type AdministerAccountSalesSettingsMutationOptions = Apollo.BaseMutationOptions<AdministerAccountSalesSettingsMutation, AdministerAccountSalesSettingsMutationVariables>;
export const SalesSettingsFormDocument = gql`
    query SalesSettingsForm($accountId: AccountId!) {
  account(id: $accountId) {
    adminSettings {
      areSalesHandledExternally
      bookDemoOffering
      isImportant
      isInteresting
      logoSignificance
      pqlScore
      sqlScore
    }
    id
    isSignupOptimized
    isTrialBarDisplayed
    phase
  }
}
    `;

/**
 * __useSalesSettingsFormQuery__
 *
 * To run a query within a React component, call `useSalesSettingsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesSettingsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesSettingsFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useSalesSettingsFormQuery(baseOptions: Apollo.QueryHookOptions<SalesSettingsFormQuery, SalesSettingsFormQueryVariables> & ({ variables: SalesSettingsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesSettingsFormQuery, SalesSettingsFormQueryVariables>(SalesSettingsFormDocument, options);
      }
export function useSalesSettingsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesSettingsFormQuery, SalesSettingsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesSettingsFormQuery, SalesSettingsFormQueryVariables>(SalesSettingsFormDocument, options);
        }
export function useSalesSettingsFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SalesSettingsFormQuery, SalesSettingsFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SalesSettingsFormQuery, SalesSettingsFormQueryVariables>(SalesSettingsFormDocument, options);
        }
export type SalesSettingsFormQueryHookResult = ReturnType<typeof useSalesSettingsFormQuery>;
export type SalesSettingsFormLazyQueryHookResult = ReturnType<typeof useSalesSettingsFormLazyQuery>;
export type SalesSettingsFormSuspenseQueryHookResult = ReturnType<typeof useSalesSettingsFormSuspenseQuery>;
export type SalesSettingsFormQueryResult = Apollo.QueryResult<SalesSettingsFormQuery, SalesSettingsFormQueryVariables>;