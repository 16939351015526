import React from 'react';

import TextField, {
	TextFieldSize,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';



type Props = {
	extraProps?: Partial<React.ComponentProps<typeof TextField>>,
	name: string,
	placeholder?: string,
};

const ParameterField: React.FC<Props> = (props) => {
	const {
		extraProps = {},
		name,
		placeholder,
	} = props;

	return (
		<TextField
			name={name}
			placeholder={placeholder}
			size={TextFieldSize.Small}
			width="100%"
			{...extraProps}
		/>
	);
};



export default ParameterField;

export {
	TextFieldType as ParameterFieldType,
};
