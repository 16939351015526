/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OrganizationAccessFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type OrganizationAccessFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly canManagersAffectSubscription: boolean, readonly canManagersControlAgencyAccess: boolean, readonly canManagersSeeApiTokens: boolean, readonly id: CK.AccountId, readonly isTwoFactorAuthenticationEnforced: boolean, readonly userRestrictions: { readonly __typename?: 'AccountUserRestrictions', readonly emailDomainsWhitelist: ReadonlyArray<string> | null }, readonly websiteRestrictions: { readonly __typename?: 'AccountWebsiteRestrictions', readonly domainsWhitelist: ReadonlyArray<string> | null }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly domain: string, readonly id: CK.WebsiteId }> } | null };

export type UpdateOrganizationAccessControlMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  canManagersAffectSubscription: GraphQL.Scalars['Boolean']['input'];
  canManagersControlAgencyAccess: GraphQL.Scalars['Boolean']['input'];
  canManagersSeeApiTokens: GraphQL.Scalars['Boolean']['input'];
  domainsWhitelist: GraphQL.Scalars['String']['input'];
  emailDomainsWhitelist: GraphQL.Scalars['String']['input'];
  isTwoFactorAuthenticationEnforced: GraphQL.Scalars['Boolean']['input'];
}>;


export type UpdateOrganizationAccessControlMutation = { readonly __typename?: 'Mutation', readonly UpdateOrganizationAccessControl: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly canManagersAffectSubscription: boolean, readonly canManagersControlAgencyAccess: boolean, readonly canManagersSeeApiTokens: boolean, readonly id: CK.AccountId, readonly isTwoFactorAuthenticationEnforced: boolean, readonly userRestrictions: { readonly __typename?: 'AccountUserRestrictions', readonly emailDomainsWhitelist: ReadonlyArray<string> | null }, readonly websiteRestrictions: { readonly __typename?: 'AccountWebsiteRestrictions', readonly domainsWhitelist: ReadonlyArray<string> | null } } | null } } };


export const OrganizationAccessFormDocument = gql`
    query OrganizationAccessForm($accountId: AccountId!) {
  account(id: $accountId) {
    canManagersAffectSubscription
    canManagersControlAgencyAccess
    canManagersSeeApiTokens
    id
    isTwoFactorAuthenticationEnforced
    userRestrictions {
      emailDomainsWhitelist
    }
    websiteRestrictions {
      domainsWhitelist
    }
    websites {
      domain
      id
    }
  }
}
    `;

/**
 * __useOrganizationAccessFormQuery__
 *
 * To run a query within a React component, call `useOrganizationAccessFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationAccessFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationAccessFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useOrganizationAccessFormQuery(baseOptions: Apollo.QueryHookOptions<OrganizationAccessFormQuery, OrganizationAccessFormQueryVariables> & ({ variables: OrganizationAccessFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrganizationAccessFormQuery, OrganizationAccessFormQueryVariables>(OrganizationAccessFormDocument, options);
      }
export function useOrganizationAccessFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationAccessFormQuery, OrganizationAccessFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrganizationAccessFormQuery, OrganizationAccessFormQueryVariables>(OrganizationAccessFormDocument, options);
        }
export function useOrganizationAccessFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OrganizationAccessFormQuery, OrganizationAccessFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OrganizationAccessFormQuery, OrganizationAccessFormQueryVariables>(OrganizationAccessFormDocument, options);
        }
export type OrganizationAccessFormQueryHookResult = ReturnType<typeof useOrganizationAccessFormQuery>;
export type OrganizationAccessFormLazyQueryHookResult = ReturnType<typeof useOrganizationAccessFormLazyQuery>;
export type OrganizationAccessFormSuspenseQueryHookResult = ReturnType<typeof useOrganizationAccessFormSuspenseQuery>;
export type OrganizationAccessFormQueryResult = Apollo.QueryResult<OrganizationAccessFormQuery, OrganizationAccessFormQueryVariables>;
export const UpdateOrganizationAccessControlDocument = gql`
    mutation UpdateOrganizationAccessControl($accountId: AccountId!, $canManagersAffectSubscription: Boolean!, $canManagersControlAgencyAccess: Boolean!, $canManagersSeeApiTokens: Boolean!, $domainsWhitelist: String!, $emailDomainsWhitelist: String!, $isTwoFactorAuthenticationEnforced: Boolean!) {
  UpdateOrganizationAccessControl(
    accountId: $accountId
    domainsWhitelist: $domainsWhitelist
    emailDomainsWhitelist: $emailDomainsWhitelist
    isTwoFactorAuthenticationEnforced: $isTwoFactorAuthenticationEnforced
    managersCanAffectSubscription: $canManagersAffectSubscription
    managersCanControlAgencyAccess: $canManagersControlAgencyAccess
    managersCanSeeApiTokens: $canManagersSeeApiTokens
  ) {
    query {
      account(id: $accountId) {
        canManagersAffectSubscription
        canManagersControlAgencyAccess
        canManagersSeeApiTokens
        id
        isTwoFactorAuthenticationEnforced
        userRestrictions {
          emailDomainsWhitelist
        }
        websiteRestrictions {
          domainsWhitelist
        }
      }
    }
  }
}
    `;
export type UpdateOrganizationAccessControlMutationFn = Apollo.MutationFunction<UpdateOrganizationAccessControlMutation, UpdateOrganizationAccessControlMutationVariables>;

/**
 * __useUpdateOrganizationAccessControlMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationAccessControlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationAccessControlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationAccessControlMutation, { data, loading, error }] = useUpdateOrganizationAccessControlMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      canManagersAffectSubscription: // value for 'canManagersAffectSubscription'
 *      canManagersControlAgencyAccess: // value for 'canManagersControlAgencyAccess'
 *      canManagersSeeApiTokens: // value for 'canManagersSeeApiTokens'
 *      domainsWhitelist: // value for 'domainsWhitelist'
 *      emailDomainsWhitelist: // value for 'emailDomainsWhitelist'
 *      isTwoFactorAuthenticationEnforced: // value for 'isTwoFactorAuthenticationEnforced'
 *   },
 * });
 */
export function useUpdateOrganizationAccessControlMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationAccessControlMutation, UpdateOrganizationAccessControlMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationAccessControlMutation, UpdateOrganizationAccessControlMutationVariables>(UpdateOrganizationAccessControlDocument, options);
      }
export type UpdateOrganizationAccessControlMutationHookResult = ReturnType<typeof useUpdateOrganizationAccessControlMutation>;
export type UpdateOrganizationAccessControlMutationResult = Apollo.MutationResult<UpdateOrganizationAccessControlMutation>;
export type UpdateOrganizationAccessControlMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationAccessControlMutation, UpdateOrganizationAccessControlMutationVariables>;