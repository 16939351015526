/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserLocaleQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserLocaleQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly locale: string } | null };


export const UserLocaleDocument = gql`
    query UserLocale($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    id
    legacyId
    locale
  }
}
    `;

/**
 * __useUserLocaleQuery__
 *
 * To run a query within a React component, call `useUserLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLocaleQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserLocaleQuery(baseOptions: Apollo.QueryHookOptions<UserLocaleQuery, UserLocaleQueryVariables> & ({ variables: UserLocaleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserLocaleQuery, UserLocaleQueryVariables>(UserLocaleDocument, options);
      }
export function useUserLocaleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserLocaleQuery, UserLocaleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserLocaleQuery, UserLocaleQueryVariables>(UserLocaleDocument, options);
        }
export function useUserLocaleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserLocaleQuery, UserLocaleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserLocaleQuery, UserLocaleQueryVariables>(UserLocaleDocument, options);
        }
export type UserLocaleQueryHookResult = ReturnType<typeof useUserLocaleQuery>;
export type UserLocaleLazyQueryHookResult = ReturnType<typeof useUserLocaleLazyQuery>;
export type UserLocaleSuspenseQueryHookResult = ReturnType<typeof useUserLocaleSuspenseQuery>;
export type UserLocaleQueryResult = Apollo.QueryResult<UserLocaleQuery, UserLocaleQueryVariables>;