/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LinksStructureBlockPartQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type LinksStructureBlockPartQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly isPrioritized: boolean, readonly lastCheckedAt: CK.Timestamp | null, readonly numberOfIncomingCanonicalLinks: number | null, readonly numberOfIncomingInternalLinks: number | null, readonly numberOfIncomingRedirects: number | null, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly numberOfOutgoingExternalLinks: number, readonly numberOfOutgoingInternalLinks: number } | null } | null };


export const LinksStructureBlockPartDocument = gql`
    query LinksStructureBlockPart($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    isPrioritized
    lastCheckedAt
    numberOfIncomingCanonicalLinks
    numberOfIncomingInternalLinks
    numberOfIncomingRedirects
    pageTypeData {
      numberOfOutgoingExternalLinks
      numberOfOutgoingInternalLinks
    }
  }
}
    `;

/**
 * __useLinksStructureBlockPartQuery__
 *
 * To run a query within a React component, call `useLinksStructureBlockPartQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksStructureBlockPartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksStructureBlockPartQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useLinksStructureBlockPartQuery(baseOptions: Apollo.QueryHookOptions<LinksStructureBlockPartQuery, LinksStructureBlockPartQueryVariables> & ({ variables: LinksStructureBlockPartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LinksStructureBlockPartQuery, LinksStructureBlockPartQueryVariables>(LinksStructureBlockPartDocument, options);
      }
export function useLinksStructureBlockPartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LinksStructureBlockPartQuery, LinksStructureBlockPartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LinksStructureBlockPartQuery, LinksStructureBlockPartQueryVariables>(LinksStructureBlockPartDocument, options);
        }
export function useLinksStructureBlockPartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LinksStructureBlockPartQuery, LinksStructureBlockPartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LinksStructureBlockPartQuery, LinksStructureBlockPartQueryVariables>(LinksStructureBlockPartDocument, options);
        }
export type LinksStructureBlockPartQueryHookResult = ReturnType<typeof useLinksStructureBlockPartQuery>;
export type LinksStructureBlockPartLazyQueryHookResult = ReturnType<typeof useLinksStructureBlockPartLazyQuery>;
export type LinksStructureBlockPartSuspenseQueryHookResult = ReturnType<typeof useLinksStructureBlockPartSuspenseQuery>;
export type LinksStructureBlockPartQueryResult = Apollo.QueryResult<LinksStructureBlockPartQuery, LinksStructureBlockPartQueryVariables>;