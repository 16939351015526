import React from 'react';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import useBuildTrackedChangesFilterDefinition from '~/hooks/useBuildTrackedChangesFilterDefinition';
import useCreateExport from '~/hooks/useCreateExport';
import useCurrentColumns from '~/hooks/useCurrentColumns';
import useEnabledWebsiteColumnsList from '~/hooks/useEnabledWebsiteColumnsList';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	getVisibleColumns,
} from '~/model/historicalChanges';

import {
	peekedColumnsSelector,
} from '~/state/changeTrackingPeekedColumns/selectors';

import {
	dateIntervalSelector,
	sortBySelector,
	totalSelector,
} from '~/state/historicalChanges/selectors';



function useExportTrackedChangesOnPages() {
	const websiteId = useWebsiteId();

	const buildTrackedChangesFilterDefinition = useBuildTrackedChangesFilterDefinition();
	const createExport = useCreateExport(websiteId);
	const currentColumns = useCurrentColumns();
	const dateInterval = useSelector(dateIntervalSelector);
	const enabledWebsiteColumnsList = useEnabledWebsiteColumnsList();
	const peekedColumns = useSelector(peekedColumnsSelector);
	const sortBy = useSelector(sortBySelector);
	const total = useSelector(totalSelector);

	const isLarge = total >= 200000;

	return React.useCallback(
		async () => {
			const fields = getVisibleColumns({
				allColumns: currentColumns.map((column) => column.name),
				enabledColumns: enabledWebsiteColumnsList,
				peekedColumns,
			});

			const filter = buildTrackedChangesFilterDefinition();

			const exportData = await createExport({
				criteria: {
					columns: fields,
					endDate: dateInterval.get('endDate'),
					filter: filter.toJS(),
					sortBy: sortBy.toJS(),
					startDate: dateInterval.get('startDate'),
				},
				type: GraphQL.ExportType.ChangeTracking,
			});

			return {
				...exportData,
				isLarge,
			};
		},
		[
			buildTrackedChangesFilterDefinition,
			createExport,
			currentColumns,
			dateInterval,
			enabledWebsiteColumnsList,
			isLarge,
			peekedColumns,
			sortBy,
		],
	);
}



export default useExportTrackedChangesOnPages;
