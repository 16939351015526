/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RequestAdminConfirmationQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type RequestAdminConfirmationQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string } }> } | null };


export const RequestAdminConfirmationDocument = gql`
    query RequestAdminConfirmation($accountId: AccountId!) {
  account(id: $accountId) {
    id
    memberships {
      account {
        id
      }
      user {
        email
        id
        legacyId
      }
    }
  }
}
    `;

/**
 * __useRequestAdminConfirmationQuery__
 *
 * To run a query within a React component, call `useRequestAdminConfirmationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRequestAdminConfirmationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRequestAdminConfirmationQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRequestAdminConfirmationQuery(baseOptions: Apollo.QueryHookOptions<RequestAdminConfirmationQuery, RequestAdminConfirmationQueryVariables> & ({ variables: RequestAdminConfirmationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RequestAdminConfirmationQuery, RequestAdminConfirmationQueryVariables>(RequestAdminConfirmationDocument, options);
      }
export function useRequestAdminConfirmationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RequestAdminConfirmationQuery, RequestAdminConfirmationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RequestAdminConfirmationQuery, RequestAdminConfirmationQueryVariables>(RequestAdminConfirmationDocument, options);
        }
export function useRequestAdminConfirmationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RequestAdminConfirmationQuery, RequestAdminConfirmationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RequestAdminConfirmationQuery, RequestAdminConfirmationQueryVariables>(RequestAdminConfirmationDocument, options);
        }
export type RequestAdminConfirmationQueryHookResult = ReturnType<typeof useRequestAdminConfirmationQuery>;
export type RequestAdminConfirmationLazyQueryHookResult = ReturnType<typeof useRequestAdminConfirmationLazyQuery>;
export type RequestAdminConfirmationSuspenseQueryHookResult = ReturnType<typeof useRequestAdminConfirmationSuspenseQuery>;
export type RequestAdminConfirmationQueryResult = Apollo.QueryResult<RequestAdminConfirmationQuery, RequestAdminConfirmationQueryVariables>;