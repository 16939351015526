import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BackButton from '~/components/app/BackButton';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import DecentText, {
	DecentTextAlignment,
	DecentTextSize,
} from '~/components/patterns/typography/DecentText';
import Spacer, {
	SpacerGap,
} from '~/components/patterns/utils/Spacer';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InternalLink, {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RadioList from '~/components/atoms/forms/components/RadioList';
import RichText from '~/components/patterns/typography/RichText';
import SubmitButton from '~/components/app/SubmitButton';
import TextField, {
	TextFieldAutocomplete,
	TextFieldType,
} from '~/components/atoms/forms/components/TextField';
import UnreliableResponseName from '~/components/names/UnreliableResponseName';

import {
	validateField,
} from '~/components/app/validations';

import {
	useCreateConnectedClientMutation,
} from './CreateNewAccountStep.gql';

import useAccountAccessRestrictions from '~/hooks/useAccountAccessRestrictions';
import useUrlState from '~/hooks/useUrlState';

import {
	type Values,
} from './types';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	accountNameLabel: {
		id: 'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.accountNameLabel',
	},
	continue: {
		id: 'ui.general.continue',
	},
	createWithoutUser: {
		id: 'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.createWithoutUser',
	},
	createWithoutUserDescription: {
		id: 'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.createWithoutUserDescription',
	},
	createWithUser: {
		id: 'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.createWithUser',
	},
	description: {
		id: 'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.description',
	},
	domainLabel: {
		id: 'ui.websites.form.domain',
	},
	emailLabel: {
		id: 'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.emailLabel',
	},
	formErrorAlreadyInYourAccount: {
		id: 'ui.websites.new.formErrors.alreadyInYourAccount',
	},
	formErrorAlreadyOnOtherAccounts: {
		id: 'ui.websites.new.formErrors.alreadyOnOtherAccounts',
	},
	formErrorBlacklisted: {
		id: 'ui.websites.new.formErrors.blacklisted',
	},
	formErrorEmailBelongsToExistingUser: {
		id: 'ui.accountAccess.createClient.formErrors.emailBelongsToExistingUser',
	},
	formErrorEmailBelongsToExistingUserLink: {
		id: 'ui.accountAccess.createClient.formErrors.emailBelongsToExistingUser.link',
	},
	formErrorEmailBelongsToYou: {
		id: 'ui.accountAccess.createClient.formErrors.emailBelongsToYou',
	},
	formErrorEmailBelongsToYourClient: {
		id: 'ui.accountAccess.createClient.formErrors.emailBelongsToYourClient',
	},
	formErrorEmailBelongsToYourClientLink: {
		id: 'ui.accountAccess.createClient.formErrors.emailBelongsToYourClient.link',
	},
	formErrorEmailBelongsToYourTeamMember: {
		id: 'ui.accountAccess.createClient.formErrors.emailBelongsToYourTeamMember',
	},
	formErrorRedirect: {
		id: 'ui.websites.new.formErrors.redirect',
	},
	formErrorServerError: {
		id: 'ui.general.serverError',
	},
	formErrorUnexpectedError: {
		id: 'ui.general.unexpectedError',
	},
	formErrorUnknownError: {
		id: 'ui.general.unknownError',
	},
	formErrorUnreachable: {
		id: 'ui.teamDetail.websites.add.errors.unreachable',
	},
	legalNotice: {
		id: 'ui.websites.new.legal.notice',
	},
	sidebar: {
		id: 'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.sidebar',
	},
	title: {
		id: 'ui.connectedAccounts.modal.connectAccount.createNewAccountStep.title',
	},
});



const validateDomain = validateField(
	'domain',
	(f) => [
		f.validateNonEmpty(),
		f.validateDomain(),
		f.customGlobal({
			message: (
				<FormattedMessage {...messages.formErrorAlreadyOnOtherAccounts} />
			),
			globalRule: 'website_already_monitored',
		}),
		f.customGlobal({
			message: (
				<FormattedMessage {...messages.formErrorAlreadyInYourAccount} />
			),
			globalRule: 'website_already_registered',
		}),
		f.customGlobal({
			message: (
				<FormattedMessage {...messages.formErrorBlacklisted} />
			),
			globalRule: 'blacklisted_domain',
		}),
		f.customGlobal({
			message: ({ globalError }) => (
				<FormattedMessage
					{...messages.formErrorUnreachable}
					values={{
						reason: (
							<UnreliableResponseName
								reason={globalError.getDetails().reason}
							/>
						),
					}}
				/>
			),
			globalRule: 'unresolvable_domain',
		}),
	],
);

const validateEmail = ({
	goToConnectExistingAccount,
	urlState,
}) => validateField(
	'email',
	(f) => [
		f.whenOtherField(
			'createAccountWithUser',
			({ value }) => value === true,
		),
		f.validateNonEmpty(),
		f.validateEmail(),
		f.customGlobal({
			message: (
				<FormattedMessage {...messages.formErrorEmailBelongsToYou} />
			),
			globalRule: 'client_email_used_by_you',
		}),
		f.customGlobal({
			message: (
				<FormattedMessage {...messages.formErrorEmailBelongsToYourTeamMember} />
			),
			globalRule: 'client_email_used_in_your_account',
		}),
		f.customGlobal({
			message: ({ globalError }) => (
				<FormattedMessage
					{...messages.formErrorEmailBelongsToYourClient}
					values={{
						text__link: (
							<InternalLink
								routeName={urlState.name}
								routeParams={Object.assign({}, urlState.params, {
									accountId: globalError.getDetails().clientAccountId,
								})}
								style={InternalLinkStyle.Decent}
							>
								<FormattedMessage {...messages.formErrorEmailBelongsToYourClientLink} />
							</InternalLink>
						),
					}}
				/>
			),
			globalRule: 'client_email_used_in_client_account',
		}),
		f.customGlobal({
			message: ({ values }) => (
				<FormattedMessage
					{...messages.formErrorEmailBelongsToExistingUser}
					values={{
						text__link: (
							<InternalLink
								onClickCallback={() => goToConnectExistingAccount(values)}
								style={InternalLinkStyle.Decent}
							>
								<FormattedMessage {...messages.formErrorEmailBelongsToExistingUserLink} />
							</InternalLink>
						),
					}}
				/>
			),
			globalRule: 'client_email_used_in_other_account',
		}),
	],
);

const validateAccountName = validateField(
	'accountName',
	(f) => [
		f.whenOtherField(
			'createAccountWithUser',
			({ value }) => value === false,
		),
		f.validateNonEmpty(),
		f.validateMaximumLength(24),
	],
);



type Props = {
	agencyAccountId: CK.AccountId | null,
	goToConnectExistingAccount: (values: Values) => void,
	onContinue: (values: Values) => void,
	values: Values,
};

const CreateNewAccountStep: React.FC<Props> = (props) => {
	const {
		agencyAccountId,
		goToConnectExistingAccount,
		onContinue,
		values: defaultValues,
	} = props;

	const accountAccessRestrictions = useAccountAccessRestrictions(agencyAccountId);
	const urlState = useUrlState();

	const canCreateAccountWithoutUsers = accountAccessRestrictions?.canCreateConnectedClientsWithoutUsers ?? false;

	const [createConnectedClient] = useCreateConnectedClientMutation();

	const handleSubmit = React.useCallback(
		async (values: Values) => {
			if (agencyAccountId === null || values.domain === undefined) {
				return;
			}

			try {
				await createConnectedClient({
					variables: {
						acceptedLegalDocuments: [GraphQL.LegalDocumentType.Woc],
						agencyId: agencyAccountId,
						clientAccountName: values.accountName || null,
						clientContactEmail: values.email || null,
						domain: values.domain,
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error, {
					'blacklisted_domain': values.domain,
					'target_redirected': values.domain,
					'website_already_monitored': values.domain,
					'website_already_registered': values.domain,
					'client_email_used_by_you': values.email,
					'client_email_used_in_client_account': values.email,
					'client_email_used_in_other_account': values.email,
					'client_email_used_in_your_account': values.email,
				});
			}

			onContinue(values);
		},
		[
			agencyAccountId,
			createConnectedClient,
			onContinue,
		],
	);

	const validations = React.useMemo(
		() => {
			return {
				validateAccountName,
				validateDomain,
				validateEmail: validateEmail({
					goToConnectExistingAccount,
					urlState,
				}),
			};
		},
		[
			goToConnectExistingAccount,
			urlState,
		],
	);

	return (
		<MultiStepModalStep
			name="createNewAccount"
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							link_article: linkExternal('https://www.contentkingapp.com/support/connected-accounts/'),
							canCreateAccountWithoutUsers: canCreateAccountWithoutUsers
								? 'yes'
								: 'no',
						}}
					/>
				</RichText>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Form
				clearOnFieldUnmount={false}
				defaultValues={{
					...defaultValues,
					accountName: '',
					createAccountWithUser: true,
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				{({ values }) => (
					<>
						<ModalTextSection>
							<FormattedMessage
								{...messages.description}
								values={{
									canCreateAccountWithoutUsers: canCreateAccountWithoutUsers
										? 'yes'
										: 'no',
								}}
							/>
							<Spacer top={SpacerGap.Small}>
								<DecentText
									alignment={DecentTextAlignment.Left}
									size={DecentTextSize.Small}
								>
									<FormattedMessage {...messages.legalNotice} />
								</DecentText>
							</Spacer>
						</ModalTextSection>

						<CenteredFormWrapper>
							<FormRow
								htmlFor="domain"
								label={(
									<FormattedMessage {...messages.domainLabel} />
								)}
							>
								<FieldStatus name="validateDomain">
									<TextField
										name="domain"
										placeholder="www.example.com"
										trimValue={true}
									/>
								</FieldStatus>
							</FormRow>
						</CenteredFormWrapper>

						{canCreateAccountWithoutUsers && (
							<>
								<ModalTextSection>
									<FormattedMessage {...messages.createWithoutUserDescription} />
								</ModalTextSection>

								<ModalTextSection>
									<RadioList
										items={[
											{
												label: <FormattedMessage {...messages.createWithUser} />,
												value: true,
											},
											{
												label: <FormattedMessage {...messages.createWithoutUser} />,
												value: false,
											},
										]}
										name="createAccountWithUser"
										width={false}
									/>
								</ModalTextSection>
							</>
						)}

						<CenteredFormWrapper>
							<FormRows>
								{values.createAccountWithUser && (
									<FormRow
										htmlFor="email"
										label={(
											<FormattedMessage {...messages.emailLabel} />
										)}
									>
										<FieldStatus name="validateEmail">
											<TextField
												autoComplete={TextFieldAutocomplete.Off}
												name="email"
												type={TextFieldType.Email}
											/>
										</FieldStatus>
									</FormRow>
								)}

								{!values.createAccountWithUser && (
									<FormRow
										htmlFor="accountName"
										label={(
											<FormattedMessage {...messages.accountNameLabel} />
										)}
									>
										<FieldStatus name="validateAccountName">
											<TextField name="accountName" />
										</FieldStatus>
									</FormRow>
								)}
							</FormRows>
						</CenteredFormWrapper>

						<CenteredFormWrapper>
							<ButtonsLayout
								alignment={ButtonsLayoutAlignment.Right}
								layout={ButtonsLayoutType.Steps}
							>
								<BackButton />

								<SubmitButton>
									<FormattedMessage {...messages.continue} />
								</SubmitButton>
							</ButtonsLayout>
						</CenteredFormWrapper>
					</>
				)}
			</Form>
		</MultiStepModalStep>
	);
};



export default CreateNewAccountStep;
