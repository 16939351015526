/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignupDefaultsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type SignupDefaultsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly billingCycle: GraphQL.Term, readonly currency: GraphQL.Currency | null, readonly defaultCountry: string, readonly isSignupOptimized: boolean, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pagesCount: number }> } | null };


export const SignupDefaultsDocument = gql`
    query SignupDefaults($accountId: AccountId!) {
  account(id: $accountId) {
    id
    billingCycle
    currency
    defaultCountry
    isSignupOptimized
    websites {
      id
      pagesCount
    }
  }
}
    `;

/**
 * __useSignupDefaultsQuery__
 *
 * To run a query within a React component, call `useSignupDefaultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSignupDefaultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSignupDefaultsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useSignupDefaultsQuery(baseOptions: Apollo.QueryHookOptions<SignupDefaultsQuery, SignupDefaultsQueryVariables> & ({ variables: SignupDefaultsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SignupDefaultsQuery, SignupDefaultsQueryVariables>(SignupDefaultsDocument, options);
      }
export function useSignupDefaultsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SignupDefaultsQuery, SignupDefaultsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SignupDefaultsQuery, SignupDefaultsQueryVariables>(SignupDefaultsDocument, options);
        }
export function useSignupDefaultsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SignupDefaultsQuery, SignupDefaultsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SignupDefaultsQuery, SignupDefaultsQueryVariables>(SignupDefaultsDocument, options);
        }
export type SignupDefaultsQueryHookResult = ReturnType<typeof useSignupDefaultsQuery>;
export type SignupDefaultsLazyQueryHookResult = ReturnType<typeof useSignupDefaultsLazyQuery>;
export type SignupDefaultsSuspenseQueryHookResult = ReturnType<typeof useSignupDefaultsSuspenseQuery>;
export type SignupDefaultsQueryResult = Apollo.QueryResult<SignupDefaultsQuery, SignupDefaultsQueryVariables>;