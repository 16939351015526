/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserHasTwoFactorAuthenticationQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserHasTwoFactorAuthenticationQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly hasTwoFactorAuthentication: boolean, readonly id: string, readonly legacyId: string } | null };


export const UserHasTwoFactorAuthenticationDocument = gql`
    query UserHasTwoFactorAuthentication($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    hasTwoFactorAuthentication
    id
    legacyId
  }
}
    `;

/**
 * __useUserHasTwoFactorAuthenticationQuery__
 *
 * To run a query within a React component, call `useUserHasTwoFactorAuthenticationQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasTwoFactorAuthenticationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasTwoFactorAuthenticationQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserHasTwoFactorAuthenticationQuery(baseOptions: Apollo.QueryHookOptions<UserHasTwoFactorAuthenticationQuery, UserHasTwoFactorAuthenticationQueryVariables> & ({ variables: UserHasTwoFactorAuthenticationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserHasTwoFactorAuthenticationQuery, UserHasTwoFactorAuthenticationQueryVariables>(UserHasTwoFactorAuthenticationDocument, options);
      }
export function useUserHasTwoFactorAuthenticationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserHasTwoFactorAuthenticationQuery, UserHasTwoFactorAuthenticationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserHasTwoFactorAuthenticationQuery, UserHasTwoFactorAuthenticationQueryVariables>(UserHasTwoFactorAuthenticationDocument, options);
        }
export function useUserHasTwoFactorAuthenticationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserHasTwoFactorAuthenticationQuery, UserHasTwoFactorAuthenticationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserHasTwoFactorAuthenticationQuery, UserHasTwoFactorAuthenticationQueryVariables>(UserHasTwoFactorAuthenticationDocument, options);
        }
export type UserHasTwoFactorAuthenticationQueryHookResult = ReturnType<typeof useUserHasTwoFactorAuthenticationQuery>;
export type UserHasTwoFactorAuthenticationLazyQueryHookResult = ReturnType<typeof useUserHasTwoFactorAuthenticationLazyQuery>;
export type UserHasTwoFactorAuthenticationSuspenseQueryHookResult = ReturnType<typeof useUserHasTwoFactorAuthenticationSuspenseQuery>;
export type UserHasTwoFactorAuthenticationQueryResult = Apollo.QueryResult<UserHasTwoFactorAuthenticationQuery, UserHasTwoFactorAuthenticationQueryVariables>;