/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthWidgetQueryVariables = GraphQL.Exact<{
  date: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type HealthWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly health: { readonly __typename?: 'WebsiteDashboardHealth', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: { readonly __typename?: 'WebsiteDashboardHealthData', readonly health: number } | null } } };


export const HealthWidgetDocument = gql`
    query HealthWidget($date: DateYMD!, $scope: String!, $websiteId: WebsiteId!) {
  dashboardData {
    health(date: $date, scope: $scope, websiteId: $websiteId) {
      id
      data {
        health
      }
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __useHealthWidgetQuery__
 *
 * To run a query within a React component, call `useHealthWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthWidgetQuery({
 *   variables: {
 *      date: // value for 'date'
 *      scope: // value for 'scope'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useHealthWidgetQuery(baseOptions: Apollo.QueryHookOptions<HealthWidgetQuery, HealthWidgetQueryVariables> & ({ variables: HealthWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthWidgetQuery, HealthWidgetQueryVariables>(HealthWidgetDocument, options);
      }
export function useHealthWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthWidgetQuery, HealthWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthWidgetQuery, HealthWidgetQueryVariables>(HealthWidgetDocument, options);
        }
export function useHealthWidgetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthWidgetQuery, HealthWidgetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthWidgetQuery, HealthWidgetQueryVariables>(HealthWidgetDocument, options);
        }
export type HealthWidgetQueryHookResult = ReturnType<typeof useHealthWidgetQuery>;
export type HealthWidgetLazyQueryHookResult = ReturnType<typeof useHealthWidgetLazyQuery>;
export type HealthWidgetSuspenseQueryHookResult = ReturnType<typeof useHealthWidgetSuspenseQuery>;
export type HealthWidgetQueryResult = Apollo.QueryResult<HealthWidgetQuery, HealthWidgetQueryVariables>;