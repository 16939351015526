import {
	differenceInCalendarDays,
	format,
	isSameDay,
	subDays,
} from 'date-fns';
import {
	TZDate,
} from '@date-fns/tz';
import Immutable, {
	List,
} from 'immutable';

import CK from '~/types/contentking';

import {
	get,
} from './api/API';

import {
	CANONICAL_TYPE_CROSSDOMAIN,
	CANONICAL_TYPE_INTERNAL_OTHER,
	CANONICAL_TYPE_INTERNAL_SELF,
	CANONICAL_TYPE_INVALID,
	CANONICAL_TYPE_NONE,
	CANONICAL_TYPE_NOT_APPLICABLE,
	TYPE_MISSING,
	TYPE_PAGE,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
	TYPE_UNREACHABLE,
} from './pages';

import {
	ColumnsWithTrackedChangesScope,
	PagesColumnsCategory,
	getColumnCategory,
	isColumn,
	isTrackedChangesColumn,
} from './pagesColumns';

import removeDefaultValues from '~/utilities/removeDefaultValues';
import {
	isString,
} from '~/utilities/typeCheck';



export const CHANGE_TYPE_ADDED = 'added';
export const CHANGE_TYPE_CHANGED = 'changed';
export const CHANGE_TYPE_OTHER = 'other';
export const CHANGE_TYPE_REDIRECTED = 'redirected';
export const CHANGE_TYPE_REMOVED = 'removed';

export const CHANGES_IN_ALL_SELECTED_COLUMNS = 'changes_in_all_selected_columns';
export const CHANGES_IN_ANY_COLUMN = 'changes_in_any_column';
export const CHANGES_IN_ANY_SELECTED_COLUMN = 'changes_in_any_selected_column';
export const CHANGES_IN_ANY_VISIBLE_COLUMN = 'changes_in_any_visible_column';

export const CHANGES_IN_OPERATOR_AND = 'and';
export const CHANGES_IN_OPERATOR_OR = 'or';

export const CHANGE_TYPES = [
	CHANGE_TYPE_ADDED,
	CHANGE_TYPE_CHANGED,
	CHANGE_TYPE_OTHER,
	CHANGE_TYPE_REDIRECTED,
	CHANGE_TYPE_REMOVED,
];



export const DEFAULT_FILTER = Immutable.fromJS({
	[CK.PagesCommonColumn.CanonicalType]: [
		CANONICAL_TYPE_INTERNAL_SELF,
		CANONICAL_TYPE_INTERNAL_OTHER,
		CANONICAL_TYPE_CROSSDOMAIN,
		CANONICAL_TYPE_INVALID,
		CANONICAL_TYPE_NONE,
		CANONICAL_TYPE_NOT_APPLICABLE,
	],
	[CK.PagesCommonColumn.ChangeType]: [
		CHANGE_TYPE_ADDED,
		CHANGE_TYPE_CHANGED,
		CHANGE_TYPE_REDIRECTED,
		CHANGE_TYPE_REMOVED,
	],
	changes_in: CHANGES_IN_ANY_COLUMN,
	changes_in_columns: null,
	[CK.PagesCommonColumn.IsDisallowedInRobotsTxt]: [
		false,
		true,
	],
	[CK.PagesCommonColumn.IsInSitemap]: [
		false,
		true,
	],
	[CK.PagesCommonColumn.IsIndexable]: [
		false,
		true,
	],
	[CK.PagesCommonColumn.IsIndexableDueToMetaRobots]: [
		'no',
		'not_applicable',
		'yes',
	],
	[CK.PagesCommonColumn.IsIndexableDueToXRobotsTag]: [
		'no',
		'not_applicable',
		'yes',
	],
	[CK.PagesCommonColumn.IsLinked]: [
		false,
		true,
	],
	[CK.PagesCommonColumn.IsSecured]: [
		false,
		true,
	],
	[CK.PagesCommonColumn.Segments]: {
		included_in: [],
		not_included_in: [],
		operator: 'and',
	},
	[CK.PagesCommonColumn.Type]: [
		TYPE_MISSING,
		TYPE_PAGE,
		TYPE_REDIRECT,
		TYPE_SERVER_ERROR,
		TYPE_UNREACHABLE,
	],
});

export const DEFAULT_SORT_BY = Immutable.fromJS({
	key: CK.PagesCommonColumn.Relevance,
	direction: false,
});



export function formatDateForBackend(
	date: Date | string,
): string {
	const zonedDate = new TZDate(date as Date, 'Europe/Amsterdam');

	return format(zonedDate, 'yyyy-MM-dd');
}



export function formatEndDateForBackend(
	startDate: Date | string,
	endDate: Date | string,
): string {
	const cleanStartDate = isString(startDate) ? new Date(startDate) : startDate;
	let cleanEndDate = isString(endDate) ? new Date(endDate) : endDate;

	const isOlderWeekly = (
		differenceInCalendarDays(new Date(), cleanStartDate) > 14
		&& !isSameDay(cleanEndDate, new Date())
		&& !isSameDay(cleanStartDate, cleanEndDate)
	);

	if (isOlderWeekly) {
		cleanEndDate = subDays(cleanEndDate, 1);
	}

	return formatDateForBackend(cleanEndDate);
}



export function getVisibleColumns({
	allColumns,
	enabledColumns,
	peekedColumns,
}: {
	allColumns: ReadonlyArray<CK.PagesColumn>,
	enabledColumns: any,
	peekedColumns: any,
}) {
	return [
		CK.PagesCommonColumn.ChangeType,
		...allColumns
			.filter((column) => enabledColumns.indexOf(column) !== -1 || peekedColumns.indexOf(column) !== -1)
			.filter((column) => isTrackedChangesColumn(column)),
	];
}



export function loadHistoricalChangesViaApi({ websiteId, period, params }) {
	params = Object.assign({}, params);

	if (period.type === 'interval') {
		params.period = period.start_date + ':' + period.end_date;
	}

	return get('/websites/' + websiteId + '/tracked_changes', params, {
		timeout: 60000,
	});
}



export function normalizeFilter(filter) {
	filter = filter.filter((value, field) => !isColumn(field) || ColumnsWithTrackedChangesScope.includes(field));

	filter = filter.filter((value) => value !== '');

	DEFAULT_FILTER.forEach((value, field) => {
		if (value instanceof List) {
			filter = filter.set(field, (filter.get(field) || value).sort());
		}
	});

	return filter.sortBy((value, key) => key);
}



export function normalizeSortBy(sortBy) {
	if (!ColumnsWithTrackedChangesScope.includes(sortBy.get('key'))) {
		return DEFAULT_SORT_BY;
	}

	return sortBy;
}



export function removeUnknownColumns() {
	return (column) => {
		return (
			ColumnsWithTrackedChangesScope.includes(column)
			|| getColumnCategory(column) === PagesColumnsCategory.CustomElements
		);
	};
}



export function removeDefaultFilterValues(activeFilters) {
	return removeDefaultValues(activeFilters, DEFAULT_FILTER);
}
