/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountAccessRestrictionsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountAccessRestrictionsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly accountAccessRestrictions: { readonly __typename?: 'AccountAccessRestrictions', readonly canCreateConnectedClientsWithoutUsers: boolean, readonly canModifyConnectionDirectionDuringTrial: boolean, readonly maximumAllowedConnectedTrialAccounts: number } } | null };


export const AccountAccessRestrictionsDocument = gql`
    query AccountAccessRestrictions($accountId: AccountId!) {
  account(id: $accountId) {
    accountAccessRestrictions {
      canCreateConnectedClientsWithoutUsers
      canModifyConnectionDirectionDuringTrial
      maximumAllowedConnectedTrialAccounts
    }
    id
  }
}
    `;

/**
 * __useAccountAccessRestrictionsQuery__
 *
 * To run a query within a React component, call `useAccountAccessRestrictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAccessRestrictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAccessRestrictionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountAccessRestrictionsQuery(baseOptions: Apollo.QueryHookOptions<AccountAccessRestrictionsQuery, AccountAccessRestrictionsQueryVariables> & ({ variables: AccountAccessRestrictionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountAccessRestrictionsQuery, AccountAccessRestrictionsQueryVariables>(AccountAccessRestrictionsDocument, options);
      }
export function useAccountAccessRestrictionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountAccessRestrictionsQuery, AccountAccessRestrictionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountAccessRestrictionsQuery, AccountAccessRestrictionsQueryVariables>(AccountAccessRestrictionsDocument, options);
        }
export function useAccountAccessRestrictionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountAccessRestrictionsQuery, AccountAccessRestrictionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountAccessRestrictionsQuery, AccountAccessRestrictionsQueryVariables>(AccountAccessRestrictionsDocument, options);
        }
export type AccountAccessRestrictionsQueryHookResult = ReturnType<typeof useAccountAccessRestrictionsQuery>;
export type AccountAccessRestrictionsLazyQueryHookResult = ReturnType<typeof useAccountAccessRestrictionsLazyQuery>;
export type AccountAccessRestrictionsSuspenseQueryHookResult = ReturnType<typeof useAccountAccessRestrictionsSuspenseQuery>;
export type AccountAccessRestrictionsQueryResult = Apollo.QueryResult<AccountAccessRestrictionsQuery, AccountAccessRestrictionsQueryVariables>;