import React from 'react';
import times from 'lodash/times';

import NewTable from '~/components/patterns/tables/newTable/NewTable';
import NewTableRow, {
	NewTableRowType,
} from '~/components/patterns/tables/newTable/NewTableRow';
import NewTableCell from '~/components/patterns/tables/newTable/NewTableCell';
import PureRadioFields, {
	SIZE_SMALL as RADIO_FIELDS_SIZE_SMALL,
} from '~/components/atoms/forms/components/builders/PureRadioFields';



type Props = {
	/** Data displayed in the table */
	data: Array<{
		/** First column */
		label: React.ReactNode,
		/** Another columns in table with attached checkbox in header. Key of value has to be the same as value of option */
		values: Record<string, React.ReactNode>,
	}>,
	isControlled?: boolean,
	name: string,
	onBlurCallback?: () => void,
	onChangeCallback?: (value: string) => void,
	onFocusCallback?: () => void,
	/** List of data for every option */
	options: Array<{
		disabled?: boolean,
		checked?: boolean,
		label: React.ReactNode,
		/** Possibility to hide radio element */
		showRadioElement?: boolean,
		value: string,
	}>,
};



const RadioTableField: React.FC<Props> = (props) => {
	const {
		data,
		isControlled,
		name,
		onBlurCallback,
		onChangeCallback,
		onFocusCallback,
		options,
	} = props;

	return (
		<PureRadioFields
			isControlled={isControlled}
			items={options}
			name={name}
			onBlurCallback={onBlurCallback}
			onChangeCallback={onChangeCallback}
			onFocusCallback={onFocusCallback}
			size={RADIO_FIELDS_SIZE_SMALL}
			stretchedFields={true}
		>
			{({ fields }) => {
				const highlightedColumnIndex = fields.findIndex((element) => element.isChecked);
				const columnsWidth = [
					'35%',
					...times(options.length, () => 'auto'),
				] as Array<`${number}%` | 'auto'>;

				return (
					<NewTable
						columnWidths={columnsWidth}
						headerRow={(
							<NewTableRow
								id="header"
								type={NewTableRowType.Header}
							>
								<NewTableCell />
								{fields.map((field, index) => (
									<NewTableCell
										centered={true}
										highlighted={index === highlightedColumnIndex}
										key={index}
									>
										{field.field}
									</NewTableCell>
								))}
							</NewTableRow>
						)}
						headerRowHeight={64}
						isSortable={false}
						rows={data}
					>
						{({ row, rowIndex }) => (
							<NewTableRow id={rowIndex}>
								<NewTableCell>{row.label}</NewTableCell>

								{options.map((option, index) => (
									<NewTableCell
										centered={true}
										highlighted={index === highlightedColumnIndex}
										key={index}
									>
										{row.values[option.value]}
									</NewTableCell>
								))}
							</NewTableRow>
						)}
					</NewTable>
				);
			}}
		</PureRadioFields>
	);
};



export default RadioTableField;
