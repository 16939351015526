/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllWebsitesQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type AllWebsitesQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly connectedClientAccounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly displayName: string, readonly id: CK.WebsiteId, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId } | null }> }> } | null };


export const AllWebsitesDocument = gql`
    query AllWebsites {
  authenticatedSession {
    accounts {
      connectedClientAccounts {
        id
      }
      id
      websites {
        account {
          id
        }
        displayName
        id
      }
    }
  }
}
    `;

/**
 * __useAllWebsitesQuery__
 *
 * To run a query within a React component, call `useAllWebsitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWebsitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWebsitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllWebsitesQuery(baseOptions?: Apollo.QueryHookOptions<AllWebsitesQuery, AllWebsitesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllWebsitesQuery, AllWebsitesQueryVariables>(AllWebsitesDocument, options);
      }
export function useAllWebsitesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllWebsitesQuery, AllWebsitesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllWebsitesQuery, AllWebsitesQueryVariables>(AllWebsitesDocument, options);
        }
export function useAllWebsitesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllWebsitesQuery, AllWebsitesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllWebsitesQuery, AllWebsitesQueryVariables>(AllWebsitesDocument, options);
        }
export type AllWebsitesQueryHookResult = ReturnType<typeof useAllWebsitesQuery>;
export type AllWebsitesLazyQueryHookResult = ReturnType<typeof useAllWebsitesLazyQuery>;
export type AllWebsitesSuspenseQueryHookResult = ReturnType<typeof useAllWebsitesSuspenseQuery>;
export type AllWebsitesQueryResult = Apollo.QueryResult<AllWebsitesQuery, AllWebsitesQueryVariables>;