/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GoogleSearchConsoleBlockQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type GoogleSearchConsoleBlockQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly googleSearchConsoleMissingDataReason: GraphQL.PageThirdPartyDataMissingReason | null, readonly googleSearchConsolePerformanceReportLink: string | null, readonly googleSearchConsoleData: { readonly __typename?: 'PageGoogleSearchConsoleData', readonly clicks: number, readonly ctr: CK.Percentage, readonly impressions: number, readonly position: number, readonly dateRange: { readonly __typename?: 'DateRange', readonly since: CK.DateYMD, readonly until: CK.DateYMD } } | null } | null };


export const GoogleSearchConsoleBlockDocument = gql`
    query GoogleSearchConsoleBlock($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    googleSearchConsoleData {
      clicks
      ctr
      dateRange {
        since
        until
      }
      impressions
      position
    }
    googleSearchConsoleMissingDataReason
    googleSearchConsolePerformanceReportLink
  }
}
    `;

/**
 * __useGoogleSearchConsoleBlockQuery__
 *
 * To run a query within a React component, call `useGoogleSearchConsoleBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleSearchConsoleBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleSearchConsoleBlockQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useGoogleSearchConsoleBlockQuery(baseOptions: Apollo.QueryHookOptions<GoogleSearchConsoleBlockQuery, GoogleSearchConsoleBlockQueryVariables> & ({ variables: GoogleSearchConsoleBlockQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoogleSearchConsoleBlockQuery, GoogleSearchConsoleBlockQueryVariables>(GoogleSearchConsoleBlockDocument, options);
      }
export function useGoogleSearchConsoleBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoogleSearchConsoleBlockQuery, GoogleSearchConsoleBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoogleSearchConsoleBlockQuery, GoogleSearchConsoleBlockQueryVariables>(GoogleSearchConsoleBlockDocument, options);
        }
export function useGoogleSearchConsoleBlockSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GoogleSearchConsoleBlockQuery, GoogleSearchConsoleBlockQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GoogleSearchConsoleBlockQuery, GoogleSearchConsoleBlockQueryVariables>(GoogleSearchConsoleBlockDocument, options);
        }
export type GoogleSearchConsoleBlockQueryHookResult = ReturnType<typeof useGoogleSearchConsoleBlockQuery>;
export type GoogleSearchConsoleBlockLazyQueryHookResult = ReturnType<typeof useGoogleSearchConsoleBlockLazyQuery>;
export type GoogleSearchConsoleBlockSuspenseQueryHookResult = ReturnType<typeof useGoogleSearchConsoleBlockSuspenseQuery>;
export type GoogleSearchConsoleBlockQueryResult = Apollo.QueryResult<GoogleSearchConsoleBlockQuery, GoogleSearchConsoleBlockQueryVariables>;