import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	BenefitSection,
} from '~/model/benefits';



const messages = defineMessages({
	[BenefitSection.AccessControl]: {
		id: 'ui.planBenefits.section.accessControl.heading',
	},
	[BenefitSection.AdvancedAnalysis]: {
		id: 'ui.planBenefits.section.advancedAnalysis.heading',
	},
	[BenefitSection.AdvancedFeatures]: {
		id: 'ui.planBenefits.section.advancedFeatures.heading',
	},
	[BenefitSection.Collaboration]: {
		id: 'ui.planBenefits.section.collaboration.heading',
	},
	[BenefitSection.MainFeatures]: {
		id: 'ui.planBenefits.section.MainFeatures.heading',
	},
	[BenefitSection.Integrations]: {
		id: 'ui.planBenefits.section.integrations.heading',
	},
	[BenefitSection.MonitoringSettings]: {
		id: 'ui.planBenefits.section.monitoringSettings.heading',
	},
	[BenefitSection.Services]: {
		id: 'ui.planBenefits.section.services.heading',
	},
});



type Props = {
	section: BenefitSection,
};

const BenefitSectionName: React.FC<Props> = (props) => {
	const {
		section,
	} = props;

	return (
		<FormattedMessage {...messages[section]} />
	);
};



export default BenefitSectionName;
