import React from 'react';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import useCreateExport from '~/hooks/useCreateExport';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	currentDataSelector,
	filterSelector,
	sortBySelector,
} from '~/state/sitemapsList/selectors';



function useExportSitemaps() {
	const websiteId = useWebsiteId();

	const createExport = useCreateExport(websiteId);
	const filter = useSelector(filterSelector);
	const sortBy = useSelector(sortBySelector);
	const totalCount = useSelector(currentDataSelector).get('total');

	const isLarge = totalCount >= 200000;

	return React.useCallback(
		async () => {
			const exportData = await createExport({
				criteria: {
					filter: filter.toJS(),
					sortBy: sortBy.toJS(),
				},
				type: GraphQL.ExportType.SitemapsList,
			});

			return {
				...exportData,
				isLarge,
			};
		},
		[
			createExport,
			filter,
			isLarge,
			sortBy,
		],
	);
}



export default useExportSitemaps;
