import React from 'react';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import useAccountBillingEntity from '~/hooks/useAccountBillingEntity';
import useAccountHasLegacyBillingDetails from '~/hooks/useAccountHasLegacyBillingDetails';

import {
	loadTeamBillingDetails,
} from '~/actions/teams';

import {
	teamsBillingSelector,
} from '~/state/teams/selectors';



function useLegacyBillingDetails(accountId: CK.AccountId | null) {
	const accountBillingEntity = useAccountBillingEntity(accountId);
	const accountHasLegacyBillingDetails = useAccountHasLegacyBillingDetails(accountId) ?? false;
	const allBillingDetails = useSelector(teamsBillingSelector);
	const dispatch = useDispatch();

	const billingDetails = (accountId !== null && allBillingDetails)
		? allBillingDetails.get(accountId + '')
		: null;

	React.useEffect(
		() => {
			if (accountHasLegacyBillingDetails && accountBillingEntity === 'old') {
				dispatch(
					loadTeamBillingDetails(
						accountId,
					),
				);
			}
		},
		[
			accountBillingEntity,
			accountHasLegacyBillingDetails,
			accountId,
			dispatch,
		],
	);

	return billingDetails;
}



export default useLegacyBillingDetails;
