export const LEVEL_MAKE_CHANGES = 'make_changes';
export const LEVEL_OWNER = 'owner';
export const LEVEL_READ_ONLY = 'read_only';



export enum ConnectedClientsStatus {
	Connected = 'connected',
	AccessRequested = 'accessRequested',
	AccessOffered = 'accessOffered',
}



export function determineConnectionStatus(connection: {
	isPending: boolean,
	isInitiatedByAgency: boolean,
}): ConnectedClientsStatus {
	if (connection.isPending) {
		return connection.isInitiatedByAgency
			? ConnectedClientsStatus.AccessRequested
			: ConnectedClientsStatus.AccessOffered;
	}

	return ConnectedClientsStatus.Connected;
}
