/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type OutgoingInternalLinksQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  limit: GraphQL.Scalars['Int']['input'];
  offset: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type OutgoingInternalLinksQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly legacyId: number | null, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly outgoingInternalLinks: { readonly __typename?: 'PageOutgoingInternalLinksConnection', readonly totalCount: number, readonly edges: ReadonlyArray<{ readonly __typename?: 'PageOutgoingInternalLinksConnectionEdge', readonly cursor: any, readonly loadedAt: number, readonly node: { readonly __typename?: 'PageOutgoingInternalLinksConnectionNode', readonly anchorText: string, readonly anchorTitle: string | null, readonly id: CK.ID, readonly targetId: CK.UrlId, readonly targetLegacyId: number | null, readonly targetStatus: GraphQL.PageOutgoingInternalLinkStatus, readonly targetStatusCode: number | null, readonly targetUrl: string } } | null> } | null } | null } | null };


export const OutgoingInternalLinksDocument = gql`
    query OutgoingInternalLinks($legacyUrlId: Int!, $limit: Int!, $offset: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    legacyId
    pageTypeData {
      outgoingInternalLinks(limit: $limit, offset: $offset) {
        edges {
          cursor
          loadedAt @client
          node {
            anchorText
            anchorTitle
            id
            targetId
            targetLegacyId
            targetStatus
            targetStatusCode
            targetUrl
          }
        }
        totalCount
      }
    }
  }
}
    `;

/**
 * __useOutgoingInternalLinksQuery__
 *
 * To run a query within a React component, call `useOutgoingInternalLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useOutgoingInternalLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOutgoingInternalLinksQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useOutgoingInternalLinksQuery(baseOptions: Apollo.QueryHookOptions<OutgoingInternalLinksQuery, OutgoingInternalLinksQueryVariables> & ({ variables: OutgoingInternalLinksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OutgoingInternalLinksQuery, OutgoingInternalLinksQueryVariables>(OutgoingInternalLinksDocument, options);
      }
export function useOutgoingInternalLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OutgoingInternalLinksQuery, OutgoingInternalLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OutgoingInternalLinksQuery, OutgoingInternalLinksQueryVariables>(OutgoingInternalLinksDocument, options);
        }
export function useOutgoingInternalLinksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<OutgoingInternalLinksQuery, OutgoingInternalLinksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<OutgoingInternalLinksQuery, OutgoingInternalLinksQueryVariables>(OutgoingInternalLinksDocument, options);
        }
export type OutgoingInternalLinksQueryHookResult = ReturnType<typeof useOutgoingInternalLinksQuery>;
export type OutgoingInternalLinksLazyQueryHookResult = ReturnType<typeof useOutgoingInternalLinksLazyQuery>;
export type OutgoingInternalLinksSuspenseQueryHookResult = ReturnType<typeof useOutgoingInternalLinksSuspenseQuery>;
export type OutgoingInternalLinksQueryResult = Apollo.QueryResult<OutgoingInternalLinksQuery, OutgoingInternalLinksQueryVariables>;