/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateExportMutationVariables = GraphQL.Exact<{
  criteria: GraphQL.CreateExportCriteria;
  type: GraphQL.ExportType;
  url: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CreateExportMutation = { readonly __typename?: 'Mutation', readonly CreateExport: { readonly __typename?: 'CreateExportResult', readonly exportDownloadUrl: string, readonly exportFilename: string, readonly exportId: number } };


export const CreateExportDocument = gql`
    mutation CreateExport($criteria: CreateExportCriteria!, $type: ExportType!, $url: String!, $websiteId: WebsiteId!) {
  CreateExport(criteria: $criteria, type: $type, url: $url, websiteId: $websiteId) {
    exportDownloadUrl
    exportFilename
    exportId
  }
}
    `;
export type CreateExportMutationFn = Apollo.MutationFunction<CreateExportMutation, CreateExportMutationVariables>;

/**
 * __useCreateExportMutation__
 *
 * To run a mutation, you first call `useCreateExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExportMutation, { data, loading, error }] = useCreateExportMutation({
 *   variables: {
 *      criteria: // value for 'criteria'
 *      type: // value for 'type'
 *      url: // value for 'url'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useCreateExportMutation(baseOptions?: Apollo.MutationHookOptions<CreateExportMutation, CreateExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExportMutation, CreateExportMutationVariables>(CreateExportDocument, options);
      }
export type CreateExportMutationHookResult = ReturnType<typeof useCreateExportMutation>;
export type CreateExportMutationResult = Apollo.MutationResult<CreateExportMutation>;
export type CreateExportMutationOptions = Apollo.BaseMutationOptions<CreateExportMutation, CreateExportMutationVariables>;