import classNames from 'classnames';
import React from 'react';

import AuthTitle from '~/components/atoms/auth/AuthTitle';



export type AuthFormLayoutRef = {
	shake: (duration?: number) => void,
};

type Props = {
	children?: React.ReactNode,
	header?: React.ReactNode,
	title?: React.ReactNode,
};

const AuthFormLayout = React.forwardRef<AuthFormLayoutRef, Props>((props, ref) => {
	const {
		children,
		header,
		title,
	} = props;

	const [isShaking, setShaking] = React.useState(false);
	const shakingTimeout = React.useRef<any>();

	const shake = React.useCallback(
		(duration = 1000) => {
			setShaking(true);

			clearTimeout(shakingTimeout.current);

			shakingTimeout.current = setTimeout(() => {
				setShaking(false);
			}, duration);
		},
		[],
	);

	React.useImperativeHandle(
		ref,
		() => ({
			shake,
		}),
	);

	const componentClasses = classNames({
		'auth-form': true,
		'auth-form--shake': isShaking,
		'dark-bg-context': true,
	});

	return (
		<div className={componentClasses}>
			{title && (
				<div className="auth-form__header">
					<AuthTitle>
						{title}
					</AuthTitle>
				</div>
			)}

			{header && (
				<div className="auth-form__header">
					{header}
				</div>
			)}

			{children}
		</div>
	);
});



export default AuthFormLayout;
