type Brand<t, n = string> = t & { __brand?: n };
type StrictBrand<t, n = string> = t & { __brand: n };



namespace CK {
	export type AccountId = Brand<number, 'AccountId'>;

	export type AlertDefinitionId = StrictBrand<string, 'AlertDefinitionId'>;

	export type ColumnSetId = Brand<number, 'ColumnSetId'>;

	/**
	 * Date in ISO 8601 format, i.e. YYYY-MM-DD.
	 */
	export type DateYMD = Brand<string, 'DateYMD'>;

	/**
	 * Number representing durations in seconds.
	 */
	export type DurationInSeconds = Brand<number, 'DurationInSeconds'>;

	export type ID = Brand<string, 'ID'>;

	export type InvitationId = Brand<string, 'InvitationId'>;

	/**
	 * Number typically between 0 and 100 where 100 equals the full amount. In
	 * contrast with the `Factor` type which range between 0 and 1 where 1 equals
	 * the full amount.
	 */
	export type Percentage = Brand<number, 'Percentage'>;

	/**
	 * Number typically between 0 and 1 where 1 equals the full amount. In
	 * contrast with the `Percentage` type which range between 0 and 100 where 100
	 * equals the full amount.
	 */
	export type Factor = Brand<number, 'Factor'>;

	export type FilterDefinition = Partial<Record<PagesColumn | 'static', any>>;

	export type PageSegmentId = Brand<string, 'PageSegmentId'>;

	export type PageSegmentName = Brand<string, 'PageSegmentName'>;

	export type PageSegmentSizeLimit = {
		field: string,
		limit: {
			numberOfPages: number,
			type: 'numberOfPages',
		} | {
			percentage: number,
			type: 'percentage',
		},
		order: 'asc' | 'desc',
	};

	export enum PagesCommonColumn {
		AaAverageTimeSpentOnSite = 'aa_average_time_spent_on_site',
		AaBounceRate = 'aa_bounce_rate',
		AaPageViews = 'aa_page_views',
		AaRevenue = 'aa_revenue',
		AaUniqueVisitors = 'aa_unique_visitors',
		AnalyticsServices = 'analytics_services',
		CanonicalLinkElement = 'canonical_link_element',
		CanonicalLinkTarget = 'canonical_link_target',
		CanonicalType = 'canonical_type',
		CanonicalUrl = 'canonical_url',
		ChangeType = 'change_type',
		Domain = 'domain',
		GaActiveUsers = 'ga_v4_active_users',
		GaAverageEngagementTime = 'ga_v4_average_engagement_time',
		GaAverageTime = 'ga_average_time',
		GaBounceRate = 'ga_bounce_rate',
		GaEngagementRate = 'ga_v4_engagement_rate',
		GaPageValue = 'ga_page_value',
		GaPageViews = 'ga_page_views',
		GaScreenPageViews = 'ga_v4_screen_page_views',
		GaUniquePageViews = 'ga_unique_page_views',
		GscClicks = 'gsc_clicks',
		GscCtr = 'gsc_ctr',
		GscImpressions = 'gsc_impressions',
		GscPosition = 'gsc_position',
		H1 = 'h1',
		Health = 'health',
		HreflangChanges = 'hreflang_changes',
		HreflangLanguage = 'hreflang_language',
		IsDisallowedInRobotsTxt = 'is_disallowed_in_robots_txt',
		IsInSitemap = 'is_in_sitemap',
		IsIndexable = 'is_indexable',
		IsIndexableDueToMetaRobots = 'is_indexable_due_to_meta_robots',
		IsIndexableDueToXRobotsTag = 'is_indexable_due_to_x_robots_tag',
		IsLinked = 'is_linked',
		IsSecured = 'is_secured',
		IssueChange = 'issue_change',
		LfaBingDesktopFrequency = 'lfa_bing_desktop_frequency',
		LfaBingDesktopLastVisit = 'lfa_bing_desktop_last_visit',
		LfaBingFrequency = 'lfa_bing_frequency',
		LfaBingLastVisit = 'lfa_bing_last_visit',
		LfaBingMobileFrequency = 'lfa_bing_mobile_frequency',
		LfaBingMobileLastVisit = 'lfa_bing_mobile_last_visit',
		LfaGoogleDesktopFrequency = 'lfa_google_desktop_frequency',
		LfaGoogleDesktopLastVisit = 'lfa_google_desktop_last_visit',
		LfaGoogleFrequency = 'lfa_google_frequency',
		LfaGoogleLastVisit = 'lfa_google_last_visit',
		LfaGoogleMobileFrequency = 'lfa_google_mobile_frequency',
		LfaGoogleMobileLastVisit = 'lfa_google_mobile_last_visit',
		LighthouseCumulativeLayoutShift = 'lighthouse_cls',
		LighthouseFirstContentfulPaint = 'lighthouse_fcp',
		LighthouseLargestContentfulPaint = 'lighthouse_lcp',
		LighthousePerformance = 'lighthouse_performance',
		LighthouseSpeedIndex = 'lighthouse_si',
		LighthouseTimeToInteractive = 'lighthouse_tti',
		LighthouseTotalBlockingTime = 'lighthouse_tbt',
		LinkAmp = 'link_amp',
		LinkNext = 'link_next',
		LinkPrev = 'link_prev',
		MetaDescription = 'meta_description',
		MobileVariant = 'mobile_variant',
		NumberOfHreflangs = 'number_of_hreflangs',
		NumberOfImagesWithMissingAlt = 'images_with_broken_alt',
		NumberOfImagesWithMixedTransport = 'images_with_mixed_transport',
		NumberOfIncomingInternalCanonicals = 'number_of_incoming_internal_canonicals',
		NumberOfIncomingInternalLinks = 'number_of_incoming_internal_links',
		NumberOfIncomingInternalRedirects = 'number_of_incoming_internal_redirects',
		NumberOfLinksToBrokenPages = 'links_to_missing',
		NumberOfLinksToCanonicalizedPages = 'links_to_canonicalized',
		NumberOfLinksToRedirectedPages = 'links_to_redirects',
		NumberOfOutgoingExternalLinks = 'number_of_outgoing_external_links',
		NumberOfOutgoingInternalLinks = 'number_of_outgoing_internal_links',
		OpenGraphDescription = 'open_graph_description',
		OpenGraphImage = 'open_graph_image',
		OpenGraphTitle = 'open_graph_title',
		OpenGraphType = 'open_graph_type',
		OpenGraphUrl = 'open_graph_url',
		Redirect = 'redirect',
		Relevance = 'relevance',
		SchemaOrgNumberOfTypes = 'schema_org_number_of_types',
		SchemaOrgTypes = 'schema_org_types',
		Segments = 'segments',
		StatusCode = 'status_code',
		TagManagers = 'tag_managers',
		TimeFound = 'time_found',
		TimeServerResponse = 'time_server_response',
		Title = 'title',
		TwitterCard = 'twitter_card',
		TwitterDescription = 'twitter_description',
		TwitterImage = 'twitter_image',
		TwitterSite = 'twitter_site',
		TwitterTitle = 'twitter_title',
		Type = 'type',
		Url = 'url',
		UrlDepth = 'url_depth',
		UrlFull = 'url_full',
		VisualAnalyticsServices = 'visual_analytics_services',
		XmlSitemapStatus = 'xml_sitemap_status',
	}

	export type PagesCustomElementColumn = `custom_${string}`;
	export type PagesEnrichmentFieldColumn = `ef_${string}`;

	export type PagesColumn = (
		| PagesCommonColumn
		| PagesCustomElementColumn
		| PagesEnrichmentFieldColumn
	);

	export type TemporaryItemsData = Array<{
		id: string,
		data: Record<string, any>,
	}>;

	export type TemporaryItemsRange = Array<{
		itemId: string,
		loadedAt: number,
		offset: number,
		sort: string,
	}>;

	/**
	 * Formatted date string in ISO 8601 format. e.g. `2021-01-14T20:23:40+00:00`.
	 */
	export type Timestamp = Brand<string, 'Timestamp'>;

	export type UrlId = Brand<string, 'UrlId'>;

	export type UserId = StrictBrand<string, 'UserId'>;

	/**
	 * Unique identifier for website.
	 */
	export type WebsiteId = Brand<string, 'WebsiteId'>;

	export type MutationError = {
		code: string,
		description: string,
		payload: any,
	};
}



declare global {
	interface Window {
		ContentKing: any,
	}
}



export default CK;
