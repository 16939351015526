/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TestCustomElementExtractionMutationVariables = GraphQL.Exact<{
  extraction: GraphQL.Scalars['Array']['input'];
  url: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type TestCustomElementExtractionMutation = { readonly __typename?: 'Mutation', readonly TestCustomElementExtraction: { readonly __typename?: 'TestCustomElementExtractionResult', readonly dataType: GraphQL.CustomElementDataType, readonly extractedValue: any | null, readonly url: string } };


export const TestCustomElementExtractionDocument = gql`
    mutation TestCustomElementExtraction($extraction: Array!, $url: String!, $websiteId: WebsiteId!) {
  TestCustomElementExtraction(
    extraction: $extraction
    url: $url
    websiteId: $websiteId
  ) {
    dataType
    extractedValue
    url
  }
}
    `;
export type TestCustomElementExtractionMutationFn = Apollo.MutationFunction<TestCustomElementExtractionMutation, TestCustomElementExtractionMutationVariables>;

/**
 * __useTestCustomElementExtractionMutation__
 *
 * To run a mutation, you first call `useTestCustomElementExtractionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCustomElementExtractionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCustomElementExtractionMutation, { data, loading, error }] = useTestCustomElementExtractionMutation({
 *   variables: {
 *      extraction: // value for 'extraction'
 *      url: // value for 'url'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useTestCustomElementExtractionMutation(baseOptions?: Apollo.MutationHookOptions<TestCustomElementExtractionMutation, TestCustomElementExtractionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestCustomElementExtractionMutation, TestCustomElementExtractionMutationVariables>(TestCustomElementExtractionDocument, options);
      }
export type TestCustomElementExtractionMutationHookResult = ReturnType<typeof useTestCustomElementExtractionMutation>;
export type TestCustomElementExtractionMutationResult = Apollo.MutationResult<TestCustomElementExtractionMutation>;
export type TestCustomElementExtractionMutationOptions = Apollo.BaseMutationOptions<TestCustomElementExtractionMutation, TestCustomElementExtractionMutationVariables>;