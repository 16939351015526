/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllWebsiteEmailSettingsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type AllWebsiteEmailSettingsQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly connectedClientAccounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId }>, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly displayName: string, readonly email: string, readonly hasLimitedWebsitesAccess: boolean, readonly id: CK.InvitationId, readonly role: GraphQL.UserRole, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly hasLimitedWebsitesAccess: boolean, readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly displayName: string, readonly email: string, readonly firstName: string | null, readonly id: string, readonly lastName: string | null, readonly legacyId: string, readonly uniqueId: CK.UserId, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }> }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> }> } | null };


export const AllWebsiteEmailSettingsDocument = gql`
    query AllWebsiteEmailSettings {
  authenticatedSession {
    accounts {
      connectedClientAccounts {
        id
      }
      id
      invitations {
        displayName
        email
        hasLimitedWebsitesAccess
        id
        role
        websiteEmailSettings {
          alertEmailsSetup
          isWeeklyReportEnabled
          websiteId
        }
        websites {
          id
        }
      }
      memberships {
        account {
          id
        }
        hasLimitedWebsitesAccess
        role
        user {
          displayName
          email
          firstName
          id
          lastName
          legacyId
          uniqueId
          websiteEmailSettings {
            alertEmailsSetup
            isWeeklyReportEnabled
            websiteId
          }
        }
        websites {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAllWebsiteEmailSettingsQuery__
 *
 * To run a query within a React component, call `useAllWebsiteEmailSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWebsiteEmailSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWebsiteEmailSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllWebsiteEmailSettingsQuery(baseOptions?: Apollo.QueryHookOptions<AllWebsiteEmailSettingsQuery, AllWebsiteEmailSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllWebsiteEmailSettingsQuery, AllWebsiteEmailSettingsQueryVariables>(AllWebsiteEmailSettingsDocument, options);
      }
export function useAllWebsiteEmailSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllWebsiteEmailSettingsQuery, AllWebsiteEmailSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllWebsiteEmailSettingsQuery, AllWebsiteEmailSettingsQueryVariables>(AllWebsiteEmailSettingsDocument, options);
        }
export function useAllWebsiteEmailSettingsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllWebsiteEmailSettingsQuery, AllWebsiteEmailSettingsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllWebsiteEmailSettingsQuery, AllWebsiteEmailSettingsQueryVariables>(AllWebsiteEmailSettingsDocument, options);
        }
export type AllWebsiteEmailSettingsQueryHookResult = ReturnType<typeof useAllWebsiteEmailSettingsQuery>;
export type AllWebsiteEmailSettingsLazyQueryHookResult = ReturnType<typeof useAllWebsiteEmailSettingsLazyQuery>;
export type AllWebsiteEmailSettingsSuspenseQueryHookResult = ReturnType<typeof useAllWebsiteEmailSettingsSuspenseQuery>;
export type AllWebsiteEmailSettingsQueryResult = Apollo.QueryResult<AllWebsiteEmailSettingsQuery, AllWebsiteEmailSettingsQueryVariables>;