/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountCurrencyQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountCurrencyQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly currency: GraphQL.Currency | null } | null };


export const AccountCurrencyDocument = gql`
    query AccountCurrency($accountId: AccountId!) {
  account(id: $accountId) {
    id
    currency
  }
}
    `;

/**
 * __useAccountCurrencyQuery__
 *
 * To run a query within a React component, call `useAccountCurrencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCurrencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCurrencyQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountCurrencyQuery(baseOptions: Apollo.QueryHookOptions<AccountCurrencyQuery, AccountCurrencyQueryVariables> & ({ variables: AccountCurrencyQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountCurrencyQuery, AccountCurrencyQueryVariables>(AccountCurrencyDocument, options);
      }
export function useAccountCurrencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountCurrencyQuery, AccountCurrencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountCurrencyQuery, AccountCurrencyQueryVariables>(AccountCurrencyDocument, options);
        }
export function useAccountCurrencySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountCurrencyQuery, AccountCurrencyQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountCurrencyQuery, AccountCurrencyQueryVariables>(AccountCurrencyDocument, options);
        }
export type AccountCurrencyQueryHookResult = ReturnType<typeof useAccountCurrencyQuery>;
export type AccountCurrencyLazyQueryHookResult = ReturnType<typeof useAccountCurrencyLazyQuery>;
export type AccountCurrencySuspenseQueryHookResult = ReturnType<typeof useAccountCurrencySuspenseQuery>;
export type AccountCurrencyQueryResult = Apollo.QueryResult<AccountCurrencyQuery, AccountCurrencyQueryVariables>;