import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import SimpleModal, {
	SimpleModalIconType,
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import WebsiteSettingsCookiesForm from '~/components/app/WebsiteSettingsCookiesForm';



const messages = defineMessages({
	title: {
		id: 'ui.websites.form.monitoring.cookies.modalTitle',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const CookiesModal: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	return (
		<SimpleModal
			alignToTop={true}
			iconType={SimpleModalIconType.CogWheel}
			size={SimpleModalSize.Large}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<WebsiteSettingsCookiesForm
				websiteId={websiteId}
			/>
		</SimpleModal>
	);
};



export default CookiesModal;
