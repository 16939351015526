/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountIsManagedQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountIsManagedQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isManaged: boolean } | null };


export const AccountIsManagedDocument = gql`
    query AccountIsManaged($accountId: AccountId!) {
  account(id: $accountId) {
    id
    isManaged
  }
}
    `;

/**
 * __useAccountIsManagedQuery__
 *
 * To run a query within a React component, call `useAccountIsManagedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountIsManagedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountIsManagedQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountIsManagedQuery(baseOptions: Apollo.QueryHookOptions<AccountIsManagedQuery, AccountIsManagedQueryVariables> & ({ variables: AccountIsManagedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountIsManagedQuery, AccountIsManagedQueryVariables>(AccountIsManagedDocument, options);
      }
export function useAccountIsManagedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountIsManagedQuery, AccountIsManagedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountIsManagedQuery, AccountIsManagedQueryVariables>(AccountIsManagedDocument, options);
        }
export function useAccountIsManagedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountIsManagedQuery, AccountIsManagedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountIsManagedQuery, AccountIsManagedQueryVariables>(AccountIsManagedDocument, options);
        }
export type AccountIsManagedQueryHookResult = ReturnType<typeof useAccountIsManagedQuery>;
export type AccountIsManagedLazyQueryHookResult = ReturnType<typeof useAccountIsManagedLazyQuery>;
export type AccountIsManagedSuspenseQueryHookResult = ReturnType<typeof useAccountIsManagedSuspenseQuery>;
export type AccountIsManagedQueryResult = Apollo.QueryResult<AccountIsManagedQuery, AccountIsManagedQueryVariables>;