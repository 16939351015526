import Immutable, {
	Map,
} from 'immutable';

import {
	LOGIN_SUCCESSFUL,
	LOGOUT_SUCCESSFUL,
	STORE_LOADED_TEAMS,
} from '~/actions';



function createDefaultState() {
	return new Map();
}



function createWebsiteEntity(website) {
	if (!(website instanceof Map)) {
		website = Immutable.fromJS(website);
	}

	website = website.remove('crawling_speed');
	website = website.remove('custom_elements');
	website = website.remove('segments');

	if (website.get('_already_entity')) {
		return website;
	}

	website = website.set(
		'id',
		website.get('id').toString(),
	);
	website = website.set(
		'team_id',
		website.get('team_id').toString(),
	);
	website = website.set(
		'displayName',
		website.get('name') || website.get('original_domain') || website.get('domain'),
	);

	website = website.set('_already_entity', true);

	return website;
}



function sortWebsites(unsortedWebsites) {
	return unsortedWebsites.sort((websiteA, websiteB) => {
		const displayNameA = websiteA.get('displayName').toLowerCase();
		const displayNameB = websiteB.get('displayName').toLowerCase();

		if (displayNameA < displayNameB) {
			return -1;
		}

		if (displayNameA > displayNameB) {
			return 1;
		}

		return 0;
	});
}



function processWebsites(state) {
	return state.map((team) => {
		team = team.remove('client_connections');
		team = team.remove('connected_agency');
		team = team.remove('connecting_agencies');

		return team.set('websites', sortWebsites(team.get('websites').map((website) => {
			return createWebsiteEntity(website);
		})));
	});
}



export const basics = function (state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	switch (action.type) {

		case LOGIN_SUCCESSFUL: {
			const {
				response,
			} = action;

			if (response.team) {
				state = processWebsites(state.mergeDeep({
					[response.team.id]: response.team,
				}));
			}

			break;
		}

		case LOGOUT_SUCCESSFUL: {
			state = createDefaultState();

			break;
		}

		case STORE_LOADED_TEAMS: {
			const {
				teams,
			} = action;

			const oldTeams = state;
			let newTeams = Immutable.fromJS(teams);
			newTeams = processWebsites(newTeams);

			if (!Immutable.is(oldTeams, newTeams)) {
				state = newTeams;
			}

			break;
		}

	}

	return state;
};
