import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CookiesModal from '../../modals/CookiesModal';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FetchingLocationFieldRow, {
	FetchingLocationFieldRowScope,
	validateFetchingLocation,
} from '~/components/app/FetchingLocationFieldRow';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import HelpHint from '~/components/patterns/hints/HelpHint';
import HttpHeadersModal from '../../modals/HttpHeadersModal';
import InternalLink from '~/components/patterns/links/InternalLink';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SetupHttpAuthenticationModal from '~/components/app/SetupHttpAuthenticationModal';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import SwitchField from '~/components/app/SwitchField';
import WithPermission from '~/components/logic/access/WithPermission';

import {
	useUpdateAdvancedWebsiteMonitoringSettingsMutation,
} from './WebsiteMonitoringAdvancedSettingsForm.gql';

import useOpenModal from '~/hooks/useOpenModal';
import useWebsiteCookies from '~/hooks/useWebsiteCookies';
import useWebsiteFetchingLocation from '~/hooks/useWebsiteFetchingLocation';
import useWebsiteHttpHeaders from '~/hooks/useWebsiteHttpHeaders';
import useWebsiteIsHttpAuthenticationEnabled from '~/hooks/useWebsiteIsHttpAuthenticationEnabled';

import {
	ObjectType,
} from '~/model/permissions';



const messages = defineMessages({
	cookiesConfigured: {
		id: 'ui.websites.form.monitoring.cookies.configured',
	},
	cookiesEdit: {
		id: 'ui.websites.form.monitoring.cookies.edit',
	},
	cookiesLabel: {
		id: 'ui.websites.form.monitoring.cookies.label',
	},
	enabled: {
		id: 'ui.general.enabled',
	},
	disabled: {
		id: 'ui.general.disabled',
	},
	httpAuthenticationEnabledAndChange: {
		id: 'ui.websites.form.monitoring.httpAuthentication.enabledAndChange',
	},
	httpAuthenticationConfigure: {
		id: 'ui.websites.form.monitoring.httpAuthentication.configure',
	},
	httpAuthenticationLabel: {
		id: 'ui.websites.form.monitoring.httpAuthentication.label',
	},
	httpAuthenticationHint: {
		id: 'ui.websites.form.monitoring.httpAuthentication.hint',
	},
	httpHeadersConfigured: {
		id: 'ui.websites.form.monitoring.httpHeaders.configured',
	},
	httpHeadersEdit: {
		id: 'ui.websites.form.monitoring.httpHeaders.edit',
	},
	httpHeadersLabel: {
		id: 'ui.websites.form.monitoring.httpHeaders.label',
	},
	title: {
		id: 'ui.websites.form.monitoring.titleAdvancedSettings',
	},
});

const validations = {
	validateFetchingLocation,
};



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteMonitoringAdvancedSettingsForm: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const websiteCookies = useWebsiteCookies(websiteId);
	const websiteFetchingLocation = useWebsiteFetchingLocation(websiteId);
	const websiteHttpHeaders = useWebsiteHttpHeaders(websiteId);
	const websiteIsHttpAuthenticationEnabled = useWebsiteIsHttpAuthenticationEnabled(websiteId);

	const [updateAdvancedWebsiteMonitoringSettings] = useUpdateAdvancedWebsiteMonitoringSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values: any) => {
			await updateAdvancedWebsiteMonitoringSettings({
				variables: {
					fetchingLocation: values.fetchingLocation,
					httpAuth: values.is_http_auth_enabled === false ? false : null,
					websiteId,
				},
			});
		},
		[
			updateAdvancedWebsiteMonitoringSettings,
			websiteId,
		],
	);

	const openCookiesModal = useOpenModal(
		() => (
			<CookiesModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const openHttpAuthModal = useOpenModal(
		() => (
			<SetupHttpAuthenticationModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	const openHttpHeadersModal = useOpenModal(
		() => (
			<HttpHeadersModal
				websiteId={websiteId}
			/>
		),
		[
			websiteId,
		],
	);

	function renderForm() {
		if (
			websiteCookies === null
			|| websiteFetchingLocation === null
			|| websiteHttpHeaders === null
			|| websiteIsHttpAuthenticationEnabled === null
		) {
			return null;
		}

		return (
			<Form
				defaultValues={{
					fetchingLocation: websiteFetchingLocation,
					is_http_auth_enabled: websiteIsHttpAuthenticationEnabled ? true : undefined,
				}}
				onSuccess={handleSubmit}
				validations={validations}
			>
				<FormRows>
					<FetchingLocationFieldRow
						fetchingLocation={websiteFetchingLocation}
						isEditable={true}
						scope={FetchingLocationFieldRowScope.Website}
					/>

					<FormRow
						label={(
							<AttachedElement
								element={(
									<HelpHint
										message={(
											<FormattedMessage {...messages.httpAuthenticationHint} />
										)}
									/>
								)}
							>
								<FormattedMessage {...messages.httpAuthenticationLabel} />
							</AttachedElement>
						)}
					>
						{websiteIsHttpAuthenticationEnabled ? (
							<SwitchField
								activeStateLabel={(
									<FormattedMessage
										{...messages.httpAuthenticationEnabledAndChange}
										values={{
											linkModal: (chunks) => (
												<InternalLink onClickCallback={openHttpAuthModal}>
													{chunks}
												</InternalLink>
											),
										}}
									/>
								)}
								inactiveStateLabel={(
									<FormattedMessage {...messages.disabled} />
								)}
								name="is_http_auth_enabled"
							/>
						) : (
							<StaticText>
								<InternalLink
									onClickCallback={openHttpAuthModal}
								>
									<FormattedMessage {...messages.httpAuthenticationConfigure} />
								</InternalLink>
							</StaticText>
						)}
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.cookiesLabel} />
						)}
					>
						<StaticText>
							<FormattedMessage
								{...messages.cookiesEdit}
								values={{
									count__cookies: websiteCookies.length,
									link_modal: (chunks) => (
										<InternalLink onClickCallback={openCookiesModal}>
											{chunks}
										</InternalLink>
									),
								}}
							/>
						</StaticText>
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.httpHeadersLabel} />
						)}
					>
						<StaticText>
							<FormattedMessage
								{...messages.httpHeadersEdit}
								values={{
									count__headers: websiteHttpHeaders.length,
									link_modal: (chunks) => (
										<InternalLink onClickCallback={openHttpHeadersModal}>
											{chunks}
										</InternalLink>
									),
								}}
							/>
						</StaticText>
					</FormRow>
				</FormRows>

				<ButtonsLayout>
					<CancelButton />
					<SaveSubmitButton />
				</ButtonsLayout>
			</Form>
		);
	}

	return (
		<WithPermission
			action={GraphQL.ActionWithWebsite.ManageMonitoringSettings}
			objectId={websiteId}
			objectType={ObjectType.Website}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<FormRows>
							<FetchingLocationFieldRow
								fetchingLocation={websiteFetchingLocation}
								isEditable={false}
								scope={FetchingLocationFieldRowScope.Website}
							/>

							<FormRow
								label={(
									<AttachedElement
										element={(
											<HelpHint
												message={(
													<FormattedMessage {...messages.httpAuthenticationHint} />
												)}
											/>
										)}
									>
										<FormattedMessage {...messages.httpAuthenticationLabel} />
									</AttachedElement>
								)}
							>
								<StaticText focusTarget="http_auth">
									{websiteIsHttpAuthenticationEnabled ? (
										<FormattedMessage {...messages.enabled} />
									) : websiteIsHttpAuthenticationEnabled === false ? (
										<FormattedMessage {...messages.disabled} />
									) : (
										<SquareSkeleton maxWidth={80} />
									)}
								</StaticText>
							</FormRow>

							<FormRow
								label={(
									<FormattedMessage {...messages.cookiesLabel} />
								)}
							>
								<StaticText>
									{websiteCookies !== null ? (
										<FormattedMessage
											{...messages.cookiesConfigured}
											values={{ count__cookies: websiteCookies.length }}
										/>
									) : (
										<SquareSkeleton maxWidth={80} />
									)}
								</StaticText>
							</FormRow>

							<FormRow
								label={(
									<FormattedMessage {...messages.httpHeadersLabel} />
								)}
							>
								<StaticText>
									{websiteHttpHeaders !== null ? (
										<FormattedMessage
											{...messages.httpHeadersConfigured}
											values={{ count__headers: websiteHttpHeaders.length }}
										/>
									) : (
										<SquareSkeleton maxWidth={80} />
									)}
								</StaticText>
							</FormRow>
						</FormRows>
					</DisplayPart>

					<EditablePart>
						{renderForm()}
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WithPermission>
	);
};



export default WebsiteMonitoringAdvancedSettingsForm;
