/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageImportanceQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageImportanceQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly importance: number } | null } | null };


export const PageImportanceDocument = gql`
    query PageImportance($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    pageTypeData {
      importance
    }
  }
}
    `;

/**
 * __usePageImportanceQuery__
 *
 * To run a query within a React component, call `usePageImportanceQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageImportanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageImportanceQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageImportanceQuery(baseOptions: Apollo.QueryHookOptions<PageImportanceQuery, PageImportanceQueryVariables> & ({ variables: PageImportanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageImportanceQuery, PageImportanceQueryVariables>(PageImportanceDocument, options);
      }
export function usePageImportanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageImportanceQuery, PageImportanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageImportanceQuery, PageImportanceQueryVariables>(PageImportanceDocument, options);
        }
export function usePageImportanceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PageImportanceQuery, PageImportanceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageImportanceQuery, PageImportanceQueryVariables>(PageImportanceDocument, options);
        }
export type PageImportanceQueryHookResult = ReturnType<typeof usePageImportanceQuery>;
export type PageImportanceLazyQueryHookResult = ReturnType<typeof usePageImportanceLazyQuery>;
export type PageImportanceSuspenseQueryHookResult = ReturnType<typeof usePageImportanceSuspenseQuery>;
export type PageImportanceQueryResult = Apollo.QueryResult<PageImportanceQuery, PageImportanceQueryVariables>;