import React from 'react';
import {
	useSortable,
} from '@dnd-kit/sortable';
import {
	CSS,
} from '@dnd-kit/utilities';
import classNames from 'classnames';

import type CK from '~/types/contentking';

import AbstractCheckboxField, {
	AbstractCheckboxFieldCheckedState,
} from '~/components/patterns/forms/fields/AbstractCheckboxField';
import DragHandler from '~/components/patterns/icons/DragHandler';

import styles from './ColumnsListItem.module.scss';
import {
	noop,
} from 'lodash';



type Props = {
	enabled: boolean,
	isDraggingBackground?: boolean,
	isSelectable?: boolean,
	isSortable?: boolean,
	label?: React.ReactNode,
	name: CK.PagesColumn,
	onSelect?: (column: CK.PagesColumn) => void,
};

const ColumnsListItem: React.FC<Props> = (props) => {
	const {
		enabled,
		isSelectable = true,
		isSortable = true,
		label,
		name,
		onSelect = noop,
	} = props;

	const {
		attributes,
		isDragging,
		listeners,
		setActivatorNodeRef,
		setNodeRef,
		transform,
		transition,
	} = useSortable({
		id: name,
		disabled: !isSortable,
	});

	const style: React.CSSProperties = {
		transform: isSortable ? CSS.Transform.toString(transform) : undefined,
		transition,
		opacity: isDragging ? 0.5 : 1,
	};

	return (
		<div
			className={styles.component}
			ref={setNodeRef}
			style={style}
		>
			<div className={styles.label}>
				<AbstractCheckboxField
					checkedState={(
						enabled
							? AbstractCheckboxFieldCheckedState.Checked
							: AbstractCheckboxFieldCheckedState.NotChecked
					)}
					isControlled={true}
					isDisabled={!isSelectable}
					isReadOnly={!isSelectable}
					label={label}
					name={name}
					onClick={() => {
						if (isSelectable) {
							onSelect(name);
						}
					}}
				/>
			</div>

			{isSortable && (
				<div
					className={styles.dragHandler}
					ref={setActivatorNodeRef}
					{...listeners}
					{...attributes}
				>
					<DragHandler />
				</div>
			)}
		</div>
	);
};



export default ColumnsListItem;



type DragOverlayColumnsListItemProps = Pick<Props, 'enabled' | 'isSelectable' | 'label' | 'name'>;

export const DragOverlayColumnsListItem: React.FC<DragOverlayColumnsListItemProps> = (props) => {
	const {
		enabled,
		isSelectable,
		label,
		name,
	} = props;

	const componentClasses = classNames({
		[styles.component]: true,
		[styles.isDragOverlay]: true,
	});

	return (
		<div
			className={componentClasses}
		>
			<div className={styles.label}>
				<AbstractCheckboxField
					checkedState={(
						enabled
							? AbstractCheckboxFieldCheckedState.Checked
							: AbstractCheckboxFieldCheckedState.NotChecked
					)}
					isControlled={true}
					isDisabled={!isSelectable}
					isReadOnly={true}
					label={label}
					name={name}
				/>
			</div>

			<div className={styles.dragHandler}>
				<DragHandler />
			</div>
		</div>
	);
};
