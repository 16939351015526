import React from 'react';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import useCreateExport from '~/hooks/useCreateExport';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	PageIssue,
} from '~/model/issuesNew';

import {
	totalSelector,
} from '~/state/affectedPagesComparison/currentWebsiteSelectors';

import {
	currentFilterSelector,
	sortBySelector,
} from '~/state/affectedPagesComparison/selectors';

import {
	issuesOverviewIssueSelector,
} from '~/state/ui/content/selectors';

import {
	dateFromSelector as historicalIssuesDateFromSelector,
	dateToSelector as historicalIssuesDateToSelector,
} from '~/state/websiteIssuesComparison/selectors';



function useExportHistoricalIssueAffectedPages() {
	const websiteId = useWebsiteId();

	const createExport = useCreateExport(websiteId);
	const endDate = useSelector(historicalIssuesDateToSelector);
	const filter = useSelector(currentFilterSelector);
	const issueType = useSelector(issuesOverviewIssueSelector);
	const sortBy = useSelector(sortBySelector);
	const startDate = useSelector(historicalIssuesDateFromSelector);
	const totalCount = useSelector(totalSelector);

	const largeExportThreshold = [
		PageIssue.ImagesAltAttribute,
		PageIssue.ImagesMixedTransport,
		PageIssue.LinksBroken,
		PageIssue.LinksRedirected,
		PageIssue.LinksToCanonicalized,
	].includes(issueType) ? 2000 : 200000;

	const isLarge = totalCount >= largeExportThreshold;

	return React.useCallback(
		async () => {
			const exportData = await createExport({
				criteria: {
					endDate,
					filter: filter.toJS(),
					issueType,
					sortBy: sortBy.toJS(),
					startDate,
				},
				type: GraphQL.ExportType.SingleIssueHistoricalAffectedPages,
			});

			return {
				...exportData,
				isLarge,
			};
		},
		[
			createExport,
			endDate,
			filter,
			isLarge,
			issueType,
			sortBy,
			startDate,
		],
	);
}



export default useExportHistoricalIssueAffectedPages;
