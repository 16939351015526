/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteAdminFetchingSettingsFormQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteAdminFetchingSettingsFormQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly domSizeLimit: number | null, readonly domStabilizationPeriod: number | null, readonly ipPools: ReadonlyArray<GraphQL.FetchingIpPool>, readonly lighthouseChromiumResourceCacheSizeLimit: number | null, readonly lighthouseFcpTimeout: number | null, readonly mainRequestSizeLimit: number | null, readonly mainRequestTimeout: number | null, readonly navigationTimeout: number | null, readonly onPageRequestsLimitLighthouseTotal: number | null, readonly onPageRequestsLimitLighthousePerDomain: number | null, readonly onPageRequestsLimitNormalTotal: number | null, readonly onPageRequestsLimitNormalPerDomain: number | null } | null } | null };

export type UpdateWebsiteAdminFetchingSettingsMutationVariables = GraphQL.Exact<{
  domSizeLimit: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  domStabilizationPeriod: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  ipPools: GraphQL.InputMaybe<ReadonlyArray<GraphQL.FetchingIpPool> | GraphQL.FetchingIpPool>;
  lighthouseChromiumResourceCacheSizeLimit: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  lighthouseFcpTimeout: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  mainRequestSizeLimit: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  mainRequestTimeout: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  navigationTimeout: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  onPageRequestsLimitLighthouseTotal: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  onPageRequestsLimitLighthousePerDomain: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  onPageRequestsLimitNormalTotal: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  onPageRequestsLimitNormalPerDomain: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteAdminFetchingSettingsMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteAdminFetchingSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly adminSettings: { readonly __typename?: 'WebsiteAdminSettings', readonly domSizeLimit: number | null, readonly domStabilizationPeriod: number | null, readonly ipPools: ReadonlyArray<GraphQL.FetchingIpPool>, readonly lighthouseChromiumResourceCacheSizeLimit: number | null, readonly lighthouseFcpTimeout: number | null, readonly mainRequestSizeLimit: number | null, readonly mainRequestTimeout: number | null, readonly navigationTimeout: number | null, readonly onPageRequestsLimitLighthouseTotal: number | null, readonly onPageRequestsLimitLighthousePerDomain: number | null, readonly onPageRequestsLimitNormalTotal: number | null, readonly onPageRequestsLimitNormalPerDomain: number | null } | null } | null } } };


export const WebsiteAdminFetchingSettingsFormDocument = gql`
    query WebsiteAdminFetchingSettingsForm($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    adminSettings {
      domSizeLimit
      domStabilizationPeriod
      ipPools
      lighthouseChromiumResourceCacheSizeLimit
      lighthouseFcpTimeout
      mainRequestSizeLimit
      mainRequestTimeout
      navigationTimeout
      onPageRequestsLimitLighthouseTotal
      onPageRequestsLimitLighthousePerDomain
      onPageRequestsLimitNormalTotal
      onPageRequestsLimitNormalPerDomain
    }
  }
}
    `;

/**
 * __useWebsiteAdminFetchingSettingsFormQuery__
 *
 * To run a query within a React component, call `useWebsiteAdminFetchingSettingsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAdminFetchingSettingsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAdminFetchingSettingsFormQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteAdminFetchingSettingsFormQuery(baseOptions: Apollo.QueryHookOptions<WebsiteAdminFetchingSettingsFormQuery, WebsiteAdminFetchingSettingsFormQueryVariables> & ({ variables: WebsiteAdminFetchingSettingsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteAdminFetchingSettingsFormQuery, WebsiteAdminFetchingSettingsFormQueryVariables>(WebsiteAdminFetchingSettingsFormDocument, options);
      }
export function useWebsiteAdminFetchingSettingsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteAdminFetchingSettingsFormQuery, WebsiteAdminFetchingSettingsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteAdminFetchingSettingsFormQuery, WebsiteAdminFetchingSettingsFormQueryVariables>(WebsiteAdminFetchingSettingsFormDocument, options);
        }
export function useWebsiteAdminFetchingSettingsFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteAdminFetchingSettingsFormQuery, WebsiteAdminFetchingSettingsFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteAdminFetchingSettingsFormQuery, WebsiteAdminFetchingSettingsFormQueryVariables>(WebsiteAdminFetchingSettingsFormDocument, options);
        }
export type WebsiteAdminFetchingSettingsFormQueryHookResult = ReturnType<typeof useWebsiteAdminFetchingSettingsFormQuery>;
export type WebsiteAdminFetchingSettingsFormLazyQueryHookResult = ReturnType<typeof useWebsiteAdminFetchingSettingsFormLazyQuery>;
export type WebsiteAdminFetchingSettingsFormSuspenseQueryHookResult = ReturnType<typeof useWebsiteAdminFetchingSettingsFormSuspenseQuery>;
export type WebsiteAdminFetchingSettingsFormQueryResult = Apollo.QueryResult<WebsiteAdminFetchingSettingsFormQuery, WebsiteAdminFetchingSettingsFormQueryVariables>;
export const UpdateWebsiteAdminFetchingSettingsDocument = gql`
    mutation UpdateWebsiteAdminFetchingSettings($domSizeLimit: Int, $domStabilizationPeriod: Int, $ipPools: [FetchingIpPool!], $lighthouseChromiumResourceCacheSizeLimit: Int, $lighthouseFcpTimeout: Int, $mainRequestSizeLimit: Int, $mainRequestTimeout: Int, $navigationTimeout: Int, $onPageRequestsLimitLighthouseTotal: Int, $onPageRequestsLimitLighthousePerDomain: Int, $onPageRequestsLimitNormalTotal: Int, $onPageRequestsLimitNormalPerDomain: Int, $websiteId: WebsiteId!) {
  UpdateWebsiteAdminFetchingSettings(
    domSizeLimit: $domSizeLimit
    domStabilizationPeriod: $domStabilizationPeriod
    ipPools: $ipPools
    lighthouseChromiumResourceCacheSizeLimit: $lighthouseChromiumResourceCacheSizeLimit
    lighthouseFcpTimeout: $lighthouseFcpTimeout
    mainRequestSizeLimit: $mainRequestSizeLimit
    mainRequestTimeout: $mainRequestTimeout
    navigationTimeout: $navigationTimeout
    onPageRequestsLimitLighthouseTotal: $onPageRequestsLimitLighthouseTotal
    onPageRequestsLimitLighthousePerDomain: $onPageRequestsLimitLighthousePerDomain
    onPageRequestsLimitNormalTotal: $onPageRequestsLimitNormalTotal
    onPageRequestsLimitNormalPerDomain: $onPageRequestsLimitNormalPerDomain
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        adminSettings {
          domSizeLimit
          domStabilizationPeriod
          ipPools
          lighthouseChromiumResourceCacheSizeLimit
          lighthouseFcpTimeout
          mainRequestSizeLimit
          mainRequestTimeout
          navigationTimeout
          onPageRequestsLimitLighthouseTotal
          onPageRequestsLimitLighthousePerDomain
          onPageRequestsLimitNormalTotal
          onPageRequestsLimitNormalPerDomain
        }
      }
    }
  }
}
    `;
export type UpdateWebsiteAdminFetchingSettingsMutationFn = Apollo.MutationFunction<UpdateWebsiteAdminFetchingSettingsMutation, UpdateWebsiteAdminFetchingSettingsMutationVariables>;

/**
 * __useUpdateWebsiteAdminFetchingSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteAdminFetchingSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteAdminFetchingSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteAdminFetchingSettingsMutation, { data, loading, error }] = useUpdateWebsiteAdminFetchingSettingsMutation({
 *   variables: {
 *      domSizeLimit: // value for 'domSizeLimit'
 *      domStabilizationPeriod: // value for 'domStabilizationPeriod'
 *      ipPools: // value for 'ipPools'
 *      lighthouseChromiumResourceCacheSizeLimit: // value for 'lighthouseChromiumResourceCacheSizeLimit'
 *      lighthouseFcpTimeout: // value for 'lighthouseFcpTimeout'
 *      mainRequestSizeLimit: // value for 'mainRequestSizeLimit'
 *      mainRequestTimeout: // value for 'mainRequestTimeout'
 *      navigationTimeout: // value for 'navigationTimeout'
 *      onPageRequestsLimitLighthouseTotal: // value for 'onPageRequestsLimitLighthouseTotal'
 *      onPageRequestsLimitLighthousePerDomain: // value for 'onPageRequestsLimitLighthousePerDomain'
 *      onPageRequestsLimitNormalTotal: // value for 'onPageRequestsLimitNormalTotal'
 *      onPageRequestsLimitNormalPerDomain: // value for 'onPageRequestsLimitNormalPerDomain'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteAdminFetchingSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteAdminFetchingSettingsMutation, UpdateWebsiteAdminFetchingSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteAdminFetchingSettingsMutation, UpdateWebsiteAdminFetchingSettingsMutationVariables>(UpdateWebsiteAdminFetchingSettingsDocument, options);
      }
export type UpdateWebsiteAdminFetchingSettingsMutationHookResult = ReturnType<typeof useUpdateWebsiteAdminFetchingSettingsMutation>;
export type UpdateWebsiteAdminFetchingSettingsMutationResult = Apollo.MutationResult<UpdateWebsiteAdminFetchingSettingsMutation>;
export type UpdateWebsiteAdminFetchingSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteAdminFetchingSettingsMutation, UpdateWebsiteAdminFetchingSettingsMutationVariables>;