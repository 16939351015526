/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RefreshPaymentStatusMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type RefreshPaymentStatusMutation = { readonly __typename?: 'Mutation', readonly RefreshPaymentStatus: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly paymentStatus: { readonly __typename?: 'AccountPaymentStatus', readonly isOkay: boolean, readonly stripeAction: { readonly __typename?: 'StripePaymentAction', readonly actionType: GraphQL.StripePaymentActionType, readonly clientSecret: string | null, readonly paymentMethod: string | null } | null } | null } | null } } };


export const RefreshPaymentStatusDocument = gql`
    mutation RefreshPaymentStatus($accountId: AccountId!) {
  RefreshPaymentStatus(accountId: $accountId) {
    query {
      account(id: $accountId) {
        id
        paymentStatus {
          isOkay
          stripeAction {
            actionType
            clientSecret
            paymentMethod
          }
        }
      }
    }
  }
}
    `;
export type RefreshPaymentStatusMutationFn = Apollo.MutationFunction<RefreshPaymentStatusMutation, RefreshPaymentStatusMutationVariables>;

/**
 * __useRefreshPaymentStatusMutation__
 *
 * To run a mutation, you first call `useRefreshPaymentStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshPaymentStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshPaymentStatusMutation, { data, loading, error }] = useRefreshPaymentStatusMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useRefreshPaymentStatusMutation(baseOptions?: Apollo.MutationHookOptions<RefreshPaymentStatusMutation, RefreshPaymentStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshPaymentStatusMutation, RefreshPaymentStatusMutationVariables>(RefreshPaymentStatusDocument, options);
      }
export type RefreshPaymentStatusMutationHookResult = ReturnType<typeof useRefreshPaymentStatusMutation>;
export type RefreshPaymentStatusMutationResult = Apollo.MutationResult<RefreshPaymentStatusMutation>;
export type RefreshPaymentStatusMutationOptions = Apollo.BaseMutationOptions<RefreshPaymentStatusMutation, RefreshPaymentStatusMutationVariables>;