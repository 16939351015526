/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UsersWithWebsiteAccessQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UsersWithWebsiteAccessQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly userAccess: ReadonlyArray<{ readonly __typename?: 'UserWebsiteAccess', readonly id: CK.ID, readonly managedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly managedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess> | null, readonly user: { readonly __typename?: 'User', readonly displayName: string, readonly email: string, readonly firstName: string | null, readonly id: string, readonly lastName: string | null, readonly legacyId: string, readonly uniqueId: CK.UserId } }> } | null };


export const UsersWithWebsiteAccessDocument = gql`
    query UsersWithWebsiteAccess($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    userAccess {
      id
      managedActions
      managedActionsForKingdomAdmin
      permittedActions
      permittedActionsForKingdomAdmin
      user {
        displayName
        email
        firstName
        id
        lastName
        legacyId
        uniqueId
      }
    }
  }
}
    `;

/**
 * __useUsersWithWebsiteAccessQuery__
 *
 * To run a query within a React component, call `useUsersWithWebsiteAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersWithWebsiteAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersWithWebsiteAccessQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUsersWithWebsiteAccessQuery(baseOptions: Apollo.QueryHookOptions<UsersWithWebsiteAccessQuery, UsersWithWebsiteAccessQueryVariables> & ({ variables: UsersWithWebsiteAccessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersWithWebsiteAccessQuery, UsersWithWebsiteAccessQueryVariables>(UsersWithWebsiteAccessDocument, options);
      }
export function useUsersWithWebsiteAccessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersWithWebsiteAccessQuery, UsersWithWebsiteAccessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersWithWebsiteAccessQuery, UsersWithWebsiteAccessQueryVariables>(UsersWithWebsiteAccessDocument, options);
        }
export function useUsersWithWebsiteAccessSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UsersWithWebsiteAccessQuery, UsersWithWebsiteAccessQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UsersWithWebsiteAccessQuery, UsersWithWebsiteAccessQueryVariables>(UsersWithWebsiteAccessDocument, options);
        }
export type UsersWithWebsiteAccessQueryHookResult = ReturnType<typeof useUsersWithWebsiteAccessQuery>;
export type UsersWithWebsiteAccessLazyQueryHookResult = ReturnType<typeof useUsersWithWebsiteAccessLazyQuery>;
export type UsersWithWebsiteAccessSuspenseQueryHookResult = ReturnType<typeof useUsersWithWebsiteAccessSuspenseQuery>;
export type UsersWithWebsiteAccessQueryResult = Apollo.QueryResult<UsersWithWebsiteAccessQuery, UsersWithWebsiteAccessQueryVariables>;