/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnrichmentFieldsBlockQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type EnrichmentFieldsBlockQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly enrichmentFields: ReadonlyArray<{ readonly __typename?: 'EnrichmentFieldData', readonly isProvided: boolean, readonly value: any | null, readonly type: { readonly __typename?: 'EnrichmentField', readonly id: CK.ID } }> } | null };


export const EnrichmentFieldsBlockDocument = gql`
    query EnrichmentFieldsBlock($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    enrichmentFields {
      isProvided
      type {
        id
      }
      value
    }
  }
}
    `;

/**
 * __useEnrichmentFieldsBlockQuery__
 *
 * To run a query within a React component, call `useEnrichmentFieldsBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrichmentFieldsBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrichmentFieldsBlockQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useEnrichmentFieldsBlockQuery(baseOptions: Apollo.QueryHookOptions<EnrichmentFieldsBlockQuery, EnrichmentFieldsBlockQueryVariables> & ({ variables: EnrichmentFieldsBlockQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnrichmentFieldsBlockQuery, EnrichmentFieldsBlockQueryVariables>(EnrichmentFieldsBlockDocument, options);
      }
export function useEnrichmentFieldsBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnrichmentFieldsBlockQuery, EnrichmentFieldsBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnrichmentFieldsBlockQuery, EnrichmentFieldsBlockQueryVariables>(EnrichmentFieldsBlockDocument, options);
        }
export function useEnrichmentFieldsBlockSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EnrichmentFieldsBlockQuery, EnrichmentFieldsBlockQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EnrichmentFieldsBlockQuery, EnrichmentFieldsBlockQueryVariables>(EnrichmentFieldsBlockDocument, options);
        }
export type EnrichmentFieldsBlockQueryHookResult = ReturnType<typeof useEnrichmentFieldsBlockQuery>;
export type EnrichmentFieldsBlockLazyQueryHookResult = ReturnType<typeof useEnrichmentFieldsBlockLazyQuery>;
export type EnrichmentFieldsBlockSuspenseQueryHookResult = ReturnType<typeof useEnrichmentFieldsBlockSuspenseQuery>;
export type EnrichmentFieldsBlockQueryResult = Apollo.QueryResult<EnrichmentFieldsBlockQuery, EnrichmentFieldsBlockQueryVariables>;