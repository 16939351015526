import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import IncidentFeedbackFormLayout from '~/components/patterns/incidents/IncidentFeedbackFormLayout';
import SubmitButton, {
	SubmitButtonSize,
} from '~/components/app/SubmitButton';
import TextArea from '~/components/atoms/forms/components/TextArea';

import {
	validateField,
} from '~/components/app/validations';



const messages = defineMessages({
	description: {
		id: 'ui.alerts.feedback.questionNegative',
	},
	submitButton: {
		id: 'ui.alerts.feedback.submit',
	},
});

const defaultValues = {
	feedback: '',
};

const validations = {
	feedback: validateField(
		'feedback',
		(f) => [
			f.validateNonEmpty(),
		],
	),
};



type Props = {
	onSubmitCallback: (values: {
		feedback: string,
	}) => Promise<void>,
};

const IncidentFeedbackForm: React.FC<Props> = (props) => {
	const {
		onSubmitCallback,
	} = props;

	return (
		<Form
			defaultValues={defaultValues}
			onSuccess={onSubmitCallback}
			validations={validations}
		>
			<IncidentFeedbackFormLayout
				buttons={[
					// <CancelButton
					// 	onClickCallback={onCancelCallback}
					// 	key="cancel-button"
					// 	size={CANCEL_BUTTON_SIZE_SMALL}
					// />,
					<SubmitButton
						key="submit-button"
						size={SubmitButtonSize.Small}
					>
						<FormattedMessage {...messages.submitButton} />
					</SubmitButton>,
				]}
				description={(
					<FormattedMessage {...messages.description} />
				)}
				textAreaField={({ height }) => (
					<FieldStatus
						name="feedback"
						showIcon={false}
					>
						<TextArea
							attributes={{
								style: {
									height,
								},
							}}
							name="feedback"
							resizable={false}
							rows={2}
							width="100%"
						/>
					</FieldStatus>
				)}
			/>
		</Form>
	);
};



export default IncidentFeedbackForm;
