/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddonsAvailabilityQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type AddonsAvailabilityQuery = { readonly __typename?: 'Query', readonly addonsAvailability: ReadonlyArray<{ readonly __typename?: 'AddonAvailability', readonly addonType: GraphQL.AddonType, readonly maximumAmount: number, readonly plan: GraphQL.AccountPlan, readonly tariff: string }> };


export const AddonsAvailabilityDocument = gql`
    query AddonsAvailability {
  addonsAvailability {
    addonType
    maximumAmount
    plan
    tariff
  }
}
    `;

/**
 * __useAddonsAvailabilityQuery__
 *
 * To run a query within a React component, call `useAddonsAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddonsAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddonsAvailabilityQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddonsAvailabilityQuery(baseOptions?: Apollo.QueryHookOptions<AddonsAvailabilityQuery, AddonsAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddonsAvailabilityQuery, AddonsAvailabilityQueryVariables>(AddonsAvailabilityDocument, options);
      }
export function useAddonsAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddonsAvailabilityQuery, AddonsAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddonsAvailabilityQuery, AddonsAvailabilityQueryVariables>(AddonsAvailabilityDocument, options);
        }
export function useAddonsAvailabilitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AddonsAvailabilityQuery, AddonsAvailabilityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddonsAvailabilityQuery, AddonsAvailabilityQueryVariables>(AddonsAvailabilityDocument, options);
        }
export type AddonsAvailabilityQueryHookResult = ReturnType<typeof useAddonsAvailabilityQuery>;
export type AddonsAvailabilityLazyQueryHookResult = ReturnType<typeof useAddonsAvailabilityLazyQuery>;
export type AddonsAvailabilitySuspenseQueryHookResult = ReturnType<typeof useAddonsAvailabilitySuspenseQuery>;
export type AddonsAvailabilityQueryResult = Apollo.QueryResult<AddonsAvailabilityQuery, AddonsAvailabilityQueryVariables>;