import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CancelButton from '~/components/app/CancelButton';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InstallCloudflareLogpushModal from '~/components/app/InstallCloudflareLogpushModal';
import InstallCloudflareWorkerModal from '~/components/app/InstallCloudflareWorkerModal';
import InternalLink from '~/components/patterns/links/InternalLink';
import LogFileAnalysisLogSourceStillPresentWarning from '~/components/app/LogFileAnalysisLogSourceStillPresentWarning';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import RadioList, {
	SIZE_SMALL as RADIO_LIST_SIZE_SMALL,
} from '~/components/atoms/forms/components/RadioList';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticList from '~/components/atoms/forms/components/StaticList';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	validateField,
} from '~/components/app/validations';

import useModals from '~/hooks/useModals';
import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteLogFileAnalysisSetup from '~/hooks/useWebsiteLogFileAnalysisSetup';
import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';

import matchAndReturn from '~/utilities/matchAndReturn';
import {
	notEmpty,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	[GraphQL.LogSourceProvider.Akamai]: {
		id: 'ui.logFileAnalysisLogSourceForm.akamaiDataStream',
	},
	[GraphQL.LogSourceProvider.Cloudflare]: {
		id: 'ui.logFileAnalysisLogSourceForm.cloudflareWorker',
	},
	[GraphQL.LogSourceProvider.CloudflareLogpush]: {
		id: 'ui.logFileAnalysisLogSourceForm.cloudflareLogpush',
	},
	[GraphQL.LogSourceProvider.Cloudfront]: {
		id: 'ui.logFileAnalysisLogSourceForm.cloudfrontStandardLogging',
	},
	[GraphQL.LogSourceProvider.Fastly]: {
		id: 'ui.logFileAnalysisLogSourceForm.fastlyRealtimeLogStreaming',
	},
	caution: {
		id: 'ui.general.caution',
	},
	deliveryMethod: {
		id: 'ui.logFileAnalysisCloudFlareSourceForm.deliveryMethod',
	},
	enableLogFileAnalysisWarning: {
		id: 'ui.logFileAnalysisCloudFlareSourceForm.enableLogFileAnalysisWarning',
	},
	install: {
		id: 'ui.logFileAnalysisLogSourceForm.install',
	},
	logpushDescription: {
		id: 'ui.logFileAnalysisCloudFlareSourceForm.logpushDescription',
	},
	reinstall: {
		id: 'ui.logFileAnalysisLogSourceForm.reinstall',
	},
	title: {
		id: 'ui.logFileAnalysisLogSourceForm.title',
	},
	unavailable: {
		id: 'ui.general.unavailable',
	},
	uninstallDeliveryMethodWarning: {
		id: 'ui.logFileAnalysisCloudFlareSourceForm.uninstallDeliveryMethodWarning',
	},
});



const LogFileAnalysisCloudFlareLogSourceForm: React.FC = () => {
	const websiteId = useWebsiteId();

	const {
		isFeatureEnabled: isLogFileAnalysisFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	const {
		getStatusForLogSource,
		isLoading,
		isLogFileAnalysisEnabled,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	const cloudflareWorkerStatus = getStatusForLogSource(GraphQL.LogSourceProvider.Cloudflare);
	const cloudflareLogpushStatus = getStatusForLogSource(GraphQL.LogSourceProvider.CloudflareLogpush);

	const isAllowedToManageLogFileAnalysis = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageLogFileAnalysis,
	);

	const defaultValues = React.useMemo(
		() => {
			if (isLoading) {
				return {};
			}

			let provider = GraphQL.LogSourceProvider.Cloudflare;

			if (getStatusForLogSource(GraphQL.LogSourceProvider.Cloudflare) === GraphQL.LogSourceStatus.Enabled) {
				provider = GraphQL.LogSourceProvider.Cloudflare;
			} else if (getStatusForLogSource(GraphQL.LogSourceProvider.CloudflareLogpush) === GraphQL.LogSourceStatus.Enabled) {
				provider = GraphQL.LogSourceProvider.CloudflareLogpush;
			}

			return {
				provider,
			};
		},
		[
			getStatusForLogSource,
			isLoading,
		],
	);

	const validations = React.useMemo(
		() => {
			return {
				validateProvider: validateField(
					'provider',
					(f) => ([
						f.custom({
							message: 'Please install the delivery method',
							rule: ({ value }) => {
								if (isLoading) {
									return false;
								}

								return getStatusForLogSource(value) === GraphQL.LogSourceStatus.Enabled;
							},
						}),
					]),
				),
			};
		},
		[
			getStatusForLogSource,
			isLoading,
		],
	);

	const showEnableLogFileAnalysisWarning = (
		!isLogFileAnalysisEnabled
		&& isAllowedToManageLogFileAnalysis.yes
		&& isLogFileAnalysisFeatureEnabled
		&& (
			cloudflareLogpushStatus === GraphQL.LogSourceStatus.Available
			|| cloudflareWorkerStatus === GraphQL.LogSourceStatus.Available
		)
	);

	const showStillPresentWarning = (
		!isLogFileAnalysisEnabled
		&& isAllowedToManageLogFileAnalysis.yes
		&& (
			cloudflareLogpushStatus === GraphQL.LogSourceStatus.Enabled
			|| cloudflareWorkerStatus === GraphQL.LogSourceStatus.Enabled
		)
	);

	const showUninstallWarning = (
		!showStillPresentWarning
		&& cloudflareLogpushStatus === GraphQL.LogSourceStatus.Enabled
		&& cloudflareWorkerStatus === GraphQL.LogSourceStatus.Enabled
	);

	const showWorkerLabel = (
		cloudflareWorkerStatus === GraphQL.LogSourceStatus.Enabled
		|| cloudflareLogpushStatus !== GraphQL.LogSourceStatus.Enabled
	);

	const showLogpushLabel = (
		cloudflareLogpushStatus === GraphQL.LogSourceStatus.Enabled
		|| cloudflareWorkerStatus !== GraphQL.LogSourceStatus.Enabled
	);

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.CloudflareLogpushLogSource}
			hideIfUnattainable={false}
			style={PremiumFeatureSituationStyle.Ribbon}
		>
			{({
				isFeatureAttainable: isLogpushFeatureAttainable,
				isFeatureEnabled: isLogpushFeatureEnabled,
				premiumAnnotation,
			}) => (
				<EditableFormWrapper
					isAllowed={isAllowedToManageLogFileAnalysis}
					isReadOnly={!isLogFileAnalysisEnabled}
					title={(
						<FormattedMessage {...messages.title} />
					)}
				>
					<DisplayPart>
						<FormRows>
							<FormRow
								description={(
									showEnableLogFileAnalysisWarning && (
										<FormattedMessage {...messages.enableLogFileAnalysisWarning} />
									)
								)}
								label={(
									<FormattedMessage {...messages.deliveryMethod} />
								)}
							>
								<StaticList>
									{showWorkerLabel ? (
										<FormattedMessage {...messages[GraphQL.LogSourceProvider.Cloudflare]} />

									) : null}

									{(showLogpushLabel && isLogpushFeatureAttainable) ? (
										<DisabledContent
											disabledContent={!isLogpushFeatureEnabled}
											disabledOverlay={premiumAnnotation}
										>
											<FormattedMessage {...messages[GraphQL.LogSourceProvider.CloudflareLogpush]} />
										</DisabledContent>
									) : null}
								</StaticList>
							</FormRow>

							{showStillPresentWarning && (
								<LogFileAnalysisLogSourceStillPresentWarning
									provider={(
										cloudflareLogpushStatus === GraphQL.LogSourceStatus.Enabled
											? GraphQL.LogSourceProvider.CloudflareLogpush
											: GraphQL.LogSourceProvider.Cloudflare
									)}
								/>
							)}

							{showUninstallWarning && (
								<UninstallWarning />
							)}
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<Form
							defaultDataHasChanged={true}
							defaultValues={defaultValues}
							key={isLoading ? 'loading' : 'ready'}
							validations={validations}
						>
							{({ values }) => {
								const provider = values.provider ?? null;

								const showCloudflareWorker = (
									provider === GraphQL.LogSourceProvider.Cloudflare
									|| cloudflareWorkerStatus === GraphQL.LogSourceStatus.Enabled
								);

								const showCloudflareLogpush = (
									provider === GraphQL.LogSourceProvider.CloudflareLogpush
									|| cloudflareLogpushStatus === GraphQL.LogSourceStatus.Enabled
								);

								return (
									<>
										<FormRows>
											<FormRow
												label={(
													<FormattedMessage {...messages.deliveryMethod} />
												)}
											>
												<RadioList
													items={[
														{
															label: (
																<FormattedMessage {...messages[GraphQL.LogSourceProvider.Cloudflare]} />
															),
															value: GraphQL.LogSourceProvider.Cloudflare,
														},
														(isLogpushFeatureAttainable ? {
															disabled: !isLogpushFeatureEnabled,
															description: values.provider === GraphQL.LogSourceProvider.CloudflareLogpush && (
																<Copy
																	{...messages.logpushDescription}
																	values={{
																		linkArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-logpush-integration/'),
																	}}
																/>
															),
															label: (
																<DisabledContent
																	disabledContent={!isLogpushFeatureEnabled}
																	disabledOverlay={premiumAnnotation}
																>
																	<FormattedMessage {...messages[GraphQL.LogSourceProvider.CloudflareLogpush]} />
																</DisabledContent>
															),
															value: GraphQL.LogSourceProvider.CloudflareLogpush,
														} : null),
													].filter(notEmpty)}
													name="provider"
													size={RADIO_LIST_SIZE_SMALL}
													width={false}
												/>
											</FormRow>

											{showCloudflareWorker && (
												<FormRow
													label={(
														<FormattedMessage {...messages[GraphQL.LogSourceProvider.Cloudflare]} />
													)}
												>
													<StaticText>
														<ProviderStatusMessage
															provider={GraphQL.LogSourceProvider.Cloudflare}
															status={cloudflareWorkerStatus}
														/>
													</StaticText>
												</FormRow>
											)}

											{showCloudflareLogpush && (
												<FormRow
													label={(
														<FormattedMessage {...messages[GraphQL.LogSourceProvider.CloudflareLogpush]} />
													)}
												>
													<StaticText>
														<ProviderStatusMessage
															provider={GraphQL.LogSourceProvider.CloudflareLogpush}
															status={cloudflareLogpushStatus}
														/>
													</StaticText>
												</FormRow>
											)}

											{showUninstallWarning && (
												<UninstallWarning />
											)}
										</FormRows>

										<ButtonsLayout>
											<CancelButton />
											<SaveSubmitButton />
										</ButtonsLayout>
									</>
								);
							}}
						</Form>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</PremiumFeatureSituation>
	);
};



type ProviderStatusMessageProps = {
	provider: (
		| GraphQL.LogSourceProvider.Cloudflare
		| GraphQL.LogSourceProvider.CloudflareLogpush
	),
	status: GraphQL.LogSourceStatus | null,
};

const ProviderStatusMessage: React.FC<ProviderStatusMessageProps> = (props) => {
	const {
		provider,
		status,
	} = props;

	const modals = useModals();

	const renderOpenModalLink = React.useCallback(
		(chunks) => {
			const modal = matchAndReturn(provider, {
				[GraphQL.LogSourceProvider.Cloudflare]: () => <InstallCloudflareWorkerModal />,
				[GraphQL.LogSourceProvider.CloudflareLogpush]: () => <InstallCloudflareLogpushModal />,
			});

			return (
				<InternalLink
					onClickCallback={() => {
						modals.openModal(modal);
					}}
				>
					{chunks}
				</InternalLink>
			);
		},
		[
			modals,
			provider,
		],
	);

	if (status === GraphQL.LogSourceStatus.Enabled) {
		return (
			<FormattedMessage
				{...messages.reinstall}
				values={{
					openModal: renderOpenModalLink,
				}}
			/>
		);
	}

	if (status === GraphQL.LogSourceStatus.Available) {
		return (
			<FormattedMessage
				{...messages.install}
				values={{
					openModal: renderOpenModalLink,
				}}
			/>
		);
	}

	if (status === GraphQL.LogSourceStatus.NotAvailable) {
		return (
			<FormattedMessage {...messages.unavailable} />
		);
	}

	return null;
};



const UninstallWarning: React.FC = () => {
	return (
		<CalloutMessage
			borders={true}
			message={(
				<FormattedMessage {...messages.caution} />
			)}
			status={CalloutMessageStatus.Warning}
		>
			<Copy
				{...messages.uninstallDeliveryMethodWarning}
				values={{
					linkLogpushArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-logpush-integration/#removing-cloudflare-logpush'),
					linkWorkerArticle: linkExternal('https://www.contentkingapp.com/support/setting-up-cloudflare-worker-integration/#removing-the-cloudflare-worker'),
				}}
			/>
		</CalloutMessage>
	);
};



export default LogFileAnalysisCloudFlareLogSourceForm;
