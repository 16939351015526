/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CustomElementsBlockQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CustomElementsBlockQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly customElements: ReadonlyArray<{ readonly __typename?: 'CustomElementData', readonly isExtracted: boolean, readonly value: any | null, readonly type: { readonly __typename?: 'CustomElement', readonly name: string } }> } | null } | null };


export const CustomElementsBlockDocument = gql`
    query CustomElementsBlock($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    pageTypeData {
      customElements {
        isExtracted
        type {
          name
        }
        value
      }
    }
  }
}
    `;

/**
 * __useCustomElementsBlockQuery__
 *
 * To run a query within a React component, call `useCustomElementsBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomElementsBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomElementsBlockQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useCustomElementsBlockQuery(baseOptions: Apollo.QueryHookOptions<CustomElementsBlockQuery, CustomElementsBlockQueryVariables> & ({ variables: CustomElementsBlockQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomElementsBlockQuery, CustomElementsBlockQueryVariables>(CustomElementsBlockDocument, options);
      }
export function useCustomElementsBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomElementsBlockQuery, CustomElementsBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomElementsBlockQuery, CustomElementsBlockQueryVariables>(CustomElementsBlockDocument, options);
        }
export function useCustomElementsBlockSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CustomElementsBlockQuery, CustomElementsBlockQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CustomElementsBlockQuery, CustomElementsBlockQueryVariables>(CustomElementsBlockDocument, options);
        }
export type CustomElementsBlockQueryHookResult = ReturnType<typeof useCustomElementsBlockQuery>;
export type CustomElementsBlockLazyQueryHookResult = ReturnType<typeof useCustomElementsBlockLazyQuery>;
export type CustomElementsBlockSuspenseQueryHookResult = ReturnType<typeof useCustomElementsBlockSuspenseQuery>;
export type CustomElementsBlockQueryResult = Apollo.QueryResult<CustomElementsBlockQuery, CustomElementsBlockQueryVariables>;