import React from 'react';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import useCreateExport from '~/hooks/useCreateExport';
import useUrlState from '~/hooks/useUrlState';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	selectedPageIdSelector,
} from '~/state/ui/selectors';



const type2ExportType = {
	incoming: 'incoming_links_internal',
	outgoing_internal: 'outgoing_links_internal',
	outbound: 'outgoing_links_external',
	canonicals: 'incoming_canonicals_internal',
	redirects: 'incoming_redirects_internal',
};

function useExportPageRelations() {
	const legacyUrlId = useSelector(selectedPageIdSelector);
	const websiteId = useWebsiteId();

	const createExport = useCreateExport(websiteId);
	const relationType = useUrlState().params.pageDetailLinksType;

	return React.useCallback(
		async () => {
			const exportData = await createExport({
				criteria: {
					legacyUrlId,
					relationType: type2ExportType[relationType],
				},
				type: GraphQL.ExportType.PageRelations,
			});

			return {
				...exportData,
				isLarge: false,
			};
		},
		[
			createExport,
			legacyUrlId,
			relationType,
		],
	);
}



export default useExportPageRelations;
