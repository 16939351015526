import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Emphasis from '~/components/patterns/typography/Emphasis';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import StaticText from '~/components/atoms/forms/components/StaticText';

import {
	useAdministerAccountFeaturesMutation,
} from './AdminFeaturesSettingsForm.gql';

import useAccountFeaturesUsage from '~/hooks/useAccountFeaturesUsage';
import useIsAllowedWithAccount from '~/hooks/useIsAllowedWithAccount';



const TOGGLEABLE_FEATURES = [
	GraphQL.AccountFeature.CmsApi,
	GraphQL.AccountFeature.ConnectToAgency,
	GraphQL.AccountFeature.ConnectToClient,
	GraphQL.AccountFeature.MultipleWebsiteCopies,
];



type Props = {
	accountId: CK.AccountId,
};

const AdminFeaturesSettingsForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountFeaturesUsage = useAccountFeaturesUsage(accountId);

	const isAllowedToManageInternals = useIsAllowedWithAccount(
		accountId,
		GraphQL.ActionWithAccount.ManageInternals,
	);

	const [administerAccountFeatures] = useAdministerAccountFeaturesMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await administerAccountFeatures({
				variables: {
					accountId,
					features: values,
				},
			});
		},
		[
			accountId,
			administerAccountFeatures,
		],
	);

	function renderFeatureLabel(feature) {
		switch (feature) {

			case GraphQL.AccountFeature.CmsApi:
				return 'CMS API';

			case GraphQL.AccountFeature.ConnectToAgency:
				return 'Allow being a connected client';

			case GraphQL.AccountFeature.ConnectToClient:
				return 'Allow being a connected agency';

			case GraphQL.AccountFeature.MultipleWebsiteCopies:
				return 'Can have multiple website copies';

		}

		return feature;
	}

	function renderForm() {
		if (accountFeaturesUsage === null) {
			return null;
		}

		const defaultValues = {};

		TOGGLEABLE_FEATURES.forEach((feature) => {
			defaultValues[feature] = accountFeaturesUsage.enabledFeatures.includes(feature);
		});

		return (
			<Form
				defaultValues={defaultValues}
				onSuccess={handleSubmit}
			>
				<FormRows>
					{TOGGLEABLE_FEATURES.map((feature) => {
						return (
							<FormRow
								htmlFor={feature}
								key={feature}
								label={renderFeatureLabel(feature)}
							>
								<CheckboxField
									label={(
										<span>
											enabled
											{accountFeaturesUsage.usedFeatures.includes(feature) && (
												<Emphasis> (in use)</Emphasis>
											)}
										</span>
									)}
									name={feature}
									width={false}
								/>
							</FormRow>
						);
					})}
				</FormRows>

				<ButtonsLayout>
					<CancelButton />
					<SaveSubmitButton />
				</ButtonsLayout>
			</Form>
		);
	}

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageInternals}
			isForAdmins={true}
			title="Features"
		>
			<DisplayPart>
				<FormRows>
					{TOGGLEABLE_FEATURES.map((feature) => {
						return (
							<FormRow
								key={feature}
								label={renderFeatureLabel(feature)}
							>
								<StaticText focusTarget={feature}>
									{accountFeaturesUsage !== null && accountFeaturesUsage.enabledFeatures.includes(feature) ? 'enabled' : 'disabled'}
									{accountFeaturesUsage !== null && accountFeaturesUsage.usedFeatures.includes(feature) && (
										<Emphasis> (in use)</Emphasis>
									)}
								</StaticText>
							</FormRow>
						);
					})}
				</FormRows>
			</DisplayPart>

			<EditablePart>
				{renderForm()}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default AdminFeaturesSettingsForm;
