/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageTypeQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageTypeQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly pageType: GraphQL.PageType } | null };


export const PageTypeDocument = gql`
    query PageType($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    pageType
  }
}
    `;

/**
 * __usePageTypeQuery__
 *
 * To run a query within a React component, call `usePageTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageTypeQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageTypeQuery(baseOptions: Apollo.QueryHookOptions<PageTypeQuery, PageTypeQueryVariables> & ({ variables: PageTypeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageTypeQuery, PageTypeQueryVariables>(PageTypeDocument, options);
      }
export function usePageTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageTypeQuery, PageTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageTypeQuery, PageTypeQueryVariables>(PageTypeDocument, options);
        }
export function usePageTypeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PageTypeQuery, PageTypeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageTypeQuery, PageTypeQueryVariables>(PageTypeDocument, options);
        }
export type PageTypeQueryHookResult = ReturnType<typeof usePageTypeQuery>;
export type PageTypeLazyQueryHookResult = ReturnType<typeof usePageTypeLazyQuery>;
export type PageTypeSuspenseQueryHookResult = ReturnType<typeof usePageTypeSuspenseQuery>;
export type PageTypeQueryResult = Apollo.QueryResult<PageTypeQuery, PageTypeQueryVariables>;