/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserAssignedWebsitesFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  legacyUserId: GraphQL.Scalars['String']['input'];
}>;


export type UserAssignedWebsitesFormQuery = { readonly __typename?: 'Query', readonly userAccountMembership: { readonly __typename?: 'AccountMembership', readonly hasLimitedWebsitesAccess: boolean, readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly displayName: string }> } | null };

export type UpdateAssignedWebsitesMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  assignedWebsites: GraphQL.InputMaybe<ReadonlyArray<GraphQL.Scalars['WebsiteId']['input']> | GraphQL.Scalars['WebsiteId']['input']>;
  hasLimitedWebsitesAccess: GraphQL.Scalars['Boolean']['input'];
  legacyId: GraphQL.Scalars['ID']['input'];
  legacyUserId: GraphQL.Scalars['String']['input'];
  role: GraphQL.UserRole;
}>;


export type UpdateAssignedWebsitesMutation = { readonly __typename?: 'Mutation', readonly ChangeUserRole: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly userAccountMembership: { readonly __typename?: 'AccountMembership', readonly hasLimitedWebsitesAccess: boolean, readonly role: GraphQL.UserRole, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly websiteEmailSettings: ReadonlyArray<{ readonly __typename?: 'WebsiteEmailSettings', readonly alertEmailsSetup: GraphQL.AlertEmailsSetup, readonly isWeeklyReportEnabled: boolean, readonly websiteId: CK.WebsiteId }> }, readonly websiteAccess: ReadonlyArray<{ readonly __typename?: 'UserWebsiteAccess', readonly id: CK.ID, readonly managedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly managedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess> | null, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId } }>, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> } | null } } };


export const UserAssignedWebsitesFormDocument = gql`
    query UserAssignedWebsitesForm($accountId: AccountId!, $legacyUserId: String!) {
  userAccountMembership(accountId: $accountId, legacyUserId: $legacyUserId) {
    account {
      id
    }
    hasLimitedWebsitesAccess
    user {
      id
      legacyId
    }
    role
    websites {
      id
      displayName
    }
  }
}
    `;

/**
 * __useUserAssignedWebsitesFormQuery__
 *
 * To run a query within a React component, call `useUserAssignedWebsitesFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserAssignedWebsitesFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserAssignedWebsitesFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserAssignedWebsitesFormQuery(baseOptions: Apollo.QueryHookOptions<UserAssignedWebsitesFormQuery, UserAssignedWebsitesFormQueryVariables> & ({ variables: UserAssignedWebsitesFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserAssignedWebsitesFormQuery, UserAssignedWebsitesFormQueryVariables>(UserAssignedWebsitesFormDocument, options);
      }
export function useUserAssignedWebsitesFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserAssignedWebsitesFormQuery, UserAssignedWebsitesFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserAssignedWebsitesFormQuery, UserAssignedWebsitesFormQueryVariables>(UserAssignedWebsitesFormDocument, options);
        }
export function useUserAssignedWebsitesFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserAssignedWebsitesFormQuery, UserAssignedWebsitesFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserAssignedWebsitesFormQuery, UserAssignedWebsitesFormQueryVariables>(UserAssignedWebsitesFormDocument, options);
        }
export type UserAssignedWebsitesFormQueryHookResult = ReturnType<typeof useUserAssignedWebsitesFormQuery>;
export type UserAssignedWebsitesFormLazyQueryHookResult = ReturnType<typeof useUserAssignedWebsitesFormLazyQuery>;
export type UserAssignedWebsitesFormSuspenseQueryHookResult = ReturnType<typeof useUserAssignedWebsitesFormSuspenseQuery>;
export type UserAssignedWebsitesFormQueryResult = Apollo.QueryResult<UserAssignedWebsitesFormQuery, UserAssignedWebsitesFormQueryVariables>;
export const UpdateAssignedWebsitesDocument = gql`
    mutation UpdateAssignedWebsites($accountId: AccountId!, $assignedWebsites: [WebsiteId!], $hasLimitedWebsitesAccess: Boolean!, $legacyId: ID!, $legacyUserId: String!, $role: UserRole!) {
  ChangeUserRole(
    accountId: $accountId
    assignedWebsites: $assignedWebsites
    limitedWebsitesAccess: $hasLimitedWebsitesAccess
    role: $role
    userId: $legacyId
  ) {
    query {
      userAccountMembership(accountId: $accountId, legacyUserId: $legacyUserId) {
        account {
          id
        }
        hasLimitedWebsitesAccess
        user {
          id
          legacyId
          websiteEmailSettings {
            alertEmailsSetup
            isWeeklyReportEnabled
            websiteId
          }
        }
        role
        websiteAccess {
          id
          managedActions
          managedActionsForKingdomAdmin
          permittedActions
          permittedActionsForKingdomAdmin
          user {
            id
            legacyId
          }
          website {
            id
          }
        }
        websites {
          id
        }
      }
    }
  }
}
    `;
export type UpdateAssignedWebsitesMutationFn = Apollo.MutationFunction<UpdateAssignedWebsitesMutation, UpdateAssignedWebsitesMutationVariables>;

/**
 * __useUpdateAssignedWebsitesMutation__
 *
 * To run a mutation, you first call `useUpdateAssignedWebsitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignedWebsitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignedWebsitesMutation, { data, loading, error }] = useUpdateAssignedWebsitesMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      assignedWebsites: // value for 'assignedWebsites'
 *      hasLimitedWebsitesAccess: // value for 'hasLimitedWebsitesAccess'
 *      legacyId: // value for 'legacyId'
 *      legacyUserId: // value for 'legacyUserId'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateAssignedWebsitesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAssignedWebsitesMutation, UpdateAssignedWebsitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAssignedWebsitesMutation, UpdateAssignedWebsitesMutationVariables>(UpdateAssignedWebsitesDocument, options);
      }
export type UpdateAssignedWebsitesMutationHookResult = ReturnType<typeof useUpdateAssignedWebsitesMutation>;
export type UpdateAssignedWebsitesMutationResult = Apollo.MutationResult<UpdateAssignedWebsitesMutation>;
export type UpdateAssignedWebsitesMutationOptions = Apollo.BaseMutationOptions<UpdateAssignedWebsitesMutation, UpdateAssignedWebsitesMutationVariables>;