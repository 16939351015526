/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlatformIssueCategoriesQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PlatformIssueCategoriesQuery = { readonly __typename?: 'Query', readonly platformData: { readonly __typename?: 'PlatformData', readonly id: CK.ID, readonly issueCategories: ReadonlyArray<{ readonly __typename?: 'PlatformIssueCategory', readonly configuration: Record<string, any>, readonly context: Record<string, any>, readonly isActionable: boolean, readonly isConfigurationModified: boolean, readonly isIgnored: boolean, readonly name: string, readonly pointsGained: number, readonly pointsToGain: number, readonly state: GraphQL.IssueCategoryState, readonly issues: ReadonlyArray<{ readonly __typename?: 'PlatformIssue', readonly configuration: Record<string, any>, readonly context: Record<string, any>, readonly isActionable: boolean, readonly isConfigurationModified: boolean, readonly isIgnored: boolean, readonly name: string, readonly pointsGained: number, readonly pointsToGain: number, readonly state: GraphQL.IssueState, readonly followupIssues: ReadonlyArray<{ readonly __typename?: 'PlatformIssue', readonly configuration: Record<string, any>, readonly context: Record<string, any>, readonly isActionable: boolean, readonly isConfigurationModified: boolean, readonly isIgnored: boolean, readonly name: string, readonly pointsGained: number, readonly pointsToGain: number, readonly state: GraphQL.IssueState, readonly ignoringRuleOnWebsite: { readonly __typename?: 'IssueIgnoringRuleOnWebsite', readonly isActive: boolean, readonly isEffective: boolean } }>, readonly ignoringRuleOnWebsite: { readonly __typename?: 'IssueIgnoringRuleOnWebsite', readonly isActive: boolean, readonly isEffective: boolean } }> }> } | null };

export type PlatformIssueFragmentFragment = { readonly __typename?: 'PlatformIssue', readonly configuration: Record<string, any>, readonly context: Record<string, any>, readonly isActionable: boolean, readonly isConfigurationModified: boolean, readonly isIgnored: boolean, readonly name: string, readonly pointsGained: number, readonly pointsToGain: number, readonly state: GraphQL.IssueState, readonly ignoringRuleOnWebsite: { readonly __typename?: 'IssueIgnoringRuleOnWebsite', readonly isActive: boolean, readonly isEffective: boolean } };

export const PlatformIssueFragmentFragmentDoc = gql`
    fragment PlatformIssueFragment on PlatformIssue {
  configuration
  context
  ignoringRuleOnWebsite {
    isActive
    isEffective
  }
  isActionable
  isConfigurationModified
  isIgnored
  name
  pointsGained
  pointsToGain
  state
}
    `;
export const PlatformIssueCategoriesDocument = gql`
    query PlatformIssueCategories($websiteId: WebsiteId!) {
  platformData(websiteId: $websiteId) {
    id
    issueCategories {
      configuration
      context
      isActionable
      isConfigurationModified
      isIgnored
      issues {
        ...PlatformIssueFragment
        followupIssues {
          ...PlatformIssueFragment
        }
      }
      name
      pointsGained
      pointsToGain
      state
    }
  }
}
    ${PlatformIssueFragmentFragmentDoc}`;

/**
 * __usePlatformIssueCategoriesQuery__
 *
 * To run a query within a React component, call `usePlatformIssueCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformIssueCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformIssueCategoriesQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePlatformIssueCategoriesQuery(baseOptions: Apollo.QueryHookOptions<PlatformIssueCategoriesQuery, PlatformIssueCategoriesQueryVariables> & ({ variables: PlatformIssueCategoriesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlatformIssueCategoriesQuery, PlatformIssueCategoriesQueryVariables>(PlatformIssueCategoriesDocument, options);
      }
export function usePlatformIssueCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlatformIssueCategoriesQuery, PlatformIssueCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlatformIssueCategoriesQuery, PlatformIssueCategoriesQueryVariables>(PlatformIssueCategoriesDocument, options);
        }
export function usePlatformIssueCategoriesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PlatformIssueCategoriesQuery, PlatformIssueCategoriesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlatformIssueCategoriesQuery, PlatformIssueCategoriesQueryVariables>(PlatformIssueCategoriesDocument, options);
        }
export type PlatformIssueCategoriesQueryHookResult = ReturnType<typeof usePlatformIssueCategoriesQuery>;
export type PlatformIssueCategoriesLazyQueryHookResult = ReturnType<typeof usePlatformIssueCategoriesLazyQuery>;
export type PlatformIssueCategoriesSuspenseQueryHookResult = ReturnType<typeof usePlatformIssueCategoriesSuspenseQuery>;
export type PlatformIssueCategoriesQueryResult = Apollo.QueryResult<PlatformIssueCategoriesQuery, PlatformIssueCategoriesQueryVariables>;