/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountEndUserPlanQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountEndUserPlanQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly endUserPlan: GraphQL.EndUserPlan | null, readonly id: CK.AccountId } | null };


export const AccountEndUserPlanDocument = gql`
    query AccountEndUserPlan($accountId: AccountId!) {
  account(id: $accountId) {
    endUserPlan
    id
  }
}
    `;

/**
 * __useAccountEndUserPlanQuery__
 *
 * To run a query within a React component, call `useAccountEndUserPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountEndUserPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountEndUserPlanQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountEndUserPlanQuery(baseOptions: Apollo.QueryHookOptions<AccountEndUserPlanQuery, AccountEndUserPlanQueryVariables> & ({ variables: AccountEndUserPlanQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountEndUserPlanQuery, AccountEndUserPlanQueryVariables>(AccountEndUserPlanDocument, options);
      }
export function useAccountEndUserPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountEndUserPlanQuery, AccountEndUserPlanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountEndUserPlanQuery, AccountEndUserPlanQueryVariables>(AccountEndUserPlanDocument, options);
        }
export function useAccountEndUserPlanSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountEndUserPlanQuery, AccountEndUserPlanQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountEndUserPlanQuery, AccountEndUserPlanQueryVariables>(AccountEndUserPlanDocument, options);
        }
export type AccountEndUserPlanQueryHookResult = ReturnType<typeof useAccountEndUserPlanQuery>;
export type AccountEndUserPlanLazyQueryHookResult = ReturnType<typeof useAccountEndUserPlanLazyQuery>;
export type AccountEndUserPlanSuspenseQueryHookResult = ReturnType<typeof useAccountEndUserPlanSuspenseQuery>;
export type AccountEndUserPlanQueryResult = Apollo.QueryResult<AccountEndUserPlanQuery, AccountEndUserPlanQueryVariables>;