import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Hint, {
	HintPopupSize,
	HintPopupSkin,
	HintPopupVisibility,
} from '~/components/patterns/hints/hint/Hint';
import ValidationMessage, {
	ValidationMessageStatus,
} from '~/components/patterns/messages/embedded/ValidationMessage';

import {
	useExportFetchingSnapshotMutation,
	useExportRobotsTxtRevisionMutation,
	useSnapshotExportStatusLazyQuery,
} from './ExportSnapshotDownloadButton.gql';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	error: {
		id: 'ui.general.unexpectedError',
	},
	label: {
		id: 'ui.exportSnapshotDownloadButton.label',
	},
});



export enum SnapshotType {
	Page,
	RobotsTxt,
}

type Props = {
	disabledExplanation?: React.ReactNode,
	isDisabled: boolean,
	timestamp: string | null,
	url: string | null,
	websiteId: CK.WebsiteId,
} & (
		| {
			snapshotType: SnapshotType.Page,
			revisionId?: never,
		}
		| {
			snapshotType: SnapshotType.RobotsTxt,
			revisionId: CK.ID | null,
		}
	);

const ExportSnapshotDownloadButton: React.FC<Props> = (props) => {
	const {
		disabledExplanation,
		isDisabled,
		revisionId,
		snapshotType,
		timestamp,
		url,
		websiteId,
	} = props;

	const [failed, setFailed] = React.useState(false);
	const [isProcessing, setProcessing] = React.useState(false);
	const statusChecks = React.useRef(0);

	const [checkExportStatus, { stopPolling }] = useSnapshotExportStatusLazyQuery({
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			if (data.fetchingSnapshotExport !== null && data.fetchingSnapshotExport.isFinished) {
				setProcessing(false);
				stopPolling();

				if (data.fetchingSnapshotExport.downloadUrl) {
					window.location.href = data.fetchingSnapshotExport.downloadUrl;
				} else {
					setFailed(true);
				}
			} else {
				statusChecks.current += 1;

				if (statusChecks.current > 20) {
					setProcessing(false);
					setFailed(true);
					stopPolling();
				}
			}
		},
		pollInterval: 1500,
	});

	const [exportFetchingSnapshot] = useExportFetchingSnapshotMutation({
		variables: {
			timestamp: timestamp ?? '',
			url: url ?? '',
			websiteId,
		},
	});

	const [exportRobotsTxtRevision] = useExportRobotsTxtRevisionMutation({
		variables: {
			revisionId: revisionId ?? '',
			url: url ?? '',
			websiteId,
		},
	});

	async function handleClick(e) {
		e.preventDefault();

		setFailed(false);
		setProcessing(true);
		statusChecks.current = 0;

		try {
			const action = matchAndReturn(snapshotType, {
				[SnapshotType.Page]: async () => {
					const { data } = await exportFetchingSnapshot();

					return data?.ExportFetchingSnapshot.export.id ?? null;
				},
				[SnapshotType.RobotsTxt]: async () => {
					const { data } = await exportRobotsTxtRevision();

					return data?.ExportRobotsTxtRevision.export.id ?? null;
				},
			});

			const exportId = await action();

			if (exportId !== null) {
				checkExportStatus({
					variables: {
						exportId,
					},
				});
			} else {
				setProcessing(false);
			}
		} catch (error) {
			setFailed(true);
			setProcessing(false);
		}
	}

	if (isDisabled) {
		return (
			<Button
				disabled={true}
				icon={(
					<BasicIcon type={BasicIconType.Download} />
				)}
				style={ButtonStyle.Hollow}
				tooltip={disabledExplanation}
			>
				<FormattedMessage {...messages.label} />
			</Button>
		);
	}

	return (
		<Hint
			popup={(
				<ValidationMessage status={ValidationMessageStatus.Invalid}>
					<FormattedMessage {...messages.error} />
				</ValidationMessage>
			)}
			popupSize={HintPopupSize.Small}
			popupSkin={HintPopupSkin.Light}
			popupVisibility={failed ? HintPopupVisibility.Always : HintPopupVisibility.Never}
		>
			<Button
				disabled={isProcessing}
				icon={(
					<BasicIcon type={BasicIconType.Download} />
				)}
				onClick={handleClick}
				progress={isProcessing}
				style={ButtonStyle.Hollow}
			>
				<FormattedMessage {...messages.label} />
			</Button>
		</Hint>
	);
};



export default ExportSnapshotDownloadButton;
