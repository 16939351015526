/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CopyCustomElementToWebsitesModalQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type CopyCustomElementToWebsitesModalQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly customElementsLimit: number, readonly displayName: string, readonly id: CK.AccountId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly displayName: string, readonly id: CK.WebsiteId, readonly customElements: ReadonlyArray<{ readonly __typename?: 'CustomElement', readonly label: string, readonly name: string }> }> }> } | null };

export type CopyCustomElementToWebsitesMutationVariables = GraphQL.Exact<{
  sourceCustomElementName: GraphQL.Scalars['String']['input'];
  sourceWebsiteId: GraphQL.Scalars['WebsiteId']['input'];
  targetWebsiteIds: ReadonlyArray<GraphQL.Scalars['WebsiteId']['input']> | GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CopyCustomElementToWebsitesMutation = { readonly __typename?: 'Mutation', readonly CopyCustomElementToWebsites: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly customElements: ReadonlyArray<{ readonly __typename?: 'CustomElement', readonly dataType: GraphQL.CustomElementDataType, readonly extraction: Record<string, any>, readonly label: string, readonly name: string }> }> }> } | null } } };


export const CopyCustomElementToWebsitesModalDocument = gql`
    query CopyCustomElementToWebsitesModal {
  authenticatedSession {
    accounts {
      customElementsLimit
      displayName
      id
      websites {
        customElements {
          label
          name
        }
        displayName
        id
      }
    }
  }
}
    `;

/**
 * __useCopyCustomElementToWebsitesModalQuery__
 *
 * To run a query within a React component, call `useCopyCustomElementToWebsitesModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCopyCustomElementToWebsitesModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCopyCustomElementToWebsitesModalQuery({
 *   variables: {
 *   },
 * });
 */
export function useCopyCustomElementToWebsitesModalQuery(baseOptions?: Apollo.QueryHookOptions<CopyCustomElementToWebsitesModalQuery, CopyCustomElementToWebsitesModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CopyCustomElementToWebsitesModalQuery, CopyCustomElementToWebsitesModalQueryVariables>(CopyCustomElementToWebsitesModalDocument, options);
      }
export function useCopyCustomElementToWebsitesModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CopyCustomElementToWebsitesModalQuery, CopyCustomElementToWebsitesModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CopyCustomElementToWebsitesModalQuery, CopyCustomElementToWebsitesModalQueryVariables>(CopyCustomElementToWebsitesModalDocument, options);
        }
export function useCopyCustomElementToWebsitesModalSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CopyCustomElementToWebsitesModalQuery, CopyCustomElementToWebsitesModalQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CopyCustomElementToWebsitesModalQuery, CopyCustomElementToWebsitesModalQueryVariables>(CopyCustomElementToWebsitesModalDocument, options);
        }
export type CopyCustomElementToWebsitesModalQueryHookResult = ReturnType<typeof useCopyCustomElementToWebsitesModalQuery>;
export type CopyCustomElementToWebsitesModalLazyQueryHookResult = ReturnType<typeof useCopyCustomElementToWebsitesModalLazyQuery>;
export type CopyCustomElementToWebsitesModalSuspenseQueryHookResult = ReturnType<typeof useCopyCustomElementToWebsitesModalSuspenseQuery>;
export type CopyCustomElementToWebsitesModalQueryResult = Apollo.QueryResult<CopyCustomElementToWebsitesModalQuery, CopyCustomElementToWebsitesModalQueryVariables>;
export const CopyCustomElementToWebsitesDocument = gql`
    mutation CopyCustomElementToWebsites($sourceCustomElementName: String!, $sourceWebsiteId: WebsiteId!, $targetWebsiteIds: [WebsiteId!]!) {
  CopyCustomElementToWebsites(
    sourceCustomElementName: $sourceCustomElementName
    sourceWebsiteId: $sourceWebsiteId
    targetWebsiteIds: $targetWebsiteIds
  ) {
    query {
      authenticatedSession {
        memberships {
          account {
            id
          }
          user {
            id
            legacyId
          }
          websites {
            id
            customElements {
              dataType
              extraction
              label
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type CopyCustomElementToWebsitesMutationFn = Apollo.MutationFunction<CopyCustomElementToWebsitesMutation, CopyCustomElementToWebsitesMutationVariables>;

/**
 * __useCopyCustomElementToWebsitesMutation__
 *
 * To run a mutation, you first call `useCopyCustomElementToWebsitesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyCustomElementToWebsitesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyCustomElementToWebsitesMutation, { data, loading, error }] = useCopyCustomElementToWebsitesMutation({
 *   variables: {
 *      sourceCustomElementName: // value for 'sourceCustomElementName'
 *      sourceWebsiteId: // value for 'sourceWebsiteId'
 *      targetWebsiteIds: // value for 'targetWebsiteIds'
 *   },
 * });
 */
export function useCopyCustomElementToWebsitesMutation(baseOptions?: Apollo.MutationHookOptions<CopyCustomElementToWebsitesMutation, CopyCustomElementToWebsitesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyCustomElementToWebsitesMutation, CopyCustomElementToWebsitesMutationVariables>(CopyCustomElementToWebsitesDocument, options);
      }
export type CopyCustomElementToWebsitesMutationHookResult = ReturnType<typeof useCopyCustomElementToWebsitesMutation>;
export type CopyCustomElementToWebsitesMutationResult = Apollo.MutationResult<CopyCustomElementToWebsitesMutation>;
export type CopyCustomElementToWebsitesMutationOptions = Apollo.BaseMutationOptions<CopyCustomElementToWebsitesMutation, CopyCustomElementToWebsitesMutationVariables>;