import Immutable from 'immutable';
import React from 'react';

import AbstractSelectField, {
	AbstractSelectFieldDropdownAttachment,
	AbstractSelectFieldSize,
} from '~/components/patterns/forms/fields/AbstractSelectField';
import SegmentDefinitionFilterIdentifier from '../../../logic/segments/SegmentDefinitionFilterIdentifier';
import SegmentFilterOperator, {
	OPERATOR_AND as FILTER_OPERATOR_AND,
	OPERATOR_AND_NOT as FILTER_OPERATOR_AND_NOT,
	OPERATOR_NOT as FILTER_OPERATOR_NOT,
} from '~/components/atoms/segments/filters/SegmentFilterOperator';
import SegmentMultiselectToggler, {
	SegmentMultiselectTogglerSize,
} from '~/components/atoms/forms/components/segments/SegmentMultiselectToggler';
import SegmentSelectionArea from './segments/SegmentSelectionArea';

import useFormContext from '~/hooks/useFormContext';
import usePrevious from '~/hooks/usePrevious';

import {
	type SegmentDefinition,
	getSegmentDefinitionByName,
} from '~/model/segments';



export const OPERATOR_AND = 'and';
export const OPERATOR_OR = 'or';
export const DEFAULT_VALUE = {
	included_in: [],
	not_included_in: [],
	operator: OPERATOR_AND,
};



type Value = any;

export type SegmentMultiselectFieldRef = {
	changeValue: (value: Value, close: boolean) => void,
};



type Props = {
	dropdownAttachment?: AbstractSelectFieldDropdownAttachment,
	dropdownWidth?: number,
	name: string,
	placeholder?: string,
	popperEnabled?: boolean,
	segments: ReadonlyArray<SegmentDefinition>,
	segmentsNotAllowedForFiltering?: Record<string, React.ReactNode>,
	width?: React.CSSProperties['width'],
};

const SegmentMultiselectField = React.forwardRef<SegmentMultiselectFieldRef, Props>((props, ref) => {
	const {
		dropdownAttachment = AbstractSelectFieldDropdownAttachment.Left,
		dropdownWidth = 510,
		name,
		placeholder,
		popperEnabled = true,
		segments,
		segmentsNotAllowedForFiltering,
		width = 280,
	} = props;

	const formContext = useFormContext();

	const formContextOnBlurHandler = formContext.onBlurHandler;
	const formContextOnChangeHandler = formContext.onChangeHandler;
	const formContextOnFocusHandler = formContext.onFocusHandler;
	const formContextOnUnmountHandler = formContext.onUnmountHandler;

	const formValue = formContext.values[name];
	const previousFormValue = usePrevious(formValue);

	const [value, setValue] = React.useState(
		() => ({
			includedSegments: formValue ? (formValue.get ? formValue.get('included_in') : formValue.included_in) : Immutable.List(),
			notIncludedSegments: formValue ? (formValue.get ? formValue.get('not_included_in') : formValue.not_included_in) : Immutable.List(),
			operator: formValue ? (formValue.get ? formValue.get('operator') : formValue.operator) : OPERATOR_AND,
		}),
	);

	React.useImperativeHandle(ref, () => ({
		changeValue: (value) => {
			setValue(
				{
					includedSegments: value.get ? value.get('included_in') : value.included_in,
					notIncludedSegments: value.get ? value.get('not_included_in') : value.not_included_in,
					operator: value.get ? value.get('operator') : value.operator,
				},
			);
		},
	}));

	React.useEffect(
		() => {
			return () => {
				formContextOnUnmountHandler(name);
			};
		},
		[
			formContextOnUnmountHandler,
			name,
		],
	);

	React.useEffect(
		() => {
			if (!previousFormValue && formValue) {
				setValue(
					{
						includedSegments: formValue.get ? formValue.get('included_in') : formValue.included_in,
						notIncludedSegments: formValue.get ? formValue.get('not_included_in') : formValue.not_included_in,
						operator: formValue.get ? formValue.get('operator') : formValue.operator,
					},
				);
			}
		},
		[
			formValue,
			previousFormValue,
		],
	);

	const handleDropdownOpenCallback = React.useCallback(
		() => {
			formContextOnFocusHandler(name);
		},
		[
			formContextOnFocusHandler,
			name,
		],
	);

	const handleDropdownCloseCallback = React.useCallback(
		() => {
			formContextOnBlurHandler(name);
		},
		[
			formContextOnBlurHandler,
			name,
		],
	);

	const handleChangeCallback = React.useCallback(
		({ includedSegments, notIncludedSegments }) => {
			setValue(
				{
					includedSegments,
					notIncludedSegments,
					operator: value.operator,
				},
			);

			formContextOnChangeHandler(name, {
				included_in: includedSegments,
				not_included_in: notIncludedSegments,
				operator: value.operator,
			});
		},
		[
			formContextOnChangeHandler,
			name,
			value,
		],
	);

	const handleOperatorChangeCallback = React.useCallback(
		({ operator }) => {
			setValue(
				{
					includedSegments: value.includedSegments,
					notIncludedSegments: value.notIncludedSegments,
					operator,
				},
			);

			formContextOnChangeHandler(name, {
				included_in: value.includedSegments,
				not_included_in: value.notIncludedSegments,
				operator,
			});
		},
		[
			formContextOnChangeHandler,
			name,
			value,
		],
	);

	function renderLabelText() {
		const showLabel = (value.includedSegments.size + value.notIncludedSegments.size) <= 2;

		if (value.includedSegments.size === 0 && value.notIncludedSegments.size === 0) {
			// this means that we will use placeholder instead
			return null;
		}

		const parts: Array<React.ReactNode> = [];

		// if (value.includedSegments.size > 1 && value.operator === 'or') {
		// 	parts.push((
		// 		<SegmentFilterSymbol>(</SegmentFilterSymbol>
		// 	));
		// }

		value.includedSegments.forEach((segment) => {
			const segmentDefinition = getSegmentDefinitionByName(segments, segment);

			if (segmentDefinition === null) {
				return;
			}

			parts.push((
				<SegmentDefinitionFilterIdentifier
					key={'segment/' + segmentDefinition.name}
					segmentDefinition={segmentDefinition}
					showLabel={showLabel}
				/>
			));

			parts.push((
				<SegmentFilterOperator
					key={'operator/' + segmentDefinition.name}
					operator={value.operator}
				/>
			));
		});

		if (value.includedSegments.size > 0) {
			parts.pop();
		}

		// if (value.includedSegments.size > 1 && value.operator === 'or') {
		// 	parts.push((
		// 		<SegmentFilterSymbol>)</SegmentFilterSymbol>
		// 	));
		// }

		if (value.includedSegments.size === 0 && value.notIncludedSegments.size > 0) {
			parts.push((
				<SegmentFilterOperator
					key="operator_not"
					operator={FILTER_OPERATOR_NOT}
				/>
			));
		}

		if (value.includedSegments.size > 0 && value.notIncludedSegments.size > 0) {
			parts.push((
				<SegmentFilterOperator
					key="operator_and_not"
					operator={FILTER_OPERATOR_AND_NOT}
				/>
			));
		}

		value.notIncludedSegments.forEach((segment) => {
			const segmentDefinition = getSegmentDefinitionByName(segments, segment);

			if (segmentDefinition === null) {
				return;
			}

			parts.push((
				<SegmentDefinitionFilterIdentifier
					key={'segment_not/' + segmentDefinition.name}
					segmentDefinition={segmentDefinition}
					showLabel={showLabel}
				/>
			));

			parts.push((
				<SegmentFilterOperator
					key={'operator_not/' + segmentDefinition.name}
					operator={FILTER_OPERATOR_AND}
				/>
			));
		});

		if (value.notIncludedSegments.size > 0) {
			parts.pop();
		}

		return parts;
	}

	return (
		<AbstractSelectField
			dropdownAttachment={dropdownAttachment}
			dropdownWidth={dropdownWidth}
			isDisabled={formContext.isDisabled}
			label={renderLabelText()}
			labelRenderer={(label, isOpen) => (
				<SegmentMultiselectToggler
					isOpen={isOpen}
					label={label}
					placeholder={placeholder}
					size={SegmentMultiselectTogglerSize.Small}
				/>
			)}
			onDropdownCloseCallback={handleDropdownCloseCallback}
			onDropdownOpenCallback={handleDropdownOpenCallback}
			popperEnabled={popperEnabled}
			size={AbstractSelectFieldSize.Small}
			width={width}
		>
			<SegmentSelectionArea
				includedSegments={value.includedSegments}
				notIncludedSegments={value.notIncludedSegments}
				onChangeCallback={handleChangeCallback}
				onOperatorChangeCallback={handleOperatorChangeCallback}
				operator={value.operator}
				segments={segments}
				segmentsNotAllowedForFiltering={segmentsNotAllowedForFiltering}
			/>
		</AbstractSelectField>
	);
});



export default SegmentMultiselectField;

export {
	AbstractSelectFieldDropdownAttachment as SegmentMultiselectFieldDropdownAttachment,
};
