import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import AlertPagesButton from '../AlertPagesButton';
import ExpressiveValue, {
	ExpressiveValueLabelFlow,
	ExpressiveValueSize,
	ExpressiveValueStatus,
} from '~/components/patterns/values/ExpressiveValue';
import IncidentBoxContent from '~/components/patterns/incidents/IncidentBoxContent';
import List from '~/components/patterns/lists/List';
import RemovedFromAlertMetric from './RemovedFromAlertMetric';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';

import {
	countPagesSplit,
} from '~/model/alerts';



const REASON_CANONICAL_LINK = ['canonicalLink', 'canonical'];
const REASON_META_ROBOTS = ['metaRobots', 'meta_robots'];
const REASON_ROBOTS_TXT = ['robotsTxt', 'robots_txt'];
const REASON_X_ROBOTS_TAG = ['xRobotsTag', 'x_robots_tag'];



const messages = defineMessages({
	affected: {
		id: 'ui.alerts.overview.incident.details.affected',
	},
	reverted: {
		id: 'ui.alerts.overview.incident.details.reverted',
	},
	reasons: {
		canonicalLink: {
			id: 'ui.contentData.canonical_link',
		},
		metaRobots: {
			id: 'ui.contentOverview.tableHeading.is_indexable_due_to_meta_robots',
		},
		robotsTxt: {
			id: 'ui.contentOverview.tableHeading.is_disallowed_in_robots_txt',
		},
		xRobotsTag: {
			id: 'ui.contentOverview.tableHeading.is_indexable_due_to_x_robots_tag',
		},
	},
});



class PageBecameNonIndexableDetails extends Component {

	render() {
		const {
			incident,
			websiteId,
		} = this.props;

		const isIncidentActive = incident.get('phase') === 'active';

		const isModern = incident.hasIn(['state', 'pages', 'split']);

		const numberOfReverted = isModern
			? countPagesSplit(incident, 'none')
			: null;

		const numberOfRemovedFromAlert = isModern
			? (
				countPagesSplit(incident, 'not_page')
				+ countPagesSplit(incident, 'over_quota')
			)
			: 0;

		const numberOfAffected = isModern
			? incident.getIn(['state', 'pages', 'total']) - numberOfReverted - numberOfRemovedFromAlert
			: incident.getIn(['state', 'affected_pages']);

		const numberOfAlertPages = (
			numberOfAffected
			+ (numberOfReverted || 0)
			+ numberOfRemovedFromAlert
		);

		return (
			<IncidentBoxContent
				button={numberOfAlertPages > 0 && (
					<AlertPagesButton
						focusOnPrimary={isIncidentActive && numberOfAffected > 0}
						incident={incident}
						websiteId={websiteId}
					/>
				)}
			>
				<SeparatedBlocks divider={false}>
					<ExpressiveValue
						label={(
							<FormattedMessage
								{...messages.affected}
								values={{
									count__pages: numberOfAffected,
								}}
							/>
						)}
						labelFlow={ExpressiveValueLabelFlow.Column}
						size={ExpressiveValueSize.Large}
						status={isIncidentActive ? ExpressiveValueStatus.Warning : ExpressiveValueStatus.Neutral}
						value={(
							<FormattedNumber value={numberOfAffected} />
						)}
					/>

					{numberOfReverted !== null && (
						<ExpressiveValue
							label={(
								<FormattedMessage
									{...messages.reverted}
									values={{
										count__pages: numberOfReverted,
									}}
								/>
							)}
							labelFlow={ExpressiveValueLabelFlow.Column}
							size={ExpressiveValueSize.Large}
							value={(
								<FormattedNumber value={numberOfReverted} />
							)}
						/>
					)}
				</SeparatedBlocks>

				{isModern && numberOfAffected > 0 && (
					<List>
						{[REASON_CANONICAL_LINK, REASON_META_ROBOTS, REASON_ROBOTS_TXT, REASON_X_ROBOTS_TAG].map(([messageKey, reason]) => {
							const numberOfPages = countPagesSplit(incident, reason);

							if (numberOfPages === 0) {
								return false;
							}

							return (
								<ExpressiveValue
									key={reason}
									label={(
										<FormattedMessage {...messages.reasons[messageKey]} />
									)}
									status={isIncidentActive ? ExpressiveValueStatus.Warning : ExpressiveValueStatus.Neutral}
									value={(
										<FormattedNumber value={numberOfPages} />
									)}
								/>
							);
						}).filter((metric) => !!metric)}
					</List>
				)}

				{numberOfRemovedFromAlert > 0 && (
					<RemovedFromAlertMetric
						numberOfRemovedFromAlert={numberOfRemovedFromAlert}
					/>
				)}
			</IncidentBoxContent>
		);
	}

}

PageBecameNonIndexableDetails.propTypes = {
	incident: PropTypes.object.isRequired,
	websiteId: PropTypes.string,
};



export default PageBecameNonIndexableDetails;
