import classNames from 'classnames';
import React from 'react';
import {
	useSortable,
} from '@dnd-kit/sortable';
import {
	CSS,
} from '@dnd-kit/utilities';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import AttributesGroupIcon, {
	type AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import DragHandler from '~/components/patterns/icons/DragHandler';
import DropdownIcon from '~/components/patterns/icons/DropdownIcon';

import styles from './ColumnsCategoriesListItem.module.scss';



type Props = {
	children?: React.ReactNode,
	icon: AttributesGroupIconType,
	isOpen?: boolean,
	isSortable?: boolean,
	label: React.ReactNode,
	name: string,
	onControlClick: (name: string) => void,
};



const ColumnsCategoriesListItem: React.FC<Props> = (props) => {
	const {
		children,
		icon,
		isOpen,
		isSortable = true,
		label,
		name,
		onControlClick,
	} = props;

	const {
		attributes,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		isDragging,
		transition,
	} = useSortable({
		id: name,
		disabled: !isSortable,
	});

	const style: React.CSSProperties = {
		transform: isSortable ? CSS.Transform.toString(transform) : undefined,
		transition,
		opacity: isDragging ? 0.5 : 1,
	};

	const controlClasses = classNames(
		styles.control,
		styles[`style-${name}`],
	);

	const dropdownClasses = classNames({
		[styles.dropdown]: true,
		[styles.dropdownOpen]: isOpen,
	});

	return (
		<div
			className={styles.component}
			ref={setNodeRef}
			style={style}
		>
			<button
				className={controlClasses}
				onClick={() => onControlClick(name)}
			>
				<div
					className={styles.label}
				>
					<AttachedIcon
						icon={(
							<AttributesGroupIcon
								size={20}
								type={icon}
							/>
						)}
					>
						{label}
					</AttachedIcon>
				</div>

				{isSortable && (
					<div
						className={styles.dragHandler}
						ref={setActivatorNodeRef}
						{...listeners}
						{...attributes}
					>
						<DragHandler />
					</div>
				)}

				<div
					className={styles.dropdownToggler}
				>
					<DropdownIcon
						isActive={isOpen}
					/>
				</div>
			</button>

			<div className={dropdownClasses}>
				<div className={styles.dropdownContent}>
					{children}
				</div>
			</div>
		</div>
	);
};



export default ColumnsCategoriesListItem;



type DragOverlayColumnsCategoriesListItemProps = Pick<Props, (
	| 'children'
	| 'icon'
	| 'isOpen'
	| 'label'
	| 'name'
)>;

export const DragOverlayColumnsCategoriesListItem: React.FC<DragOverlayColumnsCategoriesListItemProps> = (props) => {
	const {
		children,
		icon,
		isOpen,
		label,
		name,
	} = props;

	const componentClasses = classNames(
		styles.component,
		styles.isDragOverlay,
	);

	const controlClasses = classNames(
		styles.control,
		styles[`style-${name}`],
	);

	const dropdownClasses = classNames({
		[styles.dropdown]: true,
		[styles.dropdownOpen]: isOpen,
	});

	return (
		<div className={componentClasses}>
			<div className={controlClasses}>

				<div className={styles.label}>
					<AttachedIcon
						icon={(
							<AttributesGroupIcon
								size={20}
								type={icon}
							/>
						)}
					>
						{label}
					</AttachedIcon>
				</div>

				<div className={styles.dragHandler}>
					<DragHandler />
				</div>

				<div className={styles.dropdownToggler}>
					<DropdownIcon isActive={isOpen} />
				</div>
			</div>

			<div className={dropdownClasses}>
				<div className={styles.dropdownContent}>
					{children}
				</div>
			</div>

		</div>
	);
};
