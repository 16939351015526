import {
	DndContext,
	type DragEndEvent,
	DragOverlay,
	type DragStartEvent,
	type Modifier,
} from '@dnd-kit/core';
import {
	SortableContext,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import classNames from 'classnames';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import React from 'react';
import {
	isFragment,
} from 'react-is';
import {
	Grid,
	ScrollSync,
} from 'react-virtualized';
import useMeasure from 'react-use-measure';

import DisabledContent from '~/components/patterns/content/DisabledContent';
import NewTableBody from './NewTableBody';
import NewTableRow, {
	NewTableRowType,
} from '~/components/patterns/tables/newTable/NewTableRow';

import arrayMove from '~/utilities/arrayMove';
import {
	renderProp,
} from '~/utilities/renderProp';
import {
	isArray,
	isNumber,
	isString,
} from '~/utilities/typeCheck';
import {
	restrictToBoundingRect,
} from '~/utilities/dndKitRestrictToBoundingRects';
import Measurer from '~/utilities/Measurer';
import times from 'lodash/times';



export const RowHeight = 32;
export const DragHandleCellWidth = 40;



export type RowProps = {
	ref: (element?: HTMLElement | null) => any,
	style: React.CSSProperties,
};



type Dimension = number | [number, number];

export type NewTableChildProps<Row extends {}> = {
	isDraggingInProgress: boolean,
	isDragOverlay: boolean,
	isEven: boolean,
	row: Row,
	rowIndex: number,
};

export type Props<
	IsSortable extends boolean,
	// When IsSortable is true, rows must have an id property
	Row extends (IsSortable extends true ? { id: number | string } : {}),
> = {
	children: (props: NewTableChildProps<Row>) => React.ReactElement<HTMLElement>,
	footer?: React.ReactNode,
	header?: React.ReactNode,
	headerRow?: React.ReactNode,
	headerRowHeight?: number,
	height?: Dimension,
	isSortable?: IsSortable,
	onSort?: (
		rows: ReadonlyArray<Row> | Array<Row>,
		rowSourceIndex: number,
		rowDestinationIndex: number,
	) => void,
	overlay?: React.ReactNode,
	rows: ReadonlyArray<Row> | Array<Row>,
	rowHeight?: number,
	width?: Dimension,
} & ({
	columnWidths?: Array<number | `${number}%` | 'auto'>,
	columnCount?: never,
} | {
	columnWidths?: never,
	columnCount: number,
});

function NewTable<
	IsSortable extends boolean,
	Row extends (IsSortable extends true ? { id: number | string } : { id?: any }),
>(props: Props<IsSortable, Row>) {
	const {
		children,
		columnWidths = [],
		footer,
		header,
		headerRowHeight = RowHeight,
		headerRow,
		height,
		isSortable = false,
		onSort = null,
		overlay = null,
		rows,
		rowHeight = RowHeight,
		width = 0,
	} = props;

	const [draggingRowId, setDraggingRowId] = React.useState<Row['id'] | null>(null);

	const tableRef = React.useRef<HTMLTableElement | null>(null);
	const [gridElement, setGridElement] = React.useState<HTMLDivElement | null>(null);

	const rowCount = rows.length;

	const [headerRef, headerBounds] = useMeasure();
	const [footerRef, footerBounds] = useMeasure();

	const [minWidth, maxWidth] = React.useMemo(
		() => isArray(width) ? width : [width, width],
		[width],
	);

	const [minHeight, maxHeight] = React.useMemo(
		() => isArray(height) ? height : [height, height],
		[height],
	);

	const [
		gridColumnWidth,
		minTbodyHeight,
		styleVars,
		tableHeight,
		tableWidth,
		theadWidth,
	] = React.useMemo(
		() => {
			const hasVerticalScrollbar = gridElement !== null && gridElement.scrollHeight > gridElement.clientHeight;
			const hasHorizontalScrollbar = gridElement !== null && gridElement.scrollWidth > gridElement.clientWidth;

			const scrollbarHeight = hasHorizontalScrollbar ? scrollbarSize() : 0;
			const scrollbarWidth = hasVerticalScrollbar ? scrollbarSize() : 0;

			const tableWidth = tableRef.current?.clientWidth ?? gridElement?.clientWidth ?? 0;
			let contentWidth = isNumber(tableWidth) ? Math.max(tableWidth, minWidth) : minWidth;
			contentWidth -= scrollbarWidth;

			let tableHeight: number = 0;
			let minTbodyHeight: number = 0;

			if (maxHeight) {
				tableHeight = maxHeight;
				tableHeight -= 2;
				tableHeight -= headerBounds.height;
				tableHeight -= footerBounds.height;
				tableHeight -= !!headerRow ? headerRowHeight : 0;
				tableHeight = Math.min(rowCount * rowHeight + scrollbarHeight, tableHeight);
			} else {
				tableHeight = rowCount * rowHeight + scrollbarHeight;
			}

			if (minHeight) {
				minTbodyHeight = minHeight;
				minTbodyHeight -= 2;
				minTbodyHeight -= headerBounds.height;
				minTbodyHeight -= footerBounds.height;
				minTbodyHeight -= !!headerRow ? headerRowHeight : 0;
				minTbodyHeight = Math.floor(minTbodyHeight);
			}

			let gridColumnWidth = 0;
			let totalColumnsWidth = 0;
			let autoWidthColumns = 0;

			const styleVars = {
				'--row-height': `${rowHeight}px`,
				'--header-row-height': `${headerRowHeight}px`,
			};

			const columnWidthsWithDragHandle = (
				isSortable ? [...columnWidths, DragHandleCellWidth] : columnWidths
			);

			columnWidthsWithDragHandle.forEach((columnWidth, index) => {
				let pixelWidth: number = 0;

				if (isString(columnWidth)) {
					if (columnWidth.endsWith('%')) {
						const ratio = parseFloat(columnWidth.replace('%', '')) / 100;

						pixelWidth = (contentWidth - scrollbarWidth) * ratio;
					} else if (columnWidth === 'auto') {
						autoWidthColumns += 1;
					}
				} else {
					pixelWidth = columnWidth;
				}

				styleVars[`--column-${index + 1}-width`] = `${pixelWidth}px`;
				totalColumnsWidth += pixelWidth;
			});

			const leftoverWidth = Math.max(contentWidth - totalColumnsWidth, 0);
			const autoWidthColumnWidth = (
				autoWidthColumns > 0
					? Math.floor(leftoverWidth / autoWidthColumns)
					: 0
			);
			totalColumnsWidth += leftoverWidth;

			columnWidthsWithDragHandle.forEach((columnWidth, index) => {
				if (columnWidth === 'auto') {
					styleVars[`--column-${index + 1}-width`] = `${autoWidthColumnWidth}px`;
				}
			});

			gridColumnWidth = totalColumnsWidth;
			const theadWidth = totalColumnsWidth + scrollbarWidth;

			return [
				gridColumnWidth,
				minTbodyHeight,
				styleVars,
				tableHeight,
				tableWidth,
				theadWidth,
			];
		},
		[
			columnWidths,
			footerBounds,
			gridElement,
			headerBounds,
			headerRow,
			headerRowHeight,
			isSortable,
			maxHeight,
			minHeight,
			minWidth,
			rowCount,
			rowHeight,
		],
	);

	type RenderRowProps = {
		isDragOverlay?: boolean,
		rowIndex: number,
		style?: React.CSSProperties,
	};
	const renderRow = React.useCallback(
		(rowProps: RenderRowProps) => {
			const {
				rowIndex,
				style,
				isDragOverlay = false,
			} = rowProps;

			const row = rows[rowIndex] as Row;
			const isEven = rowIndex % 2 === 1;
			const isDraggingInProgress = draggingRowId !== null;
			const isDraggingBackground = !isDragOverlay && draggingRowId === row.id;

			const child = renderProp(children, {
				isDragOverlay,
				isDraggingInProgress,
				isEven,
				row,
				rowIndex,
			}) as React.ReactElement;

			const rowStyle: React.CSSProperties = {
				...style,
				opacity: isDraggingBackground ? 0.5 : 1,
			};

			const props = {
				id: row.id,
				isDraggingInProgress,
				isDragOverlay,
				isEven,
				isSortable,
				style: rowStyle,
			};

			if (isFragment(child)) {
				return (
					<NewTableRow {...props}>
						{child}
					</NewTableRow>
				);
			}

			return (
				React.cloneElement(child, props)
			);
		},
		[
			children,
			draggingRowId,
			isSortable,
			rows,
		],
	);

	const handleDragStart = React.useCallback(
		(event: DragStartEvent) => {
			setDraggingRowId(event.active.id);
		},
		[],
	);

	const handleDragEnd = React.useCallback(
		(event: DragEndEvent) => {
			const {
				active,
				over,
			} = event;

			if (over === null || onSort === null) {
				setDraggingRowId(null);
				return;
			}

			if (active.id !== over.id) {
				const rowSourceIndex = rows.findIndex((row) => row.id === active.id);
				const rowDestinationIndex = rows.findIndex((row) => row.id === over.id);

				const nextRows = arrayMove(rows, rowSourceIndex, rowDestinationIndex);

				onSort(nextRows, rowSourceIndex, rowDestinationIndex);
			}

			setDraggingRowId(null);
		},
		[
			onSort,
			rows,
		],
	);

	const restrictToGridElement = React.useCallback<Modifier>(
		(args) => {
			if (gridElement === null || args.draggingNodeRect === null || args.containerNodeRect === null) {
				return args.transform;
			}

			let transform = args.transform;
			if (args.activatorEvent instanceof PointerEvent) {
				transform.y = (args.activatorEvent.clientY - args.draggingNodeRect.top - args.draggingNodeRect.height / 2) + args.transform.y;
			}

			transform = restrictToBoundingRect(transform, args.draggingNodeRect, gridElement.getBoundingClientRect());

			return transform;
		},
		[gridElement],
	);

	return (
		<div
			className="new-table"
			style={{
				...styleVars,
				maxHeight,
				maxWidth,
				minHeight,
			}}
		>
			<DndContext
				modifiers={[restrictToGridElement]}
				onDragEnd={handleDragEnd}
				onDragStart={handleDragStart}
			>
				<SortableContext
					disabled={!isSortable}
					items={rows as Array<{ id: string | number }>}
					strategy={verticalListSortingStrategy}
				>
					<DragOverlay>
						{draggingRowId !== null && (
							renderRow({
								rowIndex: rows.findIndex((row) => row.id === draggingRowId),
								isDragOverlay: true,
								style: { pointerEvents: 'none' },
							})
						)}
					</DragOverlay>
					<div
						className="new-table__header"
						ref={headerRef}
					>
						{header}
					</div>

					<ScrollSync>
						{({
							onScroll,
							scrollLeft,
						}) => (
							<DisabledContent
								disabledContent={overlay !== null}
								disabledOverlay={overlay}
							>
								<div
									className="new-table__table"
									ref={tableRef}
								>
									{headerRow && (
										<div style={{ transform: `translateX(${-scrollLeft}px)`, width: theadWidth }}>
											<NewTableRow
												id="header"
												type={NewTableRowType.Header}
											>
												{headerRow}
											</NewTableRow>
										</div>
									)}

									<NewTableBody minHeight={minTbodyHeight}>
										<Grid
											cellRenderer={renderRow}
											columnCount={1}
											columnWidth={gridColumnWidth - 2}
											height={tableHeight}
											onScroll={onScroll}
											ref={(ref) => {
												if (
													ref !== null
													&& '_scrollingContainer' in ref
													&& ref._scrollingContainer instanceof HTMLDivElement
													&& gridElement !== ref._scrollingContainer
												) {
													setGridElement(ref._scrollingContainer);
												}
											}}
											rowCount={rowCount}
											rowHeight={rowHeight}
											width={tableWidth}
										/>
									</NewTableBody>
								</div>
							</DisabledContent>
						)}
					</ScrollSync>

					{footer && (
						<div
							className={(
								classNames({
									'new-table__footer': true,
									'new-table__footer--hide-border': !headerRow && rows.length === 0,
								})
							)}
							ref={footerRef}
						>
							{footer}
						</div>
					)}
				</SortableContext>
			</DndContext>
		</div>
	);
}



function NewTableMeasureWrapper<
	IsSortable extends boolean,
	Row extends (IsSortable extends true ? { id: number | string } : {}),
>(props: Props<IsSortable, Row>) {
	const height = props.height;
	let columnWidths = props.columnWidths;

	if (columnWidths === undefined) {
		columnWidths = times(props.columnCount ?? 1, () => 'auto');
	}

	if (props.width === undefined) {
		return (
			<Measurer>
				{({ containerWidth }) => (
					<NewTable
						{...props}
						columnCount={undefined}
						columnWidths={columnWidths}
						height={height}
						width={containerWidth}
					/>
				)}
			</Measurer>
		);
	}

	return (
		<NewTable
			{...props}
			columnCount={undefined}
			columnWidths={columnWidths}
			height={height}
		/>
	);
}



export default NewTableMeasureWrapper;
