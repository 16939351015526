import type GraphQL from '~/types/graphql';



export enum WebVital {
	CLS = 'cls',
	FCP = 'fcp',
	INP = 'inp',
	LCP = 'lcp',
	Performance = 'performance',
	SI = 'si',
	TBT = 'tbt',
	TTI = 'tti',
}



export function compareLighthouseThresholds(
	thresholdsA: GraphQL.WebsiteLighthouseThresholds,
	thresholdsB: GraphQL.WebsiteLighthouseThresholds,
): boolean {
	const webvitals = [WebVital.CLS, WebVital.FCP, WebVital.LCP, WebVital.Performance, WebVital.SI, WebVital.TBT, WebVital.TTI];

	for (const type of webvitals) {
		if (!thresholdsA[type] || !thresholdsB[type]) {
			return false;
		}

		if (thresholdsA[type][0] !== thresholdsB[type][0]) {
			return false;
		}

		if (thresholdsA[type][1] !== thresholdsB[type][1]) {
			return false;
		}
	}

	return true;
}



export function getWebVitalByName(name: string): WebVital | null {
	const mapping = {
		'cls': WebVital.CLS,
		'cumulativelayoutshift': WebVital.CLS,
		'cumulative-layout-shift': WebVital.CLS,
		'cumulativelayoutshiftscore': WebVital.CLS,
		'cumulative-layout-shift-score': WebVital.CLS,
		'fcp': WebVital.FCP,
		'firstcontentfulpaint': WebVital.FCP,
		'first-contentful-paint': WebVital.FCP,
		'inp': WebVital.INP,
		'interactiontonextpaint': WebVital.INP,
		'interaction-to-next-paint': WebVital.INP,
		'lcp': WebVital.LCP,
		'largestcontentfulpaint': WebVital.LCP,
		'largest-contentful-paint': WebVital.LCP,
		'performance': WebVital.Performance,
		'si': WebVital.SI,
		'speedindex': WebVital.SI,
		'speed-index': WebVital.SI,
		'tbt': WebVital.TBT,
		'totalblockingtime': WebVital.TBT,
		'total-blocking-time': WebVital.TBT,
		'tti': WebVital.TTI,
		'timetointeractive': WebVital.TTI,
		'time-to-interactive': WebVital.TTI,
	};

	return mapping[name.toLowerCase()] ?? null;
}
