import GraphQL from '~/types/graphql';



export enum BillingCycleComparison {
	Longer = 'longer',
	Same = 'same',
	Shorter = 'shorter',
}



const BillingCycleDurations = {
	[GraphQL.Term.Annually]: 12,
	[GraphQL.Term.Monthly]: 1,
	[GraphQL.Term.Quarterly]: 3,
} as const;



export const BillingCycleByDuration = [
	GraphQL.Term.Monthly,
	GraphQL.Term.Quarterly,
	GraphQL.Term.Annually,
] as const;



export function getBillingCycleDurationInMonths(billingCycle: GraphQL.Term) {
	return BillingCycleDurations[billingCycle];
}



type ApplyBillingCycleToCostInput = {
	baseBillingCycle: GraphQL.Term,
	cost: number,
	newBillingCycle: GraphQL.Term,
};

export function applyBillingCycleToCost({
	baseBillingCycle,
	cost,
	newBillingCycle,
}: ApplyBillingCycleToCostInput) {
	const baseBillingCycleDuration = getBillingCycleDurationInMonths(baseBillingCycle);
	const newBillingCycleDuration = getBillingCycleDurationInMonths(newBillingCycle);

	return cost * (newBillingCycleDuration / baseBillingCycleDuration);
}



export function compareBillingCycles(previous: GraphQL.Term, next: GraphQL.Term) {
	if (previous === next) {
		return BillingCycleComparison.Same;
	}

	const previousBillingCycleDuration = getBillingCycleDurationInMonths(previous);
	const nextBillingCycleDuration = getBillingCycleDurationInMonths(next);

	return (
		previousBillingCycleDuration > nextBillingCycleDuration
			? BillingCycleComparison.Shorter
			: BillingCycleComparison.Longer
	);
}
