/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountWebsiteSizesQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountWebsiteSizesQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pageCapacity: number, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId } | null }> } | null };


export const AccountWebsiteSizesDocument = gql`
    query AccountWebsiteSizes($accountId: AccountId!) {
  account(id: $accountId) {
    id
    websites {
      account {
        id
      }
      id
      pageCapacity
    }
  }
}
    `;

/**
 * __useAccountWebsiteSizesQuery__
 *
 * To run a query within a React component, call `useAccountWebsiteSizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountWebsiteSizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountWebsiteSizesQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountWebsiteSizesQuery(baseOptions: Apollo.QueryHookOptions<AccountWebsiteSizesQuery, AccountWebsiteSizesQueryVariables> & ({ variables: AccountWebsiteSizesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountWebsiteSizesQuery, AccountWebsiteSizesQueryVariables>(AccountWebsiteSizesDocument, options);
      }
export function useAccountWebsiteSizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountWebsiteSizesQuery, AccountWebsiteSizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountWebsiteSizesQuery, AccountWebsiteSizesQueryVariables>(AccountWebsiteSizesDocument, options);
        }
export function useAccountWebsiteSizesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountWebsiteSizesQuery, AccountWebsiteSizesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountWebsiteSizesQuery, AccountWebsiteSizesQueryVariables>(AccountWebsiteSizesDocument, options);
        }
export type AccountWebsiteSizesQueryHookResult = ReturnType<typeof useAccountWebsiteSizesQuery>;
export type AccountWebsiteSizesLazyQueryHookResult = ReturnType<typeof useAccountWebsiteSizesLazyQuery>;
export type AccountWebsiteSizesSuspenseQueryHookResult = ReturnType<typeof useAccountWebsiteSizesSuspenseQuery>;
export type AccountWebsiteSizesQueryResult = Apollo.QueryResult<AccountWebsiteSizesQuery, AccountWebsiteSizesQueryVariables>;