import type CK from '~/types/contentking';

import {
	useAccountHasLegacyBillingDetailsQuery,
} from './useAccountHasLegacyBillingDetails.gql';

import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';



function useAccountHasLegacyBillingDetails(accountId: CK.AccountId | null) {
	const { data } = useAccountPropertiesQuery(
		useAccountHasLegacyBillingDetailsQuery,
		accountId,
	);

	return data?.account?.hasLegacyBillingDetails ?? null;
}



export default useAccountHasLegacyBillingDetails;
