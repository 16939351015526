import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ArrayGroupField, {
	type ArrayGroupFieldRendererProps,
	type ArrayGroupFieldRowDisabledInput,
	type ArrayGroupFieldValidationInput,
} from '~/components/patterns/forms/fields/ArrayGroupField';
import TextField from '~/components/atoms/forms/components/TextField';

import useFormContext from '~/hooks/useFormContext';
import useKingdomAdminFeatures from '~/hooks/useKingdomAdminFeatures';

import {
	isNonEmptyString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	addButton: {
		id: 'ui.customHttpHeadersSetup.addButton',
	},
	columnName: {
		id: 'ui.customHttpHeadersSetup.columns.name',
	},
	columnValue: {
		id: 'ui.customHttpHeadersSetup.columns.value',
	},
	formErrorAuthorization: {
		id: 'ui.customHttpHeadersSetup.errors.authorization',
	},
	formErrorBlacklisted: {
		id: 'ui.customHttpHeadersSetup.errors.blacklisted',
	},
	formErrorBlank: {
		id: 'ui.formErrors.blank',
	},
	formErrorUserAgent: {
		id: 'ui.customHttpHeadersSetup.errors.userAgent',
	},
});

const HEADERS_WHITELIST = [
	/^Accept$/i,
	/^Accept-Language$/i,
	/^Authorization$/i,
	/^Cache-Control$/i,
	/^Forwarded$/i,
	/^Proxy-Authorization$/i,
	/^Referer$/i,
	/^X-[a-z0-9_-]+$/i,
];



type Props = {
	isHttpAuthenticationEnabled: boolean,
	name: string,
};

const HttpHeadersField: React.FC<Props> = (props) => {
	const {
		isHttpAuthenticationEnabled,
		name,
	} = props;

	const formContext = useFormContext();
	const kingdomAdminFeatures = useKingdomAdminFeatures();

	const defaultValue = formContext.defaultValues[name];

	const isRowDisabled = React.useCallback(
		({ rowId }: ArrayGroupFieldRowDisabledInput) => {
			const headerName = defaultValue?.[rowId]?.name ?? '';

			const isWhitelisted = (
				headerName?.trim() === ''
				|| HEADERS_WHITELIST.some((pattern) => pattern.test(headerName))
			);

			// If a header is not whitelisted and we are not in kingdom admin mode,
			// disable the row. This header was set by an admin and should not be changed.
			if (kingdomAdminFeatures.areVisible === false && !isWhitelisted) {
				return true;
			}

			return false;
		},
		[
			defaultValue,
			kingdomAdminFeatures.areVisible,
		],
	);

	const fields = React.useMemo(
		() => {
			return [
				{
					defaultValue: '',
					label: (
						<FormattedMessage {...messages.columnName} />
					),
					name: 'name',
					renderer: ({ fieldName, isDisabled }: ArrayGroupFieldRendererProps) => (
						<TextField
							disabled={isDisabled}
							name={fieldName}
							width="100%"
						/>
					),
					validation: ({ f, getValueInRow, rowId, rowIndex }: ArrayGroupFieldValidationInput) => ([
						f.when(({ values }) => {
							return !isRowDisabled({ rowId, rowIndex, values });
						}),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorUserAgent} />
							),
							rule: ({ value }) => {
								return !/^User-Agent$/i.test(value);
							},
						}),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorAuthorization} />
							),
							rule: ({ value }) => {
								const isAuthorizationHeader = /^Authorization$/i.test(value);

								return isHttpAuthenticationEnabled ? !isAuthorizationHeader : true;
							},
						}),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorBlacklisted} />
							),
							rule: ({ value }) => {
								if (value === undefined || value?.trim() === '') {
									return true;
								}

								if (kingdomAdminFeatures.areVisible) {
									return true;
								}

								return HEADERS_WHITELIST.some((pattern) => pattern.test(value));
							},
						}),
						f.when(
							({ values }) => {
								const headerValue = getValueInRow(values, 'value');

								return isNonEmptyString(headerValue);
							},
						),
						f.validateNonEmpty(),
					]),
					width: 220,
				},
				{
					defaultValue: '',
					label: (
						<FormattedMessage {...messages.columnValue} />
					),
					name: 'value',
					renderer: ({ fieldName, isDisabled }: ArrayGroupFieldRendererProps) => (
						<TextField
							disabled={isDisabled}
							name={fieldName}
							width="100%"
						/>
					),
					validation: ({ f, getValueInRow }: ArrayGroupFieldValidationInput) => ([
						f.when(
							({ values }) => {
								const name = getValueInRow(values, 'name');

								return isNonEmptyString(name);
							},
						),
						f.validateNonEmpty(),
					]),
				},
			];
		},
		[
			isHttpAuthenticationEnabled,
			isRowDisabled,
			kingdomAdminFeatures.areVisible,
		],
	);

	return (
		<ArrayGroupField
			addButtonLabel={(
				<FormattedMessage {...messages.addButton} />
			)}
			fields={fields}
			isRowDisabled={isRowDisabled}
			minimumRows={1}
			name={name}
		/>
	);
};



export default HttpHeadersField;
