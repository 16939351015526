import memoize from 'memoizee';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import CK from '~/types/contentking';

import BooleanFormatter from '~/components/app/BooleanFormatter';
import ChangeStatusLabel from '~/components/patterns/statuses/ChangeStatusLabel';
import ChangedChangeStatusLabel from '~/components/app/ChangedChangeStatusLabel';
import CheckboxGroupContainer from '~/components/logic/checkboxGroup/CheckboxGroupContainer';
import ColumnsConfigurator from '~/components/logic/columnsConfigurator/ColumnsConfigurator';
import ColumnsConfiguratorOverlayLayout from '~/components/patterns/columnsConfigurator/ColumnsConfiguratorOverlayLayout';
import DiffFormatter from '~/components/logic/formatters/DiffFormatter';
import EmptyResultOverlay from '~/components/logic/datatables/EmptyResultOverlay';
import FieldFormatter from '~/components/logic/formatters/FieldFormatter';
import FullwidthCell from '~/components/atoms/dataTables/parts/FullwidthCell';
import NewColumnHeader from '~/components/atoms/dataTables/parts/NewColumnHeader';
import ResizableDatatable, {
	type ResizableDatatableColumnDefinition,
	type ResizableDatatableContextMenuEntries,
	ResizableDatatableHeaderCellColorStyle,
	type ResizableDatatableRef,
	type ResizableDatatableRowGetterInput,
	ResizableDatatableRowHeight,
} from '~/components/logic/datatables/ResizableDatatable';
import SegmentsDiffFormatter from '~/components/logic/formatters/SegmentsDiffFormatter';

import {
	renderEllipsisCell,
} from './utils/ReactVirtualizedCells';

import {
	closeColumnsConfigurator,
} from '~/actions';

import {
	CHANGES_IN_ALL_SELECTED_COLUMNS,
	CHANGES_IN_ANY_COLUMN,
	CHANGES_IN_ANY_SELECTED_COLUMN,
	CHANGES_IN_ANY_VISIBLE_COLUMN,
	CHANGE_TYPE_CHANGED,
	removeDefaultFilterValues as removeHistoricalDefaultFilterValues,
} from '~/model/historicalChanges';

import {
	PagesColumnsCategory,
	getColumnCategory,
	getColumnDefaultWidth,
	isTrackedChangesColumn,
} from '~/model/pagesColumns';

import {
	type SegmentDefinition,
	SegmentDepends,
	dependsOnColumnsWithoutChangeTracking,
	filterSegmentDefinitionsByNames,
} from '~/model/segments';

import {
	columnsConfiguratorVisibilitySelector,
} from '~/state/ui/selectors';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	[SegmentDepends.OnLinkColumns]: {
		id: 'ui.segments.selection.inTrackedChanges.disabled.dueToLinksColumns',
	},
	[SegmentDepends.OnSchemaOrgColumns]: {
		id: 'ui.segments.selection.inTrackedChanges.disabled.dueToSchemaOrgColumns',
	},
	[SegmentDepends.OnTimeServerResponseColumn]: {
		id: 'ui.segments.selection.inTrackedChanges.disabled.dueToTimeServerResponseColumn',
	},
});

const changeTypeMessages = defineMessages({
	added: {
		id: 'ui.contentOverview.changeTracking.changeType.value.added',
	},
	changed: {
		id: 'ui.contentOverview.changeTracking.changeType.value.changed',
	},
	other: {
		id: 'ui.contentOverview.changeTracking.changeType.value.other',
	},
	redirected: {
		id: 'ui.contentOverview.changeTracking.changeType.value.redirected',
	},
	removed: {
		id: 'ui.contentOverview.changeTracking.changeType.value.removed',
	},
});



const MAXIMUM_ROWS = 250000;



const createSegmentsNotAllowedForFiltering = memoize((segments) => {
	const result = {};

	segments.forEach((segment) => {
		const depends = dependsOnColumnsWithoutChangeTracking(segments, segment);

		if (depends) {
			result[segment.name] = (
				<FormattedMessage {...messages[depends]} />
			);
		}
	});

	return result;
});



function createChangeTypeFormValue(filter) {
	let result = filter.get('change_type');

	if (filter.get('changes_in') === CHANGES_IN_ALL_SELECTED_COLUMNS) {
		result = result.push('changed_in_all_selected_columns');
	} else if (filter.get('changes_in') === CHANGES_IN_ANY_COLUMN) {
		result = result.push('changed_in_any_column');
	} else if (filter.get('changes_in') === CHANGES_IN_ANY_SELECTED_COLUMN) {
		result = result.push('changed_in_any_selected_column');
	} else if (filter.get('changes_in') === CHANGES_IN_ANY_VISIBLE_COLUMN) {
		result = result.push('changed_in_any_visible_column');
	}

	return result.toArray();
}

const isSegmentFilterActive = memoize((columns, filter, segments) => {
	return (
		filter.has('segments')
		&& filterSegmentDefinitionsByNames(
			segments,
			[
				...filter.get('segments').get('included_in').toArray(),
				...filter.get('segments').get('not_included_in').toArray(),
			],
		).some(
			(segmentDefinition) => Object.keys(segmentDefinition.filterDefinition).some(
				(field) => columns.indexOf(field) !== -1,
			),
		)
	);
});



const columnDefinitions = {
	canonical_type: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 180,
	},
	canonical_url: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	change_type: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		renderer: (row, a, b, c, allColumns, intl, filter, scrollToColumn) => {
			if (row.get('change_type') === CHANGE_TYPE_CHANGED) {
				return (
					<ChangedChangeStatusLabel
						allColumns={filter.get('changes_in') === CHANGES_IN_ANY_COLUMN ? true : allColumns}
						changes={row.get('changes_in')}
						scrollToColumn={scrollToColumn}
					/>
				);
			}

			return (
				<ChangeStatusLabel changeType={row.get('change_type')}>
					<FormattedMessage {...changeTypeMessages[row.get('change_type')]} />
				</ChangeStatusLabel>
			);
		},
		width: 180,
	},
	domain: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		renderer: (row) => renderEllipsisCell(
			row.get('domain'),
		),
		width: 180,
	},
	time_found: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		renderer: (row, column, dataChangeOnly, segmentDefinitions, allColumns, intl) => {
			return row.get('time_found')
				? renderEllipsisCell(
					intl.formatDate(row.get('time_found')),
				)
				: false;
		},
		width: 230,
	},
	h1: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	hreflang_language: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 180,
	},
	is_disallowed_in_robots_txt: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 135,
	},
	is_in_sitemap: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 135,
	},
	is_indexable: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 125,
	},
	is_indexable_due_to_meta_robots: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 135,
	},
	is_indexable_due_to_x_robots_tag: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 135,
	},
	is_linked: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 80,
	},
	is_secured: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		renderer: (row) => (
			<BooleanFormatter value={row.get('is_secured')} />
		),
		width: 80,
	},
	link_amp: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 230,
	},
	link_next: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 230,
	},
	link_prev: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 230,
	},
	meta_description: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	mobile_variant: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 230,
	},
	number_of_hreflangs: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 230,
	},
	open_graph_description: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	open_graph_image: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	open_graph_title: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	open_graph_type: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	open_graph_url: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	relevance: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 110,
	},
	health: {
		allowFilter: true,
		isLoaded: (row) => row.has('health'),
		renderer: (row, foo, isColored) => (
			<DiffFormatter
				customElements={null}
				formatter={(value, pageType) => {
					return (
						<FieldFormatter
							column={CK.PagesCommonColumn.NumberOfHreflangs}
							pageType={pageType}
							value={value}
							zIndex={1200}
						/>
					);
				}}
				isColored={isColored}
				pageType={row.get('type')}
				value={row.get('health')}
			/>
		),
		width: 100,
	},
	redirect: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	segments: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		renderer: (row, columnWidth) => {
			return (
				<SegmentsDiffFormatter
					value={row.get('segments_comparison')}
					width={columnWidth}
				/>
			);
		},
		width: 250,
	},
	status_code: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 100,
	},
	title: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	twitter_card: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	twitter_description: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	twitter_image: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	twitter_site: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	twitter_title: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	type: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 150,
	},
	url: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		renderer: (row) => renderEllipsisCell(
			row.get('displayUrl'),
		),
		width: 280,
	},
	url_depth: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		renderer: (row) => renderEllipsisCell(
			row.get('url_depth'),
		),
		width: 150,
	},
	url_full: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		renderer: (row) => renderEllipsisCell(
			row.get('url_full'),
		),
		width: 320,
	},
	analytics_services: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	visual_analytics_services: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
	tag_managers: {
		allowFilter: true,
		isLoaded: (row, column) => row.has(column),
		width: 280,
	},
};

const COLUMNS_WITH_FLIPPED_SORTING: ReadonlyArray<CK.PagesColumn> = [
	CK.PagesCommonColumn.AaAverageTimeSpentOnSite,
	CK.PagesCommonColumn.AaPageViews,
	CK.PagesCommonColumn.AaRevenue,
	CK.PagesCommonColumn.AaUniqueVisitors,
	CK.PagesCommonColumn.GaAverageTime,
	CK.PagesCommonColumn.GaPageValue,
	CK.PagesCommonColumn.GaPageViews,
	CK.PagesCommonColumn.GaUniquePageViews,
	CK.PagesCommonColumn.GscClicks,
	CK.PagesCommonColumn.GscCtr,
	CK.PagesCommonColumn.GscImpressions,
	CK.PagesCommonColumn.LfaBingDesktopFrequency,
	CK.PagesCommonColumn.LfaBingDesktopLastVisit,
	CK.PagesCommonColumn.LfaBingFrequency,
	CK.PagesCommonColumn.LfaBingLastVisit,
	CK.PagesCommonColumn.LfaBingMobileFrequency,
	CK.PagesCommonColumn.LfaBingMobileLastVisit,
	CK.PagesCommonColumn.LfaGoogleDesktopFrequency,
	CK.PagesCommonColumn.LfaGoogleDesktopLastVisit,
	CK.PagesCommonColumn.LfaGoogleFrequency,
	CK.PagesCommonColumn.LfaGoogleLastVisit,
	CK.PagesCommonColumn.LfaGoogleMobileFrequency,
	CK.PagesCommonColumn.LfaGoogleMobileLastVisit,
	CK.PagesCommonColumn.NumberOfIncomingInternalCanonicals,
	CK.PagesCommonColumn.NumberOfIncomingInternalLinks,
	CK.PagesCommonColumn.NumberOfIncomingInternalRedirects,
	CK.PagesCommonColumn.NumberOfOutgoingExternalLinks,
	CK.PagesCommonColumn.NumberOfOutgoingInternalLinks,
	CK.PagesCommonColumn.Relevance,
];



const HEADER_HEIGHT = 76;
const HEADER_SEGMENT_FILTER_HEIGHT = 25;



type Props = {
	columns: Array<CK.PagesColumn>,
	contextMenuEntries: ResizableDatatableContextMenuEntries<any>,
	dataLoading: boolean,
	enabledColumns: any,
	filter: any,
	filterCallback: any,
	historicalChanges: any,
	onOffsetChange: any,
	onRowClick: (
		row: any,
		event: any,
	) => void,
	onSelectedColumnsChange: any,
	peekedColumns: any,
	resetFilter: any,
	segmentDefinitions: ReadonlyArray<SegmentDefinition>,
	sortBy: any,
	sortCallback: any,
	total: number,
};

const PagesHistoricalViewDataTable: React.FC<Props> = (props) => {
	const {
		columns,
		contextMenuEntries,
		dataLoading,
		enabledColumns,
		filter,
		filterCallback,
		historicalChanges,
		onOffsetChange,
		onRowClick,
		onSelectedColumnsChange,
		peekedColumns,
		resetFilter,
		segmentDefinitions,
		sortBy,
		sortCallback,
		total,
	} = props;

	const intl = useIntl();
	const dispatch = useDispatch();

	const datatableRef = React.useRef<ResizableDatatableRef>(null);

	const columnsConfiguratorVisibility: boolean = useSelector(columnsConfiguratorVisibilitySelector);

	const finalFilter = React.useMemo(
		() => {
			if (!filter) {
				return filter;
			}

			let result = filter;

			result = result.set('change_type', createChangeTypeFormValue(filter));

			return result;
		},
		[
			filter,
		],
	);

	const rowGetter = React.useCallback(
		({ rowIndex }: ResizableDatatableRowGetterInput) => {
			return historicalChanges.get(rowIndex) ?? null;
		},
		[
			historicalChanges,
		],
	);

	const handleRowClick = React.useCallback(
		({ row }, event) => {
			onRowClick(
				row,
				event,
			);
		},
		[
			onRowClick,
		],
	);

	const handleCloseColumnsConfigurator = React.useCallback(
		() => {
			dispatch(closeColumnsConfigurator());
		},
		[
			dispatch,
		],
	);

	const onLoadPagesCallback = React.useCallback(
		({ rowIndex }) => {
			onOffsetChange(rowIndex);
		},
		[
			onOffsetChange,
		],
	);

	const extraDefaultFilterValues = React.useMemo(
		() => {
			if (!filter) {
				return {};
			}

			const result: Record<string, any> = {};

			if (filter.get('changes_in_columns')) {
				columns.forEach((columnName) => {
					if (filter.get('changes_in_columns').includes(columnName)) {
						result['checkbox-' + columnName] = true;
					}
				});
			}

			return result;
		},
		[
			columns,
			filter,
		],
	);

	const segmentsNotAllowedForFiltering = createSegmentsNotAllowedForFiltering(segmentDefinitions);

	const finalColumns = React.useMemo(
		() => {
			return columns.map(
				(columnName): ResizableDatatableColumnDefinition<any> => {
					const columnCategory = getColumnCategory(columnName);

					return {
						headerColorStyle: matchAndReturn(columnCategory, {
							[PagesColumnsCategory.AdobeAnalytics]: ResizableDatatableHeaderCellColorStyle.AdobeAnalytics,
							[PagesColumnsCategory.Content]: ResizableDatatableHeaderCellColorStyle.Content,
							[PagesColumnsCategory.Conversions]: ResizableDatatableHeaderCellColorStyle.Conversions,
							[PagesColumnsCategory.CustomElements]: ResizableDatatableHeaderCellColorStyle.CustomElements,
							[PagesColumnsCategory.EnrichmentFields]: ResizableDatatableHeaderCellColorStyle.EnrichmentFields,
							[PagesColumnsCategory.Fundamentals]: ResizableDatatableHeaderCellColorStyle.Fundamentals,
							[PagesColumnsCategory.GoogleAnalytics]: ResizableDatatableHeaderCellColorStyle.GoogleAnalytics,
							[PagesColumnsCategory.GoogleSearchConsole]: ResizableDatatableHeaderCellColorStyle.GoogleSearchConsole,
							[PagesColumnsCategory.Indexability]: ResizableDatatableHeaderCellColorStyle.Indexability,
							[PagesColumnsCategory.Lighthouse]: ResizableDatatableHeaderCellColorStyle.Lighthouse,
							[PagesColumnsCategory.NoCategory]: ResizableDatatableHeaderCellColorStyle,
							[PagesColumnsCategory.Relations]: ResizableDatatableHeaderCellColorStyle.Realtions,
							[PagesColumnsCategory.SchemaOrg]: ResizableDatatableHeaderCellColorStyle.SchemaOrg,
							[PagesColumnsCategory.SearchEngineActivity]: ResizableDatatableHeaderCellColorStyle.SearchEngineActivity,
							[PagesColumnsCategory.Social]: ResizableDatatableHeaderCellColorStyle.Social,
						}) as any,
						headerIsStriped: (
							enabledColumns.includes(columnName) === false
							&& peekedColumns.includes(columnName)
						),
						name: columnName,
						render: {
							cell: ({ innerWidth, row }) => {
								// display colored comparison only for changed_type of 'changed' value
								const isColored = row && row.get('change_type') === 'changed';

								const columnDefinition = columnDefinitions[columnName];

								if (columnDefinition?.renderer) {
									return columnDefinition.renderer(
										row,
										innerWidth,
										isColored,
										segmentDefinitions,
										columns,
										intl,
										finalFilter,
										datatableRef.current?.scrollToColumn,
									);
								}

								return (
									<DiffFormatter
										customElements={null}
										formatter={(value, pageType) => {
											return (
												<FieldFormatter
													column={columnName}
													customElements={row.get('custom_elements')}
													pageType={pageType}
													value={value}
												/>
											);
										}}
										isColored={isColored}
										pageType={row.get(CK.PagesCommonColumn.Type)}
										value={row.get(columnName)}
									/>
								);
							},
							header: ({ columnIndex, filterRef, filterWidth }) => {
								return (
									<NewColumnHeader
										columnCount={columns.length}
										columnIndex={columnIndex}
										columnName={columnName}
										columnWidth={filterWidth}
										filter={finalFilter}
										isSegmentFilterActive={isSegmentFilterActive(columns, finalFilter, segmentDefinitions)}
										key={columnName}
										onFilterChangeCallback={filterCallback}
										ref={filterRef}
										segmentDefinitions={segmentDefinitions}
										segmentsNotAllowedForFiltering={segmentsNotAllowedForFiltering}
										sortCallback={sortCallback}
										sortingActive={sortBy.get('key') === columnName}
										sortingDirection={
											sortBy.get('key') === columnName
												? sortBy.get('direction')
												: null
										}
										sortingEnabled={columnName !== CK.PagesCommonColumn.Segments}
										sortingFixed={sortBy.get('fixed') ?? false}
										sortingFlipped={COLUMNS_WITH_FLIPPED_SORTING.includes(columnName)}
									/>
								);
							},
						},
						width: getColumnDefaultWidth(columnName) || 200,
					};
				},
			);
		},
		[
			columns,
			enabledColumns,
			filterCallback,
			finalFilter,
			intl,
			peekedColumns,
			segmentDefinitions,
			segmentsNotAllowedForFiltering,
			sortBy,
			sortCallback,
		],
	);

	const renderBlankState = React.useCallback(
		() => (
			<EmptyResultOverlay
				filter={finalFilter}
				removeDefaultValues={removeHistoricalDefaultFilterValues}
				resetAction={resetFilter}
			/>
		),
		[
			finalFilter,
			resetFilter,
		],
	);

	let calculatedHeaderHeight = HEADER_HEIGHT;

	// make table header higher when filtering on segments
	if (isSegmentFilterActive(columns, finalFilter, segmentDefinitions)) {
		calculatedHeaderHeight += HEADER_SEGMENT_FILTER_HEIGHT;
	}

	return (
		<ColumnsConfiguratorOverlayLayout
			columnsConfigurator={(
				<ColumnsConfigurator />
			)}
			isOpen={columnsConfiguratorVisibility}
			onClose={handleCloseColumnsConfigurator}
		>
			<CheckboxGroupContainer
				name="columns"
				onChangeCallback={({ checked }) => {
					onSelectedColumnsChange(checked);
				}}
				options={finalColumns.filter((column) => isTrackedChangesColumn(column.name as CK.PagesColumn)).map((column) => column.name as string)}
				value={finalFilter.get('changes_in_columns') ? finalFilter.get('changes_in_columns').toArray() : []}
			>
				<ResizableDatatable
					blankSlate={renderBlankState}
					columns={finalColumns}
					contextMenuEntries={contextMenuEntries}
					defaultFilterValues={extraDefaultFilterValues}
					filter={finalFilter}
					headerHeight={calculatedHeaderHeight}
					isLoading={dataLoading}
					lastRowNote={total > MAXIMUM_ROWS ? (
						<FullwidthCell
							limit={5000}
							rowIndex={Math.min(MAXIMUM_ROWS + 1, total) + 1}
							total={total}
						/>
					) : null}
					numberOfPinnedColumns={2}
					onFilterChange={filterCallback}
					onRowClick={handleRowClick}
					onScroll={onLoadPagesCallback}
					ref={datatableRef}
					rowGetter={rowGetter}
					rowHeightStyle={ResizableDatatableRowHeight.Medium}
					rowsCount={Math.min(MAXIMUM_ROWS + 1, total)}
				/>
			</CheckboxGroupContainer>
		</ColumnsConfiguratorOverlayLayout>
	);
};



export default PagesHistoricalViewDataTable;
