/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteAdminUsageOverviewQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteAdminUsageOverviewQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly quotas: ReadonlyArray<{ readonly __typename?: 'WebsiteQuota', readonly count: number, readonly limit: number, readonly type: string }> | null } | null };


export const WebsiteAdminUsageOverviewDocument = gql`
    query WebsiteAdminUsageOverview($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    quotas {
      count
      limit
      type
    }
  }
}
    `;

/**
 * __useWebsiteAdminUsageOverviewQuery__
 *
 * To run a query within a React component, call `useWebsiteAdminUsageOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteAdminUsageOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteAdminUsageOverviewQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteAdminUsageOverviewQuery(baseOptions: Apollo.QueryHookOptions<WebsiteAdminUsageOverviewQuery, WebsiteAdminUsageOverviewQueryVariables> & ({ variables: WebsiteAdminUsageOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteAdminUsageOverviewQuery, WebsiteAdminUsageOverviewQueryVariables>(WebsiteAdminUsageOverviewDocument, options);
      }
export function useWebsiteAdminUsageOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteAdminUsageOverviewQuery, WebsiteAdminUsageOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteAdminUsageOverviewQuery, WebsiteAdminUsageOverviewQueryVariables>(WebsiteAdminUsageOverviewDocument, options);
        }
export function useWebsiteAdminUsageOverviewSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteAdminUsageOverviewQuery, WebsiteAdminUsageOverviewQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteAdminUsageOverviewQuery, WebsiteAdminUsageOverviewQueryVariables>(WebsiteAdminUsageOverviewDocument, options);
        }
export type WebsiteAdminUsageOverviewQueryHookResult = ReturnType<typeof useWebsiteAdminUsageOverviewQuery>;
export type WebsiteAdminUsageOverviewLazyQueryHookResult = ReturnType<typeof useWebsiteAdminUsageOverviewLazyQuery>;
export type WebsiteAdminUsageOverviewSuspenseQueryHookResult = ReturnType<typeof useWebsiteAdminUsageOverviewSuspenseQuery>;
export type WebsiteAdminUsageOverviewQueryResult = Apollo.QueryResult<WebsiteAdminUsageOverviewQuery, WebsiteAdminUsageOverviewQueryVariables>;