import React from 'react';

import FileExportForm from '~/components/atoms/forms/forms/FileExportForm';

import useCurrentUserId from '~/hooks/useCurrentUserId';
import useExportAffectedLinks from '~/hooks/useExportAffectedLinks';
import useExportAlertPages from '~/hooks/useExportAlertPages';
import useExportHistoricalIssueAffectedPages from '~/hooks/useExportHistoricalIssueAffectedPages';
import useExportIssueAffectedPages from '~/hooks/useExportIssueAffectedPages';
import useExportIssueCategoryAffectedPages from '~/hooks/useExportIssueCategoryAffectedPages';
import useExportPageRelations from '~/hooks/useExportPageRelations';
import useExportPages from '~/hooks/useExportPages';
import useExportSitemaps from '~/hooks/useExportSitemaps';
import useExportTrackedChangesOnPages from '~/hooks/useExportTrackedChangesOnPages';
import useUrlState from '~/hooks/useUrlState';
import useUserLocale from '~/hooks/useUserLocale';

import matchAndReturn from '~/utilities/matchAndReturn';



type Props = {
	closeCallback: () => void,
};

const ExportPanel: React.FC<Props> = (props) => {
	const {
		closeCallback,
	} = props;

	const currentUserId = useCurrentUserId();

	const urlState = useUrlState();
	const userLocale = useUserLocale(currentUserId);

	const exportAffectedLinks = useExportAffectedLinks();
	const exportAlertPages = useExportAlertPages();
	const exportHistoricalIssueAffectedPages = useExportHistoricalIssueAffectedPages();
	const exportIssueAffectedPages = useExportIssueAffectedPages();
	const exportIssueCategoryAffectedPages = useExportIssueCategoryAffectedPages();
	const exportPageRelations = useExportPageRelations();
	const exportPages = useExportPages(0);
	const exportSitemaps = useExportSitemaps();
	const exportTrackedChangesOnPages = useExportTrackedChangesOnPages();

	if (userLocale === null) {
		return null;
	}

	const createExport = matchAndReturn(urlState.name, {
		'website.issues.issueCategory.singleIssueAffectedLinks.export': exportAffectedLinks,
		'website.alerts.alertPages.export': exportAlertPages,
		'website.issues.issueCategory.affectedPages.export': exportIssueCategoryAffectedPages,
		'website.issues.issueCategory.singleIssueAffectedPages.export': matchAndReturn(urlState.params.start_date ? 'historical' : 'current', {
			current: exportIssueAffectedPages,
			historical: exportHistoricalIssueAffectedPages,
		}),
		'website.pages.detail.links.export': exportPageRelations,
		'website.pages.export': matchAndReturn(urlState.params.start_date ? 'historical' : 'current', {
			current: exportPages,
			historical: exportTrackedChangesOnPages,
		}),
		'website.platform.sitemaps.export': exportSitemaps,
	});

	return (
		<FileExportForm
			createExport={createExport}
			currentLocale={userLocale}
			onCancelCallback={closeCallback}
		/>
	);
};



export default ExportPanel;
