/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountAdminTrialPhaseFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountAdminTrialPhaseFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly trialExpirationDate: CK.Timestamp | null, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly daysToConfigurationCleanup: number, readonly daysToDataCleanup: number, readonly daysToTrialRevival: number, readonly isTrialExtensionAllowedByCustomerTeam: GraphQL.BooleanOrAutomatic, readonly isTrialRevivalAllowedByCustomerTeam: boolean } | null } | null };

export type UpdateAccountAdminTrialPhaseSettingsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  daysToDataCleanup: GraphQL.Scalars['Int']['input'];
  daysToTrialRevival: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
  isTrialExtensionAllowedByCustomerTeam: GraphQL.BooleanOrAutomatic;
  isTrialRevivalAllowedByCustomerTeam: GraphQL.Scalars['Boolean']['input'];
  trialExpirationDate: GraphQL.Scalars['Timestamp']['input'];
}>;


export type UpdateAccountAdminTrialPhaseSettingsMutation = { readonly __typename?: 'Mutation', readonly UpdateAccountAdminTrialPhaseSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly trialExpirationDate: CK.Timestamp | null, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly daysToDataCleanup: number, readonly daysToTrialRevival: number, readonly isTrialExtensionAllowedByCustomerTeam: GraphQL.BooleanOrAutomatic, readonly isTrialRevivalAllowedByCustomerTeam: boolean } | null } | null } } };


export const AccountAdminTrialPhaseFormDocument = gql`
    query AccountAdminTrialPhaseForm($accountId: AccountId!) {
  account(id: $accountId) {
    adminSettings {
      daysToConfigurationCleanup
      daysToDataCleanup
      daysToTrialRevival
      isTrialExtensionAllowedByCustomerTeam
      isTrialRevivalAllowedByCustomerTeam
    }
    id
    trialExpirationDate
  }
}
    `;

/**
 * __useAccountAdminTrialPhaseFormQuery__
 *
 * To run a query within a React component, call `useAccountAdminTrialPhaseFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountAdminTrialPhaseFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountAdminTrialPhaseFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountAdminTrialPhaseFormQuery(baseOptions: Apollo.QueryHookOptions<AccountAdminTrialPhaseFormQuery, AccountAdminTrialPhaseFormQueryVariables> & ({ variables: AccountAdminTrialPhaseFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountAdminTrialPhaseFormQuery, AccountAdminTrialPhaseFormQueryVariables>(AccountAdminTrialPhaseFormDocument, options);
      }
export function useAccountAdminTrialPhaseFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountAdminTrialPhaseFormQuery, AccountAdminTrialPhaseFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountAdminTrialPhaseFormQuery, AccountAdminTrialPhaseFormQueryVariables>(AccountAdminTrialPhaseFormDocument, options);
        }
export function useAccountAdminTrialPhaseFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountAdminTrialPhaseFormQuery, AccountAdminTrialPhaseFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountAdminTrialPhaseFormQuery, AccountAdminTrialPhaseFormQueryVariables>(AccountAdminTrialPhaseFormDocument, options);
        }
export type AccountAdminTrialPhaseFormQueryHookResult = ReturnType<typeof useAccountAdminTrialPhaseFormQuery>;
export type AccountAdminTrialPhaseFormLazyQueryHookResult = ReturnType<typeof useAccountAdminTrialPhaseFormLazyQuery>;
export type AccountAdminTrialPhaseFormSuspenseQueryHookResult = ReturnType<typeof useAccountAdminTrialPhaseFormSuspenseQuery>;
export type AccountAdminTrialPhaseFormQueryResult = Apollo.QueryResult<AccountAdminTrialPhaseFormQuery, AccountAdminTrialPhaseFormQueryVariables>;
export const UpdateAccountAdminTrialPhaseSettingsDocument = gql`
    mutation UpdateAccountAdminTrialPhaseSettings($accountId: AccountId!, $daysToDataCleanup: Int!, $daysToTrialRevival: Int, $isTrialExtensionAllowedByCustomerTeam: BooleanOrAutomatic!, $isTrialRevivalAllowedByCustomerTeam: Boolean!, $trialExpirationDate: Timestamp!) {
  UpdateAccountAdminTrialPhaseSettings(
    accountId: $accountId
    daysToDataCleanup: $daysToDataCleanup
    daysToTrialRevival: $daysToTrialRevival
    isTrialExtensionAllowedByCustomerTeam: $isTrialExtensionAllowedByCustomerTeam
    isTrialRevivalAllowedByCustomerTeam: $isTrialRevivalAllowedByCustomerTeam
    trialExpirationDate: $trialExpirationDate
  ) {
    query {
      account(id: $accountId) {
        adminSettings {
          daysToDataCleanup
          daysToTrialRevival
          isTrialExtensionAllowedByCustomerTeam
          isTrialRevivalAllowedByCustomerTeam
        }
        id
        trialExpirationDate
      }
    }
  }
}
    `;
export type UpdateAccountAdminTrialPhaseSettingsMutationFn = Apollo.MutationFunction<UpdateAccountAdminTrialPhaseSettingsMutation, UpdateAccountAdminTrialPhaseSettingsMutationVariables>;

/**
 * __useUpdateAccountAdminTrialPhaseSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAccountAdminTrialPhaseSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountAdminTrialPhaseSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountAdminTrialPhaseSettingsMutation, { data, loading, error }] = useUpdateAccountAdminTrialPhaseSettingsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      daysToDataCleanup: // value for 'daysToDataCleanup'
 *      daysToTrialRevival: // value for 'daysToTrialRevival'
 *      isTrialExtensionAllowedByCustomerTeam: // value for 'isTrialExtensionAllowedByCustomerTeam'
 *      isTrialRevivalAllowedByCustomerTeam: // value for 'isTrialRevivalAllowedByCustomerTeam'
 *      trialExpirationDate: // value for 'trialExpirationDate'
 *   },
 * });
 */
export function useUpdateAccountAdminTrialPhaseSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountAdminTrialPhaseSettingsMutation, UpdateAccountAdminTrialPhaseSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountAdminTrialPhaseSettingsMutation, UpdateAccountAdminTrialPhaseSettingsMutationVariables>(UpdateAccountAdminTrialPhaseSettingsDocument, options);
      }
export type UpdateAccountAdminTrialPhaseSettingsMutationHookResult = ReturnType<typeof useUpdateAccountAdminTrialPhaseSettingsMutation>;
export type UpdateAccountAdminTrialPhaseSettingsMutationResult = Apollo.MutationResult<UpdateAccountAdminTrialPhaseSettingsMutation>;
export type UpdateAccountAdminTrialPhaseSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateAccountAdminTrialPhaseSettingsMutation, UpdateAccountAdminTrialPhaseSettingsMutationVariables>;