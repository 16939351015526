/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HandleSuccessfulLoginQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type HandleSuccessfulLoginQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly isOnboardingTourInProgress: boolean | null, readonly isOwner: boolean, readonly account: { readonly __typename?: 'Account', readonly customerType: GraphQL.CustomerType | null, readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly user: { readonly __typename?: 'User', readonly firstName: string | null, readonly id: string, readonly lastName: string | null, readonly legacyId: string } } | null };


export const HandleSuccessfulLoginDocument = gql`
    query HandleSuccessfulLogin {
  authenticatedSession {
    memberships {
      account {
        customerType
        id
      }
      isOnboardingTourInProgress
      isOwner
      user {
        id
        legacyId
      }
    }
    user {
      firstName
      id
      lastName
      legacyId
    }
  }
}
    `;

/**
 * __useHandleSuccessfulLoginQuery__
 *
 * To run a query within a React component, call `useHandleSuccessfulLoginQuery` and pass it any options that fit your needs.
 * When your component renders, `useHandleSuccessfulLoginQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHandleSuccessfulLoginQuery({
 *   variables: {
 *   },
 * });
 */
export function useHandleSuccessfulLoginQuery(baseOptions?: Apollo.QueryHookOptions<HandleSuccessfulLoginQuery, HandleSuccessfulLoginQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HandleSuccessfulLoginQuery, HandleSuccessfulLoginQueryVariables>(HandleSuccessfulLoginDocument, options);
      }
export function useHandleSuccessfulLoginLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HandleSuccessfulLoginQuery, HandleSuccessfulLoginQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HandleSuccessfulLoginQuery, HandleSuccessfulLoginQueryVariables>(HandleSuccessfulLoginDocument, options);
        }
export function useHandleSuccessfulLoginSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HandleSuccessfulLoginQuery, HandleSuccessfulLoginQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HandleSuccessfulLoginQuery, HandleSuccessfulLoginQueryVariables>(HandleSuccessfulLoginDocument, options);
        }
export type HandleSuccessfulLoginQueryHookResult = ReturnType<typeof useHandleSuccessfulLoginQuery>;
export type HandleSuccessfulLoginLazyQueryHookResult = ReturnType<typeof useHandleSuccessfulLoginLazyQuery>;
export type HandleSuccessfulLoginSuspenseQueryHookResult = ReturnType<typeof useHandleSuccessfulLoginSuspenseQuery>;
export type HandleSuccessfulLoginQueryResult = Apollo.QueryResult<HandleSuccessfulLoginQuery, HandleSuccessfulLoginQueryVariables>;