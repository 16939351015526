import React from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import Caption, {
	CaptionStyle,
} from '~/components/patterns/headings/Caption';
import Emphasis from '~/components/patterns/typography/Emphasis';
import Grid, {
	GridGapsSize,
} from '~/components/patterns/structures/Grid';
import InternalLink from '~/components/patterns/links/InternalLink';
import LabeledValue, {
	LabeledValueFlow,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LabeledValuesList from '~/components/patterns/structuredValues/labeledValues/LabeledValuesList';
import MarginBox from '~/components/atoms/boxes/MarginBox';
import RichText from '~/components/patterns/typography/RichText';
import SquareSkeleton, {
	SquareSkeletonStyle,
} from '~/components/patterns/loaders/SquareSkeleton';

import {
	useLinksStructureBlockPartQuery,
} from './LinksStructureBlockPart.gql';

import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import usePageType from '~/hooks/usePageType';
import useViewportType from '~/hooks/useViewportType';



const messages = defineMessages({
	labelIncomingCanonicalLinks: {
		id: 'ui.pageDetail.linkData.incomingCanonical.linkLabelShort',
	},
	labelIncomingLinks: {
		id: 'ui.pageDetail.linkData.incoming.linkLabelShort',
	},
	labelIncomingRedirects: {
		id: 'ui.pageDetail.linkData.incomingRedirects.linkLabelShort',
	},
	labelOutgoingExternalLinks: {
		id: 'ui.pageDetail.linkData.outbound.linkLabelShort',
	},
	labelOutgoingInternalLinks: {
		id: 'ui.pageDetail.linkData.outgoingInternal.linkLabelShort',
	},
	titleIncomingRelations: {
		id: 'ui.pageDetail.linkData.groupTitle.incoming',
	},
	titleOutgoingRelations: {
		id: 'ui.pageDetail.linkData.groupTitle.outgoing',
	},
	unknown: {
		id: 'ui.pageDetail.linkData.unknown',
	},
});



type Props = {
	legacyUrlId: number,
	websiteId: CK.WebsiteId,
};

const LinksStructureBlockPart: React.FC<Props> = (props) => {
	const {
		legacyUrlId,
		websiteId,
	} = props;

	const pageType = usePageType(legacyUrlId, websiteId);
	const viewportType = useViewportType();

	const {
		data,
		loading,
	} = usePageDetailPropertiesQuery(
		useLinksStructureBlockPartQuery,
		legacyUrlId,
		websiteId,
	);

	const showOutgoingLinks = pageType === GraphQL.PageType.Page;

	function formatLinksNumber(count, linksType) {
		if (loading) {
			return (
				<SquareSkeleton
					maxWidth={Math.floor(Math.random() * (300 - 100 + 1)) + 100}
					style={SquareSkeletonStyle.Light}
				/>
			);
		} else if (count === null) {
			return (
				<RichText>
					<Emphasis>
						<FormattedMessage {...messages.unknown} />
					</Emphasis>
				</RichText>
			);
		} else if (count > 0) {
			return (
				<InternalLink
					ellipsis={true}
					routeName="website.pages.detail.links"
					routeParams={{
						id: legacyUrlId,
						pageDetailLinksType: linksType,
						websiteId,
					}}
				>
					<FormattedNumber value={count} />
				</InternalLink>
			);
		}

		return (
			<FormattedNumber value={count} />
		);
	}



	function getValueMaxWidth() {
		if (viewportType.isSmall || viewportType.isMedium) {
			return 350;
		}

		return 210;
	}

	const incomingInternalLinks = formatLinksNumber(
		data?.lookupPageByLegacyId?.numberOfIncomingInternalLinks ?? null,
		'incoming',
	);

	const outgoingInternalLinks = formatLinksNumber(
		data?.lookupPageByLegacyId?.pageTypeData?.numberOfOutgoingInternalLinks ?? null,
		'outgoing_internal',
	);

	const outboundLinks = formatLinksNumber(
		data?.lookupPageByLegacyId?.pageTypeData?.numberOfOutgoingExternalLinks ?? null,
		'outbound',
	);

	const incomingCanonicals = formatLinksNumber(
		data?.lookupPageByLegacyId?.numberOfIncomingCanonicalLinks ?? null,
		'canonicals',
	);

	const incomingRedirects = formatLinksNumber(
		data?.lookupPageByLegacyId?.numberOfIncomingRedirects ?? null,
		'redirects',
	);

	if (viewportType.isSmall || viewportType.isMedium) {
		return (
			<LabeledValuesList>
				<Caption style={CaptionStyle.Highlighted}>
					<FormattedMessage {...messages.titleIncomingRelations} />
				</Caption>
				<LabeledValue
					flow={LabeledValueFlow.Row}
					label={(
						<FormattedMessage {...messages.labelIncomingLinks} />
					)}
					maxWidth={getValueMaxWidth()}
				>
					{incomingInternalLinks}
				</LabeledValue>
				<LabeledValue
					flow={LabeledValueFlow.Row}
					label={(
						<FormattedMessage {...messages.labelIncomingCanonicalLinks} />
					)}
					maxWidth={getValueMaxWidth()}
				>
					{incomingCanonicals}
				</LabeledValue>
				<LabeledValue
					flow={LabeledValueFlow.Row}
					label={(
						<FormattedMessage {...messages.labelIncomingRedirects} />
					)}
					maxWidth={getValueMaxWidth()}
				>
					<MarginBox>{incomingRedirects}</MarginBox>
				</LabeledValue>

				{showOutgoingLinks && [
					<Caption
						key="outgoing-links-caption"
						style={CaptionStyle.Highlighted}
					>
						<FormattedMessage {...messages.titleOutgoingRelations} />
					</Caption>,
					<LabeledValue
						flow={LabeledValueFlow.Row}
						key="outgoing-links-internal"
						label={(
							<FormattedMessage {...messages.labelOutgoingInternalLinks} />
						)}
						maxWidth={getValueMaxWidth()}
					>
						{outgoingInternalLinks}
					</LabeledValue>,
					<LabeledValue
						flow={LabeledValueFlow.Row}
						key="outgoing-links-outbound"
						label={(
							<FormattedMessage {...messages.labelOutgoingExternalLinks} />
						)}
						maxWidth={getValueMaxWidth()}
					>
						{outboundLinks}
					</LabeledValue>,
				]}
			</LabeledValuesList>
		);
	}

	return (
		<Grid
			columnsCount={2}
			gapsSize={GridGapsSize.Responsive}
		>
			<LabeledValuesList>
				<Caption style={CaptionStyle.Highlighted}>
					<FormattedMessage {...messages.titleIncomingRelations} />
				</Caption>
				<LabeledValue
					flow={LabeledValueFlow.Row}
					label={(
						<FormattedMessage {...messages.labelIncomingLinks} />
					)}
					maxWidth={getValueMaxWidth()}
				>
					{incomingInternalLinks}
				</LabeledValue>
				<LabeledValue
					flow={LabeledValueFlow.Row}
					label={(
						<FormattedMessage {...messages.labelIncomingCanonicalLinks} />
					)}
					maxWidth={getValueMaxWidth()}
				>
					{incomingCanonicals}
				</LabeledValue>
				<LabeledValue
					flow={LabeledValueFlow.Row}
					label={(
						<FormattedMessage {...messages.labelIncomingRedirects} />
					)}
					maxWidth={getValueMaxWidth()}
				>
					{incomingRedirects}
				</LabeledValue>
			</LabeledValuesList>

			{showOutgoingLinks && (
				<LabeledValuesList>
					<Caption style={CaptionStyle.Highlighted}>
						<FormattedMessage {...messages.titleOutgoingRelations} />
					</Caption>
					<LabeledValue
						flow={LabeledValueFlow.Row}
						label={(
							<FormattedMessage {...messages.labelOutgoingInternalLinks} />
						)}
						maxWidth={getValueMaxWidth()}
					>
						{outgoingInternalLinks}
					</LabeledValue>
					<LabeledValue
						flow={LabeledValueFlow.Row}
						label={(
							<FormattedMessage {...messages.labelOutgoingExternalLinks} />
						)}
						maxWidth={getValueMaxWidth()}
					>
						{outboundLinks}
					</LabeledValue>
				</LabeledValuesList>
			)}
		</Grid>
	);
};



export default LinksStructureBlockPart;
