import React from 'react';

import GraphQL from '~/types/graphql';

import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';
import useAccountId from '~/hooks/useAccountId';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountState from '~/hooks/useAccountState';
import useFindRequiredPlan from '~/hooks/useFindRequiredPlan';

import {
	Tariff,
} from '~/model/pricing/tariffs';

import {
	getTariffPlans,
	isFeatureAvailable,
} from '~/model/universal';



function findPlanRequiredForFeatureRegardlessOfAccount(
	accountFeaturesAvailability: ReadonlyArray<{
		accountFeature: GraphQL.AccountFeature,
		plan: GraphQL.AccountPlan,
		status: GraphQL.AccountFeatureAvailabilityStatus,
		tariff: string,
	}>,
	featureName: GraphQL.AccountFeature,
) {
	for (const plan of getTariffPlans(Tariff.V4)) {
		if (isFeatureAvailable(accountFeaturesAvailability, 'v4', plan, featureName)) {
			return plan;
		}
	}

	return null;
}



type ReturnType = {
	isFeatureAttainable: boolean,
	isFeatureEnabled: boolean,
	requiredPlan: GraphQL.AccountPlan | null,
	requiredUniversalPlan: GraphQL.AccountPlan | null,
	shouldBeOfferedIfAttainable: boolean,
};



function usePremiumFeatureSituation(
	featureName: GraphQL.AccountFeature,
): ReturnType {
	const accountId = useAccountId();
	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);
	const accountFeaturesAvailability = useAccountFeaturesAvailability();
	const accountPhase = useAccountPhase(accountId);
	const accountState = useAccountState(accountId);

	const findRequiredPlan = useFindRequiredPlan(accountId);

	const shouldBeOfferedIfAttainable = (
		accountPhase !== GraphQL.AccountPhase.Customer
		|| accountState !== GraphQL.AccountState.Churned
	);

	return React.useMemo(
		() => {
			if (
				accountId === null
				|| accountEnabledFeatures === null
				|| accountFeaturesAvailability === null
			) {
				return {
					isFeatureAttainable: false,
					isFeatureEnabled: false,
					requiredPlan: null,
					requiredUniversalPlan: null,
					shouldBeOfferedIfAttainable,
				};
			}

			const isFeatureEnabled = accountEnabledFeatures.includes(featureName);

			if (isFeatureEnabled) {
				return {
					isFeatureAttainable: true,
					isFeatureEnabled,
					requiredPlan: null,
					requiredUniversalPlan: null,
					shouldBeOfferedIfAttainable,
				};
			}

			const requiredPlan = findRequiredPlan(featureName);
			const requiredUniversalPlan = requiredPlan === null
				? findPlanRequiredForFeatureRegardlessOfAccount(accountFeaturesAvailability, featureName)
				: null;

			return {
				isFeatureAttainable: requiredPlan !== null,
				isFeatureEnabled: false,
				requiredPlan,
				requiredUniversalPlan,
				shouldBeOfferedIfAttainable,
			};
		},
		[
			accountEnabledFeatures,
			accountFeaturesAvailability,
			accountId,
			featureName,
			findRequiredPlan,
			shouldBeOfferedIfAttainable,
		],
	);
}



export default usePremiumFeatureSituation;
