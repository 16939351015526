import React from 'react';

import type CK from '~/types/contentking';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import IconOnlyButton from '~/components/patterns/buttons/IconOnlyButton';
import SimpleNavigation from '~/components/patterns/navigations/simpleNavigation/SimpleNavigation';
import SimpleNavigationItem from '~/components/patterns/navigations/simpleNavigation/SimpleNavigationItem';



type Props = {
	columnSets: ReadonlyArray<{
		id: CK.ColumnSetId,
		name: string,
	}>,
	editMessage: string,
	icon: BasicIconType,
	onEdit: (columnSetId: CK.ColumnSetId) => void,
	onNavigate: (columnSetId: CK.ColumnSetId) => void,
	selectedColumnSetId: CK.ColumnSetId | null,
	title: React.ReactNode,
};

const ColumnsSetsNavigation: React.FC<Props> = (props) => {
	const {
		columnSets,
		editMessage,
		icon,
		onEdit,
		onNavigate,
		selectedColumnSetId,
		title,
	} = props;

	const handleEditClick = React.useCallback(
		(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, columnSetId: CK.ColumnSetId) => {
			event.stopPropagation();

			onEdit(columnSetId);
		},
		[
			onEdit,
		],
	);

	return (
		<SimpleNavigation
			icon={(
				<BasicIcon type={icon} />
			)}
			title={title}
		>
			{columnSets.map((columnSet) => (
				<SimpleNavigationItem
					isHighlighted={selectedColumnSetId === columnSet.id}
					key={columnSet.id}
					onClickCallback={() => onNavigate(columnSet.id)}
					specialCTAElement={(
						<IconOnlyButton
							icon={(
								<BasicIcon type={BasicIconType.CogWheel} />
							)}
							onClick={(event) => handleEditClick(event, columnSet.id)}
							title={editMessage}
						/>
					)}
				>
					{columnSet.name}
				</SimpleNavigationItem>
			))}
		</SimpleNavigation>
	);
};



export default ColumnsSetsNavigation;
