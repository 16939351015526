/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageUrlQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageUrlQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly url: string } | null };


export const PageUrlDocument = gql`
    query PageUrl($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    url
  }
}
    `;

/**
 * __usePageUrlQuery__
 *
 * To run a query within a React component, call `usePageUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageUrlQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageUrlQuery(baseOptions: Apollo.QueryHookOptions<PageUrlQuery, PageUrlQueryVariables> & ({ variables: PageUrlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageUrlQuery, PageUrlQueryVariables>(PageUrlDocument, options);
      }
export function usePageUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageUrlQuery, PageUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageUrlQuery, PageUrlQueryVariables>(PageUrlDocument, options);
        }
export function usePageUrlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PageUrlQuery, PageUrlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageUrlQuery, PageUrlQueryVariables>(PageUrlDocument, options);
        }
export type PageUrlQueryHookResult = ReturnType<typeof usePageUrlQuery>;
export type PageUrlLazyQueryHookResult = ReturnType<typeof usePageUrlLazyQuery>;
export type PageUrlSuspenseQueryHookResult = ReturnType<typeof usePageUrlSuspenseQuery>;
export type PageUrlQueryResult = Apollo.QueryResult<PageUrlQuery, PageUrlQueryVariables>;