import sortBy from 'lodash/sortBy';
import React from 'react';

import type CK from '~/types/contentking';

import {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Ellipsis from '~/components/patterns/values/Ellipsis';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import MultiselectTableField from '~/components/logic/formFields/MultiselectTableField';
import SimpleModal, {
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SmallImageLabel from '~/components/patterns/images/SmallImageLabel';
import Spinner from '~/components/patterns/loaders/Spinner';
import SubmitButton from '~/components/app/SubmitButton';
import WebsiteFavicon, {
	WebsiteFaviconUsageContext,
} from '~/components/logic/websites/WebsiteFavicon';

import {
	useCopyCustomElementToWebsitesModalQuery,
	useCopyCustomElementToWebsitesMutation,
} from '~/components/app/CopyCustomElementToWebsitesModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useHomeAccountIds from '~/hooks/useHomeAccountIds';
import useWebsiteId from '~/hooks/useWebsiteId';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



type Item = {
	account: {
		customElementsLimit: number,
		displayName: string,
		id: CK.AccountId,
	},
	customElements: ReadonlyArray<{
		label: string,
		name: string,
	}>,
	displayName: string,
	id: CK.WebsiteId,
};



type Props = {
	customElementLabel: string,
	customElementName: string,
};

const CopyCustomElementToWebsitesModal: React.FC<Props> = (props) => {
	const {
		customElementLabel,
		customElementName,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const homeAccountIds = useHomeAccountIds();
	const websiteId = useWebsiteId();

	const {
		data,
		loading,
	} = useCopyCustomElementToWebsitesModalQuery({
		fetchPolicy: 'network-only',
	});

	const [copyCustomElementToWebsitesMutation] = useCopyCustomElementToWebsitesMutation();

	const websites = React.useMemo(
		() => {
			if (homeAccountIds === null) {
				return [];
			}

			const websites: Array<Item> = [];

			for (const account of data?.authenticatedSession?.accounts ?? []) {
				for (const website of account.websites) {
					websites.push({
						account,
						customElements: website.customElements,
						displayName: website.displayName,
						id: website.id,
					});
				}
			}

			return sortBy(
				websites,
				[
					(website) => homeAccountIds.includes(website.account.id) === false,
					(website) => website.account.displayName,
					(website) => website.displayName,
				],
			);
		},
		[
			data,
			homeAccountIds,
		],
	);

	const handleSubmit = React.useCallback(
		async (values) => {
			const targetWebsiteIds = values.items.map((item) => {
				return item.id;
			});

			await copyCustomElementToWebsitesMutation({
				variables: {
					sourceCustomElementName: customElementName,
					sourceWebsiteId: websiteId,
					targetWebsiteIds,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			copyCustomElementToWebsitesMutation,
			customElementName,
			websiteId,
		],
	);

	const isRowDisabled = React.useCallback(
		({ value }: { value: Item }) => {
			const website = value;

			const existingCustomElement = website.customElements.find((customElement) => {
				return customElement.name === customElementName;
			}) ?? null;

			const maximumCustomElements = (
				website.customElements.length === website.account.customElementsLimit
			);

			return (
				existingCustomElement !== null
				|| maximumCustomElements
			);
		},
		[
			customElementName,
		],
	);

	const renderCell = React.useCallback(
		(({ columnIndex, rowIndex }) => {
			const row = getArrayItemAtSafeIndex(websites, rowIndex);

			switch (columnIndex) {

				case 0:
					return (
						<SmallImageLabel
							image={(
								<WebsiteFavicon
									usageContext={WebsiteFaviconUsageContext.List}
									websiteId={row.id}
								/>
							)}
							label={(
								<Ellipsis>
									{row.displayName}
								</Ellipsis>
							)}
						/>
					);

				case 1:
					return (
						<Ellipsis>
							{row.account.displayName}
						</Ellipsis>
					);

			}

			return null;
		}),
		[
			websites,
		],
	);

	const renderHeader = React.useCallback(
		({ columnIndex }) => {
			switch (columnIndex) {

				case 0:
					return 'Website';

				case 1:
					return 'Account';

			}

			return null;
		},
		[],
	);

	return (
		<SimpleModal
			alignToTop={true}
			iconType={BasicIconType.Export}
			size={SimpleModalSize.XLarge}
			title="Copy custom element to websites"
		>
			<Form
				defaultValues={{
					items: [],
				}}
				onSuccess={handleSubmit}
			>
				{({ values }) => (
					<>
						<ModalContentSection>
							<FormRow label={<Ellipsis>Custom element</Ellipsis>}>
								<div style={{ alignSelf: 'center' }}>
									{customElementLabel}
								</div>
							</FormRow>
						</ModalContentSection>

						{loading ? (
							<Spinner />
						) : (
							<MultiselectTableField
								cellRenderer={renderCell}
								columnCount={2}
								columnWidth={({ index, width }) => [width * 0.6, width * 0.4][index]}
								disabledGetter={isRowDisabled}
								headerRenderer={renderHeader}
								items={websites}
								name="items"
								tableHeight={400}
							/>
						)}

						<FormErrorMessages
							errors={{
								customElementAlreadyExists: 'Custom element already exists on website(s)',
								maximumCustomElementsCountReached: 'Maximum custom elements reached on target website(s)',
								sourceCustomElementNotFound: 'Source custom element not found',
							}}
						/>

						<CenteredFormWrapper>
							<ButtonsLayout>
								<CancelButton />

								<SubmitButton>
									Copy custom element to {values.items.length} website(s)
								</SubmitButton>
							</ButtonsLayout>
						</CenteredFormWrapper>
					</>
				)}
			</Form>
		</SimpleModal>
	);
};



export default CopyCustomElementToWebsitesModal;
