/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CoreWebVitalsAssessmentWidgetQueryVariables = GraphQL.Exact<{
  date: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CoreWebVitalsAssessmentWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly webVitalsOriginSummaryData: { readonly __typename?: 'WebsiteDashboardWebVitalsOriginSummaryData', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: { readonly __typename?: 'WebVitalsOriginSummaryData', readonly coreWebVitalsAssessment: GraphQL.CoreWebVitalsAssessment } | null } } };


export const CoreWebVitalsAssessmentWidgetDocument = gql`
    query CoreWebVitalsAssessmentWidget($date: DateYMD!, $scope: String!, $websiteId: WebsiteId!) {
  dashboardData {
    webVitalsOriginSummaryData(date: $date, scope: $scope, websiteId: $websiteId) {
      data {
        coreWebVitalsAssessment
      }
      id
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __useCoreWebVitalsAssessmentWidgetQuery__
 *
 * To run a query within a React component, call `useCoreWebVitalsAssessmentWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoreWebVitalsAssessmentWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoreWebVitalsAssessmentWidgetQuery({
 *   variables: {
 *      date: // value for 'date'
 *      scope: // value for 'scope'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useCoreWebVitalsAssessmentWidgetQuery(baseOptions: Apollo.QueryHookOptions<CoreWebVitalsAssessmentWidgetQuery, CoreWebVitalsAssessmentWidgetQueryVariables> & ({ variables: CoreWebVitalsAssessmentWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoreWebVitalsAssessmentWidgetQuery, CoreWebVitalsAssessmentWidgetQueryVariables>(CoreWebVitalsAssessmentWidgetDocument, options);
      }
export function useCoreWebVitalsAssessmentWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoreWebVitalsAssessmentWidgetQuery, CoreWebVitalsAssessmentWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoreWebVitalsAssessmentWidgetQuery, CoreWebVitalsAssessmentWidgetQueryVariables>(CoreWebVitalsAssessmentWidgetDocument, options);
        }
export function useCoreWebVitalsAssessmentWidgetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CoreWebVitalsAssessmentWidgetQuery, CoreWebVitalsAssessmentWidgetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CoreWebVitalsAssessmentWidgetQuery, CoreWebVitalsAssessmentWidgetQueryVariables>(CoreWebVitalsAssessmentWidgetDocument, options);
        }
export type CoreWebVitalsAssessmentWidgetQueryHookResult = ReturnType<typeof useCoreWebVitalsAssessmentWidgetQuery>;
export type CoreWebVitalsAssessmentWidgetLazyQueryHookResult = ReturnType<typeof useCoreWebVitalsAssessmentWidgetLazyQuery>;
export type CoreWebVitalsAssessmentWidgetSuspenseQueryHookResult = ReturnType<typeof useCoreWebVitalsAssessmentWidgetSuspenseQuery>;
export type CoreWebVitalsAssessmentWidgetQueryResult = Apollo.QueryResult<CoreWebVitalsAssessmentWidgetQuery, CoreWebVitalsAssessmentWidgetQueryVariables>;