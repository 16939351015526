import React from 'react';
import {
	DndContext,
	type DragEndEvent,
	DragOverlay,
	type DragStartEvent,
} from '@dnd-kit/core';
import {
	SortableContext,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import type CK from '~/types/contentking';

import ColumnsListItem, {
	DragOverlayColumnsListItem,
} from './ColumnsListItem';

import arrayMove from '~/utilities/arrayMove';
import {
	restrictToParentElement,
	restrictToVerticalAxis,
} from '@dnd-kit/modifiers';
import {
	createPortal,
} from 'react-dom';



type ColumnItem = {
	enabled: boolean,
	label: React.ReactNode,
	name: CK.PagesColumn,
};

type Props = {
	columns: Array<ColumnItem>,
	onSelect: (
		column: CK.PagesColumn,
	) => void,
	onSort: (
		columns: Array<ColumnItem>,
	) => void,
	/**
	 * Columns with a readonly checkbox. The `enabled` state of these columns cannot be changed.
	 */
	unselectableColumns?: Array<CK.PagesColumn>,
	/**
	 * Columns that cannot be sorted. This is useful when you want to keep some columns in a fixed
	 * position. These columns should be placed at the beginning of the list.
	 */
	unsortableColumns?: Array<CK.PagesColumn>,
};

const ColumnsList: React.FC<Props> = (props) => {
	const {
		columns,
		onSelect,
		onSort = null,
		unselectableColumns = [],
		unsortableColumns = [],
	} = props;

	const [activeItem, setActiveItem] = React.useState<ColumnItem | null>(null);

	const handleDragStart = React.useCallback(
		(event: DragStartEvent) => {
			const { active } = event;

			const activeItem = columns.find((column) => column.name === active.id);

			if (activeItem !== undefined) {
				setActiveItem(activeItem);
			}
		},
		[
			columns,
		],
	);

	const handleDragEnd = React.useCallback(
		(event: DragEndEvent) => {
			const { active, over } = event;

			if (
				onSort !== null
				&& over !== null
				&& active.id !== over.id
			) {
				const oldIndex = columns.findIndex((column) => column.name === active.id);

				// If the "over" column is unsortable, move the active column to the first sortable
				const newIndex = unsortableColumns.includes(over.id as CK.PagesColumn)
					? columns.findIndex((column) => !unsortableColumns.includes(column.name))
					: columns.findIndex((column) => column.name === over.id);

				const nextColumns = arrayMove(columns, oldIndex, newIndex);

				onSort(nextColumns);
			}

			setActiveItem(null);
		},
		[
			columns,
			onSort,
			unsortableColumns,
		],
	);

	return (
		<DndContext
			modifiers={[restrictToVerticalAxis, restrictToParentElement]}
			onDragEnd={handleDragEnd}
			onDragStart={handleDragStart}
		>
			<SortableContext
				items={columns.map((column) => ({ ...column, id: column.name }))}
				strategy={verticalListSortingStrategy}
			>
				{columns.map((column) => (
					<ColumnsListItem
						enabled={column.enabled}
						isSelectable={!unselectableColumns.includes(column.name)}
						isSortable={!unsortableColumns.includes(column.name)}
						key={column.name}
						label={column.label}
						name={column.name}
						onSelect={onSelect}
					/>
				))}
			</SortableContext>

			{createPortal(
				<DragOverlay>
					{activeItem !== null && (
						<DragOverlayColumnsListItem
							enabled={activeItem.enabled}
							isSelectable={!unselectableColumns.includes(activeItem.name)}
							label={activeItem.label}
							name={activeItem.name}
						/>
					)}
				</DragOverlay>,
				document.body,
			)}
		</DndContext>
	);
};



export default ColumnsList;
