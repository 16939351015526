/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateAdvancedWebsiteMonitoringSettingsMutationVariables = GraphQL.Exact<{
  fetchingLocation: GraphQL.FetchingLocation;
  httpAuth: GraphQL.InputMaybe<GraphQL.Scalars['Boolean']['input']>;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateAdvancedWebsiteMonitoringSettingsMutation = { readonly __typename?: 'Mutation', readonly UpdateAdvancedWebsiteMonitoringSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly fetchingLocation: GraphQL.FetchingLocation, readonly id: CK.WebsiteId, readonly isHttpAuthenticationEnabled: boolean } | null } } };


export const UpdateAdvancedWebsiteMonitoringSettingsDocument = gql`
    mutation UpdateAdvancedWebsiteMonitoringSettings($fetchingLocation: FetchingLocation!, $httpAuth: Boolean, $websiteId: WebsiteId!) {
  UpdateAdvancedWebsiteMonitoringSettings(
    fetchingLocation: $fetchingLocation
    httpAuth: $httpAuth
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        fetchingLocation
        id
        isHttpAuthenticationEnabled
      }
    }
  }
}
    `;
export type UpdateAdvancedWebsiteMonitoringSettingsMutationFn = Apollo.MutationFunction<UpdateAdvancedWebsiteMonitoringSettingsMutation, UpdateAdvancedWebsiteMonitoringSettingsMutationVariables>;

/**
 * __useUpdateAdvancedWebsiteMonitoringSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateAdvancedWebsiteMonitoringSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdvancedWebsiteMonitoringSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdvancedWebsiteMonitoringSettingsMutation, { data, loading, error }] = useUpdateAdvancedWebsiteMonitoringSettingsMutation({
 *   variables: {
 *      fetchingLocation: // value for 'fetchingLocation'
 *      httpAuth: // value for 'httpAuth'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateAdvancedWebsiteMonitoringSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAdvancedWebsiteMonitoringSettingsMutation, UpdateAdvancedWebsiteMonitoringSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAdvancedWebsiteMonitoringSettingsMutation, UpdateAdvancedWebsiteMonitoringSettingsMutationVariables>(UpdateAdvancedWebsiteMonitoringSettingsDocument, options);
      }
export type UpdateAdvancedWebsiteMonitoringSettingsMutationHookResult = ReturnType<typeof useUpdateAdvancedWebsiteMonitoringSettingsMutation>;
export type UpdateAdvancedWebsiteMonitoringSettingsMutationResult = Apollo.MutationResult<UpdateAdvancedWebsiteMonitoringSettingsMutation>;
export type UpdateAdvancedWebsiteMonitoringSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateAdvancedWebsiteMonitoringSettingsMutation, UpdateAdvancedWebsiteMonitoringSettingsMutationVariables>;