/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteStatisticsBlockQueryVariables = GraphQL.Exact<{
  endDate: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  startDate: GraphQL.Scalars['DateYMD']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteStatisticsBlockQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly healthChanges: { readonly __typename?: 'WebsiteDashboardHealthChanges', readonly id: CK.ID, readonly data: ReadonlyArray<{ readonly __typename?: 'WebsiteDashboardHealthChangesData', readonly date: CK.DateYMD, readonly health: number | null }> | null }, readonly numberOfIssues: { readonly __typename?: 'WebsiteDashboardNumberOfIssues', readonly id: CK.ID, readonly data: { readonly __typename?: 'WebsiteDashboardNumberOfIssuesData', readonly deltaFromStartDate: number | null, readonly numberOfIssuesOnEndDate: number } | null }, readonly numberOfPages: { readonly __typename?: 'WebsiteDashboardNumberOfPages', readonly id: CK.ID, readonly data: { readonly __typename?: 'WebsiteDashboardNumberOfPagesData', readonly deltaFromStartDate: number | null, readonly numberOfPagesOnEndDate: number } | null } } };


export const WebsiteStatisticsBlockDocument = gql`
    query WebsiteStatisticsBlock($endDate: DateYMD!, $scope: String!, $startDate: DateYMD!, $websiteId: WebsiteId!) {
  dashboardData {
    healthChanges(
      endDate: $endDate
      scope: $scope
      startDate: $startDate
      websiteId: $websiteId
    ) {
      data {
        date
        health
      }
      id
    }
    numberOfIssues(
      endDate: $endDate
      scope: $scope
      startDate: $startDate
      websiteId: $websiteId
    ) {
      data {
        deltaFromStartDate
        numberOfIssuesOnEndDate
      }
      id
    }
    numberOfPages(
      endDate: $endDate
      scope: $scope
      startDate: $startDate
      websiteId: $websiteId
    ) {
      data {
        deltaFromStartDate
        numberOfPagesOnEndDate
      }
      id
    }
  }
}
    `;

/**
 * __useWebsiteStatisticsBlockQuery__
 *
 * To run a query within a React component, call `useWebsiteStatisticsBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteStatisticsBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteStatisticsBlockQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      scope: // value for 'scope'
 *      startDate: // value for 'startDate'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteStatisticsBlockQuery(baseOptions: Apollo.QueryHookOptions<WebsiteStatisticsBlockQuery, WebsiteStatisticsBlockQueryVariables> & ({ variables: WebsiteStatisticsBlockQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteStatisticsBlockQuery, WebsiteStatisticsBlockQueryVariables>(WebsiteStatisticsBlockDocument, options);
      }
export function useWebsiteStatisticsBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteStatisticsBlockQuery, WebsiteStatisticsBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteStatisticsBlockQuery, WebsiteStatisticsBlockQueryVariables>(WebsiteStatisticsBlockDocument, options);
        }
export function useWebsiteStatisticsBlockSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteStatisticsBlockQuery, WebsiteStatisticsBlockQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteStatisticsBlockQuery, WebsiteStatisticsBlockQueryVariables>(WebsiteStatisticsBlockDocument, options);
        }
export type WebsiteStatisticsBlockQueryHookResult = ReturnType<typeof useWebsiteStatisticsBlockQuery>;
export type WebsiteStatisticsBlockLazyQueryHookResult = ReturnType<typeof useWebsiteStatisticsBlockLazyQuery>;
export type WebsiteStatisticsBlockSuspenseQueryHookResult = ReturnType<typeof useWebsiteStatisticsBlockSuspenseQuery>;
export type WebsiteStatisticsBlockQueryResult = Apollo.QueryResult<WebsiteStatisticsBlockQuery, WebsiteStatisticsBlockQueryVariables>;