import classNames from 'classnames';
import React from 'react';
import ReactDOM from 'react-dom';
import {
	usePopper,
} from 'react-popper';

import AbstractSelectFieldToggler, {
	AbstractSelectFieldTogglerSize as AbstractSelectFieldSize,
	AbstractSelectFieldTogglerStyle as AbstractSelectFieldStyle,
} from '~/components/patterns/forms/fieldParts/selectFieldTogglers/AbstractSelectFieldToggler';
import DepthLayer from '~/components/patterns/utils/DepthLayer';

import {
	isNumber,
} from '~/utilities/typeCheck';



export const DEFAULT_WIDTH = 280;
const CLOSE_ON_HOVER_TIMEOUT = 200;



export enum AbstractSelectFieldDropdownAttachment {
	Left = 'left',
	Right = 'right',
}

export type AbstractSelectFieldRef = {
	close: () => void,
	open: () => void,
};

type Props = {
	/** Attachment of dropdown against to target */
	dropdownAttachment?: AbstractSelectFieldDropdownAttachment,
	dropdownWidth?: number,
	/** Enable dropdown toggle on hover */
	hoverable?: boolean,
	/** Dropdown content */
	children: React.ReactNode,
	/** Make whole select disabled */
	isDisabled?: boolean,
	label: React.ReactNode,
	labelRenderer?: (
		label: React.ReactNode,
		isOpen: boolean,
		size: AbstractSelectFieldSize,
		isDisabled: boolean,
		style: AbstractSelectFieldStyle,
	) => React.ReactNode,
	/** Callback triggered on dropdown open */
	onDropdownOpenCallback?: () => void,
	/** Callback triggered on dropdown close */
	onDropdownCloseCallback?: () => void,
	/** Callback triggered on key reveal */
	onKeyDownCallback?: (input: {
		isDropdownOpen: boolean,
	}, e: Event) => void,
	/** Callback triggered on key press */
	onKeyUpCallback?: (input: {
		isDropdownOpen: boolean,
	}, e: Event) => void,
	/** Possibility to specify boundary for Popper where we will detect overflow */
	popperBoundary?: Element,
	/** Possibility to disable Popper library used for attached dropdowns without overflowing */
	popperEnabled?: boolean,
	/** Set minimum height for dropdown and make it scrollable. Note: this will break overflowing variants present in dropdown. */
	scrollableDropdown?: boolean | number,
	size?: AbstractSelectFieldSize,
	style?: AbstractSelectFieldStyle,
	width?: React.CSSProperties['width'],
};



const AbstractSelectField = React.forwardRef<any, Props>((props, ref: React.Ref<AbstractSelectFieldRef>) => {
	const {
		dropdownAttachment = AbstractSelectFieldDropdownAttachment.Left,
		dropdownWidth = DEFAULT_WIDTH,
		hoverable = false,
		children,
		isDisabled,
		label,
		labelRenderer = (label, isOpen, size, isDisabled, style) => {
			return (
				<AbstractSelectFieldToggler
					isDisabled={isDisabled}
					isOpen={isOpen}
					label={label}
					size={size}
					style={style}
				/>
			);
		},
		onDropdownCloseCallback,
		onDropdownOpenCallback,
		onKeyDownCallback,
		onKeyUpCallback,
		popperBoundary = 'clippingParents',
		popperEnabled = true,
		scrollableDropdown = false,
		size = AbstractSelectFieldSize.Default,
		style = AbstractSelectFieldStyle.Light,
		width = DEFAULT_WIDTH,
	} = props;

	const isMounted = React.useRef<boolean>(false);
	const closePopupTimeout = React.useRef<any | null>(null);

	const dropdownRef = React.useRef<HTMLDivElement | null>(null);
	const selectRef = React.useRef<HTMLDivElement | null>(null);

	const [open, setOpen] = React.useState(false);

	const [referenceElement, setReferenceElement] = React.useState<HTMLDivElement | null>(null);
	const [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);

	const {
		state: popperState,
		styles,
		attributes,
	} = usePopper(
		referenceElement,
		popperElement,
		{
			placement: dropdownAttachment === AbstractSelectFieldDropdownAttachment.Left ? 'bottom-start' : 'bottom-end',
			modifiers: [
				{
					name: 'flip',
					options: {
						boundary: popperBoundary,
						altBoundary: popperBoundary === 'clippingParents',
					},
				},
				{
					name: 'preventOverflow',
					options: {
						mainAxis: false,
					},
				},
			],
		},
	);

	const handleOpen = () => {
		if (isDisabled) {
			return false;
		}

		setOpen(true);

		dropdownRef.current?.focus();

		if (onDropdownOpenCallback) {
			onDropdownOpenCallback();
		}
	};

	const handleClose = React.useCallback(
		() => {
			if (!isMounted.current) {
				return false;
			}

			setOpen(false);

			selectRef.current?.focus();

			if (onDropdownCloseCallback) {
				onDropdownCloseCallback();
			}
		},
		[
			onDropdownCloseCallback,
		],
	);

	React.useImperativeHandle(ref, () => ({
		close: handleClose,
		open: handleOpen,
	}));

	const dropdownOutsideClickHandler = React.useCallback(
		(event: MouseEvent): void => {
			if (!dropdownRef.current || !selectRef.current || !open) {
				return;
			}

			const target = event.target;

			if (!target || !(target instanceof HTMLElement)) {
				return;
			}

			// If the target is the <body> we are clicking on something rendered via a portal.
			// We don't want to close the dropdown in this case.
			if (target === document.body) {
				return;
			}

			const isDropdownClickInside = dropdownRef.current.contains(target);

			if (!isDropdownClickInside) {
				const isTogglerClickInside = selectRef.current.contains(target);

				if (!isTogglerClickInside) {
					handleClose();
				}
			}
		},
		[
			handleClose,
			open,
		],
	);

	React.useEffect(
		() => {
			isMounted.current = true;

			return () => {
				isMounted.current = false;

				document.removeEventListener('click', dropdownOutsideClickHandler, { capture: true });
			};
		},
		[
			dropdownOutsideClickHandler,
		],
	);

	React.useEffect(
		() => {
			if (open) {
				// Close select when click outside of open selectbox
				document.addEventListener('click', dropdownOutsideClickHandler, { capture: true });
			} else {
				document.removeEventListener('click', dropdownOutsideClickHandler, { capture: true });
			}
		},
		[
			dropdownOutsideClickHandler,
			open,
		],
	);

	const handleKeyDown = (e) => {
		if (onKeyDownCallback) {
			onKeyDownCallback({
				isDropdownOpen: open,
			}, e);
		}
	};

	const handleKeyUp = (e) => {
		e.stopPropagation();

		if (open) {
			// esc key
			if (e.keyCode === 27) {
				// we have to call it in async way
				setTimeout(() => {
					handleClose();
				}, 0);
			}
		} else {
			// enter key
			if (e.keyCode === 13) {
				handleOpen();
			}
		}

		if (onKeyUpCallback) {
			onKeyUpCallback({
				isDropdownOpen: open,
			}, e);
		}
	};

	const handleDropdownToggle = () => {
		if (!open) {
			handleOpen();
		} else {
			handleClose();
		}
	};

	const stopDropdownClosing = () => {
		clearTimeout(closePopupTimeout.current);
	};

	const handleDropdownMouseOverOpen = () => {
		if (!hoverable) {
			return false;
		}

		stopDropdownClosing();
		handleOpen();
	};

	const handleDropdownMouseOverClose = () => {
		if (!hoverable) {
			return false;
		}

		closePopupTimeout.current = setTimeout(() => {
			handleClose();
		}, CLOSE_ON_HOVER_TIMEOUT);
	};

	const componentClasses = classNames({
		'select-field': true,
		'select-field--small': size === AbstractSelectFieldSize.Small,
	});

	const dropdownClasses = classNames({
		'select-field__dropdown': true,
		'select-field__dropdown--is-wider': dropdownWidth && isNumber(width) && dropdownWidth > (width + 4),
		'select-field__dropdown--is-pinned': !popperEnabled,
		'select-field__dropdown--is-scrollable': scrollableDropdown,
		['select-field__dropdown--' + dropdownAttachment + '-attachment']: true,
	});

	const scrollableDropdownContainerClasses = classNames({
		'select-field__dropdown-container': true,
		'select-field__dropdown-container--is-scrollable': scrollableDropdown,
		'js-scrollable': scrollableDropdown,
	});

	const selectClasses = classNames({
		'select-field__virtual-select': true,
		'select-field__virtual-select--closed': !open,
		'select-field__virtual-select--open': open,
	});

	const selectElement = (
		<div
			aria-expanded={open ? 'true' : 'false'}
			className={selectClasses}
			onClick={handleDropdownToggle}
			onKeyDown={handleKeyDown}
			onKeyUp={handleKeyUp}
			onMouseEnter={handleDropdownMouseOverOpen}
			onMouseLeave={handleDropdownMouseOverClose}
			ref={selectRef}
			tabIndex={0}
		>
			{labelRenderer(
				label,
				open,
				size,
				isDisabled || false,
				style,
			)}
		</div>
	);

	const dropdownElement = (
		<div
			className={dropdownClasses}
			onKeyDown={handleKeyDown}
			onKeyUp={handleKeyUp}
			onMouseEnter={stopDropdownClosing}
			onMouseLeave={handleDropdownMouseOverClose}
			ref={dropdownRef}
			tabIndex={0}
		>
			<div
				className={scrollableDropdownContainerClasses}
				style={{
					maxWidth: dropdownWidth,
					minWidth: dropdownWidth,
					maxHeight: isNumber(scrollableDropdown) ? scrollableDropdown : 'auto',
				}}
			>
				{children}
			</div>
		</div>
	);

	let field;

	if (popperEnabled) {
		const popperDropdownContainer = document.getElementById('popper-elements');

		field = (
			<DepthLayer>
				{({ depthLayer }) => {
					return (
						<>
							<div
								data-popper-placement={popperState && popperState.placement}
								ref={setReferenceElement}
							>
								{selectElement}
							</div>
							{open && popperDropdownContainer && ReactDOM.createPortal(
								<div
									ref={setPopperElement}
									style={{
										zIndex: depthLayer,
										...styles.popper,
									}}
									{...attributes.popper}
								>
									{dropdownElement}
								</div>,
								popperDropdownContainer,
							)}
						</>
					);
				}}
			</DepthLayer>
		);
	} else {
		field = (
			<div>
				{selectElement}
				{open && dropdownElement}
			</div>
		);
	}

	return (
		<div
			className={componentClasses}
			style={{
				maxWidth: width,
				minWidth: width,
			}}
		>
			{field}
		</div>
	);
});



export default AbstractSelectField;

export {
	AbstractSelectFieldSize,
	AbstractSelectFieldStyle,
};
