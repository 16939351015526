import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import {
	IntlProvider,
} from 'react-intl';

import DateFormatter from '~/components/app/DateFormatter';
import SharedChartTooltip from '~/components/patterns/charts/components/SharedChartTooltip';

import {
	dictionaries,
} from '~/localization/dictionaries';

import {
	isString,
} from '~/utilities/typeCheck';



type Props = {
	chartHeight: number,
	disabled?: boolean,
	dateMaximum?: number,
	dateMinimum?: number,
	series: Array<Highcharts.SeriesSplineOptions>,
	valueMinimum?: number,
	valueMaximum?: number,
};

const DateTimeChart: React.FC<Props> = (props) => {
	const {
		chartHeight,
		dateMaximum,
		dateMinimum,
		disabled,
		series,
		valueMaximum,
		valueMinimum,
	} = props;

	const config: Highcharts.Options = {
		chart: {
			backgroundColor: 'transparent',
			height: chartHeight,
			spacing: [10, 0, 0, 0],
			type: 'datetime',
			animation: false,
		},
		credits: {
			enabled: false,
		},
		legend: {
			enabled: false,
		},
		plotOptions: {
			spline: {
				animation: disabled ? false : {
					duration: 1000,
				},
				cursor: disabled ? 'default' : 'pointer',
				lineWidth: 2,
				marker: {
					enabled: undefined,
					enabledThreshold: 200,
					lineWidth: 1,
					states: {
						hover: {
							enabled: false,
						},
					},
					symbol: 'circle',
				},
				states: {
					hover: {
						lineWidthPlus: 0,
					},
					inactive: {
						opacity: 0.2,
					},
				},
			},
		},
		series: series.map((serie) => ({
			...serie,
			type: 'spline',
		})),
		title: {
			text: undefined,
		},
		tooltip: {
			animation: false,
			borderWidth: 0,
			enabled: !disabled,
			formatter: function () {
				return ReactDOMServer.renderToString(
					<IntlProvider
						locale="en-US"
						messages={dictionaries.get('en-US')}
					>
						<SharedChartTooltip
							data={this.points?.map((point) => {
								return {
									color: isString(point.color) ? point.color : '#5c6773',
									count: point.y,
									label: point.series.name,
								};
							}) ?? []}
							title={(
								<DateFormatter value={this.x ?? null} />
							)}
						/>
					</IntlProvider>,
				);
			},
			distance: 4,
			padding: 0,
			shadow: false,
			shared: true,
			shape: 'rect',
			useHTML: true,
		},
		xAxis: {
			crosshair: true,
			labels: {
				style: {
					color: '#8595A6',
					fontFamily: '\'Roboto\', sans-serif',
					fontSize: '11px',
					fontWeight: '300',
					lineHeight: '11px',
				},
				y: 18,
			},
			type: 'datetime',
			dateTimeLabelFormats: {
				day: '%e %b',
				week: '%e %b',
				month: '%b %Y',
				year: '%Y',
			},
			tickPixelInterval: 150,
			lineWidth: 0,
			tickWidth: 0,
			title: {
				text: null,
			},
			max: dateMaximum,
			min: dateMinimum,
		},
		yAxis: {
			labels: {
				style: {
					color: '#8595A6',
					fontFamily: '\'Roboto\', sans-serif',
					fontSize: '11px',
					fontWeight: '300',
					lineHeight: '11px',
				},
				x: -10,
			},
			max: valueMaximum,
			min: valueMinimum ? valueMinimum - 0.5 : -0.5,
			startOnTick: false,
			title: {
				text: null,
			},
		},
	};

	return (
		<HighchartsReact
			highcharts={Highcharts}
			options={config}
		/>
	);
};

export default DateTimeChart;
