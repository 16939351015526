import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import NewTable, {
	type NewTableChildProps,
} from '~/components/patterns/tables/newTable/NewTable';
import NewTableCell, {
	NewTableCellType,
} from '~/components/patterns/tables/newTable/NewTableCell';
import NewTableRow, {
	NewTableRowType,
} from '~/components/patterns/tables/newTable/NewTableRow';
import SimpleBox from '~/components/patterns/boxes/SimpleBox';
import TableDeleteButton from '~/components/patterns/tables/datatables/buttons/TableDeleteButton';

import {
	renderProp,
} from '~/utilities/renderProp';



const FallbackIsRowDisabled = () => false;



export type FieldRendererProps = {
	fieldName: string,
	isDisabled: boolean,
	rowId: number,
	rowIndex: number,
	rowValues: Record<string, unknown>,
};

export type Field = {
	label: React.ReactNode,
	name: string,
	renderer: (props: FieldRendererProps) => React.ReactNode,
	validation?: unknown | undefined,
	width?: number | string | 'auto',
};

export type Props = {
	addButtonLabel?: React.ReactNode,
	fields: Array<Field>,
	getRowValues: (rowIndex: number) => Record<string, unknown>,
	isRowDisabled?: (rowId: number, rowIndex: number) => boolean,
	isSortable?: boolean,
	maximumRows?: number,
	name: string,
	onAddRow: () => void,
	onMoveRow: (fromIndex: number, toIndex: number) => void,
	onRemoveRow: (index: number) => void,
	rowHeight?: number,
	rows: Array<{ id: number }>,
	showAddButton?: boolean,
	showHeaderWhenEmpty?: boolean,
	showRowNumbers?: boolean,
};

const ArrayGroupFieldTable: React.FC<Props> = (props) => {
	const {
		addButtonLabel,
		fields,
		getRowValues,
		isRowDisabled = FallbackIsRowDisabled,
		isSortable = false,
		maximumRows = Number.MAX_SAFE_INTEGER,
		name,
		onAddRow,
		onMoveRow,
		onRemoveRow,
		rowHeight = 48,
		rows,
		showAddButton = true,
		showHeaderWhenEmpty = true,
		showRowNumbers = false,
	} = props;

	const onSort = React.useCallback(
		(_rows: unknown, rowSourceIndex: number, rowDestinationIndex: number) => {
			onMoveRow(rowSourceIndex, rowDestinationIndex);
		},
		[
			onMoveRow,
		],
	);

	const columnWidths = React.useMemo(
		() => {
			const widths: Array<number | string> = [];

			if (showRowNumbers) {
				widths.push(40);
			}

			fields.forEach((field) => {
				widths.push(field.width ?? 'auto');
			});

			widths.push(55);

			return widths as Array<number | `${number}%` | 'auto'>;
		},
		[
			fields,
			showRowNumbers,
		],
	);

	const renderRow = React.useCallback(
		(props: NewTableChildProps<{ id }>) => {
			const {
				row,
				rowIndex,
			} = props;

			const isDisabled = isRowDisabled(row.id, rowIndex);

			const cells = fields.map((field) => {
				const fieldName = `${name}/${row.id}/${field.name}`;

				let renderedField = renderProp(field.renderer, {
					fieldName,
					isDisabled,
					rowId: row.id,
					rowIndex,
					rowValues: getRowValues(rowIndex),
				});

				if (field.validation) {
					renderedField = (
						<FieldStatus
							name={fieldName}
							showErrorMessageOnFocus={props.isDraggingInProgress}
							showIcon={false}
						>
							{renderedField}
						</FieldStatus>
					);
				}

				return (
					<NewTableCell key={fieldName}>
						{renderedField}
					</NewTableCell>
				);
			});

			return (
				<NewTableRow
					id={row.id}
					key={row.id}
				>
					{showRowNumbers && (
						<NewTableCell>{rowIndex + 1}.</NewTableCell>
					)}

					{cells}

					<NewTableCell>
						<TableDeleteButton
							isDisabled={isDisabled}
							onClick={() => onRemoveRow(row.id)}
						/>
					</NewTableCell>
				</NewTableRow>
			);
		},
		[
			fields,
			getRowValues,
			isRowDisabled,
			name,
			onRemoveRow,
			showRowNumbers,
		],
	);

	const renderHeader = React.useCallback(
		() => {
			if (!showHeaderWhenEmpty && rows.length === 0) {
				return null;
			}

			return (
				<NewTableRow
					id="header"
					type={NewTableRowType.Header}
				>
					{showRowNumbers && (
						<NewTableCell />
					)}

					{fields.map((field) => (
						<NewTableCell
							key={field.name}
							type={NewTableCellType.Header}
						>
							{field.label}
						</NewTableCell>
					),
					)}
				</NewTableRow>
			);
		},
		[
			showHeaderWhenEmpty,
			rows.length,
			showRowNumbers,
			fields,
		],
	);

	const renderFooter = React.useCallback(
		() => {
			if (!showAddButton) {
				return null;
			}

			return (
				<SimpleBox
					hasBorders={false}
					paddingSize={1}
				>
					<Button
						disabled={rows.length >= maximumRows}
						icon={(
							<BasicIcon type={BasicIconType.Plus} />
						)}
						onClick={onAddRow}
						size={ButtonSize.Small}
						style={ButtonStyle.Hollow}
						uppercase={true}
					>
						{addButtonLabel}
					</Button>
				</SimpleBox>
			);
		},
		[
			addButtonLabel,
			maximumRows,
			onAddRow,
			rows,
			showAddButton,
		],
	);

	return (
		<NewTable
			children={renderRow}
			columnWidths={columnWidths}
			footer={renderFooter()}
			headerRow={renderHeader()}
			headerRowHeight={32}
			isSortable={isSortable}
			onSort={onSort}
			rowHeight={rowHeight}
			rows={rows}
		/>
	);
};



export default ArrayGroupFieldTable;
