import React from 'react';

import styles from './ColumnsConfiguratorActions.module.scss';



type Props = {
	buttons?: React.ReactNode,
	deletionActionElements?: React.ReactNode,
	deletionButton?: React.ReactNode,
	deletionNote?: React.ReactNode,
	note?: React.ReactNode,
	revertChangesElement?: React.ReactNode,
	title?: React.ReactNode,
};

const ColumnsConfiguratorActions: React.FC<Props> = (props) => {
	const {
		buttons,
		deletionActionElements,
		deletionButton,
		deletionNote,
		note,
		revertChangesElement,
		title,
	} = props;

	return (
		<div className={styles.component}>
			{title && (
				<div className={styles.title}>
					{title}
				</div>
			)}

			{(deletionActionElements || deletionButton || deletionNote) && (
				<div className={styles.deletionRow}>
					{deletionActionElements}

					{deletionNote && (
						<div className={styles.note}>
							{deletionNote}
						</div>
					)}

					{deletionButton && (
						<div className={styles.deletionButton}>
							{deletionButton}
						</div>
					)}
				</div>
			)}

			{note && (
				<div className={styles.note}>
					{note}
				</div>
			)}

			{buttons && (
				<div className={styles.buttons}>
					{buttons}
				</div>
			)}

			{revertChangesElement && (
				<div className={styles.revertChanges}>
					{revertChangesElement}
				</div>
			)}
		</div>
	);
};



export default ColumnsConfiguratorActions;
