/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountIsTrialRevivableQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountIsTrialRevivableQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly trialRevivalDate: CK.Timestamp | null } | null };


export const AccountIsTrialRevivableDocument = gql`
    query AccountIsTrialRevivable($accountId: AccountId!) {
  account(id: $accountId) {
    id
    trialRevivalDate
  }
}
    `;

/**
 * __useAccountIsTrialRevivableQuery__
 *
 * To run a query within a React component, call `useAccountIsTrialRevivableQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountIsTrialRevivableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountIsTrialRevivableQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountIsTrialRevivableQuery(baseOptions: Apollo.QueryHookOptions<AccountIsTrialRevivableQuery, AccountIsTrialRevivableQueryVariables> & ({ variables: AccountIsTrialRevivableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountIsTrialRevivableQuery, AccountIsTrialRevivableQueryVariables>(AccountIsTrialRevivableDocument, options);
      }
export function useAccountIsTrialRevivableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountIsTrialRevivableQuery, AccountIsTrialRevivableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountIsTrialRevivableQuery, AccountIsTrialRevivableQueryVariables>(AccountIsTrialRevivableDocument, options);
        }
export function useAccountIsTrialRevivableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountIsTrialRevivableQuery, AccountIsTrialRevivableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountIsTrialRevivableQuery, AccountIsTrialRevivableQueryVariables>(AccountIsTrialRevivableDocument, options);
        }
export type AccountIsTrialRevivableQueryHookResult = ReturnType<typeof useAccountIsTrialRevivableQuery>;
export type AccountIsTrialRevivableLazyQueryHookResult = ReturnType<typeof useAccountIsTrialRevivableLazyQuery>;
export type AccountIsTrialRevivableSuspenseQueryHookResult = ReturnType<typeof useAccountIsTrialRevivableSuspenseQuery>;
export type AccountIsTrialRevivableQueryResult = Apollo.QueryResult<AccountIsTrialRevivableQuery, AccountIsTrialRevivableQueryVariables>;