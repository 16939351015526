import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import ArrayGroupField, {
	type ArrayGroupFieldRendererProps,
	type ArrayGroupFieldValidationInput,
} from '~/components/patterns/forms/fields/ArrayGroupField';
import Copy from '~/components/logic/Copy';
import TextField from '~/components/atoms/forms/components/TextField';

import {
	isString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	addButton: {
		id: 'ui.cookiesSetup.addButton',
	},
	columnName: {
		id: 'ui.cookiesSetup.columns.name',
	},
	columnValue: {
		id: 'ui.cookiesSetup.columns.value',
	},
	placeholderName: {
		id: 'ui.cookiesSetup.placeholders.name',
	},
	placeholderValue: {
		id: 'ui.cookiesSetup.placeholders.value',
	},
	formErrorCannotContainSemicolon: {
		id: 'ui.formErrors.cannotContainSemicolon',
	},
});



type Props = {
	name: string,
};

const CookiesField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	const intl = useIntl();

	const fields = React.useMemo(
		() => {
			return [
				{
					defaultValue: '',
					label: (
						<FormattedMessage {...messages.columnName} />
					),
					name: 'name',
					renderer: ({ fieldName }: ArrayGroupFieldRendererProps) => (
						<TextField
							name={fieldName}
							placeholder={(
								intl.formatMessage(messages.placeholderName)
							)}
							width="100%"
						/>
					),
					validation: ({ f, getValueInRow }: ArrayGroupFieldValidationInput) => {
						return [
							f.when(
								({ values }) => {
									const value = getValueInRow(values, 'value');

									return isString(value) && value.trim() !== '';
								},
							),
							f.validateNonEmpty(),
							f.custom({
								message: (
									<Copy {...messages.formErrorCannotContainSemicolon} />
								),
								rule: ({ value }) => /^[^;]*$/.test(value),
							}),
						];
					},
					width: 220,
				},
				{
					defaultValue: '',
					label: (
						<FormattedMessage {...messages.columnValue} />
					),
					name: 'value',
					renderer: ({ fieldName }: ArrayGroupFieldRendererProps) => (
						<TextField
							name={fieldName}
							placeholder={(
								intl.formatMessage(messages.placeholderValue)
							)}
							width="100%"
						/>
					),
					validation: ({ f, getValueInRow }: ArrayGroupFieldValidationInput) => ([
						f.when(
							({ values }) => {
								const name = getValueInRow(values, 'name');

								return isString(name) && name.trim() !== '';
							},
						),
						f.validateNonEmpty(),
						f.custom({
							message: (
								<Copy {...messages.formErrorCannotContainSemicolon} />
							),
							rule: ({ value }) => /^[^;]*$/.test(value),
						}),
					]),
				},
			];
		},
		[
			intl,
		],
	);

	return (
		<ArrayGroupField
			addButtonLabel={(
				<FormattedMessage {...messages.addButton} />
			)}
			fields={fields}
			minimumRows={1}
			name={name}
		/>
	);
};



export default CookiesField;
