import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import IndexNowIssuesRequiredToPassModal from '~/components/app/IndexNowIssuesRequiredToPassModal';
import IndexNowSegmentConditionsModal from '~/components/app/IndexNowSegmentConditionsModal';
import IndexNowUrlConditionsModal from '~/components/app/IndexNowUrlConditionsModal';
import InternalLink from '~/components/patterns/links/InternalLink';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import StaticText from '~/components/atoms/forms/components/StaticText';

import useIndexNowConditions from '~/hooks/useIndexNowConditions';
import useIsAllowedWithWebsite from '~/hooks/useIsAllowedWithWebsite';
import useModals from '~/hooks/useModals';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	blockingIssues: {
		id: 'ui.websiteSettingsIndexNowConditionsForm.blockingIssues',
	},
	changeLink: {
		id: 'ui.websiteSettingsIndexNowConditionsForm.changeLink',
	},
	issuesBlockingSubmission: {
		id: 'ui.websiteSettingsIndexNowConditionsForm.issuesBlockingSubmission',
	},
	segmentsBlockList: {
		id: 'ui.websiteSettingsIndexNowConditionsForm.segmentsBlocklist',
	},
	segmentsConfigured: {
		id: 'ui.websiteSettingsIndexNowConditionsForm.segmentsConfigured',
	},
	title: {
		id: 'ui.websiteSettingsIndexNowConditionsForm.title',
	},
	urlBlockList: {
		id: 'ui.websiteSettingsIndexNowConditionsForm.urlBlocklist',
	},
	urlsConfigured: {
		id: 'ui.websiteSettingsIndexNowConditionsForm.urlsConfigured',
	},
});



const WebsiteSettingsIndexNowConditionsForm: React.FC = () => {
	const modals = useModals();
	const websiteId = useWebsiteId();
	const indexNowConditions = useIndexNowConditions(websiteId);

	const isAllowedToManageSettings = useIsAllowedWithWebsite(
		websiteId,
		GraphQL.ActionWithWebsite.ManageSettings,
	);

	const openIssuesRequiredToPassModal = React.useCallback(
		() => {
			modals.openModal(() => (
				<IndexNowIssuesRequiredToPassModal />
			));
		},
		[
			modals,
		],
	);

	const openSegmentConditionsModal = React.useCallback(
		() => {
			modals.openModal(() => (
				<IndexNowSegmentConditionsModal />
			));
		},
		[
			modals,
		],
	);

	const openIndexNowUrlConditionsModal = React.useCallback(
		() => {
			modals.openModal(() => (
				<IndexNowUrlConditionsModal />
			));
		},
		[
			modals,
		],
	);

	function renderChangeLink(onClickCallback: () => void) {
		return (
			<FormattedMessage
				{...messages.changeLink}
				values={{
					linkModal: (chunks) => (
						<InternalLink onClickCallback={onClickCallback}>
							{chunks}
						</InternalLink>
					),
				}}
			/>
		);
	}

	function renderPart(editable: boolean) {
		return (
			<>
				<FormRows>
					<FormRow
						label={(
							<FormattedMessage {...messages.issuesBlockingSubmission} />
						)}
					>
						{indexNowConditions.loading ? (
							<SquareSkeleton width={150} />
						) : (
							<StaticText>
								<FormattedMessage
									{...messages.blockingIssues}
									values={{
										amount: indexNowConditions.issuesRequiredToPass?.length,
									}}
								/>
								{editable && renderChangeLink(openIssuesRequiredToPassModal)}
							</StaticText>
						)}
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.segmentsBlockList} />
						)}
					>
						{indexNowConditions.loading ? (
							<SquareSkeleton width={150} />
						) : (
							<StaticText>
								<FormattedMessage
									{...messages.segmentsConfigured}
									values={{
										mode: indexNowConditions.segmentsBlocklistMode === GraphQL.IndexNowSegmentsBlocklistMode.AllowAllExcept
											? 'allowAllExcept'
											: 'denyAllExcept',
										rules: (
											indexNowConditions.segmentsBlocklistMode === GraphQL.IndexNowSegmentsBlocklistMode.AllowAllExcept
												? indexNowConditions.segmentsBlocklistDisallowedSegments?.length
												: indexNowConditions.segmentsBlocklistAllowedSegments?.length
										),
									}}
								/>
								{editable && renderChangeLink(openSegmentConditionsModal)}
							</StaticText>
						)}
					</FormRow>

					<FormRow
						label={(
							<FormattedMessage {...messages.urlBlockList} />
						)}
					>
						{indexNowConditions.loading ? (
							<SquareSkeleton width={150} />
						) : (
							<StaticText>
								<FormattedMessage
									{...messages.urlsConfigured}
									values={{
										mode: indexNowConditions.urlBlocklistMode === GraphQL.UrlBlocklistMode.AllowAllExcept
											? 'allowAllExcept'
											: 'denyAllExcept',
										rules: indexNowConditions.urlBlocklistRules?.length,
									}}
								/>
								{editable && renderChangeLink(openIndexNowUrlConditionsModal)}
							</StaticText>
						)}
					</FormRow>
				</FormRows>

				{editable && (
					<ButtonsLayout>
						<CancelButton />
					</ButtonsLayout>
				)}
			</>
		);
	}

	return (
		<EditableFormWrapper
			isAllowed={isAllowedToManageSettings}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<DisplayPart>
				{renderPart(false)}
			</DisplayPart>

			<EditablePart>
				{renderPart(true)}
			</EditablePart>
		</EditableFormWrapper>
	);
};



export default WebsiteSettingsIndexNowConditionsForm;
