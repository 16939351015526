import React from 'react';

import FormFieldsContext from '~/components/atoms/forms/basis/FormFieldsContext';

import {
	validateField,
} from '~/components/app/validations';

import useFormContext from '~/hooks/useFormContext';
import useFormFieldsContext from '~/hooks/useFormFieldsContext';
import useFormValidations from '~/hooks/useFormValidations';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';
import {
	assertString,
} from '~/utilities/typeCheck';

import {
	type Rule,
	type Values,
} from '~/utilities/validations';
import {
	type FormOnChangeHandlerOptions,
} from '../basis/Form';



export type CompositeFieldValidationInput = {
	f: Parameters<Parameters<typeof validateField>[1]>[0],
	getValue: (values: Values, fieldName: string) => any,
};

type Field = {
	defaultValue?: any,
	name: string,
	validation?: (input: CompositeFieldValidationInput) => ReadonlyArray<Rule>,
};

function formatFieldName(
	name: string,
	field: Field,
) {
	return name + '/' + field.name;
}



const FallbackDefaultValue = {};
const FallbackValidation = () => [];



function createInternalFormFieldName(name: string, field: Field) {
	return `${name}/${field.name}`;
}

function createInternalFormFields(
	name: string,
	fields: Array<Field>,
	values: Values,
) {
	return fields.map((field) => {
		const defaultValue = (
			values[field.name]
			?? field.defaultValue
		);

		return {
			_originalName: field.name,
			defaultValue,
			name: createInternalFormFieldName(name, field),
			validation: field.validation ?? FallbackValidation,
		};
	});
}



type Props = {
	children: RenderProp<{
		getFieldName: (field: string) => string,
	}>,
	fields: Array<Field>,
	name: string,
};

const CompositeField: React.FC<Props> = (props) => {
	const {
		children,
		fields,
		name,
	} = props;

	const externalFormContext = useFormContext();
	const externalFormContextOnChangeHandler = externalFormContext.onChangeHandler;
	const externalFormDefaultValue = externalFormContext.defaultValues[name] ?? FallbackDefaultValue;

	const [internalFormFields] = React.useState(
		createInternalFormFields(name, fields, externalFormDefaultValue),
	);

	const internalFormFieldsContext = useFormFieldsContext(internalFormFields);

	const internalFormContext = React.useMemo(
		() => {
			const valuesSelector = internalFormFieldsContext.contextForProvider.valuesSelector;
			const onChangeHandler = internalFormFieldsContext.contextForProvider.onChangeHandler;

			function compositeFieldValuesSelector(values: Values) {
				// Run the external context values selector first to ensure that the external context values
				// are as expected.
				const externalValues = valuesSelector(values);

				const fieldValues = {};
				fields.forEach((field) => {
					const fieldName = createInternalFormFieldName(name, field);
					const fieldValue = externalValues[name]?.[field.name];

					fieldValues[fieldName] = fieldValue;
				});

				return fieldValues;
			}

			// Remap the onChangeHandler to update the parent context values.
			// Reverse the process of the arrayGroupValuesSelector.
			async function compositeFieldChangeHandler(
				fieldName: string,
				fieldValue: any,
				options?: FormOnChangeHandlerOptions,
			) {
				await onChangeHandler(fieldName, fieldValue, options);

				const externalFieldValues = externalFormContext.values[name];

				const [field] = fieldName.split('/').slice(-1);
				assertString(field);

				if (externalFieldValues?.[name]?.[field] !== fieldValue) {
					const nextExternalFieldValues = structuredClone(externalFieldValues) ?? {};

					nextExternalFieldValues[field] = fieldValue;

					externalFormContextOnChangeHandler(name, nextExternalFieldValues, options);
				}

				return Promise.resolve();
			}

			return {
				...internalFormFieldsContext.contextForProvider,
				onChangeHandler: compositeFieldChangeHandler,
				valuesSelector: compositeFieldValuesSelector,
			};
		},
		[
			fields,
			externalFormContext.values,
			externalFormContextOnChangeHandler,
			internalFormFieldsContext.contextForProvider,
			name,
		],
	);

	useFormValidations(
		name,
		React.useCallback(
			() => {
				const validations: Record<string, Array<Rule>> = {};

				internalFormFields.forEach((field) => {
					const fieldValidation = field.validation;

					validations[field.name] = validateField(field.name, (f) => {
						function getValue(values: Values, fieldName: string) {
							return values[fieldName];
						}

						f.setValueSelector(
							() => {
								return getValue(internalFormFieldsContext.state.values, field.name);
							},
						);

						const input: CompositeFieldValidationInput = {
							f,
							getValue,
						};

						return fieldValidation(input);
					});
				});

				return validations;
			},
			[internalFormFields, internalFormFieldsContext.state.values],
		),
	);

	const childrenInput = React.useMemo(
		() => {
			const fieldNames = Object.fromEntries(
				fields.map(
					(field) => ([
						field.name,
						formatFieldName(name, field),
					]),
				),
			);

			return {
				getFieldName: (field: string) => {
					const fieldName = fieldNames[field];

					if (fieldName === undefined) {
						throw new Error(`Unknown field '${field}'`);
					}

					return fieldName;
				},
			};
		},
		[
			fields,
			name,
		],
	);

	return (
		<FormFieldsContext context={internalFormContext}>
			{renderProp(children, childrenInput)}
		</FormFieldsContext>
	);
};



export default CompositeField;
