import classNames from 'classnames';
import React from 'react';



type Props = {
	footer?: React.ReactNode,
	children: React.ReactNode,
	logo: React.ReactNode,
};

const AuthScreenLayout: React.FC<Props> = (props) => {
	const {
		footer,
		children,
		logo,
	} = props;

	const componentClasses = classNames({
		'auth-page': true,
		'auth-page--has-footer': footer,
		'js-scrollable': true,
	});

	return (
		<div className={componentClasses}>
			<div className="auth-page__outer-container">
				<div className="auth-page__container">
					<div className="auto-page__logo">
						{logo}
					</div>

					<div className="auth-page__center-wrapper">
						<div className="auth-page__content">
							{children}
						</div>
					</div>
				</div>

				{footer && (
					<div className="auth-page__footer">
						{footer}
					</div>
				)}
			</div>
		</div>
	);
};



export default AuthScreenLayout;
