/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteIntegrationsQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteIntegrationsQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly integrations: ReadonlyArray<{ readonly __typename?: 'WebsiteIntegration', readonly isNotOwned: boolean, readonly status: GraphQL.WebsiteIntegrationStatus, readonly type: GraphQL.WebsiteIntegrationType }> } | null };


export const WebsiteIntegrationsDocument = gql`
    query WebsiteIntegrations($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    integrations {
      isNotOwned
      status
      type
    }
  }
}
    `;

/**
 * __useWebsiteIntegrationsQuery__
 *
 * To run a query within a React component, call `useWebsiteIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteIntegrationsQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteIntegrationsQuery(baseOptions: Apollo.QueryHookOptions<WebsiteIntegrationsQuery, WebsiteIntegrationsQueryVariables> & ({ variables: WebsiteIntegrationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIntegrationsQuery, WebsiteIntegrationsQueryVariables>(WebsiteIntegrationsDocument, options);
      }
export function useWebsiteIntegrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIntegrationsQuery, WebsiteIntegrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIntegrationsQuery, WebsiteIntegrationsQueryVariables>(WebsiteIntegrationsDocument, options);
        }
export function useWebsiteIntegrationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteIntegrationsQuery, WebsiteIntegrationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteIntegrationsQuery, WebsiteIntegrationsQueryVariables>(WebsiteIntegrationsDocument, options);
        }
export type WebsiteIntegrationsQueryHookResult = ReturnType<typeof useWebsiteIntegrationsQuery>;
export type WebsiteIntegrationsLazyQueryHookResult = ReturnType<typeof useWebsiteIntegrationsLazyQuery>;
export type WebsiteIntegrationsSuspenseQueryHookResult = ReturnType<typeof useWebsiteIntegrationsSuspenseQuery>;
export type WebsiteIntegrationsQueryResult = Apollo.QueryResult<WebsiteIntegrationsQuery, WebsiteIntegrationsQueryVariables>;