import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import ColumnName from '~/components/names/ColumnName';
import ColumnValueFormatter from '~/components/logic/formatters/ColumnValueFormatter';
import DatePeriodLabel from '~/components/app/DatePeriodLabel';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermValueSkeleton from './DefinitionTermValueSkeleton';
import LabeledValue, {
	LabeledValueFlow,
} from '~/components/patterns/structuredValues/labeledValues/LabeledValue';
import LabeledValuesList from '~/components/patterns/structuredValues/labeledValues/LabeledValuesList';

import {
	useAdobeAnalyticsBlockQuery,
} from './AdobeAnalyticsBlock.gql';

import useLegacyUrlId from '~/hooks/useLegacyUrlId';
import usePageDetailPropertiesQuery from '~/hooks/usePageDetailPropertiesQuery';
import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	title: {
		id: 'ui.pageDetail.adobeAnalytics.title',
	},
});



const ColumnMapping = [
	[CK.PagesCommonColumn.AaPageViews, 'pageViews'],
	[CK.PagesCommonColumn.AaUniqueVisitors, 'uniqueVisitors'],
	[CK.PagesCommonColumn.AaAverageTimeSpentOnSite, 'averageTimeSpentOnSite'],
	[CK.PagesCommonColumn.AaBounceRate, 'bounceRate'],
	[CK.PagesCommonColumn.AaRevenue, 'revenue'],
] as const;



const AdobeAnalyticsBlock: React.FC = () => {
	const legacyUrlId = useLegacyUrlId();
	const websiteId = useWebsiteId();

	const { data } = usePageDetailPropertiesQuery(
		useAdobeAnalyticsBlockQuery,
		legacyUrlId,
		websiteId,
	);

	const adobeAnalyticsData = data?.lookupPageByLegacyId?.adobeAnalyticsData ?? null;
	const adobeAnalyticsMissingDataReason = data?.lookupPageByLegacyId?.adobeAnalyticsMissingDataReason ?? null;

	return (
		<BorderedBox
			headerIcon={(
				<AttributesGroupIcon
					size={28}
					type={AttributesGroupIconType.AdobeAnalytics}
				/>
			)}
			headerLabel={(
				<FormattedMessage {...messages.title} />
			)}
			headerSublabel={adobeAnalyticsData !== null && (
				<DatePeriodLabel
					since={new Date(adobeAnalyticsData.dateRange.since)}
					until={new Date(adobeAnalyticsData.dateRange.until)}
				/>
			)}
			paddingSize={3}
		>
			<LabeledValuesList>
				{ColumnMapping.map(([column, field]) => {
					const label = (
						<ColumnName column={column} />
					);

					let showAsDefinitionTerm = true;
					let value;

					if (adobeAnalyticsData !== null) {
						showAsDefinitionTerm = false;
						value = adobeAnalyticsData[field];
					} else if (adobeAnalyticsMissingDataReason !== null) {
						value = adobeAnalyticsMissingDataReason;
					}

					if (value !== undefined) {
						value = (
							<ColumnValueFormatter
								column={column}
								value={value}
							/>
						);
					} else {
						value = (
							<DefinitionTermValueSkeleton />
						);
					}

					if (showAsDefinitionTerm) {
						return (
							<DefinitionTerm
								description={value}
								key={column}
								term={label}
							/>
						);
					}

					return (
						<LabeledValue
							flow={LabeledValueFlow.Row}
							key={column}
							label={label}
							maxWidth={350}
						>
							{value}
						</LabeledValue>
					);
				})}
			</LabeledValuesList>
		</BorderedBox>
	);
};



export default AdobeAnalyticsBlock;
