/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateEndUserPlanMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  billingCycle: GraphQL.Term;
  endUserPlan: GraphQL.EndUserPlan;
  threeDSecureToken: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
}>;


export type UpdateEndUserPlanMutation = { readonly __typename?: 'Mutation', readonly UpdateEndUserPlan: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly billingCycle: GraphQL.Term, readonly pageBundle: number | null, readonly plan: GraphQL.AccountPlan | null, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID }> } | null } } };


export const UpdateEndUserPlanDocument = gql`
    mutation UpdateEndUserPlan($accountId: AccountId!, $billingCycle: Term!, $endUserPlan: EndUserPlan!, $threeDSecureToken: String) {
  UpdateEndUserPlan(
    accountId: $accountId
    billingCycle: $billingCycle
    endUserPlan: $endUserPlan
    threeDSecureToken: $threeDSecureToken
  ) {
    query {
      account(id: $accountId) {
        id
        billingCycle
        features {
          feature
          id
        }
        pageBundle
        plan
      }
    }
  }
}
    `;
export type UpdateEndUserPlanMutationFn = Apollo.MutationFunction<UpdateEndUserPlanMutation, UpdateEndUserPlanMutationVariables>;

/**
 * __useUpdateEndUserPlanMutation__
 *
 * To run a mutation, you first call `useUpdateEndUserPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEndUserPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEndUserPlanMutation, { data, loading, error }] = useUpdateEndUserPlanMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      billingCycle: // value for 'billingCycle'
 *      endUserPlan: // value for 'endUserPlan'
 *      threeDSecureToken: // value for 'threeDSecureToken'
 *   },
 * });
 */
export function useUpdateEndUserPlanMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEndUserPlanMutation, UpdateEndUserPlanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEndUserPlanMutation, UpdateEndUserPlanMutationVariables>(UpdateEndUserPlanDocument, options);
      }
export type UpdateEndUserPlanMutationHookResult = ReturnType<typeof useUpdateEndUserPlanMutation>;
export type UpdateEndUserPlanMutationResult = Apollo.MutationResult<UpdateEndUserPlanMutation>;
export type UpdateEndUserPlanMutationOptions = Apollo.BaseMutationOptions<UpdateEndUserPlanMutation, UpdateEndUserPlanMutationVariables>;