/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HiddenDashboardScopesQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type HiddenDashboardScopesQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly personalTweaks: { readonly __typename?: 'UserPersonalTweaks', readonly hiddenDashboardScopes: ReadonlyArray<Record<string, any>> } | null } } | null };


export const HiddenDashboardScopesDocument = gql`
    query HiddenDashboardScopes {
  authenticatedSession {
    user {
      id
      legacyId
      personalTweaks {
        hiddenDashboardScopes
      }
    }
  }
}
    `;

/**
 * __useHiddenDashboardScopesQuery__
 *
 * To run a query within a React component, call `useHiddenDashboardScopesQuery` and pass it any options that fit your needs.
 * When your component renders, `useHiddenDashboardScopesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHiddenDashboardScopesQuery({
 *   variables: {
 *   },
 * });
 */
export function useHiddenDashboardScopesQuery(baseOptions?: Apollo.QueryHookOptions<HiddenDashboardScopesQuery, HiddenDashboardScopesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HiddenDashboardScopesQuery, HiddenDashboardScopesQueryVariables>(HiddenDashboardScopesDocument, options);
      }
export function useHiddenDashboardScopesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HiddenDashboardScopesQuery, HiddenDashboardScopesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HiddenDashboardScopesQuery, HiddenDashboardScopesQueryVariables>(HiddenDashboardScopesDocument, options);
        }
export function useHiddenDashboardScopesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HiddenDashboardScopesQuery, HiddenDashboardScopesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HiddenDashboardScopesQuery, HiddenDashboardScopesQueryVariables>(HiddenDashboardScopesDocument, options);
        }
export type HiddenDashboardScopesQueryHookResult = ReturnType<typeof useHiddenDashboardScopesQuery>;
export type HiddenDashboardScopesLazyQueryHookResult = ReturnType<typeof useHiddenDashboardScopesLazyQuery>;
export type HiddenDashboardScopesSuspenseQueryHookResult = ReturnType<typeof useHiddenDashboardScopesSuspenseQuery>;
export type HiddenDashboardScopesQueryResult = Apollo.QueryResult<HiddenDashboardScopesQuery, HiddenDashboardScopesQueryVariables>;