/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountDefaultLighthouseThresholdsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountDefaultLighthouseThresholdsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly defaultLighthouseThresholds: { readonly __typename?: 'WebsiteLighthouseThresholds', readonly cls: ReadonlyArray<number>, readonly fcp: ReadonlyArray<number>, readonly lcp: ReadonlyArray<number>, readonly performance: ReadonlyArray<number>, readonly si: ReadonlyArray<number>, readonly tbt: ReadonlyArray<number>, readonly tti: ReadonlyArray<number> } } | null };


export const AccountDefaultLighthouseThresholdsDocument = gql`
    query AccountDefaultLighthouseThresholds($accountId: AccountId!) {
  account(id: $accountId) {
    id
    defaultLighthouseThresholds {
      cls
      fcp
      lcp
      performance
      si
      tbt
      tti
    }
  }
}
    `;

/**
 * __useAccountDefaultLighthouseThresholdsQuery__
 *
 * To run a query within a React component, call `useAccountDefaultLighthouseThresholdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDefaultLighthouseThresholdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDefaultLighthouseThresholdsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountDefaultLighthouseThresholdsQuery(baseOptions: Apollo.QueryHookOptions<AccountDefaultLighthouseThresholdsQuery, AccountDefaultLighthouseThresholdsQueryVariables> & ({ variables: AccountDefaultLighthouseThresholdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountDefaultLighthouseThresholdsQuery, AccountDefaultLighthouseThresholdsQueryVariables>(AccountDefaultLighthouseThresholdsDocument, options);
      }
export function useAccountDefaultLighthouseThresholdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDefaultLighthouseThresholdsQuery, AccountDefaultLighthouseThresholdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountDefaultLighthouseThresholdsQuery, AccountDefaultLighthouseThresholdsQueryVariables>(AccountDefaultLighthouseThresholdsDocument, options);
        }
export function useAccountDefaultLighthouseThresholdsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountDefaultLighthouseThresholdsQuery, AccountDefaultLighthouseThresholdsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountDefaultLighthouseThresholdsQuery, AccountDefaultLighthouseThresholdsQueryVariables>(AccountDefaultLighthouseThresholdsDocument, options);
        }
export type AccountDefaultLighthouseThresholdsQueryHookResult = ReturnType<typeof useAccountDefaultLighthouseThresholdsQuery>;
export type AccountDefaultLighthouseThresholdsLazyQueryHookResult = ReturnType<typeof useAccountDefaultLighthouseThresholdsLazyQuery>;
export type AccountDefaultLighthouseThresholdsSuspenseQueryHookResult = ReturnType<typeof useAccountDefaultLighthouseThresholdsSuspenseQuery>;
export type AccountDefaultLighthouseThresholdsQueryResult = Apollo.QueryResult<AccountDefaultLighthouseThresholdsQuery, AccountDefaultLighthouseThresholdsQueryVariables>;