import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import ArrayGroupField, {
	type ArrayGroupFieldRendererProps,
	type ArrayGroupFieldValidationInput,
} from '~/components/patterns/forms/fields/ArrayGroupField';
import SelectField, {
	SelectFieldSize,
} from '~/components/atoms/forms/components/SelectField';
import TextField, {
	TextFieldSize,
} from '~/components/atoms/forms/components/TextField';

import {
	validateExpressionOfRegexp,
} from '~/model/customElements';

import {
	isNonEmptyString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	[GraphQL.UrlBlocklistMode.AllowAllExcept]: {
		id: 'ui.urlBlocklistForm.mode.allowAllExcept',
	},
	[GraphQL.UrlBlocklistMode.DenyAllExcept]: {
		id: 'ui.urlBlocklistForm.mode.denyAllExcept',
	},
	[GraphQL.UrlBlocklistRuleOperator.Contains]: {
		id: 'ui.urlBlocklistForm.operator.contains',
	},
	[GraphQL.UrlBlocklistRuleOperator.EndsWith]: {
		id: 'ui.urlBlocklistForm.operator.endsWith',
	},
	[GraphQL.UrlBlocklistRuleOperator.Equals]: {
		id: 'ui.urlBlocklistForm.operator.equals',
	},
	[GraphQL.UrlBlocklistRuleOperator.Matches]: {
		id: 'ui.urlBlocklistForm.operator.matches',
	},
	[GraphQL.UrlBlocklistRuleOperator.NotContains]: {
		id: 'ui.urlBlocklistForm.operator.notContains',
	},
	[GraphQL.UrlBlocklistRuleOperator.StartsWith]: {
		id: 'ui.urlBlocklistForm.operator.startsWith',
	},
	columnOperator: {
		id: 'ui.urlBlocklistForm.column.operator',
	},
	columnValue: {
		id: 'ui.urlBlocklistForm.column.value',
	},
	formErrorMaxRules: {
		id: 'ui.urlBlocklistForm.maximumRules',
	},
	formErrorInvalidRegexp: {
		id: 'ui.customElements.formErrors.invalidRegexp',
	},
});



type Props = {
	__UGLY__transformEnumsToCamelCase?: boolean,
	name: string,
	maximumNumberOfRules?: number,
};

const UrlBlocklistField: React.FC<Props> = (props) => {
	const {
		__UGLY__transformEnumsToCamelCase,
		maximumNumberOfRules = 8,
		name,
	} = props;

	const fields = React.useMemo(
		() => {
			const enumCase = (
				__UGLY__transformEnumsToCamelCase
					? (string) => string.charAt(0).toLowerCase() + string.slice(1)
					: (string) => string
			);

			const options = [
				GraphQL.UrlBlocklistRuleOperator.Contains,
				GraphQL.UrlBlocklistRuleOperator.EndsWith,
				GraphQL.UrlBlocklistRuleOperator.NotContains,
				GraphQL.UrlBlocklistRuleOperator.StartsWith,
				GraphQL.UrlBlocklistRuleOperator.Equals,
				GraphQL.UrlBlocklistRuleOperator.Matches,
			] as const;

			return [
				{
					label: (
						<FormattedMessage {...messages.columnOperator} />
					),
					name: 'operator',
					renderer: ({ fieldName }: ArrayGroupFieldRendererProps) => (
						<SelectField
							name={fieldName}
							options={options.map(
								(option) => ({
									label: (
										<FormattedMessage {...messages[option]} />
									),
									name: enumCase(option),
								}),
							)}
							size={SelectFieldSize.Small}
							width="100%"
						/>
					),
					validation: ({ f, getValueInRow }: ArrayGroupFieldValidationInput) => ([
						f.when(
							({ values }) => isNonEmptyString(getValueInRow(values, 'value')),
						),
						f.validateNonEmpty(),
					]),
					width: 220,
				},
				{
					defaultValue: '',
					label: (
						<FormattedMessage {...messages.columnValue} />
					),
					name: 'value',
					renderer: ({ fieldName }: ArrayGroupFieldRendererProps) => (
						<TextField
							name={fieldName}
							size={TextFieldSize.Small}
							width="100%"
						/>
					),
					validation: ({ f, getValueInRow }: ArrayGroupFieldValidationInput) => ([
						f.when(
							({ values }) => isNonEmptyString(getValueInRow(values, 'operator')),
						),
						f.validateNonEmpty(),
						f.validateMaximumLength(256),
						f.custom({
							message: (
								<FormattedMessage {...messages.formErrorInvalidRegexp} />
							),
							rule: ({ value, values }) => {
								const operator = getValueInRow(values, 'operator');

								if (operator !== enumCase(GraphQL.UrlBlocklistRuleOperator.Matches)) {
									return true;
								}

								return validateExpressionOfRegexp(value);
							},
						}),
					]),
				},
			];
		},
		[
			__UGLY__transformEnumsToCamelCase,
		],
	);

	return (
		<ArrayGroupField
			fields={fields}
			maximumRows={maximumNumberOfRules}
			minimumRows={1}
			name={name}
			showRowNumbers={false}
		/>
	);
};



export default UrlBlocklistField;
