import classNames from 'classnames';
import React from 'react';

import Hint from '~/components/patterns/hints/hint/Hint';



export enum ColumnControllerSize {
	Default = 'default',
	Small = 'small',
}

type Props = {
	isDisabled?: boolean,
	label: React.ReactNode,
	labelTooltip?: React.ReactNode,
	size?: ColumnControllerSize,
};



const ColumnController: React.FC<Props> = (props) => {
	const {
		isDisabled,
		label,
		labelTooltip,
		size = ColumnControllerSize.Default,
	} = props;

	const componentClasses = classNames({
		'page-column-controller': true,
		'page-column-controller--disabled': isDisabled,
		['page-column-controller--' + size + '-size']: true,
	});

	return (
		<div className={componentClasses}>
			<div className="page-column-controller__content">
				<div className="page-column-controller__checkbox">
					{labelTooltip ? (
						<Hint
							popup={labelTooltip}
							popupOffset={[0, -8]}
						>
							{label}
						</Hint>
					) : label}
				</div>
			</div>
		</div>
	);
};



export default ColumnController;
