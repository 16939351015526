import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import HighlightedDate from '~/components/patterns/time/HighlightedDate';
import RobotsTxtStatusMessage from '~/components/app/RobotsTxtStatusMessage';
import SearchEngineActivityBadges, {
	SearchEngineActivityBadgesWording,
} from '~/components/app/SearchEngineActivityBadges';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import Version from '~/components/patterns/time/versionsOverview/Version';
import VersionsOverview from '~/components/patterns/time/versionsOverview/VersionsOverview';

import {
	useRobotsTxtViewerSidebarQuery,
} from '~/components/app/RobotsTxtViewerSidebar.gql';

import useBatchContextRobotsTxt from '~/hooks/useBatchContextRobotsTxt';
import useWebsiteId from '~/hooks/useWebsiteId';
import useWebsiteIsCrawlingPaused from '~/hooks/useWebsiteIsCrawlingPaused';



const DEFAULT_REVISIONS_LIST = [];

const messages = defineMessages({
	liveVersion: {
		id: 'ui.robotsTxtViewSidebar.liveVersion',
	},
});



type Props = {
	onClickRevision: (revisionId: CK.ID) => void,
	selectedRevisionId?: CK.ID | null,
};

const RobotsTxtViewSidebar: React.FC<Props> = (props) => {
	const {
		selectedRevisionId,
		onClickRevision,
	} = props;

	const websiteId = useWebsiteId();
	const isCrawlingPaused = useWebsiteIsCrawlingPaused(websiteId);

	const {
		data,
		loading,
	} = useRobotsTxtViewerSidebarQuery({
		context: useBatchContextRobotsTxt(websiteId),
		variables: {
			websiteId,
		},
	});

	const revisionsList = data?.list ?? DEFAULT_REVISIONS_LIST;
	const latestRevisionId = data?.latest?.id ?? null;

	return (
		<VersionsOverview>
			{loading && revisionsList.length === 0 && (
				<Version
					date={(
						<SquareSkeleton
							height={16}
							width={135}
						/>
					)}
					isHighlighted={true}
				/>
			)}

			{revisionsList.map((revision) => {
				const showStatusMessage = (
					revision.responseStatusCode === null
					|| revision.responseStatusCode === 0
					|| revision.responseStatusCode >= 300
					|| revision.responseFailureReason !== null
					|| revision.isContentEmpty
				);

				const showLiveVersionFlag = (
					revision.id === latestRevisionId
					&& !isCrawlingPaused
				) && !showStatusMessage;

				return (
					<Version
						alert={showStatusMessage && (
							<RobotsTxtStatusMessage
								failureReason={revision.responseFailureReason}
								isContentEmpty={revision.isContentEmpty}
								isLatestRevision={revision.id === latestRevisionId}
								shortMessage={true}
								statusCode={revision.responseStatusCode}
							/>
						)}
						date={(
							<HighlightedDate timestamp={revision.createdAt} />
						)}
						flag={showLiveVersionFlag && (
							<FormattedMessage {...messages.liveVersion} />
						)}
						isHighlighted={revision.id === selectedRevisionId}
						key={revision.id}
						onClick={() => {
							onClickRevision(revision.id);
						}}
						searchEngineActivityStates={(
							<SearchEngineActivityBadges
								isCompact={true}
								searchEngineActivity={revision.searchEngineActivity}
								wording={SearchEngineActivityBadgesWording.SeenBySearchEngine}
							/>
						)}
					/>
				);
			})}
		</VersionsOverview>
	);
};



export default RobotsTxtViewSidebar;
