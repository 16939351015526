/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HealthChangesWidgetQueryVariables = GraphQL.Exact<{
  endDate: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  startDate: GraphQL.Scalars['DateYMD']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type HealthChangesWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly healthChanges: { readonly __typename?: 'WebsiteDashboardHealthChanges', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: ReadonlyArray<{ readonly __typename?: 'WebsiteDashboardHealthChangesData', readonly date: CK.DateYMD, readonly health: number | null }> | null } } };


export const HealthChangesWidgetDocument = gql`
    query HealthChangesWidget($endDate: DateYMD!, $scope: String!, $startDate: DateYMD!, $websiteId: WebsiteId!) {
  dashboardData {
    healthChanges(
      endDate: $endDate
      scope: $scope
      startDate: $startDate
      websiteId: $websiteId
    ) {
      data {
        date
        health
      }
      id
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __useHealthChangesWidgetQuery__
 *
 * To run a query within a React component, call `useHealthChangesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useHealthChangesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHealthChangesWidgetQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      scope: // value for 'scope'
 *      startDate: // value for 'startDate'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useHealthChangesWidgetQuery(baseOptions: Apollo.QueryHookOptions<HealthChangesWidgetQuery, HealthChangesWidgetQueryVariables> & ({ variables: HealthChangesWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HealthChangesWidgetQuery, HealthChangesWidgetQueryVariables>(HealthChangesWidgetDocument, options);
      }
export function useHealthChangesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HealthChangesWidgetQuery, HealthChangesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HealthChangesWidgetQuery, HealthChangesWidgetQueryVariables>(HealthChangesWidgetDocument, options);
        }
export function useHealthChangesWidgetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HealthChangesWidgetQuery, HealthChangesWidgetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HealthChangesWidgetQuery, HealthChangesWidgetQueryVariables>(HealthChangesWidgetDocument, options);
        }
export type HealthChangesWidgetQueryHookResult = ReturnType<typeof useHealthChangesWidgetQuery>;
export type HealthChangesWidgetLazyQueryHookResult = ReturnType<typeof useHealthChangesWidgetLazyQuery>;
export type HealthChangesWidgetSuspenseQueryHookResult = ReturnType<typeof useHealthChangesWidgetSuspenseQuery>;
export type HealthChangesWidgetQueryResult = Apollo.QueryResult<HealthChangesWidgetQuery, HealthChangesWidgetQueryVariables>;