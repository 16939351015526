/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HomeAccountIdsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type HomeAccountIdsQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }> } | null };


export const HomeAccountIdsDocument = gql`
    query HomeAccountIds {
  authenticatedSession {
    memberships {
      account {
        id
      }
      user {
        id
        legacyId
      }
    }
  }
}
    `;

/**
 * __useHomeAccountIdsQuery__
 *
 * To run a query within a React component, call `useHomeAccountIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeAccountIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeAccountIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHomeAccountIdsQuery(baseOptions?: Apollo.QueryHookOptions<HomeAccountIdsQuery, HomeAccountIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeAccountIdsQuery, HomeAccountIdsQueryVariables>(HomeAccountIdsDocument, options);
      }
export function useHomeAccountIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeAccountIdsQuery, HomeAccountIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeAccountIdsQuery, HomeAccountIdsQueryVariables>(HomeAccountIdsDocument, options);
        }
export function useHomeAccountIdsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HomeAccountIdsQuery, HomeAccountIdsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeAccountIdsQuery, HomeAccountIdsQueryVariables>(HomeAccountIdsDocument, options);
        }
export type HomeAccountIdsQueryHookResult = ReturnType<typeof useHomeAccountIdsQuery>;
export type HomeAccountIdsLazyQueryHookResult = ReturnType<typeof useHomeAccountIdsLazyQuery>;
export type HomeAccountIdsSuspenseQueryHookResult = ReturnType<typeof useHomeAccountIdsSuspenseQuery>;
export type HomeAccountIdsQueryResult = Apollo.QueryResult<HomeAccountIdsQuery, HomeAccountIdsQueryVariables>;