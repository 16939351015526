/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HeadersSnapshotQueryVariables = GraphQL.Exact<{
  timestamp: GraphQL.Scalars['Timestamp']['input'];
  urlId: GraphQL.Scalars['UrlId']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type HeadersSnapshotQuery = { readonly __typename?: 'Query', readonly responseSnapshot: { readonly __typename?: 'ResponseSnapshot', readonly id: CK.ID | null, readonly isRendered: boolean, readonly isResponseBodyTracked: boolean, readonly isFailedResponse: boolean, readonly responseFailureReason: GraphQL.FetchingFailureReason | null, readonly responsePrimaryIp: string | null, readonly responseStatusCode: number | null, readonly requestHeaders: ReadonlyArray<{ readonly __typename?: 'HttpHeader', readonly name: string, readonly values: ReadonlyArray<string> }>, readonly responseHeaders: ReadonlyArray<{ readonly __typename?: 'HttpHeader', readonly name: string, readonly values: ReadonlyArray<string> }> | null } | null };


export const HeadersSnapshotDocument = gql`
    query HeadersSnapshot($timestamp: Timestamp!, $urlId: UrlId!, $websiteId: WebsiteId!) {
  responseSnapshot(
    timeReceivedAt: $timestamp
    urlId: $urlId
    websiteId: $websiteId
  ) {
    id
    isRendered
    isResponseBodyTracked
    isFailedResponse
    requestHeaders {
      name
      values
    }
    responseFailureReason
    responseHeaders {
      name
      values
    }
    responsePrimaryIp
    responseStatusCode
  }
}
    `;

/**
 * __useHeadersSnapshotQuery__
 *
 * To run a query within a React component, call `useHeadersSnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeadersSnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeadersSnapshotQuery({
 *   variables: {
 *      timestamp: // value for 'timestamp'
 *      urlId: // value for 'urlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useHeadersSnapshotQuery(baseOptions: Apollo.QueryHookOptions<HeadersSnapshotQuery, HeadersSnapshotQueryVariables> & ({ variables: HeadersSnapshotQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HeadersSnapshotQuery, HeadersSnapshotQueryVariables>(HeadersSnapshotDocument, options);
      }
export function useHeadersSnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HeadersSnapshotQuery, HeadersSnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HeadersSnapshotQuery, HeadersSnapshotQueryVariables>(HeadersSnapshotDocument, options);
        }
export function useHeadersSnapshotSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HeadersSnapshotQuery, HeadersSnapshotQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HeadersSnapshotQuery, HeadersSnapshotQueryVariables>(HeadersSnapshotDocument, options);
        }
export type HeadersSnapshotQueryHookResult = ReturnType<typeof useHeadersSnapshotQuery>;
export type HeadersSnapshotLazyQueryHookResult = ReturnType<typeof useHeadersSnapshotLazyQuery>;
export type HeadersSnapshotSuspenseQueryHookResult = ReturnType<typeof useHeadersSnapshotSuspenseQuery>;
export type HeadersSnapshotQueryResult = Apollo.QueryResult<HeadersSnapshotQuery, HeadersSnapshotQueryVariables>;