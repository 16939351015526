/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReviewsFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type ReviewsFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly adminSettings: { readonly __typename?: 'AccountAdminSettings', readonly numberOfReviewsPerReviewSite: ReadonlyArray<{ readonly __typename?: 'AccountNumberOfReviewsOnReviewSite', readonly numberOfReviews: number, readonly reviewSite: string }> } | null } | null };


export const ReviewsFormDocument = gql`
    query ReviewsForm($accountId: AccountId!) {
  account(id: $accountId) {
    adminSettings {
      numberOfReviewsPerReviewSite {
        numberOfReviews
        reviewSite
      }
    }
    id
  }
}
    `;

/**
 * __useReviewsFormQuery__
 *
 * To run a query within a React component, call `useReviewsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useReviewsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReviewsFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useReviewsFormQuery(baseOptions: Apollo.QueryHookOptions<ReviewsFormQuery, ReviewsFormQueryVariables> & ({ variables: ReviewsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReviewsFormQuery, ReviewsFormQueryVariables>(ReviewsFormDocument, options);
      }
export function useReviewsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReviewsFormQuery, ReviewsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReviewsFormQuery, ReviewsFormQueryVariables>(ReviewsFormDocument, options);
        }
export function useReviewsFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReviewsFormQuery, ReviewsFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReviewsFormQuery, ReviewsFormQueryVariables>(ReviewsFormDocument, options);
        }
export type ReviewsFormQueryHookResult = ReturnType<typeof useReviewsFormQuery>;
export type ReviewsFormLazyQueryHookResult = ReturnType<typeof useReviewsFormLazyQuery>;
export type ReviewsFormSuspenseQueryHookResult = ReturnType<typeof useReviewsFormSuspenseQuery>;
export type ReviewsFormQueryResult = Apollo.QueryResult<ReviewsFormQuery, ReviewsFormQueryVariables>;