/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GoogleAnalyticsBlockQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type GoogleAnalyticsBlockQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly googleAnalyticsMissingDataReason: GraphQL.PageThirdPartyDataMissingReason | null, readonly googleAnalyticsV4MissingDataReason: GraphQL.PageThirdPartyDataMissingReason | null, readonly googleAnalyticsData: { readonly __typename?: 'PageGoogleAnalyticsData', readonly averageTime: CK.DurationInSeconds, readonly bounceRate: CK.Percentage, readonly pageViews: number, readonly uniquePageViews: number, readonly dateRange: { readonly __typename?: 'DateRange', readonly since: CK.DateYMD, readonly until: CK.DateYMD }, readonly pageValue: { readonly __typename?: 'MoneyAmount', readonly currency: string, readonly value: number } } | null, readonly googleAnalyticsV4Data: { readonly __typename?: 'PageGoogleAnalyticsV4Data', readonly activeUsers: number, readonly averageEngagementTime: CK.DurationInSeconds, readonly engagementRate: CK.Percentage, readonly screenPageViews: number, readonly dateRange: { readonly __typename?: 'DateRange', readonly since: CK.DateYMD, readonly until: CK.DateYMD } } | null } | null, readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly googleAnalyticsIntegration: { readonly __typename?: 'WebsiteGoogleAnalyticsIntegration', readonly version: GraphQL.GoogleAnalyticsVersion } | null } | null };


export const GoogleAnalyticsBlockDocument = gql`
    query GoogleAnalyticsBlock($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    googleAnalyticsData {
      averageTime
      bounceRate
      dateRange {
        since
        until
      }
      pageValue {
        currency
        value
      }
      pageViews
      uniquePageViews
    }
    googleAnalyticsMissingDataReason
    googleAnalyticsV4Data {
      activeUsers
      averageEngagementTime
      dateRange {
        since
        until
      }
      engagementRate
      screenPageViews
    }
    googleAnalyticsV4MissingDataReason
  }
  website(id: $websiteId) {
    id
    googleAnalyticsIntegration {
      version
    }
  }
}
    `;

/**
 * __useGoogleAnalyticsBlockQuery__
 *
 * To run a query within a React component, call `useGoogleAnalyticsBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoogleAnalyticsBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoogleAnalyticsBlockQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useGoogleAnalyticsBlockQuery(baseOptions: Apollo.QueryHookOptions<GoogleAnalyticsBlockQuery, GoogleAnalyticsBlockQueryVariables> & ({ variables: GoogleAnalyticsBlockQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GoogleAnalyticsBlockQuery, GoogleAnalyticsBlockQueryVariables>(GoogleAnalyticsBlockDocument, options);
      }
export function useGoogleAnalyticsBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GoogleAnalyticsBlockQuery, GoogleAnalyticsBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GoogleAnalyticsBlockQuery, GoogleAnalyticsBlockQueryVariables>(GoogleAnalyticsBlockDocument, options);
        }
export function useGoogleAnalyticsBlockSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GoogleAnalyticsBlockQuery, GoogleAnalyticsBlockQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GoogleAnalyticsBlockQuery, GoogleAnalyticsBlockQueryVariables>(GoogleAnalyticsBlockDocument, options);
        }
export type GoogleAnalyticsBlockQueryHookResult = ReturnType<typeof useGoogleAnalyticsBlockQuery>;
export type GoogleAnalyticsBlockLazyQueryHookResult = ReturnType<typeof useGoogleAnalyticsBlockLazyQuery>;
export type GoogleAnalyticsBlockSuspenseQueryHookResult = ReturnType<typeof useGoogleAnalyticsBlockSuspenseQuery>;
export type GoogleAnalyticsBlockQueryResult = Apollo.QueryResult<GoogleAnalyticsBlockQuery, GoogleAnalyticsBlockQueryVariables>;