/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type LighthouseBlockQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type LighthouseBlockQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly pageType: GraphQL.PageType, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly lighthouseData: { readonly __typename?: 'PageLighthouseData', readonly cls: number, readonly clsRange: GraphQL.WebVitalRange, readonly fcp: number, readonly fcpRange: GraphQL.WebVitalRange, readonly lcp: number, readonly lcpRange: GraphQL.WebVitalRange, readonly performance: number, readonly performanceRange: GraphQL.WebVitalRange, readonly si: number, readonly siRange: GraphQL.WebVitalRange, readonly tbt: number, readonly tbtRange: GraphQL.WebVitalRange, readonly tti: number, readonly ttiRange: GraphQL.WebVitalRange } | null } | null } | null };


export const LighthouseBlockDocument = gql`
    query LighthouseBlock($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    pageType
    pageTypeData {
      lighthouseData {
        cls
        clsRange
        fcp
        fcpRange
        lcp
        lcpRange
        performance
        performanceRange
        si
        siRange
        tbt
        tbtRange
        tti
        ttiRange
      }
    }
  }
}
    `;

/**
 * __useLighthouseBlockQuery__
 *
 * To run a query within a React component, call `useLighthouseBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useLighthouseBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLighthouseBlockQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useLighthouseBlockQuery(baseOptions: Apollo.QueryHookOptions<LighthouseBlockQuery, LighthouseBlockQueryVariables> & ({ variables: LighthouseBlockQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LighthouseBlockQuery, LighthouseBlockQueryVariables>(LighthouseBlockDocument, options);
      }
export function useLighthouseBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LighthouseBlockQuery, LighthouseBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LighthouseBlockQuery, LighthouseBlockQueryVariables>(LighthouseBlockDocument, options);
        }
export function useLighthouseBlockSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LighthouseBlockQuery, LighthouseBlockQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<LighthouseBlockQuery, LighthouseBlockQueryVariables>(LighthouseBlockDocument, options);
        }
export type LighthouseBlockQueryHookResult = ReturnType<typeof useLighthouseBlockQuery>;
export type LighthouseBlockLazyQueryHookResult = ReturnType<typeof useLighthouseBlockLazyQuery>;
export type LighthouseBlockSuspenseQueryHookResult = ReturnType<typeof useLighthouseBlockSuspenseQuery>;
export type LighthouseBlockQueryResult = Apollo.QueryResult<LighthouseBlockQuery, LighthouseBlockQueryVariables>;