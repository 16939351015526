/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountCanSignupQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountCanSignupQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly canSignup: boolean } | null };


export const AccountCanSignupDocument = gql`
    query AccountCanSignup($accountId: AccountId!) {
  account(id: $accountId) {
    id
    canSignup
  }
}
    `;

/**
 * __useAccountCanSignupQuery__
 *
 * To run a query within a React component, call `useAccountCanSignupQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountCanSignupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountCanSignupQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountCanSignupQuery(baseOptions: Apollo.QueryHookOptions<AccountCanSignupQuery, AccountCanSignupQueryVariables> & ({ variables: AccountCanSignupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountCanSignupQuery, AccountCanSignupQueryVariables>(AccountCanSignupDocument, options);
      }
export function useAccountCanSignupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountCanSignupQuery, AccountCanSignupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountCanSignupQuery, AccountCanSignupQueryVariables>(AccountCanSignupDocument, options);
        }
export function useAccountCanSignupSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountCanSignupQuery, AccountCanSignupQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountCanSignupQuery, AccountCanSignupQueryVariables>(AccountCanSignupDocument, options);
        }
export type AccountCanSignupQueryHookResult = ReturnType<typeof useAccountCanSignupQuery>;
export type AccountCanSignupLazyQueryHookResult = ReturnType<typeof useAccountCanSignupLazyQuery>;
export type AccountCanSignupSuspenseQueryHookResult = ReturnType<typeof useAccountCanSignupSuspenseQuery>;
export type AccountCanSignupQueryResult = Apollo.QueryResult<AccountCanSignupQuery, AccountCanSignupQueryVariables>;