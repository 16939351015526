/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountProblemsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountProblemsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly unpaidPhase: GraphQL.AccountUnpaidPhase | null, readonly unpaidSince: CK.Timestamp | null, readonly unpaidSituation: GraphQL.AccountUnpaidSituation | null, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isCrawlingPaused: boolean, readonly problem: GraphQL.WebsiteProblem | null }> } | null };


export const AccountProblemsDocument = gql`
    query AccountProblems($accountId: AccountId!) {
  account(id: $accountId) {
    id
    unpaidPhase
    unpaidSince
    unpaidSituation
    websites {
      id
      isCrawlingPaused
      problem
    }
  }
}
    `;

/**
 * __useAccountProblemsQuery__
 *
 * To run a query within a React component, call `useAccountProblemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountProblemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountProblemsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountProblemsQuery(baseOptions: Apollo.QueryHookOptions<AccountProblemsQuery, AccountProblemsQueryVariables> & ({ variables: AccountProblemsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountProblemsQuery, AccountProblemsQueryVariables>(AccountProblemsDocument, options);
      }
export function useAccountProblemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountProblemsQuery, AccountProblemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountProblemsQuery, AccountProblemsQueryVariables>(AccountProblemsDocument, options);
        }
export function useAccountProblemsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountProblemsQuery, AccountProblemsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountProblemsQuery, AccountProblemsQueryVariables>(AccountProblemsDocument, options);
        }
export type AccountProblemsQueryHookResult = ReturnType<typeof useAccountProblemsQuery>;
export type AccountProblemsLazyQueryHookResult = ReturnType<typeof useAccountProblemsLazyQuery>;
export type AccountProblemsSuspenseQueryHookResult = ReturnType<typeof useAccountProblemsSuspenseQuery>;
export type AccountProblemsQueryResult = Apollo.QueryResult<AccountProblemsQuery, AccountProblemsQueryVariables>;