import {
	differenceInMilliseconds,
	formatDistanceToNowStrict,
	milliseconds,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import Copy from '~/components/logic/Copy';
import PlanName from '~/components/names/PlanName';



export enum RemainingTimeContext {
	Default = 'default',
	Trial = 'trial',
	PremiumTrial = 'premium-trial',
}



const messages = defineMessages({
	[RemainingTimeContext.Default]: {
		id: 'ui.remainingTime.default',
	},
	[RemainingTimeContext.Trial]: {
		id: 'ui.remainingTime.trial',
	},
	[RemainingTimeContext.PremiumTrial]: {
		id: 'ui.remainingTime.premiumTrial',
	},
	noMoreTime: {
		id: 'ui.remainingTime.noMoreTime',
	},
});



const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

function getUnit(delta: number): 'day' | 'hour' | 'minute' {
	if (delta < HOUR) {
		return 'minute';
	}

	if (delta < DAY) {
		return 'hour';
	}

	return 'day';
}



type Props = {
	context?: Exclude<RemainingTimeContext, RemainingTimeContext.PremiumTrial>,
	plan?: never,
	until: Date,
};

type PremiumTrialProps = {
	context: RemainingTimeContext.PremiumTrial,
	plan: GraphQL.AccountPlan | null,
	until: Date,
};

const RemainingTime: React.FC<Props | PremiumTrialProps> = (props) => {
	const {
		context = RemainingTimeContext.Default,
		plan = null,
		until,
	} = props;

	const [, forceRerender] = React.useState(0);

	const delta = differenceInMilliseconds(until, new Date());
	let remainingTime: React.ReactNode = null;

	if (delta > 0) {
		const unit = getUnit(delta);
		remainingTime = formatDistanceToNowStrict(
			props.until,
			{
				roundingMethod: unit === 'minute' ? 'ceil' : 'round',
				unit,
			},
		);
	} else {
		remainingTime = (
			<FormattedMessage {...messages.noMoreTime} />
		);
	}

	React.useEffect(
		() => {
			let timeout: any;

			function scheduleRerender() {
				const delta = differenceInMilliseconds(until, new Date());
				const unit = getUnit(delta);

				if (isNaN(delta) || delta <= 0) {
					return;
				}

				const delay = milliseconds({
					hours: unit === 'day' ? 1 : 0,
					minutes: unit === 'hour' ? 1 : 0,
					seconds: 1,
				});

				clearTimeout(timeout);
				timeout = setTimeout(() => {
					forceRerender(Date.now());
					scheduleRerender();
				}, delay);
			}

			scheduleRerender();

			return () => {
				clearTimeout(timeout);
			};
		},
		[
			until,
		],
	);

	return (
		<Copy
			{...messages[context]}
			values={{
				plan: plan !== null ? <PlanName plan={plan} /> : 'unknown',
				remainingTime,
			}}
		/>
	);
};



export default RemainingTime;
