/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type IncomingRedirectsQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  limit: GraphQL.Scalars['Int']['input'];
  offset: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type IncomingRedirectsQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly legacyId: number | null, readonly incomingRedirects: { readonly __typename?: 'PageIncomingRedirectsConnection', readonly totalCount: number, readonly edges: ReadonlyArray<{ readonly __typename?: 'PageIncomingRedirectsConnectionEdge', readonly cursor: any, readonly loadedAt: number, readonly node: { readonly __typename?: 'PageIncomingRedirectsConnectionNode', readonly id: CK.ID, readonly sourceId: CK.UrlId, readonly sourceLegacyId: number | null, readonly sourceStatusCode: number, readonly sourceUrl: string } } | null> } | null } | null };


export const IncomingRedirectsDocument = gql`
    query IncomingRedirects($legacyUrlId: Int!, $limit: Int!, $offset: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    incomingRedirects(limit: $limit, offset: $offset) {
      edges {
        cursor
        loadedAt @client
        node {
          id
          sourceId
          sourceLegacyId
          sourceStatusCode
          sourceUrl
        }
      }
      totalCount
    }
    legacyId
  }
}
    `;

/**
 * __useIncomingRedirectsQuery__
 *
 * To run a query within a React component, call `useIncomingRedirectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useIncomingRedirectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIncomingRedirectsQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useIncomingRedirectsQuery(baseOptions: Apollo.QueryHookOptions<IncomingRedirectsQuery, IncomingRedirectsQueryVariables> & ({ variables: IncomingRedirectsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IncomingRedirectsQuery, IncomingRedirectsQueryVariables>(IncomingRedirectsDocument, options);
      }
export function useIncomingRedirectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IncomingRedirectsQuery, IncomingRedirectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IncomingRedirectsQuery, IncomingRedirectsQueryVariables>(IncomingRedirectsDocument, options);
        }
export function useIncomingRedirectsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<IncomingRedirectsQuery, IncomingRedirectsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<IncomingRedirectsQuery, IncomingRedirectsQueryVariables>(IncomingRedirectsDocument, options);
        }
export type IncomingRedirectsQueryHookResult = ReturnType<typeof useIncomingRedirectsQuery>;
export type IncomingRedirectsLazyQueryHookResult = ReturnType<typeof useIncomingRedirectsLazyQuery>;
export type IncomingRedirectsSuspenseQueryHookResult = ReturnType<typeof useIncomingRedirectsSuspenseQuery>;
export type IncomingRedirectsQueryResult = Apollo.QueryResult<IncomingRedirectsQuery, IncomingRedirectsQueryVariables>;