import {
	Map,
} from 'immutable';



export const EmptyTeamBilling = new Map({
	details: new Map(),
	invoices: null,
});

const EmptyTeamsBilling = {
	get: () => EmptyTeamBilling,
};

export const teamsSelector = (state) => state.get('teams').get('basics');

export const teamsBillingSelector = (state) => state.get('teams').get('billing').size ? state.get('teams').get('billing') : EmptyTeamsBilling;
