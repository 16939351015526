import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Copy from '~/components/logic/Copy';
import DestructConfirmationModal from '~/components/app/DestructConfirmationModal';
import Gravatar from '~/components/patterns/users/Gravatar';
import LabeledValuesBox from '~/components/patterns/structuredValues/labeledValues/LabeledValuesBox';
import SmallImageLabel from '~/components/patterns/images/SmallImageLabel';

import useAccountDisplayName from '~/hooks/useAccountDisplayName';
import useNavigation from '~/hooks/useNavigation';

import {
	useRemoveTeamMemberConfirmationQuery,
	useRemoveUserMutation,
} from './RemoveTeamMemberConfirmationModal.gql';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	confirmButton: {
		id: 'ui.removeTeamMemberConfirmation.confirmButton',
	},
	errorAlreadyRemoved: {
		id: 'ui.removeTeamMemberConfirmation.errorAlreadyRemoved',
	},
	errorLastTeamMember: {
		id: 'ui.removeTeamMemberConfirmation.errorLastTeamMember',
	},
	labelEmail: {
		id: 'ui.removeTeamMemberConfirmation.labelEmail',
	},
	labelFromAccount: {
		id: 'ui.removeTeamMemberConfirmation.labelFromAccount',
	},
	labelName: {
		id: 'ui.removeTeamMemberConfirmation.labelName',
	},
	labelTeamMember: {
		id: 'ui.removeTeamMemberConfirmation.labelTeamMember',
	},
	title: {
		id: 'ui.removeTeamMemberConfirmation.title',
	},
	verb: {
		id: 'ui.removeTeamMemberConfirmation.verb',
	},
	warnPermanent: {
		id: 'ui.removeTeamMemberConfirmation.warnPermanent',
	},
});



type Props = {
	accountId: CK.AccountId,
	userId: string,
};

const RemoveTeamMemberConfirmationModal: React.FC<Props> = (props) => {
	const {
		accountId,
		userId,
	} = props;

	const accountDisplayName = useAccountDisplayName(accountId);
	const navigation = useNavigation();

	const [removeUser] = useRemoveUserMutation();

	const {
		data,
		loading,
	} = useRemoveTeamMemberConfirmationQuery({
		returnPartialData: true,
		variables: {
			userId,
		},
	});

	const {
		email = '...',
		initials,
		signature,
	} = data?.user || {};

	const handleDeletion = React.useCallback(
		async () => {
			try {
				await removeUser({
					variables: {
						accountId,
						email,
					},
				});
			} catch (error) {
				throw FormError.fromApolloError(error);
			}

			navigation.navigate({
				routeName: 'account.members',
				routeParams: {
					accountId,
				},
			});
		},
		[
			accountId,
			email,
			navigation,
			removeUser,
		],
	);

	const labeledValues: Array<{
		label: React.ReactNode,
		value: React.ReactNode,
	}> = [];

	if (!loading) {
		const hasName = signature !== email;
		const userIdentifier = (
			<SmallImageLabel
				image={(
					<Gravatar
						email={email}
						placeholder={initials}
					/>
				)}
				label={signature}
			/>
		);

		if (hasName) {
			labeledValues.push({
				label: <FormattedMessage {...messages.labelName} />,
				value: userIdentifier,
			});

			labeledValues.push({
				label: <FormattedMessage {...messages.labelEmail} />,
				value: email,
			});
		} else {
			labeledValues.push({
				label: <FormattedMessage {...messages.labelTeamMember} />,
				value: userIdentifier,
			});
		}
	}

	labeledValues.push({
		label: <FormattedMessage {...messages.labelFromAccount} />,
		value: accountDisplayName,
	});

	return (
		<DestructConfirmationModal
			confirmButton={(
				<FormattedMessage {...messages.confirmButton} />
			)}
			confirmationPhrase={email}
			globalFormErrors={{
				'cannot_delete_last_team_member': (
					<FormattedMessage
						{...messages.errorLastTeamMember}
						values={{
							signature,
						}}
					/>
				),
				'not_member_of_team': (
					<FormattedMessage
						{...messages.errorAlreadyRemoved}
						values={{
							signature,
						}}
					/>
				),
			}}
			loading={loading}
			onConfirmation={handleDeletion}
			titleAction={(
				<FormattedMessage {...messages.verb} />
			)}
			titleObject={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Copy {...messages.warnPermanent} />

			<LabeledValuesBox
				items={labeledValues}
			/>
		</DestructConfirmationModal>
	);
};



export default RemoveTeamMemberConfirmationModal;
