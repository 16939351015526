import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useSelector,
} from 'react-redux';

import ExportHeaderButton from '~/components/app/ExportHeaderButton';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderLayout from '~/components/logic/pagesOverview/HeaderLayout';
import HeaderTitleWithNumericValue, {
	SIZE_SMALL as TITLE_SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithNumericValue';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import ManageColumnsLink from '~/components/logic/columnsConfigurator/ManageColumnsLink';
import TrackedChangesActiveFilterBar from '~/components/app/TrackedChangesActiveFilterBar';
import TrackedChangesCalendar from '~/components/logic/pagesOverview/TrackedChangesCalendar';

import useDatatableContext from '~/hooks/useDatatableContext';
import useExportTrackedChangesOnPages from '~/hooks/useExportTrackedChangesOnPages';

import {
	totalSelector,
} from '~/state/historicalChanges/selectors';



const messages = defineMessages({
	exportButton: {
		id: 'ui.exports.type.general.button.openDialog.label',
	},
	title: {
		id: 'ui.contentOverview.title',
	},
});



const ChangeTrackingHeader: React.FC = () => {
	const datatableContext = useDatatableContext();
	const exportTrackedChangesOnPages = useExportTrackedChangesOnPages();
	const totalCount = useSelector(totalSelector);

	return (
		<HeaderLayout
			filter={(
				<TrackedChangesActiveFilterBar
					onFilterClickCallback={(filterName) => {
						datatableContext.scrollToColumn?.(filterName);
					}}
				/>
			)}
			title={(
				<HeaderTitleLayout>
					<HeaderTitleWithNumericValue
						size={TITLE_SIZE_SMALL}
						title={(
							<FormattedMessage {...messages.title} />
						)}
						value={totalCount}
					/>
					<ManageColumnsLink />
				</HeaderTitleLayout>
			)}
		>
			<HeaderButtonsLayout>
				<TrackedChangesCalendar />

				<ExportHeaderButton
					createExport={exportTrackedChangesOnPages}
					linkLabel={(
						<FormattedMessage {...messages.exportButton} />
					)}
				/>
			</HeaderButtonsLayout>
		</HeaderLayout>
	);
};



export default React.memo(ChangeTrackingHeader);
