import {
	IssueCategoryName,
	type IssueName,
	PageIssue,
	PlatformIssue,
} from '~/model/issuesNew';



const MAPPING_IN = {
	analytics: {
		analytics_missing: PageIssue.AnalyticsAnalyticsMissing,
		visual_analytics_missing: PageIssue.AnalyticsVisualAnalyticsMissing,
	},
	contentking_lighthouse_web_vitals: {
		cumulative_layout_shift: PageIssue.LighthouseCls,
		first_contentful_paint: PageIssue.LighthouseFcp,
		largest_contentful_paint: PageIssue.LighthouseLcp,
		performance: PageIssue.LighthousePerformance,
		speed_index: PageIssue.LighthouseSi,
		total_blocking_time: PageIssue.LighthouseTbt,
		time_to_interactive: PageIssue.LighthouseTti,
	},
	domain: {
		hostname_noncanonical_present: PlatformIssue.DomainHostnameNoncanonicalPresent,
		https_available: PlatformIssue.DomainHttpsAvailable,
		https_certificate_invalid: PlatformIssue.DomainHttpsCertificateInvalid,
		https_noncanonical_present: PlatformIssue.DomainHttpsNoncanonicalPresent,
		soft_404s_present: PlatformIssue.DomainSoft404sPresent,
	},
	meta_description: {
		duplicate: PageIssue.MetaDescriptionDuplicate,
		incorrect_length: PageIssue.MetaDescriptionIncorrectLength,
		missing: PageIssue.MetaDescriptionMissing,
		too_many: PageIssue.MetaDescriptionTooMany,
	},
	title: {
		duplicate: PageIssue.TitleDuplicate,
		missing: PageIssue.TitleMissing,
		incorrect_length: PageIssue.TitleIncorrectLength,
		too_many: PageIssue.TitleTooMany,
	},
	twitter_cards: {
		description_incorrect_length: PageIssue.TwitterCardsDescriptionIncorrectLength,
		description_missing: PageIssue.TwitterCardsDescriptionMissing,
		image_missing: PageIssue.TwitterCardsImageMissing,
		site_missing: PageIssue.TwitterCardsSiteMissing,
		title_incorrect_length: PageIssue.TwitterCardsTitleIncorrectLength,
		title_missing: PageIssue.TwitterCardsTitleMissing,
		type_invalid: PageIssue.TwitterCardsTypeInvalid,
		type_missing: PageIssue.TwitterCardsTypeMissing,
	},
	open_graph: {
		description_incorrect_length: PageIssue.OpenGraphDescriptionIncorrectLength,
		description_missing: PageIssue.OpenGraphDescriptionMissing,
		image_missing: PageIssue.OpenGraphImageMissing,
		title_incorrect_length: PageIssue.OpenGraphTitleIncorrectLength,
		title_missing: PageIssue.OpenGraphTitleMissing,
		url_missing: PageIssue.OpenGraphUrlMissing,
	},
	h1: {
		duplicate: PageIssue.H1Duplicate,
		levels_skipped: PageIssue.H1LevelsSkipped,
		missing: PageIssue.H1Missing,
		incorrect_length: PageIssue.H1IncorrectLength,
		too_many: PageIssue.H1TooMany,
	},
	hreflang: {
		conflicting_targets: PageIssue.HreflangConflictingTargets,
		invalid_target: PageIssue.HreflangInvalidTarget,
		invalid_value: PageIssue.HreflangInvalidValue,
		missing_self_reference: PageIssue.HreflangMissingSelfReference,
		missing_specific_audience: PageIssue.HreflangMissingSpecificAudience,
		missing_x_default: PageIssue.HreflangMissingXDefault,
	},
	links: {
		broken: PageIssue.LinksBroken,
		redirected: PageIssue.LinksRedirected,
		to_canonicalized: PageIssue.LinksToCanonicalized,
	},
	images: {
		alt_attribute: PageIssue.ImagesAltAttribute,
		mixed_transport: PageIssue.ImagesMixedTransport,
	},
	xml_sitemap: {
		filesize_limit: PlatformIssue.XmlSitemapFilesizeLimit,
		inaccessible: PlatformIssue.XmlSitemapInaccessible,
		incorrectly_missing: PageIssue.XmlSitemapIncorrectlyMissing,
		incorrectly_present: PageIssue.XmlSitemapIncorrectlyPresent,
		invalid_contents: PlatformIssue.XmlSitemapInvalidContents,
		invalid_structure: PlatformIssue.XmlSitemapInvalidStructure,
		invalid_structure_all: PlatformIssue.XmlSitemapInvalidStructureAll,
		missing: PlatformIssue.XmlSitemapMissing,
		orphaned: PlatformIssue.XmlSitemapOrphaned,
		urlcount_limit: PlatformIssue.XmlSitemapUrlcountLimit,
	},
	canonical_links: {
		incorrectly_canonicalized: PageIssue.CanonicalLinkIncorrectlyCanonicalized,
		missing: PageIssue.CanonicalLinkMissing,
		points_to_unindexable: PageIssue.CanonicalLinkPointsToUnindexable,
		too_many: PageIssue.CanonicalLinkTooMany,
	},
	robot_directives: {
		conflicting: PageIssue.RobotDirectivesConflicting,
		invalid: PageIssue.RobotDirectivesInvalid,
		unsupported: PageIssue.RobotDirectivesUnsupported,
	},
	robots_txt: {
		assets_disallowed: PlatformIssue.RobotsTxtAssetsDisallowed,
		crawldelay_present: PlatformIssue.RobotsTxtCrawldelayPresent,
		inaccessible: PlatformIssue.RobotsTxtInaccessible,
		invalid_directive: PlatformIssue.RobotsTxtInvalidDirective,
		invalid_syntax: PlatformIssue.RobotsTxtInvalidSyntax,
		noncanonical_present: PlatformIssue.RobotsTxtNoncanonicalPresent,
		xmlsitemap_reference_inaccessible: PlatformIssue.RobotsTxtXmlsitemapReferenceInaccessible,
		xmlsitemap_reference_missing: PlatformIssue.RobotsTxtXmlsitemapReferenceMissing,
		xmlsitemap_reference_relative: PlatformIssue.RobotsTxtXmlsitemapReferenceRelative,
	},
	schema_org: {
		errors: PageIssue.SchemaOrgErrors,
		invalid_json: PageIssue.SchemaOrgInvalidJson,
	},
	web_vitals_origin_summary: {
		core_web_vitals: PlatformIssue.WebVitalsOriginSummaryCoreWebVitals,
		cumulative_layout_shift: PlatformIssue.WebVitalsOriginSummaryCumulativeLayoutShift,
		first_contentful_paint: PlatformIssue.WebVitalsOriginSummaryFirstContentfulPaint,
		interaction_to_next_paint: PlatformIssue.WebVitalsOriginSummaryInteractionToNextPaint,
		largest_contentful_paint: PlatformIssue.WebVitalsOriginSummaryLargestContentfulPaint,
	},
};

const MAPPING_OUT = {
	[PageIssue.AnalyticsAnalyticsMissing]: 'analytics_missing',
	[PageIssue.AnalyticsVisualAnalyticsMissing]: 'visual_analytics_missing',
	[PageIssue.CanonicalLinkIncorrectlyCanonicalized]: 'incorrectly_canonicalized',
	[PageIssue.CanonicalLinkMissing]: 'missing',
	[PageIssue.CanonicalLinkPointsToUnindexable]: 'points_to_unindexable',
	[PageIssue.CanonicalLinkTooMany]: 'too_many',
	[PlatformIssue.DomainHostnameNoncanonicalPresent]: 'hostname_noncanonical_present',
	[PlatformIssue.DomainHttpsAvailable]: 'https_available',
	[PlatformIssue.DomainHttpsCertificateInvalid]: 'https_certificate_invalid',
	[PlatformIssue.DomainHttpsNoncanonicalPresent]: 'https_noncanonical_present',
	[PlatformIssue.DomainSoft404sPresent]: 'soft_404s_present',
	[PageIssue.H1Duplicate]: 'duplicate',
	[PageIssue.H1IncorrectLength]: 'incorrect_length',
	[PageIssue.H1LevelsSkipped]: 'levels_skipped',
	[PageIssue.H1Missing]: 'missing',
	[PageIssue.H1TooMany]: 'too_many',
	[PageIssue.HreflangConflictingTargets]: 'conflicting_targets',
	[PageIssue.HreflangInvalidTarget]: 'invalid_target',
	[PageIssue.HreflangInvalidValue]: 'invalid_value',
	[PageIssue.HreflangMissingSelfReference]: 'missing_self_reference',
	[PageIssue.HreflangMissingSpecificAudience]: 'missing_specific_audience',
	[PageIssue.HreflangMissingXDefault]: 'missing_x_default',
	[PageIssue.ImagesAltAttribute]: 'alt_attribute',
	[PageIssue.ImagesMixedTransport]: 'mixed_transport',
	[PageIssue.LighthouseCls]: 'cumulative_layout_shift',
	[PageIssue.LighthouseFcp]: 'first_contentful_paint',
	[PageIssue.LighthouseLcp]: 'largest_contentful_paint',
	[PageIssue.LighthousePerformance]: 'performance',
	[PageIssue.LighthouseSi]: 'speed_index',
	[PageIssue.LighthouseTbt]: 'total_blocking_time',
	[PageIssue.LighthouseTti]: 'time_to_interactive',
	[PageIssue.LinksBroken]: 'broken',
	[PageIssue.LinksRedirected]: 'redirected',
	[PageIssue.LinksToCanonicalized]: 'to_canonicalized',
	[PageIssue.MetaDescriptionDuplicate]: 'duplicate',
	[PageIssue.MetaDescriptionIncorrectLength]: 'incorrect_length',
	[PageIssue.MetaDescriptionMissing]: 'missing',
	[PageIssue.MetaDescriptionTooMany]: 'too_many',
	[PageIssue.OpenGraphDescriptionIncorrectLength]: 'description_incorrect_length',
	[PageIssue.OpenGraphDescriptionMissing]: 'description_missing',
	[PageIssue.OpenGraphImageMissing]: 'image_missing',
	[PageIssue.OpenGraphTitleIncorrectLength]: 'title_incorrect_length',
	[PageIssue.OpenGraphTitleMissing]: 'title_missing',
	[PageIssue.OpenGraphUrlMissing]: 'url_missing',
	[PageIssue.RobotDirectivesConflicting]: 'conflicting',
	[PageIssue.RobotDirectivesInvalid]: 'invalid',
	[PageIssue.RobotDirectivesUnsupported]: 'unsupported',
	[PageIssue.SchemaOrgErrors]: 'errors',
	[PageIssue.SchemaOrgInvalidJson]: 'invalid_json',
	[PageIssue.TitleDuplicate]: 'duplicate',
	[PageIssue.TitleIncorrectLength]: 'incorrect_length',
	[PageIssue.TitleMissing]: 'missing',
	[PageIssue.TitleTooMany]: 'too_many',
	[PageIssue.TwitterCardsDescriptionIncorrectLength]: 'description_incorrect_length',
	[PageIssue.TwitterCardsDescriptionMissing]: 'description_missing',
	[PageIssue.TwitterCardsImageMissing]: 'image_missing',
	[PageIssue.TwitterCardsSiteMissing]: 'site_missing',
	[PageIssue.TwitterCardsTitleIncorrectLength]: 'title_incorrect_length',
	[PageIssue.TwitterCardsTitleMissing]: 'title_missing',
	[PageIssue.TwitterCardsTypeInvalid]: 'type_invalid',
	[PageIssue.TwitterCardsTypeMissing]: 'type_missing',
	[PlatformIssue.XmlSitemapFilesizeLimit]: 'filesize_limit',
	[PlatformIssue.XmlSitemapInaccessible]: 'inaccessible',
	[PageIssue.XmlSitemapIncorrectlyMissing]: 'incorrectly_missing',
	[PageIssue.XmlSitemapIncorrectlyPresent]: 'incorrectly_present',
	[PlatformIssue.XmlSitemapInvalidContents]: 'invalid_contents',
	[PlatformIssue.XmlSitemapInvalidStructure]: 'invalid_structure',
	[PlatformIssue.XmlSitemapInvalidStructureAll]: 'invalid_structure_all',
	[PlatformIssue.XmlSitemapMissing]: 'missing',
	[PlatformIssue.XmlSitemapOrphaned]: 'orphaned',
	[PlatformIssue.XmlSitemapUrlcountLimit]: 'urlcount_limit',
	[PlatformIssue.RobotsTxtAssetsDisallowed]: 'assets_disallowed',
	[PlatformIssue.RobotsTxtCrawldelayPresent]: 'crawldelay_present',
	[PlatformIssue.RobotsTxtInaccessible]: 'inaccessible',
	[PlatformIssue.RobotsTxtInvalidDirective]: 'invalid_directive',
	[PlatformIssue.RobotsTxtInvalidSyntax]: 'invalid_syntax',
	[PlatformIssue.RobotsTxtNoncanonicalPresent]: 'noncanonical_present',
	[PlatformIssue.RobotsTxtXmlsitemapReferenceInaccessible]: 'xmlsitemap_reference_inaccessible',
	[PlatformIssue.RobotsTxtXmlsitemapReferenceMissing]: 'xmlsitemap_reference_missing',
	[PlatformIssue.RobotsTxtXmlsitemapReferenceRelative]: 'xmlsitemap_reference_relative',
	[PlatformIssue.WebVitalsOriginSummaryCoreWebVitals]: 'core_web_vitals',
	[PlatformIssue.WebVitalsOriginSummaryCumulativeLayoutShift]: 'cumulative_layout_shift',
	[PlatformIssue.WebVitalsOriginSummaryFirstContentfulPaint]: 'first_contentful_paint',
	[PlatformIssue.WebVitalsOriginSummaryInteractionToNextPaint]: 'interaction_to_next_paint',
	[PlatformIssue.WebVitalsOriginSummaryLargestContentfulPaint]: 'largest_contentful_paint',
};



const CATEGORY_MAPPING_IN = {
	analytics: IssueCategoryName.Analytics,
	canonical_links: IssueCategoryName.CanonicalLink,
	contentking_lighthouse_web_vitals: IssueCategoryName.Lighthouse,
	domain: IssueCategoryName.Domain,
	h1: IssueCategoryName.H1,
	hreflang: IssueCategoryName.Hreflang,
	images: IssueCategoryName.Images,
	links: IssueCategoryName.Links,
	meta_description: IssueCategoryName.MetaDescription,
	open_graph: IssueCategoryName.OpenGraph,
	robot_directives: IssueCategoryName.RobotDirectives,
	robots_txt: IssueCategoryName.RobotsTxt,
	schema_org: IssueCategoryName.SchemaOrg,
	title: IssueCategoryName.Title,
	twitter_cards: IssueCategoryName.TwitterCards,
	web_vitals_origin_summary: IssueCategoryName.WebVitalsOriginSummary,
	xml_sitemap: IssueCategoryName.XmlSitemap,
};

const CATEGORY_MAPPING_OUT = {
	[IssueCategoryName.Analytics]: 'analytics',
	[IssueCategoryName.CanonicalLink]: 'canonical_links',
	[IssueCategoryName.Domain]: 'domain',
	[IssueCategoryName.H1]: 'h1',
	[IssueCategoryName.Hreflang]: 'hreflang',
	[IssueCategoryName.Images]: 'images',
	[IssueCategoryName.Lighthouse]: 'contentking_lighthouse_web_vitals',
	[IssueCategoryName.Links]: 'links',
	[IssueCategoryName.MetaDescription]: 'meta_description',
	[IssueCategoryName.OpenGraph]: 'open_graph',
	[IssueCategoryName.RobotDirectives]: 'robot_directives',
	[IssueCategoryName.RobotsTxt]: 'robots_txt',
	[IssueCategoryName.SchemaOrg]: 'schema_org',
	[IssueCategoryName.Title]: 'title',
	[IssueCategoryName.TwitterCards]: 'twitter_cards',
	[IssueCategoryName.WebVitalsOriginSummary]: 'web_vitals_origin_summary',
	[IssueCategoryName.XmlSitemap]: 'xml_sitemap',
};



export function getAbsoluteCategoryType(category: string): IssueCategoryName | null {
	if (CATEGORY_MAPPING_IN[category] !== undefined) {
		return CATEGORY_MAPPING_IN[category];
	}

	return null;
}



export function getAbsoluteIssueType(category: string, issueType: string): IssueName | null {
	if (MAPPING_IN[category] !== undefined && MAPPING_IN[category][issueType] !== undefined) {
		return MAPPING_IN[category][issueType];
	}

	return null;
}



export function getCategoryUrlIdentifier(absoluteCategory: IssueCategoryName): string {
	return CATEGORY_MAPPING_OUT[absoluteCategory];
}



export function getUrlIdentifier(absoluteIssueType: IssueName): string {
	return MAPPING_OUT[absoluteIssueType];
}



export function redirectOldCategory(router, name, paramName) {
	router.canActivate(name, () => (toState, fromState, done) => {
		const value = toState.params[paramName];

		const oldCategories = {
			canonical_link: 'canonical_links',
			content_headings: 'h1',
			meta_information: 'title',
			qualitative_analytics: 'analytics',
			quantitative_analytics: 'analytics',
		};

		const isDeprecated = oldCategories[value];

		if (isDeprecated) {
			toState.params[paramName] = isDeprecated;
			done({
				redirect: {
					name: toState.name,
					params: toState.params,
				},
			});
		} else {
			done();
		}
	});
}



export function redirectOldIssue(router, name, categoryParamName, paramName) {
	router.canActivate(name, () => (toState, fromState, done) => {
		const category = toState.params[categoryParamName];
		const value = toState.params[paramName];

		const oldCategories = {
			canonical_link: 'canonical_links',
			content_headings: 'h1',
			meta_information: 'title',
			visual_analytics: 'analytics',
		};

		let isCategoryDeprecated = oldCategories[category];

		const oldIssues = {
			analytics: {
				missing: 'analytics_missing',
				outdated: 'missing',
			},
			h1: {
				h1_duplicate: 'duplicate',
				h1_incorrect_length: 'incorrect_length',
				h1_missing: 'missing',
				h1_too_many: 'too_many',
			},
			title: {
				meta_description_duplicate: ['meta_description', 'duplicate'],
				meta_description_incorrect_length: ['meta_description', 'incorrect_length'],
				meta_description_missing: ['meta_description', 'missing'],
				meta_description_too_many: ['meta_description', 'too_many'],
				title_duplicate: 'duplicate',
				title_incorrect_length: 'incorrect_length',
				title_missing: 'missing',
				title_too_many: 'too_many',
			},
			visual_analytics: {
				missing: 'visual_analytics_missing',
			},
			web_vitals_origin_summary: {
				first_input_delay: 'interaction_to_next_paint',
			},
		};

		let isIssueDeprecated = oldIssues[toState.params[categoryParamName]]
			? oldIssues[toState.params[categoryParamName]][value]
			: false;

		if (typeof isIssueDeprecated === 'object') {
			isCategoryDeprecated = isIssueDeprecated[0];
			isIssueDeprecated = isIssueDeprecated[1];
		}

		if (isCategoryDeprecated) {
			toState.params[categoryParamName] = isCategoryDeprecated;
		}

		if (isIssueDeprecated) {
			toState.params[paramName] = isIssueDeprecated;
		}

		if (isCategoryDeprecated || isIssueDeprecated) {
			done({
				redirect: {
					name: toState.name,
					params: toState.params,
				},
			});
		} else {
			done();
		}
	});
}
