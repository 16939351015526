import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import Importer from './ExclusionsModal/Importer';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import Manager from './ExclusionsModal/Manager';
import MultiStepModal, {
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RichText from '~/components/patterns/typography/RichText';

import {
	useUrlExclusionListModalQuery,
} from './ExclusionsModal.gql';

import useWebsiteId from '~/hooks/useWebsiteId';



const messages = defineMessages({
	importStepBreadcrumbs: {
		id: 'ui.modal.urlExclusionList.importStep.breadcrumbs',
	},
	importStepSidebar: {
		id: 'ui.modal.urlExclusionList.importStep.sidebar',
	},
	importStepTitle: {
		id: 'ui.websites.exclusionsModal.importer.title',
	},
	manageStepBreadcrumbs: {
		id: 'ui.modal.urlExclusionList.manageStep.breadcrumbs',
	},
	manageStepSidebar: {
		id: 'ui.modal.urlExclusionList.manageStep.sidebar',
	},
	manageStepTitle: {
		id: 'ui.websites.exclusionsModal.manager.title',
	},
});



const ExclusionsModal = (props) => {
	const {
		closeCallback,
	} = props;

	const websiteId = useWebsiteId();

	const { data } = useUrlExclusionListModalQuery({
		variables: {
			websiteId,
		},
	});

	const suggestedUrlExclusionListRules = data?.website?.suggestedUrlExclusionListRules ?? null;
	const urlExclusionListRules = data?.website?.urlExclusionList.rules ?? null;

	const multiStepModalRef = React.useRef<MultiStepModalRef>();

	const [selectedRules, setSelectedRules] = React.useState<ReadonlyArray<string> | null>(null);

	const goToStepWithRules = React.useCallback(
		(selectedRules: ReadonlyArray<string>) => {
			if (selectedRules.length > 0) {
				setSelectedRules(selectedRules);
			}

			multiStepModalRef.current?.goToNextStep();
		},
		[
			multiStepModalRef,
			setSelectedRules,
		],
	);

	const isLoaded = (
		suggestedUrlExclusionListRules !== null
		&& urlExclusionListRules !== null
	);

	const allowImport = (
		isLoaded
		&& suggestedUrlExclusionListRules.length > 0
		&& urlExclusionListRules.length === 0
	);

	return (
		<MultiStepModal
			isContentDisabled={isLoaded === false}
			key={allowImport ? 'withImport' : 'withoutImport'}
			preloader={isLoaded === false && (
				<LoadingDots isStretched={true} />
			)}
			ref={multiStepModalRef}
		>
			{allowImport && (
				<MultiStepModalStep
					breadcrumb={(
						<FormattedMessage {...messages.importStepBreadcrumbs} />
					)}
					name="importStep"
					sidebar={(
						<RichText>
							<Copy
								{...messages.importStepSidebar}
								values={{
									linkArticle: linkExternal('https://www.contentkingapp.com/support/removing-urls-from-monitoring/#url-exclusion-list'),
								}}
							/>
						</RichText>
					)}
					title={(
						<FormattedMessage {...messages.importStepTitle} />
					)}
				>
					<Importer
						onSubmitCallback={goToStepWithRules}
						robotsRules={suggestedUrlExclusionListRules}
					/>
				</MultiStepModalStep>
			)}

			<MultiStepModalStep
				breadcrumb={(
					<FormattedMessage {...messages.manageStepBreadcrumbs} />
				)}
				name="managerStep"
				sidebar={(
					<RichText>
						<Copy
							{...messages.manageStepSidebar}
							values={{
								linkArticle: linkExternal('https://www.contentkingapp.com/support/removing-urls-from-monitoring/#url-exclusion-list'),
							}}
						/>
					</RichText>
				)}
				title={(
					<FormattedMessage {...messages.manageStepTitle} />
				)}
			>
				<Manager
					closeModal={closeCallback}
					importedUrlExclusionListRules={selectedRules}
					urlExclusionListRules={urlExclusionListRules}
					websiteId={websiteId}
				/>
			</MultiStepModalStep>
		</MultiStepModal>

	);
};



export default ExclusionsModal;
