/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CumulativeLayoutShiftWidgetQueryVariables = GraphQL.Exact<{
  date: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type CumulativeLayoutShiftWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly webVitalsOriginSummaryData: { readonly __typename?: 'WebsiteDashboardWebVitalsOriginSummaryData', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: { readonly __typename?: 'WebVitalsOriginSummaryData', readonly cumulativeLayoutShiftScore: { readonly __typename?: 'WebVitalsOriginMetric', readonly maximumFast: number, readonly maximumGood: number, readonly percentageFast: CK.Percentage, readonly percentageGood: CK.Percentage, readonly percentageSlow: CK.Percentage, readonly percentile: number, readonly verdict: GraphQL.WebVitalVerdict } | null } | null } } };


export const CumulativeLayoutShiftWidgetDocument = gql`
    query CumulativeLayoutShiftWidget($date: DateYMD!, $scope: String!, $websiteId: WebsiteId!) {
  dashboardData {
    webVitalsOriginSummaryData(date: $date, scope: $scope, websiteId: $websiteId) {
      data {
        cumulativeLayoutShiftScore {
          maximumFast
          maximumGood
          percentageFast
          percentageGood
          percentageSlow
          percentile
          verdict
        }
      }
      id
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __useCumulativeLayoutShiftWidgetQuery__
 *
 * To run a query within a React component, call `useCumulativeLayoutShiftWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useCumulativeLayoutShiftWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCumulativeLayoutShiftWidgetQuery({
 *   variables: {
 *      date: // value for 'date'
 *      scope: // value for 'scope'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useCumulativeLayoutShiftWidgetQuery(baseOptions: Apollo.QueryHookOptions<CumulativeLayoutShiftWidgetQuery, CumulativeLayoutShiftWidgetQueryVariables> & ({ variables: CumulativeLayoutShiftWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CumulativeLayoutShiftWidgetQuery, CumulativeLayoutShiftWidgetQueryVariables>(CumulativeLayoutShiftWidgetDocument, options);
      }
export function useCumulativeLayoutShiftWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CumulativeLayoutShiftWidgetQuery, CumulativeLayoutShiftWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CumulativeLayoutShiftWidgetQuery, CumulativeLayoutShiftWidgetQueryVariables>(CumulativeLayoutShiftWidgetDocument, options);
        }
export function useCumulativeLayoutShiftWidgetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CumulativeLayoutShiftWidgetQuery, CumulativeLayoutShiftWidgetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CumulativeLayoutShiftWidgetQuery, CumulativeLayoutShiftWidgetQueryVariables>(CumulativeLayoutShiftWidgetDocument, options);
        }
export type CumulativeLayoutShiftWidgetQueryHookResult = ReturnType<typeof useCumulativeLayoutShiftWidgetQuery>;
export type CumulativeLayoutShiftWidgetLazyQueryHookResult = ReturnType<typeof useCumulativeLayoutShiftWidgetLazyQuery>;
export type CumulativeLayoutShiftWidgetSuspenseQueryHookResult = ReturnType<typeof useCumulativeLayoutShiftWidgetSuspenseQuery>;
export type CumulativeLayoutShiftWidgetQueryResult = Apollo.QueryResult<CumulativeLayoutShiftWidgetQuery, CumulativeLayoutShiftWidgetQueryVariables>;