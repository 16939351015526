import React from 'react';
import {
	useSelector,
} from 'react-redux';
import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';

import ActivePanelProvider from '~/components/providers/ActivePanelProvider';
import CloseByEscape from './CloseByEscape';
import CarouselDetailPanel from './CarouselDetailPanel';
import DetailPanel from '~/components/patterns/modals/DetailPanel';
import FullscreenPanel from './FullscreenPanel';
import MainErrorBoundary from '../../logic/MainErrorBoundary';
import ModalPanel, {
	ALIGNMENT_CENTER as MODAL_ALIGNMENT_CENTER,
	SIZE_MEDIUM as MODAL_SIZE_MEDIUM,
} from './ModalPanel';
import PageContent from '~/components/patterns/layout/PageContent';
import Panel from './Panel';

import useViewportType from '~/hooks/useViewportType';

import {
	ScrollContainerProvider,
} from '~/hooks/useScrollContainer';

import {
	navigate,
} from '~/routing/router';

import {
	contentCurrentSelector,
	contentStackSelector,
} from '~/state/ui/content/selectors';



const DesktopPanelStack = (props) => {
	const {
		animations,
	} = props;

	const current = useSelector(contentCurrentSelector);
	const stack = useSelector(contentStackSelector);
	const viewportType = useViewportType();

	const mainContentSetup = stack.slice(0, 1)[0];
	if (!mainContentSetup) {
		return null;
	}

	let panels = stack.slice(1);

	let activePanelFound = false;
	let activePanelCloseCallback;
	let isActivePanelCloseableViaOverlay = true;
	const somePanelIsActive = panels.some((panelSetup) => {
		return current === panelSetup.content;
	});
	const numberOfNormalPanels = panels.filter((panelSetup) => {
		return panelSetup.type === 'normal';
	}).length;

	let normalPanelsCounter = 0;

	panels = panels.map((panelSetup, i) => {
		const currentPanelIsActive = current === panelSetup.content;
		let isOverlaid = false;

		const closeCallback = () => {
			navigate(panelSetup.closeRoute.name, panelSetup.closeRoute.params || {});
		};

		if (!activePanelFound) {
			if (currentPanelIsActive) {
				activePanelFound = true;
				activePanelCloseCallback = closeCallback;
				isActivePanelCloseableViaOverlay = panelSetup.type === 'modal' && panelSetup.canBeClosedViaOverlay !== undefined
					? panelSetup.canBeClosedViaOverlay
					: true;
			} else {
				isOverlaid = (normalPanelsCounter) + 1 < numberOfNormalPanels;
			}
		}

		const isVisible = (currentPanelIsActive || !activePanelFound) && somePanelIsActive;
		const isOverlay = normalPanelsCounter > 0;

		const isSecondLevelOverlay = normalPanelsCounter > 1;

		if (panelSetup.type === 'normal') {
			normalPanelsCounter++;
		}

		return Object.assign({}, panelSetup, {
			backlink: panelSetup.backlink,
			content: panelSetup.content,
			visible: isVisible,
			overlaid: isOverlaid,
			overlay: isOverlay,
			secondLevelOverlay: isSecondLevelOverlay,
			closeCallback,
			key: i,
		});
	});

	mainContentSetup.contentProps = {};
	let previousContentProps = mainContentSetup.contentProps;

	panels = panels.map((panelSetup, i) => {
		let PanelStyle;
		let panelProps = {};
		const contentProps = {
			level: i,
		};

		let PanelContent;
		let panelContent;

		if (panelSetup.type === 'fullscreen') {
			PanelStyle = FullscreenPanel;
			panelProps = {
				backlink: panelSetup.backlink,
				closeCallback: panelSetup.closeCallback,
				key: panelSetup.key,
				overlaid: panelSetup.overlaid,
				overlay: panelSetup.overlay,
				secondLevelOverlay: panelSetup.secondLevelOverlay,
				visible: panelSetup.visible,
			};
		} else if (panelSetup.type === 'normal') {
			PanelStyle = Panel;
			panelProps = {
				animateOnMount: animations,
				visible: panelSetup.visible,
				overlaid: panelSetup.overlaid,
				overlay: panelSetup.overlay,
				secondLevelOverlay: panelSetup.secondLevelOverlay,
				closeCallback: panelSetup.closeCallback,
				key: panelSetup.key,
				viewportType,
			};
		} else if (panelSetup.type === 'fullscreen-detail') {
			panelProps = {
				animateOnMount: animations,
				visible: panelSetup.visible,
				overlaid: panelSetup.overlaid,
				overlay: panelSetup.overlay,
				secondLevelOverlay: panelSetup.secondLevelOverlay,
				closeCallback: panelSetup.closeCallback,
				key: panelSetup.key,
			};

			PanelContent = panelSetup.content;
			panelContent = (
				<PanelContent {...contentProps} />
			);

			previousContentProps.fullscreenDetailPanel = (extraProps) => {
				return (
					<DetailPanel
						isFullwidth={true}
						{...panelProps}
						{...extraProps}
					>
						{panelContent}
					</DetailPanel>
				);
			};

			previousContentProps = contentProps;
			return false;
		} else if (panelSetup.type === 'carousel-detail') {
			panelProps = {
				closeCallback: panelSetup.closeCallback,
				key: panelSetup.key,
				visible: panelSetup.visible,
			};

			PanelContent = panelSetup.content;
			panelContent = (
				<PanelContent {...contentProps} />
			);

			previousContentProps.carouselPanel = (extraProps) => {
				return (
					<CarouselDetailPanel
						{...panelProps}
						{...extraProps}
					>
						{panelContent}
					</CarouselDetailPanel>
				);
			};

			previousContentProps = contentProps;
			return false;
		} else if (panelSetup.type === 'detail') {
			panelProps = {
				animateOnMount: animations,
				visible: panelSetup.visible,
				closeCallback: panelSetup.closeCallback,
			};

			if (panelSetup.visible) {
				previousContentProps.innerPanel = (extraProps) => {
					return (
						<DetailPanel
							{...panelProps}
							{...extraProps}
						>
							{panelContent}
						</DetailPanel>
					);
				};
			}

			PanelContent = panelSetup.content;
			panelContent = (
				<PanelContent {...contentProps} />
			);

			previousContentProps = contentProps;
			return false;
		} else if (panelSetup.type === 'mobile-only') {
			previousContentProps = contentProps;
			return false;
		} else if (panelSetup.type === 'modal') {
			PanelStyle = ModalPanel;
			panelProps = {
				alignment: panelSetup.alignment || MODAL_ALIGNMENT_CENTER,
				canBeClosed: true,
				canBeClosedViaOverlay: panelSetup.canBeClosedViaOverlay !== undefined
					? panelSetup.canBeClosedViaOverlay
					: true,
				onCloseCallback: panelSetup.closeCallback,
				size: panelSetup.size || MODAL_SIZE_MEDIUM,
			};
		} else {
			PanelStyle = Panel;
			panelProps = {
				animateOnMount: animations,
				visible: panelSetup.visible,
				overlaid: panelSetup.overlaid,
				overlay: panelSetup.overlay,
				closeCallback: panelSetup.closeCallback,
				key: panelSetup.key,
			};
		}

		contentProps.closeCallback = panelSetup.closeCallback;

		previousContentProps = contentProps;
		return {
			content: panelSetup.content,
			contentProps,
			style: PanelStyle,
			styleProps: panelProps,
		};
	});

	panels = panels.filter((panelSetup) => {
		return !!panelSetup;
	});

	const isPanelModal = panels[panels.length - 1]?.style === ModalPanel;

	panels = panels.map((panelSetup, i) => {
		const {
			content: PanelContent,
			contentProps,
			style: PanelStyle,
			styleProps,
		} = panelSetup;

		let content;
		if (PanelContent) {
			content = (
				<MainErrorBoundary>
					<PanelContent {...contentProps} />
				</MainErrorBoundary>
			);
		}

		if (PanelStyle === ModalPanel) {
			return (
				<CSSTransition
					classNames={{
						exit: 'modal--closing',
					}}
					in={true}
					key={`panel-${i}`}
					timeout={200}
				>
					<PanelStyle {...styleProps}>
						{content}
					</PanelStyle>
				</CSSTransition>
			);
		}

		return (
			<CSSTransition
				classNames={{
					exit: 'panel--before-closing',
					exitActive: 'panel--closing',
				}}
				in={true}
				key={`panel-${i}`}
				timeout={200}
			>
				<div>
					<PanelStyle {...styleProps}>
						{content}
					</PanelStyle>
				</div>
			</CSSTransition>
		);
	});

	let closeByEscape;
	if (activePanelFound && isActivePanelCloseableViaOverlay) {
		closeByEscape = (
			<CloseByEscape
				callback={activePanelCloseCallback}
			/>
		);
	}

	mainContentSetup.contentProps.backlink = mainContentSetup.backlink;
	mainContentSetup.contentProps.closeCallback = mainContentSetup.closeRoute
		? () => navigate(
			mainContentSetup.closeRoute.name,
			mainContentSetup.closeRoute.params || {},
		)
		: null;

	const MainContent = mainContentSetup.content;
	const mainContent = (
		<MainContent {...mainContentSetup.contentProps} />
	);

	return (
		<ActivePanelProvider
			close={activePanelCloseCallback ?? null}
			isModal={isPanelModal}
		>
			<ScrollContainerProvider>
				{({ containerRef }) => (
					<div>
						<PageContent ref={containerRef}>
							<MainErrorBoundary>
								{mainContent}
							</MainErrorBoundary>
						</PageContent>

						{closeByEscape}

						<TransitionGroup>
							{panels}
						</TransitionGroup>
					</div>
				)}
			</ScrollContainerProvider>
		</ActivePanelProvider>
	);
};



export default DesktopPanelStack;
