/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MetricFragment = { readonly __typename?: 'WebVitalsOriginMetric', readonly maximumFast: number, readonly maximumGood: number, readonly percentageFast: CK.Percentage, readonly percentageGood: CK.Percentage, readonly percentageSlow: CK.Percentage, readonly percentile: number, readonly verdict: GraphQL.WebVitalVerdict };

export type PlatformWebVitalsOriginSummaryBlockQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PlatformWebVitalsOriginSummaryBlockQuery = { readonly __typename?: 'Query', readonly platformData: { readonly __typename?: 'PlatformData', readonly id: CK.ID, readonly webVitalsOriginSummaryData: { readonly __typename?: 'WebVitalsOriginSummaryData', readonly coreWebVitalsAssessment: GraphQL.CoreWebVitalsAssessment, readonly cumulativeLayoutShiftScore: { readonly __typename?: 'WebVitalsOriginMetric', readonly maximumFast: number, readonly maximumGood: number, readonly percentageFast: CK.Percentage, readonly percentageGood: CK.Percentage, readonly percentageSlow: CK.Percentage, readonly percentile: number, readonly verdict: GraphQL.WebVitalVerdict } | null, readonly dateRange: { readonly __typename?: 'DateRange', readonly since: CK.DateYMD, readonly until: CK.DateYMD }, readonly firstContentfulPaint: { readonly __typename?: 'WebVitalsOriginMetric', readonly maximumFast: number, readonly maximumGood: number, readonly percentageFast: CK.Percentage, readonly percentageGood: CK.Percentage, readonly percentageSlow: CK.Percentage, readonly percentile: number, readonly verdict: GraphQL.WebVitalVerdict } | null, readonly interactionToNextPaint: { readonly __typename?: 'WebVitalsOriginMetric', readonly maximumFast: number, readonly maximumGood: number, readonly percentageFast: CK.Percentage, readonly percentageGood: CK.Percentage, readonly percentageSlow: CK.Percentage, readonly percentile: number, readonly verdict: GraphQL.WebVitalVerdict } | null, readonly largestContentfulPaint: { readonly __typename?: 'WebVitalsOriginMetric', readonly maximumFast: number, readonly maximumGood: number, readonly percentageFast: CK.Percentage, readonly percentageGood: CK.Percentage, readonly percentageSlow: CK.Percentage, readonly percentile: number, readonly verdict: GraphQL.WebVitalVerdict } | null } | null } | null };

export const MetricFragmentDoc = gql`
    fragment Metric on WebVitalsOriginMetric {
  maximumFast
  maximumGood
  percentageFast
  percentageGood
  percentageSlow
  percentile
  verdict
}
    `;
export const PlatformWebVitalsOriginSummaryBlockDocument = gql`
    query PlatformWebVitalsOriginSummaryBlock($websiteId: WebsiteId!) {
  platformData(websiteId: $websiteId) {
    id
    webVitalsOriginSummaryData {
      coreWebVitalsAssessment
      cumulativeLayoutShiftScore {
        ...Metric
      }
      dateRange {
        since
        until
      }
      firstContentfulPaint {
        ...Metric
      }
      interactionToNextPaint {
        ...Metric
      }
      largestContentfulPaint {
        ...Metric
      }
    }
  }
}
    ${MetricFragmentDoc}`;

/**
 * __usePlatformWebVitalsOriginSummaryBlockQuery__
 *
 * To run a query within a React component, call `usePlatformWebVitalsOriginSummaryBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformWebVitalsOriginSummaryBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformWebVitalsOriginSummaryBlockQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePlatformWebVitalsOriginSummaryBlockQuery(baseOptions: Apollo.QueryHookOptions<PlatformWebVitalsOriginSummaryBlockQuery, PlatformWebVitalsOriginSummaryBlockQueryVariables> & ({ variables: PlatformWebVitalsOriginSummaryBlockQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlatformWebVitalsOriginSummaryBlockQuery, PlatformWebVitalsOriginSummaryBlockQueryVariables>(PlatformWebVitalsOriginSummaryBlockDocument, options);
      }
export function usePlatformWebVitalsOriginSummaryBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlatformWebVitalsOriginSummaryBlockQuery, PlatformWebVitalsOriginSummaryBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlatformWebVitalsOriginSummaryBlockQuery, PlatformWebVitalsOriginSummaryBlockQueryVariables>(PlatformWebVitalsOriginSummaryBlockDocument, options);
        }
export function usePlatformWebVitalsOriginSummaryBlockSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PlatformWebVitalsOriginSummaryBlockQuery, PlatformWebVitalsOriginSummaryBlockQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlatformWebVitalsOriginSummaryBlockQuery, PlatformWebVitalsOriginSummaryBlockQueryVariables>(PlatformWebVitalsOriginSummaryBlockDocument, options);
        }
export type PlatformWebVitalsOriginSummaryBlockQueryHookResult = ReturnType<typeof usePlatformWebVitalsOriginSummaryBlockQuery>;
export type PlatformWebVitalsOriginSummaryBlockLazyQueryHookResult = ReturnType<typeof usePlatformWebVitalsOriginSummaryBlockLazyQuery>;
export type PlatformWebVitalsOriginSummaryBlockSuspenseQueryHookResult = ReturnType<typeof usePlatformWebVitalsOriginSummaryBlockSuspenseQuery>;
export type PlatformWebVitalsOriginSummaryBlockQueryResult = Apollo.QueryResult<PlatformWebVitalsOriginSummaryBlockQuery, PlatformWebVitalsOriginSummaryBlockQueryVariables>;