/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountIsTrialExtensionAllowedQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountIsTrialExtensionAllowedQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isTrialExtensionAllowed: boolean } | null };


export const AccountIsTrialExtensionAllowedDocument = gql`
    query AccountIsTrialExtensionAllowed($accountId: AccountId!) {
  account(id: $accountId) {
    id
    isTrialExtensionAllowed
  }
}
    `;

/**
 * __useAccountIsTrialExtensionAllowedQuery__
 *
 * To run a query within a React component, call `useAccountIsTrialExtensionAllowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountIsTrialExtensionAllowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountIsTrialExtensionAllowedQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountIsTrialExtensionAllowedQuery(baseOptions: Apollo.QueryHookOptions<AccountIsTrialExtensionAllowedQuery, AccountIsTrialExtensionAllowedQueryVariables> & ({ variables: AccountIsTrialExtensionAllowedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountIsTrialExtensionAllowedQuery, AccountIsTrialExtensionAllowedQueryVariables>(AccountIsTrialExtensionAllowedDocument, options);
      }
export function useAccountIsTrialExtensionAllowedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountIsTrialExtensionAllowedQuery, AccountIsTrialExtensionAllowedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountIsTrialExtensionAllowedQuery, AccountIsTrialExtensionAllowedQueryVariables>(AccountIsTrialExtensionAllowedDocument, options);
        }
export function useAccountIsTrialExtensionAllowedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountIsTrialExtensionAllowedQuery, AccountIsTrialExtensionAllowedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountIsTrialExtensionAllowedQuery, AccountIsTrialExtensionAllowedQueryVariables>(AccountIsTrialExtensionAllowedDocument, options);
        }
export type AccountIsTrialExtensionAllowedQueryHookResult = ReturnType<typeof useAccountIsTrialExtensionAllowedQuery>;
export type AccountIsTrialExtensionAllowedLazyQueryHookResult = ReturnType<typeof useAccountIsTrialExtensionAllowedLazyQuery>;
export type AccountIsTrialExtensionAllowedSuspenseQueryHookResult = ReturnType<typeof useAccountIsTrialExtensionAllowedSuspenseQuery>;
export type AccountIsTrialExtensionAllowedQueryResult = Apollo.QueryResult<AccountIsTrialExtensionAllowedQuery, AccountIsTrialExtensionAllowedQueryVariables>;