import React from 'react';

import AccessRequestedStep from './AccessRequestedStep';
import AccountCreatedStep from './AccountCreatedStep';
import ConnectExistingAccountStep from './ConnectExistingAccountStep';
import ConnectToAccountStep from './ConnectToAccountStep';
import CreateNewAccountStep from './CreateNewAccountStep';
import LoadingDots from '~/components/patterns/loaders/LoadingDots';
import MultiStepModal, {
	MultiStepModalNavigation,
	type MultiStepModalRef,
} from '~/components/patterns/modals/MultiStepModal';

import useAccountId from '~/hooks/useAccountId';

import {
	Action,
	type Values,
} from './types';



const ConnectAccountModal = (props) => {
	const {
		closeCallback,
	} = props;

	const accountId = useAccountId();

	const [values, setValues] = React.useState<Values>({
		accountName: undefined,
		action: undefined,
		domain: undefined,
		email: undefined,
	});

	const multiStepModal = React.useRef<MultiStepModalRef | null>(null);

	const handleFirstStep = React.useCallback(
		({ action: nextAction }) => {
			setValues(
				(values) => ({
					...values,
					action: nextAction,
				}),
			);

			setTimeout(
				() => multiStepModal.current?.goToNextStep(),
				0,
			);
		},
		[],
	);

	return (
		<MultiStepModal
			navigationModel={MultiStepModalNavigation.Rigid}
			preloader={accountId === null && (
				<LoadingDots isStretched={true} />
			)}
			ref={multiStepModal}
		>
			<ConnectToAccountStep
				accountId={accountId}
				onContinue={handleFirstStep}
				values={values}
			/>

			{values.action === Action.CreateNewAccount && (
				<>
					<CreateNewAccountStep
						agencyAccountId={accountId}
						goToConnectExistingAccount={(nextValues) => {
							setValues((values) => ({
								...values,
								...nextValues,
								action: Action.ConnectExistingAccount,
							}));

							setTimeout(() => {
								multiStepModal.current?.goToStep('connectToAccount');
							}, 0);
						}}
						onContinue={(nextValues) => {
							setValues((values) => ({
								...values,
								...nextValues,
							}));

							multiStepModal.current?.goToNextStep();
						}}
						values={values}
					/>

					<AccountCreatedStep
						onClose={() => closeCallback()}
						values={values}
					/>
				</>
			)}

			{values.action === Action.ConnectExistingAccount && (
				<>
					<ConnectExistingAccountStep
						accountId={accountId}
						goToCreateNewAccount={(nextValues) => {
							setValues((values) => ({
								...values,
								...nextValues,
								action: Action.CreateNewAccount,
							}));

							setTimeout(() => {
								multiStepModal.current?.goToStep('createNewAccount');
							}, 0);
						}}
						onContinue={(nextValues) => {
							setValues((values) => ({
								...values,
								...nextValues,
							}));

							multiStepModal.current?.goToStep('accessRequested');
						}}
						values={values}
					/>

					<AccessRequestedStep
						onClose={() => closeCallback()}
						values={values}
					/>
				</>
			)}
		</MultiStepModal>
	);
};



export default ConnectAccountModal;
