import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';
import SimpleModal, {
	SimpleModalIconType,
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import SubmitButton from '~/components/app/SubmitButton';
import TextArea from '~/components/atoms/forms/components/TextArea';
import TextField, {
	TextFieldAutocomplete,
} from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';

import {
	useConnectAdobeAnalyticsAccountMutation,
} from './AdobeAnalyticsConnectionModal.gql';

import useAccountId from '~/hooks/useAccountId';
import useEffectiveHomeAccountId from '~/hooks/useEffectiveHomeAccountId';
import useModals from '~/hooks/useModals';



const messages = defineMessages({
	connectButton: {
		id: 'ui.adobeAnalytics.connectionModal.connectButton',
	},
	description: {
		id: 'ui.adobeAnalytics.connectionModal.description',
	},
	fieldClientId: {
		id: 'ui.adobeAnalytics.connectionModal.fields.clientId',
	},
	fieldClientSecret: {
		id: 'ui.adobeAnalytics.connectionModal.fields.clientSecret',
	},
	fieldOrganizationId: {
		id: 'ui.adobeAnalytics.connectionModal.fields.organizationId',
	},
	fieldPrivateKey: {
		id: 'ui.adobeAnalytics.connectionModal.fields.privateKey',
	},
	fieldTechnicalAccountId: {
		id: 'ui.adobeAnalytics.connectionModal.fields.technicalAccountId',
	},
	sidebar: {
		id: 'ui.adobeAnalytics.connectionModal.sidebar',
	},
	title: {
		id: 'ui.adobeAnalytics.connectionModal.title',
	},
});

const errorMessages = defineMessages({
	expiredCredentials: {
		id: 'ui.adobeAnalytics.connectionModal.errors.expiredCredentials',
	},
	invalidCredentials: {
		id: 'ui.adobeAnalytics.connectionModal.errors.invalidCredentials',
	},
});

const validations = {
	validateClientId: validateField(
		'clientId',
		(f) => ([
			f.validateNonEmpty(),
		]),
	),
	validateClientSecret: validateField(
		'clientSecret',
		(f) => ([
			f.validateNonEmpty(),
		]),
	),
	validateOrganizationId: validateField(
		'organizationId',
		(f) => ([
			f.validateNonEmpty(),
		]),
	),
	validatePrivateKey: validateField(
		'privateKey',
		(f) => ([
			f.validateNonEmpty(),
		]),
	),
	validateTechnicalAccountId: validateField(
		'technicalAccountId',
		(f) => ([
			f.validateNonEmpty(),
		]),
	),
};



type Props = {
	onSuccess?: (result: {
		adobeAnalyticsAccountId: number | null,
	}) => void,
};

const AdobeAnalyticsConnectionModal: React.FC<Props> = (props) => {
	const {
		onSuccess,
	} = props;

	const accountId = useAccountId();
	const effectiveHomeAccountId = useEffectiveHomeAccountId();
	const modals = useModals();

	const formRef = React.useRef<FormRef>(null);

	const [connectAdobeAnalyticsAccount] = useConnectAdobeAnalyticsAccountMutation();

	async function handleSubmit(values): Promise<void> {
		if (accountId === null) {
			throw new Error(
				`accountId can't be null when connecting Adobe Analytics integration`,
			);
		}

		if (effectiveHomeAccountId === null) {
			throw new Error(
				`effectiveHomeAccountId can't be null when connecting Adobe Analytics integration`,
			);
		}

		formRef.current?.resetGlobalError();

		const { data } = await connectAdobeAnalyticsAccount({
			variables: {
				accountId,
				adobeAnalyticsClientId: values.clientId,
				adobeAnalyticsClientSecret: values.clientSecret,
				adobeAnalyticsTechnicalAccountId: values.technicalAccountId,
				adobeAnalyticsOrganizationId: values.organizationId,
				adobeAnalyticsPrivateKey: values.privateKey,
				ownerAccountId: effectiveHomeAccountId,
			},
		});

		const adobeAnalyticsAccountId = data?.ConnectAdobeAnalyticsAccount.adobeAnalyticsAccount.id ?? null;

		modals.closeModal();

		if (onSuccess) {
			onSuccess({
				adobeAnalyticsAccountId,
			});
		}
	}

	return (
		<SimpleModal
			alignToTop={true}
			iconType={SimpleModalIconType.Plus}
			sidebar={(
				<RichText>
					<Copy
						{...messages.sidebar}
						values={{
							linkArticle: linkExternal('https://www.contentkingapp.com/support/adobe-analytics/'),
						}}
					/>
				</RichText>
			)}
			size={SimpleModalSize.XXLarge}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<ModalTextSection>
				<Copy
					{...messages.description}
					values={{
						linkArticle: linkExternal('https://www.contentkingapp.com/support/adobe-analytics/'),
					}}
				/>
			</ModalTextSection>

			<Form
				onSuccess={handleSubmit}
				ref={formRef}
				validations={validations}
			>
				<CenteredFormWrapper>
					<FormRows>
						<FormRow
							htmlFor="clientId"
							label={(
								<FormattedMessage {...messages.fieldClientId} />
							)}
						>
							<FieldStatus
								allowOk={false}
								name="validateClientId"
							>
								<TextField
									name="clientId"
									spellCheck={false}
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="clientSecret"
							label={(
								<FormattedMessage {...messages.fieldClientSecret} />
							)}
						>
							<FieldStatus
								allowOk={false}
								name="validateClientSecret"
							>
								<TextField
									autoComplete={TextFieldAutocomplete.Off}
									name="clientSecret"
									spellCheck={false}
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="technicalAccountId"
							label={(
								<Copy {...messages.fieldTechnicalAccountId} />
							)}
						>
							<FieldStatus
								allowOk={false}
								name="validateTechnicalAccountId"
							>
								<TextField
									name="technicalAccountId"
									spellCheck={false}
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="organizationId"
							label={(
								<FormattedMessage {...messages.fieldOrganizationId} />
							)}
						>
							<FieldStatus
								allowOk={false}
								name="validateOrganizationId"
							>
								<TextField
									name="organizationId"
									spellCheck={false}
								/>
							</FieldStatus>
						</FormRow>

						<FormRow
							htmlFor="privateKey"
							label={(
								<FormattedMessage {...messages.fieldPrivateKey} />
							)}
						>
							<FieldStatus
								allowOk={false}
								name="validatePrivateKey"
							>
								<TextArea
									autoComplete={false}
									name="privateKey"
									spellCheck={false}
								/>
							</FieldStatus>
						</FormRow>
					</FormRows>

					<FormErrorMessages
						errors={{
							expiredCredentials: (
								<Copy {...errorMessages.expiredCredentials} />
							),
							invalidCredentials: (
								<Copy {...errorMessages.invalidCredentials} />
							),
						}}
					/>

					<ButtonsLayout>
						<CancelButton />

						<SubmitButton>
							<FormattedMessage {...messages.connectButton} />
						</SubmitButton>
					</ButtonsLayout>
				</CenteredFormWrapper>
			</Form>
		</SimpleModal>
	);
};



export default AdobeAnalyticsConnectionModal;
