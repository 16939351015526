import classNames from 'classnames';
import React from 'react';



type Props = {
	/** Possibility to remove default padding of content */
	gaps?: boolean,
	header?: React.ReactNode,
	children: React.ReactNode,
};



const ScreenBody: React.FC<Props> = (props) => {
	const {
		gaps = true,
		header,
		children,
	} = props;

	const _renderHeader = () => {
		if (!header) {
			return null;
		}

		return (
			<div className="screen-body__header">
				{header && (
					<div className="screen-body__header-body">
						{header}
					</div>
				)}
			</div>
		);
	};

	const componentClasses = classNames({
		'screen-body': true,
		'screen-body--has-gaps': gaps,
		'screen-body--has-fixed-header': header,
	});

	return (
		<div className={componentClasses}>
			{_renderHeader()}
			<div className="screen-body__container js-scrollable">
				<div className="screen-body__body">
					{children}
				</div>
			</div>
		</div>
	);
};



export default ScreenBody;

