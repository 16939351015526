import GraphQL from '~/types/graphql';



export enum IgnoringScope {
	Cases = 'cases',
	Page = 'page',
	Pages = 'pages',
	Segments = 'segments',
	Website = 'website',
}

export enum PageIssue {
	AnalyticsAnalyticsMissing = 'analytics/analytics_missing',
	AnalyticsVisualAnalyticsMissing = 'analytics/visual_analytics_missing',
	CanonicalLinkIncorrectlyCanonicalized = 'canonical_link/incorrectly_canonicalized',
	CanonicalLinkMissing = 'canonical_link/missing',
	CanonicalLinkPointsToUnindexable = 'canonical_link/points_to_unindexable',
	CanonicalLinkTooMany = 'canonical_link/too_many',
	H1Duplicate = 'h1/duplicate',
	H1IncorrectLength = 'h1/incorrect_length',
	H1LevelsSkipped = 'h1/levels_skipped',
	H1Missing = 'h1/missing',
	H1TooMany = 'h1/too_many',
	HreflangConflictingTargets = 'hreflang/conflicting_targets',
	HreflangInvalidTarget = 'hreflang/invalid_target',
	HreflangInvalidValue = 'hreflang/invalid_value',
	HreflangMissingSelfReference = 'hreflang/missing_self_reference',
	HreflangMissingSpecificAudience = 'hreflang/missing_specific_audience',
	HreflangMissingXDefault = 'hreflang/missing_x_default',
	ImagesAltAttribute = 'images/alt_attribute',
	ImagesMixedTransport = 'images/mixed_transport',
	LighthouseCls = 'lighthouse/cls',
	LighthouseFcp = 'lighthouse/fcp',
	LighthouseLcp = 'lighthouse/lcp',
	LighthousePerformance = 'lighthouse/performance',
	LighthouseSi = 'lighthouse/si',
	LighthouseTbt = 'lighthouse/tbt',
	LighthouseTti = 'lighthouse/tti',
	LinksBroken = 'links/broken',
	LinksRedirected = 'links/redirected',
	LinksToCanonicalized = 'links/to_canonicalized',
	MetaDescriptionDuplicate = 'meta_description/duplicate',
	MetaDescriptionIncorrectLength = 'meta_description/incorrect_length',
	MetaDescriptionMissing = 'meta_description/missing',
	MetaDescriptionTooMany = 'meta_description/too_many',
	OpenGraphDescriptionIncorrectLength = 'open_graph/description_incorrect_length',
	OpenGraphDescriptionMissing = 'open_graph/description_missing',
	OpenGraphImageMissing = 'open_graph/image_missing',
	OpenGraphTitleIncorrectLength = 'open_graph/title_incorrect_length',
	OpenGraphTitleMissing = 'open_graph/title_missing',
	OpenGraphUrlMissing = 'open_graph/url_missing',
	RobotDirectivesConflicting = 'robot_directives/conflicting',
	RobotDirectivesInvalid = 'robot_directives/invalid',
	RobotDirectivesUnsupported = 'robot_directives/unsupported',
	SchemaOrgErrors = 'schema_org/errors',
	SchemaOrgInvalidJson = 'schema_org/invalid_json',
	TitleDuplicate = 'title/duplicate',
	TitleIncorrectLength = 'title/incorrect_length',
	TitleMissing = 'title/missing',
	TitleTooMany = 'title/too_many',
	TwitterCardsDescriptionIncorrectLength = 'twitter_cards/description_incorrect_length',
	TwitterCardsDescriptionMissing = 'twitter_cards/description_missing',
	TwitterCardsImageMissing = 'twitter_cards/image_missing',
	TwitterCardsSiteMissing = 'twitter_cards/site_missing',
	TwitterCardsTitleIncorrectLength = 'twitter_cards/title_incorrect_length',
	TwitterCardsTitleMissing = 'twitter_cards/title_missing',
	TwitterCardsTypeMissing = 'twitter_cards/type_missing',
	TwitterCardsTypeInvalid = 'twitter_cards/type_invalid',
	XmlSitemapIncorrectlyMissing = 'xml_sitemap/incorrectly_missing',
	XmlSitemapIncorrectlyPresent = 'xml_sitemap/incorrectly_present',
}

export enum PlatformIssue {
	DomainHostnameNoncanonicalPresent = 'domain/hostname_noncanonical_present',
	DomainHttpsAvailable = 'domain/https_available',
	DomainHttpsCertificateInvalid = 'domain/https_certificate_invalid',
	DomainHttpsNoncanonicalPresent = 'domain/https_noncanonical_present',
	DomainSoft404sPresent = 'domain/soft_404s_present',
	RobotsTxtAssetsDisallowed = 'robots_txt/assets_disallowed',
	RobotsTxtCrawldelayPresent = 'robots_txt/crawldelay_present',
	RobotsTxtInaccessible = 'robots_txt/inaccessible',
	RobotsTxtInvalidDirective = 'robots_txt/invalid_directive',
	RobotsTxtInvalidSyntax = 'robots_txt/invalid_syntax',
	RobotsTxtNoncanonicalPresent = 'robots_txt/noncanonical_present',
	RobotsTxtXmlsitemapReferenceMissing = 'robots_txt/xmlsitemap_reference_missing',
	RobotsTxtXmlsitemapReferenceInaccessible = 'robots_txt/xmlsitemap_reference_inaccessible',
	RobotsTxtXmlsitemapReferenceRelative = 'robots_txt/xmlsitemap_reference_relative',
	WebVitalsOriginSummaryCoreWebVitals = 'web_vitals_origin_summary/core_web_vitals',
	WebVitalsOriginSummaryCumulativeLayoutShift = 'web_vitals_origin_summary/cumulative_layout_shift',
	WebVitalsOriginSummaryFirstContentfulPaint = 'web_vitals_origin_summary/first_contentful_paint',
	WebVitalsOriginSummaryInteractionToNextPaint = 'web_vitals_origin_summary/interaction_to_next_paint',
	WebVitalsOriginSummaryLargestContentfulPaint = 'web_vitals_origin_summary/largest_contentful_paint',
	XmlSitemapFilesizeLimit = 'xml_sitemap/filesize_limit',
	XmlSitemapInaccessible = 'xml_sitemap/inaccessible',
	XmlSitemapInvalidContents = 'xml_sitemap/invalid_contents',
	XmlSitemapInvalidStructure = 'xml_sitemap/invalid_structure',
	XmlSitemapInvalidStructureAll = 'xml_sitemap/invalid_structure_all',
	XmlSitemapMissing = 'xml_sitemap/missing',
	XmlSitemapOrphaned = 'xml_sitemap/orphaned',
	XmlSitemapUrlcountLimit = 'xml_sitemap/urlcount_limit',
}

export type IssueName =
	| PageIssue
	| PlatformIssue;

export enum IssueCategoryName {
	Analytics = 'analytics',
	CanonicalLink = 'canonical_link',
	Domain = 'domain',
	H1 = 'h1',
	Hreflang = 'hreflang',
	Images = 'images',
	Lighthouse = 'lighthouse',
	Links = 'links',
	MetaDescription = 'meta_description',
	OpenGraph = 'open_graph',
	RobotDirectives = 'robot_directives',
	RobotsTxt = 'robots_txt',
	SchemaOrg = 'schema_org',
	Title = 'title',
	TwitterCards = 'twitter_cards',
	WebVitalsOriginSummary = 'web_vitals_origin_summary',
	XmlSitemap = 'xml_sitemap',
}

export type PageIssueWithAffectedLinks =
	| PageIssue.LinksBroken
	| PageIssue.LinksRedirected;



export const PAGE_ISSUES_WITH_CASES: ReadonlyArray<string> = [
	PageIssue.ImagesAltAttribute,
	PageIssue.ImagesMixedTransport,
	PageIssue.LinksBroken,
	PageIssue.LinksRedirected,
	PageIssue.LinksToCanonicalized,
];



type AffectedPagesPerState = {
	absoluteNumberOfPages: {
		closed: number,
		ignored: number,
		notApplicable: number,
		notRequired: number,
		open: number,
		unknown: number,
	},
	percentage: {
		closed: number,
		ignored: number,
		notApplicable: number,
		notRequired: number,
		open: number,
		unknown: number,
	},
};

export function hasAffectedPagesPerState<
	T extends {
		affectedPagesPerState: AffectedPagesPerState | null,
	},
>(input: T | null): input is T & {
	affectedPagesPerState: AffectedPagesPerState,
} {
	if (input === null) {
		return false;
	}

	return input.affectedPagesPerState !== null;
}



export function isIssueOfName<
	TIssue extends {
		name: IssueName,
	},
	TIssueName extends IssueName
>(issue: TIssue, issueName: TIssueName): issue is TIssue & {
	name: TIssueName,
} {
	return issue.name === issueName;
}



export function isPageIssueWithAffectedLinks(
	issueName: IssueName,
): issueName is PageIssueWithAffectedLinks {
	return (
		issueName === PageIssue.LinksBroken
		|| issueName === PageIssue.LinksRedirected
	);
}



export function isRecognizedIssue<
	T extends {
		name: string,
	},
>(issue: T | null): issue is T & {
	name: IssueName,
} {
	if (issue === null) {
		return false;
	}

	return isRecognizedIssueName(issue.name);
}



export function isRecognizedIssueName(issueName: string): issueName is IssueName {
	return (
		isRecognizedPageIssueName(issueName)
		|| isRecognizedPlatformIssueName(issueName)
	);
}



export function isRecognizedPageIssue<
	T extends {
		name: string,
	},
>(issue: T | null): issue is T & {
	name: PageIssue,
} {
	if (issue === null) {
		return false;
	}

	return isRecognizedPageIssueName(issue.name);
}



export function isRecognizedPageIssueName(issueName: string): issueName is PageIssue {
	return Object.values<string>(PageIssue).includes(issueName);
}



export function isRecognizedPlatformIssue<
	T extends {
		name: string,
	},
>(issue: T | null): issue is T & {
	name: PlatformIssue,
} {
	if (issue === null) {
		return false;
	}

	return isRecognizedPlatformIssueName(issue.name);
}



export function isRecognizedPlatformIssueName(issueName: string): issueName is PlatformIssue {
	return Object.values<string>(PlatformIssue).includes(issueName);
}



export function isRecognizedIssueCategory<
	T extends {
		name: string,
	},
>(issueCategory: T | null): issueCategory is T & {
	name: IssueCategoryName,
} {
	if (issueCategory === null) {
		return false;
	}

	return isRecognizedIssueCategoryName(issueCategory.name);
}



export function isRecognizedIssueCategoryName(issueCategoryName: string): issueCategoryName is IssueCategoryName {
	return Object.values<string>(IssueCategoryName).includes(issueCategoryName);
}



export function getPointsToBeDisplayed(
	issue: {
		pointsGained: number,
		pointsToGain: number,
	},
) {
	return issue.pointsToGain > 0 ? issue.pointsToGain : issue.pointsGained;
}



export function createIssuesSortingOrder<TItem extends { name: string }>(
	issues: ReadonlyArray<TItem>,
	sortingAlgorithm: (issues: ReadonlyArray<TItem>) => ReadonlyArray<TItem>,
) {
	const result = {};

	let i = 0;
	sortingAlgorithm(issues).forEach((issue) => {
		result[issue.name] = ++i;
	});

	return result;
}



export function getEntryScopeInPageContext(
	issue: {
		ignoringRuleOnPage: {
			isEffective: boolean,
		},
		ignoringRuleOnSegments: {
			isEffective: boolean,
		},
		ignoringRuleOnWebsite: {
			isEffective: boolean,
		},
		name: string,
	},
) {
	if (issue.ignoringRuleOnWebsite.isEffective) {
		return IgnoringScope.Website;
	}

	if (issue.ignoringRuleOnSegments.isEffective) {
		return IgnoringScope.Segments;
	}

	if (issue.ignoringRuleOnPage.isEffective) {
		return IgnoringScope.Page;
	}

	if (PAGE_ISSUES_WITH_CASES.includes(issue.name)) {
		return IgnoringScope.Cases;
	}

	return IgnoringScope.Page;
}



export function getEntryScopeInWebsiteContext(
	issue: {
		affectedPagesPerState: {} | null,
		ignoringRuleOnCases: {
			isEffective: boolean,
		} | null,
		ignoringRuleOnPages: {
			isEffective: boolean,
		} | null,
		ignoringRuleOnSegments: {
			isEffective: boolean,
		} | null,
		ignoringRuleOnWebsite: {
			isEffective: boolean,
		},
		name: string,
	},
) {
	if (issue.ignoringRuleOnWebsite.isEffective) {
		return IgnoringScope.Website;
	}

	if (issue.ignoringRuleOnSegments?.isEffective ?? false) {
		return IgnoringScope.Segments;
	}

	if (issue.ignoringRuleOnPages?.isEffective ?? false) {
		return IgnoringScope.Pages;
	}

	if (issue.ignoringRuleOnCases?.isEffective ?? false) {
		return IgnoringScope.Cases;
	}

	if (issue.affectedPagesPerState !== null) {
		return IgnoringScope.Segments;
	}

	return IgnoringScope.Website;
}



export function listPagesWithAllIssuesIgnored(
	issueCategory: {
		issues: ReadonlyArray<{
			ignoringRuleOnPages: {
				ignoredPageLegacyIds: ReadonlyArray<number>,
			} | null,
		}>,
	},
) {
	const numberOfIgnoredIssuesPerPage: Record<number, number> = {};

	for (const issue of issueCategory.issues) {
		for (const legacyUrlId of issue.ignoringRuleOnPages?.ignoredPageLegacyIds ?? []) {
			if (!numberOfIgnoredIssuesPerPage[legacyUrlId]) {
				numberOfIgnoredIssuesPerPage[legacyUrlId] = 0;
			}

			numberOfIgnoredIssuesPerPage[legacyUrlId]++;
		}
	}

	const result: Array<number> = [];

	for (const [legacyUrlId, numberOfIgnoredIssues] of Object.entries(numberOfIgnoredIssuesPerPage)) {
		if (numberOfIgnoredIssues === issueCategory.issues.length) {
			result.push(parseInt(legacyUrlId));
		}
	}

	return result;
}



export function listSegmentsWithAllIssuesIgnored(
	issueCategory: {
		issues: ReadonlyArray<{
			ignoringRuleOnSegments: {
				ignoredSegments: ReadonlyArray<string>,
			} | null,
		}>,
	},
) {
	const numberOfIgnoredIssuesPerSegment: Record<string, number> = {};

	for (const issue of issueCategory.issues) {
		for (const segmentName of issue.ignoringRuleOnSegments?.ignoredSegments ?? []) {
			if (numberOfIgnoredIssuesPerSegment[segmentName] === undefined) {
				numberOfIgnoredIssuesPerSegment[segmentName] = 0;
			}

			numberOfIgnoredIssuesPerSegment[segmentName]++;
		}
	}

	const result: Array<string> = [];

	for (const [segmentName, numberOfIgnoredIssues] of Object.entries(numberOfIgnoredIssuesPerSegment)) {
		if (numberOfIgnoredIssues === issueCategory.issues.length) {
			result.push(segmentName);
		}
	}

	return result;
}



const ORDER = [
	GraphQL.IssueState.Open,
	GraphQL.IssueState.Open + '_ignored',
	GraphQL.IssueState.Unknown,
	GraphQL.IssueState.Unknown + '_ignored',
	GraphQL.IssueState.Closed + '_ignored',
	GraphQL.IssueState.Closed,
	GraphQL.IssueState.NotRequired + '_ignored',
	GraphQL.IssueState.NotRequired,
	GraphQL.IssueState.NotApplicable + '_ignored',
	GraphQL.IssueState.NotApplicable,
];

export function sortIssues<TState extends GraphQL.IssueCategoryState | GraphQL.IssueState, TItem extends { state: TState }>(
	issues: ReadonlyArray<TItem & {
		isIgnored: boolean,
		pointsGained: number,
		pointsToGain: number,
		name: string,
		state: TState,
	}>,
) {
	return [...issues].sort((issueA, issueB) => {
		const orderA = ORDER.indexOf(issueA.state + (issueA.isIgnored ? '_ignored' : ''));
		const orderB = ORDER.indexOf(issueB.state + (issueB.isIgnored ? '_ignored' : ''));

		if (orderA === orderB) {
			const issueAScore = getPointsToBeDisplayed(issueA);
			const issueBScore = getPointsToBeDisplayed(issueB);

			if (issueAScore === issueBScore) {
				return issueA.name < issueB.name ? 1 : -1;
			}

			return issueAScore < issueBScore ? 1 : -1;
		}

		return orderA < orderB ? -1 : 1;
	});
}



export function sortIssuesBySortingOrder<TIssue extends { name: string }>(
	issues: ReadonlyArray<TIssue>,
	sortingOrder: Record<string, number>,
) {
	const invalidOrderError = new Error(`Sorting order isn't valid anymore.`);

	try {
		return [...issues].sort((issueA, issueB) => {
			const issueAOrder = sortingOrder[issueA.name];
			const issueBOrder = sortingOrder[issueB.name];

			if (issueAOrder === undefined || issueBOrder === undefined) {
				throw invalidOrderError;
			}

			if (issueAOrder > issueBOrder) {
				return 1;
			} else if (issueAOrder < issueBOrder) {
				return -1;
			}

			return 0;
		});
	} catch (e) {
		if (e === invalidOrderError) {
			return false;
		}

		throw e;
	}
}



export enum AffectedPagesCategory {
	Closed = 'closed',
	Ignored = 'ignored',
	NotApplicable = 'notApplicable',
	NotRequired = 'notRequired',
	Open = 'open',
	Unknown = 'unknown',
}
