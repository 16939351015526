/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserHasNameQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserHasNameQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly firstName: string | null, readonly id: string, readonly lastName: string | null, readonly legacyId: string } | null };


export const UserHasNameDocument = gql`
    query UserHasName($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    firstName
    id
    lastName
    legacyId
  }
}
    `;

/**
 * __useUserHasNameQuery__
 *
 * To run a query within a React component, call `useUserHasNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserHasNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserHasNameQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserHasNameQuery(baseOptions: Apollo.QueryHookOptions<UserHasNameQuery, UserHasNameQueryVariables> & ({ variables: UserHasNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserHasNameQuery, UserHasNameQueryVariables>(UserHasNameDocument, options);
      }
export function useUserHasNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserHasNameQuery, UserHasNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserHasNameQuery, UserHasNameQueryVariables>(UserHasNameDocument, options);
        }
export function useUserHasNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserHasNameQuery, UserHasNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserHasNameQuery, UserHasNameQueryVariables>(UserHasNameDocument, options);
        }
export type UserHasNameQueryHookResult = ReturnType<typeof useUserHasNameQuery>;
export type UserHasNameLazyQueryHookResult = ReturnType<typeof useUserHasNameLazyQuery>;
export type UserHasNameSuspenseQueryHookResult = ReturnType<typeof useUserHasNameSuspenseQuery>;
export type UserHasNameQueryResult = Apollo.QueryResult<UserHasNameQuery, UserHasNameQueryVariables>;