/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteCustomerTeamGoogleSearchConsoleQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteCustomerTeamGoogleSearchConsoleQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly googleSearchConsoleUsedProperties: ReadonlyArray<string> | null } | null };


export const WebsiteCustomerTeamGoogleSearchConsoleDocument = gql`
    query WebsiteCustomerTeamGoogleSearchConsole($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    googleSearchConsoleUsedProperties
  }
}
    `;

/**
 * __useWebsiteCustomerTeamGoogleSearchConsoleQuery__
 *
 * To run a query within a React component, call `useWebsiteCustomerTeamGoogleSearchConsoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteCustomerTeamGoogleSearchConsoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteCustomerTeamGoogleSearchConsoleQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteCustomerTeamGoogleSearchConsoleQuery(baseOptions: Apollo.QueryHookOptions<WebsiteCustomerTeamGoogleSearchConsoleQuery, WebsiteCustomerTeamGoogleSearchConsoleQueryVariables> & ({ variables: WebsiteCustomerTeamGoogleSearchConsoleQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteCustomerTeamGoogleSearchConsoleQuery, WebsiteCustomerTeamGoogleSearchConsoleQueryVariables>(WebsiteCustomerTeamGoogleSearchConsoleDocument, options);
      }
export function useWebsiteCustomerTeamGoogleSearchConsoleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteCustomerTeamGoogleSearchConsoleQuery, WebsiteCustomerTeamGoogleSearchConsoleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteCustomerTeamGoogleSearchConsoleQuery, WebsiteCustomerTeamGoogleSearchConsoleQueryVariables>(WebsiteCustomerTeamGoogleSearchConsoleDocument, options);
        }
export function useWebsiteCustomerTeamGoogleSearchConsoleSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteCustomerTeamGoogleSearchConsoleQuery, WebsiteCustomerTeamGoogleSearchConsoleQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteCustomerTeamGoogleSearchConsoleQuery, WebsiteCustomerTeamGoogleSearchConsoleQueryVariables>(WebsiteCustomerTeamGoogleSearchConsoleDocument, options);
        }
export type WebsiteCustomerTeamGoogleSearchConsoleQueryHookResult = ReturnType<typeof useWebsiteCustomerTeamGoogleSearchConsoleQuery>;
export type WebsiteCustomerTeamGoogleSearchConsoleLazyQueryHookResult = ReturnType<typeof useWebsiteCustomerTeamGoogleSearchConsoleLazyQuery>;
export type WebsiteCustomerTeamGoogleSearchConsoleSuspenseQueryHookResult = ReturnType<typeof useWebsiteCustomerTeamGoogleSearchConsoleSuspenseQuery>;
export type WebsiteCustomerTeamGoogleSearchConsoleQueryResult = Apollo.QueryResult<WebsiteCustomerTeamGoogleSearchConsoleQuery, WebsiteCustomerTeamGoogleSearchConsoleQueryVariables>;