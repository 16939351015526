/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteIsLogFileAnalysisEnabledQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteIsLogFileAnalysisEnabledQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isLogFileAnalysisEnabled: boolean } | null };


export const WebsiteIsLogFileAnalysisEnabledDocument = gql`
    query WebsiteIsLogFileAnalysisEnabled($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    isLogFileAnalysisEnabled
  }
}
    `;

/**
 * __useWebsiteIsLogFileAnalysisEnabledQuery__
 *
 * To run a query within a React component, call `useWebsiteIsLogFileAnalysisEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteIsLogFileAnalysisEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteIsLogFileAnalysisEnabledQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteIsLogFileAnalysisEnabledQuery(baseOptions: Apollo.QueryHookOptions<WebsiteIsLogFileAnalysisEnabledQuery, WebsiteIsLogFileAnalysisEnabledQueryVariables> & ({ variables: WebsiteIsLogFileAnalysisEnabledQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIsLogFileAnalysisEnabledQuery, WebsiteIsLogFileAnalysisEnabledQueryVariables>(WebsiteIsLogFileAnalysisEnabledDocument, options);
      }
export function useWebsiteIsLogFileAnalysisEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIsLogFileAnalysisEnabledQuery, WebsiteIsLogFileAnalysisEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIsLogFileAnalysisEnabledQuery, WebsiteIsLogFileAnalysisEnabledQueryVariables>(WebsiteIsLogFileAnalysisEnabledDocument, options);
        }
export function useWebsiteIsLogFileAnalysisEnabledSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteIsLogFileAnalysisEnabledQuery, WebsiteIsLogFileAnalysisEnabledQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteIsLogFileAnalysisEnabledQuery, WebsiteIsLogFileAnalysisEnabledQueryVariables>(WebsiteIsLogFileAnalysisEnabledDocument, options);
        }
export type WebsiteIsLogFileAnalysisEnabledQueryHookResult = ReturnType<typeof useWebsiteIsLogFileAnalysisEnabledQuery>;
export type WebsiteIsLogFileAnalysisEnabledLazyQueryHookResult = ReturnType<typeof useWebsiteIsLogFileAnalysisEnabledLazyQuery>;
export type WebsiteIsLogFileAnalysisEnabledSuspenseQueryHookResult = ReturnType<typeof useWebsiteIsLogFileAnalysisEnabledSuspenseQuery>;
export type WebsiteIsLogFileAnalysisEnabledQueryResult = Apollo.QueryResult<WebsiteIsLogFileAnalysisEnabledQuery, WebsiteIsLogFileAnalysisEnabledQueryVariables>;