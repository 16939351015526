import memoize from 'memoizee';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import useCurrentColumnSet from '~/hooks/useCurrentColumnSet';
import useCurrentColumns from '~/hooks/useCurrentColumns';
import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteGoogleAnalyticsVisibleColumns from '~/hooks/useWebsiteGoogleAnalyticsVisibleColumns';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	AllCategories,
	GoogleAnalyticsV3Columns,
	GoogleAnalyticsV4Columns,
	ManageableColumns,
	PagesColumnsCategory,
	getColumnCategory,
} from '~/model/pagesColumns';



const format = memoize(
	(
		currentColumns: ReturnType<typeof useCurrentColumns>,
		currentColumnSet: ReturnType<typeof useCurrentColumnSet>,
		customElementDefinitions: ReturnType<typeof useWebsiteCustomElementDefinitions>,
		enrichmentFieldDefinitions: ReturnType<typeof useWebsiteEnrichmentFieldDefinitions>,
		googleAnalyticsVisibleColumns: ReturnType<typeof useWebsiteGoogleAnalyticsVisibleColumns>,
	) => {
		const customElementColumns = customElementDefinitions
			.listAll()
			.map((customElementDefinition) => customElementDefinition.column);

		const enrichmentFieldColumns = enrichmentFieldDefinitions
			.listAll()
			.map((enrichmentFieldDefinition) => enrichmentFieldDefinition.column);

		const allColumns: Array<CK.PagesColumn> = [
			...ManageableColumns,
			...customElementColumns,
			...enrichmentFieldColumns,
		];

		const categoriesOrder: Array<string> = [];

		currentColumns.forEach((column) => {
			const category = getColumnCategory(column.name);

			if (!categoriesOrder.includes(category)) {
				categoriesOrder.push(category);
			}
		});

		if (!categoriesOrder.includes(PagesColumnsCategory.CustomElements)) {
			categoriesOrder.push(PagesColumnsCategory.CustomElements);
		}

		if (!categoriesOrder.includes(PagesColumnsCategory.EnrichmentFields)) {
			categoriesOrder.push(PagesColumnsCategory.EnrichmentFields);
		}

		AllCategories.forEach((category) => {
			if (!categoriesOrder.includes(category)) {
				let includeCategory = true;

				if (category === PagesColumnsCategory.CustomElements) {
					includeCategory = customElementColumns.length > 0;
				} else if (category === PagesColumnsCategory.EnrichmentFields) {
					includeCategory = enrichmentFieldColumns.length > 0;
				}

				if (includeCategory) {
					categoriesOrder.push(category);
				}
			}
		});

		return AllCategories
			.filter((category) => categoriesOrder.includes(category))
			.sort((categoryA, categoryB) => {
				if (categoriesOrder.indexOf(categoryA) === categoriesOrder.indexOf(categoryB)) {
					return 0;
				}

				return categoriesOrder.indexOf(categoryA) > categoriesOrder.indexOf(categoryB) ? 1 : -1;
			})
			.map((category) => {
				let possibleColumns: Array<CK.PagesColumn>;

				if (category === PagesColumnsCategory.CustomElements) {
					if (currentColumnSet !== null) {
						possibleColumns = currentColumnSet.columns
							.filter((column) => getColumnCategory(column.name) === category)
							.map((column) => column.name);

						possibleColumns = possibleColumns.concat(
							customElementColumns.filter((column) => !possibleColumns.includes(column)),
						);
					} else {
						possibleColumns = customElementColumns;
					}
				} else if (category === PagesColumnsCategory.EnrichmentFields) {
					if (currentColumnSet !== null) {
						possibleColumns = currentColumnSet.columns
							.filter((column) => getColumnCategory(column.name) === category)
							.map((column) => column.name);

						possibleColumns = possibleColumns.concat(
							enrichmentFieldColumns.filter((column) => !possibleColumns.includes(column)),
						);
					} else {
						possibleColumns = enrichmentFieldColumns;
					}
				} else if (category === PagesColumnsCategory.GoogleAnalytics) {
					possibleColumns = allColumns;

					if (!googleAnalyticsVisibleColumns.includes(GraphQL.GoogleAnalyticsVersion.V3)) {
						possibleColumns = possibleColumns.filter((column) => {
							return !GoogleAnalyticsV3Columns.includes(column);
						});
					}

					if (!googleAnalyticsVisibleColumns.includes(GraphQL.GoogleAnalyticsVersion.V4)) {
						possibleColumns = possibleColumns.filter((column) => {
							return !GoogleAnalyticsV4Columns.includes(column);
						});
					}
				} else {
					possibleColumns = allColumns;
				}

				const columns = possibleColumns
					.filter((column) => getColumnCategory(column) === category)
					.sort((columnA, columnB) => {
						let columnAOrder = currentColumns.findIndex((column) => column.name === columnA);
						let columnBOrder = currentColumns.findIndex((column) => column.name === columnB);

						if (columnAOrder === columnBOrder) {
							if (columnAOrder === -1) {
								columnAOrder = possibleColumns.indexOf(columnA) + 9999;
							}

							if (columnBOrder === -1) {
								columnBOrder = possibleColumns.indexOf(columnB) + 9999;
							}

							return columnAOrder < columnBOrder ? -1 : 1;
						}

						return columnAOrder < columnBOrder ? -1 : 1;
					})
					.map(
						(column) => ({
							name: column,
							enabled: currentColumns.find((currentColumn) => currentColumn.name === column)?.enabled ?? false,
						}),
					);

				return {
					name: category,
					locked: category === PagesColumnsCategory.Fundamentals,
					enabled: columns.some((column) => column.enabled),
					fullyEnabled: columns.every((column) => column.enabled),
					columns,
				};
			});
	},
);



function useCurrentColumnsSetup() {
	const websiteId = useWebsiteId();

	const currentColumnSet = useCurrentColumnSet();
	const currentColumns = useCurrentColumns();
	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);
	const googleAnalyticsVisibleColumns = useWebsiteGoogleAnalyticsVisibleColumns(websiteId);

	return format(
		currentColumns,
		currentColumnSet,
		customElementDefinitions,
		enrichmentFieldDefinitions,
		googleAnalyticsVisibleColumns,
	);
}



export default useCurrentColumnsSetup;

export type ColumnsSetup = ReturnType<typeof useCurrentColumnsSetup>;
