/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageRedirectTargetQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageRedirectTargetQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly redirectTarget: { readonly __typename?: 'PageRedirectTarget', readonly isCrossdomain: boolean, readonly legacyUrlId: number | null, readonly url: string } | null } | null };


export const PageRedirectTargetDocument = gql`
    query PageRedirectTarget($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    redirectTarget {
      isCrossdomain
      legacyUrlId
      url
    }
  }
}
    `;

/**
 * __usePageRedirectTargetQuery__
 *
 * To run a query within a React component, call `usePageRedirectTargetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageRedirectTargetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageRedirectTargetQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageRedirectTargetQuery(baseOptions: Apollo.QueryHookOptions<PageRedirectTargetQuery, PageRedirectTargetQueryVariables> & ({ variables: PageRedirectTargetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageRedirectTargetQuery, PageRedirectTargetQueryVariables>(PageRedirectTargetDocument, options);
      }
export function usePageRedirectTargetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageRedirectTargetQuery, PageRedirectTargetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageRedirectTargetQuery, PageRedirectTargetQueryVariables>(PageRedirectTargetDocument, options);
        }
export function usePageRedirectTargetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PageRedirectTargetQuery, PageRedirectTargetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageRedirectTargetQuery, PageRedirectTargetQueryVariables>(PageRedirectTargetDocument, options);
        }
export type PageRedirectTargetQueryHookResult = ReturnType<typeof usePageRedirectTargetQuery>;
export type PageRedirectTargetLazyQueryHookResult = ReturnType<typeof usePageRedirectTargetLazyQuery>;
export type PageRedirectTargetSuspenseQueryHookResult = ReturnType<typeof usePageRedirectTargetSuspenseQuery>;
export type PageRedirectTargetQueryResult = Apollo.QueryResult<PageRedirectTargetQuery, PageRedirectTargetQueryVariables>;