/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ForcedModalsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type ForcedModalsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isCleanedUp: boolean, readonly isSuitableForTrialRevival: boolean, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithAccount> } | null, readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly isOnboardingTourInProgress: boolean | null, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isCleanedUp: boolean, readonly isSuitableForTrialRevival: boolean, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithAccount> }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }>, readonly user: { readonly __typename?: 'User', readonly email: string, readonly firstName: string | null, readonly id: string, readonly lastName: string | null, readonly legacyId: string, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithUser> } } | null };


export const ForcedModalsDocument = gql`
    query ForcedModals($accountId: AccountId!) {
  account(id: $accountId) {
    id
    isCleanedUp
    isSuitableForTrialRevival
    permittedActions
  }
  authenticatedSession {
    memberships {
      account {
        id
        isCleanedUp
        isSuitableForTrialRevival
        permittedActions
      }
      isOnboardingTourInProgress
      user {
        id
        legacyId
      }
    }
    user {
      email
      firstName
      id
      lastName
      legacyId
      permittedActions
    }
  }
}
    `;

/**
 * __useForcedModalsQuery__
 *
 * To run a query within a React component, call `useForcedModalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useForcedModalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForcedModalsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useForcedModalsQuery(baseOptions: Apollo.QueryHookOptions<ForcedModalsQuery, ForcedModalsQueryVariables> & ({ variables: ForcedModalsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ForcedModalsQuery, ForcedModalsQueryVariables>(ForcedModalsDocument, options);
      }
export function useForcedModalsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ForcedModalsQuery, ForcedModalsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ForcedModalsQuery, ForcedModalsQueryVariables>(ForcedModalsDocument, options);
        }
export function useForcedModalsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ForcedModalsQuery, ForcedModalsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ForcedModalsQuery, ForcedModalsQueryVariables>(ForcedModalsDocument, options);
        }
export type ForcedModalsQueryHookResult = ReturnType<typeof useForcedModalsQuery>;
export type ForcedModalsLazyQueryHookResult = ReturnType<typeof useForcedModalsLazyQuery>;
export type ForcedModalsSuspenseQueryHookResult = ReturnType<typeof useForcedModalsSuspenseQuery>;
export type ForcedModalsQueryResult = Apollo.QueryResult<ForcedModalsQuery, ForcedModalsQueryVariables>;