import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
	useSelector,
} from 'react-redux';

import ColumnsSetPicker from '~/components/patterns/columnsConfigurator/ColumnsSetPicker';
import ColumnSetsPickerHint from '~/components/logic/interfaceHints/ColumnSetsPickerHint';

import useColumnSets from '~/hooks/useColumnSets';
import useCurrentColumnSet from '~/hooks/useCurrentColumnSet';
import useCurrentColumnSetId from '~/hooks/useCurrentColumnSetId';

import {
	toggleColumnsSetsList,
} from '~/actions';

import {
	columnsSetsListVisibilitySelector,
} from '~/state/ui/selectors';



const messages = defineMessages({
	tagChangedSet: {
		id: 'ui.columnsConfigurator.heading.tag.changed',
	},
	tagDeletedSet: {
		id: 'ui.columnsConfigurator.heading.tag.deleted',
	},
	tagUnsavedSet: {
		id: 'ui.columnsConfigurator.heading.tag.unsaved',
	},
	unsavedLabel: {
		id: 'ui.columnsConfigurator.heading.view.unsaved',
	},
	caption: {
		id: 'ui.columnsConfigurator.heading.view',
	},
});



type Props = {
	isColumnSetModified: boolean | undefined,
};

const ColumnsConfiguratorHeader: React.FC<Props> = (props) => {
	const {
		isColumnSetModified = false,
	} = props;

	const columnSets = useColumnSets();
	const columnsSetsListVisible = useSelector(columnsSetsListVisibilitySelector);
	const currentColumnSet = useCurrentColumnSet();
	const currentColumnSetId = useCurrentColumnSetId();
	const dispatch = useDispatch();

	const noColumnSets = (
		columnSets.isLoaded
		&& columnSets.columnSets.length === 0
	);

	const isColumnSetDeleted = (
		columnSets.isLoaded
		&& currentColumnSetId !== null
		&& currentColumnSet === null
	);

	const handleClick = React.useCallback(
		() => {
			dispatch(
				toggleColumnsSetsList(!columnsSetsListVisible),
			);
		},
		[
			columnsSetsListVisible,
			dispatch,
		],
	);

	const title = (
		currentColumnSet !== null
			? currentColumnSet.name
			: (
				<FormattedMessage {...messages.unsavedLabel} />
			)
	);

	const tag = (
		isColumnSetDeleted ? (
			<FormattedMessage {...messages.tagDeletedSet} />
		) : isColumnSetModified ? (
			<FormattedMessage {...messages.tagChangedSet} />
		) : noColumnSets ? (
			<FormattedMessage {...messages.tagUnsavedSet} />
		) : null
	);

	return (
		<ColumnSetsPickerHint>
			<ColumnsSetPicker
				caption={(
					<FormattedMessage {...messages.caption} />
				)}
				isActive={columnsSetsListVisible}
				onClick={handleClick}
				tag={tag}
				title={title}
			/>
		</ColumnSetsPickerHint>
	);
};



export default ColumnsConfiguratorHeader;
