import React from 'react';

import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import InterfaceMessage from '~/components/patterns/messages/popup/InterfaceMessage';
import Spacer, {
	SpacerGap,
} from '~/components/patterns/utils/Spacer';
import {
	isString,
} from '~/utilities/typeCheck';



type Props = {
	linkRouteName?: string,
	linkRouteParams?: {},
	buttonLabel?: React.ReactNode,
	children: React.ReactNode,
};

const ColumnsMessage: React.FC<Props> = (props) => {
	const {
		linkRouteName,
		linkRouteParams,
		buttonLabel,
		children,
	} = props;

	return (
		<Spacer gap={SpacerGap.Small}>
			<InterfaceMessage
				ctaElement={(
					isString(linkRouteName) && buttonLabel ? (
						<Button
							linkRouteName={linkRouteName}
							linkRouteParams={linkRouteParams}
							size={ButtonSize.Small}
							style={ButtonStyle.Hollow}
							uppercase={true}
						>
							{buttonLabel}
						</Button>
					) : null
				)}
			>
				{children}
			</InterfaceMessage>
		</Spacer>
	);
};

export default ColumnsMessage;
