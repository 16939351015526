import {
	shouldPolyfill as shouldPolyfillLocale,
} from '@formatjs/intl-locale/should-polyfill';
import {
	shouldPolyfill as shouldPolyfillListFormat,
} from '@formatjs/intl-listformat/should-polyfill';
import {
	shouldPolyfill as shouldPolyfillPluralRules,
} from '@formatjs/intl-pluralrules/should-polyfill';
import {
	shouldPolyfill as shouldPolyfillRelativeTimeFormat,
} from '@formatjs/intl-relativetimeformat/should-polyfill';
import {
	shouldPolyfill as shouldPolyfillDateTimeFormat,
} from '@formatjs/intl-datetimeformat/should-polyfill';
import {
	shouldPolyfill as shouldPolyfillNumberFormat,
} from '@formatjs/intl-numberformat/should-polyfill';
import {
	polyfill as scrollPolyfill,
} from 'seamless-scroll-polyfill';



export async function setupPolyfills(): Promise<void> {
	await setupGlobalThisPolyfill();
	await setupLocalePolyfill();
	await setupListFormatPolyfill();
	await setupPluralRulesPolyfill();
	await setupRelativeTimeFormatPolyfill();
	await setupDateTimeFormatPolyfill();
	await setupNumberFormatPolyfill();
	await setupScrollPolyfill();
}



async function setupGlobalThisPolyfill(): Promise<void> {
	// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
	if (!globalThis) {
		(await import('globalthis/polyfill'))();
	}
}



async function setupLocalePolyfill(): Promise<void> {
	if (shouldPolyfillLocale()) {
		await import('@formatjs/intl-locale/polyfill');
	}
}



async function setupListFormatPolyfill(): Promise<void> {
	if (shouldPolyfillListFormat()) {
		await import('@formatjs/intl-listformat/polyfill');
	}
}



async function setupPluralRulesPolyfill(): Promise<void> {
	if (shouldPolyfillPluralRules()) {
		await import('@formatjs/intl-pluralrules/polyfill');
		await import('@formatjs/intl-pluralrules/locale-data/en');
	}
}



async function setupRelativeTimeFormatPolyfill(): Promise<void> {
	if (shouldPolyfillRelativeTimeFormat()) {
		await import('@formatjs/intl-relativetimeformat/polyfill');
		await import('@formatjs/intl-relativetimeformat/locale-data/en');
	}
}



async function setupDateTimeFormatPolyfill(): Promise<void> {
	if (shouldPolyfillDateTimeFormat()) {
		await import('@formatjs/intl-datetimeformat/polyfill');
		await import('@formatjs/intl-datetimeformat/locale-data/en');
		await import('@formatjs/intl-datetimeformat/add-all-tz');
	}
}



async function setupNumberFormatPolyfill(): Promise<void> {
	if (shouldPolyfillNumberFormat()) {
		await import('@formatjs/intl-numberformat/polyfill');
		await import('@formatjs/intl-numberformat/locale-data/en');
	}
}



async function setupScrollPolyfill() {
	scrollPolyfill();
}
