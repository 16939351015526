/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountIsSubscriptionRenewalEnabledQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountIsSubscriptionRenewalEnabledQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isSubscriptionRenewalEnabled: boolean } | null };


export const AccountIsSubscriptionRenewalEnabledDocument = gql`
    query AccountIsSubscriptionRenewalEnabled($accountId: AccountId!) {
  account(id: $accountId) {
    id
    isSubscriptionRenewalEnabled
  }
}
    `;

/**
 * __useAccountIsSubscriptionRenewalEnabledQuery__
 *
 * To run a query within a React component, call `useAccountIsSubscriptionRenewalEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountIsSubscriptionRenewalEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountIsSubscriptionRenewalEnabledQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountIsSubscriptionRenewalEnabledQuery(baseOptions: Apollo.QueryHookOptions<AccountIsSubscriptionRenewalEnabledQuery, AccountIsSubscriptionRenewalEnabledQueryVariables> & ({ variables: AccountIsSubscriptionRenewalEnabledQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountIsSubscriptionRenewalEnabledQuery, AccountIsSubscriptionRenewalEnabledQueryVariables>(AccountIsSubscriptionRenewalEnabledDocument, options);
      }
export function useAccountIsSubscriptionRenewalEnabledLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountIsSubscriptionRenewalEnabledQuery, AccountIsSubscriptionRenewalEnabledQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountIsSubscriptionRenewalEnabledQuery, AccountIsSubscriptionRenewalEnabledQueryVariables>(AccountIsSubscriptionRenewalEnabledDocument, options);
        }
export function useAccountIsSubscriptionRenewalEnabledSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountIsSubscriptionRenewalEnabledQuery, AccountIsSubscriptionRenewalEnabledQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountIsSubscriptionRenewalEnabledQuery, AccountIsSubscriptionRenewalEnabledQueryVariables>(AccountIsSubscriptionRenewalEnabledDocument, options);
        }
export type AccountIsSubscriptionRenewalEnabledQueryHookResult = ReturnType<typeof useAccountIsSubscriptionRenewalEnabledQuery>;
export type AccountIsSubscriptionRenewalEnabledLazyQueryHookResult = ReturnType<typeof useAccountIsSubscriptionRenewalEnabledLazyQuery>;
export type AccountIsSubscriptionRenewalEnabledSuspenseQueryHookResult = ReturnType<typeof useAccountIsSubscriptionRenewalEnabledSuspenseQuery>;
export type AccountIsSubscriptionRenewalEnabledQueryResult = Apollo.QueryResult<AccountIsSubscriptionRenewalEnabledQuery, AccountIsSubscriptionRenewalEnabledQueryVariables>;