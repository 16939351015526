import classNames from 'classnames';
import React from 'react';
import {
	CSSTransition,
	TransitionGroup,
} from 'react-transition-group';



type Props = {
	children?: React.ReactNode,
	/** Additional classes */
	className?: string,
};



const PanelAnimation: React.FC<Props> = (props) => {
	const {
		children,
		className,
	} = props;

	return (
		<TransitionGroup>
			{children ? (
				<CSSTransition
					appear={true}
					classNames={{
						appear: 'panel-animation--before-opening',
						appearActive: 'panel-animation--opening',
						enter: 'panel-animation--before-opening',
						enterActive: 'panel-animation--opening',
						exit: 'panel-animation--before-closing',
						exitActive: 'panel-animation--closing',
					}}
					in={true}
					timeout={{
						enter: 300,
						exit: 100,
					}}
				>
					<div
						className={classNames('panel-animation', className)}
					>
						{children}
					</div>
				</CSSTransition>
			) : null}
		</TransitionGroup>
	);
};



export default PanelAnimation;
