import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import MultiselectTableField from '~/components/logic/formFields/MultiselectTableField';
import UpcomingValueUpdateHighlight from '~/components/patterns/values/UpcomingValueUpdateHighlight';

import useFormContext from '~/hooks/useFormContext';



const messages = defineMessages({
	statusColumn: {
		id: 'ui.indexNowTriggerSelectField.statusColumn',
	},
	doesTrigger: {
		id: 'ui.indexNowTriggerSelectField.doesTrigger',
	},
	doesNotTrigger: {
		id: 'ui.indexNowTriggerSelectField.doesNotTrigger',
	},
	willTrigger: {
		id: 'ui.indexNowTriggerSelectField.willTrigger',
	},
	willNotTrigger: {
		id: 'ui.indexNowTriggerSelectField.willNotTrigger',
	},
});



type Props<Item = any> = {
	itemLabel: (item: Item) => React.ReactNode,
	items: Array<Item>,
	itemsHeader: React.ReactNode,
	name: string,
};

const IndexNowTriggerSelectField: React.FC<Props> = (props) => {
	const {
		itemLabel,
		items,
		itemsHeader,
		name,
	} = props;

	const formContext = useFormContext();
	const defaultValue = formContext.defaultValues[name];

	const renderHeader = React.useCallback(
		({ columnIndex }) => {
			switch (columnIndex) {

				case 0:
					return itemsHeader;

				case 1:
					return (
						<FormattedMessage {...messages.statusColumn} />
					);

			}

			return null;
		},
		[
			itemsHeader,
		],
	);

	const renderCell = React.useCallback(
		({ columnIndex, item, isChecked }) => {
			switch (columnIndex) {

				case 0:
					return itemLabel(item);

				case 1:
					const valueChanged = defaultValue.includes(item) !== isChecked;

					if (!valueChanged) {
						if (isChecked) {
							return (
								<FormattedMessage {...messages.doesTrigger} />
							);
						}

						return (
							<FormattedMessage {...messages.doesNotTrigger} />
						);
					}

					if (isChecked) {
						return (
							<UpcomingValueUpdateHighlight updateDescription={<FormattedMessage {...messages.willTrigger} />}>
								<FormattedMessage {...messages.willTrigger} />
							</UpcomingValueUpdateHighlight>
						);
					}

					return (
						<UpcomingValueUpdateHighlight updateDescription={<FormattedMessage {...messages.willNotTrigger} />}>
							<FormattedMessage {...messages.willNotTrigger} />
						</UpcomingValueUpdateHighlight>
					);

			}

			return null;
		},
		[
			defaultValue,
			itemLabel,
		],
	);

	return (
		<MultiselectTableField
			cellRenderer={renderCell}
			columnCount={2}
			columnWidth={({ width }) => width / 2}
			headerRenderer={renderHeader}
			items={items}
			name={name}
			tableHeight={items.length * 60}
		/>
	);
};



export default IndexNowTriggerSelectField;
