import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import EndUserPackageForm from '~/components/atoms/forms/forms/EndUserPackageForm';
import FormsList from '~/components/atoms/lists/FormsList';
import LegacyPackageForm from '~/components/app/LegacyPackageForm';
import PremiumTrialForm from '~/components/logic/accountSettings/PremiumTrialForm';
import SubscriptionForm from '~/components/app/SubscriptionForm';

import useAccountBillingEntity from '~/hooks/useAccountBillingEntity';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountType from '~/hooks/useAccountType';
import useUrlState from '~/hooks/useUrlState';

import {
	navigate,
} from '~/routing/router';



type Props = {
	accountId: CK.AccountId | null,
};

const SubscriptionGroup: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountBillingEntity = useAccountBillingEntity(accountId);
	const accountPhase = useAccountPhase(accountId);
	const accountType = useAccountType(accountId);
	const urlState = useUrlState();

	const [isEditingSubscription, setEditingSubscription] = React.useState(false);
	const [preselectedPlan, setPreselectedPlan] = React.useState<GraphQL.AccountPlan | null>(null);

	React.useEffect(
		() => {
			if (urlState.params.plan) {
				setPreselectedPlan(urlState.params.plan);

				navigate(
					urlState.name,
					Object.assign({}, urlState.params, {
						plan: false,
					}),
					{
						replace: true,
					},
				);
			}
		},
		[urlState],
	);

	if (
		accountBillingEntity === null
		|| accountPhase !== GraphQL.AccountPhase.Customer
		|| accountType === null
	) {
		return null;
	}

	if (accountType === GraphQL.AccountType.Universal) {
		if (accountBillingEntity !== 'old') {
			return (
				<FormsList>
					<SubscriptionForm
						accountId={accountId}
						onEnterEditModeCallback={setEditingSubscription}
						preselectedPlan={preselectedPlan}
					/>

					{!isEditingSubscription && (
						<PremiumTrialForm />
					)}
				</FormsList>
			);
		}
	}

	return (
		<FormsList>
			{accountType === GraphQL.AccountType.EndUser && (
				<EndUserPackageForm
					accountId={accountId}
				/>
			)}

			{accountType === GraphQL.AccountType.Universal && (
				<LegacyPackageForm
					accountId={accountId}
					onEnterEditModeCallback={setEditingSubscription}
					preselectedPlan={preselectedPlan}
				/>
			)}

			{!isEditingSubscription && (
				<PremiumTrialForm />
			)}
		</FormsList>
	);
};



export default SubscriptionGroup;
