/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteTimeCreatedAtQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteTimeCreatedAtQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly timeCreatedAt: CK.Timestamp } | null };


export const WebsiteTimeCreatedAtDocument = gql`
    query WebsiteTimeCreatedAt($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    timeCreatedAt
  }
}
    `;

/**
 * __useWebsiteTimeCreatedAtQuery__
 *
 * To run a query within a React component, call `useWebsiteTimeCreatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteTimeCreatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteTimeCreatedAtQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteTimeCreatedAtQuery(baseOptions: Apollo.QueryHookOptions<WebsiteTimeCreatedAtQuery, WebsiteTimeCreatedAtQueryVariables> & ({ variables: WebsiteTimeCreatedAtQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteTimeCreatedAtQuery, WebsiteTimeCreatedAtQueryVariables>(WebsiteTimeCreatedAtDocument, options);
      }
export function useWebsiteTimeCreatedAtLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteTimeCreatedAtQuery, WebsiteTimeCreatedAtQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteTimeCreatedAtQuery, WebsiteTimeCreatedAtQueryVariables>(WebsiteTimeCreatedAtDocument, options);
        }
export function useWebsiteTimeCreatedAtSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteTimeCreatedAtQuery, WebsiteTimeCreatedAtQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteTimeCreatedAtQuery, WebsiteTimeCreatedAtQueryVariables>(WebsiteTimeCreatedAtDocument, options);
        }
export type WebsiteTimeCreatedAtQueryHookResult = ReturnType<typeof useWebsiteTimeCreatedAtQuery>;
export type WebsiteTimeCreatedAtLazyQueryHookResult = ReturnType<typeof useWebsiteTimeCreatedAtLazyQuery>;
export type WebsiteTimeCreatedAtSuspenseQueryHookResult = ReturnType<typeof useWebsiteTimeCreatedAtSuspenseQuery>;
export type WebsiteTimeCreatedAtQueryResult = Apollo.QueryResult<WebsiteTimeCreatedAtQuery, WebsiteTimeCreatedAtQueryVariables>;