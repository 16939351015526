import {
	useSelector,
} from 'react-redux';

import type CK from '~/types/contentking';

import useAffectedLinksFilter from '~/hooks/useAffectedLinksFilter';
import usePages from '~/hooks/usePages';

import {
	type IssueName,
} from '~/model/issuesNew';

import {
	sortBySelector,
} from '~/state/affectedLinks/selectors';



const columns = [
	'number_of_incoming_internal_links',
	'url_full',
];



function useAffectedLinks(
	issueName: IssueName,
	websiteId: CK.WebsiteId,
) {
	const filter = useAffectedLinksFilter(issueName, true);
	const sortBy = useSelector(sortBySelector);

	return usePages({
		columns,
		filter: filter ?? {},
		limit: 1000,
		pollIntervalInMilliseconds: 30000,
		skip: filter === null,
		sortBy,
		websiteId,
	});
}



export default useAffectedLinks;
