import React from 'react';

import {
	useReportBlockedIntercomMutation,
} from './IntercomPinger.gql';



const IntercomPinger: React.FC = () => {
	const timeoutRef = React.useRef<ReturnType<typeof setTimeout> | null>(null);

	const [reportBlockedIntercom] = useReportBlockedIntercomMutation();

	const ping = React.useCallback(
		async () => {
			await reportBlockedIntercom();

			timeoutRef.current = setTimeout(
				() => {
					ping();
				},
				300000,
			);
		},
		[
			reportBlockedIntercom,
		],
	);

	React.useEffect(
		() => {
			ping();

			return () => {
				if (timeoutRef.current !== null) {
					clearTimeout(timeoutRef.current);
				}
			};
		},
		[
			ping,
		],
	);

	return null;
};



export default IntercomPinger;
