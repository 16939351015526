/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type StagingAreaFragment = { readonly __typename?: 'StagingAreaOfDomainsValidationSession', readonly failedDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationFailedDomain', readonly domain: string, readonly failureReason: GraphQL.DomainValidationFailure, readonly isDomainVerified: boolean }>, readonly finishedDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationFinishedDomain', readonly domain: string, readonly isDomainVerified: boolean }>, readonly pendingDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationPendingDomain', readonly domain: string, readonly isDomainVerified: boolean }> };

export type InitiateValidateDomainsSessionMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type InitiateValidateDomainsSessionMutation = { readonly __typename?: 'Mutation', readonly InitiateValidateDomainsSession: { readonly __typename?: 'InitiateValidateDomainsSessionResult', readonly sessionId: string } };

export type CompleteValidateDomainsSessionMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  sessionId: GraphQL.Scalars['String']['input'];
}>;


export type CompleteValidateDomainsSessionMutation = { readonly __typename?: 'Mutation', readonly CompleteValidateDomainsSession: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };

export type ExtractDomainsFromFreeInputMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  sessionId: GraphQL.Scalars['String']['input'];
  freeInput: GraphQL.Scalars['String']['input'];
}>;


export type ExtractDomainsFromFreeInputMutation = { readonly __typename?: 'Mutation', readonly StageDomainsForValidationFromFreeInput: { readonly __typename?: 'StageDomainsForValidationFromFreeInputResult', readonly remainingFreeInputText: string, readonly stagingArea: { readonly __typename?: 'StagingAreaOfDomainsValidationSession', readonly failedDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationFailedDomain', readonly domain: string, readonly failureReason: GraphQL.DomainValidationFailure, readonly isDomainVerified: boolean }>, readonly finishedDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationFinishedDomain', readonly domain: string, readonly isDomainVerified: boolean }>, readonly pendingDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationPendingDomain', readonly domain: string, readonly isDomainVerified: boolean }> } } };

export type StageDomainsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  sessionId: GraphQL.Scalars['String']['input'];
  domains: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
}>;


export type StageDomainsMutation = { readonly __typename?: 'Mutation', readonly StageDomainsForValidation: { readonly __typename?: 'StageDomainsForValidationResult', readonly stagingArea: { readonly __typename?: 'StagingAreaOfDomainsValidationSession', readonly failedDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationFailedDomain', readonly domain: string, readonly failureReason: GraphQL.DomainValidationFailure, readonly isDomainVerified: boolean }>, readonly finishedDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationFinishedDomain', readonly domain: string, readonly isDomainVerified: boolean }>, readonly pendingDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationPendingDomain', readonly domain: string, readonly isDomainVerified: boolean }> } } };

export type UnstageDomainsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  sessionId: GraphQL.Scalars['String']['input'];
  domains: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
}>;


export type UnstageDomainsMutation = { readonly __typename?: 'Mutation', readonly UnstageDomainsFromValidationSession: { readonly __typename?: 'UnstageDomainsFromValidationSessionResult', readonly stagingArea: { readonly __typename?: 'StagingAreaOfDomainsValidationSession', readonly failedDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationFailedDomain', readonly domain: string, readonly failureReason: GraphQL.DomainValidationFailure, readonly isDomainVerified: boolean }>, readonly finishedDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationFinishedDomain', readonly domain: string, readonly isDomainVerified: boolean }>, readonly pendingDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationPendingDomain', readonly domain: string, readonly isDomainVerified: boolean }> } } };

export type DomainsSessonStagingAreaQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  sessionId: GraphQL.Scalars['String']['input'];
}>;


export type DomainsSessonStagingAreaQuery = { readonly __typename?: 'Query', readonly domainsValidationSession: { readonly __typename?: 'StagingAreaOfDomainsValidationSession', readonly failedDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationFailedDomain', readonly domain: string, readonly failureReason: GraphQL.DomainValidationFailure, readonly isDomainVerified: boolean }>, readonly finishedDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationFinishedDomain', readonly domain: string, readonly isDomainVerified: boolean }>, readonly pendingDomains: ReadonlyArray<{ readonly __typename?: 'DomainsValidationPendingDomain', readonly domain: string, readonly isDomainVerified: boolean }> } | null };

export type SuggestedDomainsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type SuggestedDomainsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly suggestedDomains: ReadonlyArray<string> } | null };

export const StagingAreaFragmentDoc = gql`
    fragment StagingArea on StagingAreaOfDomainsValidationSession {
  failedDomains {
    domain
    failureReason
    isDomainVerified
  }
  finishedDomains {
    domain
    isDomainVerified
  }
  pendingDomains {
    domain
    isDomainVerified
  }
}
    `;
export const InitiateValidateDomainsSessionDocument = gql`
    mutation InitiateValidateDomainsSession($accountId: AccountId!) {
  InitiateValidateDomainsSession(accountId: $accountId) {
    sessionId
  }
}
    `;
export type InitiateValidateDomainsSessionMutationFn = Apollo.MutationFunction<InitiateValidateDomainsSessionMutation, InitiateValidateDomainsSessionMutationVariables>;

/**
 * __useInitiateValidateDomainsSessionMutation__
 *
 * To run a mutation, you first call `useInitiateValidateDomainsSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateValidateDomainsSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateValidateDomainsSessionMutation, { data, loading, error }] = useInitiateValidateDomainsSessionMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useInitiateValidateDomainsSessionMutation(baseOptions?: Apollo.MutationHookOptions<InitiateValidateDomainsSessionMutation, InitiateValidateDomainsSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateValidateDomainsSessionMutation, InitiateValidateDomainsSessionMutationVariables>(InitiateValidateDomainsSessionDocument, options);
      }
export type InitiateValidateDomainsSessionMutationHookResult = ReturnType<typeof useInitiateValidateDomainsSessionMutation>;
export type InitiateValidateDomainsSessionMutationResult = Apollo.MutationResult<InitiateValidateDomainsSessionMutation>;
export type InitiateValidateDomainsSessionMutationOptions = Apollo.BaseMutationOptions<InitiateValidateDomainsSessionMutation, InitiateValidateDomainsSessionMutationVariables>;
export const CompleteValidateDomainsSessionDocument = gql`
    mutation CompleteValidateDomainsSession($accountId: AccountId!, $sessionId: String!) {
  CompleteValidateDomainsSession(accountId: $accountId, sessionId: $sessionId) {
    query {
      ping
    }
  }
}
    `;
export type CompleteValidateDomainsSessionMutationFn = Apollo.MutationFunction<CompleteValidateDomainsSessionMutation, CompleteValidateDomainsSessionMutationVariables>;

/**
 * __useCompleteValidateDomainsSessionMutation__
 *
 * To run a mutation, you first call `useCompleteValidateDomainsSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteValidateDomainsSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeValidateDomainsSessionMutation, { data, loading, error }] = useCompleteValidateDomainsSessionMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useCompleteValidateDomainsSessionMutation(baseOptions?: Apollo.MutationHookOptions<CompleteValidateDomainsSessionMutation, CompleteValidateDomainsSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteValidateDomainsSessionMutation, CompleteValidateDomainsSessionMutationVariables>(CompleteValidateDomainsSessionDocument, options);
      }
export type CompleteValidateDomainsSessionMutationHookResult = ReturnType<typeof useCompleteValidateDomainsSessionMutation>;
export type CompleteValidateDomainsSessionMutationResult = Apollo.MutationResult<CompleteValidateDomainsSessionMutation>;
export type CompleteValidateDomainsSessionMutationOptions = Apollo.BaseMutationOptions<CompleteValidateDomainsSessionMutation, CompleteValidateDomainsSessionMutationVariables>;
export const ExtractDomainsFromFreeInputDocument = gql`
    mutation ExtractDomainsFromFreeInput($accountId: AccountId!, $sessionId: String!, $freeInput: String!) {
  StageDomainsForValidationFromFreeInput(
    accountId: $accountId
    sessionId: $sessionId
    bulkDomainsFreeInput: $freeInput
  ) {
    stagingArea {
      ...StagingArea
    }
    remainingFreeInputText
  }
}
    ${StagingAreaFragmentDoc}`;
export type ExtractDomainsFromFreeInputMutationFn = Apollo.MutationFunction<ExtractDomainsFromFreeInputMutation, ExtractDomainsFromFreeInputMutationVariables>;

/**
 * __useExtractDomainsFromFreeInputMutation__
 *
 * To run a mutation, you first call `useExtractDomainsFromFreeInputMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExtractDomainsFromFreeInputMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [extractDomainsFromFreeInputMutation, { data, loading, error }] = useExtractDomainsFromFreeInputMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sessionId: // value for 'sessionId'
 *      freeInput: // value for 'freeInput'
 *   },
 * });
 */
export function useExtractDomainsFromFreeInputMutation(baseOptions?: Apollo.MutationHookOptions<ExtractDomainsFromFreeInputMutation, ExtractDomainsFromFreeInputMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExtractDomainsFromFreeInputMutation, ExtractDomainsFromFreeInputMutationVariables>(ExtractDomainsFromFreeInputDocument, options);
      }
export type ExtractDomainsFromFreeInputMutationHookResult = ReturnType<typeof useExtractDomainsFromFreeInputMutation>;
export type ExtractDomainsFromFreeInputMutationResult = Apollo.MutationResult<ExtractDomainsFromFreeInputMutation>;
export type ExtractDomainsFromFreeInputMutationOptions = Apollo.BaseMutationOptions<ExtractDomainsFromFreeInputMutation, ExtractDomainsFromFreeInputMutationVariables>;
export const StageDomainsDocument = gql`
    mutation StageDomains($accountId: AccountId!, $sessionId: String!, $domains: [String!]!) {
  StageDomainsForValidation(
    accountId: $accountId
    sessionId: $sessionId
    domains: $domains
  ) {
    stagingArea {
      ...StagingArea
    }
  }
}
    ${StagingAreaFragmentDoc}`;
export type StageDomainsMutationFn = Apollo.MutationFunction<StageDomainsMutation, StageDomainsMutationVariables>;

/**
 * __useStageDomainsMutation__
 *
 * To run a mutation, you first call `useStageDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStageDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stageDomainsMutation, { data, loading, error }] = useStageDomainsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sessionId: // value for 'sessionId'
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useStageDomainsMutation(baseOptions?: Apollo.MutationHookOptions<StageDomainsMutation, StageDomainsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StageDomainsMutation, StageDomainsMutationVariables>(StageDomainsDocument, options);
      }
export type StageDomainsMutationHookResult = ReturnType<typeof useStageDomainsMutation>;
export type StageDomainsMutationResult = Apollo.MutationResult<StageDomainsMutation>;
export type StageDomainsMutationOptions = Apollo.BaseMutationOptions<StageDomainsMutation, StageDomainsMutationVariables>;
export const UnstageDomainsDocument = gql`
    mutation UnstageDomains($accountId: AccountId!, $sessionId: String!, $domains: [String!]!) {
  UnstageDomainsFromValidationSession(
    accountId: $accountId
    sessionId: $sessionId
    domains: $domains
  ) {
    stagingArea {
      ...StagingArea
    }
  }
}
    ${StagingAreaFragmentDoc}`;
export type UnstageDomainsMutationFn = Apollo.MutationFunction<UnstageDomainsMutation, UnstageDomainsMutationVariables>;

/**
 * __useUnstageDomainsMutation__
 *
 * To run a mutation, you first call `useUnstageDomainsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnstageDomainsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unstageDomainsMutation, { data, loading, error }] = useUnstageDomainsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sessionId: // value for 'sessionId'
 *      domains: // value for 'domains'
 *   },
 * });
 */
export function useUnstageDomainsMutation(baseOptions?: Apollo.MutationHookOptions<UnstageDomainsMutation, UnstageDomainsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnstageDomainsMutation, UnstageDomainsMutationVariables>(UnstageDomainsDocument, options);
      }
export type UnstageDomainsMutationHookResult = ReturnType<typeof useUnstageDomainsMutation>;
export type UnstageDomainsMutationResult = Apollo.MutationResult<UnstageDomainsMutation>;
export type UnstageDomainsMutationOptions = Apollo.BaseMutationOptions<UnstageDomainsMutation, UnstageDomainsMutationVariables>;
export const DomainsSessonStagingAreaDocument = gql`
    query DomainsSessonStagingArea($accountId: AccountId!, $sessionId: String!) {
  domainsValidationSession(accountId: $accountId, sessionId: $sessionId) {
    ...StagingArea
  }
}
    ${StagingAreaFragmentDoc}`;

/**
 * __useDomainsSessonStagingAreaQuery__
 *
 * To run a query within a React component, call `useDomainsSessonStagingAreaQuery` and pass it any options that fit your needs.
 * When your component renders, `useDomainsSessonStagingAreaQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDomainsSessonStagingAreaQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      sessionId: // value for 'sessionId'
 *   },
 * });
 */
export function useDomainsSessonStagingAreaQuery(baseOptions: Apollo.QueryHookOptions<DomainsSessonStagingAreaQuery, DomainsSessonStagingAreaQueryVariables> & ({ variables: DomainsSessonStagingAreaQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DomainsSessonStagingAreaQuery, DomainsSessonStagingAreaQueryVariables>(DomainsSessonStagingAreaDocument, options);
      }
export function useDomainsSessonStagingAreaLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DomainsSessonStagingAreaQuery, DomainsSessonStagingAreaQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DomainsSessonStagingAreaQuery, DomainsSessonStagingAreaQueryVariables>(DomainsSessonStagingAreaDocument, options);
        }
export function useDomainsSessonStagingAreaSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DomainsSessonStagingAreaQuery, DomainsSessonStagingAreaQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DomainsSessonStagingAreaQuery, DomainsSessonStagingAreaQueryVariables>(DomainsSessonStagingAreaDocument, options);
        }
export type DomainsSessonStagingAreaQueryHookResult = ReturnType<typeof useDomainsSessonStagingAreaQuery>;
export type DomainsSessonStagingAreaLazyQueryHookResult = ReturnType<typeof useDomainsSessonStagingAreaLazyQuery>;
export type DomainsSessonStagingAreaSuspenseQueryHookResult = ReturnType<typeof useDomainsSessonStagingAreaSuspenseQuery>;
export type DomainsSessonStagingAreaQueryResult = Apollo.QueryResult<DomainsSessonStagingAreaQuery, DomainsSessonStagingAreaQueryVariables>;
export const SuggestedDomainsDocument = gql`
    query SuggestedDomains($accountId: AccountId!) {
  account(id: $accountId) {
    id
    suggestedDomains
  }
}
    `;

/**
 * __useSuggestedDomainsQuery__
 *
 * To run a query within a React component, call `useSuggestedDomainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestedDomainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestedDomainsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useSuggestedDomainsQuery(baseOptions: Apollo.QueryHookOptions<SuggestedDomainsQuery, SuggestedDomainsQueryVariables> & ({ variables: SuggestedDomainsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuggestedDomainsQuery, SuggestedDomainsQueryVariables>(SuggestedDomainsDocument, options);
      }
export function useSuggestedDomainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuggestedDomainsQuery, SuggestedDomainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuggestedDomainsQuery, SuggestedDomainsQueryVariables>(SuggestedDomainsDocument, options);
        }
export function useSuggestedDomainsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SuggestedDomainsQuery, SuggestedDomainsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SuggestedDomainsQuery, SuggestedDomainsQueryVariables>(SuggestedDomainsDocument, options);
        }
export type SuggestedDomainsQueryHookResult = ReturnType<typeof useSuggestedDomainsQuery>;
export type SuggestedDomainsLazyQueryHookResult = ReturnType<typeof useSuggestedDomainsLazyQuery>;
export type SuggestedDomainsSuspenseQueryHookResult = ReturnType<typeof useSuggestedDomainsSuspenseQuery>;
export type SuggestedDomainsQueryResult = Apollo.QueryResult<SuggestedDomainsQuery, SuggestedDomainsQueryVariables>;