import React from 'react';

import type CK from '~/types/contentking';

import {
	useActivatePremiumTrialMutation,
} from './useActivatePremiumTrial.gql';

import useRequestAdminConfirmationIfApplicable from '~/hooks/useRequestAdminConfirmationIfApplicable';



type ReturnType = () => Promise<void>;



function useActivatePremiumTrial(accountId: CK.AccountId | null): ReturnType {
	const requestAdminConfirmationIfApplicable = useRequestAdminConfirmationIfApplicable(accountId);

	const [activatePremiumTrial] = useActivatePremiumTrialMutation();

	return React.useCallback(
		async () => {
			if (accountId === null) {
				throw new Error(
					`accountId isn't ready yet`,
				);
			}

			await requestAdminConfirmationIfApplicable();

			await activatePremiumTrial({
				variables: {
					accountId,
				},
			});
		},
		[
			accountId,
			activatePremiumTrial,
			requestAdminConfirmationIfApplicable,
		],
	);
}



export default useActivatePremiumTrial;
