import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import IncidentBoxFeedbackFooter from '~/components/patterns/incidents/IncidentBoxFeedbackFooter';
import IncidentFeedbackForm from '~/components/atoms/forms/forms/IncidentFeedbackForm';

import {
	useProvideFeedbackMutation,
} from './FeedbackControls.gql';

import useUrlState from '~/hooks/useUrlState';

import {
	navigate,
} from '~/routing/router';

import decodeFromBase64 from '~/utilities/decodeFromBase64';



const messages = defineMessages({
	question: {
		id: 'ui.alerts.feedback.questionLabel',
	},
	thanksInFooter: {
		id: 'ui.alerts.feedback.thanksGeneric',
	},
	thanksOnAnswer: {
		id: 'ui.alerts.feedback.thanksOnAnswer',
	},
});



const STATUS_ANSWERED = 'answered';
const STATUS_FEEDBACK_FORM = 'feedback-form';
const STATUS_QUESTION = 'question';
const STATUS_THANKS = 'thanks';



type Props = {
	incident: any,
};

const FeedbackControls: React.FC<Props> = (props) => {
	const {
		incident,
	} = props;

	const urlState = useUrlState();

	const [status, setStatus] = React.useState(
		() => incident.getIn(['feedback', 'is_provided'])
			? STATUS_ANSWERED
			: STATUS_QUESTION,
	);

	const [provideFeedback] = useProvideFeedbackMutation();

	// const handleCancelDetailedFeedback = React.useCallback(
	// 	async () => {
	// 		await provideFeedback({
	// 			variables: {
	// 				explanation: '',
	// 				subject: decodeFromBase64(incident.getIn(['feedback', 'subject'])),
	// 				verdict: false,
	// 			},
	// 		});

	// 		setStatus(STATUS_THANKS);

	// 		setTimeout(
	// 			() => setStatus(STATUS_ANSWERED),
	// 			2000,
	// 		);
	// 	},
	// 	[
	// 		incident,
	// 		provideFeedback,
	// 	],
	// );

	const handleSubmitDetailedFeedback = React.useCallback(
		async (values) => {
			await provideFeedback({
				variables: {
					explanation: values.feedback,
					subject: decodeFromBase64(incident.getIn(['feedback', 'subject'])),
					verdict: false,
				},
			});

			setStatus(STATUS_THANKS);

			setTimeout(
				() => setStatus(STATUS_ANSWERED),
				2000,
			);
		},
		[
			incident,
			provideFeedback,
		],
	);

	const handleSubmitThumbDown = React.useCallback(
		(e: React.MouseEvent | null = null) => {
			if (e !== null) {
				e.preventDefault();
			}

			setStatus(STATUS_FEEDBACK_FORM);
		},
		[],
	);

	const handleSubmitThumbUp = React.useCallback(
		async (e: React.MouseEvent | null = null) => {
			if (e !== null) {
				e.preventDefault();
			}

			await provideFeedback({
				variables: {
					explanation: '',
					subject: decodeFromBase64(incident.getIn(['feedback', 'subject'])),
					verdict: true,
				},
			});

			setStatus(STATUS_THANKS);

			setTimeout(
				() => setStatus(STATUS_ANSWERED),
				2000,
			);
		},
		[
			incident,
			provideFeedback,
		],
	);

	React.useEffect(
		() => {
			const alertId = urlState.params.alert;
			const urlTrigger = urlState.params.is_helpful;

			if (alertId && incident.get('id') === parseInt(alertId, 10)) {
				navigate('website.alerts', {
					alert: urlState.params.alert,
					websiteId: urlState.params.websiteId,
				}, {
					replace: true,
				});

				if (urlTrigger === 'true' || parseInt(urlTrigger, 10) === 1) {
					handleSubmitThumbUp(null);
				} else if (urlTrigger === 'false' || parseInt(urlTrigger, 10) === 0) {
					handleSubmitThumbDown(null);
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	const renderOverlay = () => {
		if (status === STATUS_FEEDBACK_FORM) {
			return (
				<IncidentFeedbackForm
					onSubmitCallback={handleSubmitDetailedFeedback}
				/>
			);
		}

		if (status === STATUS_QUESTION || status === STATUS_THANKS || status === STATUS_ANSWERED) {
			return (
				<CalloutMessage
					centeredContent={true}
					message={(
						<FormattedMessage {...messages.thanksOnAnswer} />
					)}
					status={CalloutMessageStatus.Success}
				/>
			);
		}

		return false;
	};

	let label: React.ReactNode;

	if (status === STATUS_QUESTION || status === STATUS_FEEDBACK_FORM || status === STATUS_THANKS) {
		label = (
			<FormattedMessage {...messages.question} />
		);
	} else {
		label = (
			<FormattedMessage {...messages.thanksOnAnswer} />
		);
	}

	return (
		<IncidentBoxFeedbackFooter
			active={status !== STATUS_ANSWERED}
			label={label}
			onNegativeFeedbackClickCallback={handleSubmitThumbDown}
			onPositiveFeedbackClickCallback={handleSubmitThumbUp}
			overlay={renderOverlay()}
			overlayVisible={status === STATUS_FEEDBACK_FORM || status === STATUS_THANKS}
		/>
	);
};



export default FeedbackControls;
