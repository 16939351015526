/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type KingdomSettingsTrialAbuserEmailsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type KingdomSettingsTrialAbuserEmailsQuery = { readonly __typename?: 'Query', readonly kingdomSettings: { readonly __typename?: 'KingdomSettings', readonly trialAbuserEmails: ReadonlyArray<string> } | null };


export const KingdomSettingsTrialAbuserEmailsDocument = gql`
    query KingdomSettingsTrialAbuserEmails {
  kingdomSettings {
    trialAbuserEmails
  }
}
    `;

/**
 * __useKingdomSettingsTrialAbuserEmailsQuery__
 *
 * To run a query within a React component, call `useKingdomSettingsTrialAbuserEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useKingdomSettingsTrialAbuserEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useKingdomSettingsTrialAbuserEmailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useKingdomSettingsTrialAbuserEmailsQuery(baseOptions?: Apollo.QueryHookOptions<KingdomSettingsTrialAbuserEmailsQuery, KingdomSettingsTrialAbuserEmailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<KingdomSettingsTrialAbuserEmailsQuery, KingdomSettingsTrialAbuserEmailsQueryVariables>(KingdomSettingsTrialAbuserEmailsDocument, options);
      }
export function useKingdomSettingsTrialAbuserEmailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<KingdomSettingsTrialAbuserEmailsQuery, KingdomSettingsTrialAbuserEmailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<KingdomSettingsTrialAbuserEmailsQuery, KingdomSettingsTrialAbuserEmailsQueryVariables>(KingdomSettingsTrialAbuserEmailsDocument, options);
        }
export function useKingdomSettingsTrialAbuserEmailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<KingdomSettingsTrialAbuserEmailsQuery, KingdomSettingsTrialAbuserEmailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<KingdomSettingsTrialAbuserEmailsQuery, KingdomSettingsTrialAbuserEmailsQueryVariables>(KingdomSettingsTrialAbuserEmailsDocument, options);
        }
export type KingdomSettingsTrialAbuserEmailsQueryHookResult = ReturnType<typeof useKingdomSettingsTrialAbuserEmailsQuery>;
export type KingdomSettingsTrialAbuserEmailsLazyQueryHookResult = ReturnType<typeof useKingdomSettingsTrialAbuserEmailsLazyQuery>;
export type KingdomSettingsTrialAbuserEmailsSuspenseQueryHookResult = ReturnType<typeof useKingdomSettingsTrialAbuserEmailsSuspenseQuery>;
export type KingdomSettingsTrialAbuserEmailsQueryResult = Apollo.QueryResult<KingdomSettingsTrialAbuserEmailsQuery, KingdomSettingsTrialAbuserEmailsQueryVariables>;