import React from 'react';
import {
	isFragment,
} from 'react-is';
import times from 'lodash/times';
import flattenChildren from 'react-keyed-flatten-children';

import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import FormWrapperHeader from '~/components/patterns/forms/wrappers/FormWrapperHeader.part';
import NewTable, {
	type Props as NewTableProps,
} from '~/components/patterns/tables/newTable/NewTable';
import NewTableCell, {
	NewTableCellSize,
	NewTableCellType,
} from '~/components/patterns/tables/newTable/NewTableCell';
import NewTableRow from '~/components/patterns/tables/newTable/NewTableRow';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';

import {
	renderProp,
} from '~/utilities/renderProp';



const EmptyRowPlaceholderRows = times(100, (i) => ({ id: i }));

type Props<
	IsSortable extends boolean,
	Row extends (IsSortable extends true ? { id: number | string } : {}),
> = {
	blankSlate?: React.ReactNode,
	buttons?: Array<React.ReactNode> | React.ReactNode,
	children: NewTableProps<IsSortable, Row>['children'],
	columnLabels: Array<React.ReactNode> | React.ReactNode,
	columnWidths: Array<number | `${number}%` | 'auto'>,
	emptyRowCount?: number,
	height: NewTableProps<IsSortable, Row>['height'],
	isLoading?: boolean,
	isSortable?: IsSortable,
	onRowClick?: (row: Row) => void,
	onSort?: NewTableProps<IsSortable, Row>['onSort'],
	overlay?: NewTableProps<IsSortable, Row>['overlay'],
	rows: NewTableProps<IsSortable, Row>['rows'],
	title?: React.ReactNode,
	width: NewTableProps<IsSortable, Row>['width'],
};

function ManagementTable<
	IsSortable extends boolean,
	Row extends (IsSortable extends true ? { id: number | string } : {}),
>(props: Props<IsSortable, Row>) {
	const {
		blankSlate = null,
		buttons = null,
		children,
		columnLabels,
		columnWidths,
		emptyRowCount = 5,
		height,
		isLoading,
		isSortable,
		onRowClick = null,
		onSort,
		overlay = null,
		rows,
		title = null,
		width,
	} = props;

	const isEmpty = blankSlate !== null;

	const renderHeader = React.useCallback(
		() => {
			if (buttons === null && title === null) {
				return null;
			}

			return (
				<FormWrapperHeader
					ctaElement={(buttons !== null && (
						<ButtonsGroup>
							{buttons}
						</ButtonsGroup>
					))}
					title={title}
				/>
			);
		},
		[
			buttons,
			title,
		],
	);

	const renderHeaderRow = React.useCallback(
		() => {
			if (isEmpty) {
				return null;
			}

			const labels = flattenChildren(columnLabels, 1);

			return columnWidths.map((_, columnIndex) => {
				const label = labels[columnIndex];
				return (
					<NewTableCell
						key={(React.isValidElement(label) && label.key) || columnIndex}
						type={NewTableCellType.Header}
					>
						<TableLabel label={label} />
					</NewTableCell>
				);
			});
		},
		[
			columnLabels,
			columnWidths,
			isEmpty,
		],
	);

	const renderLoadingRow = React.useCallback(
		({ rowIndex }) => (
			<NewTableRow id="loading">
				{columnWidths.map((_, columnIndex) => (
					<NewTableCell key={`${rowIndex}-${columnIndex}`}>
						<SquareSkeleton rowIndex={rowIndex} />
					</NewTableCell>
				))}
			</NewTableRow>
		),
		[
			columnWidths,
		],
	);

	const renderEmptyRow = React.useCallback(
		() => <></>,
		[],
	);

	const renderRow = React.useCallback(
		((rowProps) => {
			const child = renderProp(children, rowProps);

			const onClick = (
				onRowClick !== null
					? (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
						event.preventDefault();

						onRowClick(rowProps.row);
					}
					: undefined
			);

			if (isFragment(child)) {
				return (
					<NewTableRow
						id={rowProps.id}
						key={rowProps.id}
						onClick={onClick}
					>
						{flattenChildren(child, 1).map((cell, index) => (
							<NewTableCell
								key={(React.isValidElement(cell) && cell.key) || index}
								size={NewTableCellSize.Small}
							>
								{cell}
							</NewTableCell>
						))}
					</NewTableRow>
				);
			}

			return child as unknown as React.ReactElement;
		}),
		[
			children,
			onRowClick,
		],
	);

	return (
		<NewTable
			columnWidths={columnWidths}
			header={renderHeader()}
			headerRow={renderHeaderRow()}
			height={height}
			isSortable={!isLoading && !isEmpty && isSortable}
			onSort={onSort}
			overlay={(isLoading === false ? blankSlate : null) ?? overlay}
			rowHeight={42}
			rows={(
				isEmpty || isLoading
					? (EmptyRowPlaceholderRows as Array<Row>).slice(0, emptyRowCount)
					: rows
			)}
			width={width}
		>
			{isLoading ? (
				renderLoadingRow
			) : isEmpty ? (
				renderEmptyRow
			) : (
				renderRow
			)}
		</NewTable>
	);
}



export default ManagementTable;
