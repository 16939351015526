/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserOrganizationTeamQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserOrganizationTeamQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly organizationTeam: string | null } | null };


export const UserOrganizationTeamDocument = gql`
    query UserOrganizationTeam($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    id
    legacyId
    organizationTeam
  }
}
    `;

/**
 * __useUserOrganizationTeamQuery__
 *
 * To run a query within a React component, call `useUserOrganizationTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrganizationTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrganizationTeamQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserOrganizationTeamQuery(baseOptions: Apollo.QueryHookOptions<UserOrganizationTeamQuery, UserOrganizationTeamQueryVariables> & ({ variables: UserOrganizationTeamQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserOrganizationTeamQuery, UserOrganizationTeamQueryVariables>(UserOrganizationTeamDocument, options);
      }
export function useUserOrganizationTeamLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserOrganizationTeamQuery, UserOrganizationTeamQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserOrganizationTeamQuery, UserOrganizationTeamQueryVariables>(UserOrganizationTeamDocument, options);
        }
export function useUserOrganizationTeamSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserOrganizationTeamQuery, UserOrganizationTeamQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserOrganizationTeamQuery, UserOrganizationTeamQueryVariables>(UserOrganizationTeamDocument, options);
        }
export type UserOrganizationTeamQueryHookResult = ReturnType<typeof useUserOrganizationTeamQuery>;
export type UserOrganizationTeamLazyQueryHookResult = ReturnType<typeof useUserOrganizationTeamLazyQuery>;
export type UserOrganizationTeamSuspenseQueryHookResult = ReturnType<typeof useUserOrganizationTeamSuspenseQuery>;
export type UserOrganizationTeamQueryResult = Apollo.QueryResult<UserOrganizationTeamQuery, UserOrganizationTeamQueryVariables>;