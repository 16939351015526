/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageHealthQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageHealthQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly pageTypeData: { readonly __typename?: 'PageTypeData', readonly health: number } | null } | null };


export const PageHealthDocument = gql`
    query PageHealth($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    pageTypeData {
      health
    }
  }
}
    `;

/**
 * __usePageHealthQuery__
 *
 * To run a query within a React component, call `usePageHealthQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageHealthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageHealthQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageHealthQuery(baseOptions: Apollo.QueryHookOptions<PageHealthQuery, PageHealthQueryVariables> & ({ variables: PageHealthQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageHealthQuery, PageHealthQueryVariables>(PageHealthDocument, options);
      }
export function usePageHealthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageHealthQuery, PageHealthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageHealthQuery, PageHealthQueryVariables>(PageHealthDocument, options);
        }
export function usePageHealthSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PageHealthQuery, PageHealthQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageHealthQuery, PageHealthQueryVariables>(PageHealthDocument, options);
        }
export type PageHealthQueryHookResult = ReturnType<typeof usePageHealthQuery>;
export type PageHealthLazyQueryHookResult = ReturnType<typeof usePageHealthLazyQuery>;
export type PageHealthSuspenseQueryHookResult = ReturnType<typeof usePageHealthSuspenseQuery>;
export type PageHealthQueryResult = Apollo.QueryResult<PageHealthQuery, PageHealthQueryVariables>;