/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountFeaturesUsageQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountFeaturesUsageQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null };


export const AccountFeaturesUsageDocument = gql`
    query AccountFeaturesUsage($accountId: AccountId!) {
  account(id: $accountId) {
    id
    features {
      feature
      id
      inUse
    }
  }
}
    `;

/**
 * __useAccountFeaturesUsageQuery__
 *
 * To run a query within a React component, call `useAccountFeaturesUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountFeaturesUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountFeaturesUsageQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountFeaturesUsageQuery(baseOptions: Apollo.QueryHookOptions<AccountFeaturesUsageQuery, AccountFeaturesUsageQueryVariables> & ({ variables: AccountFeaturesUsageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountFeaturesUsageQuery, AccountFeaturesUsageQueryVariables>(AccountFeaturesUsageDocument, options);
      }
export function useAccountFeaturesUsageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountFeaturesUsageQuery, AccountFeaturesUsageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountFeaturesUsageQuery, AccountFeaturesUsageQueryVariables>(AccountFeaturesUsageDocument, options);
        }
export function useAccountFeaturesUsageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountFeaturesUsageQuery, AccountFeaturesUsageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountFeaturesUsageQuery, AccountFeaturesUsageQueryVariables>(AccountFeaturesUsageDocument, options);
        }
export type AccountFeaturesUsageQueryHookResult = ReturnType<typeof useAccountFeaturesUsageQuery>;
export type AccountFeaturesUsageLazyQueryHookResult = ReturnType<typeof useAccountFeaturesUsageLazyQuery>;
export type AccountFeaturesUsageSuspenseQueryHookResult = ReturnType<typeof useAccountFeaturesUsageSuspenseQuery>;
export type AccountFeaturesUsageQueryResult = Apollo.QueryResult<AccountFeaturesUsageQuery, AccountFeaturesUsageQueryVariables>;