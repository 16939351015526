import React from 'react';

import type CK from '~/types/contentking';

import LoadingDots, {
	LoadingDotsSize,
} from '~/components/patterns/loaders/LoadingDots';
import ScreenOverlay, {
	ScreenOverlayStyle,
} from '~/components/patterns/screens/parts/ScreenOverlay';
import SnapshotHeaders from '~/components/app/SnapshotHeaders';

import {
	useHeadersSnapshotQuery,
} from './PageSnapshotHeaders.gql';



type Props = {
	timestamp: CK.Timestamp,
	urlId: CK.UrlId,
	websiteId: CK.WebsiteId,
	height: number,
	url: string | null,
};

const PageSnapshotHeaders: React.FC<Props> = (props) => {
	const {
		timestamp,
		urlId,
		websiteId,
		height,
		url,
	} = props;

	const {
		data,
		loading,
	} = useHeadersSnapshotQuery({
		variables: {
			timestamp,
			urlId,
			websiteId,
		},
		fetchPolicy: 'cache-first',
	});

	const snapshot = data?.responseSnapshot ?? null;

	if (snapshot === null && loading) {
		return (
			<div style={{ height }}>
				<ScreenOverlay style={ScreenOverlayStyle.Light}>
					<LoadingDots size={LoadingDotsSize.Large} />
				</ScreenOverlay>
			</div>
		);
	}

	return (
		<div style={{ overflowY: 'scroll', height }}>
			<SnapshotHeaders
				requestHttpHeaders={snapshot?.requestHeaders}
				responseFailureReason={snapshot?.responseFailureReason}
				responseHttpHeaders={snapshot?.responseHeaders}
				responsePrimaryIp={snapshot?.responsePrimaryIp}
				responseStatusCode={snapshot?.responseStatusCode}
				timestamp={timestamp}
				url={url ?? ''}
			/>
		</div>
	);
};



export default PageSnapshotHeaders;
