import {
	Map,
} from 'immutable';

import {
	STATE_LOADED,
	STATE_NOT_LOADED,
	createFullId,
} from '~/model/pages';
import {
	trimHostname,
} from '~/model//utils';



const formatDisplayUrl = (url) => url
	? trimHostname(url)
	: undefined;



const formatRelevance = (relevance) => relevance !== null
	? parseFloat(relevance).toFixed(2)
	: null;



const createDataStatus = (data) => {
	const dataStatus = {};
	for (const field in data) {
		if (data[field] === undefined) {
			dataStatus[field] = STATE_NOT_LOADED;
		} else {
			dataStatus[field] = STATE_LOADED;
		}
	}

	return dataStatus;
};



export function createPageFromData(id, domainId, data, fullId) {
	const formattedData = data || {};

	formattedData.id = id.toString();
	formattedData.domainId = domainId;
	formattedData.fullId = fullId || createFullId(domainId, id);

	if (data) {
		formattedData.displayUrl = formatDisplayUrl(data.url);
		formattedData.url = data.url;
		if (data.relevance !== undefined) {
			formattedData.relevance = formatRelevance(data.relevance);
		}
	}

	formattedData._status = new Map(
		createDataStatus(formattedData),
	);

	return new Map(formattedData);
}



export function updatePageFromData(id, domainId, data, fullId) {
	const formattedData = {};

	formattedData.fullId = fullId || createFullId(domainId, id);

	if (data.url !== undefined) {
		formattedData.displayUrl = formatDisplayUrl(data.url);
	}

	if (data.type !== undefined) {
		formattedData.type = data.type;
	}

	delete data.type;

	if (data.relevance !== undefined) {
		formattedData.relevance = formatRelevance(data.relevance);
	}

	delete data.relevance;

	for (const field in data) {
		if (data[field] !== undefined) {
			formattedData[field] = data[field];
		}
	}

	formattedData._status = new Map(
		createDataStatus(formattedData),
	);

	return new Map(formattedData);
}
