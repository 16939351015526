import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import type GraphQL from '~/types/graphql';

import CenteredFormWrapper from '~/components/atoms/forms/components/layout/CenteredFormWrapper';
import Emphasis from '~/components/patterns/typography/Emphasis';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
	ButtonsLayoutUsageContext,
} from '~/components/patterns/buttons/ButtonsLayout';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import TesterOutput from '~/components/logic/customElements/tester/TesterOutput';
import TesterOutputError from '~/components/logic/customElements/tester/TesterOutputError';
import TextField from '~/components/atoms/forms/components/TextField';
import SubmitButton, {
	SIZE_SMALL as BUTTON_SIZE_SMALL,
	STYLE_HOLLOW as BUTTON_STYLE_HOLLOW,
} from '~/components/atoms/forms/components/SubmitButton';

import {
	useTestCustomElementExtractionMutation,
} from './CustomElementTesterForm.gql';

import useWebsiteDomain from '~/hooks/useWebsiteDomain';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	type Extraction,
} from '~/model/customElements';

import FormError from '~/utilities/FormError';



const messages = defineMessages({
	formErrorsBlank: {
		id: 'ui.formErrors.blank',
	},
	formFieldsUrlDescription: {
		id: 'ui.customElements.tester.fields.url.description',
	},
	formFieldsUrlLabel: {
		id: 'ui.customElements.tester.fields.url.label',
	},
	formFieldsUrlPlaceholder: {
		id: 'ui.customElements.tester.fields.url.placeholder',
	},
	submitButton: {
		id: 'ui.customElements.tester.submitButton.label',
	},
});

const validations = {
	url: [
		{
			message: (
				<FormattedMessage {...messages.formErrorsBlank} />
			),
			field: 'url',
			rule: ({ values, name }) => {
				return !!values[name];
			},
		},
	],
};



type Props = {
	extraction: Extraction,
	isValid: boolean,
};

const CustomElementTesterForm: React.FC<Props> = (props) => {
	const {
		extraction,
		isValid: isExtractionValid,
	} = props;

	const intl = useIntl();
	const websiteId = useWebsiteId();

	const websiteDomain = useWebsiteDomain(websiteId);

	const [result, setResult] = React.useState<{
		elementType: GraphQL.CustomElementDataType,
		url: string,
		value: any,
	} | {
		error: FormError,
	} | null>(null);

	const [testCustomElementExtraction] = useTestCustomElementExtractionMutation();

	const handleExtractionInvalidSubmit = React.useCallback(
		(e) => {
			e.preventDefault();
		},
		[],
	);

	const handleFormSubmit = React.useCallback(
		async (data) => {
			if (isExtractionValid === false) {
				return;
			}

			try {
				const result = await testCustomElementExtraction({
					variables: {
						extraction,
						url: data.url,
						websiteId,
					},
				});

				const resultData = result.data?.TestCustomElementExtraction ?? null;

				if (resultData !== null) {
					setResult({
						elementType: resultData.dataType,
						url: resultData.url,
						value: resultData.extractedValue,
					});
				}
			} catch (error) {
				error = FormError.fromApolloError(error);

				if (error.getName() === 'invalidExtraction') {
					return;
				}

				setResult({
					error,
				});
			}
		},
		[
			extraction,
			isExtractionValid,
			testCustomElementExtraction,
			websiteId,
		],
	);

	return (
		<List size={ListSize.XLarge}>
			<Form
				onSuccess={handleFormSubmit}
				validations={validations}
			>
				{({ isSubmitting, isValid }) => (
					<CenteredFormWrapper>
						<FormRow
							description={(
								<FormattedMessage
									{...messages.formFieldsUrlDescription}
									values={{
										'text__domain': websiteDomain ? (
											<Emphasis>{websiteDomain}</Emphasis>
										) : '',
									}}
								/>
							)}
							htmlFor="url"
							inline={false}
							label={(
								<FormattedMessage {...messages.formFieldsUrlLabel} />
							)}
						>
							<FieldStatus
								allowOk={false}
								name="url"
							>
								<TextField
									name="url"
									placeholder={intl.formatMessage(messages.formFieldsUrlPlaceholder)}
									width="100%"
								/>
							</FieldStatus>
						</FormRow>
						<ButtonsLayout
							alignment={ButtonsLayoutAlignment.Center}
							layout={ButtonsLayoutType.FormRow}
							usageContext={ButtonsLayoutUsageContext.Raw}
						>
							<SubmitButton
								inactive={!isExtractionValid || !isValid}
								onClickCallback={!isExtractionValid ? handleExtractionInvalidSubmit : null}
								progress={isSubmitting}
								size={BUTTON_SIZE_SMALL}
								style={BUTTON_STYLE_HOLLOW}
								uppercase={true}
							>
								<FormattedMessage {...messages.submitButton} />
							</SubmitButton>
						</ButtonsLayout>
					</CenteredFormWrapper>
				)}
			</Form>

			{result !== null && 'error' in result && (
				<TesterOutputError
					error={result.error}
				/>
			)}

			{result !== null && 'value' in result && (
				<TesterOutput
					elementType={result.elementType}
					url={result.url}
					value={result.value}
				/>
			)}
		</List>
	);
};



export default CustomElementTesterForm;
