/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageIsPrioritizedQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageIsPrioritizedQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly isPrioritized: boolean } | null };


export const PageIsPrioritizedDocument = gql`
    query PageIsPrioritized($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    isPrioritized
  }
}
    `;

/**
 * __usePageIsPrioritizedQuery__
 *
 * To run a query within a React component, call `usePageIsPrioritizedQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageIsPrioritizedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageIsPrioritizedQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageIsPrioritizedQuery(baseOptions: Apollo.QueryHookOptions<PageIsPrioritizedQuery, PageIsPrioritizedQueryVariables> & ({ variables: PageIsPrioritizedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageIsPrioritizedQuery, PageIsPrioritizedQueryVariables>(PageIsPrioritizedDocument, options);
      }
export function usePageIsPrioritizedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageIsPrioritizedQuery, PageIsPrioritizedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageIsPrioritizedQuery, PageIsPrioritizedQueryVariables>(PageIsPrioritizedDocument, options);
        }
export function usePageIsPrioritizedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PageIsPrioritizedQuery, PageIsPrioritizedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageIsPrioritizedQuery, PageIsPrioritizedQueryVariables>(PageIsPrioritizedDocument, options);
        }
export type PageIsPrioritizedQueryHookResult = ReturnType<typeof usePageIsPrioritizedQuery>;
export type PageIsPrioritizedLazyQueryHookResult = ReturnType<typeof usePageIsPrioritizedLazyQuery>;
export type PageIsPrioritizedSuspenseQueryHookResult = ReturnType<typeof usePageIsPrioritizedSuspenseQuery>;
export type PageIsPrioritizedQueryResult = Apollo.QueryResult<PageIsPrioritizedQuery, PageIsPrioritizedQueryVariables>;