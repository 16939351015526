import {
	parseISO,
} from 'date-fns';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import Copy, {
	linkInternal,
} from '~/components/logic/Copy';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import Emphasis from '~/components/patterns/typography/Emphasis';
import ExternalLink from '~/components/patterns/links/ExternalLink';
import {
	InternalLinkStyle,
} from '~/components/patterns/links/InternalLink';
import PageLastCheckedMessage from '~/components/app/PageLastCheckedMessage';
import MarginBox from '~/components/atoms/boxes/MarginBox';
import MissingValue from '~/components/app/MissingValue';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import RichText from '~/components/patterns/typography/RichText';
import SearchEngineLastVisitFormatter from '../formatters/SearchEngineLastVisitFormatter';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';

import {
	useRobotsTxtBoxQuery,
} from '~/components/logic/platform/RobotsTxtBox.gql';

import usePlatformCanonicalDomain from '~/hooks/usePlatformCanonicalDomain';
import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import usePrioritizePage from '~/hooks/usePrioritizePage';
import useWebsiteLogFileAnalysisSetup from '~/hooks/useWebsiteLogFileAnalysisSetup';

import {
	getBySearchEngine,
} from '~/model/searchEngineActivity';



const messages = defineMessages({
	blankSlateMessage: {
		id: 'ui.platform.robots.none',
	},
	bingLastVisit: {
		id: 'ui.platform.robots.bingLastVisit',
	},
	enableLogFileAnalysis: {
		id: 'ui.platform.robots.enableLogFileAnalysis',
	},
	lastChange: {
		id: 'ui.platform.robots.lastChange',
	},
	missingMessage: {
		id: 'ui.platform.robots.missing',
	},
	googleLastVisit: {
		id: 'ui.platform.robots.googleLastVisit',
	},
	title: {
		id: 'ui.platform.robots.title',
	},
	viewRobotsTxt: {
		id: 'ui.platform.robots.viewer.button',
	},
	xmlSitemapReference: {
		id: 'ui.platform.robots.sitemapReference',
	},
	url: {
		id: 'ui.platform.robots.url',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const RobotsTxtBox: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const canonicalDomain = usePlatformCanonicalDomain(websiteId);

	const { data } = useRobotsTxtBoxQuery({
		variables: {
			websiteId,
		},
	});

	usePrioritizePage(
		data?.platformData?.canonicalRobotsTxtLegacyUrlId ?? null,
		websiteId,
	);

	const {
		isFeatureEnabled: isLogFileAnalysisFeatureEnabled,
		isFeatureAttainable: isLogFileAnalysisFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	const {
		isLogFileAnalysisEnabled,
		hasAvailableLogSources,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	const isLoading = (data?.platformData ?? null) === null;

	const robotsOnWebsite = (data?.platformData?.robotsTxtUrls.length ?? 0) > 0;
	const hasMultipleRevisions = data?.list ? data.list.length > 1 : false;

	const showRobotsTxtViewerButton = (
		robotsOnWebsite
		|| hasMultipleRevisions
	);

	const robotsTxtLastChangedAt = React.useMemo(
		() => data?.list?.[0]
			? parseISO(data.list[0].createdAt)
			: null,
		[
			data,
		],
	);

	const showLogFileAnalysisSetupLink = (
		!isLogFileAnalysisEnabled
		&& isLogFileAnalysisFeatureEnabled
		&& hasAvailableLogSources
	);

	const showSearchEngineLastVisits = (
		(
			isLogFileAnalysisEnabled
			|| (isLogFileAnalysisFeatureEnabled && hasAvailableLogSources)
			|| (isLogFileAnalysisFeatureAttainable && hasAvailableLogSources)
		) && (
			!showLogFileAnalysisSetupLink
		)
	);

	function renderUrls() {
		if (canonicalDomain === null) {
			return [];
		}

		const robotsTxtUrls = [...(data?.platformData?.robotsTxtUrls ?? [])];

		robotsTxtUrls.sort((robotsTxtUrlA, robotsTxtUrlB) => {
			if (robotsTxtUrlA.indexOf(canonicalDomain) === 0) {
				return -1;
			} else if (robotsTxtUrlB.indexOf(canonicalDomain) === 0) {
				return 1;
			}

			return 0;
		});

		return robotsTxtUrls.map((robotsTxtUrl, i) => (
			<ExternalLink
				breakLines={true}
				href={robotsTxtUrl}
				key={i}
			>
				{robotsTxtUrl}
			</ExternalLink>
		));
	}

	function renderXmlSitemapReference() {
		const sitemaps = data?.platformData?.robotsTxtXmlSitemapReference ?? [];

		if (sitemaps.length === 0) {
			return (
				<MissingValue />
			);
		}

		return sitemaps;
	}

	return (
		<MarginBox>
			<BorderedBox
				headerActionElements={(showRobotsTxtViewerButton) && (
					<Button
						linkRouteName="website.platform.robotsTxtViewer"
						linkRouteParams={{
							revisionId: 'latest',
							websiteId,
						}}
						size={ButtonSize.Small}
						style={ButtonStyle.Hollow}
						uppercase={true}
					>
						<FormattedMessage {...messages.viewRobotsTxt} />
					</Button>
				)}
				headerIcon={(
					<AttributesGroupIcon
						color="#964fa5"
						size={28}
						type={AttributesGroupIconType.RobotsTxt}
					/>
				)}
				headerLabel={(
					<FormattedMessage {...messages.title} />
				)}
				paddingSize={3}
			>
				{(isLoading || robotsOnWebsite) && (
					<DefinitionTermsList>
						<DefinitionTerm
							description={
								isLoading ? (
									<SquareSkeleton maxWidth={220} />
								) : (
									renderUrls()
								)}
							showRowNumbers={false}
							term={(
								<FormattedMessage {...messages.url} />
							)}
							visibleRowsCount={3}
						/>

						<DefinitionTerm
							description={
								isLoading ? (
									<SquareSkeleton maxWidth={50} />
								) : (
									renderXmlSitemapReference()
								)}
							showRowNumbers={false}
							term={(
								<FormattedMessage {...messages.xmlSitemapReference} />
							)}
							visibleRowsCount={3}
						/>

						{robotsTxtLastChangedAt !== null && (
							<DefinitionTerm
								description={(
									<PageLastCheckedMessage
										date={robotsTxtLastChangedAt}
									/>
								)}
								term={(
									<FormattedMessage {...messages.lastChange} />
								)}
							/>
						)}

						{showSearchEngineLastVisits && (
							<DisabledContent
								disabledContent={!isLogFileAnalysisEnabled}
								disabledOverlay={(
									<PremiumFeatureSituation
										featureName={GraphQL.AccountFeature.LogFileAnalysis}
										style={PremiumFeatureSituationStyle.Ribbon}
									>
										{({ premiumAnnotation }) => <>{premiumAnnotation}</>}
									</PremiumFeatureSituation>
								)}
							>
								<DefinitionTerm
									description={(
										<SearchEngineLastVisitFormatter
											value={getBySearchEngine(
												data?.platformData?.robotsTxtSearchEngineActivity ?? null,
												GraphQL.SearchEngineActivitySearchEngine.Google,
											)?.lastVisit ?? undefined}
										/>
									)}
									term={(
										<FormattedMessage {...messages.googleLastVisit} />
									)}
								/>
							</DisabledContent>
						)}

						{showSearchEngineLastVisits && (
							<DisabledContent
								disabledContent={!isLogFileAnalysisEnabled}
							>
								<DefinitionTerm
									description={(
										<SearchEngineLastVisitFormatter
											value={getBySearchEngine(
												data?.platformData?.robotsTxtSearchEngineActivity ?? null,
												GraphQL.SearchEngineActivitySearchEngine.Bing,
											)?.lastVisit ?? undefined}
										/>
									)}
									term={(
										<FormattedMessage {...messages.bingLastVisit} />
									)}
								/>
							</DisabledContent>
						)}

						{showLogFileAnalysisSetupLink && (
							<RichText>
								<Emphasis>
									<Copy
										{...messages.enableLogFileAnalysis}
										values={{
											linkSettings: linkInternal(
												'website.detail.logFileAnalysis',
												{ websiteId },
												{ style: InternalLinkStyle.Decent },
											),
										}}
									/>
								</Emphasis>
							</RichText>
						)}
					</DefinitionTermsList>
				)}

				{isLoading === false && robotsOnWebsite === false && (
					<BlankSlate>
						{(data?.list?.length ?? 0) > 0 ? (
							<FormattedMessage {...messages.missingMessage} />
						) : (
							<FormattedMessage {...messages.blankSlateMessage} />
						)}
					</BlankSlate>
				)}
			</BorderedBox>
		</MarginBox>
	);
};



export default RobotsTxtBox;
