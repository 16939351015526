import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';

import type FormError from '~/utilities/FormError';



const descriptionMessages = defineMessages({
	invalidDomain: {
		id: 'ui.customElements.tester.formErrors.invalidDomain.description',
	},
	invalidState: {
		id: 'ui.customElements.tester.formErrors.invalidState.description',
	},
	invalidUrl: {
		id: 'ui.customElements.tester.formErrors.invalidUrl.description',
	},
	requestFailedDueToNoResponse: {
		id: 'ui.customElements.tester.formErrors.requestFailedDueToNoResponse.description',
	},
	requestFailedDueToStatusCode: {
		id: 'ui.customElements.tester.formErrors.requestFailedDueToStatusCode.description',
	},
	unexpectedError: {
		id: 'ui.general.unexpectedError',
	},
});

const titleMessages = defineMessages({
	invalidDomain: {
		id: 'ui.customElements.tester.formErrors.invalidDomain.title',
	},
	invalidState: {
		id: 'ui.customElements.tester.formErrors.invalidState.title',
	},
	invalidUrl: {
		id: 'ui.customElements.tester.formErrors.invalidUrl.title',
	},
	requestFailedDueToNoResponse: {
		id: 'ui.customElements.tester.formErrors.requestFailedDueToNoResponse.title',
	},
	requestFailedDueToStatusCode: {
		id: 'ui.customElements.tester.formErrors.requestFailedDueToStatusCode.title',
	},
	unexpectedError: {
		id: 'ui.general.unexpectedError',
	},
});



type Props = {
	error: FormError,
};

const TesterOutputError: React.FC<Props> = (props) => {
	const {
		error,
	} = props;

	let errorType = error.getName();

	if (errorType === 'requestFailed') {
		errorType = error.getDetails().statusCode
			? 'requestFailedDueToStatusCode'
			: 'requestFailedDueToNoResponse';
	}

	let errorTitle;
	let errorDescription;

	if (errorType === 'requestFailedDueToStatusCode') {
		errorTitle = (
			<FormattedMessage
				{...titleMessages[errorType]}
				values={{
					statusCode: error.getDetails().statusCode,
				}}
			/>
		);
	} else if (titleMessages[errorType]) {
		errorTitle = (
			<FormattedMessage {...titleMessages[errorType]} />
		);
	} else {
		errorTitle = (
			<FormattedMessage {...titleMessages.unexpectedError} />
		);
	}

	if (errorType === 'requestFailedDueToNoResponse') {
		errorDescription = (
			<FormattedMessage
				{...descriptionMessages[errorType]}
				values={{
					reason: error.getDetails().failureReason,
				}}
			/>
		);
	} else if (descriptionMessages[errorType]) {
		errorDescription = (
			<FormattedMessage {...descriptionMessages[errorType]} />
		);
	}

	return (
		<CalloutMessage
			borders={true}
			message={errorTitle}
			status={CalloutMessageStatus.Critical}
		>
			{errorDescription}
		</CalloutMessage>
	);
};



export default TesterOutputError;
