/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountContextSelectQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type AccountContextSelectQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly displayName: string, readonly id: CK.AccountId }>, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }> } | null };


export const AccountContextSelectDocument = gql`
    query AccountContextSelect {
  authenticatedSession {
    accounts {
      displayName
      id
    }
    memberships {
      account {
        id
      }
      user {
        id
        legacyId
      }
    }
  }
}
    `;

/**
 * __useAccountContextSelectQuery__
 *
 * To run a query within a React component, call `useAccountContextSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountContextSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountContextSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountContextSelectQuery(baseOptions?: Apollo.QueryHookOptions<AccountContextSelectQuery, AccountContextSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountContextSelectQuery, AccountContextSelectQueryVariables>(AccountContextSelectDocument, options);
      }
export function useAccountContextSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountContextSelectQuery, AccountContextSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountContextSelectQuery, AccountContextSelectQueryVariables>(AccountContextSelectDocument, options);
        }
export function useAccountContextSelectSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountContextSelectQuery, AccountContextSelectQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountContextSelectQuery, AccountContextSelectQueryVariables>(AccountContextSelectDocument, options);
        }
export type AccountContextSelectQueryHookResult = ReturnType<typeof useAccountContextSelectQuery>;
export type AccountContextSelectLazyQueryHookResult = ReturnType<typeof useAccountContextSelectLazyQuery>;
export type AccountContextSelectSuspenseQueryHookResult = ReturnType<typeof useAccountContextSelectSuspenseQuery>;
export type AccountContextSelectQueryResult = Apollo.QueryResult<AccountContextSelectQuery, AccountContextSelectQueryVariables>;