/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteCustomElementDefinitionsQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteCustomElementDefinitionsQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly customElements: ReadonlyArray<{ readonly __typename?: 'CustomElement', readonly column: `custom_${string}`, readonly createdAt: CK.Timestamp, readonly dataType: GraphQL.CustomElementDataType, readonly extraction: Record<string, any>, readonly label: string, readonly name: string }> } | null };


export const WebsiteCustomElementDefinitionsDocument = gql`
    query WebsiteCustomElementDefinitions($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    customElements {
      column
      createdAt
      dataType
      extraction
      label
      name
    }
    id
  }
}
    `;

/**
 * __useWebsiteCustomElementDefinitionsQuery__
 *
 * To run a query within a React component, call `useWebsiteCustomElementDefinitionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteCustomElementDefinitionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteCustomElementDefinitionsQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteCustomElementDefinitionsQuery(baseOptions: Apollo.QueryHookOptions<WebsiteCustomElementDefinitionsQuery, WebsiteCustomElementDefinitionsQueryVariables> & ({ variables: WebsiteCustomElementDefinitionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteCustomElementDefinitionsQuery, WebsiteCustomElementDefinitionsQueryVariables>(WebsiteCustomElementDefinitionsDocument, options);
      }
export function useWebsiteCustomElementDefinitionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteCustomElementDefinitionsQuery, WebsiteCustomElementDefinitionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteCustomElementDefinitionsQuery, WebsiteCustomElementDefinitionsQueryVariables>(WebsiteCustomElementDefinitionsDocument, options);
        }
export function useWebsiteCustomElementDefinitionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteCustomElementDefinitionsQuery, WebsiteCustomElementDefinitionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteCustomElementDefinitionsQuery, WebsiteCustomElementDefinitionsQueryVariables>(WebsiteCustomElementDefinitionsDocument, options);
        }
export type WebsiteCustomElementDefinitionsQueryHookResult = ReturnType<typeof useWebsiteCustomElementDefinitionsQuery>;
export type WebsiteCustomElementDefinitionsLazyQueryHookResult = ReturnType<typeof useWebsiteCustomElementDefinitionsLazyQuery>;
export type WebsiteCustomElementDefinitionsSuspenseQueryHookResult = ReturnType<typeof useWebsiteCustomElementDefinitionsSuspenseQuery>;
export type WebsiteCustomElementDefinitionsQueryResult = Apollo.QueryResult<WebsiteCustomElementDefinitionsQuery, WebsiteCustomElementDefinitionsQueryVariables>;