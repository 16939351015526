import React from 'react';

import ArrayGroupField, {
	type ArrayGroupFieldRendererProps,
	type ArrayGroupFieldValidationInput,
} from '~/components/patterns/forms/fields/ArrayGroupField';
import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CalloutMessage, {
	CalloutMessageStatus,
} from '~/components/patterns/messages/embedded/CalloutMessage';
import CodeValue from '~/components/patterns/values/CodeValue';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import DisabledContent from '~/components/patterns/content/DisabledContent';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import MarginsList from '~/components/atoms/lists/MarginsList';
import NamedValue from '~/components/patterns/structuredValues/NamedValue';
import SimpleBox from '~/components/patterns/boxes/SimpleBox';
import SubmitButton from '~/components/app/SubmitButton';
import TextArea from '~/components/atoms/forms/components/TextArea';

import {
	validateField,
} from '~/components/app/validations';

import {
	useValidateSchemaOrgMutation,
} from './AdminSchemaOrgValidator.gql';



const validations = {
	urlLines: validateField(
		'schemaOrgJsons',
		(f) => [
			f.validateMinimumLength(1),
		],
	),
};



const AdminSchemaOrgValidator: React.FC = () => {
	const [errors, setErrors] = React.useState<ReadonlyArray<{
		element: number,
		message: string,
		path: string,
	}> | null>(null);

	const [validateSchemaOrg] = useValidateSchemaOrgMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			const { data } = await validateSchemaOrg({
				variables: {
					schemaOrgJsons: values.schemaOrgJsons.map(
						(schemaOrgJson) => schemaOrgJson.element,
					),
				},
			});

			setErrors(
				data?.ValidateSchemaOrg.errors ?? [],
			);
		},
		[
			validateSchemaOrg,
		],
	);

	const fields = React.useMemo(
		() => {
			return [
				{
					label: 'Element',
					name: 'element',
					renderer: ({ fieldName }: ArrayGroupFieldRendererProps) => (
						<TextArea
							autoComplete={false}
							height={400}
							name={fieldName}
							placeholder="{ ... }"
							resizable={false}
							spellCheck={false}
							width="100%"
						/>
					),
					validation: ({ f }: ArrayGroupFieldValidationInput) => ([
						f.validateNonEmpty(),
						f.validateJson(),
					]),
				},
			];
		},
		[],
	);

	return (
		<Form
			defaultFocus="schemaOrgJsons"
			defaultValues={{
				schemaOrgJsons: [],
			}}
			onSuccess={handleSubmit}
			validations={validations}
		>
			{({ isSubmitting }) => (
				<MarginsList>
					<FormRow
						fullwidth={true}
						htmlFor="schemaOrgJsons"
					>
						<ArrayGroupField
							addButtonLabel="Add element"
							fields={fields}
							minimumRows={1}
							name="schemaOrgJsons"
							rowHeight={420}
							showRowNumbers={true}
						/>
					</FormRow>

					{errors !== null && (
						<DisabledContent disabledContent={isSubmitting}>
							<SimpleBox>
								{errors.length > 0 ? (
									<DefinitionTermsList>
										{errors.map(
											(error) => (
												<DefinitionTerm
													description={[
														<NamedValue
															key="path"
															name="Path"
														>
															<CodeValue>{error.path}</CodeValue>
														</NamedValue>,
														<NamedValue
															key="error"
															name="Error"
														>
															<CodeValue>{error.message}</CodeValue>
														</NamedValue>,
													]}
													key={error.element}
													showRowNumbers={false}
													term={(
														<NamedValue name={`Element #${error.element}`} />
													)}
												/>
											),
										)}
									</DefinitionTermsList>
								) : (
									<CalloutMessage
										message="All valid"
										status={CalloutMessageStatus.Success}
									/>
								)}
							</SimpleBox>
						</DisabledContent>
					)}

					<ButtonsLayout>
						<SubmitButton>
							Validate
						</SubmitButton>
					</ButtonsLayout>
				</MarginsList>
			)}
		</Form>
	);
};



export default AdminSchemaOrgValidator;
