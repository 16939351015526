import {
	formatDateForBackend,
	formatEndDateForBackend,
} from '~/model/historicalChanges';



export enum WidgetType {
	CoreWebVitals = 'core-web-vitals',
	CumulativeLayoutShift = 'cumulative-layout-shift',
	Health = 'health',
	HealthChanges = 'health-changes',
	InteractionToNextPaint = 'interaction-to-next-paint',
	Indexable = 'indexable',
	IssueChanges = 'issue-changes',
	Issues = 'issues',
	LargestContentfulPaint = 'largest-contentful-paint',
	OpenIncidents = 'open-incidents',
	PageChanges = 'page-changes',
	PageType = 'page-type',
	Pages = 'pages',
}

export type DateRange = {
	end: Date,
	start: Date,
};



export function formatDateForQuery(
	dateRange: DateRange,
) {
	return {
		date: formatEndDateForBackend(dateRange.start, dateRange.end),
	};
}



export function formatDateRangeForQuery(
	dateRange: DateRange,
) {
	return {
		startDate: formatDateForBackend(dateRange.start),
		endDate: formatEndDateForBackend(dateRange.start, dateRange.end),
	};
}

