/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateSubscriptionInLegacyPackageFormMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  billingCycle: GraphQL.Term;
  pageBundle: GraphQL.Scalars['Int']['input'];
  plan: GraphQL.AccountPlan;
  tariff: GraphQL.Scalars['String']['input'];
}>;


export type UpdateSubscriptionInLegacyPackageFormMutation = { readonly __typename?: 'Mutation', readonly UpdateSubscription: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly billingCycle: GraphQL.Term, readonly pageBundle: number | null, readonly plan: GraphQL.AccountPlan | null, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID }> } | null } } };


export const UpdateSubscriptionInLegacyPackageFormDocument = gql`
    mutation UpdateSubscriptionInLegacyPackageForm($accountId: AccountId!, $billingCycle: Term!, $pageBundle: Int!, $plan: AccountPlan!, $tariff: String!) {
  UpdateSubscription(
    accountId: $accountId
    billingCycle: $billingCycle
    pageBundle: $pageBundle
    plan: $plan
    tariff: $tariff
  ) {
    query {
      account(id: $accountId) {
        id
        billingCycle
        features {
          feature
          id
        }
        pageBundle
        plan
      }
    }
  }
}
    `;
export type UpdateSubscriptionInLegacyPackageFormMutationFn = Apollo.MutationFunction<UpdateSubscriptionInLegacyPackageFormMutation, UpdateSubscriptionInLegacyPackageFormMutationVariables>;

/**
 * __useUpdateSubscriptionInLegacyPackageFormMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionInLegacyPackageFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionInLegacyPackageFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionInLegacyPackageFormMutation, { data, loading, error }] = useUpdateSubscriptionInLegacyPackageFormMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      billingCycle: // value for 'billingCycle'
 *      pageBundle: // value for 'pageBundle'
 *      plan: // value for 'plan'
 *      tariff: // value for 'tariff'
 *   },
 * });
 */
export function useUpdateSubscriptionInLegacyPackageFormMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubscriptionInLegacyPackageFormMutation, UpdateSubscriptionInLegacyPackageFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubscriptionInLegacyPackageFormMutation, UpdateSubscriptionInLegacyPackageFormMutationVariables>(UpdateSubscriptionInLegacyPackageFormDocument, options);
      }
export type UpdateSubscriptionInLegacyPackageFormMutationHookResult = ReturnType<typeof useUpdateSubscriptionInLegacyPackageFormMutation>;
export type UpdateSubscriptionInLegacyPackageFormMutationResult = Apollo.MutationResult<UpdateSubscriptionInLegacyPackageFormMutation>;
export type UpdateSubscriptionInLegacyPackageFormMutationOptions = Apollo.BaseMutationOptions<UpdateSubscriptionInLegacyPackageFormMutation, UpdateSubscriptionInLegacyPackageFormMutationVariables>;