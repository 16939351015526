import {
	useDroppable,
} from '@dnd-kit/core';
import classNames from 'classnames';
import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import DraggableSegmentDefinitionIdentifier from './DraggableSegmentDefinitionIdentifier';
import SegmentsList from '~/components/atoms/segments/SegmentsList';

import {
	type SegmentDefinition,
} from '~/model/segments';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	blankSlate: {
		id: 'ui.segments.selection.hint.drag',
	},
	includeHint: {
		id: 'ui.segments.selection.hint.drop.include',
	},
	removeHint: {
		id: 'ui.segments.selection.hint.drop.remove',
	},
});

export enum SegmentSelectionSectionType {
	Add = 'add',
	Remove = 'remove',
}



type Props = {
	headerAppendix?: React.ReactNode | null,
	name: string,
	onSegmentClick?: (segmentName: CK.PageSegmentName) => void,
	segments: ReadonlyArray<SegmentDefinition>,
	segmentsNotAllowedForFiltering: Record<string, React.ReactNode> | undefined,
	title: React.ReactNode,
	type: SegmentSelectionSectionType,
};

const SegmentSelectionSection: React.FC<Props> = (props: Props) => {
	const {
		headerAppendix = null,
		name,
		onSegmentClick,
		segments,
		segmentsNotAllowedForFiltering,
		title,
		type,
	} = props;

	const {
		setNodeRef,
		active,
	} = useDroppable({
		id: name,
	});

	let content: React.ReactNode = undefined;
	if (segments.length > 0) {
		content = (
			<div className="segment-selection-section__content">
				<SegmentsList>
					{segments.map((segment) => (
						<DraggableSegmentDefinitionIdentifier
							disabled={segmentsNotAllowedForFiltering?.[segment.name]}
							key={segment.name}
							name={segment.name}
							onClick={onSegmentClick}
							segmentDefinition={segment}
						/>
					))}
				</SegmentsList>
			</div>
		);
	} else {
		content = (
			<div className="segment-selection-section__blank-slate">
				<FormattedMessage {...messages.blankSlate} />
			</div>
		);
	}

	return (
		<div
			className="segment-selection-section"
			ref={setNodeRef}
		>
			<div className="segment-selection-section__header">
				<div className="segment-selection-section__title">
					{title}
				</div>

				{headerAppendix !== null && (
					<div className="segment-selection-section__header-appendix">
						{headerAppendix}
					</div>
				)}
			</div>

			<div className="segment-selection-section__scrollable-area js-scrollable">
				{content}

				{active !== null && (
					<div className="segment-selection-section__can-drop-info">
						<div
							className={classNames({
								'dashed-box': true,
								'dashed-box--info': type === SegmentSelectionSectionType.Add,
							})}
						>
							{matchAndReturn(type, {
								[SegmentSelectionSectionType.Add]: (
									<FormattedMessage {...messages['includeHint']} />
								),
								[SegmentSelectionSectionType.Remove]: (
									<FormattedMessage {...messages['removeHint']} />
								),
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};



export default SegmentSelectionSection;
