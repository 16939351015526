/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountDisplayNamesQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type AccountDisplayNamesQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly displayName: string, readonly id: CK.AccountId }> } | null };


export const AccountDisplayNamesDocument = gql`
    query AccountDisplayNames {
  authenticatedSession {
    accounts {
      displayName
      id
    }
  }
}
    `;

/**
 * __useAccountDisplayNamesQuery__
 *
 * To run a query within a React component, call `useAccountDisplayNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountDisplayNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountDisplayNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountDisplayNamesQuery(baseOptions?: Apollo.QueryHookOptions<AccountDisplayNamesQuery, AccountDisplayNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountDisplayNamesQuery, AccountDisplayNamesQueryVariables>(AccountDisplayNamesDocument, options);
      }
export function useAccountDisplayNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountDisplayNamesQuery, AccountDisplayNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountDisplayNamesQuery, AccountDisplayNamesQueryVariables>(AccountDisplayNamesDocument, options);
        }
export function useAccountDisplayNamesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountDisplayNamesQuery, AccountDisplayNamesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountDisplayNamesQuery, AccountDisplayNamesQueryVariables>(AccountDisplayNamesDocument, options);
        }
export type AccountDisplayNamesQueryHookResult = ReturnType<typeof useAccountDisplayNamesQuery>;
export type AccountDisplayNamesLazyQueryHookResult = ReturnType<typeof useAccountDisplayNamesLazyQuery>;
export type AccountDisplayNamesSuspenseQueryHookResult = ReturnType<typeof useAccountDisplayNamesSuspenseQuery>;
export type AccountDisplayNamesQueryResult = Apollo.QueryResult<AccountDisplayNamesQuery, AccountDisplayNamesQueryVariables>;