/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NumberOfIssuesWidgetQueryVariables = GraphQL.Exact<{
  endDate: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  startDate: GraphQL.Scalars['DateYMD']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type NumberOfIssuesWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly numberOfIssues: { readonly __typename?: 'WebsiteDashboardNumberOfIssues', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: { readonly __typename?: 'WebsiteDashboardNumberOfIssuesData', readonly numberOfIssuesOnEndDate: number } | null } } };


export const NumberOfIssuesWidgetDocument = gql`
    query NumberOfIssuesWidget($endDate: DateYMD!, $scope: String!, $startDate: DateYMD!, $websiteId: WebsiteId!) {
  dashboardData {
    numberOfIssues(
      websiteId: $websiteId
      endDate: $endDate
      scope: $scope
      startDate: $startDate
    ) {
      data {
        numberOfIssuesOnEndDate
      }
      id
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __useNumberOfIssuesWidgetQuery__
 *
 * To run a query within a React component, call `useNumberOfIssuesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumberOfIssuesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumberOfIssuesWidgetQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      scope: // value for 'scope'
 *      startDate: // value for 'startDate'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useNumberOfIssuesWidgetQuery(baseOptions: Apollo.QueryHookOptions<NumberOfIssuesWidgetQuery, NumberOfIssuesWidgetQueryVariables> & ({ variables: NumberOfIssuesWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NumberOfIssuesWidgetQuery, NumberOfIssuesWidgetQueryVariables>(NumberOfIssuesWidgetDocument, options);
      }
export function useNumberOfIssuesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NumberOfIssuesWidgetQuery, NumberOfIssuesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NumberOfIssuesWidgetQuery, NumberOfIssuesWidgetQueryVariables>(NumberOfIssuesWidgetDocument, options);
        }
export function useNumberOfIssuesWidgetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NumberOfIssuesWidgetQuery, NumberOfIssuesWidgetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NumberOfIssuesWidgetQuery, NumberOfIssuesWidgetQueryVariables>(NumberOfIssuesWidgetDocument, options);
        }
export type NumberOfIssuesWidgetQueryHookResult = ReturnType<typeof useNumberOfIssuesWidgetQuery>;
export type NumberOfIssuesWidgetLazyQueryHookResult = ReturnType<typeof useNumberOfIssuesWidgetLazyQuery>;
export type NumberOfIssuesWidgetSuspenseQueryHookResult = ReturnType<typeof useNumberOfIssuesWidgetSuspenseQuery>;
export type NumberOfIssuesWidgetQueryResult = Apollo.QueryResult<NumberOfIssuesWidgetQuery, NumberOfIssuesWidgetQueryVariables>;