import React from 'react';

import {
	FormContext,
} from '~/components/atoms/forms/basis/Form';

import PlanFeatures from '~/components/app/PlanFeatures';
import PlanName from '~/components/names/PlanName';
import SwitchButtonsField from '~/components/atoms/forms/components/SwitchButtonsField';
import SwitchButtonsDescription from '~/components/patterns/buttons/SwitchButtons/SwitchButtonsDescription';

import useAccountId from '~/hooks/useAccountId';
import useAccountTariff from '~/hooks/useAccountTariff';
import useAllowedPlans from '~/hooks/useAllowedPlans';

import GraphQL from '~/types/graphql';

import {
	getTariffPlans,
} from '~/model/universal';



type Props = {
	name: string,
};

const PlanField: React.FC<Props> = (props) => {
	const {
		name,
	} = props;

	const {
		isDisabled: isGloballyDisabled,
		values,
	} = React.useContext(FormContext);

	const accountId = useAccountId();
	const accountTariff = useAccountTariff(accountId);
	const allowedPlans = useAllowedPlans(accountId);

	const items = React.useMemo(
		() => {
			if (accountTariff === null) {
				return [];
			}

			return getTariffPlans(accountTariff)
				.filter((plan) => allowedPlans?.includes(plan))
				.filter((plan) => plan !== GraphQL.AccountPlan.Enterprise)
				.map((plan) => ({
					description: (
						<PlanFeatures
							compact={true}
							plan={plan}
						/>
					),
					disabled: false,
					label: (
						<PlanName plan={plan} />
					),
					value: plan,
				}));
		},
		[
			accountTariff,
			allowedPlans,
		],
	);

	const descriptionGetter = (value) => {
		const selectedItem = items.find((item) => item.value === value);

		if (selectedItem) {
			return selectedItem.description;
		}

		return null;
	};

	return (
		<SwitchButtonsDescription description={descriptionGetter(values[name])}>
			<SwitchButtonsField
				items={items.map((item) => {
					item = Object.assign({}, item);

					if (isGloballyDisabled) {
						item.disabled = true;
					}

					return item;
				})}
				name={name}
			/>
		</SwitchButtonsDescription>
	);
};



export default React.memo(PlanField);
