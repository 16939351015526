import React from 'react';

import type GraphQL from '~/types/graphql';

import BenefitNameWithIcon from './BenefitNameWithIcon';
import BenefitSectionName from './BenefitSectionName';
import Caption from '~/components/patterns/headings/Caption';
import PlansComparisonTable from '~/components/patterns/pricingPlans/plansComparisonTable/PlansComparisonTable';
import PlansComparisonTableCells, {
	PlansComparisonTableCellsStyle,
} from '~/components/patterns/pricingPlans/plansComparisonTable/PlansComparisonTableCells';
import PlansComparisonTableColumn from '~/components/patterns/pricingPlans/plansComparisonTable/PlansComparisonTableColumn';
import PlansComparisonTableLabels from '~/components/patterns/pricingPlans/plansComparisonTable/PlansComparisonTableLabels';

import useAccountFeaturesAvailability from '~/hooks/useAccountFeaturesAvailability';

import {
	BenefitSection,
} from '~/model/benefits';

import {
	isEnterprisePlan,
} from '~/model/plans';

import {
	type Tariff,
} from '~/model/pricing/tariffs';

import {
	getNewPlanBenefits,
} from '~/model/universal';



const higherCells = [
	BenefitSection.AdvancedFeatures,
	BenefitSection.Services,
];



type Props = {
	plans: ReadonlyArray<GraphQL.AccountPlan>,
	section: BenefitSection,
	tariff: Tariff,
};

const BenefitsComparison: React.FC<Props> = (props) => {
	const {
		plans,
		section,
		tariff,
	} = props;

	const accountFeaturesAvailability = useAccountFeaturesAvailability();

	if (
		accountFeaturesAvailability === null
	) {
		return null;
	}

	return (
		<PlansComparisonTable
			labels={(
				<PlansComparisonTableLabels
					higher={higherCells.includes(section)}
					labels={
						getNewPlanBenefits(tariff, accountFeaturesAvailability, section)
							.map((item) => (
								<BenefitNameWithIcon
									benefit={item.name}
									hint={item.hint}
									iconType={item.iconType}
									key={item.name}
								/>
							))
					}
				/>
			)}
			title={(
				<Caption>
					<BenefitSectionName section={section} />
				</Caption>
			)}
		>
			{plans.map((plan) => {
				const isEnterprise = isEnterprisePlan(plan);

				const cells = getNewPlanBenefits(tariff, accountFeaturesAvailability, section)
					.map((item) => item.values[plan]);

				return (
					<PlansComparisonTableColumn
						benefits={(
							<PlansComparisonTableCells
								cells={cells}
								higher={higherCells.includes(section)}
								style={isEnterprise ? PlansComparisonTableCellsStyle.Light : PlansComparisonTableCellsStyle.Default}
							/>
						)}
						isEnterprise={isEnterprise}
						key={plan}
					/>
				);
			})}
		</PlansComparisonTable>
	);
};



export default BenefitsComparison;
