import React from 'react';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import useCreateExport from '~/hooks/useCreateExport';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	PageIssue,
} from '~/model/issuesNew';

import {
	totalSelector,
} from '~/state/affectedPages/currentWebsiteSelectors';

import {
	filterSelector,
	sortBySelector,
} from '~/state/affectedPages/selectors';

import {
	issuesOverviewIssueSelector,
} from '~/state/ui/content/selectors';



function useExportIssueAffectedPages() {
	const websiteId = useWebsiteId();

	const createExport = useCreateExport(websiteId);
	const filter = useSelector(filterSelector);
	const issueType = useSelector(issuesOverviewIssueSelector);
	const sortBy = useSelector(sortBySelector);
	const totalCount = useSelector(totalSelector);

	const largeExportThreshold = [
		PageIssue.ImagesAltAttribute,
		PageIssue.ImagesMixedTransport,
		PageIssue.LinksBroken,
		PageIssue.LinksRedirected,
		PageIssue.LinksToCanonicalized,
	].includes(issueType) ? 2000 : 200000;

	const isLarge = totalCount >= largeExportThreshold;

	return React.useCallback(
		async () => {
			const exportData = await createExport({
				criteria: {
					filter: filter.toJS(),
					issueType,
					sortBy: sortBy.toJS(),
				},
				type: GraphQL.ExportType.SingleIssueAffectedPages,
			});

			return {
				...exportData,
				isLarge,
			};
		},
		[
			createExport,
			filter,
			isLarge,
			issueType,
			sortBy,
		],
	);
}



export default useExportIssueAffectedPages;
