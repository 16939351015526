/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExportFetchingSnapshotMutationVariables = GraphQL.Exact<{
  timestamp: GraphQL.Scalars['Timestamp']['input'];
  url: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ExportFetchingSnapshotMutation = { readonly __typename?: 'Mutation', readonly ExportFetchingSnapshot: { readonly __typename?: 'ExportFetchingSnapshotResult', readonly export: { readonly __typename?: 'FetchingSnapshotExport', readonly id: number } } };

export type ExportRobotsTxtRevisionMutationVariables = GraphQL.Exact<{
  revisionId: GraphQL.Scalars['String']['input'];
  url: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ExportRobotsTxtRevisionMutation = { readonly __typename?: 'Mutation', readonly ExportRobotsTxtRevision: { readonly __typename?: 'ExportFetchingSnapshotResult', readonly export: { readonly __typename?: 'FetchingSnapshotExport', readonly id: number } } };

export type SnapshotExportStatusQueryVariables = GraphQL.Exact<{
  exportId: GraphQL.Scalars['Int']['input'];
}>;


export type SnapshotExportStatusQuery = { readonly __typename?: 'Query', readonly fetchingSnapshotExport: { readonly __typename?: 'FetchingSnapshotExport', readonly downloadUrl: string | null, readonly id: number, readonly isFinished: boolean } | null };


export const ExportFetchingSnapshotDocument = gql`
    mutation ExportFetchingSnapshot($timestamp: Timestamp!, $url: String!, $websiteId: WebsiteId!) {
  ExportFetchingSnapshot(
    timeReceivedAt: $timestamp
    url: $url
    websiteId: $websiteId
  ) {
    export {
      id
    }
  }
}
    `;
export type ExportFetchingSnapshotMutationFn = Apollo.MutationFunction<ExportFetchingSnapshotMutation, ExportFetchingSnapshotMutationVariables>;

/**
 * __useExportFetchingSnapshotMutation__
 *
 * To run a mutation, you first call `useExportFetchingSnapshotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportFetchingSnapshotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportFetchingSnapshotMutation, { data, loading, error }] = useExportFetchingSnapshotMutation({
 *   variables: {
 *      timestamp: // value for 'timestamp'
 *      url: // value for 'url'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useExportFetchingSnapshotMutation(baseOptions?: Apollo.MutationHookOptions<ExportFetchingSnapshotMutation, ExportFetchingSnapshotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportFetchingSnapshotMutation, ExportFetchingSnapshotMutationVariables>(ExportFetchingSnapshotDocument, options);
      }
export type ExportFetchingSnapshotMutationHookResult = ReturnType<typeof useExportFetchingSnapshotMutation>;
export type ExportFetchingSnapshotMutationResult = Apollo.MutationResult<ExportFetchingSnapshotMutation>;
export type ExportFetchingSnapshotMutationOptions = Apollo.BaseMutationOptions<ExportFetchingSnapshotMutation, ExportFetchingSnapshotMutationVariables>;
export const ExportRobotsTxtRevisionDocument = gql`
    mutation ExportRobotsTxtRevision($revisionId: String!, $url: String!, $websiteId: WebsiteId!) {
  ExportRobotsTxtRevision(
    revisionId: $revisionId
    url: $url
    websiteId: $websiteId
  ) {
    export {
      id
    }
  }
}
    `;
export type ExportRobotsTxtRevisionMutationFn = Apollo.MutationFunction<ExportRobotsTxtRevisionMutation, ExportRobotsTxtRevisionMutationVariables>;

/**
 * __useExportRobotsTxtRevisionMutation__
 *
 * To run a mutation, you first call `useExportRobotsTxtRevisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportRobotsTxtRevisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportRobotsTxtRevisionMutation, { data, loading, error }] = useExportRobotsTxtRevisionMutation({
 *   variables: {
 *      revisionId: // value for 'revisionId'
 *      url: // value for 'url'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useExportRobotsTxtRevisionMutation(baseOptions?: Apollo.MutationHookOptions<ExportRobotsTxtRevisionMutation, ExportRobotsTxtRevisionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportRobotsTxtRevisionMutation, ExportRobotsTxtRevisionMutationVariables>(ExportRobotsTxtRevisionDocument, options);
      }
export type ExportRobotsTxtRevisionMutationHookResult = ReturnType<typeof useExportRobotsTxtRevisionMutation>;
export type ExportRobotsTxtRevisionMutationResult = Apollo.MutationResult<ExportRobotsTxtRevisionMutation>;
export type ExportRobotsTxtRevisionMutationOptions = Apollo.BaseMutationOptions<ExportRobotsTxtRevisionMutation, ExportRobotsTxtRevisionMutationVariables>;
export const SnapshotExportStatusDocument = gql`
    query SnapshotExportStatus($exportId: Int!) {
  fetchingSnapshotExport(exportId: $exportId) {
    downloadUrl
    id
    isFinished
  }
}
    `;

/**
 * __useSnapshotExportStatusQuery__
 *
 * To run a query within a React component, call `useSnapshotExportStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useSnapshotExportStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSnapshotExportStatusQuery({
 *   variables: {
 *      exportId: // value for 'exportId'
 *   },
 * });
 */
export function useSnapshotExportStatusQuery(baseOptions: Apollo.QueryHookOptions<SnapshotExportStatusQuery, SnapshotExportStatusQueryVariables> & ({ variables: SnapshotExportStatusQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SnapshotExportStatusQuery, SnapshotExportStatusQueryVariables>(SnapshotExportStatusDocument, options);
      }
export function useSnapshotExportStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SnapshotExportStatusQuery, SnapshotExportStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SnapshotExportStatusQuery, SnapshotExportStatusQueryVariables>(SnapshotExportStatusDocument, options);
        }
export function useSnapshotExportStatusSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SnapshotExportStatusQuery, SnapshotExportStatusQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SnapshotExportStatusQuery, SnapshotExportStatusQueryVariables>(SnapshotExportStatusDocument, options);
        }
export type SnapshotExportStatusQueryHookResult = ReturnType<typeof useSnapshotExportStatusQuery>;
export type SnapshotExportStatusLazyQueryHookResult = ReturnType<typeof useSnapshotExportStatusLazyQuery>;
export type SnapshotExportStatusSuspenseQueryHookResult = ReturnType<typeof useSnapshotExportStatusSuspenseQuery>;
export type SnapshotExportStatusQueryResult = Apollo.QueryResult<SnapshotExportStatusQuery, SnapshotExportStatusQueryVariables>;