import React from 'react';

import SelectField, {
	SelectFieldSize,
} from '~/components/atoms/forms/components/SelectField';



type Props = {
	extraProps?: Partial<React.ComponentProps<typeof SelectField>>,
	name: string,
	options: Array<{
		description?: React.ReactNode,
		label: React.ReactNode,
		name: string,
	}>,
};

const ParameterSelectField: React.FC<Props> = (props) => {
	const {
		extraProps = {},
		name,
		options,
	} = props;

	return (
		<SelectField
			name={name}
			options={options}
			size={SelectFieldSize.Small}
			width="100%"
			{...extraProps}
		/>
	);
};



export default ParameterSelectField;
