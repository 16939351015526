/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageChangesWidgetQueryVariables = GraphQL.Exact<{
  endDate: GraphQL.Scalars['DateYMD']['input'];
  scope: GraphQL.Scalars['String']['input'];
  startDate: GraphQL.Scalars['DateYMD']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageChangesWidgetQuery = { readonly __typename?: 'Query', readonly dashboardData: { readonly __typename?: 'DashboardData', readonly pageChanges: { readonly __typename?: 'WebsiteDashboardPageChanges', readonly id: CK.ID, readonly pollInterval: number | null, readonly reasonForNoData: GraphQL.WebsiteDashboardReasonForNoData | null, readonly data: ReadonlyArray<{ readonly __typename?: 'WebsiteDashboardPageChangesData', readonly endDate: CK.DateYMD, readonly startDate: CK.DateYMD, readonly changes: ReadonlyArray<{ readonly __typename?: 'WebsiteDashboardPageChange', readonly changeType: GraphQL.PageChangeType, readonly numberOfPages: number }> }> | null } } };


export const PageChangesWidgetDocument = gql`
    query PageChangesWidget($endDate: DateYMD!, $scope: String!, $startDate: DateYMD!, $websiteId: WebsiteId!) {
  dashboardData {
    pageChanges(
      endDate: $endDate
      scope: $scope
      startDate: $startDate
      websiteId: $websiteId
    ) {
      data {
        changes {
          changeType
          numberOfPages
        }
        endDate
        startDate
      }
      id
      pollInterval
      reasonForNoData
    }
  }
}
    `;

/**
 * __usePageChangesWidgetQuery__
 *
 * To run a query within a React component, call `usePageChangesWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageChangesWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageChangesWidgetQuery({
 *   variables: {
 *      endDate: // value for 'endDate'
 *      scope: // value for 'scope'
 *      startDate: // value for 'startDate'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageChangesWidgetQuery(baseOptions: Apollo.QueryHookOptions<PageChangesWidgetQuery, PageChangesWidgetQueryVariables> & ({ variables: PageChangesWidgetQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageChangesWidgetQuery, PageChangesWidgetQueryVariables>(PageChangesWidgetDocument, options);
      }
export function usePageChangesWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageChangesWidgetQuery, PageChangesWidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageChangesWidgetQuery, PageChangesWidgetQueryVariables>(PageChangesWidgetDocument, options);
        }
export function usePageChangesWidgetSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PageChangesWidgetQuery, PageChangesWidgetQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageChangesWidgetQuery, PageChangesWidgetQueryVariables>(PageChangesWidgetDocument, options);
        }
export type PageChangesWidgetQueryHookResult = ReturnType<typeof usePageChangesWidgetQuery>;
export type PageChangesWidgetLazyQueryHookResult = ReturnType<typeof usePageChangesWidgetLazyQuery>;
export type PageChangesWidgetSuspenseQueryHookResult = ReturnType<typeof usePageChangesWidgetSuspenseQuery>;
export type PageChangesWidgetQueryResult = Apollo.QueryResult<PageChangesWidgetQuery, PageChangesWidgetQueryVariables>;