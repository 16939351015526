import classNames from 'classnames';
import React from 'react';

import matchAndReturn from '~/utilities/matchAndReturn';



export enum AppearAnimationDirection {
	Down = 'down',
	InPlace = 'in-place',
	Up = 'up',
}



type Props = {
	children?: React.ReactNode,
	delay?: number,
	direction?: AppearAnimationDirection,
	duration?: number,
	/** Stretch whole container with attached animation */
	stretched?: boolean,
};

const AppearAnimation: React.FC<Props> = (props) => {
	const {
		children,
		delay,
		direction = AppearAnimationDirection.Up,
		duration,
		stretched,
	} = props;

	const distance = matchAndReturn(direction, {
		[AppearAnimationDirection.Down]: -20,
		[AppearAnimationDirection.InPlace]: 0,
		[AppearAnimationDirection.Up]: 20,
	});

	const componentClasses = classNames({
		'appear-animation': true,
		'appear-animation--stretched': stretched,
	});

	return (
		<div
			className={componentClasses}
			style={{
				'--delay': `${delay ?? 0}ms`,
				'--distance': `${distance}px`,
				'--duration': `${duration ?? 500}ms`,
			} as React.CSSProperties}
		>
			{children}
		</div>
	);
};



export default AppearAnimation;
