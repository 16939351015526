import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import {
	useCreateExportMutation,
} from './useCreateExport.gql';



function useCreateExport(websiteId: CK.WebsiteId) {
	const [createExport] = useCreateExportMutation();

	return React.useCallback(
		async (input: {
			criteria: Partial<GraphQL.CreateExportCriteria>,
			type: GraphQL.ExportType,
		}) => {
			const {
				type,
			} = input;

			const criteria = {
				alertId: input.criteria.alertId ?? null,
				columns: input.criteria.columns ?? null,
				endDate: input.criteria.endDate ?? null,
				filter: input.criteria.filter ?? null,
				issueCategory: input.criteria.issueCategory ?? null,
				issueType: input.criteria.issueType ?? null,
				legacyUrlId: input.criteria.legacyUrlId ?? null,
				relationType: input.criteria.relationType ?? null,
				sortBy: input.criteria.sortBy ?? null,
				startDate: input.criteria.startDate ?? null,
			};

			const result = await createExport({
				variables: {
					criteria,
					type,
					url: location.href,
					websiteId,
				},
			});

			const exportData = result.data?.CreateExport ?? null;

			if (exportData === null) {
				throw new Error(`exportData can't be null`);
			}

			return exportData;
		},
		[
			createExport,
			websiteId,
		],
	);
}



export default useCreateExport;
