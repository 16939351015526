/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AllInvitationsQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type AllInvitationsQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId, readonly connectedClientAccounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly id: CK.AccountId }>, readonly invitations: ReadonlyArray<{ readonly __typename?: 'Invitation', readonly displayName: string, readonly email: string, readonly hasLimitedWebsitesAccess: boolean, readonly id: CK.InvitationId, readonly role: GraphQL.UserRole, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly id: CK.WebsiteId }> }> }> } | null };


export const AllInvitationsDocument = gql`
    query AllInvitations {
  authenticatedSession {
    accounts {
      connectedClientAccounts {
        id
      }
      id
      invitations {
        displayName
        email
        hasLimitedWebsitesAccess
        id
        role
        websites {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useAllInvitationsQuery__
 *
 * To run a query within a React component, call `useAllInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllInvitationsQuery(baseOptions?: Apollo.QueryHookOptions<AllInvitationsQuery, AllInvitationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllInvitationsQuery, AllInvitationsQueryVariables>(AllInvitationsDocument, options);
      }
export function useAllInvitationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllInvitationsQuery, AllInvitationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllInvitationsQuery, AllInvitationsQueryVariables>(AllInvitationsDocument, options);
        }
export function useAllInvitationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AllInvitationsQuery, AllInvitationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AllInvitationsQuery, AllInvitationsQueryVariables>(AllInvitationsDocument, options);
        }
export type AllInvitationsQueryHookResult = ReturnType<typeof useAllInvitationsQuery>;
export type AllInvitationsLazyQueryHookResult = ReturnType<typeof useAllInvitationsLazyQuery>;
export type AllInvitationsSuspenseQueryHookResult = ReturnType<typeof useAllInvitationsSuspenseQuery>;
export type AllInvitationsQueryResult = Apollo.QueryResult<AllInvitationsQuery, AllInvitationsQueryVariables>;