/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdministerAccountTransactionSettingsMutationVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
  allowedBillingCycles: ReadonlyArray<GraphQL.Term> | GraphQL.Term;
  allowedCurrencies: ReadonlyArray<GraphQL.Currency> | GraphQL.Currency;
  allowedPaymentMethods: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  allowedPlans: ReadonlyArray<GraphQL.AccountPlan> | GraphQL.AccountPlan;
  customDiscount: GraphQL.Scalars['String']['input'];
  fixedBillingEntity: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  isRenewalEnabled: GraphQL.Scalars['Boolean']['input'];
  manualPaymentNetTerms: GraphQL.InputMaybe<GraphQL.Scalars['Int']['input']>;
}>;


export type AdministerAccountTransactionSettingsMutation = { readonly __typename?: 'Mutation', readonly AdministerAccountTransactionSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly allowedBillingCycles: ReadonlyArray<GraphQL.Term>, readonly allowedCurrencies: ReadonlyArray<GraphQL.Currency>, readonly allowedPaymentMethods: ReadonlyArray<string>, readonly allowedPlans: ReadonlyArray<GraphQL.AccountPlan>, readonly billingCycle: GraphQL.Term, readonly billingEntity: GraphQL.SubscriptionsSellerCode, readonly currency: GraphQL.Currency | null, readonly defaultCountry: string, readonly fixedBillingEntity: string | null, readonly id: CK.AccountId, readonly isSubscriptionRenewalEnabled: boolean, readonly manualPaymentNetTerms: number | null, readonly plan: GraphQL.AccountPlan | null, readonly possibleBillingCycles: ReadonlyArray<GraphQL.Term> } | null } } };

export type TransactionsSettingsFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type TransactionsSettingsFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly manualPaymentNetTerms: number | null, readonly possibleBillingCycles: ReadonlyArray<GraphQL.Term> } | null };


export const AdministerAccountTransactionSettingsDocument = gql`
    mutation AdministerAccountTransactionSettings($accountId: AccountId!, $allowedBillingCycles: [Term!]!, $allowedCurrencies: [Currency!]!, $allowedPaymentMethods: [String!]!, $allowedPlans: [AccountPlan!]!, $customDiscount: String!, $fixedBillingEntity: String, $isRenewalEnabled: Boolean!, $manualPaymentNetTerms: Int) {
  AdministerAccountTransactionSettings(
    accountId: $accountId
    allowedBillingCycles: $allowedBillingCycles
    allowedCurrencies: $allowedCurrencies
    allowedPaymentMethods: $allowedPaymentMethods
    allowedPlans: $allowedPlans
    customDiscount: $customDiscount
    fixedBillingEntity: $fixedBillingEntity
    isRenewalEnabled: $isRenewalEnabled
    manualPaymentNetTerms: $manualPaymentNetTerms
  ) {
    query {
      account(id: $accountId) {
        allowedBillingCycles
        allowedCurrencies
        allowedPaymentMethods
        allowedPlans
        billingCycle
        billingEntity
        currency
        defaultCountry
        fixedBillingEntity
        id
        isSubscriptionRenewalEnabled
        manualPaymentNetTerms
        plan
        possibleBillingCycles
      }
    }
  }
}
    `;
export type AdministerAccountTransactionSettingsMutationFn = Apollo.MutationFunction<AdministerAccountTransactionSettingsMutation, AdministerAccountTransactionSettingsMutationVariables>;

/**
 * __useAdministerAccountTransactionSettingsMutation__
 *
 * To run a mutation, you first call `useAdministerAccountTransactionSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdministerAccountTransactionSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [administerAccountTransactionSettingsMutation, { data, loading, error }] = useAdministerAccountTransactionSettingsMutation({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      allowedBillingCycles: // value for 'allowedBillingCycles'
 *      allowedCurrencies: // value for 'allowedCurrencies'
 *      allowedPaymentMethods: // value for 'allowedPaymentMethods'
 *      allowedPlans: // value for 'allowedPlans'
 *      customDiscount: // value for 'customDiscount'
 *      fixedBillingEntity: // value for 'fixedBillingEntity'
 *      isRenewalEnabled: // value for 'isRenewalEnabled'
 *      manualPaymentNetTerms: // value for 'manualPaymentNetTerms'
 *   },
 * });
 */
export function useAdministerAccountTransactionSettingsMutation(baseOptions?: Apollo.MutationHookOptions<AdministerAccountTransactionSettingsMutation, AdministerAccountTransactionSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdministerAccountTransactionSettingsMutation, AdministerAccountTransactionSettingsMutationVariables>(AdministerAccountTransactionSettingsDocument, options);
      }
export type AdministerAccountTransactionSettingsMutationHookResult = ReturnType<typeof useAdministerAccountTransactionSettingsMutation>;
export type AdministerAccountTransactionSettingsMutationResult = Apollo.MutationResult<AdministerAccountTransactionSettingsMutation>;
export type AdministerAccountTransactionSettingsMutationOptions = Apollo.BaseMutationOptions<AdministerAccountTransactionSettingsMutation, AdministerAccountTransactionSettingsMutationVariables>;
export const TransactionsSettingsFormDocument = gql`
    query TransactionsSettingsForm($accountId: AccountId!) {
  account(id: $accountId) {
    id
    manualPaymentNetTerms
    possibleBillingCycles
  }
}
    `;

/**
 * __useTransactionsSettingsFormQuery__
 *
 * To run a query within a React component, call `useTransactionsSettingsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransactionsSettingsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTransactionsSettingsFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useTransactionsSettingsFormQuery(baseOptions: Apollo.QueryHookOptions<TransactionsSettingsFormQuery, TransactionsSettingsFormQueryVariables> & ({ variables: TransactionsSettingsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TransactionsSettingsFormQuery, TransactionsSettingsFormQueryVariables>(TransactionsSettingsFormDocument, options);
      }
export function useTransactionsSettingsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TransactionsSettingsFormQuery, TransactionsSettingsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TransactionsSettingsFormQuery, TransactionsSettingsFormQueryVariables>(TransactionsSettingsFormDocument, options);
        }
export function useTransactionsSettingsFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TransactionsSettingsFormQuery, TransactionsSettingsFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TransactionsSettingsFormQuery, TransactionsSettingsFormQueryVariables>(TransactionsSettingsFormDocument, options);
        }
export type TransactionsSettingsFormQueryHookResult = ReturnType<typeof useTransactionsSettingsFormQuery>;
export type TransactionsSettingsFormLazyQueryHookResult = ReturnType<typeof useTransactionsSettingsFormLazyQuery>;
export type TransactionsSettingsFormSuspenseQueryHookResult = ReturnType<typeof useTransactionsSettingsFormSuspenseQuery>;
export type TransactionsSettingsFormQueryResult = Apollo.QueryResult<TransactionsSettingsFormQuery, TransactionsSettingsFormQueryVariables>;