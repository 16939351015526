import {
	format,
} from 'date-fns';
import type React from 'react';



type Props = {
	datetime: Date | number | string,
};

const DateTime: React.FC<Props> = (props) => {
	const {
		datetime,
	} = props;

	return format(datetime, 'd MMM yyyy, h:mm a');
};



export default DateTime;
