/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountNameQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountNameQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly name: string | null } | null };


export const AccountNameDocument = gql`
    query AccountName($accountId: AccountId!) {
  account(id: $accountId) {
    id
    name
  }
}
    `;

/**
 * __useAccountNameQuery__
 *
 * To run a query within a React component, call `useAccountNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountNameQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountNameQuery(baseOptions: Apollo.QueryHookOptions<AccountNameQuery, AccountNameQueryVariables> & ({ variables: AccountNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountNameQuery, AccountNameQueryVariables>(AccountNameDocument, options);
      }
export function useAccountNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountNameQuery, AccountNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountNameQuery, AccountNameQueryVariables>(AccountNameDocument, options);
        }
export function useAccountNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountNameQuery, AccountNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountNameQuery, AccountNameQueryVariables>(AccountNameDocument, options);
        }
export type AccountNameQueryHookResult = ReturnType<typeof useAccountNameQuery>;
export type AccountNameLazyQueryHookResult = ReturnType<typeof useAccountNameLazyQuery>;
export type AccountNameSuspenseQueryHookResult = ReturnType<typeof useAccountNameSuspenseQuery>;
export type AccountNameQueryResult = Apollo.QueryResult<AccountNameQuery, AccountNameQueryVariables>;