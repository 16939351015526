import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

import GraphQL from '~/types/graphql';

import CoreWebVitalsAssessmentWidget from './CoreWebVitalsAssessmentWidget';
import CumulativeLayoutShiftWidget from './CumulativeLayoutShiftWidget';
import DistributionOfIsIndexableWidget from '~/components/app/Dashboard/DistributionOfIsIndexableWidget';
import DistributionOfPageTypeWidget from '~/components/app/Dashboard/DistributionOfPageTypeWidget';
import HealthChangesWidget from '~/components/app/Dashboard/HealthChangesWidget';
import HealthWidget from '~/components/app/Dashboard/HealthWidget';
import InteractionToNextPaintWidget from './InteractionToNextPaintWidget';
import IssuesChangesWidget from '~/components/app/Dashboard/IssuesChangesWidget';
import LargestContentfulPaintWidget from './LargestContentfulPaintWidget';
import ManuallyTrackedLoading from '~/components/app/ManuallyTrackedLoading';
import NumberOfActiveIncidentsWidget from '~/components/app/Dashboard/NumberOfActiveIncidentsWidget';
import NumberOfIssuesWidget from '~/components/app/Dashboard/NumberOfIssuesWidget';
import NumberOfPagesWidget from '~/components/app/Dashboard/NumberOfPagesWidget';
import PageChangesWidget from '~/components/app/Dashboard/PageChangesWidget';
import ScopeTitle from './ScopeTitle';
import WidgetGrid from '~/components/patterns/widgets/WidgetGrid';

import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import useAccountId from '~/hooks/useAccountId';
import useScrollContainer from '~/hooks/useScrollContainer';

import {
	type Scope,
} from '~/model/scopes';



type ScopeSectionContextType = {
	isVisible: boolean,
	scope: Scope,
};

const ScopeSectionContext = React.createContext<ScopeSectionContextType | null>(null);



type Props = {
	scope: Scope,
};

const ScopeSection: React.FC<Props> = (props) => {
	const {
		scope,
	} = props;

	const accountId = useAccountId();
	const accountEnabledFeatures = useAccountEnabledFeatures(accountId);

	const isWebVitalsEnabled = accountEnabledFeatures !== null
		? accountEnabledFeatures.includes(GraphQL.AccountFeature.WebVitalsOriginSummary)
		: null;

	const [isVisible, setIsVisible] = React.useState<boolean>(false);
	const scrollContainer = useScrollContainer();

	const scopeContext = React.useMemo(
		() => ({
			isVisible,
			scope,
		}),
		[
			isVisible,
			scope,
		],
	);

	if (scrollContainer === null) {
		return null;
	}

	const showWebVitals = scope === 'website' && isWebVitalsEnabled === true;

	return (
		<VisibilitySensor
			containment={scrollContainer}
			intervalCheck={true}
			offset={{
				bottom: -1000,
				top: -1000,
			}}
			onChange={setIsVisible}
			partialVisibility={true}
			scrollCheck={true}
		>
			<ScopeSectionContext.Provider value={scopeContext}>
				<WidgetGrid
					hasWebVitals={showWebVitals}
					title={(
						<ScopeTitle scope={scope} />
					)}
				>
					<HealthWidget />
					<HealthChangesWidget />
					<DistributionOfIsIndexableWidget />
					<DistributionOfPageTypeWidget />
					<ManuallyTrackedLoading defaultIsLoading={true}>
						<IssuesChangesWidget />
					</ManuallyTrackedLoading>
					<NumberOfActiveIncidentsWidget />
					<NumberOfIssuesWidget />
					<NumberOfPagesWidget />
					<ManuallyTrackedLoading defaultIsLoading={true}>
						<PageChangesWidget />
					</ManuallyTrackedLoading>

					{showWebVitals && (
						<>
							<CoreWebVitalsAssessmentWidget />
							<InteractionToNextPaintWidget />
							<LargestContentfulPaintWidget />
							<CumulativeLayoutShiftWidget />
						</>
					)}
				</WidgetGrid>
			</ScopeSectionContext.Provider>
		</VisibilitySensor>
	);
};



export default ScopeSection;

export {
	ScopeSectionContext,
};
