/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteIsVerifiedQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteIsVerifiedQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isVerified: boolean } | null };


export const WebsiteIsVerifiedDocument = gql`
    query WebsiteIsVerified($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    isVerified
  }
}
    `;

/**
 * __useWebsiteIsVerifiedQuery__
 *
 * To run a query within a React component, call `useWebsiteIsVerifiedQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteIsVerifiedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteIsVerifiedQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteIsVerifiedQuery(baseOptions: Apollo.QueryHookOptions<WebsiteIsVerifiedQuery, WebsiteIsVerifiedQueryVariables> & ({ variables: WebsiteIsVerifiedQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteIsVerifiedQuery, WebsiteIsVerifiedQueryVariables>(WebsiteIsVerifiedDocument, options);
      }
export function useWebsiteIsVerifiedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteIsVerifiedQuery, WebsiteIsVerifiedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteIsVerifiedQuery, WebsiteIsVerifiedQueryVariables>(WebsiteIsVerifiedDocument, options);
        }
export function useWebsiteIsVerifiedSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteIsVerifiedQuery, WebsiteIsVerifiedQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteIsVerifiedQuery, WebsiteIsVerifiedQueryVariables>(WebsiteIsVerifiedDocument, options);
        }
export type WebsiteIsVerifiedQueryHookResult = ReturnType<typeof useWebsiteIsVerifiedQuery>;
export type WebsiteIsVerifiedLazyQueryHookResult = ReturnType<typeof useWebsiteIsVerifiedLazyQuery>;
export type WebsiteIsVerifiedSuspenseQueryHookResult = ReturnType<typeof useWebsiteIsVerifiedSuspenseQuery>;
export type WebsiteIsVerifiedQueryResult = Apollo.QueryResult<WebsiteIsVerifiedQuery, WebsiteIsVerifiedQueryVariables>;