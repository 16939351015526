/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SourceModePickerQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type SourceModePickerQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly isImpersonated: boolean, readonly kingdomAdmin: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string, readonly personalTweaks: { readonly __typename?: 'UserPersonalTweaks', readonly pageDetailSourceMode: GraphQL.PageDetailSourceMode } | null } | null, readonly user: { readonly __typename?: 'User', readonly email: string, readonly id: string, readonly legacyId: string, readonly personalTweaks: { readonly __typename?: 'UserPersonalTweaks', readonly pageDetailSourceMode: GraphQL.PageDetailSourceMode } | null } } | null };

export type UpdateSourceModeMutationVariables = GraphQL.Exact<{
  email: GraphQL.Scalars['String']['input'];
  mode: GraphQL.Scalars['String']['input'];
}>;


export type UpdateSourceModeMutation = { readonly __typename?: 'Mutation', readonly UpdateUserPageDetailSourceMode: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly kingdomAdmin: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly personalTweaks: { readonly __typename?: 'UserPersonalTweaks', readonly pageDetailSourceMode: GraphQL.PageDetailSourceMode } | null } | null, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly personalTweaks: { readonly __typename?: 'UserPersonalTweaks', readonly pageDetailSourceMode: GraphQL.PageDetailSourceMode } | null } } | null } } };


export const SourceModePickerDocument = gql`
    query SourceModePicker {
  authenticatedSession {
    isImpersonated
    kingdomAdmin {
      email
      id
      legacyId
      personalTweaks {
        pageDetailSourceMode
      }
    }
    user {
      email
      id
      legacyId
      personalTweaks {
        pageDetailSourceMode
      }
    }
  }
}
    `;

/**
 * __useSourceModePickerQuery__
 *
 * To run a query within a React component, call `useSourceModePickerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceModePickerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceModePickerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSourceModePickerQuery(baseOptions?: Apollo.QueryHookOptions<SourceModePickerQuery, SourceModePickerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SourceModePickerQuery, SourceModePickerQueryVariables>(SourceModePickerDocument, options);
      }
export function useSourceModePickerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SourceModePickerQuery, SourceModePickerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SourceModePickerQuery, SourceModePickerQueryVariables>(SourceModePickerDocument, options);
        }
export function useSourceModePickerSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SourceModePickerQuery, SourceModePickerQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SourceModePickerQuery, SourceModePickerQueryVariables>(SourceModePickerDocument, options);
        }
export type SourceModePickerQueryHookResult = ReturnType<typeof useSourceModePickerQuery>;
export type SourceModePickerLazyQueryHookResult = ReturnType<typeof useSourceModePickerLazyQuery>;
export type SourceModePickerSuspenseQueryHookResult = ReturnType<typeof useSourceModePickerSuspenseQuery>;
export type SourceModePickerQueryResult = Apollo.QueryResult<SourceModePickerQuery, SourceModePickerQueryVariables>;
export const UpdateSourceModeDocument = gql`
    mutation UpdateSourceMode($email: String!, $mode: String!) {
  UpdateUserPageDetailSourceMode(email: $email, mode: $mode) {
    query {
      authenticatedSession {
        kingdomAdmin {
          id
          legacyId
          personalTweaks {
            pageDetailSourceMode
          }
        }
        user {
          id
          legacyId
          personalTweaks {
            pageDetailSourceMode
          }
        }
      }
    }
  }
}
    `;
export type UpdateSourceModeMutationFn = Apollo.MutationFunction<UpdateSourceModeMutation, UpdateSourceModeMutationVariables>;

/**
 * __useUpdateSourceModeMutation__
 *
 * To run a mutation, you first call `useUpdateSourceModeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSourceModeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSourceModeMutation, { data, loading, error }] = useUpdateSourceModeMutation({
 *   variables: {
 *      email: // value for 'email'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useUpdateSourceModeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSourceModeMutation, UpdateSourceModeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSourceModeMutation, UpdateSourceModeMutationVariables>(UpdateSourceModeDocument, options);
      }
export type UpdateSourceModeMutationHookResult = ReturnType<typeof useUpdateSourceModeMutation>;
export type UpdateSourceModeMutationResult = Apollo.MutationResult<UpdateSourceModeMutation>;
export type UpdateSourceModeMutationOptions = Apollo.BaseMutationOptions<UpdateSourceModeMutation, UpdateSourceModeMutationVariables>;