import {
	useDroppable,
} from '@dnd-kit/core';
import React from 'react';



type Props = {
	children?: React.ReactNode,
	minHeight: number,
};

const NewTableBody: React.FC<Props> = (props) => {
	const {
		children,
		minHeight,
	} = props;

	const { setNodeRef } = useDroppable({
		id: 'droppable',
	});

	return (
		<div
			className="new-table__body"
			ref={setNodeRef}
			style={{ minHeight }}
		>
			{children}
		</div>
	);
};



export default NewTableBody;
