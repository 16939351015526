/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateWebsiteMonitoringSettingsMutationVariables = GraphQL.Exact<{
  crawlingSpeed: GraphQL.CrawlingSpeedInput;
  renderingDeviceType: GraphQL.RenderingDeviceType;
  userAgentType: GraphQL.Scalars['String']['input'];
  userAgentValue: GraphQL.InputMaybe<GraphQL.Scalars['String']['input']>;
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateWebsiteMonitoringSettingsMutation = { readonly __typename?: 'Mutation', readonly UpdateWebsiteMonitoringSettings: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly renderingDeviceType: GraphQL.RenderingDeviceType, readonly userAgentType: string, readonly userAgentValue: string | null, readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly features: ReadonlyArray<{ readonly __typename?: 'AccountFeatureState', readonly feature: GraphQL.AccountFeature, readonly id: CK.ID, readonly inUse: boolean }> } | null, readonly crawlingSpeed: { readonly __typename?: 'WebsiteCrawlingSpeed', readonly timezoneOffset: string | null, readonly intervals: ReadonlyArray<{ readonly __typename?: 'WebsiteCrawlingSpeedInterval', readonly isSetDueToThrottling: boolean, readonly scaleFactor: number, readonly since: string, readonly tags: ReadonlyArray<string>, readonly until: string }> } } | null } } };

export type WebsiteMonitoringFormQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteMonitoringFormQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly renderingDeviceType: GraphQL.RenderingDeviceType, readonly userAgentType: string, readonly userAgentValue: string | null, readonly crawlingSpeed: { readonly __typename?: 'WebsiteCrawlingSpeed', readonly timezoneOffset: string | null, readonly intervals: ReadonlyArray<{ readonly __typename?: 'WebsiteCrawlingSpeedInterval', readonly isSetDueToThrottling: boolean, readonly scaleFactor: number, readonly since: string, readonly tags: ReadonlyArray<string>, readonly until: string }> } } | null };


export const UpdateWebsiteMonitoringSettingsDocument = gql`
    mutation UpdateWebsiteMonitoringSettings($crawlingSpeed: CrawlingSpeedInput!, $renderingDeviceType: RenderingDeviceType!, $userAgentType: String!, $userAgentValue: String, $websiteId: WebsiteId!) {
  UpdateWebsiteMonitoringSettings(
    crawlingSpeed: $crawlingSpeed
    renderingDeviceType: $renderingDeviceType
    userAgentType: $userAgentType
    userAgentValue: $userAgentValue
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        account {
          features {
            feature
            id
            inUse
          }
          id
        }
        crawlingSpeed {
          intervals {
            isSetDueToThrottling
            scaleFactor
            since
            tags
            until
          }
          timezoneOffset
        }
        id
        renderingDeviceType
        userAgentType
        userAgentValue
      }
    }
  }
}
    `;
export type UpdateWebsiteMonitoringSettingsMutationFn = Apollo.MutationFunction<UpdateWebsiteMonitoringSettingsMutation, UpdateWebsiteMonitoringSettingsMutationVariables>;

/**
 * __useUpdateWebsiteMonitoringSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateWebsiteMonitoringSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebsiteMonitoringSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebsiteMonitoringSettingsMutation, { data, loading, error }] = useUpdateWebsiteMonitoringSettingsMutation({
 *   variables: {
 *      crawlingSpeed: // value for 'crawlingSpeed'
 *      renderingDeviceType: // value for 'renderingDeviceType'
 *      userAgentType: // value for 'userAgentType'
 *      userAgentValue: // value for 'userAgentValue'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateWebsiteMonitoringSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWebsiteMonitoringSettingsMutation, UpdateWebsiteMonitoringSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWebsiteMonitoringSettingsMutation, UpdateWebsiteMonitoringSettingsMutationVariables>(UpdateWebsiteMonitoringSettingsDocument, options);
      }
export type UpdateWebsiteMonitoringSettingsMutationHookResult = ReturnType<typeof useUpdateWebsiteMonitoringSettingsMutation>;
export type UpdateWebsiteMonitoringSettingsMutationResult = Apollo.MutationResult<UpdateWebsiteMonitoringSettingsMutation>;
export type UpdateWebsiteMonitoringSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateWebsiteMonitoringSettingsMutation, UpdateWebsiteMonitoringSettingsMutationVariables>;
export const WebsiteMonitoringFormDocument = gql`
    query WebsiteMonitoringForm($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    crawlingSpeed {
      intervals {
        isSetDueToThrottling
        scaleFactor
        since
        tags
        until
      }
      timezoneOffset
    }
    id
    renderingDeviceType
    userAgentType
    userAgentValue
  }
}
    `;

/**
 * __useWebsiteMonitoringFormQuery__
 *
 * To run a query within a React component, call `useWebsiteMonitoringFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteMonitoringFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteMonitoringFormQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteMonitoringFormQuery(baseOptions: Apollo.QueryHookOptions<WebsiteMonitoringFormQuery, WebsiteMonitoringFormQueryVariables> & ({ variables: WebsiteMonitoringFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteMonitoringFormQuery, WebsiteMonitoringFormQueryVariables>(WebsiteMonitoringFormDocument, options);
      }
export function useWebsiteMonitoringFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteMonitoringFormQuery, WebsiteMonitoringFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteMonitoringFormQuery, WebsiteMonitoringFormQueryVariables>(WebsiteMonitoringFormDocument, options);
        }
export function useWebsiteMonitoringFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteMonitoringFormQuery, WebsiteMonitoringFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteMonitoringFormQuery, WebsiteMonitoringFormQueryVariables>(WebsiteMonitoringFormDocument, options);
        }
export type WebsiteMonitoringFormQueryHookResult = ReturnType<typeof useWebsiteMonitoringFormQuery>;
export type WebsiteMonitoringFormLazyQueryHookResult = ReturnType<typeof useWebsiteMonitoringFormLazyQuery>;
export type WebsiteMonitoringFormSuspenseQueryHookResult = ReturnType<typeof useWebsiteMonitoringFormSuspenseQuery>;
export type WebsiteMonitoringFormQueryResult = Apollo.QueryResult<WebsiteMonitoringFormQuery, WebsiteMonitoringFormQueryVariables>;