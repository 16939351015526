import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	assertDictionaryMessage,
} from '~/localization/dictionaries';



const END_USER_PLAN_ESSENTIALS = 'end_user_essentials';
const END_USER_PLAN_PRO = 'end_user_pro';

const messages = defineMessages({
	[END_USER_PLAN_ESSENTIALS]: {
		id: 'ui.endUser.plan.essentials',
	},
	[END_USER_PLAN_PRO]: {
		id: 'ui.endUser.plan.pro',
	},
});



type Props = {
	plan: (
		| typeof END_USER_PLAN_ESSENTIALS
		| typeof END_USER_PLAN_PRO
	),
};

const EndUserPlanName: React.FC<Props> = (props) => {
	const {
		plan,
	} = props;

	const message = messages[plan];
	assertDictionaryMessage(message);

	return (
		<FormattedMessage {...message} />
	);
};



export default EndUserPlanName;
