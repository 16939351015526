import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import GraphQL from '~/types/graphql';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import {
	BenefitIconType,
} from '~/components/patterns/icons/BenefitIcon';
import HighlightedValue from '~/components/patterns/values/HighlightedValue';

import {
	Benefit,
	BenefitSection,
} from './benefits';

import {
	comparePlans as _comparePlans,
	isEnterprisePlan,
} from './plans';

import {
	notEmpty,
} from '~/utilities/typeCheck';
import {
	Tariff,
} from './pricing/tariffs';
import matchAndReturn from '~/utilities/matchAndReturn';
import Copy from '~/components/logic/Copy';



type PlanBenefit = {
	detailed?: boolean,
	disabled?: boolean,
	feature?: GraphQL.AccountFeature,
	name: Benefit,
};

type PlanBenefits = {
	[key in 'v3' | 'v4']: {
		[key in GraphQL.AccountPlan]?: Array<PlanBenefit>
	}
};

type NewPlanBenefit = {
	featureForAll?: boolean,
	featureName?: GraphQL.AccountFeature,
	hint?: React.ReactNode,
	iconType?: BasicIconType,
	name: Benefit,
	plans?: Array<GraphQL.AccountPlan>,
	values?: {
		[key in GraphQL.AccountPlan]?: React.ReactNode
	},
};

type NewPlanBenefitWithValues = (
	NewPlanBenefit & {
		values: Exclude<NewPlanBenefit['values'], undefined>,
	}
);

type NewPlanBenefits = {
	[key in BenefitSection]: Array<NewPlanBenefit>
};



const benefitValueMessages = defineMessages({
	amount: {
		id: 'ui.planBenefits.values.amount',
	},
	addon: {
		id: 'ui.planBenefits.values.addon',
	},
	headersFullBodyAddon: {
		id: 'ui.planBenefits.values.headersFullBodyAddon',
	},
	headersOnly: {
		id: 'ui.planBenefits.values.headersOnly',
	},
	monthsCount: {
		id: 'ui.planBenefits.values.monthsCount',
	},
	nonStandard: {
		id: 'ui.planBenefits.values.nonStandard',
	},
	pageBundles: {
		id: 'ui.planBenefits.values.pageBundles',
	},
	perWebsite: {
		id: 'ui.planBenefits.values.perWebsite',
	},
	priority: {
		id: 'ui.planBenefits.values.priority',
	},
	standard: {
		id: 'ui.planBenefits.values.standard',
	},
	unlimited: {
		id: 'ui.planBenefits.values.unlimited',
	},
	upToMonthsCount: {
		id: 'ui.planBenefits.values.upToMonthsCount',
	},
	upToPerWebsite: {
		id: 'ui.planBenefits.values.upToPerWebsite',
	},
	upsellAmount: {
		id: 'ui.planBenefits.values.upsellAmount',
	},
	upsellMonthsCount: {
		id: 'ui.planBenefits.values.upsellMonthsCount',
	},
	upsellPerWebsite: {
		id: 'ui.planBenefits.values.upsellPerWebsite',
	},
	weeksCount: {
		id: 'ui.planBenefits.values.weeksCount',
	},
});

const messages = defineMessages({
	comingSoon: {
		id: 'ui.general.comingSoon',
	},
});

const hintMessages = defineMessages({
	lightspeedMonitoring: {
		id: 'ui.planBenefits.section.monitoringSettings.benefitHints.lightspeedMonitoring',
	},
	rechecks: {
		id: 'ui.planBenefits.section.MainFeatures.benefitHints.rechecks',
	},
});



const LIST_OF_ALL_PLANS = {
	[Tariff.C1]: [
		GraphQL.AccountPlan.EnterpriseLite,
		GraphQL.AccountPlan.Enterprise,
	],
	[Tariff.C2]: [
		GraphQL.AccountPlan.C2Starter,
		GraphQL.AccountPlan.C2Professional,
		GraphQL.AccountPlan.C2Enterprise,
	],
	[Tariff.V3]: [
		GraphQL.AccountPlan.Basic,
		GraphQL.AccountPlan.Standard,
		GraphQL.AccountPlan.Pro,
		GraphQL.AccountPlan.Prov4,
		GraphQL.AccountPlan.Enterprise,
	],
	[Tariff.V4]: [
		GraphQL.AccountPlan.Basic,
		GraphQL.AccountPlan.Standard,
		GraphQL.AccountPlan.Pro,
		GraphQL.AccountPlan.Enterprise,
	],
};

export function getTariffPlans(tariff: Tariff): ReadonlyArray<GraphQL.AccountPlan> {
	return LIST_OF_ALL_PLANS[tariff];
}


export const PLAN_BENEFITS: PlanBenefits = {
	v3: {
		[GraphQL.AccountPlan.Basic]: [
			{
				detailed: true,
				name: Benefit.UnlimitedWebsites,
			},
			{
				detailed: true,
				name: Benefit.UnlimitedUsers,
			},
			{
				name: Benefit.DataRetention6Months,
			},
			{
				name: Benefit.Cobranding,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.IssuesConfiguration,
				name: Benefit.IssuesConfiguration,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.CustomElements_2,
				name: Benefit.CustomElements0,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
				name: Benefit.CoreWebVitalsOriginSummary,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.LighthouseMonitoring,
				name: Benefit.LighthouseMonitoring,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.CustomAlertTrigger,
				name: Benefit.CustomAlertTrigger,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.Rendering,
				name: Benefit.Rendering,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.GoogleLookerStudio,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.ReportingApi,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.LogFileAnalysis,
				name: Benefit.LogFileAnalysis,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.IndexNow,
				name: Benefit.RealtimeIndexNow,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.MicrosoftTeams,
				name: Benefit.MicrosoftTeams,
			},
		],
		[GraphQL.AccountPlan.Standard]: [
			{
				detailed: true,
				name: Benefit.UnlimitedWebsites,
			},
			{
				detailed: true,
				name: Benefit.UnlimitedUsers,
			},
			{
				name: Benefit.DataRetention9Months,
			},
			{
				name: Benefit.Cobranding,
			},
			{
				feature: GraphQL.AccountFeature.IssuesConfiguration,
				name: Benefit.IssuesConfiguration,
			},
			{
				feature: GraphQL.AccountFeature.CustomElements_2,
				name: Benefit.CustomElements2,
			},
			{
				feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
				name: Benefit.CoreWebVitalsOriginSummary,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.LighthouseMonitoring,
				name: Benefit.LighthouseMonitoring,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.CustomAlertTrigger,
				name: Benefit.CustomAlertTrigger,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.Rendering,
				name: Benefit.Rendering,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.GoogleLookerStudio,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.ReportingApi,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.LogFileAnalysis,
				name: Benefit.LogFileAnalysis,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.IndexNow,
				name: Benefit.RealtimeIndexNow,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.MicrosoftTeams,
				name: Benefit.MicrosoftTeams,
			},
		],
		[GraphQL.AccountPlan.Pro]: [
			{
				detailed: true,
				name: Benefit.UnlimitedWebsites,
			},
			{
				detailed: true,
				name: Benefit.UnlimitedUsers,
			},
			{
				name: Benefit.DataRetention12Months,
			},
			{
				name: Benefit.Cobranding,
			},
			{
				feature: GraphQL.AccountFeature.IssuesConfiguration,
				name: Benefit.IssuesConfiguration,
			},
			{
				feature: GraphQL.AccountFeature.CustomElements_5,
				name: Benefit.CustomElements5,
			},
			{
				feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
				name: Benefit.CoreWebVitalsOriginSummary,
			},
			{
				feature: GraphQL.AccountFeature.LighthouseMonitoring,
				name: Benefit.LighthouseMonitoring,
			},
			{
				feature: GraphQL.AccountFeature.CustomAlertTrigger,
				name: Benefit.CustomAlertTrigger,
			},
			{
				feature: GraphQL.AccountFeature.Rendering,
				name: Benefit.Rendering,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.GoogleLookerStudio,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.ReportingApi,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.LogFileAnalysis,
				name: Benefit.LogFileAnalysis,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.IndexNow,
				name: Benefit.RealtimeIndexNow,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.MicrosoftTeams,
				name: Benefit.MicrosoftTeams,
			},
		],
		[GraphQL.AccountPlan.Prov4]: [
			{
				detailed: true,
				name: Benefit.UnlimitedWebsites,
			},
			{
				detailed: true,
				name: Benefit.UnlimitedUsers,
			},
			{
				name: Benefit.DataRetention12Months,
			},
			{
				name: Benefit.Cobranding,
			},
			{
				feature: GraphQL.AccountFeature.IssuesConfiguration,
				name: Benefit.IssuesConfiguration,
			},
			{
				feature: GraphQL.AccountFeature.CustomElements_10,
				name: Benefit.CustomElements10,
			},
			{
				feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
				name: Benefit.CoreWebVitalsOriginSummary,
			},
			{
				feature: GraphQL.AccountFeature.LighthouseMonitoring,
				name: Benefit.LighthouseMonitoring,
			},
			{
				feature: GraphQL.AccountFeature.CustomAlertTrigger,
				name: Benefit.CustomAlertTrigger,
			},
			{
				feature: GraphQL.AccountFeature.Rendering,
				name: Benefit.Rendering,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.GoogleLookerStudio,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.ReportingApi,
			},
			{
				feature: GraphQL.AccountFeature.LogFileAnalysis,
				name: Benefit.LogFileAnalysis,
			},
			{
				feature: GraphQL.AccountFeature.IndexNow,
				name: Benefit.RealtimeIndexNow,
			},
			{
				feature: GraphQL.AccountFeature.MicrosoftTeams,
				name: Benefit.MicrosoftTeams,
			},
		],
	},
	v4: {
		[GraphQL.AccountPlan.Basic]: [
			{
				detailed: true,
				name: Benefit.UnlimitedWebsites,
			},
			{
				detailed: true,
				name: Benefit.UnlimitedUsers,
			},
			{
				name: Benefit.DataRetention6Months,
			},
			{
				name: Benefit.Cobranding,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.CustomElements_2,
				name: Benefit.CustomElements0,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
				name: Benefit.CoreWebVitalsOriginSummary,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.LighthouseMonitoring,
				name: Benefit.LighthouseMonitoring,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.CustomAlertTrigger,
				name: Benefit.CustomAlertTrigger,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.Rendering,
				name: Benefit.Rendering,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.GoogleLookerStudio,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.ReportingApi,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.LogFileAnalysis,
				name: Benefit.LogFileAnalysis,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.IndexNow,
				name: Benefit.RealtimeIndexNow,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.MicrosoftTeams,
				name: Benefit.MicrosoftTeams,
			},
		],
		[GraphQL.AccountPlan.Standard]: [
			{
				detailed: true,
				name: Benefit.UnlimitedWebsites,
			},
			{
				detailed: true,
				name: Benefit.UnlimitedUsers,
			},
			{
				name: Benefit.DataRetention9Months,
			},
			{
				name: Benefit.Cobranding,
			},
			{
				feature: GraphQL.AccountFeature.CustomElements_5,
				name: Benefit.CustomElements5,
			},
			{
				feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
				name: Benefit.CoreWebVitalsOriginSummary,
			},
			{
				feature: GraphQL.AccountFeature.LighthouseMonitoring,
				name: Benefit.LighthouseMonitoring,
			},
			{
				feature: GraphQL.AccountFeature.CustomAlertTrigger,
				name: Benefit.CustomAlertTrigger,
			},
			{
				feature: GraphQL.AccountFeature.Rendering,
				name: Benefit.Rendering,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.GoogleLookerStudio,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.ReportingApi,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.LogFileAnalysis,
				name: Benefit.LogFileAnalysis,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.IndexNow,
				name: Benefit.RealtimeIndexNow,
			},
			{
				disabled: true,
				feature: GraphQL.AccountFeature.MicrosoftTeams,
				name: Benefit.MicrosoftTeams,
			},
		],
		[GraphQL.AccountPlan.Pro]: [
			{
				detailed: true,
				name: Benefit.UnlimitedWebsites,
			},
			{
				detailed: true,
				name: Benefit.UnlimitedUsers,
			},
			{
				name: Benefit.DataRetention12Months,
			},
			{
				name: Benefit.Cobranding,
			},
			{
				feature: GraphQL.AccountFeature.CustomElements_10,
				name: Benefit.CustomElements10,
			},
			{
				feature: GraphQL.AccountFeature.WebVitalsOriginSummary,
				name: Benefit.CoreWebVitalsOriginSummary,
			},
			{
				feature: GraphQL.AccountFeature.LighthouseMonitoring,
				name: Benefit.LighthouseMonitoring,
			},
			{
				feature: GraphQL.AccountFeature.CustomAlertTrigger,
				name: Benefit.CustomAlertTrigger,
			},
			{
				feature: GraphQL.AccountFeature.Rendering,
				name: Benefit.Rendering,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.GoogleLookerStudio,
			},
			{
				feature: GraphQL.AccountFeature.ReportingApi,
				name: Benefit.ReportingApi,
			},
			{
				feature: GraphQL.AccountFeature.LogFileAnalysis,
				name: Benefit.LogFileAnalysis,
			},
			{
				feature: GraphQL.AccountFeature.IndexNow,
				name: Benefit.RealtimeIndexNow,
			},
			{
				feature: GraphQL.AccountFeature.MicrosoftTeams,
				name: Benefit.MicrosoftTeams,
			},
		],
	},
};

export function getNewPlanBenefits(
	tariff: Tariff,
	accountFeaturesAvailability: ReadonlyArray<{
		accountFeature: GraphQL.AccountFeature,
		plan: GraphQL.AccountPlan,
		status: GraphQL.AccountFeatureAvailabilityStatus,
		tariff: string,
	}>,
	section: BenefitSection,
): Array<NewPlanBenefitWithValues> {
	const newPlanBenefits = matchAndReturn(tariff, {
		[Tariff.C1]: null,
		[Tariff.C2]: C2PlanBenefits,
		[Tariff.V2]: null,
		[Tariff.V3]: null,
		[Tariff.V4]: V4PlanBenefits,
	});

	if (newPlanBenefits === null) {
		throw new Error(`No benefits for tariff ${tariff}`);
	}

	return newPlanBenefits[section].map((benefit) => {
		if (benefit.values !== undefined) {
			return benefit as NewPlanBenefitWithValues;
		}

		const values: Exclude<NewPlanBenefit['values'], undefined> = {};

		getTariffPlans(tariff).forEach((plan) => {
			const color = isEnterprisePlan(plan)
				? `#f4a21e`
				: undefined;

			let isAvailable = false;

			if (benefit.featureForAll) {
				isAvailable = true;
			} else if (benefit.featureName) {
				isAvailable = isFeatureAvailable(
					accountFeaturesAvailability,
					tariff,
					plan,
					benefit.featureName,
				);
			} else if (benefit.plans) {
				isAvailable = benefit.plans.includes(plan);
			}

			values[plan] = (
				<BasicIcon
					color={color}
					size={18}
					type={isAvailable ? BasicIconType.Available : BasicIconType.Unavailable}
				/>
			);
		});

		return {
			...benefit,
			values,
		} as NewPlanBenefitWithValues;
	});
}



const V4PlanBenefits: NewPlanBenefits = {
	[BenefitSection.AccessControl]: [
		{
			featureName: GraphQL.AccountFeature.AdminRole,
			name: Benefit.OrganizationAdminRole,
		},
		{
			featureName: GraphQL.AccountFeature.EmailDomainsWhitelist,
			name: Benefit.RestrictEmailDomains,
		},
		{
			featureName: GraphQL.AccountFeature.EnforceTwoFactorAuthentication,
			name: Benefit.Enforce2FA,
		},
		{
			name: Benefit.AuditLog,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<HighlightedValue tag={true}>
						<FormattedMessage {...messages.comingSoon} />
					</HighlightedValue>
				),
			},
		},
	],
	[BenefitSection.AdvancedAnalysis]: [
		{
			plans: [
				GraphQL.AccountPlan.Standard,
				GraphQL.AccountPlan.Pro,
				GraphQL.AccountPlan.Enterprise,
			],
			name: Benefit.HttpHeaderSnapshot,
		},
		{
			featureName: GraphQL.AccountFeature.StoreResponseSnapshots,
			name: Benefit.HttpBodySnapshot,
		},
		{
			featureName: GraphQL.AccountFeature.LogFileAnalysis,
			name: Benefit.LogFileAnalysis,
		},
		{
			name: Benefit.LogFileExplorer,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<HighlightedValue tag={true}>
						<FormattedMessage {...messages.comingSoon} />
					</HighlightedValue>
				),
			},
		},
	],
	[BenefitSection.AdvancedFeatures]: [
		{
			name: Benefit.DataRetention,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<Copy
						{...benefitValueMessages.monthsCount}
						values={{
							count: 6,
						}}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<Copy
						{...benefitValueMessages.monthsCount}
						values={{
							count: 9,
						}}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<Copy
						{...benefitValueMessages.monthsCount}
						values={{
							count: 12,
						}}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<Copy
						{...benefitValueMessages.upToMonthsCount}
						values={{
							count: 60,
						}}
					/>
				),
			},
		},
		{
			name: Benefit.CustomElementExtraction,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<AttachedIcon
						columnFlow={true}
						icon={(
							<BasicIcon
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<Copy
							{...benefitValueMessages.perWebsite}
							values={{
								count: 5,
							}}
						/>
					</AttachedIcon>
				),
				[GraphQL.AccountPlan.Pro]: (
					<AttachedIcon
						columnFlow={true}
						icon={(
							<BasicIcon
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<Copy
							{...benefitValueMessages.perWebsite}
							values={{
								count: 10,
							}}
						/>
					</AttachedIcon>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<AttachedIcon
						columnFlow={true}
						ellipsis={false}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<Copy
							{...benefitValueMessages.upToPerWebsite}
							values={{
								count: 20,
							}}
						/>
					</AttachedIcon>
				),
			},
		},
		{
			featureName: GraphQL.AccountFeature.CustomAlertTrigger,
			name: Benefit.AlertsForCustomElements,
		},
		{
			featureName: GraphQL.AccountFeature.Rendering,
			iconType: BasicIconType.JavaScript,
			name: Benefit.JsRendering,
		},
		{
			featureName: GraphQL.AccountFeature.WebVitalsOriginSummary,
			iconType: BasicIconType.CoreWebVitalsMonitoring,
			name: Benefit.CoreWebVitalsMonitoring,
		},
		{
			featureName: GraphQL.AccountFeature.IndexNow,
			iconType: BasicIconType.RealtimeIndexNow,
			name: Benefit.RealtimeIndexNow,
		},
	],
	[BenefitSection.Collaboration]: [
		{
			featureForAll: true,
			name: Benefit.Cobranding,
		},
		{
			featureForAll: true,
			name: Benefit.ClientAccess,
		},
	],
	[BenefitSection.MainFeatures]: [
		{
			name: Benefit.Users,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Standard]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Pro]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
			},
		},
		{
			hint: (
				<FormattedMessage {...hintMessages.rechecks} />
			),
			name: Benefit.Rechecks,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Standard]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Pro]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
			},
		},
		{
			featureForAll: true,
			name: Benefit.WebsiteAuditing,
		},
		{
			featureForAll: true,
			name: Benefit.NonStopMonitoring,
		},
		{
			featureForAll: true,
			name: Benefit.ChangeTracking,
		},
		{
			featureForAll: true,
			name: Benefit.Alerting,
		},
	],
	[BenefitSection.Integrations]: [
		{
			featureForAll: true,
			iconType: BasicIconType.Slack,
			name: Benefit.Slack,
		},
		{
			featureForAll: true,
			iconType: BasicIconType.GoogleAnalytics,
			name: Benefit.GoogleAnalytics,
		},
		{
			featureForAll: true,
			iconType: BasicIconType.GoogleSearchConsole,
			name: Benefit.GoogleSearchConsole,
		},
		{
			featureForAll: true,
			iconType: BasicIconType.Chrome,
			name: Benefit.GoogleChromeExtension,
		},
		{
			featureName: GraphQL.AccountFeature.ReportingApi,
			iconType: BasicIconType.LookerStudio,
			name: Benefit.GoogleLookerStudio,
		},
		{
			featureName: GraphQL.AccountFeature.ReportingApi,
			iconType: BasicIconType.Api,
			name: Benefit.ReportingApi,
		},
		{
			featureName: GraphQL.AccountFeature.MicrosoftTeams,
			iconType: BasicIconType.MicrosoftTeams,
			name: Benefit.MicrosoftTeams,
		},
		{
			featureName: GraphQL.AccountFeature.EnrichmentFields_5,
			iconType: BasicIconType.Api,
			name: Benefit.DataEnrichmentAPI,
		},
		{
			featureName: GraphQL.AccountFeature.AdobeAnalyticsIntegration,
			iconType: BasicIconType.AdobeAnalytics,
			name: Benefit.AdobeAnalytics,
		},
	],
	[BenefitSection.MonitoringSettings]: [
		{
			featureForAll: true,
			name: Benefit.MonitoringFromUSUKEU,
		},
		{
			name: Benefit.CustomMonitoringLocation,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<AttachedIcon
						columnFlow={true}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.addon} />
					</AttachedIcon>
				),
			},
		},
		{
			featureName: GraphQL.AccountFeature.HighCrawlingSpeed,
			hint: (
				<FormattedMessage {...hintMessages.lightspeedMonitoring} />
			),
			name: Benefit.LightspeedMonitoring,
		},
	],
	[BenefitSection.Services]: [
		{
			name: Benefit.ChatSupport,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Standard]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Pro]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<AttachedIcon
						ellipsis={false}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={24}
								type={BasicIconType.Priority}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.priority} />
					</AttachedIcon>
				),
			},
		},
		{
			name: Benefit.EmailSupport,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Standard]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Pro]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<AttachedIcon
						ellipsis={false}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={24}
								type={BasicIconType.Priority}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.priority} />
					</AttachedIcon>
				),
			},
		},
		{
			name: Benefit.GuidesAndVideos,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Available}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Available}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Available}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
		{
			name: Benefit.CustomTraining,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<AttachedIcon
						columnFlow={true}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.addon} />
					</AttachedIcon>
				),
			},
		},
		{
			name: Benefit.PhoneSupport,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
		{
			name: Benefit.DedicatedSuccessManager,
			values: {
				[GraphQL.AccountPlan.Basic]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Standard]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Pro]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
	],
};

const C2PlanBenefits: NewPlanBenefits = {
	[BenefitSection.AccessControl]: [
		{
			featureName: GraphQL.AccountFeature.AdminRole,
			name: Benefit.OrganizationAdminRole,
		},
		{
			featureName: GraphQL.AccountFeature.EmailDomainsWhitelist,
			name: Benefit.RestrictEmailDomains,
		},
		{
			featureName: GraphQL.AccountFeature.EnforceTwoFactorAuthentication,
			name: Benefit.Enforce2FA,
		},
	],
	[BenefitSection.AdvancedAnalysis]: [
		{
			plans: [
				GraphQL.AccountPlan.C2Professional,
				GraphQL.AccountPlan.C2Enterprise,
			],
			name: Benefit.HttpHeaderSnapshot,
		},
		{
			featureName: GraphQL.AccountFeature.StoreResponseSnapshots,
			name: Benefit.HttpBodySnapshot,
		},
		{
			featureName: GraphQL.AccountFeature.LogFileAnalysis,
			name: Benefit.LogFileAnalysis,
		},
	],
	[BenefitSection.AdvancedFeatures]: [
		{
			name: Benefit.DataRetention,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<Copy
						{...benefitValueMessages.weeksCount}
						values={{
							count: 2,
						}}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<Copy
						{...benefitValueMessages.monthsCount}
						values={{
							count: 6,
						}}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<Copy
						{...benefitValueMessages.upsellMonthsCount}
						values={{
							base: 12,
							upsell: 60,
						}}
					/>
				),
			},
		},
		{
			name: Benefit.CustomElementExtraction,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<AttachedIcon
						columnFlow={true}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<Copy
							{...benefitValueMessages.upsellPerWebsite}
							values={{
								base: 5,
								upsell: 20,
							}}
						/>
					</AttachedIcon>
				),
			},
		},
		{
			featureName: GraphQL.AccountFeature.CustomAlertTrigger,
			name: Benefit.AlertsForCustomElements,
		},
		{
			name: Benefit.CWVMonitoringAndJSRendering,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<strong>$</strong>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<strong>$</strong>
				),
			},
		},
		{
			featureName: GraphQL.AccountFeature.WebVitalsOriginSummary,
			name: Benefit.CoreWebVitalsMonitoring,
		},
		{
			featureName: GraphQL.AccountFeature.IndexNow,
			name: Benefit.RealtimeIndexNow,
		},
	],
	[BenefitSection.Collaboration]: [],
	[BenefitSection.MainFeatures]: [
		{
			name: Benefit.PageBundles,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<Copy
						{...benefitValueMessages.pageBundles}
						values={{
							from: 1_000,
							to: 100_000,
						}}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<Copy
						{...benefitValueMessages.pageBundles}
						values={{
							from: 1_000,
							to: 250_000,
						}}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<Copy
						{...benefitValueMessages.pageBundles}
						values={{
							from: 1_000,
							to: 10_000_000,
						}}
					/>
				),
			},
		},
		{
			name: Benefit.Websites,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<Copy
						{...benefitValueMessages.amount}
						values={{
							count: 3,
						}}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<Copy
						{...benefitValueMessages.amount}
						values={{
							count: 5,
						}}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<Copy
						{...benefitValueMessages.upsellAmount}
						values={{
							base: 10,
							upsell: 'more',
						}}
					/>
				),
			},
		},
		{
			hint: (
				<FormattedMessage {...hintMessages.rechecks} />
			),
			name: Benefit.Rechecks,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
			},
		},
		{
			name: Benefit.Users,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<FormattedMessage {...benefitValueMessages.unlimited} />
				),
			},
		},
		{
			featureForAll: true,
			name: Benefit.WebsiteAuditing,
		},
		{
			featureForAll: true,
			name: Benefit.NonStopMonitoring,
		},
		{
			featureForAll: true,
			name: Benefit.ChangeTracking,
		},
		{
			featureForAll: true,
			name: Benefit.Alerting,
		},
	],
	[BenefitSection.Integrations]: [
		{
			featureForAll: true,
			iconType: BasicIconType.Chrome,
			name: Benefit.GoogleChromeExtension,
		},
		{
			featureName: GraphQL.AccountFeature.GoogleAnalyticsIntegration,
			iconType: BasicIconType.GoogleAnalytics,
			name: Benefit.GoogleAnalytics,
		},
		{
			featureName: GraphQL.AccountFeature.GoogleSearchConsoleIntegration,
			iconType: BasicIconType.GoogleSearchConsole,
			name: Benefit.GoogleSearchConsole,
		},
		{
			// featureName: GraphQL.AccountFeature.Slack,
			iconType: BasicIconType.Slack,
			name: Benefit.Slack,
		},
		{
			featureName: GraphQL.AccountFeature.ReportingApi,
			iconType: BasicIconType.LookerStudio,
			name: Benefit.GoogleLookerStudio,
		},
		{
			featureName: GraphQL.AccountFeature.MicrosoftTeams,
			iconType: BasicIconType.MicrosoftTeams,
			name: Benefit.MicrosoftTeams,
		},
		{
			featureName: GraphQL.AccountFeature.ReportingApi,
			iconType: BasicIconType.Api,
			name: Benefit.ReportingApi,
		},
		{
			featureName: GraphQL.AccountFeature.EnrichmentFields_5,
			iconType: BasicIconType.Api,
			name: Benefit.DataEnrichmentAPI,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<AttachedIcon
						columnFlow={true}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={18}
								type={BasicIconType.Available}
							/>
						)}
					>
						<Copy
							{...benefitValueMessages.upsellPerWebsite}
							values={{
								base: 5,
								upsell: 20,
							}}
						/>
					</AttachedIcon>
				),
			},
		},
		{
			featureName: GraphQL.AccountFeature.AdobeAnalyticsIntegration,
			iconType: BasicIconType.AdobeAnalytics,
			name: Benefit.AdobeAnalytics,
		},
	],
	[BenefitSection.MonitoringSettings]: [
		{
			featureForAll: true,
			name: Benefit.MonitoringFromUSUKEU,
		},
		{
			featureName: GraphQL.AccountFeature.HighCrawlingSpeed,
			hint: (
				<FormattedMessage {...hintMessages.lightspeedMonitoring} />
			),
			name: Benefit.LightspeedMonitoring,
		},
		{
			name: Benefit.CustomMonitoringLocation,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
	],
	[BenefitSection.Services]: [
		{
			name: Benefit.ChatSupport,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<AttachedIcon
						ellipsis={false}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={24}
								type={BasicIconType.Priority}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.priority} />
					</AttachedIcon>
				),
			},
		},
		{
			name: Benefit.EmailSupport,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<FormattedMessage {...benefitValueMessages.standard} />
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<AttachedIcon
						ellipsis={false}
						icon={(
							<BasicIcon
								color="#f4a21e"
								size={24}
								type={BasicIconType.Priority}
							/>
						)}
					>
						<FormattedMessage {...benefitValueMessages.priority} />
					</AttachedIcon>
				),
			},
		},
		{
			name: Benefit.GuidesAndVideos,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Available}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Available}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
		{
			name: Benefit.CustomTraining,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
		{
			name: Benefit.PhoneSupport,
			values: {
				[GraphQL.AccountPlan.C2Starter]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Professional]: (
					<BasicIcon
						size={18}
						type={BasicIconType.Unavailable}
					/>
				),
				[GraphQL.AccountPlan.C2Enterprise]: (
					<BasicIcon
						color="#f4a21e"
						size={18}
						type={BasicIconType.Available}
					/>
				),
			},
		},
	],
};



export function getEnterprisePlanBenefits({ accountIsOutOfBand = false }) {
	return [
		!accountIsOutOfBand ? {
			name: Benefit.UnlimitedUsersWebsitesRechecks,
			iconType: BenefitIconType.UnlimitedUsersWebsitesRechecks,
		} : null,
		accountIsOutOfBand ? {
			name: Benefit.UnlimitedUsersRechecks,
			iconType: BenefitIconType.UnlimitedUsersWebsitesRechecks,
		} : null,
		{
			name: Benefit.RealtimeAuditing,
			iconType: BenefitIconType.RealtimeAuditing,
		},
		{
			name: Benefit.ChangeTrackingAndAlerting,
			iconType: BenefitIconType.ChangeTrackingAndAlerting,
		},
		{
			name: Benefit.BodySnapshots,
			iconType: BenefitIconType.BodySnapshots,
		},
		{
			name: Benefit.LogFileAnalysis,
			iconType: BenefitIconType.LogFileAnalysis,
		},
		{
			name: Benefit.CWVMonitoringAndJSRendering,
			iconType: BenefitIconType.CWVMonitoringAndJSRendering,
		},
		{
			name: Benefit.DedicatedSuccessManager,
			iconType: BenefitIconType.DedicatedSuccessManager,
		},
		{
			name: Benefit.OnboardingAndCustomTraining,
			iconType: BenefitIconType.OnboardingAndCustomTraining,
		},
		{
			name: Benefit.PrioritySupport,
			iconType: BenefitIconType.PrioritySupport,
		},
		{
			name: Benefit.CustomElements,
			iconType: BenefitIconType.CustomElements,
		},
		{
			name: Benefit.DataRetention,
			iconType: BenefitIconType.DataRetention,
		},
		{
			name: Benefit.AnalyticsAndGSCIntegrations,
			iconType: BenefitIconType.AnalyticsAndGSCIntegrations,
		},
		{
			name: Benefit.DataStudioAndAPIs,
			iconType: BenefitIconType.DataStudioAndAPIs,
		},
		{
			name: Benefit.ChatPlatformIntegrations,
			iconType: BenefitIconType.ChatPlatformIntegrations,
		},
		{
			name: Benefit.CustomMonitoringLocation,
			iconType: BenefitIconType.CustomMonitoringLocation,
		},
		{
			name: Benefit.InvoiceBilling,
			iconType: BenefitIconType.InvoiceBilling,
		},
		{
			name: Benefit.AdminAndAccessControlFeatures,
			iconType: BenefitIconType.AdminAndAccessControlFeatures,
		},
	].filter(notEmpty);
}



export function comparePlans(previous, next) {
	return _comparePlans([
		GraphQL.AccountPlan.Basic,
		GraphQL.AccountPlan.Standard,
		GraphQL.AccountPlan.Pro,
		GraphQL.AccountPlan.Prov4,
		GraphQL.AccountPlan.EnterpriseLite,
		GraphQL.AccountPlan.Enterprise,
	], previous, next);
}



export function isFeatureAvailable(
	accountFeaturesAvailability: ReadonlyArray<{
		accountFeature: GraphQL.AccountFeature,
		plan: GraphQL.AccountPlan,
		status: GraphQL.AccountFeatureAvailabilityStatus,
		tariff: string,
	}>,
	tariff: string,
	plan: GraphQL.AccountPlan,
	featureName: GraphQL.AccountFeature,
) {
	const featureAvailability = accountFeaturesAvailability.find(
		(item) => (
			item.accountFeature === featureName
			&& item.plan === plan
			&& item.tariff === tariff
		),
	);

	if (featureAvailability === undefined) {
		return true;
	}

	return featureAvailability.status === GraphQL.AccountFeatureAvailabilityStatus.Available;
}



export function getPlanAvailabilityModern(
	accountFeaturesAvailability: ReadonlyArray<{
		accountFeature: GraphQL.AccountFeature,
		plan: GraphQL.AccountPlan,
		status: GraphQL.AccountFeatureAvailabilityStatus,
		tariff: string,
	}>,
	accountPlan: GraphQL.AccountPlan,
	accountTariff: string,
	plan: GraphQL.AccountPlan,
	usedFeatures: ReadonlyArray<GraphQL.AccountFeature>,
) {
	if (accountPlan === plan) {
		return {
			conflictingFeatures: [],
			isAvailable: true,
		};
	}

	const hierarchy = {
		[GraphQL.AccountFeature.CustomElements_5]: [
			GraphQL.AccountFeature.CustomElements_2,
		],
		[GraphQL.AccountFeature.CustomElements_10]: [
			GraphQL.AccountFeature.CustomElements_2,
			GraphQL.AccountFeature.CustomElements_5,
		],
		[GraphQL.AccountFeature.CustomElements_15]: [
			GraphQL.AccountFeature.CustomElements_2,
			GraphQL.AccountFeature.CustomElements_5,
			GraphQL.AccountFeature.CustomElements_10,
		],
		[GraphQL.AccountFeature.CustomElements_20]: [
			GraphQL.AccountFeature.CustomElements_2,
			GraphQL.AccountFeature.CustomElements_5,
			GraphQL.AccountFeature.CustomElements_10,
			GraphQL.AccountFeature.CustomElements_15,
		],
		[GraphQL.AccountFeature.CustomElements_25]: [
			GraphQL.AccountFeature.CustomElements_2,
			GraphQL.AccountFeature.CustomElements_5,
			GraphQL.AccountFeature.CustomElements_10,
			GraphQL.AccountFeature.CustomElements_15,
			GraphQL.AccountFeature.CustomElements_20,
		],
		[GraphQL.AccountFeature.CustomElements_30]: [
			GraphQL.AccountFeature.CustomElements_2,
			GraphQL.AccountFeature.CustomElements_5,
			GraphQL.AccountFeature.CustomElements_10,
			GraphQL.AccountFeature.CustomElements_15,
			GraphQL.AccountFeature.CustomElements_20,
			GraphQL.AccountFeature.CustomElements_25,
		],
		[GraphQL.AccountFeature.CustomElements_35]: [
			GraphQL.AccountFeature.CustomElements_2,
			GraphQL.AccountFeature.CustomElements_5,
			GraphQL.AccountFeature.CustomElements_10,
			GraphQL.AccountFeature.CustomElements_15,
			GraphQL.AccountFeature.CustomElements_20,
			GraphQL.AccountFeature.CustomElements_25,
			GraphQL.AccountFeature.CustomElements_30,
		],
		[GraphQL.AccountFeature.CustomElements_40]: [
			GraphQL.AccountFeature.CustomElements_2,
			GraphQL.AccountFeature.CustomElements_5,
			GraphQL.AccountFeature.CustomElements_10,
			GraphQL.AccountFeature.CustomElements_15,
			GraphQL.AccountFeature.CustomElements_20,
			GraphQL.AccountFeature.CustomElements_25,
			GraphQL.AccountFeature.CustomElements_30,
			GraphQL.AccountFeature.CustomElements_35,
		],
		[GraphQL.AccountFeature.EnrichmentFields_10]: [
			GraphQL.AccountFeature.EnrichmentFields_5,
		],
		[GraphQL.AccountFeature.EnrichmentFields_15]: [
			GraphQL.AccountFeature.EnrichmentFields_5,
			GraphQL.AccountFeature.EnrichmentFields_10,
		],
		[GraphQL.AccountFeature.EnrichmentFields_20]: [
			GraphQL.AccountFeature.EnrichmentFields_5,
			GraphQL.AccountFeature.EnrichmentFields_10,
			GraphQL.AccountFeature.EnrichmentFields_15,
		],
		[GraphQL.AccountFeature.EnrichmentFields_25]: [
			GraphQL.AccountFeature.EnrichmentFields_5,
			GraphQL.AccountFeature.EnrichmentFields_10,
			GraphQL.AccountFeature.EnrichmentFields_15,
			GraphQL.AccountFeature.EnrichmentFields_20,
		],
		[GraphQL.AccountFeature.EnrichmentFields_30]: [
			GraphQL.AccountFeature.EnrichmentFields_5,
			GraphQL.AccountFeature.EnrichmentFields_10,
			GraphQL.AccountFeature.EnrichmentFields_15,
			GraphQL.AccountFeature.EnrichmentFields_20,
			GraphQL.AccountFeature.EnrichmentFields_25,
		],
		[GraphQL.AccountFeature.EnrichmentFields_35]: [
			GraphQL.AccountFeature.EnrichmentFields_5,
			GraphQL.AccountFeature.EnrichmentFields_10,
			GraphQL.AccountFeature.EnrichmentFields_15,
			GraphQL.AccountFeature.EnrichmentFields_20,
			GraphQL.AccountFeature.EnrichmentFields_25,
			GraphQL.AccountFeature.EnrichmentFields_30,
		],
		[GraphQL.AccountFeature.EnrichmentFields_40]: [
			GraphQL.AccountFeature.EnrichmentFields_5,
			GraphQL.AccountFeature.EnrichmentFields_10,
			GraphQL.AccountFeature.EnrichmentFields_15,
			GraphQL.AccountFeature.EnrichmentFields_20,
			GraphQL.AccountFeature.EnrichmentFields_25,
			GraphQL.AccountFeature.EnrichmentFields_30,
			GraphQL.AccountFeature.EnrichmentFields_35,
		],
	};

	let conflictingFeatures = [
		GraphQL.AccountFeature.Rendering,
		GraphQL.AccountFeature.CustomElements_2,
		GraphQL.AccountFeature.CustomElements_5,
		GraphQL.AccountFeature.CustomElements_10,
		GraphQL.AccountFeature.CustomElements_15,
		GraphQL.AccountFeature.CustomElements_20,
		GraphQL.AccountFeature.CustomElements_25,
		GraphQL.AccountFeature.CustomElements_30,
		GraphQL.AccountFeature.CustomElements_35,
		GraphQL.AccountFeature.CustomElements_40,
		GraphQL.AccountFeature.CustomAlertTrigger,
		GraphQL.AccountFeature.EnrichmentFields_5,
		GraphQL.AccountFeature.EnrichmentFields_10,
		GraphQL.AccountFeature.EnrichmentFields_15,
		GraphQL.AccountFeature.EnrichmentFields_20,
		GraphQL.AccountFeature.EnrichmentFields_25,
		GraphQL.AccountFeature.EnrichmentFields_30,
		GraphQL.AccountFeature.EnrichmentFields_35,
		GraphQL.AccountFeature.EnrichmentFields_40,
		GraphQL.AccountFeature.MicrosoftTeams,
		GraphQL.AccountFeature.ReportingApi,
		GraphQL.AccountFeature.LighthouseMonitoring,
		GraphQL.AccountFeature.LogFileAnalysis,
		GraphQL.AccountFeature.WebVitalsOriginSummary,
		GraphQL.AccountFeature.AdminRole,
		GraphQL.AccountFeature.IssuesConfiguration,
	].filter((featureName) => (
		!isFeatureAvailable(accountFeaturesAvailability, accountTariff, plan, featureName)
		&& usedFeatures.includes(featureName)
	));

	conflictingFeatures = conflictingFeatures.filter((featureName) => (
		!hierarchy[featureName]
		|| !hierarchy[featureName].some((overridingFeatureName) => conflictingFeatures.includes(overridingFeatureName))
	));

	return {
		conflictingFeatures,
		isAvailable: conflictingFeatures.length === 0,
	};
}



export function findHighestPlan(plans) {
	return [
		GraphQL.AccountPlan.Enterprise,
		GraphQL.AccountPlan.Prov4,
		GraphQL.AccountPlan.Pro,
		GraphQL.AccountPlan.Standard,
		GraphQL.AccountPlan.Basic,
	].find((plan) => plans.includes(plan)) ?? null;
}
