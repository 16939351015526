import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	FormattedMessage,
	FormattedNumber,
	defineMessages,
} from 'react-intl';

import AlertPagesButton from '../AlertPagesButton';
import ExpressiveValue, {
	ExpressiveValueLabelFlow,
	ExpressiveValueSize,
	ExpressiveValueStatus,
} from '~/components/patterns/values/ExpressiveValue';
import IncidentBoxContent from '~/components/patterns/incidents/IncidentBoxContent';
import List from '~/components/patterns/lists/List';
import RemovedFromAlertMetric from './RemovedFromAlertMetric';
import SeparatedBlocks from '~/components/patterns/structures/SeparatedBlocks';

import {
	countPagesSplit,
} from '~/model/alerts';

import {
	TYPE_MISSING,
	TYPE_REDIRECT,
	TYPE_SERVER_ERROR,
} from '~/model/pages';



const messages = defineMessages({
	affected: {
		id: 'ui.alerts.overview.incident.details.affected',
	},
	restored: {
		id: 'ui.alerts.overview.incident.details.restored',
	},
	split: {
		[TYPE_MISSING]: {
			id: 'ui.alerts.overview.incident.details.pageType.missing',
		},
		[TYPE_REDIRECT]: {
			id: 'ui.alerts.overview.incident.details.pageType.redirect',
		},
		[TYPE_SERVER_ERROR]: {
			id: 'ui.alerts.overview.incident.details.pageType.serverError',
		},
		unreachable: {
			id: 'ui.alerts.overview.incident.details.pageType.unreachable',
		},
	},
});



class PageBecameNonPageDetails extends Component {

	render() {
		const {
			incident,
			websiteId,
		} = this.props;

		const isIncidentActive = incident.get('phase') === 'active';

		const isModern = incident.hasIn(['state', 'pages', 'split']);

		const numberOfAffected = isModern
			? (
				countPagesSplit(incident, TYPE_MISSING)
				+ countPagesSplit(incident, TYPE_REDIRECT)
				+ countPagesSplit(incident, TYPE_SERVER_ERROR)
				+ countPagesSplit(incident, 'unreachable')
			)
			: incident.getIn(['state', 'affected_pages', 'total']);

		const numberOfRestored = isModern
			? countPagesSplit(incident, 'restored')
			: null;

		const numberOfRemovedFromAlert = isModern
			? (
				countPagesSplit(incident, 'not_applicable')
				+ countPagesSplit(incident, 'over_quota')
			)
			: 0;

		const numberOfAlertPages = (
			numberOfAffected
			+ (numberOfRestored || 0)
			+ numberOfRemovedFromAlert
		);

		return (
			<IncidentBoxContent
				button={numberOfAlertPages > 0 && (
					<AlertPagesButton
						focusOnPrimary={isIncidentActive && numberOfAffected > 0}
						incident={incident}
						websiteId={websiteId}
					/>
				)}
			>
				<SeparatedBlocks divider={false}>
					<ExpressiveValue
						label={(
							<FormattedMessage
								{...messages.affected}
								values={{
									count__pages: numberOfAffected,
								}}
							/>
						)}
						labelFlow={ExpressiveValueLabelFlow.Column}
						size={ExpressiveValueSize.Large}
						status={isIncidentActive ? ExpressiveValueStatus.Warning : ExpressiveValueStatus.Neutral}
						value={(
							<FormattedNumber value={numberOfAffected} />
						)}
					/>

					{numberOfRestored !== null && (
						<ExpressiveValue
							label={(
								<FormattedMessage
									{...messages.restored}
									values={{
										count__pages: numberOfRestored,
									}}
								/>
							)}
							labelFlow={ExpressiveValueLabelFlow.Column}
							size={ExpressiveValueSize.Large}
							value={(
								<FormattedNumber value={numberOfRestored} />
							)}
						/>
					)}
				</SeparatedBlocks>

				{isModern && numberOfAffected > 0 && (
					<List>
						{[TYPE_REDIRECT, TYPE_MISSING, TYPE_SERVER_ERROR, 'unreachable'].map((pageType) => {
							const numberOfPages = countPagesSplit(incident, pageType);

							if (numberOfPages === 0) {
								return false;
							}

							return (
								<ExpressiveValue
									key={pageType}
									label={(
										<FormattedMessage
											{...messages.split[pageType]}
											values={{
												count__pages: numberOfPages,
											}}
										/>
									)}
									status={isIncidentActive ? ExpressiveValueStatus.Warning : ExpressiveValueStatus.Neutral}
									value={(
										<FormattedNumber value={numberOfPages} />
									)}
								/>
							);
						})}
					</List>
				)}

				{numberOfRemovedFromAlert > 0 && (
					<RemovedFromAlertMetric
						numberOfRemovedFromAlert={numberOfRemovedFromAlert}
					/>
				)}
			</IncidentBoxContent>
		);
	}

}

PageBecameNonPageDetails.propTypes = {
	incident: PropTypes.object.isRequired,
	websiteId: PropTypes.string,
};



export default PageBecameNonPageDetails;
