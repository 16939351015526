import Immutable, {
	Map,
} from 'immutable';

import {
	content,
} from './content/reducer';
import {
	interfaceHints,
} from './interfaceHints/reducer';
import {
	menu,
} from './menu/reducer';
import {
	uiInteractions,
} from './uiInteractions/reducer';

import {
	ANIMATE_HEARTBEAT,
	CHANGE_URL_STATE,
	COLUMNS_CONFIGURATOR_CLOSE,
	COLUMNS_CONFIGURATOR_OPEN,
	COLUMNS_SETS_LIST_TOGGLE,
	LOGOUT_SUCCESSFUL,
	MARK_APP_EMPTY_STATE,
	REMOVE_HOVERED_CATEGORY,
	STORE_HOVERED_CATEGORY,
	UPDATE_ISSUES_FILTER,
	UPDATE_PAGES_FILTER,
	UPDATE_PAGES_SORTING,
} from '~/actions';

import {
	RESET_HISTORICAL_CHANGES_INTERVAL,
} from '~/actions/historicalChanges';

import {
	CHANGE_VIEW,
	SET_COLUMNS,
} from '~/actions/teams/views';

import {
	SEGMENTS_FINISH_SEGMENT_DELETION,
} from '~/actions/segments';

import {
	DEFAULT_FILTER as DEFAULT_ISSUES_FILTER,
} from '~/model/issues';

import {
	DEFAULT_FILTER as DEFAULT_PAGES_FILTER,
	DEFAULT_SORT_BY as DEFAULT_PAGES_SORT_BY,
} from '~/model/pages';

import decodeFromBase64 from '~/utilities/decodeFromBase64';



function createDefaultState() {
	return new Map({
		active: new Map({
			website: null,
			memberDetailId: '0',
			member: '0',
			page: null,
			team: null,
			section: 'website.pages',
		}),
		animateHeartbeat: false,
		appEmptyState: false,
		columnsConfigurator: false,
		columnsSetsList: false,
		issuesOverviewFilter: DEFAULT_ISSUES_FILTER,
		listenToUrlState: true,
		pagesOverviewFilter: DEFAULT_PAGES_FILTER,
		pagesOverviewSortBy: DEFAULT_PAGES_SORT_BY,
		urlState: {
			name: 'home',
			params: {},
		},
		hoveredCategory: null,
	});
}



function unpackFilter(packedFilter) {
	return JSON.parse(
		decodeFromBase64(packedFilter),
	);
}



function determineActiveSection(state, urlState) {
	let section;

	if (urlState.name.indexOf('website.pages') === 0) {
		section = 'website.pages';
	} else if (urlState.name.indexOf('website.issues') === 0) {
		section = 'website.issues';
	} else if (urlState.name.indexOf('website.platform') === 0) {
		section = 'website.platform';
	} else if (urlState.name.indexOf('website.alerts') === 0) {
		section = 'website.alerts';
	} else if (urlState.name.indexOf('website.dashboard') === 0) {
		section = 'website.dashboard';
	} else if (urlState.name.indexOf('website.detail') === 0 || urlState.name.indexOf('website.segmentEditor') === 0) {
		section = 'website.detail';
	} else if (urlState.name === 'account' || urlState.name.indexOf('account.websites') === 0) {
		section = 'account.websites';
	} else if (urlState.name.indexOf('account.members') === 0) {
		section = 'account.members';
	} else if (urlState.name.indexOf('account.profile') === 0) {
		section = 'account.profile';
	} else if (urlState.name.indexOf('userProfile') === 0) {
		section = 'userProfile';
	} else if (urlState.name.indexOf('account.pricing') === 0) {
		section = 'account.pricing';
	}

	return state.mergeDeep({
		active: {
			section,
		},
	});
}



function processUrlState(state, urlState) {
	if (urlState.name.indexOf('account.members.member') === 0) {
		state = state.mergeDeep({
			active: {
				memberDetailId: decodeURIComponent(urlState.params.memberDetailId.toString()),
			},
		});
	}

	if (urlState.name.indexOf('website') === 0) {
		state = state.mergeDeep({
			active: {
				website: urlState.params.websiteId.toString(),
			},
		});
	}

	if (
		urlState.name.indexOf('account.websites.website') === 0
		|| (
			urlState.name.indexOf('account.websites.new') === 0
			&& urlState.params.websiteId
		)
	) {
		state = state.mergeDeep({
			active: {
				website: urlState.params.websiteId.toString(),
			},
		});
	}

	if (urlState.name === 'website.pages') {
		if ((!urlState.params.start_date || !urlState.params.end_date)) {
			if (urlState.params.filter) {
				let filter;

				try {
					filter = unpackFilter(urlState.params.filter);
				} catch (e) {
					filter = {};
				}

				if (filter.filter) {
					const newFilter = Immutable.fromJS(filter.filter);

					if (!Immutable.is(newFilter, state.get('pagesOverviewFilter'))) {
						state = state.set('pagesOverviewFilter', newFilter);
					}
				}

				if (filter.sortBy) {
					if (!Immutable.is(filter.sortBy, state.get('pagesOverviewSortBy'))) {
						state = state.mergeDeep({
							pagesOverviewSortBy: filter.sortBy,
						});
					}
				}
			} else {
				if (!Immutable.is(DEFAULT_PAGES_FILTER, state.get('pagesOverviewFilter'))) {
					state = state.set('pagesOverviewFilter', DEFAULT_PAGES_FILTER);
				}

				if (!Immutable.is(DEFAULT_PAGES_SORT_BY, state.get('pagesOverviewSortBy'))) {
					state = state.mergeDeep({
						pagesOverviewSortBy: DEFAULT_PAGES_SORT_BY,
					});
				}
			}
		}
	}

	if (
		urlState.name === 'website.issues'
		|| urlState.name === 'website.issues.filter'
		|| urlState.name === 'website.issues.issueCategory'
	) {
		if (!urlState.params.start_date) {
			if (urlState.params.filter) {
				let filter;

				try {
					filter = unpackFilter(urlState.params.filter);
				} catch (e) {
					filter = DEFAULT_ISSUES_FILTER;
				}

				if (filter) {
					state = state.merge({
						issuesOverviewFilter: filter,
					});
				}
			} else {
				state = state.merge({
					issuesOverviewFilter: DEFAULT_ISSUES_FILTER,
				});
			}
		}
	}

	if (urlState.name.indexOf('website.pages.detail') === 0 && urlState.params.id) {
		state = state.mergeDeep({
			active: {
				page: urlState.params.id.toString(),
			},
		});
	}

	if (state.get('urlState')) {
		if (state.get('urlState').params.websiteId && urlState.params.websiteId && urlState.params.websiteId !== state.get('urlState').params.websiteId) {
			if (state.get('pagesOverviewFilter').has('segments')) {
				state = state.mergeIn(['pagesOverviewFilter'], {
					segments: DEFAULT_PAGES_FILTER.get('segments'),
				});
			}

			state = state.mergeIn(['issuesOverviewFilter'], {
				segment: DEFAULT_ISSUES_FILTER.get('segment'),
			});
		}
	}

	state = determineActiveSection(state, urlState);
	state = state.set('urlState', urlState);

	return state;
}



export function ui(state, action) {
	if (state === undefined) {
		state = createDefaultState();
	}

	state = state.merge({
		content: content(state.get('content'), action),
	});

	state = state.merge({
		interfaceHints: interfaceHints(state.get('interfaceHints'), action),
	});

	state = state.merge({
		menu: menu(state.get('menu'), action),
	});

	state = state.merge({
		uiInteractions: uiInteractions(state.get('uiInteractions'), action),
	});

	if (action && action.meta && action.type !== CHANGE_URL_STATE) {
		state = state.set('listenToUrlState', false);
	}

	switch (action.type) {

		case COLUMNS_CONFIGURATOR_OPEN: {
			state = state.set('columnsConfigurator', true);

			break;
		}

		case COLUMNS_CONFIGURATOR_CLOSE: {
			state = state.set('columnsConfigurator', false);

			break;
		}

		case COLUMNS_SETS_LIST_TOGGLE: {
			const {
				value,
			} = action;

			state = state.set('columnsSetsList', value);

			break;
		}

		case CHANGE_URL_STATE: {
			const urlState = action.urlState;

			if (urlState.name.indexOf('account') === 0 && state.get('urlState') && state.get('urlState').name.indexOf('account') < 0) {
				state = state.set('menu', state.get('menu').set('userNavigation', false));
			}

			if (state.get('listenToUrlState')) {
				state = processUrlState(state, urlState);
			} else {
				state = state.set('listenToUrlState', true);
			}

			if (urlState.name.indexOf('website.pages') === -1) {
				state = state.set('columnsConfigurator', false);
			}

			break;
		}

		case MARK_APP_EMPTY_STATE: {
			const {
				value,
			} = action;
			state = state.set('appEmptyState', value);

			break;
		}

		case REMOVE_HOVERED_CATEGORY:
		case STORE_HOVERED_CATEGORY: {
			const {
				category,
				type,
			} = action;

			state = state.set(
				'hoveredCategory',
				type === STORE_HOVERED_CATEGORY
					? category
					: null,
			);

			break;
		}

		case ANIMATE_HEARTBEAT: {
			const { animate } = action;
			state = state.set('animateHeartbeat', animate);

			break;
		}

		case UPDATE_PAGES_FILTER: {
			const newFilter = state
				.get('pagesOverviewFilter')
				.merge(action.filter)
				.filter((val) => val !== '');

			if (!Immutable.is(state.get('pagesOverviewFilter'), newFilter)) {
				state = state.set('pagesOverviewFilter', newFilter);
			}

			break;
		}

		case UPDATE_ISSUES_FILTER: {
			const {
				filter,
			} = action;

			state = state.mergeIn(['issuesOverviewFilter'], filter);
			state = state.setIn(['issuesOverviewFilter'], state.get('issuesOverviewFilter').filter((val) => val !== ''));

			break;
		}

		case UPDATE_PAGES_SORTING: {
			if (!Immutable.is(state.get('pagesOverviewSortBy'), action.sortBy)) {
				state = state.merge({
					pagesOverviewSortBy: action.sortBy,
				});
			}

			break;
		}

		case RESET_HISTORICAL_CHANGES_INTERVAL: {
			const {
				historicalChangesFilter,
				historicalChangesSortBy,
			} = action;

			state = state.set(
				'pagesOverviewFilter',
				historicalChangesFilter
					.remove('change_type')
					.remove('changes_in'),
			);

			if (historicalChangesSortBy.get('key') !== 'change_type') {
				state = state.set('pagesOverviewSortBy', historicalChangesSortBy);
			}

			break;
		}

		case CHANGE_VIEW:
			const {
				closeColumnsConfigurator,
			} = action;

			state = state.set('columnsSetsList', false);

			if (closeColumnsConfigurator) {
				state = state.set('columnsConfigurator', false);
			}

		case SET_COLUMNS: {
			const {
				columns,
			} = action;

			let pagesFilter = state.get('pagesOverviewFilter');

			columns.forEach((column) => {
				if (column.enabled) {
					if (!pagesFilter.has(column.name) && DEFAULT_PAGES_FILTER.has(column.name)) {
						pagesFilter = pagesFilter.set(
							column.getname,
							DEFAULT_PAGES_FILTER.get(column.name),
						);
					}
				} else {
					if (pagesFilter.has(column.name)) {
						pagesFilter = pagesFilter.delete(column.name);
					}
				}
			});

			if (state.get('pagesOverviewFilter') !== pagesFilter) {
				state = state.set('pagesOverviewFilter', pagesFilter);
			}

			break;
		}

		case LOGOUT_SUCCESSFUL: {
			const urlState = state.get('urlState');

			state = createDefaultState();

			state = state.set('urlState', urlState);

			break;
		}

		case SEGMENTS_FINISH_SEGMENT_DELETION: {
			const {
				lastFilter,
			} = action;

			state = state.set('pagesOverviewFilter', lastFilter);

			break;
		}

	}

	if (!state.get('listenToUrlState')) {
		state = state.set('listenToUrlState', true);
	}

	return state;
}
