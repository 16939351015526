import {
	Map,
} from 'immutable';
import {
	captureException,
	withScope,
} from '@sentry/browser';



import {
	affectedLinks,
} from '~/state/affectedLinks/reducer';
import {
	affectedPages,
} from '~/state/affectedPages/reducer';
import {
	affectedPagesComparison,
} from '~/state/affectedPagesComparison/reducer';
import {
	alertPages,
} from '~/state/alertPages/reducer';
import {
	alertsConfiguration,
} from '~/state/alertsConfiguration/reducer';
import {
	columnsSettings,
} from '~/state/columnsSettings/reducer';
import {
	dataLoading,
} from '~/state/dataLoading/reducer';
import {
	changeTrackingPeekedColumns,
} from '~/state/changeTrackingPeekedColumns/reducer';
import {
	featureFlags,
} from '~/state/featureFlags/reducer';
import {
	flashMessage,
} from '~/state/flashMessage/reducer';
import {
	historicalChanges,
} from '~/state/historicalChanges/reducer';
import {
	incidents,
} from '~/state/incidents/reducer';
import {
	localization,
} from '~/state/localization/reducer';
import {
	pages,
} from '~/state/pages/reducer';
import {
	pagesColumns,
} from '~/state/pagesColumns/reducer';
import {
	ui,
} from '~/state/ui/reducer';
import {
	authentication,
} from '~/state/authentication/reducer';
import {
	lastSearchedWebsite,
} from '~/state/lastSearchedWebsite/reducer';
import {
	lastSelectedWebsite,
} from '~/state/lastSelectedWebsite/reducer';
import {
	reducer as paymentAuthorization,
} from '~/state/paymentAuthorization';
import {
	teams,
} from '~/state/teams/reducer';
import {
	duplicateContentUrls,
} from '~/state/duplicateContentUrls/reducer';
import {
	sitemapsList,
} from '~/state/sitemapsList/reducer';
import {
	reducer as orphanedSitemaps,
} from '~/state/orphanedSitemaps';
import {
	reducer as inaccessibleSitemaps,
} from '~/state/inaccessibleSitemaps';
import {
	reducer as invalidContentsSitemaps,
} from '~/state/invalidContentsSitemaps';
import {
	reducer as invalidStructureSitemaps,
} from '~/state/invalidStructureSitemaps';
import {
	reducer as sitemapsErrors,
} from '~/state/sitemapsErrors';
import {
	reducer as sitemapsViolatingFilesizeLimit,
} from '~/state/sitemapsViolatingFilesizeLimit';
import {
	reducer as sitemapsViolatingUrlcountLimit,
} from '~/state/sitemapsViolatingUrlcountLimit';
import {
	pagesGraphs,
} from '~/state/pagesGraphs/reducer';
import {
	tariffs,
} from '~/state/tariffs/reducer';
import {
	websiteIssuesComparison,
} from '~/state/websiteIssuesComparison/reducer';
import {
	_window,
} from '~/state/window/reducer';



function processSubstate(state, name, reducer, action) {
	return state.merge({
		[name]: reducer(state.get(name), action),
	});
}



const substates = Map({
	authentication,
	columnsSettings,
	ui,
	affectedLinks,
	affectedPages,
	affectedPagesComparison,
	alertPages,
	alertsConfiguration,
	changeTrackingPeekedColumns,
	featureFlags,
	flashMessage,
	historicalChanges,
	incidents,
	pages,
	pagesColumns,
	paymentAuthorization,
	localization,
	dataLoading,
	lastSearchedWebsite,
	lastSelectedWebsite,
	teams,
	duplicateContentUrls,
	sitemapsList,
	orphanedSitemaps,
	inaccessibleSitemaps,
	invalidContentsSitemaps,
	invalidStructureSitemaps,
	sitemapsErrors,
	sitemapsViolatingFilesizeLimit,
	sitemapsViolatingUrlcountLimit,
	pagesGraphs,
	tariffs,
	websiteIssuesComparison,
	window: _window,
});



export default function (state = Map(), action) {
	substates.forEach((reducer, name) => {
		try {
			state = processSubstate(state, name, reducer, action);
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);

			withScope((scope) => {
				scope.setTag('impact', 'state_update');
				captureException(error);
			});
		}
	});

	return state;
}
