/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TwoFactorAuthenticationFormQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type TwoFactorAuthenticationFormQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly isTwoFactorAuthenticationEnforced: boolean }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }> } | null };


export const TwoFactorAuthenticationFormDocument = gql`
    query TwoFactorAuthenticationForm {
  authenticatedSession {
    memberships {
      account {
        id
        isTwoFactorAuthenticationEnforced
      }
      user {
        id
        legacyId
      }
    }
  }
}
    `;

/**
 * __useTwoFactorAuthenticationFormQuery__
 *
 * To run a query within a React component, call `useTwoFactorAuthenticationFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useTwoFactorAuthenticationFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTwoFactorAuthenticationFormQuery({
 *   variables: {
 *   },
 * });
 */
export function useTwoFactorAuthenticationFormQuery(baseOptions?: Apollo.QueryHookOptions<TwoFactorAuthenticationFormQuery, TwoFactorAuthenticationFormQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TwoFactorAuthenticationFormQuery, TwoFactorAuthenticationFormQueryVariables>(TwoFactorAuthenticationFormDocument, options);
      }
export function useTwoFactorAuthenticationFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TwoFactorAuthenticationFormQuery, TwoFactorAuthenticationFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TwoFactorAuthenticationFormQuery, TwoFactorAuthenticationFormQueryVariables>(TwoFactorAuthenticationFormDocument, options);
        }
export function useTwoFactorAuthenticationFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TwoFactorAuthenticationFormQuery, TwoFactorAuthenticationFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TwoFactorAuthenticationFormQuery, TwoFactorAuthenticationFormQueryVariables>(TwoFactorAuthenticationFormDocument, options);
        }
export type TwoFactorAuthenticationFormQueryHookResult = ReturnType<typeof useTwoFactorAuthenticationFormQuery>;
export type TwoFactorAuthenticationFormLazyQueryHookResult = ReturnType<typeof useTwoFactorAuthenticationFormLazyQuery>;
export type TwoFactorAuthenticationFormSuspenseQueryHookResult = ReturnType<typeof useTwoFactorAuthenticationFormSuspenseQuery>;
export type TwoFactorAuthenticationFormQueryResult = Apollo.QueryResult<TwoFactorAuthenticationFormQuery, TwoFactorAuthenticationFormQueryVariables>;