import React from 'react';

import DropdownIcon, {
	DropdownIconStyle,
} from '~/components/patterns/icons/DropdownIcon';

import styles from './ColumnsSetPicker.module.scss';



type Props = {
	caption: React.ReactNode,
	isActive?: boolean,
	onClick?: () => void,
	tag?: React.ReactNode,
	title: React.ReactNode,
};

const ColumnsSetPicker: React.FC<Props> = (props) => {
	const {
		caption,
		isActive,
		onClick,
		tag,
		title,
	} = props;


	return (
		<button
			className={styles.component}
			onClick={onClick}
		>
			<span className={styles.content}>
				<span className={styles.caption}>
					{caption}
				</span>
				<span className={styles.name}>
					{title}

					<span className={styles.tag}>{tag}</span>
				</span>
			</span>
			<span className={styles.toggleIcon}>
				<DropdownIcon
					isActive={isActive}
					style={DropdownIconStyle.White}
				/>
			</span>
		</button>
	);
};



export default ColumnsSetPicker;
