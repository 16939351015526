import React from 'react';

import type CK from '~/types/contentking';

import ContributorsAssignmentForm from '~/components/app/ContributorsAssignmentForm';
import EditEndUserWebsiteSubscriptionForm from '~/components/atoms/forms/forms/EditEndUserWebsiteSubscriptionForm';
import EditWebsiteNameForm from '~/components/app/EditWebsiteNameForm';
import EditWebsiteSubscriptionForm from '~/components/atoms/forms/forms/EditWebsiteSubscriptionForm';
import FormsList from '~/components/atoms/lists/FormsList';

import {
	useUpdateWebsitePageCapacityMutation,
} from '~/components/app/WebsiteDetailsGroup.gql';

import useAccountTariff from '~/hooks/useAccountTariff';
import useWebsiteAccountId from '~/hooks/useWebsiteAccountId';
import useRequestAdminConfirmationIfApplicable from '~/hooks/useRequestAdminConfirmationIfApplicable';

import {
	Tariff,
} from '~/model/pricing/tariffs';



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteDetailsGroup: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const websiteAccountId = useWebsiteAccountId(websiteId);

	const accountTariff = useAccountTariff(websiteAccountId);
	const requestAdminConfirmationIfApplicable = useRequestAdminConfirmationIfApplicable(websiteAccountId);

	const [updateWebsitePageCapacity] = useUpdateWebsitePageCapacityMutation();

	const handleWebsiteSubscriptionSubmit = React.useCallback(
		async ({ threeDSecureToken, values }: {
			threeDSecureToken: string | null,
			values: {
				pageCapacity: number,
			},
		}) => {
			await requestAdminConfirmationIfApplicable();

			await updateWebsitePageCapacity({
				variables: {
					pageCapacity: values.pageCapacity,
					threeDSecureToken,
					websiteId,
				},
			});
		},
		[
			requestAdminConfirmationIfApplicable,
			updateWebsitePageCapacity,
			websiteId,
		],
	);

	return (
		<FormsList>
			<EditWebsiteNameForm
				websiteId={websiteId}
			/>

			{accountTariff === Tariff.V2 ? (
				<EditEndUserWebsiteSubscriptionForm
					submitCallback={handleWebsiteSubscriptionSubmit}
					websiteId={websiteId}
				/>
			) : (
				<EditWebsiteSubscriptionForm
					submitCallback={handleWebsiteSubscriptionSubmit}
					websiteId={websiteId}
				/>
			)}

			{websiteAccountId !== null && (
				<ContributorsAssignmentForm
					websiteAccountId={websiteAccountId}
					websiteId={websiteId}
				/>
			)}
		</FormsList>
	);
};



export default WebsiteDetailsGroup;
