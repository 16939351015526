import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AffectedPagesComparisonFilterBar from '~/components/logic/affectedPages/activeTableFilterBar/AffectedPagesComparisonFilterBar';
import Backlink from '~/components/logic/links/Backlink';
import ComparisonEntry from '~/components/logic/issuesOverview/components/ComparisonEntry';
import ExportHeaderButton from '~/components/app/ExportHeaderButton';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderFilterLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderFilterLayout';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleWithNumericValue, {
	SIZE_SMALL as TITLE_SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithNumericValue';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import IssueTitle from '~/components/logic/issues/IssueTitle';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';

import useExportHistoricalIssueAffectedPages from '~/hooks/useExportHistoricalIssueAffectedPages';
import useGetIssue from '~/hooks/useGetIssue';
import useGetIssueCategory from '~/hooks/useGetIssueCategory';
import useHistoricalIssuesComparison from '~/hooks/useHistoricalIssuesComparison';
import useViewportType from '~/hooks/useViewportType';

import {
	type IssueCategoryName,
	type IssueName,
} from '~/model/issuesNew';

import {
	horizontalScrollTo,
} from '~/utilities/scrollTo';



const messages = defineMessages({
	exportButton: {
		id: 'ui.exports.type.general.button.openDialog.label',
	},
});



type Props = {
	backlinkCallback: (() => void) | null,
	backlinkLabel: React.ReactNode,
	issueCategoryName: IssueCategoryName,
	issueName: IssueName,
	numberOfAffectedPages: number,
	websiteId: CK.WebsiteId,
};

const HistoricalAffectedPagesScreenHeader: React.FC<Props> = (props) => {
	const {
		backlinkCallback,
		backlinkLabel,
		issueCategoryName,
		issueName,
		numberOfAffectedPages,
		websiteId,
	} = props;

	const exportHistoricalIssueAffectedPages = useExportHistoricalIssueAffectedPages();
	const historicalIssuesComparison = useHistoricalIssuesComparison(websiteId);
	const viewportType = useViewportType();

	const issueCategory = useGetIssueCategory(historicalIssuesComparison, issueCategoryName);
	const issue = useGetIssue(issueCategory, issueName);

	return (
		<ScreenHeader>
			<HeaderTitleLayout>
				{backlinkCallback && backlinkLabel && (
					<Backlink
						callback={backlinkCallback}
						label={backlinkLabel}
					/>
				)}

				<HeaderTitleWithNumericValue
					size={TITLE_SIZE_SMALL}
					title={issue !== null && (
						<IssueTitle
							issue={issue}
						/>
					)}
					value={numberOfAffectedPages}
				/>
			</HeaderTitleLayout>

			<HeaderFilterLayout compactView={viewportType.isSmall || viewportType.isMedium}>
				<AffectedPagesComparisonFilterBar
					onFilterClickCallback={(filterName) => {
						horizontalScrollTo(
							document.querySelector('.rv-table__content'),
							(document.querySelector('.table-header-cell--cell-' + filterName) as any).offsetLeft,
						);
					}}
				/>
			</HeaderFilterLayout>

			<HeaderOptionsLayout>
				<HeaderButtonsLayout>
					<ComparisonEntry />

					<ExportHeaderButton
						createExport={exportHistoricalIssueAffectedPages}
						linkLabel={(
							<FormattedMessage {...messages.exportButton} />
						)}
					/>
				</HeaderButtonsLayout>
			</HeaderOptionsLayout>
		</ScreenHeader>
	);
};



export default React.memo(HistoricalAffectedPagesScreenHeader);
