import React from 'react';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import useCreateExport from '~/hooks/useCreateExport';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	currentDataSelector as alertPagesCurrentDataSelector,
	currentFilterSelector,
	currentSortBySelector,
} from '~/state/alertPages/selectors';

import {
	currentDataSelector as incidentsCurrentDataSelector,
} from '~/state/incidents/selectors';

import {
	alertIdSelector,
} from '~/state/ui/content/selectors';



function useExportAlertPages() {
	const alertId = useSelector(alertIdSelector) ?? null;
	const websiteId = useWebsiteId();

	const createExport = useCreateExport(websiteId);
	const filter = useSelector(currentFilterSelector);
	const sortBy = useSelector(currentSortBySelector);
	const incidents = useSelector(incidentsCurrentDataSelector);
	const totalCount = useSelector(alertPagesCurrentDataSelector).get('total');

	const isAlertLoaded = alertId !== null && (incidents.get(alertId) || incidents.get(alertId + ''));
	const isLarge = totalCount >= 200000;

	return React.useCallback(
		async () => {
			if (isAlertLoaded === false) {
				throw new Error(
					`Incident can't be not loaded yet when exporting alert pages`,
				);
			}

			const exportData = await createExport({
				criteria: {
					alertId,
					filter: filter.toJS(),
					sortBy: sortBy.toJS(),
				},
				type: GraphQL.ExportType.AlertPages,
			});

			return {
				...exportData,
				isLarge,
			};
		},
		[
			alertId,
			createExport,
			filter,
			isAlertLoaded,
			isLarge,
			sortBy,
		],
	);
}



export default useExportAlertPages;
