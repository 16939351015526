/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SearchEngineVisitsPerDayFragment = { readonly __typename?: 'PageSearchEngineActivityVisitsPerDay', readonly date: CK.DateYMD, readonly isDateComplete: boolean, readonly numberOfVisits: number };

export type SearchEngineActivityBlockQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type SearchEngineActivityBlockQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly searchEngineActivity: { readonly __typename?: 'PageSearchEngineActivity', readonly lastVisitOfBingDesktop: CK.Timestamp | null, readonly lastVisitOfBingMobile: CK.Timestamp | null, readonly lastVisitOfGoogleDesktop: CK.Timestamp | null, readonly lastVisitOfGoogleMobile: CK.Timestamp | null, readonly visitFrequencyOfBingDesktop: number | null, readonly visitFrequencyOfBingMobile: number | null, readonly visitFrequencyOfGoogleDesktop: number | null, readonly visitFrequencyOfGoogleMobile: number | null, readonly visitsPerDayBingDesktop: ReadonlyArray<{ readonly __typename?: 'PageSearchEngineActivityVisitsPerDay', readonly date: CK.DateYMD, readonly isDateComplete: boolean, readonly numberOfVisits: number }> | null, readonly visitsPerDayBingMobile: ReadonlyArray<{ readonly __typename?: 'PageSearchEngineActivityVisitsPerDay', readonly date: CK.DateYMD, readonly isDateComplete: boolean, readonly numberOfVisits: number }> | null, readonly visitsPerDayGoogleDesktop: ReadonlyArray<{ readonly __typename?: 'PageSearchEngineActivityVisitsPerDay', readonly date: CK.DateYMD, readonly isDateComplete: boolean, readonly numberOfVisits: number }> | null, readonly visitsPerDayGoogleMobile: ReadonlyArray<{ readonly __typename?: 'PageSearchEngineActivityVisitsPerDay', readonly date: CK.DateYMD, readonly isDateComplete: boolean, readonly numberOfVisits: number }> | null } | null } | null };

export const SearchEngineVisitsPerDayFragmentDoc = gql`
    fragment SearchEngineVisitsPerDay on PageSearchEngineActivityVisitsPerDay {
  date
  isDateComplete
  numberOfVisits
}
    `;
export const SearchEngineActivityBlockDocument = gql`
    query SearchEngineActivityBlock($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    searchEngineActivity {
      lastVisitOfBingDesktop: lastVisit(searchEngine: bing, device: desktop)
      lastVisitOfBingMobile: lastVisit(searchEngine: bing, device: mobile)
      lastVisitOfGoogleDesktop: lastVisit(searchEngine: google, device: desktop)
      lastVisitOfGoogleMobile: lastVisit(searchEngine: google, device: mobile)
      visitFrequencyOfBingDesktop: visitFrequency(
        searchEngine: bing
        device: desktop
        periodUnit: month
      )
      visitFrequencyOfBingMobile: visitFrequency(
        searchEngine: bing
        device: mobile
        periodUnit: month
      )
      visitFrequencyOfGoogleDesktop: visitFrequency(
        searchEngine: google
        device: desktop
        periodUnit: month
      )
      visitFrequencyOfGoogleMobile: visitFrequency(
        searchEngine: google
        device: mobile
        periodUnit: month
      )
      visitsPerDayBingDesktop: visitsPerDay(searchEngine: bing, device: desktop) {
        ...SearchEngineVisitsPerDay
      }
      visitsPerDayBingMobile: visitsPerDay(searchEngine: bing, device: mobile) {
        ...SearchEngineVisitsPerDay
      }
      visitsPerDayGoogleDesktop: visitsPerDay(searchEngine: google, device: desktop) {
        ...SearchEngineVisitsPerDay
      }
      visitsPerDayGoogleMobile: visitsPerDay(searchEngine: google, device: mobile) {
        ...SearchEngineVisitsPerDay
      }
    }
  }
}
    ${SearchEngineVisitsPerDayFragmentDoc}`;

/**
 * __useSearchEngineActivityBlockQuery__
 *
 * To run a query within a React component, call `useSearchEngineActivityBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEngineActivityBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEngineActivityBlockQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useSearchEngineActivityBlockQuery(baseOptions: Apollo.QueryHookOptions<SearchEngineActivityBlockQuery, SearchEngineActivityBlockQueryVariables> & ({ variables: SearchEngineActivityBlockQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchEngineActivityBlockQuery, SearchEngineActivityBlockQueryVariables>(SearchEngineActivityBlockDocument, options);
      }
export function useSearchEngineActivityBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchEngineActivityBlockQuery, SearchEngineActivityBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchEngineActivityBlockQuery, SearchEngineActivityBlockQueryVariables>(SearchEngineActivityBlockDocument, options);
        }
export function useSearchEngineActivityBlockSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchEngineActivityBlockQuery, SearchEngineActivityBlockQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SearchEngineActivityBlockQuery, SearchEngineActivityBlockQueryVariables>(SearchEngineActivityBlockDocument, options);
        }
export type SearchEngineActivityBlockQueryHookResult = ReturnType<typeof useSearchEngineActivityBlockQuery>;
export type SearchEngineActivityBlockLazyQueryHookResult = ReturnType<typeof useSearchEngineActivityBlockLazyQuery>;
export type SearchEngineActivityBlockSuspenseQueryHookResult = ReturnType<typeof useSearchEngineActivityBlockSuspenseQuery>;
export type SearchEngineActivityBlockQueryResult = Apollo.QueryResult<SearchEngineActivityBlockQuery, SearchEngineActivityBlockQueryVariables>;