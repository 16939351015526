import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CheckListTable from '~/components/atoms/forms/components/CheckListTable';
import Form from '~/components/atoms/forms/basis/Form';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import SubmitButton from '~/components/app/SubmitButton';



const messages = defineMessages({
	content: {
		id: 'ui.websites.exclusionsModal.importer.description',
	},
	importRules: {
		id: 'ui.websites.exclusionsModal.importer.importRules',
	},
	setupNewList: {
		id: 'ui.websites.exclusionsModal.importer.setupNewList',
	},
	tableHeader: {
		id: 'ui.websites.exclusionsModal.importer.rule',
	},
});



type Props = {
	onSubmitCallback: (selectedRules: ReadonlyArray<string>) => void,
	robotsRules: ReadonlyArray<string>,
};

const Importer: React.FC<Props> = (props) => {
	const {
		onSubmitCallback,
		robotsRules,
	} = props;

	const handleSkip = React.useCallback(
		() => {
			onSubmitCallback([]);
		},
		[
			onSubmitCallback,
		],
	);

	const handleSubmit = React.useCallback(
		(values) => {
			onSubmitCallback(
				robotsRules.filter((rule, index) => values.rules.indexOf(index) !== -1),
			);
		},
		[
			onSubmitCallback,
			robotsRules,
		],
	);

	const defaultValues = React.useMemo(
		() => ({
			rules: robotsRules.map((rule, index) => index),
		}),
		[
			robotsRules,
		],
	);

	const items = React.useMemo(
		() => robotsRules.map((rule, index) => ({
			label: rule,
			value: index,
		})),
		[
			robotsRules,
		],
	);

	return (
		<Form
			defaultDataHasChanged={true}
			defaultValues={defaultValues}
			onSuccess={handleSubmit}
		>
			<ModalTextSection>
				<FormattedMessage {...messages.content} />
			</ModalTextSection>

			<ModalContentSection enforceReadableTextLength={true}>
				<CheckListTable
					items={items}
					label={(
						<FormattedMessage {...messages.tableHeader} />
					)}
					name="rules"
				/>

				<ButtonsLayout layout={ButtonsLayoutType.Steps}>
					<CancelButton onClickCallback={handleSkip}>
						<FormattedMessage {...messages.setupNewList} />
					</CancelButton>

					<SubmitButton>
						<FormattedMessage {...messages.importRules} />
					</SubmitButton>
				</ButtonsLayout>
			</ModalContentSection>
		</Form>
	);
};



export default Importer;
