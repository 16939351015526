import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import CrawlDelayDirectivesTable from '../CrawlDelayDirectivesTable';
import DisallowedAssetsTable from '../DisallowedAssetsTable';
import IgnoringButton from '~/components/app/IgnoringButton';
import InaccessibleSitemapsTable from '../InaccessibleSitemapsTable';
import InvalidContentsSitemapsErrorsTable from '../InvalidContentsSitemapsErrorsTable';
import InvalidHttpsCertificateStatus from '../InvalidHttpsCertificateStatus';
import InvalidRobotsTxtDirectivesTable from '../InvalidRobotsTxtDirectivesTable';
import InvalidRobotsTxtSyntaxTable from '../InvalidRobotsTxtSyntaxTable';
import InvalidStructureSitemapsErrorsTable from '../InvalidStructureSitemapsErrorsTable';
import IssueDetail from '~/components/app/IssueDetail';
import OrphanedSitemapsTable from '../OrphanedSitemapsTable';
import RobotsTxtInaccessibleSitemapReferencesTable from '../RobotsTxtInaccessibleSitemapReferencesTable';
import RobotsTxtNonCanonicalTable from '../RobotsTxtNonCanonicalTable';
import RobotsTxtRelativeSitemapReferencesTable from '../RobotsTxtRelativeSitemapReferencesTable';
import RobotsTxtStatus from '../RobotsTxtStatus';
import SampleSoft404UrlsTable from '../SampleSoft404UrlsTable';
import SampleUrlsWithStatusCodeTable from '../SampleUrlsWithStatusCodeTable';
import SitemapsViolatingFilesizeLimitTable from '../SitemapsViolatingFilesizeLimitTable';
import SitemapsViolatingUrlcountLimitTable from '../SitemapsViolatingUrlcountLimitTable';
import WebVitalMetricsTable from '../WebVitalMetricsTable';

import {
	getCategoryUrlIdentifier,
	getUrlIdentifier,
} from '~/model/issues/identifierMapping';

import {
	IgnoringScope,
	type IssueCategoryName,
	type IssueName,
	PlatformIssue,
} from '~/model/issuesNew';



type Props = {
	issue: {
		configuration: Record<string, any>,
		context: any,
		followupIssues?: ReadonlyArray<{
			configuration: Record<string, any>,
			isConfigurationModified: boolean,
			name: string,
		}>,
		isActionable: boolean,
		isConfigurationModified: boolean,
		isIgnored: boolean,
		name: IssueName,
		pointsGained: number,
		pointsToGain: number,
		state: GraphQL.IssueState,
	},
	issueCategoryName: IssueCategoryName,
	websiteId: CK.WebsiteId,
};

const PlatformScreenIssueDetail: React.FC<Props> = (props) => {
	const {
		issue,
		issueCategoryName,
		websiteId,
	} = props;

	const renderCaseIllustration = React.useCallback(
		() => {
			if (issue.name === PlatformIssue.DomainSoft404sPresent) {
				return ({ containerWidth }) => (
					<SampleSoft404UrlsTable
						issue={issue}
						tableWidth={containerWidth}
					/>
				);
			}

			if (issue.name === PlatformIssue.DomainHttpsCertificateInvalid) {
				return (
					<InvalidHttpsCertificateStatus
						issue={issue}
					/>
				);
			}

			if (
				issue.name === PlatformIssue.DomainHostnameNoncanonicalPresent
				|| issue.name === PlatformIssue.DomainHttpsNoncanonicalPresent
			) {
				return ({ containerWidth }) => (
					<SampleUrlsWithStatusCodeTable
						issue={issue}
						tableWidth={containerWidth}
					/>
				);
			}

			if (issue.name === PlatformIssue.RobotsTxtAssetsDisallowed) {
				return ({ containerWidth }) => (
					<DisallowedAssetsTable
						issue={issue}
						tableWidth={containerWidth}
					/>
				);
			}

			if (issue.name === PlatformIssue.RobotsTxtCrawldelayPresent) {
				return ({ containerWidth }) => (
					<CrawlDelayDirectivesTable
						issue={issue}
						tableWidth={containerWidth}
					/>
				);
			}

			if (issue.name === PlatformIssue.RobotsTxtInaccessible) {
				return (
					<RobotsTxtStatus
						lastUnreliableCircumstance={issue.context.lastUnreliableCircumstance}
						redirectTarget={issue.context.redirectTarget}
						statusCode={issue.context.statusCode}
						url={issue.context.url}
					/>
				);
			}

			if (issue.name === PlatformIssue.RobotsTxtInvalidDirective) {
				return ({ containerWidth }) => (
					<InvalidRobotsTxtDirectivesTable
						invalidDirectives={issue.context.directives}
						tableWidth={containerWidth}
					/>
				);
			}

			if (issue.name === PlatformIssue.RobotsTxtInvalidSyntax) {
				return ({ containerWidth }) => (
					<InvalidRobotsTxtSyntaxTable
						tableWidth={containerWidth}
						websiteId={websiteId}
					/>
				);
			}

			if (issue.name === PlatformIssue.RobotsTxtNoncanonicalPresent) {
				return ({ containerWidth }) => (
					<RobotsTxtNonCanonicalTable
						issue={issue}
						tableWidth={containerWidth}
					/>
				);
			}

			if (issue.name === PlatformIssue.RobotsTxtXmlsitemapReferenceInaccessible) {
				return ({ containerWidth }) => (
					<RobotsTxtInaccessibleSitemapReferencesTable
						issue={issue}
						tableWidth={containerWidth}
					/>
				);
			}

			if (issue.name === PlatformIssue.RobotsTxtXmlsitemapReferenceRelative) {
				return ({ containerWidth }) => (
					<RobotsTxtRelativeSitemapReferencesTable
						issue={issue}
						tableWidth={containerWidth}
					/>
				);
			}

			if (
				issue.name === PlatformIssue.WebVitalsOriginSummaryCoreWebVitals
				|| issue.name === PlatformIssue.WebVitalsOriginSummaryCumulativeLayoutShift
				|| issue.name === PlatformIssue.WebVitalsOriginSummaryFirstContentfulPaint
				|| issue.name === PlatformIssue.WebVitalsOriginSummaryInteractionToNextPaint
				|| issue.name === PlatformIssue.WebVitalsOriginSummaryLargestContentfulPaint
			) {
				return ({ containerWidth }) => (
					<WebVitalMetricsTable
						issue={issue}
						tableWidth={containerWidth}
					/>
				);
			}

			if (issue.name === PlatformIssue.XmlSitemapInaccessible) {
				return ({ containerWidth }) => (
					<InaccessibleSitemapsTable tableWidth={containerWidth} />
				);
			}

			if (issue.name === PlatformIssue.XmlSitemapOrphaned) {
				return ({ containerWidth }) => (
					<OrphanedSitemapsTable tableWidth={containerWidth} />
				);
			}

			if (
				issue.name === PlatformIssue.XmlSitemapInvalidStructure
				|| issue.name === PlatformIssue.XmlSitemapInvalidStructureAll
			) {
				return ({ containerWidth }) => (
					<InvalidStructureSitemapsErrorsTable tableWidth={containerWidth} />
				);
			}

			if (issue.name === PlatformIssue.XmlSitemapInvalidContents) {
				return ({ containerWidth }) => (
					<InvalidContentsSitemapsErrorsTable tableWidth={containerWidth} />
				);
			}

			if (issue.name === PlatformIssue.XmlSitemapFilesizeLimit) {
				return ({ containerWidth }) => (
					<SitemapsViolatingFilesizeLimitTable tableWidth={containerWidth} />
				);
			}

			if (issue.name === PlatformIssue.XmlSitemapUrlcountLimit) {
				return ({ containerWidth }) => (
					<SitemapsViolatingUrlcountLimitTable tableWidth={containerWidth} />
				);
			}

			return null;
		},
		[
			issue,
			websiteId,
		],
	);

	const renderIgnoringButton = React.useCallback(
		() => (
			<IgnoringButton
				isIgnored={issue.isIgnored}
				routeName="website.platform.issuesCategory.issueIgnoring"
				routeParams={{
					platformScreenIssuesCategoryType: getCategoryUrlIdentifier(issueCategoryName),
					platformIssue: getUrlIdentifier(issue.name),
					scope: IgnoringScope.Website,
					websiteId,
				}}
			/>
		),
		[
			issue,
			issueCategoryName,
			websiteId,
		],
	);

	return (
		<IssueDetail
			issue={issue}
			renderCaseIllustration={renderCaseIllustration}
			renderIgnoringButton={renderIgnoringButton}
		/>
	);
};



export default PlatformScreenIssueDetail;
