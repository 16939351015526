import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import Button, {
	ButtonStyle,
	ButtonWidth,
} from '~/components/patterns/buttons/Button';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import ExclusionRulesDataTable from '~/components/atoms/dataTables/ExclusionRulesDataTable';
import ListElement from '~/components/patterns/lists/List';
import Measurer from '~/utilities/Measurer';
import ModalContentSection from '~/components/atoms/modals/parts/ModalContentSection';
import ModalTextSection from '~/components/atoms/modals/parts/ModalTextSection';
import RichText from '~/components/patterns/typography/RichText';

import {
	useUpdateUrlExclusionListMutation,
} from './Manager.gql';



const messages = defineMessages({
	applyChanges: {
		id: 'ui.general.applyChanges',
	},
	cancel: {
		id: 'ui.general.cancelButton',
	},
	content: {
		id: 'ui.websites.exclusionsModal.manager.description',
	},
	propagationTimeWarning: {
		id: 'ui.websites.exclusionsModal.manager.propagationTimeWarning',
	},
});



type Props = {
	closeModal: () => void,
	importedUrlExclusionListRules: ReadonlyArray<string> | null,
	urlExclusionListRules: ReadonlyArray<string> | null,
	websiteId: CK.WebsiteId,
};

const Manager: React.FC<Props> = (props) => {
	const {
		closeModal,
		importedUrlExclusionListRules,
		urlExclusionListRules,
		websiteId,
	} = props;

	const [updateUrlExclusionList] = useUpdateUrlExclusionListMutation();

	const [state, setState] = React.useState<{
		canSubmit: boolean,
		isLoading: boolean,
		isSubmitting: boolean,
		rules: ReadonlyArray<{
			rule: string,
			deleted: boolean,
			unsaved: boolean,
		}>,
	}>(() => ({
		canSubmit: false,
		isLoading: urlExclusionListRules === null,
		isSubmitting: false,
		rules: (urlExclusionListRules ?? []).map((rule) => ({
			rule,
			deleted: false,
			unsaved: false,
		})),
	}));

	React.useEffect(
		() => {
			if (urlExclusionListRules === null) {
				return;
			}

			setState((state) => {
				if (state.isLoading === false) {
					return state;
				}

				return {
					...state,
					isLoading: false,
					rules: urlExclusionListRules.map((rule) => ({
						rule,
						deleted: false,
						unsaved: false,
					})),
				};
			});
		},
		[
			urlExclusionListRules,
		],
	);

	React.useEffect(
		() => {
			if (importedUrlExclusionListRules === null) {
				return;
			}

			setState((state) => {
				return {
					...state,
					canSubmit: true,
					rules: [...importedUrlExclusionListRules].sort().map((rule) => ({
						rule,
						deleted: false,
						unsaved: false,
					})),
				};
			});
		},
		[
			importedUrlExclusionListRules,
		],
	);

	const handleApplyChangesButtonClick = React.useCallback(
		async () => {
			setState((state) => ({
				...state,
				isSubmitting: true,
			}));

			try {
				await updateUrlExclusionList({
					variables: {
						rules: state.rules
							.filter((rule) => rule.deleted === false)
							.map((rule) => rule.rule),
						websiteId,
					},
				});
			} catch (error) {
				setState((state) => ({
					...state,
					isSubmitting: false,
				}));

				return;
			}

			setState((state) => ({
				...state,
				isSubmitting: false,
			}));

			closeModal();
		},
		[
			closeModal,
			state,
			updateUrlExclusionList,
			websiteId,
		],
	);



	const handleFormSubmit = React.useCallback(
		(model) => {
			setState((state) => {
				const rules = [
					...state.rules,
					{
						rule: model.rule,
						deleted: false,
						unsaved: true,
					},
				];

				return {
					...state,
					canSubmit: true,
					rules,
				};
			});
		},
		[],
	);

	const toggleRule = React.useCallback(
		(index: number) => {
			setState((state) => {
				const rules = state.rules.map(
					(rule, i: number) => {
						if (index !== i) {
							return rule;
						}

						return {
							...rule,
							deleted: rule.deleted === false,
						};
					},
				);

				return {
					...state,
					canSubmit: true,
					rules,
				};
			});
		},
		[],
	);

	return (
		<>
			<ModalTextSection>
				<FormattedMessage {...messages.content} />
			</ModalTextSection>

			<ModalContentSection enforceReadableTextLength={true}>
				<ListElement>
					<Measurer>
						{({ containerWidth }) => (
							<ExclusionRulesDataTable
								allowDeletion={true}
								formSubmitCallback={handleFormSubmit}
								isLoading={state.isLoading}
								key={'table-' + containerWidth}
								onToggleRule={toggleRule}
								rules={state.rules}
								tableWidth={containerWidth - 2}
							/>
						)}
					</Measurer>
					{state.canSubmit && (
						<RichText>
							<p>
								<FormattedMessage {...messages.propagationTimeWarning} />
							</p>
						</RichText>
					)}
				</ListElement>

				<ButtonsLayout layout={ButtonsLayoutType.Steps}>
					<CancelButton onClickCallback={closeModal} />

					<Button
						disabled={!state.canSubmit}
						onClick={handleApplyChangesButtonClick}
						progress={state.isSubmitting}
						style={ButtonStyle.Action}
						width={ButtonWidth.SubmitButton}
					>
						<FormattedMessage {...messages.applyChanges} />
					</Button>
				</ButtonsLayout>
			</ModalContentSection>
		</>
	);
};



export default Manager;
