import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';

import AttributesGroupIcon, {
	type AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ColumnController from '~/components/patterns/forms/fieldParts/pageColumns/ColumnController';
import ColumnCategoryName from '~/components/names/ColumnCategoryName';
import ColumnName from '~/components/names/ColumnName';
import ColumnsCategoryController from '~/components/patterns/forms/fieldParts/pageColumns/ColumnsCategoryController';
import ColumnsConfiguratorLayout from '~/components/patterns/columnsConfigurator/ColumnsConfiguratorLayout';
import FilterSearchResults from '~/components/patterns/utils/FilterSearchResults';
import NoSearchResults from '~/components/patterns/messages/embedded/NoSearchResults';
import PureRadioFields from '~/components/atoms/forms/components/builders/PureRadioFields';
import Spacer, {
	SpacerGap,
} from '~/components/patterns/utils/Spacer';

import useWebsiteColumnCategories from '~/hooks/useWebsiteColumnCategories';

import {
	type PagesColumnsCategory,
} from '~/model/pagesColumns';

import {
	isString,
} from '~/utilities/typeCheck';



const messages = defineMessages({
	confirmButton: {
		id: 'ui.segments.editor.addColumn.confirmButton',
	},
});



type Props = {
	defaultSelectedColumn?: CK.PagesColumn | null,
	excludedColumns?: Array<CK.PagesColumn>,
	onColumnSelect?: (column: CK.PagesColumn) => void,
	searchFilter?: string,
};

const ColumnAddColumn: React.FC<Props> = (props) => {
	const {
		defaultSelectedColumn,
		excludedColumns = [],
		onColumnSelect,
		searchFilter,
	} = props;

	const websiteColumnCategories = useWebsiteColumnCategories();

	const [selectedColumn, setSelectedColumn] = React.useState<CK.PagesColumn | null>(defaultSelectedColumn ?? null);
	const isSearching = isString(searchFilter) && searchFilter !== '';

	return (
		<ColumnsConfiguratorLayout
			footer={(
				<Button
					disabled={selectedColumn === null}
					onClick={() => onColumnSelect && onColumnSelect(selectedColumn as CK.PagesColumn)}
					size={ButtonSize.Small}
					style={ButtonStyle.Action}
					uppercase={true}
				>
					<FormattedMessage {...messages.confirmButton} />
				</Button>
			)}
		>
			{isSearching ? (
				<SearchResults
					excludedColumns={excludedColumns}
					onChangeCallback={setSelectedColumn}
					searchFilter={searchFilter}
					selectedColumn={selectedColumn}
				/>
			) : (
				<>
					{websiteColumnCategories.length === 0 && (
						<Spacer gap={SpacerGap.Default}>
							<NoSearchResults />
						</Spacer>
					)}

					{websiteColumnCategories.map((category) => (
						<Category
							category={category}
							excludedColumns={excludedColumns}
							key={category.name}
							onChangeCallback={setSelectedColumn}
							selectedColumn={selectedColumn}
						/>
					))}
				</>
			)}
		</ColumnsConfiguratorLayout>
	);
};



type CategoryProps = {
	category: {
		name: PagesColumnsCategory,
		columns: ReadonlyArray<CK.PagesColumn>,
	},
	excludedColumns?: Array<CK.PagesColumn>,
	onChangeCallback: (column: CK.PagesColumn) => void,
	selectedColumn: CK.PagesColumn | null,
};

const Category: React.FC<CategoryProps> = (props) => {
	const {
		category,
		excludedColumns,
		onChangeCallback,
		selectedColumn,
	} = props;

	const name = category.name;
	const items: Array<{
		checked: boolean,
		label: React.ReactNode,
		value: any,
	}> = [];

	const hasSelectedColumn = category.columns.some((columnName) => columnName === selectedColumn);

	category.columns.forEach((columnName) => {
		if (excludedColumns && excludedColumns.includes(columnName)) {
			return;
		}

		items.push({
			checked: columnName === selectedColumn,
			label: (
				<ColumnName column={columnName} />
			),
			value: columnName,
		});
	});

	if (items.length === 0) {
		return null;
	}

	return (
		<ColumnsCategoryController
			defaultOpen={hasSelectedColumn}
			label={(
				<>
					<AttributesGroupIcon
						size={20}
						type={name as unknown as AttributesGroupIconType}
					/>
					<ColumnCategoryName columnCategory={name} />
				</>
			)}
			name={name}
		>
			<PureRadioFields
				isControlled={true}
				items={items}
				key={name}
				name={`add-column-${name}`}
				onChangeCallback={onChangeCallback}
			>
				{({ fields }) => fields.map((field) => (
					<ColumnController
						key={field.data.value}
						label={field.field}
					/>
				))}
			</PureRadioFields>
		</ColumnsCategoryController>
	);
};



type SearchResultsProps = {
	excludedColumns?: Array<CK.PagesColumn>,
	onChangeCallback: (column: CK.PagesColumn) => void,
	searchFilter: string,
	selectedColumn: CK.PagesColumn | null,
};

const SearchResults: React.FC<SearchResultsProps> = (props) => {
	const {
		excludedColumns,
		onChangeCallback,
		searchFilter,
		selectedColumn,
	} = props;

	const websiteColumnCategories = useWebsiteColumnCategories();

	const matchingColumns = React.useMemo(
		() => {
			return websiteColumnCategories
				.reduce((columns, category) => {
					return [
						...columns,
						...category.columns,
					];
				}, [])
				.filter((columnName) => excludedColumns?.includes(columnName) === false)
				.map((columnName) => ({
					checked: columnName === selectedColumn,
					label: (
						<ColumnName column={columnName} />
					),
					value: columnName,
				}));
		},
		[
			excludedColumns,
			selectedColumn,
			websiteColumnCategories,
		],
	);

	if (matchingColumns.length === 0) {
		return (
			<Spacer gap={SpacerGap.Default}>
				<NoSearchResults />
			</Spacer>
		);
	}

	return (
		<PureRadioFields
			isControlled={true}
			items={matchingColumns}
			name="add-column"
			onChangeCallback={onChangeCallback}
		>
			{({ fields }) => (
				<FilterSearchResults
					elements={(
						fields.map((field) => (
							<ColumnController
								key={`${selectedColumn}-${field.data.value}`}
								label={field.field}
							/>
						))
					)}
					searchTerm={searchFilter}
				/>
			)}
		</PureRadioFields>
	);
};



export default ColumnAddColumn;
