export default <const>{
	'links.academy_canonicalfaq_noindex.label': `Learn more about combining a canonical link and noindex`,
	'links.academy_canonicalfaq_noindex.url': `https://www.conductor.com/academy/canonical/faq/noindex/`,
	'links.academy_canonicallink.label': `Learn more about canonical links`,
	'links.academy_canonicallink.url': `https://www.conductor.com/academy/canonical/`,
	'links.academy_corewebvitals.label': `Learn more about Core Web Vitals`,
	'links.academy_corewebvitals.url': `https://www.conductor.com/academy/core-web-vitals/`,
	'links.academy_corewebvitals_cls.label': `Learn more about Cumulative Layout Shift`,
	'links.academy_corewebvitals_cls.url': `https://www.conductor.com/academy/core-web-vitals/#cls`,
	'links.academy_corewebvitals_fcp.label': `Learn more about First Contentful Paint`,
	'links.academy_corewebvitals_fcp.url': `https://www.conductor.com/academy/core-web-vitals/#fcp`,
	'links.academy_corewebvitals_lcp.label': `Learn more about Largest Contentful Paint`,
	'links.academy_corewebvitals_lcp.url': `https://www.conductor.com/academy/core-web-vitals/#lcp`,
	'links.academy_corewebvitals_performancecalculation.label': `Learn how the Lighthouse Performance score is calculated`,
	'links.academy_corewebvitals_performancecalculation.url': `https://www.conductor.com/academy/core-web-vitals/#performance-calculation`,
	'links.academy_corewebvitals_si.label': `Learn more about Speed Index`,
	'links.academy_corewebvitals_si.url': `https://www.conductor.com/academy/core-web-vitals/#si`,
	'links.academy_corewebvitals_tbt.label': `Learn more about Total Blocking Time`,
	'links.academy_corewebvitals_tbt.url': `https://www.conductor.com/academy/core-web-vitals/#tbt`,
	'links.academy_corewebvitals_tti.label': `Learn more about Time To Interactive`,
	'links.academy_corewebvitals_tti.url': `https://www.conductor.com/academy/core-web-vitals/#tti`,
	'links.academy_corewebvitals_whatarethey.label': `Learn more about other non-Core Web Vitals`,
	'links.academy_corewebvitals_whatarethey.url': `https://www.conductor.com/academy/core-web-vitals/#what-are-they`,
	'links.academy_corewebvitals_whycare.label': `Learn why you should care about Core Web Vitals Assessment`,
	'links.academy_corewebvitals_whycare.url': `https://www.conductor.com/academy/core-web-vitals/#why-care`,
	'links.academy_crawlbudget.label': `Learn more about crawl budget`,
	'links.academy_crawlbudget.url': `https://www.conductor.com/academy/crawl-budget/`,
	'links.academy_crawlbudget_brokenlinks.label': `Learn more about broken links`,
	'links.academy_crawlbudget_brokenlinks.url': `https://www.conductor.com/academy/crawl-budget/#broken-redirected-links`,
	'links.academy_crawlbudget_xmlsitemaps.label': `Learn more about incorrect URLs in XML sitemaps`,
	'links.academy_crawlbudget_xmlsitemaps.url': `https://www.conductor.com/academy/crawl-budget/#incorrect-urls-xml-sitemap`,
	'links.academy_duplicatecontent.label': `Learn more about duplicate content`,
	'links.academy_duplicatecontent.url': `https://www.conductor.com/academy/duplicate-content/`,
	'links.academy_headings.label': `Learn more about headings`,
	'links.academy_headings.url': `https://www.conductor.com/academy/headings/`,
	'links.academy_headings_h1_multiple.label': `Learn more about multiple H1 headings`,
	'links.academy_headings_h1_multiple.url': `https://www.conductor.com/academy/headings/#multiple-h1-headings`,
	'links.academy_headings_length.label': `Learn more about H1 heading length`,
	'links.academy_headings_length.url': `https://www.conductor.com/academy/headings/#h1-heading-length`,
	'links.academy_hreflang.label': `Learn more about hreflang`,
	'links.academy_hreflang.url': `https://www.conductor.com/academy/hreflang/`,
	'links.academy_hreflang_absolute_url.label': `Learn more about why an hreflang attribute should target an absolute URL`,
	'links.academy_hreflang_absolute_url.url': `https://www.conductor.com/academy/hreflang/#absolute-urls`,
	'links.academy_hreflang_language_and_region.label': `Learn how to correctly define language and region combinations`,
	'links.academy_hreflang_language_and_region.url': `https://www.conductor.com/academy/hreflang/#correctly-define-language-and-region-combinations`,
	'links.academy_hreflang_self_reference.label': `See an example of how to define a self-referencing hreflang`,
	'links.academy_hreflang_self_reference.url': `https://www.conductor.com/academy/hreflang/#reference-the-page-itself-and-its-translated-variants`,
	'links.academy_hreflang_x_default.label': `Learn how to specify the hreflang attribute with the x-default value`,
	'links.academy_hreflang_x_default.url': `https://www.conductor.com/academy/hreflang/#always-set-hreflang-x-default`,
	'links.academy_imageseo.label': `Learn more about images`,
	'links.academy_imageseo.url': `https://www.conductor.com/academy/image-seo/`,
	'links.academy_imageseo_alt_attribute.label': `Learn more about the alt attribute`,
	'links.academy_imageseo_alt_attribute.url': `https://www.conductor.com/academy/image-seo/#image-alt-attribute`,
	'links.academy_linkauthority.label': `Learn more about link authority`,
	'links.academy_linkauthority.url': `https://www.conductor.com/academy/authority/#link-authority`,
	'links.academy_metadescription.label': `Learn more about meta descriptions`,
	'links.academy_metadescription.url': `https://www.conductor.com/academy/meta-description/`,
	'links.academy_metadescription_length.label': `Learn more about meta description length`,
	'links.academy_metadescription_length.url': `https://www.conductor.com/academy/meta-description/#good-length`,
	'links.academy_metadescription_uniqueness.label': `Learn more about meta description uniqueness`,
	'links.academy_metadescription_uniqueness.url': `https://www.conductor.com/academy/meta-description/#unique-meta-description-throughout-website`,
	'links.academy_opengraph.label': `Learn more about Open Graph`,
	'links.academy_opengraph.url': `https://www.conductor.com/academy/open-graph/`,
	'links.academy_opengraph_description.label': `Learn more about Open Graph description`,
	'links.academy_opengraph_description.url': `https://www.conductor.com/academy/open-graph/#og-description`,
	'links.academy_opengraph_image.label': `Learn more about Open Graph image`,
	'links.academy_opengraph_image.url': `https://www.conductor.com/academy/open-graph/#og-image`,
	'links.academy_opengraph_title.label': `Learn more about Open Graph title`,
	'links.academy_opengraph_title.url': `https://www.conductor.com/academy/open-graph/#og-title`,
	'links.academy_opengraph_url.label': `Learn more about Open Graph URL`,
	'links.academy_opengraph_url.url': `https://www.conductor.com/academy/open-graph/#og-url`,
	'links.academy_redirects.label': `Learn more about redirects`,
	'links.academy_redirects.url': `https://www.conductor.com/academy/redirects/#avoid-redirects-whenever-you-can`,
	'links.academy_redirects_preferred_version.label': `Learn more about redirecting the non-canonical domain variants`,
	'links.academy_redirects_preferred_version.url': `https://www.conductor.com/academy/redirects/#redirect-to-preferred-version-of-your-website-url`,
	'links.academy_robotstxt.label': `Learn more about robots.txt`,
	'links.academy_robotstxt.url': `https://www.conductor.com/academy/robotstxt/`,
	'links.academy_robotstxt_crawldelay.label': `Learn more about crawl-delay in robots.txt`,
	'links.academy_robotstxt_crawldelay.url': `https://www.conductor.com/academy/robotstxt/#crawl-delay`,
	'links.academy_robotstxt_xmlsitemap.label': `Learn more about the XML sitemap reference in robots.txt`,
	'links.academy_robotstxt_xmlsitemap.url': `https://www.conductor.com/academy/robotstxt/#sitemap`,
	'links.academy_schema.label': `Learn more about schema.org`,
	'links.academy_schema.url': `https://www.conductor.com/academy/schema/`,
	'links.academy_seo_requirements_new_website_https.label': `Learn more about HTTPS`,
	'links.academy_seo_requirements_new_website_https.url': `https://www.conductor.com/academy/seo-requirements-new-website/#https`,
	'links.academy_title.label': `Learn more about page titles`,
	'links.academy_title.url': `https://www.conductor.com/academy/title-tag/`,
	'links.academy_title_length.label': `Learn more about page title length`,
	'links.academy_title_length.url': `https://www.conductor.com/academy/title-tag/#good-length`,
	'links.academy_title_uniqueness.label': `Learn more about page title uniqueness`,
	'links.academy_title_uniqueness.url': `https://www.conductor.com/academy/title-tag/#unique-title-tags-throughout-a-website`,
	'links.academy_twittercards.label': `Learn more about Twitter Cards`,
	'links.academy_twittercards.url': `https://www.conductor.com/academy/twitter-cards/`,
	'links.academy_twittercards_description.label': `Learn more about Twitter Cards description`,
	'links.academy_twittercards_description.url': `https://www.conductor.com/academy/twitter-cards/#description`,
	'links.academy_twittercards_image.label': `Learn more about the Twitter Cards image property`,
	'links.academy_twittercards_image.url': `https://www.conductor.com/academy/twitter-cards/#image`,
	'links.academy_twittercards_site.label': `Learn more about the Twitter Cards site property`,
	'links.academy_twittercards_site.url': `https://www.conductor.com/academy/twitter-cards/#site`,
	'links.academy_twittercards_title.label': `Learn more about Twitter Cards title`,
	'links.academy_twittercards_title.url': `https://www.conductor.com/academy/twitter-cards/#title`,
	'links.academy_twittercards_type.label': `Learn more about Twitter Cards types`,
	'links.academy_twittercards_type.url': `https://www.conductor.com/academy/twitter-cards/#card-type`,
	'links.academy_xmlsitemap.label': `Learn more about XML sitemaps`,
	'links.academy_xmlsitemap.url': `https://www.conductor.com/academy/xml-sitemap/`,
	'links.external_sitemapsorg_protocol.label': `See XML sitemap protocol definition`,
	'links.external_sitemapsorg_protocol.url': `https://www.sitemaps.org/protocol.html`,
	'links.support_analytics.label': `Learn more about Conductor Website Monitoring's analytics detection`,
	'links.support_analytics.url': `https://www.contentkingapp.com/support/analytics-tracking-detection/`,
};
