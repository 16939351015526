/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EnrichmentFieldsQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type EnrichmentFieldsQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly enrichmentFields: ReadonlyArray<{ readonly __typename?: 'EnrichmentField', readonly column: `ef_${string}`, readonly dataType: GraphQL.EnrichmentFieldDataType, readonly id: CK.ID, readonly label: string }> } | null };


export const EnrichmentFieldsDocument = gql`
    query EnrichmentFields($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    enrichmentFields {
      column
      dataType
      id
      label
    }
  }
}
    `;

/**
 * __useEnrichmentFieldsQuery__
 *
 * To run a query within a React component, call `useEnrichmentFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnrichmentFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnrichmentFieldsQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useEnrichmentFieldsQuery(baseOptions: Apollo.QueryHookOptions<EnrichmentFieldsQuery, EnrichmentFieldsQueryVariables> & ({ variables: EnrichmentFieldsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EnrichmentFieldsQuery, EnrichmentFieldsQueryVariables>(EnrichmentFieldsDocument, options);
      }
export function useEnrichmentFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EnrichmentFieldsQuery, EnrichmentFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EnrichmentFieldsQuery, EnrichmentFieldsQueryVariables>(EnrichmentFieldsDocument, options);
        }
export function useEnrichmentFieldsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EnrichmentFieldsQuery, EnrichmentFieldsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EnrichmentFieldsQuery, EnrichmentFieldsQueryVariables>(EnrichmentFieldsDocument, options);
        }
export type EnrichmentFieldsQueryHookResult = ReturnType<typeof useEnrichmentFieldsQuery>;
export type EnrichmentFieldsLazyQueryHookResult = ReturnType<typeof useEnrichmentFieldsLazyQuery>;
export type EnrichmentFieldsSuspenseQueryHookResult = ReturnType<typeof useEnrichmentFieldsSuspenseQuery>;
export type EnrichmentFieldsQueryResult = Apollo.QueryResult<EnrichmentFieldsQuery, EnrichmentFieldsQueryVariables>;