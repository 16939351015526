/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetupHttpAuthenticationMutationVariables = GraphQL.Exact<{
  password: GraphQL.Scalars['String']['input'];
  username: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type SetupHttpAuthenticationMutation = { readonly __typename?: 'Mutation', readonly SetupHttpAuthentication: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly isHttpAuthenticationEnabled: boolean } | null } } };


export const SetupHttpAuthenticationDocument = gql`
    mutation SetupHttpAuthentication($password: String!, $username: String!, $websiteId: WebsiteId!) {
  SetupHttpAuthentication(
    password: $password
    username: $username
    websiteId: $websiteId
  ) {
    query {
      website(id: $websiteId) {
        id
        isHttpAuthenticationEnabled
      }
    }
  }
}
    `;
export type SetupHttpAuthenticationMutationFn = Apollo.MutationFunction<SetupHttpAuthenticationMutation, SetupHttpAuthenticationMutationVariables>;

/**
 * __useSetupHttpAuthenticationMutation__
 *
 * To run a mutation, you first call `useSetupHttpAuthenticationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetupHttpAuthenticationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setupHttpAuthenticationMutation, { data, loading, error }] = useSetupHttpAuthenticationMutation({
 *   variables: {
 *      password: // value for 'password'
 *      username: // value for 'username'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useSetupHttpAuthenticationMutation(baseOptions?: Apollo.MutationHookOptions<SetupHttpAuthenticationMutation, SetupHttpAuthenticationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetupHttpAuthenticationMutation, SetupHttpAuthenticationMutationVariables>(SetupHttpAuthenticationDocument, options);
      }
export type SetupHttpAuthenticationMutationHookResult = ReturnType<typeof useSetupHttpAuthenticationMutation>;
export type SetupHttpAuthenticationMutationResult = Apollo.MutationResult<SetupHttpAuthenticationMutation>;
export type SetupHttpAuthenticationMutationOptions = Apollo.BaseMutationOptions<SetupHttpAuthenticationMutation, SetupHttpAuthenticationMutationVariables>;