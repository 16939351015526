/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteDisplayNameQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteDisplayNameQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly displayName: string } | null };


export const WebsiteDisplayNameDocument = gql`
    query WebsiteDisplayName($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    displayName
  }
}
    `;

/**
 * __useWebsiteDisplayNameQuery__
 *
 * To run a query within a React component, call `useWebsiteDisplayNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteDisplayNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteDisplayNameQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteDisplayNameQuery(baseOptions: Apollo.QueryHookOptions<WebsiteDisplayNameQuery, WebsiteDisplayNameQueryVariables> & ({ variables: WebsiteDisplayNameQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteDisplayNameQuery, WebsiteDisplayNameQueryVariables>(WebsiteDisplayNameDocument, options);
      }
export function useWebsiteDisplayNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteDisplayNameQuery, WebsiteDisplayNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteDisplayNameQuery, WebsiteDisplayNameQueryVariables>(WebsiteDisplayNameDocument, options);
        }
export function useWebsiteDisplayNameSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteDisplayNameQuery, WebsiteDisplayNameQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteDisplayNameQuery, WebsiteDisplayNameQueryVariables>(WebsiteDisplayNameDocument, options);
        }
export type WebsiteDisplayNameQueryHookResult = ReturnType<typeof useWebsiteDisplayNameQuery>;
export type WebsiteDisplayNameLazyQueryHookResult = ReturnType<typeof useWebsiteDisplayNameLazyQuery>;
export type WebsiteDisplayNameSuspenseQueryHookResult = ReturnType<typeof useWebsiteDisplayNameSuspenseQuery>;
export type WebsiteDisplayNameQueryResult = Apollo.QueryResult<WebsiteDisplayNameQuery, WebsiteDisplayNameQueryVariables>;