/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvoicesOverviewQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type InvoicesOverviewQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly invoices: ReadonlyArray<{ readonly __typename?: 'Invoice', readonly currency: GraphQL.Currency, readonly downloadUrl: string, readonly dueDate: CK.DateYMD | null, readonly id: CK.ID, readonly issuedAt: CK.Timestamp, readonly number: string, readonly status: GraphQL.InvoiceStatus, readonly totalAmount: number }> } | null };


export const InvoicesOverviewDocument = gql`
    query InvoicesOverview($accountId: AccountId!) {
  account(id: $accountId) {
    id
    invoices {
      currency
      downloadUrl
      dueDate
      id
      issuedAt
      number
      status
      totalAmount
    }
  }
}
    `;

/**
 * __useInvoicesOverviewQuery__
 *
 * To run a query within a React component, call `useInvoicesOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesOverviewQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useInvoicesOverviewQuery(baseOptions: Apollo.QueryHookOptions<InvoicesOverviewQuery, InvoicesOverviewQueryVariables> & ({ variables: InvoicesOverviewQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesOverviewQuery, InvoicesOverviewQueryVariables>(InvoicesOverviewDocument, options);
      }
export function useInvoicesOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesOverviewQuery, InvoicesOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesOverviewQuery, InvoicesOverviewQueryVariables>(InvoicesOverviewDocument, options);
        }
export function useInvoicesOverviewSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InvoicesOverviewQuery, InvoicesOverviewQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<InvoicesOverviewQuery, InvoicesOverviewQueryVariables>(InvoicesOverviewDocument, options);
        }
export type InvoicesOverviewQueryHookResult = ReturnType<typeof useInvoicesOverviewQuery>;
export type InvoicesOverviewLazyQueryHookResult = ReturnType<typeof useInvoicesOverviewLazyQuery>;
export type InvoicesOverviewSuspenseQueryHookResult = ReturnType<typeof useInvoicesOverviewSuspenseQuery>;
export type InvoicesOverviewQueryResult = Apollo.QueryResult<InvoicesOverviewQuery, InvoicesOverviewQueryVariables>;