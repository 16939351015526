/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HistoricalIssuesChartQueryVariables = GraphQL.Exact<{
  since: GraphQL.Scalars['DateYMD']['input'];
  until: GraphQL.Scalars['DateYMD']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type HistoricalIssuesChartQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly aggregateHistoricalComparisons: ReadonlyArray<{ readonly __typename?: 'WebsiteAggregateHistoricalComparison', readonly numberOfIssuesOpenedOnAddedPages: number, readonly numberOfIssuesOpenedOnExistingPages: number, readonly numberOfResolvedIssues: number, readonly scope: string, readonly since: CK.DateYMD, readonly until: CK.DateYMD }> | null } | null };


export const HistoricalIssuesChartDocument = gql`
    query HistoricalIssuesChart($since: DateYMD!, $until: DateYMD!, $websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    aggregateHistoricalComparisons(since: $since, until: $until) {
      numberOfIssuesOpenedOnAddedPages
      numberOfIssuesOpenedOnExistingPages
      numberOfResolvedIssues
      scope
      since
      until
    }
  }
}
    `;

/**
 * __useHistoricalIssuesChartQuery__
 *
 * To run a query within a React component, call `useHistoricalIssuesChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricalIssuesChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricalIssuesChartQuery({
 *   variables: {
 *      since: // value for 'since'
 *      until: // value for 'until'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useHistoricalIssuesChartQuery(baseOptions: Apollo.QueryHookOptions<HistoricalIssuesChartQuery, HistoricalIssuesChartQueryVariables> & ({ variables: HistoricalIssuesChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoricalIssuesChartQuery, HistoricalIssuesChartQueryVariables>(HistoricalIssuesChartDocument, options);
      }
export function useHistoricalIssuesChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoricalIssuesChartQuery, HistoricalIssuesChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoricalIssuesChartQuery, HistoricalIssuesChartQueryVariables>(HistoricalIssuesChartDocument, options);
        }
export function useHistoricalIssuesChartSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HistoricalIssuesChartQuery, HistoricalIssuesChartQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HistoricalIssuesChartQuery, HistoricalIssuesChartQueryVariables>(HistoricalIssuesChartDocument, options);
        }
export type HistoricalIssuesChartQueryHookResult = ReturnType<typeof useHistoricalIssuesChartQuery>;
export type HistoricalIssuesChartLazyQueryHookResult = ReturnType<typeof useHistoricalIssuesChartLazyQuery>;
export type HistoricalIssuesChartSuspenseQueryHookResult = ReturnType<typeof useHistoricalIssuesChartSuspenseQuery>;
export type HistoricalIssuesChartQueryResult = Apollo.QueryResult<HistoricalIssuesChartQuery, HistoricalIssuesChartQueryVariables>;