import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import {
	ASSETS_STORAGE_VERSIONLESS,
} from '~/config';

import Button, {
	ButtonStyle,
	ButtonType,
} from '~/components/patterns/buttons/Button';
import ButtonsLayout, {
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import Form from '../basis/Form';
import SubmitButton from '~/components/app/SubmitButton';



const messages = defineMessages({
	notSubmittedDownloadButton: {
		id: 'ui.exports.type.general.button.download.label',
	},
	notSubmittedContent: {
		id: 'ui.exports.type.general.content',
	},
	notSubmittedTitle: {
		id: 'ui.exports.type.general.title',
	},
	submitButton: {
		id: 'ui.exports.type.general.button.submit.label',
	},
	submittedContent: {
		id: 'ui.exports.type.general.content.inProgress',
	},
	submittedContentLarge: {
		id: 'ui.exports.type.general.content.inProgress.largeExport',
	},
	submittedDownloadButton: {
		id: 'ui.exports.type.general.button.submit.label.inProgress',
	},
	submittedTitle: {
		id: 'ui.exports.type.general.title.inProgress',
	},
});



type Props = {
	createExport: () => Promise<{
		exportDownloadUrl: string,
		exportFilename: string,
		exportId: number,
		isLarge: boolean,
	}>,
	currentLocale: string,
	onCancelCallback: () => void,
};



const FileExportForm: React.FC<Props> = (props) => {
	const {
		createExport,
		currentLocale,
		onCancelCallback,
	} = props;

	const [exportResult, setExportResult] = React.useState<{
		exportDownloadUrl: string,
		exportFilename: string,
		exportId: number,
		isLarge: boolean,
	} | null>(null);

	const handleSubmitForm = React.useCallback(
		async () => {
			const exportData = await createExport();

			setExportResult(exportData);
		},
		[
			createExport,
			setExportResult,
		],
	);

	function renderSubmittedAction(exportResult: {
		exportDownloadUrl: string,
		exportFilename: string,
		exportId: number,
		isLarge: boolean,
	}) {
		let exportDownloadUrl = ASSETS_STORAGE_VERSIONLESS
			+ '/export.html'
			+ '?export=' + exportResult.exportFilename
			+ '&lang=' + currentLocale
			+ '&download=' + exportResult.exportDownloadUrl;

		if (exportResult.isLarge) {
			exportDownloadUrl += '&is_large=true';
		}

		return (
			<div>
				<ButtonsLayout layout={ButtonsLayoutType.CenterActionButton}>
					<span></span>
					<a
						href={exportDownloadUrl}
						target="_blank"
					>
						<FormattedMessage {...messages.notSubmittedDownloadButton} />
					</a>
					<span></span>
				</ButtonsLayout>
				<ButtonsLayout layout={ButtonsLayoutType.CenterActionButton}>
					<span></span>
					<span>
						<Button
							onClick={onCancelCallback}
							style={ButtonStyle.Action}
							type={ButtonType.Button}
						>
							<FormattedMessage {...messages.submittedDownloadButton} />
						</Button>
					</span>
					<span></span>
				</ButtonsLayout>
			</div>
		);
	}

	function renderSubmitAction() {
		return (
			<Form
				defaultDataHasChanged={true}
				onSuccess={handleSubmitForm}
			>
				<ButtonsLayout layout={ButtonsLayoutType.CenterActionButton}>
					<span>
						<CancelButton onClickCallback={onCancelCallback} />
					</span>
					<span>
						<SubmitButton>
							<FormattedMessage {...messages.submitButton} />
						</SubmitButton>
					</span>
					<span></span>
				</ButtonsLayout>
			</Form>
		);
	}

	const titleMessage = exportResult !== null
		? messages.submittedTitle
		: messages.notSubmittedTitle;

	const contentMessage = exportResult !== null
		? messages[exportResult.isLarge ? 'submittedContentLarge' : 'submittedContent']
		: messages.notSubmittedContent;

	return (
		<div className="export-panel">
			<h3 className="export-panel__title">
				<FormattedMessage {...titleMessage} />
			</h3>

			<div className="export-panel__content">
				<div className="export-panel__text typo">
					<p>
						<FormattedMessage {...contentMessage} />
					</p>
				</div>
			</div>

			<div className="export-panel__form">
				{exportResult !== null ? renderSubmittedAction(exportResult) : renderSubmitAction()}
			</div>
		</div>
	);
};



export default FileExportForm;
