import React from 'react';

import type CK from '~/types/contentking';

import FormsList from '~/components/atoms/lists/FormsList';
import JavascriptRenderingAndLighthouseForm from '~/components/app/JavascriptRenderingAndLighthouseForm';
import WebsiteMonitoringAdvancedSettingsForm from '~/components/atoms/forms/forms/WebsiteMonitoringAdvancedSettingsForm';
import WebsiteMonitoringForm from '~/components/atoms/forms/forms/WebsiteMonitoringForm';
import WebsiteSettingsMiscellaneousForm from '~/components/app/WebsiteSettingsMiscellaneousForm';



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteMonitoringGroup: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	return (
		<FormsList>
			<WebsiteMonitoringForm
				websiteId={websiteId}
			/>

			<JavascriptRenderingAndLighthouseForm
				websiteId={websiteId}
			/>

			<WebsiteMonitoringAdvancedSettingsForm
				websiteId={websiteId}
			/>

			<WebsiteSettingsMiscellaneousForm
				websiteId={websiteId}
			/>
		</FormsList>
	);
};



export default WebsiteMonitoringGroup;
