import classNames from 'classnames';
import React from 'react';
import {
	useSortable,
} from '@dnd-kit/sortable';
import {
	CSS,
} from '@dnd-kit/utilities';

import DragHandler from '~/components/patterns/icons/DragHandler';
import NewTableCell from './NewTableCell';

import {
	type RenderProp,
	renderProp,
} from '~/utilities/renderProp';



export enum NewTableRowType {
	Body = 'body',
	Header = 'header',
}



type ChildProps = {
	hovered: boolean,
};

type Props = {
	children?: RenderProp<ChildProps>,
	id: string | number,
	isSortable?: boolean,
	isDragging?: boolean,
	isEven?: boolean,
	onClick?: (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void,
	style?: React.CSSProperties,
	type?: NewTableRowType,
};

const NewTableRow: React.FC<Props> = (props) => {
	const {
		children,
		id,
		isSortable = false,
		isDragging = false,
		onClick = undefined,
		type = NewTableRowType.Body,
	} = props;

	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({
		id,
		disabled: !isSortable || isDragging,
	});

	const [hovered, setHovered] = React.useState(false);

	const style: React.CSSProperties = {
		...props.style,
		transform: CSS.Transform.toString(transform),
		transition,
	};

	let isEven = props.isEven;
	if (transform !== null && transform.y !== 0) {
		isEven = !isEven;
	}

	const rowClasses = classNames({
		'new-table__row': true,
		'new-table__row--is-dragging': isDragging === true,
		'new-table__row--is-clickable': onClick !== undefined,
		'new-table__row--even': isEven === true,
		'new-table__row--odd': isEven === false,
		[`new-table__row--${type}`]: true,
	});

	return (
		<div
			className={rowClasses}
			key={id}
			onClick={onClick}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
			ref={setNodeRef}
			style={style}
		>
			{renderProp(children, { hovered })}

			{isSortable && (
				<NewTableCell>
					<DragHandler
						{...listeners}
						{...attributes}
					/>
				</NewTableCell>
			)}
		</div>
	);
};



export default NewTableRow;
