import {
	captureMessage,
} from '@sentry/browser';
import React from 'react';
import {
	defineMessages,
} from 'react-intl';

import CK from '~/types/contentking';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Copy from '~/components/logic/Copy';

import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteGoogleAnalyticsVisibleColumns from '~/hooks/useWebsiteGoogleAnalyticsVisibleColumns';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	GoogleAnalyticsV3Columns,
	GoogleAnalyticsV4Columns,
	LfaBingColumns,
	LfaGoogleColumns,
	PagesColumnsCategory,
	getColumnCategory,
} from '~/model/pagesColumns';



const messages = defineMessages({
	[CK.PagesCommonColumn.AaAverageTimeSpentOnSite]: {
		id: 'ui.contentOverview.tableHeading.aa_average_time_spent_on_site',
	},
	[CK.PagesCommonColumn.AaBounceRate]: {
		id: 'ui.contentOverview.tableHeading.aa_bounce_rate',
	},
	[CK.PagesCommonColumn.AaPageViews]: {
		id: 'ui.contentOverview.tableHeading.aa_page_views',
	},
	[CK.PagesCommonColumn.AaRevenue]: {
		id: 'ui.contentOverview.tableHeading.aa_revenue',
	},
	[CK.PagesCommonColumn.AaUniqueVisitors]: {
		id: 'ui.contentOverview.tableHeading.aa_unique_visitors',
	},
	[CK.PagesCommonColumn.AnalyticsServices]: {
		id: 'ui.contentOverview.tableHeading.analytics_services',
	},
	[CK.PagesCommonColumn.CanonicalLinkElement]: {
		id: 'ui.affectedPages.specific.canonicalLink.headings.element',
	},
	[CK.PagesCommonColumn.CanonicalLinkTarget]: {
		id: 'ui.affectedPages.specific.canonicalLink.headings.url',
	},
	[CK.PagesCommonColumn.CanonicalType]: {
		id: 'ui.contentOverview.tableHeading.canonical_type',
	},
	[CK.PagesCommonColumn.CanonicalUrl]: {
		id: 'ui.contentOverview.tableHeading.canonical_url',
	},
	[CK.PagesCommonColumn.ChangeType]: {
		id: 'ui.contentOverview.tableHeading.change_type',
	},
	[CK.PagesCommonColumn.Domain]: {
		id: 'ui.contentOverview.tableHeading.domain',
	},
	[CK.PagesCommonColumn.GaActiveUsers]: {
		id: 'ui.contentoverview.tableheading.ga_active_users',
	},
	[CK.PagesCommonColumn.GaAverageEngagementTime]: {
		id: 'ui.contentoverview.tableheading.ga_average_engagement_time',
	},
	[CK.PagesCommonColumn.GaAverageTime]: {
		id: 'ui.contentOverview.tableHeading.ga_average_time',
	},
	[CK.PagesCommonColumn.GaBounceRate]: {
		id: 'ui.contentOverview.tableHeading.ga_bounce_rate',
	},
	[CK.PagesCommonColumn.GaEngagementRate]: {
		id: 'ui.contentOverview.tableHeading.ga_engagement_rate',
	},
	[CK.PagesCommonColumn.GaPageValue]: {
		id: 'ui.contentOverview.tableHeading.ga_page_value',
	},
	[CK.PagesCommonColumn.GaPageViews]: {
		id: 'ui.contentOverview.tableHeading.ga_page_views',
	},
	[CK.PagesCommonColumn.GaScreenPageViews]: {
		id: 'ui.contentoverview.tableheading.ga_screen_page_views',
	},
	[CK.PagesCommonColumn.GaUniquePageViews]: {
		id: 'ui.contentOverview.tableHeading.ga_unique_page_views',
	},
	[CK.PagesCommonColumn.GscClicks]: {
		id: 'ui.contentOverview.tableHeading.gsc_clicks',
	},
	[CK.PagesCommonColumn.GscCtr]: {
		id: 'ui.contentOverview.tableHeading.gsc_ctr',
	},
	[CK.PagesCommonColumn.GscImpressions]: {
		id: 'ui.contentOverview.tableHeading.gsc_impressions',
	},
	[CK.PagesCommonColumn.GscPosition]: {
		id: 'ui.contentOverview.tableHeading.gsc_position',
	},
	[CK.PagesCommonColumn.Health]: {
		id: 'ui.contentOverview.tableHeading.score',
	},
	[CK.PagesCommonColumn.H1]: {
		id: 'ui.contentOverview.tableHeading.h1',
	},
	[CK.PagesCommonColumn.HreflangChanges]: {
		id: 'ui.contentOverview.tableHeading.hreflang_changes',
	},
	[CK.PagesCommonColumn.HreflangLanguage]: {
		id: 'ui.contentOverview.tableHeading.hreflang_language',
	},
	[CK.PagesCommonColumn.IsDisallowedInRobotsTxt]: {
		id: 'ui.contentOverview.tableHeading.is_disallowed_in_robots_txt',
	},
	[CK.PagesCommonColumn.IsInSitemap]: {
		id: 'ui.contentOverview.tableHeading.is_in_sitemap',
	},
	[CK.PagesCommonColumn.IsIndexable]: {
		id: 'ui.contentOverview.tableHeading.is_indexable',
	},
	[CK.PagesCommonColumn.IsIndexableDueToMetaRobots]: {
		id: 'ui.contentOverview.tableHeading.is_indexable_due_to_meta_robots',
	},
	[CK.PagesCommonColumn.IsIndexableDueToXRobotsTag]: {
		id: 'ui.contentOverview.tableHeading.is_indexable_due_to_x_robots_tag',
	},
	[CK.PagesCommonColumn.IsLinked]: {
		id: 'ui.contentOverview.tableHeading.is_linked',
	},
	[CK.PagesCommonColumn.IsSecured]: {
		id: 'ui.contentOverview.tableHeading.is_secured',
	},
	[CK.PagesCommonColumn.LfaBingDesktopFrequency]: {
		id: 'ui.contentOverview.tableHeading.lfa_desktop_frequency',
	},
	[CK.PagesCommonColumn.LfaBingDesktopLastVisit]: {
		id: 'ui.contentOverview.tableHeading.lfa_desktop_last_visit',
	},
	[CK.PagesCommonColumn.LfaBingFrequency]: {
		id: 'ui.contentOverview.tableHeading.lfa_frequency',
	},
	[CK.PagesCommonColumn.LfaBingLastVisit]: {
		id: 'ui.contentOverview.tableHeading.lfa_last_visit',
	},
	[CK.PagesCommonColumn.LfaBingMobileFrequency]: {
		id: 'ui.contentOverview.tableHeading.lfa_mobile_frequency',
	},
	[CK.PagesCommonColumn.LfaBingMobileLastVisit]: {
		id: 'ui.contentOverview.tableHeading.lfa_mobile_last_visit',
	},
	[CK.PagesCommonColumn.LfaGoogleDesktopFrequency]: {
		id: 'ui.contentOverview.tableHeading.lfa_desktop_frequency',
	},
	[CK.PagesCommonColumn.LfaGoogleDesktopLastVisit]: {
		id: 'ui.contentOverview.tableHeading.lfa_desktop_last_visit',
	},
	[CK.PagesCommonColumn.LfaGoogleFrequency]: {
		id: 'ui.contentOverview.tableHeading.lfa_frequency',
	},
	[CK.PagesCommonColumn.LfaGoogleLastVisit]: {
		id: 'ui.contentOverview.tableHeading.lfa_last_visit',
	},
	[CK.PagesCommonColumn.LfaGoogleMobileFrequency]: {
		id: 'ui.contentOverview.tableHeading.lfa_mobile_frequency',
	},
	[CK.PagesCommonColumn.LfaGoogleMobileLastVisit]: {
		id: 'ui.contentOverview.tableHeading.lfa_mobile_last_visit',
	},
	[CK.PagesCommonColumn.LighthouseCumulativeLayoutShift]: {
		id: 'ui.contentOverview.tableHeading.lighthouse_cumulative_layout_shift',
	},
	[CK.PagesCommonColumn.LighthouseFirstContentfulPaint]: {
		id: 'ui.contentOverview.tableHeading.lighthouse_first_contentful_paint',
	},
	[CK.PagesCommonColumn.LighthouseLargestContentfulPaint]: {
		id: 'ui.contentOverview.tableHeading.lighthouse_largest_contentful_paint',
	},
	[CK.PagesCommonColumn.LighthousePerformance]: {
		id: 'ui.contentOverview.tableHeading.lighthouse_performance',
	},
	[CK.PagesCommonColumn.LighthouseSpeedIndex]: {
		id: 'ui.contentOverview.tableHeading.lighthouse_speed_index',
	},
	[CK.PagesCommonColumn.LighthouseTimeToInteractive]: {
		id: 'ui.contentOverview.tableHeading.lighthouse_time_to_interactive',
	},
	[CK.PagesCommonColumn.LighthouseTotalBlockingTime]: {
		id: 'ui.contentOverview.tableHeading.lighthouse_total_blocking_time',
	},
	[CK.PagesCommonColumn.LinkAmp]: {
		id: 'ui.contentOverview.tableHeading.link_amp',
	},
	[CK.PagesCommonColumn.LinkNext]: {
		id: 'ui.contentOverview.tableHeading.link_next',
	},
	[CK.PagesCommonColumn.LinkPrev]: {
		id: 'ui.contentOverview.tableHeading.link_prev',
	},
	[CK.PagesCommonColumn.MetaDescription]: {
		id: 'ui.contentOverview.tableHeading.meta_description',
	},
	[CK.PagesCommonColumn.MobileVariant]: {
		id: 'ui.contentOverview.tableHeading.mobile_variant',
	},
	[CK.PagesCommonColumn.OpenGraphDescription]: {
		id: 'ui.contentOverview.tableHeading.open_graph_description',
	},
	[CK.PagesCommonColumn.OpenGraphImage]: {
		id: 'ui.contentOverview.tableHeading.open_graph_image',
	},
	[CK.PagesCommonColumn.OpenGraphTitle]: {
		id: 'ui.contentOverview.tableHeading.open_graph_title',
	},
	[CK.PagesCommonColumn.OpenGraphType]: {
		id: 'ui.contentOverview.tableHeading.open_graph_type',
	},
	[CK.PagesCommonColumn.OpenGraphUrl]: {
		id: 'ui.contentOverview.tableHeading.open_graph_url',
	},
	[CK.PagesCommonColumn.NumberOfHreflangs]: {
		id: 'ui.contentOverview.tableHeading.number_of_hreflangs',
	},
	[CK.PagesCommonColumn.NumberOfImagesWithMissingAlt]: {
		id: 'ui.affectedPages.specific.images.headings.alt',
	},
	[CK.PagesCommonColumn.NumberOfImagesWithMixedTransport]: {
		id: 'ui.affectedPages.specific.images.headings.mixedTransport',
	},
	[CK.PagesCommonColumn.NumberOfIncomingInternalCanonicals]: {
		id: 'ui.contentOverview.tableHeading.number_of_incoming_internal_canonicals',
	},
	[CK.PagesCommonColumn.NumberOfIncomingInternalLinks]: {
		id: 'ui.contentOverview.tableHeading.number_of_incoming_internal_links',
	},
	[CK.PagesCommonColumn.NumberOfIncomingInternalRedirects]: {
		id: 'ui.contentOverview.tableHeading.number_of_incoming_internal_redirects',
	},
	[CK.PagesCommonColumn.NumberOfLinksToBrokenPages]: {
		id: 'ui.affectedPages.specific.links.headings.broken',
	},
	[CK.PagesCommonColumn.NumberOfLinksToCanonicalizedPages]: {
		id: 'ui.affectedPages.specific.links.headings.toCanonicalized',
	},
	[CK.PagesCommonColumn.NumberOfLinksToRedirectedPages]: {
		id: 'ui.affectedPages.specific.links.headings.redirecting',
	},
	[CK.PagesCommonColumn.NumberOfOutgoingExternalLinks]: {
		id: 'ui.contentOverview.tableHeading.number_of_outgoing_external_links',
	},
	[CK.PagesCommonColumn.NumberOfOutgoingInternalLinks]: {
		id: 'ui.contentOverview.tableHeading.number_of_outgoing_internal_links',
	},
	[CK.PagesCommonColumn.Redirect]: {
		id: 'ui.contentOverview.tableHeading.redirect',
	},
	[CK.PagesCommonColumn.Relevance]: {
		id: 'ui.contentOverview.tableHeading.relevance',
	},
	[CK.PagesCommonColumn.SchemaOrgNumberOfTypes]: {
		id: 'ui.contentOverview.tableHeading.schemaOrgNumberOfTypes',
	},
	[CK.PagesCommonColumn.SchemaOrgTypes]: {
		id: 'ui.contentOverview.tableHeading.schemaOrgTypes',
	},
	[CK.PagesCommonColumn.Segments]: {
		id: 'ui.contentOverview.tableHeading.segments',
	},
	[CK.PagesCommonColumn.StatusCode]: {
		id: 'ui.contentOverview.tableHeading.status_code',
	},
	[CK.PagesCommonColumn.TagManagers]: {
		id: 'ui.contentOverview.tableHeading.tag_managers',
	},
	[CK.PagesCommonColumn.TimeFound]: {
		id: 'ui.contentOverview.tableHeading.foundAt',
	},
	[CK.PagesCommonColumn.TimeServerResponse]: {
		id: 'ui.contentOverview.tableHeading.timeServerResponse',
	},
	[CK.PagesCommonColumn.Title]: {
		id: 'ui.contentOverview.tableHeading.title',
	},
	[CK.PagesCommonColumn.TwitterCard]: {
		id: 'ui.contentOverview.tableHeading.twitter_card',
	},
	[CK.PagesCommonColumn.TwitterDescription]: {
		id: 'ui.contentOverview.tableHeading.twitter_description',
	},
	[CK.PagesCommonColumn.TwitterImage]: {
		id: 'ui.contentOverview.tableHeading.twitter_image',
	},
	[CK.PagesCommonColumn.TwitterSite]: {
		id: 'ui.contentOverview.tableHeading.twitter_site',
	},
	[CK.PagesCommonColumn.TwitterTitle]: {
		id: 'ui.contentOverview.tableHeading.twitter_title',
	},
	[CK.PagesCommonColumn.Type]: {
		id: 'ui.contentOverview.tableHeading.type',
	},
	[CK.PagesCommonColumn.Url]: {
		id: 'ui.contentOverview.tableHeading.url',
	},
	[CK.PagesCommonColumn.UrlDepth]: {
		id: 'ui.contentOverview.tableHeading.url_depth',
	},
	[CK.PagesCommonColumn.UrlFull]: {
		id: 'ui.contentOverview.tableHeading.url_full',
	},
	[CK.PagesCommonColumn.VisualAnalyticsServices]: {
		id: 'ui.contentOverview.tableHeading.visual_analytics_services',
	},
	[CK.PagesCommonColumn.XmlSitemapStatus]: {
		id: 'ui.contentOverview.tableHeading.xml_sitemap_status',
	},
});



type Props = {
	column: CK.PagesColumn,
	textOnly?: boolean,
};

const ColumnName: React.FC<Props> = (props) => {
	const {
		column,
		textOnly,
	} = props;



	if (getColumnCategory(column) === PagesColumnsCategory.CustomElements) {
		return (
			<CustomElementColumnName column={column} />
		);
	}

	if (getColumnCategory(column) === PagesColumnsCategory.EnrichmentFields) {
		return (
			<EnrichmentFieldColumnName column={column} />
		);
	}

	if (getColumnCategory(column) === PagesColumnsCategory.GoogleAnalytics) {
		return (
			<GoogleAnalyticsColumnName column={column} />
		);
	}

	if (getColumnCategory(column) === PagesColumnsCategory.SearchEngineActivity) {
		return (
			<SearchEngineActivityColumnName
				column={column}
				textOnly={textOnly}
			/>
		);
	}

	if (!messages[column]) {
		captureMessage(`Cannot find column name for unknown column: ${column}`);

		return (
			<>
				{column}
			</>
		);
	}

	return (
		<Copy {...messages[column]} />
	);
};



type CustomElementColumnProps = {
	column: CK.PagesColumn,
};

const CustomElementColumnName: React.FC<CustomElementColumnProps> = (props) => {
	const {
		column,
	} = props;

	const websiteId = useWebsiteId();
	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);

	return (
		<>
			{customElementDefinitions.getByColumn(column)?.label ?? '-'}
		</>
	);
};



type EnrichmentFieldColumnProps = {
	column: CK.PagesColumn,
};

const EnrichmentFieldColumnName: React.FC<EnrichmentFieldColumnProps> = (props) => {
	const {
		column,
	} = props;

	const websiteId = useWebsiteId();
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	return (
		<>
			{enrichmentFieldDefinitions.getByColumn(column)?.label ?? '-'}
		</>
	);
};



type GoogleAnalyticsColumnProps = {
	column: CK.PagesColumn,
};

const GoogleAnalyticsColumnName: React.FC<GoogleAnalyticsColumnProps> = (props) => {
	const {
		column,
	} = props;

	const websiteId = useWebsiteId();
	const googleAnalyticsVisibleColumns = useWebsiteGoogleAnalyticsVisibleColumns(websiteId);

	if (!messages[column]) {
		captureMessage(`Cannot find column name for unknown column: ${column}`);

		return (
			<>
				{column}
			</>
		);
	}

	let output = (
		<Copy {...messages[column]} />
	);

	if (googleAnalyticsVisibleColumns.length > 1) {
		if (GoogleAnalyticsV3Columns.includes(column)) {
			output = (
				<>
					{output}{' (v3)'}
				</>
			);
		} else if (GoogleAnalyticsV4Columns.includes(column)) {
			output = (
				<>
					{output}{' (v4)'}
				</>
			);
		}
	}

	return output;
};



type SearchEngineActivityColumnProps = {
	column: CK.PagesColumn,
	textOnly?: boolean,
};

const SearchEngineActivityColumnName: React.FC<SearchEngineActivityColumnProps> = (props) => {
	const {
		column,
		textOnly = false,
	} = props;

	if (!messages[column]) {
		captureMessage(`Cannot find column name for unknown column: ${column}`);

		return (
			<>
				{column}
			</>
		);
	}

	let output = (
		<Copy {...messages[column]} />
	);

	if (LfaBingColumns.includes(column)) {
		if (textOnly) {
			output = (
				<>
					<em>
						{'Bing '}
					</em>
					{output}
				</>
			);
		} else {
			output = (
				<AttachedIcon
					icon={(
						<BasicIcon
							size={22}
							type={BasicIconType.Bing}
						/>
					)}
				>
					{output}
				</AttachedIcon>
			);
		}
	} else if (LfaGoogleColumns.includes(column)) {
		if (textOnly) {
			output = (
				<>
					<em>
						{'Google '}
					</em>
					{output}
				</>
			);
		} else {
			output = (
				<AttachedIcon
					icon={(
						<BasicIcon
							size={22}
							type={BasicIconType.Google}
						/>
					)}
				>
					{output}
				</AttachedIcon>
			);
		}
	}

	return output;
};



export default ColumnName;
