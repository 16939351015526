import PropTypes from 'prop-types';
import React, {
	Component,
} from 'react';
import {
	connect,
} from 'react-redux';
import {
	createSelector,
} from 'reselect';
import scrollbarSize from 'dom-helpers/scrollbarSize';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import ColumnFilter from '../filters/ColumnFilter';
import ColumnName from '../../names/ColumnName';
import ColumnsConfigurator from '~/components/logic/columnsConfigurator/ColumnsConfigurator';
import ColumnsConfiguratorOverlayLayout from '~/components/patterns/columnsConfigurator/ColumnsConfiguratorOverlayLayout';
import DataCategoryCell from '../../atoms/boxes/DataCategoryCell';
import ExpandableFilterOperatorHeaderLabel from '~/components/logic/filters/ExpandableFilterOperatorHeaderLabel';
import FilterFieldLayout from '~/components/patterns/filtering/FilterFieldLayout';
import Form from '../../atoms/forms/basis/Form';
import HealthIcon from '~/components/patterns/icons/HealthIcon';
import LoadingDots, {
	LoadingDotsSize,
} from '~/components/patterns/loaders/LoadingDots';
import List, {
	ListSize,
} from '~/components/patterns/lists/List';
import MarginsList from '../../atoms/lists/MarginsList';
import ScreenBody from '~/components/patterns/screens/parts/body/ScreenBody';
import ScreenBodyLayout, {
	ScreenBodyLayoutPosition,
} from '~/components/patterns/screens/parts/body/ScreenBodyLayout';
import ScreenOverlay from '~/components/patterns/screens/parts/ScreenOverlay';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';

import PageAttributesCategoryBox from './pagesCharts/components/PageAttributesCategoryBox';

import {
	closeColumnsConfigurator,
} from '~/actions';

import {
	loadPagesCharts,
	updatePagesFilter,
} from '~/actions/pages/overview';

import {
	GRAPHABLE_FIELDS,
} from '~/model/pages';

import {
	AllCategories,
	PagesColumnsCategory,
	getColumnCategory,
} from '~/model/pagesColumns';

import {
	FILTER_TYPE_STRING,
	FILTER_TYPE_STRING_NON_NULLABLE,
} from '~/model/pagesColumnsFiltering';

import {
	statisticsSelector,
} from '~/state/pagesGraphs/selectors';

import {
	dataLoadingSelector,
} from '~/state/dataLoading/selectors';

import {
	columnsConfiguratorVisibilitySelector,
	filterSelector,
	selectedWebsiteIdSelector,
} from '~/state/ui/selectors';



const select = createSelector(
	columnsConfiguratorVisibilitySelector,
	dataLoadingSelector,
	filterSelector,
	statisticsSelector,
	selectedWebsiteIdSelector,
	(columnsConfiguratorVisibility, dataLoading, filter, statistics, websiteId) => {
		return {
			columnsConfiguratorVisibility,
			dataLoading,
			filter,
			statistics,
			websiteId,
		};
	},
);



const VISIBLE_FILTER_SIDEBAR_BREAKPOINT = 1000;



class PagesGraphs extends Component {

	constructor(props, context) {
		super(props, context);

		this.synchronizeTimeout = null;

		this._closeColumnsConfigurator = this._closeColumnsConfigurator.bind(this);
		this._handleFormChange = this._handleFormChange.bind(this);

		this._isMounted = false;
	}



	componentDidMount() {
		this._isMounted = true;

		this._loadPagesCharts();
	}



	componentDidUpdate(prevProps) {
		const { dispatch } = this.props;

		const {
			columns: prevColumns,
			filter: prevFilter,
			websiteId: prevWebsiteId,
		} = prevProps;

		const {
			columns: nextColumns,
			filter: nextFilter,
			websiteId: nextWebsiteId,
		} = this.props;

		if (nextFilter !== prevFilter) {
			nextColumns.forEach((columnName) => {
				const element = this.refs['filter-' + columnName];

				if (element) {
					element.changeValue(
						nextFilter.get(columnName),
					);
				}
			});
		}

		if (
			nextWebsiteId
			&& (
				(prevWebsiteId !== nextWebsiteId)
				|| (!prevFilter.equals(nextFilter))
				|| (!prevColumns.equals(nextColumns))
			)
		) {
			const resetData = prevWebsiteId !== nextWebsiteId;
			const displayLoader = prevWebsiteId !== nextWebsiteId;

			const loadAllColumns = !prevFilter.equals(nextFilter) || prevWebsiteId !== nextWebsiteId;
			const columns = loadAllColumns
				? nextColumns
				: nextColumns.filter((column) => !prevColumns.includes(column));

			dispatch(
				loadPagesCharts(columns, displayLoader, resetData),
			);

			this._syncPagesCharts();
		}
	}



	componentWillUnmount() {
		this._isMounted = false;

		if (this.changeCallbackTimeout) {
			clearTimeout(this.changeCallbackTimeout);
			this.changeCallbackTimeout = null;
		}

		if (this.synchronizeTimeout) {
			clearTimeout(this.synchronizeTimeout);
			this.synchronizeTimeout = null;
		}
	}


	_closeColumnsConfigurator() {
		const {
			dispatch,
		} = this.props;

		dispatch(
			closeColumnsConfigurator(),
		);
	}



	_handleFormChange(field, value) {
		const changedValue = value;

		if (this.changeCallbackTimeout) {
			clearTimeout(this.changeCallbackTimeout);
			this.changeCallbackTimeout = null;
		}

		if (this.synchronizeTimeout) {
			clearTimeout(this.synchronizeTimeout);
			this.synchronizeTimeout = null;
		}

		this.changeCallbackTimeout = setTimeout(() => {
			this._handleFilterChange({
				[field]: changedValue === undefined ? '' : changedValue,
			});

			this.changeCallbackTimeout = undefined;
		}, 500);
	}



	_handleFilterChange(filter) {
		const {
			dispatch,
		} = this.props;

		dispatch(
			updatePagesFilter(filter),
		);
	}



	_loadPagesCharts() {
		const {
			columns,
			dispatch,
		} = this.props;

		dispatch(loadPagesCharts(columns)).then(() => {
			this._syncPagesCharts();
		});
	}



	_syncPagesCharts() {
		const {
			columns,
			dispatch,
		} = this.props;

		if (this.synchronizeTimeout) {
			clearTimeout(this.synchronizeTimeout);
			this.synchronizeTimeout = null;
		}

		this.synchronizeTimeout = setTimeout(() => {
			if (this._isMounted) {
				this.synchronizeTimeout = null;

				dispatch(loadPagesCharts(columns)).then(() => {
					this._syncPagesCharts();
				});
			}
		}, 30000);
	}



	_getListOfVisibleColumns(columns) {
		return columns.filter((column) => GRAPHABLE_FIELDS.indexOf(column) !== -1);
	}



	_renderFilteringSidebar() {
		const {
			columns,
			customElementDefinitions,
			filter,
			getFilterType,
			segmentDefinitions,
		} = this.props;

		const content = AllCategories.map((category, index) => {
			const categoryColumns = columns.filter((column) => getColumnCategory(column) === category);

			if (categoryColumns.size === 0) {
				return false;
			}

			if (category === PagesColumnsCategory.CustomElements) {
				if (!customElementDefinitions) {
					return false;
				}
			}

			return (
				<DataCategoryCell
					key={'cell-' + index}
					style={category}
				>
					<List size={ListSize.XLarge}>
						{categoryColumns.map((columnName) => {
							let icon;

							if (columnName === 'segments') {
								icon = (
									<BasicIcon
										size={14}
										type={BasicIconType.Segment}
									/>
								);
							}

							if (columnName === 'health') {
								icon = (
									<HealthIcon />
								);
							}

							const fieldWidth = 330 - 40 - scrollbarSize();

							let labelOperator = false;

							const filterType = getFilterType(columnName);

							if (
								filterType === FILTER_TYPE_STRING
								|| filterType === FILTER_TYPE_STRING_NON_NULLABLE
							) {
								labelOperator = (
									<ExpandableFilterOperatorHeaderLabel
										value={filter.get(columnName)}
									/>
								);
							}

							return (
								<FilterFieldLayout
									field={(
										<ColumnFilter
											columnName={columnName}
											columnWidth={fieldWidth}
											inTrackedChanges={false}
											isAtRightEdge={true}
											ref={'filter-' + columnName}
											segmentDefinitions={segmentDefinitions}
										/>
									)}
									key={columnName}
									label={(
										<TableLabel
											label={(
												<ColumnName column={columnName} />
											)}
											labelIcon={icon}
											labelOperator={labelOperator}
										/>
									)}
								/>
							);
						})}
					</List>
				</DataCategoryCell>
			);
		});

		return (
			<Form
				defaultValues={filter ? filter.toObject() : {}}
				onChangeCallback={this._handleFormChange}
			>
				{content}
			</Form>
		);
	}



	render() {
		const {
			columnsConfiguratorVisibility,
			columns,
			dataLoading,
			statistics,
			viewportWidth,
		} = this.props;

		const visibleColumns = this._getListOfVisibleColumns(columns);

		const content = AllCategories.map((category) => {
			const categoryColumns = visibleColumns.filter((column) => getColumnCategory(column) === category);

			if (categoryColumns.size === 0) {
				return false;
			}

			return (
				<PageAttributesCategoryBox
					category={category}
					categoryColumns={categoryColumns}
					key={category}
					statistics={statistics}
				/>
			);
		});

		let contentComponent = (
			<MarginsList>
				{content}
			</MarginsList>
		);

		// filtering sidebar will be only visible when we have space for it
		if (viewportWidth < VISIBLE_FILTER_SIDEBAR_BREAKPOINT) {
			contentComponent = (
				<ScreenBody>
					{contentComponent}
				</ScreenBody>
			);
		} else {
			contentComponent = (
				<ScreenBodyLayout
					contentOverlay={dataLoading && (
						<ScreenOverlay>
							<LoadingDots size={LoadingDotsSize.Large} />
						</ScreenOverlay>
					)}
					sidebar={this._renderFilteringSidebar()}
					sidebarPosition={ScreenBodyLayoutPosition.Right}
					sidebarWidth={330}
				>
					<ScreenBody>
						{contentComponent}
					</ScreenBody>
				</ScreenBodyLayout>
			);
		}

		return (
			<ColumnsConfiguratorOverlayLayout
				columnsConfigurator={(
					<ColumnsConfigurator />
				)}
				isOpen={columnsConfiguratorVisibility}
				onClose={this._closeColumnsConfigurator}
			>
				{contentComponent}
			</ColumnsConfiguratorOverlayLayout>
		);
	}

}

PagesGraphs.propTypes = {
	columns: PropTypes.any,
	customElementDefinitions: PropTypes.any,
	getFilterType: PropTypes.any,
	segmentDefinitions: PropTypes.object.isRequired,
	viewportWidth: PropTypes.number.isRequired,
};



export default connect(select)(PagesGraphs);
