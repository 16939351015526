import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import CompositeField, {
	type CompositeFieldValidationInput,
} from '~/components/atoms/forms/components/CompositeField';
import CustomElementDatePatternSelectField, {
	CustomElementDatePatternSelectFieldSize,
} from '~/components/app/CustomElementDatePatternSelectField';
import CustomElementNumberPatternSelectField, {
	CustomElementNumberPatternSelectFieldSize,
} from '~/components/app/CustomElementNumberPatternSelectField';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import Grid, {
	GridGapsSize,
} from '~/components/patterns/structures/Grid';
import ParameterField, {
	ParameterFieldType,
} from './ParameterField';
import ParameterSelectField from './ParameterSelectField';

import {
	TRANSFORMATION_APPEND,
	TRANSFORMATION_APPLY_PATTERN,
	TRANSFORMATION_CAST_TO_BOOLEAN,
	TRANSFORMATION_CONCATENATE,
	TRANSFORMATION_EXTRACT_DATE,
	TRANSFORMATION_EXTRACT_FROM_JSON,
	TRANSFORMATION_EXTRACT_NUMBER,
	TRANSFORMATION_PREPEND,
	TRANSFORMATION_REPLACE,
	TRANSFORMATION_TRUNCATE,
	TRANSFORMATION_WHITELIST_HTML_ATTRIBUTES,
} from '~/model/customElements';

import {
	type Rule,
} from '~/utilities/validations';



const messages = defineMessages({
	parameterPlaceholder: {
		id: 'ui.customElements.transformationSteps.parameterPlaceholder',
	},
	transformationAppendParameterLabel: {
		id: 'ui.customElements.transformationSteps.parameters.append.label',
	},
	transformationApplyPatternParameterInputLabel: {
		id: 'ui.customElements.transformationSteps.parameters.replace.inputPattern.label',
	},
	transformationApplyPatternParameterOutputLabel: {
		id: 'ui.customElements.transformationSteps.parameters.replace.outputPattern.label',
	},
	transformationCastToBooleanParameterLabel: {
		id: 'ui.customElements.transformationSteps.parameters.expression.label',
	},
	transformationConcatenateParameterLabel: {
		id: 'ui.customElements.transformationSteps.parameters.concatenate.label',
	},
	transformationConcatenateParameterPlaceholder: {
		id: 'ui.customElements.transformationSteps.parameters.concatenate.placeholder',
	},
	transformationExtractDateParameterLabel: {
		id: 'ui.customElements.transformationSteps.parameters.smartPattern.label',
	},
	transformationExtractFromJsonParameterExpressionLabel: {
		id: 'ui.customElements.transformationSteps.parameters.expression.label',
	},
	transformationExtractFromJsonParameterExtractedTypeLabel: {
		id: 'ui.customElements.transformationSteps.parameters.extractedType.label',
	},
	transformationExtractNumberParameterLabel: {
		id: 'ui.customElements.transformationSteps.parameters.pattern.label',
	},
	transformationPrependParameterLabel: {
		id: 'ui.customElements.transformationSteps.parameters.prepend.label',
	},
	transformationReplaceParameterReplaceWithLabel: {
		id: 'ui.customElements.transformationSteps.parameters.replace.replaceWith.label',
	},
	transformationReplaceParameterSearchForLabel: {
		id: 'ui.customElements.transformationSteps.parameters.replace.searchFor.label',
	},
	transformationTruncateParameterLabel: {
		id: 'ui.customElements.transformationSteps.parameters.truncate.label',
	},
	transformationWhitelistHtmlAttributesParameterLabel: {
		id: 'ui.customElements.transformationSteps.parameters.whitelistHtmlAttributes.label',
	},
});

type Parameter = {
	defaultValue?: any,
	label: React.ReactNode,
	name: string,
	validation?: (input: CompositeFieldValidationInput) => ReadonlyArray<Rule>,
} & ({
	field: (fieldName: string, width: number) => React.ReactNode,
} | {
	extraProps?: React.ComponentProps<typeof ParameterSelectField>['extraProps'],
	options: Array<any>,
} | {
	extraProps?: React.ComponentProps<typeof ParameterField>['extraProps'],
});

const fallbackParameters = [];

const parametersPerTransformationType: Record<string, Array<Parameter>> = {
	[TRANSFORMATION_APPEND]: [
		{
			label: (
				<FormattedMessage {...messages.transformationAppendParameterLabel} />
			),
			name: 'content',
			validation: ({ f }) => ([
				f.validateNonEmpty(),
			]),
		},
	],
	[TRANSFORMATION_CAST_TO_BOOLEAN]: [
		{
			label: (
				<FormattedMessage {...messages.transformationCastToBooleanParameterLabel} />
			),
			name: 'expression',
			validation: ({ f }) => ([
				f.validateNonEmpty(),
			]),
		},
	],
	[TRANSFORMATION_APPLY_PATTERN]: [
		{
			label: (
				<FormattedMessage {...messages.transformationApplyPatternParameterInputLabel} />
			),
			name: 'pattern',
			validation: ({ f }) => ([
				f.validateNonEmpty(),
				f.validateRegexp(),
			]),
		},
		{
			defaultValue: '',
			label: (
				<FormattedMessage {...messages.transformationApplyPatternParameterOutputLabel} />
			),
			name: 'output',
		},
	],
	[TRANSFORMATION_CONCATENATE]: [
		{
			defaultValue: '',
			label: (
				<FormattedMessage {...messages.transformationConcatenateParameterLabel} />
			),
			name: 'glue',
		},
	],
	[TRANSFORMATION_EXTRACT_DATE]: [
		{
			field: (fieldName, columnWidth) => (
				<CustomElementDatePatternSelectField
					name={fieldName}
					size={CustomElementDatePatternSelectFieldSize.Small}
					width={columnWidth}
				/>
			),
			label: (
				<FormattedMessage {...messages.transformationExtractDateParameterLabel} />
			),
			name: 'smart_pattern',
			validation: ({ f }) => ([
				f.validateNonEmpty(),
			]),
		},
	],
	[TRANSFORMATION_EXTRACT_FROM_JSON]: [
		{
			label: (
				<FormattedMessage {...messages.transformationExtractFromJsonParameterExpressionLabel} />
			),
			name: 'expression',
			validation: ({ f }) => ([
				f.validateNonEmpty(),
			]),
		},
		{
			extraProps: {
				dropdownWidth: 160,
			},
			label: (
				<FormattedMessage {...messages.transformationExtractFromJsonParameterExtractedTypeLabel} />
			),
			name: 'extracted_type',
			options: [
				{
					name: 'string',
					label: 'String',
				},
				{
					name: 'number',
					label: 'Number',
				},
				{
					name: 'boolean',
					label: 'Boolean',
				},
			],
			validation: ({ f }) => ([
				f.validateNonEmpty(),
			]),
		},
	],
	[TRANSFORMATION_EXTRACT_NUMBER]: [
		{
			field: (fieldName, columnWidth) => (
				<CustomElementNumberPatternSelectField
					name={fieldName}
					size={CustomElementNumberPatternSelectFieldSize.Small}
					width={columnWidth}
				/>
			),
			label: (
				<FormattedMessage {...messages.transformationExtractNumberParameterLabel} />
			),
			name: 'pattern',
			validation: ({ f }) => ([
				f.validateNonEmpty(),
			]),
		},
	],
	[TRANSFORMATION_PREPEND]: [
		{
			label: (
				<FormattedMessage {...messages.transformationPrependParameterLabel} />
			),
			name: 'content',
			validation: ({ f }) => ([
				f.validateNonEmpty(),
			]),
		},
	],
	[TRANSFORMATION_REPLACE]: [
		{
			label: (
				<FormattedMessage {...messages.transformationReplaceParameterSearchForLabel} />
			),
			name: 'needle',
			validation: ({ f }) => ([
				f.validateNonEmpty(),
			]),
		},
		{
			defaultValue: '',
			label: (
				<FormattedMessage {...messages.transformationReplaceParameterReplaceWithLabel} />
			),
			name: 'content',
		},
	],
	[TRANSFORMATION_TRUNCATE]: [
		{
			extraProps: {
				attributes: {
					min: 1,
				},
				type: ParameterFieldType.Number,
			},
			label: (
				<FormattedMessage {...messages.transformationTruncateParameterLabel} />
			),
			name: 'maximum_length',
			validation: ({ f }) => ([
				f.validateNonEmpty(),
				f.validateMinimumValue(1),
			]),
		},
	],
	[TRANSFORMATION_WHITELIST_HTML_ATTRIBUTES]: [
		{
			defaultValue: '',
			label: (
				<FormattedMessage {...messages.transformationWhitelistHtmlAttributesParameterLabel} />
			),
			name: 'whitelist',
		},
	],
	['']: fallbackParameters,
};



type Props = {
	columnWidth: number,
	name: string,
	transformationType: string | null,
};

const TransformationParameterFields: React.FC<Props> = (props) => {
	const {
		columnWidth,
		name,
		transformationType,
	} = props;

	const intl = useIntl();

	const parameters = parametersPerTransformationType[transformationType ?? ''] ?? fallbackParameters;

	function renderParameterField(parameter: Parameter, parameterFieldName: string) {
		if ('field' in parameter) {
			return parameter.field(
				parameterFieldName,
				columnWidth / parameters.length - 8,
			);
		} else if ('options' in parameter) {
			return (
				<ParameterSelectField
					extraProps={parameter.extraProps}
					name={parameterFieldName}
					options={parameter.options}
				/>
			);
		}

		const placeholder = parameter.name === 'glue'
			? intl.formatMessage(messages.transformationConcatenateParameterPlaceholder)
			: intl.formatMessage(messages.parameterPlaceholder);

		return (
			<ParameterField
				extraProps={parameter.extraProps}
				name={parameterFieldName}
				placeholder={placeholder}
			/>
		);
	}

	return (
		<CompositeField
			fields={parameters}
			name={name}
		>
			{({ getFieldName }) => (
				<Grid
					columnsCount={parameters.length}
					gapsSize={GridGapsSize.Small}
				>
					{parameters.map((parameter) => {
						const fieldName = getFieldName(parameter.name);

						return (
							<FormRow
								htmlFor={fieldName}
								indentationSize={0}
								inline={false}
								key={parameter.name}
								label={parameter.label}
								maxFieldWidth="100%"
							>
								<FieldStatus
									name={getFieldName(parameter.name)}
									showIcon={false}
								>
									{renderParameterField(parameter, fieldName)}
								</FieldStatus>
							</FormRow>
						);
					})}
				</Grid>
			)}
		</CompositeField>
	);
};



export default TransformationParameterFields;
