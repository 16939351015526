import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import Copy, {
	linkExternal,
} from '~/components/logic/Copy';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import HelpHint from '~/components/patterns/hints/HelpHint';
import SelectField from '~/components/atoms/forms/components/SelectField';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import StaticText from '~/components/atoms/forms/components/StaticText';
import TextArea from '~/components/atoms/forms/components/TextArea';
import UserAgentHeaderValue from '~/components/app/UserAgentHeaderValue';
import UserAgentName from '~/components/names/UserAgentName';

import {
	validateField,
} from '~/components/app/validations';

import useFormContext from '~/hooks/useFormContext';

import {
	UserAgent,
} from '~/model/websites/userAgents';



export enum UserAgentFieldsScope {
	Account,
	AddWebsite,
	Website,
}



const UserAgentMapping = [
	UserAgent.Automatic,
	UserAgent.GooglechromeDesktop,
	UserAgent.GooglebotDesktop,
	UserAgent.GooglebotMobile,
	UserAgent.BingbotDesktop,
	UserAgent.BingbotMobile,
	UserAgent.Slurp,
	UserAgent.Yandexbot,
	UserAgent.Baiduspider,
	UserAgent.ScreamingFrog,
	UserAgent.GooglechromeMobile,
	UserAgent.DuckDuckGo,
	UserAgent.Seznambot,
	UserAgent.ContentKing,
	UserAgent.Custom,
] as const;



const messages = defineMessages({
	userAgent: {
		id: 'ui.userAgentFields.userAgent',
	},
	descriptionAutomaticUserAgent: {
		id: 'ui.userAgentFields.description.automatic',
	},
	disabledCustomUserAgent: {
		id: 'ui.userAgentFields.description.custom',
	},
	errorCustomUserAgent: {
		id: 'ui.userAgentFields.error.custom',
	},
});

const hintMessages = defineMessages({
	[UserAgentFieldsScope.Account]: {
		id: 'ui.userAgentFields.hint.account',
	},
	[UserAgentFieldsScope.AddWebsite]: {
		id: 'ui.userAgentFields.hint.website',
	},
	[UserAgentFieldsScope.Website]: {
		id: 'ui.userAgentFields.hint.website',
	},
});



type Props = {
	customHeaderValue?: string | null,
	isEditable?: boolean,
	isWebsiteVerified?: boolean,
	scope: UserAgentFieldsScope,
	userAgent: UserAgent | null,
};

const UserAgentFields: React.FC<Props> = (props) => {
	const {
		customHeaderValue,
		isEditable = true,
		isWebsiteVerified = false,
		scope,
		userAgent,
	} = props;

	const formContext = useFormContext();

	const userAgentOptions = React.useMemo(
		() => {
			return UserAgentMapping
				.filter((userAgent) => {
					if (userAgent === UserAgent.Custom) {
						return (
							scope === UserAgentFieldsScope.AddWebsite
							|| scope === UserAgentFieldsScope.Website
						);
					}

					if (userAgent === UserAgent.Automatic) {
						return (
							scope === UserAgentFieldsScope.Account
						);
					}

					return true;
				})
				.map((userAgent) => {
					const name = userAgent;
					const label = <UserAgentName userAgent={userAgent} />;
					let disabled = false;
					let description: React.ReactNode | null = null;

					if (userAgent === UserAgent.Automatic) {
						description = <FormattedMessage {...messages.descriptionAutomaticUserAgent} />;
					}

					if (
						userAgent === UserAgent.Custom
						&& isWebsiteVerified === false
					) {
						disabled = true;

						if (scope === UserAgentFieldsScope.Website) {
							description = <FormattedMessage {...messages.disabledCustomUserAgent} />;
						}
					}

					return {
						description,
						disabled,
						label,
						name,
					};
				});
		},
		[
			scope,
			isWebsiteVerified,
		],
	);

	function renderUserAgentLabel() {
		const hintMessage = hintMessages[scope];

		return (
			<AttachedElement
				element={(
					<HelpHint
						message={(
							<Copy
								{...hintMessage}
								values={{
									linkArticle: linkExternal('https://www.contentkingapp.com/support/user-agent-string/'),
								}}
							/>
						)}
					/>
				)}
			>
				<FormattedMessage {...messages.userAgent} />
			</AttachedElement>
		);
	}

	const selectedUserAgent = (formContext.values['userAgent'] ?? userAgent ?? null) as (UserAgent | null);

	if (isEditable === false) {
		return (
			<FormRow
				description={userAgent === null ? (
					<SquareSkeleton
						height={40}
						maxWidth={120}
					/>
				) : (
					<UserAgentHeaderValue
						customHeaderValue={customHeaderValue}
						userAgent={userAgent}
					/>
				)}
				htmlFor="userAgent"
				label={renderUserAgentLabel()}
			>
				<StaticText focusTarget="userAgent">
					{userAgent === null ? (
						<SquareSkeleton maxWidth={80} />
					) : (
						<UserAgentName userAgent={userAgent} />
					)}
				</StaticText>
			</FormRow>
		);
	}

	return (
		<FormRows>
			<FormRow
				description={selectedUserAgent !== UserAgent.Custom && (
					<UserAgentHeaderValue
						userAgent={selectedUserAgent}
					/>
				)}
				htmlFor="userAgent"
				label={renderUserAgentLabel()}
			>
				<FieldStatus name="validateUserAgent">
					<SelectField
						name="userAgent"
						options={userAgentOptions}
					/>
				</FieldStatus>
			</FormRow>

			{selectedUserAgent === UserAgent.Custom && (
				<FormRow
					htmlFor="userAgentCustomValue"
					label={(
						<UserAgentName userAgent={UserAgent.Custom} />
					)}
				>
					<FieldStatus name="validateUserAgentCustomValue">
						<TextArea
							isDisabled={isWebsiteVerified === false}
							name="userAgentCustomValue"
							resizable={true}
						/>
					</FieldStatus>
				</FormRow>
			)}
		</FormRows>
	);
};



export default UserAgentFields;

export const validateUserAgent = ({ isWebsiteVerified }: { isWebsiteVerified: boolean }) => {
	return validateField(
		'userAgent',
		(f) => ([
			f.validateNonEmpty(),
			f.custom({
				message: (
					<Copy
						{...messages.errorCustomUserAgent}
						values={{
							linkArticle: linkExternal('https://www.contentkingapp.com/support/user-agent-string/#changing-the-user-agent-string'),
						}}
					/>
				),
				rule: ({ value }) => {
					if (isWebsiteVerified === false && value === UserAgent.Custom) {
						return false;
					}

					return true;
				},
			}),
		]),
	);
};



export const validateUserAgentCustomValue = ({ isWebsiteVerified }: { isWebsiteVerified: boolean }) => {
	return validateField(
		'userAgentCustomValue',
		(f) => ([
			f.whenOtherField('userAgent', ({ value }) => {
				return isWebsiteVerified === true && value === UserAgent.Custom;
			}),
			f.validateNonEmpty(),
			f.validateMaximumLength(2048),
		]),
	);
};
