import React from 'react';

import type CK from '~/types/contentking';

import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Emphasis from '~/components/patterns/typography/Emphasis';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import InternalLink from '~/components/patterns/links/InternalLink';
import List from '~/components/patterns/lists/List';
import Spinner from '~/components/patterns/loaders/Spinner';

import {
	useRenderingAndLighthouseWebsitesQuery,
} from './RenderingAndLighthouseWebsitesForm.gql';



type Props = {
	accountId: CK.AccountId,
};

const RenderingAndLighthouseWebsitesForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const {
		data,
		loading,
	} = useRenderingAndLighthouseWebsitesQuery({
		variables: {
			accountId,
		},
	});

	const lighthouseWebsites = (
		data?.account?.websites
			.filter((website) => website.isLighthouseMonitored)
			.sort((a, b) => a.displayName.localeCompare(b.displayName))
	) ?? [];

	const renderedWebsites = (
		data?.account?.websites
			.filter((website) => website.isDomTracked)
			.sort((a, b) => a.displayName.localeCompare(b.displayName))
	) ?? [];

	return (
		<EditableFormWrapper
			isForAdmins={true}
			isReadOnly={true}
			title="JavaScript Rendering and Lighthouse"
		>
			<DisplayPart>
				<FormRows>
					<FormRow label="Websites using JS rendering">
						<List>
							{loading ? (
								<Spinner />
							) : renderedWebsites.length > 0 ? (
								renderedWebsites.map((website) => (
									<InternalLink
										key={website.id}
										routeName="website.detail.monitoring"
										routeParams={{ websiteId: website.id }}
									>
										{website.displayName}
									</InternalLink>
								))
							) : (
								<Emphasis>none</Emphasis>
							)}
						</List>
					</FormRow>

					<FormRow label="Websites using Lighthouse">
						<List>
							{loading ? (
								<Spinner />
							) : lighthouseWebsites.length > 0 ? (
								lighthouseWebsites.map((website) => (
									<InternalLink
										key={website.id}
										routeName="website.detail.monitoring"
										routeParams={{ websiteId: website.id }}
									>
										{website.displayName}
									</InternalLink>
								))
							) : (
								<Emphasis>none</Emphasis>
							)}
						</List>
					</FormRow>
				</FormRows>
			</DisplayPart>

			<EditablePart />
		</EditableFormWrapper>
	);
};



export default RenderingAndLighthouseWebsitesForm;
