/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type BillingDetailsGroupQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type BillingDetailsGroupQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly billingDetails: { readonly __typename?: 'AccountBillingDetails', readonly emails: ReadonlyArray<string>, readonly vatNumber: string | null, readonly customerAddress: { readonly __typename?: 'BillingDetailsAddress', readonly address: string, readonly city: string, readonly country: string, readonly name: string, readonly postalCode: string, readonly state: string | null }, readonly deliveryAddress: { readonly __typename?: 'BillingDetailsAddress', readonly address: string, readonly city: string, readonly country: string, readonly name: string, readonly postalCode: string, readonly state: string | null } | null, readonly extraInvoiceDetails: { readonly __typename?: 'BillingExtraInvoiceDetails', readonly attn: string | null, readonly customerReference: string | null, readonly purchaseOrderNumber: string | null, readonly supplierNumber: string | null } | null } | null } | null };


export const BillingDetailsGroupDocument = gql`
    query BillingDetailsGroup($accountId: AccountId!) {
  account(id: $accountId) {
    id
    billingDetails {
      customerAddress {
        address
        city
        country
        name
        postalCode
        state
      }
      deliveryAddress {
        address
        city
        country
        name
        postalCode
        state
      }
      emails
      extraInvoiceDetails {
        attn
        customerReference
        purchaseOrderNumber
        supplierNumber
      }
      vatNumber
    }
  }
}
    `;

/**
 * __useBillingDetailsGroupQuery__
 *
 * To run a query within a React component, call `useBillingDetailsGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillingDetailsGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillingDetailsGroupQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useBillingDetailsGroupQuery(baseOptions: Apollo.QueryHookOptions<BillingDetailsGroupQuery, BillingDetailsGroupQueryVariables> & ({ variables: BillingDetailsGroupQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BillingDetailsGroupQuery, BillingDetailsGroupQueryVariables>(BillingDetailsGroupDocument, options);
      }
export function useBillingDetailsGroupLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillingDetailsGroupQuery, BillingDetailsGroupQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BillingDetailsGroupQuery, BillingDetailsGroupQueryVariables>(BillingDetailsGroupDocument, options);
        }
export function useBillingDetailsGroupSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<BillingDetailsGroupQuery, BillingDetailsGroupQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BillingDetailsGroupQuery, BillingDetailsGroupQueryVariables>(BillingDetailsGroupDocument, options);
        }
export type BillingDetailsGroupQueryHookResult = ReturnType<typeof useBillingDetailsGroupQuery>;
export type BillingDetailsGroupLazyQueryHookResult = ReturnType<typeof useBillingDetailsGroupLazyQuery>;
export type BillingDetailsGroupSuspenseQueryHookResult = ReturnType<typeof useBillingDetailsGroupSuspenseQuery>;
export type BillingDetailsGroupQueryResult = Apollo.QueryResult<BillingDetailsGroupQuery, BillingDetailsGroupQueryVariables>;