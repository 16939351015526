import React from 'react';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import useCreateExport from '~/hooks/useCreateExport';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	IssueCategoryName,
} from '~/model/issuesNew';

import {
	totalSelector,
} from '~/state/affectedPages/currentWebsiteSelectors';

import {
	categorySelector,
	filterSelector,
	sortBySelector,
} from '~/state/affectedPages/selectors';



function useExportIssueCategoryAffectedPages() {
	const websiteId = useWebsiteId();

	const createExport = useCreateExport(websiteId);
	const filter = useSelector(filterSelector);
	const issueCategory = useSelector(categorySelector);
	const sortBy = useSelector(sortBySelector);
	const totalCount = useSelector(totalSelector);

	const largeExportThreshold = [
		IssueCategoryName.Images,
		IssueCategoryName.Links,
	].includes(issueCategory) ? 2000 : 200000;

	const isLarge = totalCount >= largeExportThreshold;

	return React.useCallback(
		async () => {
			const criteria = {
				filter: filter.toJS(),
				issueCategory,
				sortBy: sortBy.toJS(),
			};

			if (criteria.filter.is_indexable) {
				if (criteria.filter.is_indexable.length === 1) {
					criteria.filter.is_indexable = criteria.filter.is_indexable[0];
				} else if (criteria.filter.is_indexable.length > 1) {
					criteria.filter.is_indexable = 'invalid';
				}
			}

			if (criteria.filter.is_linked) {
				if (criteria.filter.is_linked.length === 1) {
					criteria.filter.is_linked = criteria.filter.is_linked[0];
				} else if (criteria.filter.is_linked.length > 1) {
					criteria.filter.is_linked = 'invalid';
				}
			}

			if (criteria.filter.is_secured) {
				if (criteria.filter.is_secured.length === 1) {
					criteria.filter.is_secured = criteria.filter.is_secured[0];
				} else if (criteria.filter.is_secured.length > 1) {
					criteria.filter.is_secured = 'invalid';
				}
			}

			if (criteria.filter.xml_sitemap_status && criteria.filter.xml_sitemap_status.length === 1) {
				if (criteria.filter.xml_sitemap_status.indexOf('incorrectly_present') !== -1) {
					criteria.filter.issue = {
						issue: 'xml_sitemap_incorrectly_present',
					};
				} else {
					criteria.filter.issue = {
						issue: 'is_in_sitemap',
					};
				}
			}

			delete criteria.filter.xml_sitemap_status;

			const exportData = await createExport({
				criteria,
				type: GraphQL.ExportType.AffectedPages,
			});

			return {
				...exportData,
				isLarge,
			};
		},
		[
			createExport,
			filter,
			isLarge,
			issueCategory,
			sortBy,
		],
	);
}



export default useExportIssueCategoryAffectedPages;
