/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AgencyConnectionsQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AgencyConnectionsQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly agencyConnections: ReadonlyArray<{ readonly __typename?: 'AccountAccessAgencyConnection', readonly agencyAccountId: CK.AccountId, readonly agencyAccountName: string, readonly id: any, readonly inviterEmail: string | null, readonly isInitiatedByAgency: boolean, readonly isPending: boolean, readonly level: GraphQL.AccountAccessConnectionLevel }> } | null };


export const AgencyConnectionsDocument = gql`
    query AgencyConnections($accountId: AccountId!) {
  account(id: $accountId) {
    agencyConnections {
      agencyAccountId
      agencyAccountName
      id
      inviterEmail
      isInitiatedByAgency
      isPending
      level
    }
    id
  }
}
    `;

/**
 * __useAgencyConnectionsQuery__
 *
 * To run a query within a React component, call `useAgencyConnectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAgencyConnectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAgencyConnectionsQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAgencyConnectionsQuery(baseOptions: Apollo.QueryHookOptions<AgencyConnectionsQuery, AgencyConnectionsQueryVariables> & ({ variables: AgencyConnectionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AgencyConnectionsQuery, AgencyConnectionsQueryVariables>(AgencyConnectionsDocument, options);
      }
export function useAgencyConnectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AgencyConnectionsQuery, AgencyConnectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AgencyConnectionsQuery, AgencyConnectionsQueryVariables>(AgencyConnectionsDocument, options);
        }
export function useAgencyConnectionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AgencyConnectionsQuery, AgencyConnectionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AgencyConnectionsQuery, AgencyConnectionsQueryVariables>(AgencyConnectionsDocument, options);
        }
export type AgencyConnectionsQueryHookResult = ReturnType<typeof useAgencyConnectionsQuery>;
export type AgencyConnectionsLazyQueryHookResult = ReturnType<typeof useAgencyConnectionsLazyQuery>;
export type AgencyConnectionsSuspenseQueryHookResult = ReturnType<typeof useAgencyConnectionsSuspenseQuery>;
export type AgencyConnectionsQueryResult = Apollo.QueryResult<AgencyConnectionsQuery, AgencyConnectionsQueryVariables>;