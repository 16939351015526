import React from 'react';

import AttachedIcon from '~/components/patterns/structuredValues/AttachedIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';



type Props = {
	/** Exit button for tracked changes mode */
	exitButton: React.ReactNode,
	/** Label describing current tracked changes mode */
	label: React.ReactNode,
};



const TrackedChangesModeHighlight: React.FC<Props> = (props) => {
	const {
		exitButton,
		label,
	} = props;

	return (
		<section className="header-tracked-changes-highlight">
			<div className="header-tracked-changes-highlight__label">
				<AttachedIcon
					ellipsis={false}
					icon={(
						<BasicIcon
							size={28}
							type={BasicIconType.History}
						/>
					)}
				>
					{label}
				</AttachedIcon>
			</div>
			<div className="header-tracked-changes-highlight__exit-button">
				{exitButton}
			</div>
		</section>
	);
};



export default TrackedChangesModeHighlight;
