import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import BillingCycleConfigurator from '../payments/BillingCycleConfigurator';
import BusinessSection from './components/BusinessSection';
import CurrencyConfigurator from '../payments/CurrencyConfigurator';
import DesktopPlansComparison from './plans/DesktopPlansComparison';
import DesktopSignupScreenLayout from '~/components/patterns/screens/signup/layouts/DesktopSignupScreenLayout';
import EnterprisePlanOverview from './components/EnterprisePlanOverview';
import PagesConfiguratorSection from './components/PagesConfiguratorSection';
import StickyPlansComparison from './plans/StickyPlansComparison';

import useAccountCustomerType from '~/hooks/useAccountCustomerType';

import {
	type BenefitSection,
} from '~/model/benefits';

import {
	Tariff,
} from '~/model/pricing/tariffs';



type Props = {
	accountId: CK.AccountId,
	bestChoicePlan?: GraphQL.AccountPlan,
	disabledPlans: {
		[K in GraphQL.AccountPlan]?: 'accountTooLarge' | 'disallowed' | undefined
	},
	mostPopularPlan?: GraphQL.AccountPlan,
	plans: ReadonlyArray<GraphQL.AccountPlan>,
	sections: Array<BenefitSection>,
	showBillingCycleConfigurator: boolean,
	showCurrencyConfigurator: boolean,
	showOnlyEnterprisePlan: boolean,
	tariff: Tariff,
};

const DesktopAccountSignup: React.FC<Props> = (props) => {
	const {
		accountId,
		bestChoicePlan,
		disabledPlans,
		mostPopularPlan,
		plans,
		sections,
		showBillingCycleConfigurator,
		showCurrencyConfigurator,
		showOnlyEnterprisePlan,
		tariff,
	} = props;

	const accountCustomerType = useAccountCustomerType(accountId);

	const plansRef = React.useRef<HTMLDivElement | null>(null);

	return (
		<DesktopSignupScreenLayout
			billingCycleConfigurator={showBillingCycleConfigurator && (
				<BillingCycleConfigurator accountId={accountId} />
			)}
			businessSection={(
				<BusinessSection
					benefits={!showOnlyEnterprisePlan && tariff !== Tariff.C2}
					customerType={accountCustomerType}
				/>
			)}
			currencyConfigurator={showCurrencyConfigurator && (
				<CurrencyConfigurator
					accountId={accountId}
				/>
			)}
			pagesConfigurator={(
				<PagesConfiguratorSection
					accountId={accountId}
				/>
			)}
			plans={(
				showOnlyEnterprisePlan ? (
					null
				) : (
					<>
						<StickyPlansComparison
							disabledPlans={disabledPlans}
							plans={plans}
							plansRef={plansRef}
						/>

						<DesktopPlansComparison
							bestChoicePlan={bestChoicePlan}
							disabledPlans={disabledPlans}
							mostPopularPlan={mostPopularPlan}
							plans={plans}
							ref={plansRef}
							sections={sections}
							tariff={tariff}
						/>
					</>
				)
			)}
			selectedPlanOverview={(
				showOnlyEnterprisePlan ? (
					<EnterprisePlanOverview />
				) : (
					null
				)
			)}
		/>
	);
};



export default DesktopAccountSignup;
