import React from 'react';

import styles from './VisuallyHidden.module.scss';



type Props = {
	children?: React.ReactNode,
};

const VisuallyHidden: React.FC<Props> = (props) => {
	const {
		children,
	} = props;

	return (
		<div
			className={styles.component}
			hidden={true}
		>
			{children}
		</div>
	);
};



export default VisuallyHidden;
