import React from 'react';

import type CK from '~/types/contentking';
import type GraphQL from '~/types/graphql';

import BillingCycleConfigurator from '../payments/BillingCycleConfigurator';
import CurrencyConfigurator from '../payments/CurrencyConfigurator';
import EnterprisePlanOverview from './components/EnterprisePlanOverview';
import MobilePlansComparison from './plans/MobilePlansComparison';
import MobileSignupScreenLayout from '~/components/patterns/screens/signup/layouts/MobileSignupScreenLayout';
import PagesConfiguratorSection from './components/PagesConfiguratorSection';
import PlansOverviewSection from './components/PlansOverviewSection';

import {
	type BenefitSection,
} from '~/model/benefits';

import {
	type Tariff,
} from '~/model/pricing/tariffs';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



type Props = {
	accountId: CK.AccountId,
	bestChoicePlan?: GraphQL.AccountPlan,
	disabledPlans: {
		[K in GraphQL.AccountPlan]?: 'accountTooLarge' | 'disallowed' | undefined
	},
	plans: ReadonlyArray<GraphQL.AccountPlan>,
	mostPopularPlan?: GraphQL.AccountPlan,
	sections: Array<BenefitSection>,
	showBillingCycleConfigurator: boolean,
	showCurrencyConfigurator: boolean,
	showOnlyEnterprisePlan: boolean,
	tariff: Tariff,
};

const MobileAccountSignup: React.FC<Props> = (props) => {
	const {
		accountId,
		disabledPlans,
		mostPopularPlan,
		plans,
		sections,
		showBillingCycleConfigurator,
		showCurrencyConfigurator,
		showOnlyEnterprisePlan,
		tariff,
	} = props;

	const [focusedPlan, setFocusedPlan] = React.useState<GraphQL.AccountPlan>(
		getArrayItemAtSafeIndex(plans, 0),
	);

	const plansRef = React.useRef<HTMLDivElement | null>(null);

	if (showOnlyEnterprisePlan) {
		return (
			<MobileSignupScreenLayout
				currencyConfigurator={(
					showCurrencyConfigurator && (
						<CurrencyConfigurator
							accountId={accountId}
						/>
					)
				)}
				pagesConfigurator={(
					<PagesConfiguratorSection
						accountId={accountId}
					/>
				)}
				selectedPlanOverview={(
					<EnterprisePlanOverview />
				)}
			/>
		);
	}

	return (
		<MobileSignupScreenLayout
			billingCycleConfigurator={(
				showBillingCycleConfigurator && (
					<BillingCycleConfigurator accountId={accountId} />
				)
			)}
			currencyConfigurator={(
				showCurrencyConfigurator && (
					<CurrencyConfigurator
						accountId={accountId}
					/>
				)
			)}
			pagesConfigurator={(
				<PagesConfiguratorSection
					accountId={accountId}
				/>
			)}
			plans={(
				<MobilePlansComparison
					disabledPlans={disabledPlans}
					focusedPlan={focusedPlan}
					mostPopularPlan={mostPopularPlan}
					plans={plans}
					ref={plansRef}
					sections={sections}
					tariff={tariff}
				/>
			)}
			plansOverview={(
				<PlansOverviewSection
					accountId={accountId}
					disabledPlans={disabledPlans}
					onPlanClick={setFocusedPlan}
					plans={plans}
				/>
			)}
		/>
	);
};



export default MobileAccountSignup;
