import type CK from '~/types/contentking';

import useTariffs from '~/hooks/useTariffs';



function useAccountTariffCalculator(accountId: CK.AccountId | null) {
	return useTariffs(accountId);
}



export default useAccountTariffCalculator;
