/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteLighthouseThresholdsQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteLighthouseThresholdsQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly lighthouseThresholds: { readonly __typename?: 'WebsiteLighthouseThresholds', readonly cls: ReadonlyArray<number>, readonly fcp: ReadonlyArray<number>, readonly lcp: ReadonlyArray<number>, readonly performance: ReadonlyArray<number>, readonly si: ReadonlyArray<number>, readonly tbt: ReadonlyArray<number>, readonly tti: ReadonlyArray<number> } } | null };


export const WebsiteLighthouseThresholdsDocument = gql`
    query WebsiteLighthouseThresholds($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    lighthouseThresholds {
      cls
      fcp
      lcp
      performance
      si
      tbt
      tti
    }
  }
}
    `;

/**
 * __useWebsiteLighthouseThresholdsQuery__
 *
 * To run a query within a React component, call `useWebsiteLighthouseThresholdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteLighthouseThresholdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteLighthouseThresholdsQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteLighthouseThresholdsQuery(baseOptions: Apollo.QueryHookOptions<WebsiteLighthouseThresholdsQuery, WebsiteLighthouseThresholdsQueryVariables> & ({ variables: WebsiteLighthouseThresholdsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteLighthouseThresholdsQuery, WebsiteLighthouseThresholdsQueryVariables>(WebsiteLighthouseThresholdsDocument, options);
      }
export function useWebsiteLighthouseThresholdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteLighthouseThresholdsQuery, WebsiteLighthouseThresholdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteLighthouseThresholdsQuery, WebsiteLighthouseThresholdsQueryVariables>(WebsiteLighthouseThresholdsDocument, options);
        }
export function useWebsiteLighthouseThresholdsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteLighthouseThresholdsQuery, WebsiteLighthouseThresholdsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteLighthouseThresholdsQuery, WebsiteLighthouseThresholdsQueryVariables>(WebsiteLighthouseThresholdsDocument, options);
        }
export type WebsiteLighthouseThresholdsQueryHookResult = ReturnType<typeof useWebsiteLighthouseThresholdsQuery>;
export type WebsiteLighthouseThresholdsLazyQueryHookResult = ReturnType<typeof useWebsiteLighthouseThresholdsLazyQuery>;
export type WebsiteLighthouseThresholdsSuspenseQueryHookResult = ReturnType<typeof useWebsiteLighthouseThresholdsSuspenseQuery>;
export type WebsiteLighthouseThresholdsQueryResult = Apollo.QueryResult<WebsiteLighthouseThresholdsQuery, WebsiteLighthouseThresholdsQueryVariables>;