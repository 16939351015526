/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUrlExclusionListMutationVariables = GraphQL.Exact<{
  rules: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type UpdateUrlExclusionListMutation = { readonly __typename?: 'Mutation', readonly UpdateUrlExclusionList: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly urlExclusionList: { readonly __typename?: 'WebsiteUrlExclusionList', readonly rules: ReadonlyArray<string> } } | null } } };


export const UpdateUrlExclusionListDocument = gql`
    mutation UpdateUrlExclusionList($rules: [String!]!, $websiteId: WebsiteId!) {
  UpdateUrlExclusionList(rules: $rules, websiteId: $websiteId) {
    query {
      website(id: $websiteId) {
        id
        urlExclusionList {
          rules
        }
      }
    }
  }
}
    `;
export type UpdateUrlExclusionListMutationFn = Apollo.MutationFunction<UpdateUrlExclusionListMutation, UpdateUrlExclusionListMutationVariables>;

/**
 * __useUpdateUrlExclusionListMutation__
 *
 * To run a mutation, you first call `useUpdateUrlExclusionListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUrlExclusionListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUrlExclusionListMutation, { data, loading, error }] = useUpdateUrlExclusionListMutation({
 *   variables: {
 *      rules: // value for 'rules'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useUpdateUrlExclusionListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUrlExclusionListMutation, UpdateUrlExclusionListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUrlExclusionListMutation, UpdateUrlExclusionListMutationVariables>(UpdateUrlExclusionListDocument, options);
      }
export type UpdateUrlExclusionListMutationHookResult = ReturnType<typeof useUpdateUrlExclusionListMutation>;
export type UpdateUrlExclusionListMutationResult = Apollo.MutationResult<UpdateUrlExclusionListMutation>;
export type UpdateUrlExclusionListMutationOptions = Apollo.BaseMutationOptions<UpdateUrlExclusionListMutation, UpdateUrlExclusionListMutationVariables>;