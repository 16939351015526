import React from 'react';
import {
	FormattedMessage,
	defineMessages,
	useIntl,
} from 'react-intl';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import CodeValue from '~/components/patterns/values/CodeValue';
import ExclusionRuleFormLayout from '~/components/atoms/forms/components/layout/ExclusionRuleFormLayout';
import FieldStatus from '~/components/patterns/forms/basis/FieldStatus';
import Form from '~/components/atoms/forms/basis/Form';
import SubmitButton, {
	SIZE_SMALL,
	STYLE_HOLLOW,
} from '~/components/atoms/forms/components/SubmitButton';
import TextField, {
	type TextFieldRef,
	TextFieldSize,
} from '~/components/atoms/forms/components/TextField';

import {
	validateField,
} from '~/components/app/validations';



const messages = defineMessages({
	addRuleFieldButton: {
		id: 'ui.websites.exclusionsModal.manager.addRule',
	},
	addRuleFieldPlaceholder: {
		id: 'ui.websites.exclusionsModal.manager.addRule.fieldPlaceholder',
	},
	formErrorsInvalid: {
		id: 'ui.modal.urlExclusionList.validationError',
	},
});

const defaultValues = {
	rule: '',
};

const validations = {
	firstlayer: validateField(
		'rule',
		(f) => [
			f.validateNonEmpty(),
		],
	),
	secondLayer: validateField(
		'rule',
		(f) => [
			f.custom({
				message: (
					<FormattedMessage
						{...messages.formErrorsInvalid}
						values={{
							code: (chunks) => (
								<CodeValue>{chunks}</CodeValue>
							),
						}}
					/>
				),
				rule: ({ value }) => {
					if (!value) {
						return true;
					}

					const firstLetter = value.substr(0, 1);

					return firstLetter === '/' || firstLetter === '*' || firstLetter === 'A';
				},
			}),
		],
	),
};



type Props = {
	submitCallback: (values: {
		rule: string,
	}) => void,
};

const AddExclusionRuleForm: React.FC<Props> = (props) => {
	const {
		submitCallback,
	} = props;

	const intl = useIntl();

	const [inputKey, setInputKey] = React.useState(0);

	const fieldRef = React.useRef<TextFieldRef>(null);

	const handleFormSubmit = React.useCallback(
		(model: {
			rule: string,
		}) => {
			setInputKey(
				(state) => {
					submitCallback(model);

					fieldRef.current?.focus();

					return state + 1;
				},
			);
		},
		[
			submitCallback,
		],
	);

	return (
		<Form
			defaultValues={defaultValues}
			key={inputKey}
			onSuccess={handleFormSubmit}
			validations={validations}
		>
			{({ isValid }) => {
				return (
					<ExclusionRuleFormLayout
						button={(
							<SubmitButton
								icon={(
									<BasicIcon
										size={18}
										type={BasicIconType.Plus}
									/>
								)}
								inactive={!isValid}
								size={SIZE_SMALL}
								style={STYLE_HOLLOW}
							>
								<FormattedMessage {...messages.addRuleFieldButton} />
							</SubmitButton>
						)}
						field={(
							<FieldStatus
								allowOk={false}
								name="secondLayer"
								showIcon={false}
							>
								<TextField
									name="rule"
									placeholder={intl.formatMessage(messages.addRuleFieldPlaceholder)}
									ref={fieldRef}
									size={TextFieldSize.Small}
									trimValue={true}
									width="100%"
								/>
							</FieldStatus>
						)}
					/>
				);
			}}
		</Form>
	);
};



export default AddExclusionRuleForm;
