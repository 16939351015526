/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsiteDomainQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteDomainQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly domain: string } | null };


export const WebsiteDomainDocument = gql`
    query WebsiteDomain($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    domain
  }
}
    `;

/**
 * __useWebsiteDomainQuery__
 *
 * To run a query within a React component, call `useWebsiteDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteDomainQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteDomainQuery(baseOptions: Apollo.QueryHookOptions<WebsiteDomainQuery, WebsiteDomainQueryVariables> & ({ variables: WebsiteDomainQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteDomainQuery, WebsiteDomainQueryVariables>(WebsiteDomainDocument, options);
      }
export function useWebsiteDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteDomainQuery, WebsiteDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteDomainQuery, WebsiteDomainQueryVariables>(WebsiteDomainDocument, options);
        }
export function useWebsiteDomainSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteDomainQuery, WebsiteDomainQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteDomainQuery, WebsiteDomainQueryVariables>(WebsiteDomainDocument, options);
        }
export type WebsiteDomainQueryHookResult = ReturnType<typeof useWebsiteDomainQuery>;
export type WebsiteDomainLazyQueryHookResult = ReturnType<typeof useWebsiteDomainLazyQuery>;
export type WebsiteDomainSuspenseQueryHookResult = ReturnType<typeof useWebsiteDomainSuspenseQuery>;
export type WebsiteDomainQueryResult = Apollo.QueryResult<WebsiteDomainQuery, WebsiteDomainQueryVariables>;