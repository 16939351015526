import React from 'react';
import ReactDOM from 'react-dom';
import scrollbarSize from 'dom-helpers/scrollbarSize';
import throttle from 'lodash/throttle';

import GraphQL from '~/types/graphql';

import {
	AccountScreenContext,
} from '~/components/logic/screens/AccountScreen';
import AttachedEnterpriseRibbon from '~/components/patterns/pricingPlans/AttachedEnterpriseRibbon';
import BillingCycleConfigurator from '../../payments/BillingCycleConfigurator';
import PlanDetails from '~/components/patterns/pricingPlans/PlanDetails';
import PlanName from '~/components/names/PlanName';
import PlanPriceSummary, {
	PlanPriceSummaryView,
} from '~/components/logic/account/components/PlanPriceSummary';
import PlansComparisonTableStickyColumn from '~/components/patterns/pricingPlans/plansComparisonTable/PlansComparisonTableStickyColumn';
import SignupPlanComparisonButton from '~/components/app/SignupPlanComparisonButton';
import SignupPlansStickyOverview from '~/components/patterns/screens/signup/sections/SignupPlansStickyOverview';

import useAccountId from '~/hooks/useAccountId';
import useAccountSignup from '~/hooks/useAccountSignup';
import useScrollContainer from '~/hooks/useScrollContainer';
import useViewportType from '~/hooks/useViewportType';

import {
	isEnterprisePlan,
} from '~/model/plans';



type Props = {
	disabledPlans: {
		[K in GraphQL.AccountPlan]?: 'accountTooLarge' | 'disallowed' | undefined
	},
	plans: ReadonlyArray<GraphQL.AccountPlan>,
	plansRef: React.RefObject<HTMLDivElement | null>,
};

const StickyPlansComparison: React.FC<Props> = (props) => {
	const {
		disabledPlans,
		plans,
		plansRef,
	} = props;

	const scrollContainer = useScrollContainer() as (HTMLElement | null);
	const accountId = useAccountId();
	const accountSignup = useAccountSignup(accountId);

	const viewportType = useViewportType();

	const [showBillingCycle, setBillingCycleVisibility] = React.useState(accountSignup.billingCycle === GraphQL.Term.Monthly);
	const [scrollPosition, setScrollPosition] = React.useState(0);
	const [isStickyHeaderVisible, setStickyHeaderVisibility] = React.useState(false);

	React.useEffect(() => {
		if (!isStickyHeaderVisible) {
			setBillingCycleVisibility(accountSignup.billingCycle === GraphQL.Term.Monthly);
		}
	}, [
		accountSignup.billingCycle,
		isStickyHeaderVisible,
	]);

	React.useEffect(() => {
		if (!scrollContainer) {
			return;
		}

		const scrollListener = throttle((e) => {
			setScrollPosition(e.target.scrollTop);
		});

		scrollContainer.addEventListener('scroll', scrollListener);

		return () => {
			scrollContainer.removeEventListener('scroll', scrollListener);
		};
	}, [scrollContainer]);

	React.useEffect(() => {
		const stickyHeaderVisiblePosition = (plansRef.current?.offsetTop || 0) + 280;

		if (scrollPosition > stickyHeaderVisiblePosition && !isStickyHeaderVisible) {
			setStickyHeaderVisibility(true);
		}

		if (scrollPosition <= stickyHeaderVisiblePosition && isStickyHeaderVisible) {
			setStickyHeaderVisibility(false);
		}
	}, [
		isStickyHeaderVisible,
		plansRef,
		scrollPosition,
	]);

	const {
		stickyContentElementRef,
	} = React.useContext(AccountScreenContext);

	if (stickyContentElementRef.current === null) {
		return null;
	}

	return ReactDOM.createPortal(
		<SignupPlansStickyOverview
			isVisible={isStickyHeaderVisible}
			scrollbarOffset={scrollbarSize()}
			sidebar={showBillingCycle && (
				<BillingCycleConfigurator accountId={accountId} />
			)}
		>
			{plans.map((plan, i) => {
				const isDisabled = disabledPlans[plan] !== undefined;
				const isEnterprise = isEnterprisePlan(plan);

				return (
					<PlansComparisonTableStickyColumn
						isDisabled={isDisabled}
						isEnterprise={isEnterprise}
						isFirst={i === 0}
						isLast={i === plans.length - 1}
						key={plan}
					>
						<PlanDetails
							ctaButton={(
								<SignupPlanComparisonButton plan={plan} />
							)}
							isCompact={true}
							isDisabled={isDisabled}
							name={isEnterprise && !viewportType.isMedium ? (
								<AttachedEnterpriseRibbon>
									<PlanName plan={plan} />
								</AttachedEnterpriseRibbon>
							) : (
								<PlanName plan={plan} />
							)}
							priceOverview={accountId !== null && (
								<PlanPriceSummary
									accountId={accountId}
									disabledReason={disabledPlans[plan]}
									plan={plan}
									view={PlanPriceSummaryView.Reduced}
								/>
							)}
						/>
					</PlansComparisonTableStickyColumn>
				);
			})}
		</SignupPlansStickyOverview>,
		stickyContentElementRef.current,
	);
};



export default StickyPlansComparison;
