import {
	ApolloClient,
	ApolloLink,
	HttpLink,
	InMemoryCache,
} from '@apollo/client';
import {
	BatchHttpLink,
} from '@apollo/client/link/batch-http';
import fetch from 'isomorphic-fetch';
import isEqual from 'lodash/isEqual';

import {
	fieldMergePolicyForExpiringEdges,
} from '~/apolloUtilities';

import {
	APP_VERSION,
	CONTENTKING_API_URL,
} from '~/config';

import {
	isString,
	notEmpty,
} from '~/utilities/typeCheck';



const cache = new InMemoryCache({
	possibleTypes: {
		PageHistoricalMoment: ['PageHistoricalMomentPageDiscovered', 'PageHistoricalMomentTrackedChanges'],
	},
	typePolicies: {
		Account: {
			fields: {
				adminSettings: {
					merge: true,
				},
				billingDetails: {
					merge: true,
				},
			},
		},
		AccountAccessRestrictions: {
			merge: true,
		},
		AccountMembership: {
			keyFields: [
				'account',
				['id'],
				'user',
				['legacyId'],
			],
		},
		AccountProfileCompleteness: {
			merge: true,
		},
		AccountUserRestrictions: {
			merge: true,
		},
		AccountWebsiteRestrictions: {
			merge: true,
		},
		AdobeAnalyticsDimension: {
			keyFields: false,
		},
		AuthenticatedSession: {
			keyFields: [],
		},
		AuthenticatedSessionAccountMembershipLabel: {
			keyFields: ['accountId'],
		},
		Discount: {
			keyFields: ['coupon'],
		},
		FilteredPagesConnection: {
			fields: {
				distributions: {
					merge: (existing, incoming) => {
						if (isEqual(existing, incoming)) {
							return existing;
						}

						return incoming;
					},
				},
				edges: {
					merge: fieldMergePolicyForExpiringEdges(),
				},
			},
		},
		FilteredPagesConnectionEdge: {
			keyFields: false,
		},
		IndexNowSubmission: {
			merge: true,
		},
		KingdomSettings: {
			merge: true,
		},
		NonPagesConnection: {
			fields: {
				distributions: {
					merge: (existing, incoming) => {
						if (isEqual(existing, incoming)) {
							return existing;
						}

						return incoming;
					},
				},
				edges: {
					merge: fieldMergePolicyForExpiringEdges(),
				},
			},
		},
		NonPagesConnectionEdge: {
			keyFields: false,
		},
		Page: {
			fields: {
				incomingCanonicalLinks: {
					keyArgs: [],
				},
				incomingInternalLinks: {
					keyArgs: [],
				},
				incomingRedirects: {
					keyArgs: [],
				},
				pageTypeData: {
					merge: true,
				},
				searchEngineActivity: {
					merge: true,
				},
			},
		},
		PageIncomingCanonicalLinksConnection: {
			fields: {
				edges: {
					merge: fieldMergePolicyForExpiringEdges(),
				},
			},
		},
		PageIncomingCanonicalLinksConnectionEdge: {
			keyFields: false,
		},
		PageIncomingInternalLinksConnection: {
			fields: {
				edges: {
					merge: fieldMergePolicyForExpiringEdges(),
				},
			},
		},
		PageIncomingInternalLinksConnectionEdge: {
			keyFields: false,
		},
		PageIncomingRedirectsConnection: {
			fields: {
				edges: {
					merge: fieldMergePolicyForExpiringEdges(),
				},
			},
		},
		PageIncomingRedirectsConnectionEdge: {
			keyFields: false,
		},
		PageIssue: {
			keyFields: false,
		},
		PageIssueCategory: {
			keyFields: false,
		},
		PageOutgoingExternalLinksConnection: {
			fields: {
				edges: {
					merge: fieldMergePolicyForExpiringEdges(),
				},
			},
		},
		PageOutgoingExternalLinksConnectionEdge: {
			keyFields: false,
		},
		PageOutgoingInternalLinksConnection: {
			fields: {
				edges: {
					merge: fieldMergePolicyForExpiringEdges(),
				},
			},
		},
		PageOutgoingInternalLinksConnectionEdge: {
			keyFields: false,
		},
		PageTypeData: {
			fields: {
				outgoingExternalLinks: {
					keyArgs: [],
				},
				outgoingInternalLinks: {
					keyArgs: [],
				},
				primaryProperties: {
					merge: true,
				},
				srcProperties: {
					merge: true,
				},
			},
		},
		Query: {
			fields: {
				account: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: 'Account',
							id: args?.id,
						});
					},
				},
				dashboardData: {
					merge(existing, incoming) {
						return {
							...existing,
							...incoming,
						};
					},
				},
				filteredPages: {
					keyArgs: [
						'ascending',
						'filter',
						'sort',
						'websiteId',
					],
				},
				robotsTxtRevisionById: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: 'RobotsTxtRevision',
							id: args?.revisionId,
						});
					},
				},
				user: {
					read(_, { args, cache, toReference }) {
						if (args?.legacyId) {
							return toReference({
								__typename: 'User',
								legacyId: args.legacyId,
							});
						}

						if (args?.uniqueId) {
							const serializedState = cache.extract();

							const user = Object.values(serializedState).find(
								(item: any) => item.__typename === 'User' && item.uniqueId === args.uniqueId,
							);

							if (user !== undefined && user.legacyId) {
								return toReference({
									__typename: 'User',
									legacyId: user.legacyId,
								});
							}
						}
					},
				},
				userAccountMembership: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: 'AccountMembership',
							account: {
								id: args?.accountId ?? 0,
							},
							user: {
								legacyId: args?.legacyUserId ?? '',
							},
						});
					},
				},
				website: {
					read(_, { args, toReference }) {
						return toReference({
							__typename: 'Website',
							id: args?.id,
						});
					},
				},
			},
		},
		User: {
			fields: {
				personalTweaks: {
					merge: true,
				},
			},
			keyFields: ['legacyId'],
		},
		WebsiteDashboardWebVitalsOriginSummaryData: {
			fields: {
				data: {
					merge: true,
				},
			},
		},
		WebsiteGoogleAnalyticsIntegration: {
			merge: true,
		},
		Website: {
			fields: {
				adminSettings: {
					merge: true,
				},
				dashboardData: {
					merge(existing, incoming) {
						return {
							...existing,
							...incoming,
						};
					},
				},
			},
		},
		WebsiteEmailSettings: {
			keyFields: false,
		},
		WebsiteScopeIssues: {
			keyFields: false,
		},
	},
});



const baseUri = `${CONTENTKING_API_URL}/graphql`;

const httpOptions = {
	credentials: 'include',
	headers: {
		'X-Client-Type': 'app',
		'X-Client-Version': APP_VERSION,
	},
};

const batchLinks: Record<string, BatchHttpLink> = {};

function getBatchLink(name: string, batchMax: number = 20) {
	let batchLink = batchLinks[name];

	if (batchLink !== undefined) {
		return batchLink;
	}

	batchLink = new BatchHttpLink({
		...httpOptions,
		batchInterval: 50,
		batchMax,
		fetch: (uri, options) => {
			let opNames = '';

			const body = options?.body;
			if (isString(body)) {
				opNames = [...body.matchAll(/"operationName":\s*"([a-zA-Z0=9]+)"/g)]
					.map((match) => match[1])
					.filter(notEmpty)
					.sort((a, b) => a.localeCompare(b))
					.join('+');
			}

			return fetch(`${baseUri}?batch=${name}&op=${opNames}`, options);
		},
	});

	batchLinks[name] = batchLink;

	return batchLink;
}

const singleLink = new HttpLink({
	...httpOptions,
	uri: (operation) => {
		if (operation.operationName) {
			return `${baseUri}?single&op=${operation.operationName}`;
		}

		return baseUri;
	},
});

const splitLink = new ApolloLink(
	(operation) => {
		const batchName = operation.getContext().batchName;
		const batchMax = operation.getContext().batchMax;

		if (batchName !== undefined) {
			return getBatchLink(batchName, batchMax).request(operation);
		}

		return singleLink.request(operation);
	},
);



const client = new ApolloClient({
	assumeImmutableResults: true,
	cache,
	link: splitLink,
});



export {
	cache,
	client,
};
