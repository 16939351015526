/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TeamWebsitesDropdownQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type TeamWebsitesDropdownQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly accounts: ReadonlyArray<{ readonly __typename?: 'Account', readonly displayName: string, readonly id: CK.AccountId, readonly websites: ReadonlyArray<{ readonly __typename?: 'Website', readonly displayName: string, readonly id: CK.WebsiteId, readonly isCrawlingPaused: boolean, readonly isDiscovered: boolean }> }> } | null };


export const TeamWebsitesDropdownDocument = gql`
    query TeamWebsitesDropdown {
  authenticatedSession {
    accounts {
      displayName
      id
      websites {
        displayName
        id
        isCrawlingPaused
        isDiscovered
      }
    }
  }
}
    `;

/**
 * __useTeamWebsitesDropdownQuery__
 *
 * To run a query within a React component, call `useTeamWebsitesDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamWebsitesDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamWebsitesDropdownQuery({
 *   variables: {
 *   },
 * });
 */
export function useTeamWebsitesDropdownQuery(baseOptions?: Apollo.QueryHookOptions<TeamWebsitesDropdownQuery, TeamWebsitesDropdownQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamWebsitesDropdownQuery, TeamWebsitesDropdownQueryVariables>(TeamWebsitesDropdownDocument, options);
      }
export function useTeamWebsitesDropdownLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamWebsitesDropdownQuery, TeamWebsitesDropdownQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamWebsitesDropdownQuery, TeamWebsitesDropdownQueryVariables>(TeamWebsitesDropdownDocument, options);
        }
export function useTeamWebsitesDropdownSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamWebsitesDropdownQuery, TeamWebsitesDropdownQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamWebsitesDropdownQuery, TeamWebsitesDropdownQueryVariables>(TeamWebsitesDropdownDocument, options);
        }
export type TeamWebsitesDropdownQueryHookResult = ReturnType<typeof useTeamWebsitesDropdownQuery>;
export type TeamWebsitesDropdownLazyQueryHookResult = ReturnType<typeof useTeamWebsitesDropdownLazyQuery>;
export type TeamWebsitesDropdownSuspenseQueryHookResult = ReturnType<typeof useTeamWebsitesDropdownSuspenseQuery>;
export type TeamWebsitesDropdownQueryResult = Apollo.QueryResult<TeamWebsitesDropdownQuery, TeamWebsitesDropdownQueryVariables>;