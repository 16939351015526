import classNames from 'classnames';
import React from 'react';

import FormDescription from '../components/rowParts/FormDescription';



export enum FormRowAlignment {
	Center = 'center',
	Left = 'left',
	Right = 'right',
}

export enum FormRowStyle {
	Default = 'default',
	Highlighted = 'highlighted',
}



type Props = {
	alignment?: FormRowAlignment,
	children?: React.ReactNode,
	description?: React.ReactNode,
	flag?: React.ReactNode,
	fullwidth?: boolean,
	htmlFor?: string,
	indentationSize?: 0 | 1 | 2,
	inline?: boolean,
	label?: React.ReactNode,
	labelIcon?: React.ReactNode,
	maxFieldWidth?: React.CSSProperties['maxWidth'],
	style?: FormRowStyle,
};

const FormRow: React.FC<Props> = (props) => {
	const {
		alignment,
		children,
		description,
		flag,
		fullwidth = false,
		htmlFor,
		indentationSize = 1,
		inline = true,
		label,
		labelIcon,
		maxFieldWidth,
		style = FormRowStyle.Default,
	} = props;

	function renderLabel() {
		if (!label) {
			return null;
		}

		if (label === '') {
			return (
				<div className="form-row__label"></div>
			);
		}

		const labelClasses = classNames({
			'form-row__label': true,
			'form-row__label--clickable': htmlFor,
		});

		return (
			<label
				className={labelClasses}
				htmlFor={htmlFor}
			>
				<span className="form-row__label-container">
					{labelIcon && (
						<span className="form-row__label-icon">
							{labelIcon}
						</span>
					)}
					<span className="form-row__label-text">
						<span>{label}</span>
						{flag && (
							<span className="form-row__flag">
								{flag}
							</span>
						)}
					</span>
				</span>
			</label>
		);
	}

	function renderDescription() {
		if (!description) {
			return null;
		}

		return (
			<div className="form-row__description">
				<FormDescription>
					{description}
				</FormDescription>
			</div>
		);
	}

	const componentClasses = classNames({
		'form-row': true,
		'form-row--fullwidth': fullwidth,
		'form-row--inline': inline,
		'form-row--highlighted': style === FormRowStyle.Highlighted,
		'form-row--no-indentation': indentationSize === 0,
		'form-row--bigger-indentation': indentationSize === 2,
	});

	const valueClasses = classNames({
		'form-row__value': true,
		'form-row__value--center-aligned': alignment === FormRowAlignment.Center,
		'form-row__value--left-aligned': alignment === FormRowAlignment.Left,
		'form-row__value--right-aligned': alignment === FormRowAlignment.Right,
	});

	return (
		<div className={componentClasses}>
			{renderLabel()}
			<div
				className="form-row__content"
				style={{
					maxWidth: maxFieldWidth,
				}}
			>
				<div className={valueClasses}>
					{children}
				</div>
				{renderDescription()}
			</div>
		</div>
	);
};



export default FormRow;
