import type CK from '~/types/contentking';

import {
	useWebsitePagesCountQuery,
} from './useWebsitePagesCount.gql';



function useWebsitePagesCount(websiteId: CK.WebsiteId | null): number | null {
	const { data } = useWebsitePagesCountQuery({
		skip: websiteId === null,
		variables: {
			websiteId: websiteId ?? '',
		},
	});

	return data?.website?.pagesCount ?? null;
}



export default useWebsitePagesCount;
