import React from 'react';

import type CK from '~/types/contentking';

import ButtonsLayout, {
	ButtonsLayoutAlignment,
	ButtonsLayoutType,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import Form from '~/components/atoms/forms/basis/Form';
import FormErrorMessages from '~/components/app/FormErrorMessages';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SimpleModal, {
	SimpleModalIconType,
	SimpleModalSize,
} from '~/components/patterns/modals/SimpleModal';
import UrlBlocklistField from '~/components/app/UrlBlocklistField';

import {
	useUpdateWebsiteOnPageRequestsWhitelistMutation,
} from './WebsiteAdminSettingsOnPageRequestsWhitelistRulesModal.gql';

import useClassicFormBehavior from '~/hooks/useClassicFormBehavior';
import useWebsiteOnPageRequestsWhitelistRules from '~/hooks/useWebsiteOnPageRequestsWhitelistRules';

import {
	isNonEmptyString,
} from '~/utilities/typeCheck';



type Props = {
	websiteId: CK.WebsiteId,
};

const WebsiteAdminSettingsOnPageRequestsWhitelistRulesModal: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const classicFormBehavior = useClassicFormBehavior();
	const websiteOnPageRequestsWhitelistRules = useWebsiteOnPageRequestsWhitelistRules(websiteId);

	const [
		updateWebsiteOnPageRequestsWhitelist,
	] = useUpdateWebsiteOnPageRequestsWhitelistMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			await updateWebsiteOnPageRequestsWhitelist({
				variables: {
					rules: values.rules.filter(
						(rule) => isNonEmptyString(rule.value) && rule.operator !== undefined,
					),
					websiteId,
				},
			});

			classicFormBehavior.finish();
		},
		[
			classicFormBehavior,
			updateWebsiteOnPageRequestsWhitelist,
			websiteId,
		],
	);

	return (
		<SimpleModal
			alignToTop={true}
			iconType={SimpleModalIconType.CogWheel}
			size={SimpleModalSize.XXLarge}
			title="On-page requests whitelist"
		>
			<Form
				defaultValues={{
					rules: websiteOnPageRequestsWhitelistRules,
				}}
				onSuccess={handleSubmit}
			>
				<FormRows>
					<UrlBlocklistField
						__UGLY__transformEnumsToCamelCase={true}
						name="rules"
					/>

					<FormErrorMessages />
				</FormRows>

				<ButtonsLayout
					alignment={ButtonsLayoutAlignment.Right}
					layout={ButtonsLayoutType.Steps}
				>
					<CancelButton />
					<SaveSubmitButton />
				</ButtonsLayout>
			</Form>
		</SimpleModal>
	);
};



export default WebsiteAdminSettingsOnPageRequestsWhitelistRulesModal;
