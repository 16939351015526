import React from 'react';

import useFormContext from '~/hooks/useFormContext';

import {
	type Rule,
} from '~/utilities/validations';



function useFormValidations(
	id: string,
	validationsFactory: () => Record<string, ReadonlyArray<Rule>>,
) {
	const formContext = useFormContext();
	const formContextRegisterValidations = formContext.registerValidations;

	React.useEffect(
		() => {
			formContextRegisterValidations(
				id,
				validationsFactory(),
			);

			return () => {
				formContextRegisterValidations(id, {});
			};
		},
		[
			formContextRegisterValidations,
			id,
			validationsFactory,
		],
	);
}



export default useFormValidations;
