/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RoyalModeQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type RoyalModeQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly isImpersonated: boolean, readonly kingdomAdmin: { readonly __typename?: 'User', readonly email: string, readonly firstName: string | null, readonly id: string, readonly legacyId: string } | null } | null };


export const RoyalModeDocument = gql`
    query RoyalMode {
  authenticatedSession {
    isImpersonated
    kingdomAdmin {
      email
      firstName
      id
      legacyId
    }
  }
}
    `;

/**
 * __useRoyalModeQuery__
 *
 * To run a query within a React component, call `useRoyalModeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoyalModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoyalModeQuery({
 *   variables: {
 *   },
 * });
 */
export function useRoyalModeQuery(baseOptions?: Apollo.QueryHookOptions<RoyalModeQuery, RoyalModeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoyalModeQuery, RoyalModeQueryVariables>(RoyalModeDocument, options);
      }
export function useRoyalModeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoyalModeQuery, RoyalModeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoyalModeQuery, RoyalModeQueryVariables>(RoyalModeDocument, options);
        }
export function useRoyalModeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RoyalModeQuery, RoyalModeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RoyalModeQuery, RoyalModeQueryVariables>(RoyalModeDocument, options);
        }
export type RoyalModeQueryHookResult = ReturnType<typeof useRoyalModeQuery>;
export type RoyalModeLazyQueryHookResult = ReturnType<typeof useRoyalModeLazyQuery>;
export type RoyalModeSuspenseQueryHookResult = ReturnType<typeof useRoyalModeSuspenseQuery>;
export type RoyalModeQueryResult = Apollo.QueryResult<RoyalModeQuery, RoyalModeQueryVariables>;