import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import BlankSlateOverlay from './BlankslateOverlay';
import Button, {
	ButtonSize,
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import ButtonsGroup from '~/components/patterns/buttons/ButtonsGroup';
import ButtonsLayout, {
	ButtonsLayoutType,
	ButtonsLayoutUsageContext,
} from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import DatatableBodyCell, {
	DatatableBodyCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableBodyCell';
import DatatableContainer from '~/components/patterns/tables/datatables/DatatableContainer';
import DatatableFooterBulkActionsLayout from '~/components/patterns/tables/datatables/footer/DatatableFooterBulkActionsLayout';
import DatatableHeaderCell, {
	DatatableHeaderCellSize,
} from '~/components/patterns/tables/datatables/cells/DatatableHeaderCell';
import DatatableLayout from '~/components/patterns/tables/datatables/DatatableLayout';
import EmailDomainNotWhitelistedErrorMessage from '~/components/logic/errorMessages/EmailDomainNotWhitelistedErrorMessage';
import FixedHeaderGrid from '~/components/patterns/tables/datatables/FixedHeaderGrid';
import Form, {
	type FormRef,
} from '~/components/atoms/forms/basis/Form';
import Hint from '~/components/patterns/hints/hint/Hint';
import MarginsList from '~/components/atoms/lists/MarginsList';
import Measurer from '~/utilities/Measurer';
import MultiStepModalStep from '~/components/patterns/modals/MultiStepModalStep';
import RoleSelectField, {
	RoleSelectFieldSize,
} from '~/components/logic/formFields/RoleSelectField';
import Sidebar from './Sidebar';
import Spinner, {
	SpinnerSize,
} from '~/components/patterns/loaders/Spinner';
import StatusFlag, {
	StatusFlagStatus,
} from '~/components/patterns/statuses/StatusFlag';
import SubmitButton from '~/components/app/SubmitButton';
import TableDeleteButton from '~/components/patterns/tables/datatables/buttons/TableDeleteButton';
import TableLabel from '~/components/patterns/tables/datatables/parts/TableLabel';
import TextualCellValue from '~/components/logic/datatables/cellValues/TextualCellValue';
import ValidationStatusMessage from './ValidationStatusMessage';

import {
	useInvitationsSessionStagingAreaQuery,
	useInviteNewUsersInBulkMutation,
	useUnstageEmailsMutation,
} from './InviteMultipleMembersStep.gql';

import useValidationSession from './useValidationSession';

import useAccountUserRestrictions from '~/hooks/useAccountUserRestrictions';
import useAllInvitations from '~/hooks/useAllInvitations';

import getArrayItemAtSafeIndex from '~/utilities/getArrayItemAtSafeIndex';



const messages = defineMessages({
	title: {
		id: 'ui.members.new.bulk.title',
	},
});

const errorMessages = defineMessages({
	alreadyInvited: {
		id: 'ui.teamDetail.teamInvitation.errorAlreadyInvited',
	},
	alreadyMember: {
		id: 'ui.teamDetail.teamInvitation.errorAlreadyMember',
	},
	blacklisted: {
		id: 'ui.teamDetail.teamInvitation.errorBlacklisted',
	},
});



export enum ValidationStatus {
	Pending,
	Failed,
	Finished,
}



enum Columns {
	Status = 0,
	Email = 1,
	Role = 2,
	Action = 3,
}

type Props = {
	accountId: CK.AccountId | null,
	isContentDisabled?: boolean,
	onContinueCallback: (email: Array<string>) => void,
};

const InviteMultipleMembersStep: React.FC<Props> = (props) => {
	const {
		accountId,
		isContentDisabled,
		onContinueCallback,
	} = props;

	const accountUserRestrictions = useAccountUserRestrictions(accountId);
	const allInvitations = useAllInvitations();
	const sessionId = useValidationSession(accountId);

	const [unstageEmailsMutation] = useUnstageEmailsMutation();
	const [inviteNewUsersInBulk] = useInviteNewUsersInBulkMutation();

	const [stagedEmails, setStagedEmails] = React.useState<Array<string>>([]);
	const [statuses, setStatuses] = React.useState<Record<string, ValidationStatus>>({});

	const formRef = React.useRef<FormRef>(null);

	const isLoading = (
		accountId === null
		|| accountUserRestrictions === null
		|| allInvitations === null
		|| sessionId === undefined
	);

	const maxInvitations = accountUserRestrictions?.maximumInvitations ?? Infinity;
	const numberOfOpenInvitations = allInvitations?.listByAccount(accountId ?? 0).length ?? 0;
	const isMaxInvitationsLimitReached = numberOfOpenInvitations >= maxInvitations;

	const { startPolling, stopPolling } = useInvitationsSessionStagingAreaQuery({
		notifyOnNetworkStatusChange: true,
		onCompleted: (data) => {
			if (data.invitationsValidationSession) {
				updateStagingArea(data.invitationsValidationSession);
			}
		},
		skip: (
			accountId === null
			|| sessionId === undefined
		),
		variables: {
			accountId: accountId as number,
			sessionId: sessionId as string,
		},
	});

	const addEmailsToStagingArea = React.useCallback(
		(emails: Array<string>) => {
			const values = formRef.current?.getValues();
			const defaultRole = values ? values['defaultRole'] : GraphQL.UserRole.Manager;

			const newStagedEmails: Array<string> = [];
			const newFormValues: Record<string, any> = {};

			const numberOfAvailableSpots = maxInvitations - numberOfOpenInvitations - stagedEmails.length;

			const newStatuses: Record<string, ValidationStatus> = {};

			emails
				.filter((email) => !stagedEmails.includes(email))
				.slice(0, numberOfAvailableSpots)
				.forEach((email) => {
					newStagedEmails.push(email);
					newFormValues[`role_${email}`] = defaultRole;

					newStatuses[email] = ValidationStatus.Pending;
				});

			setStatuses(
				(statuses) => ({
					...statuses,
					...newStatuses,
				}),
			);

			formRef.current?.setValues(newFormValues);

			setStagedEmails([
				...stagedEmails,
				...newStagedEmails,
			]);

			startPolling(3000);
		},
		[
			maxInvitations,
			numberOfOpenInvitations,
			stagedEmails,
			startPolling,
		],
	);

	const removeEmailFromStagingArea = React.useCallback(
		(email: string) => {
			setStagedEmails(
				(stagedEmails) => {
					const index = stagedEmails.indexOf(email);

					return [
						...stagedEmails.slice(0, index),
						...stagedEmails.slice(index + 1),
					];
				},
			);

			unstageEmailsMutation({
				variables: {
					emails: [email],
					accountId: accountId ?? -1,
					sessionId: sessionId ?? '',
				},
			});
		},
		[
			accountId,
			unstageEmailsMutation,
			sessionId,
		],
	);

	function updateStagingArea(stagingArea: GraphQL.StagingAreaOfInvitationsValidationSession): void {
		const form = formRef.current;

		if (!form) {
			stopPolling();
			return;
		}

		function filterStagedDomains(item: GraphQL.InvitationsValidationFailedEmail | GraphQL.InvitationsValidationFinishedEmail | GraphQL.InvitationsValidationPendingEmail): boolean {
			return stagedEmails.includes(item.email);
		}

		const newStatuses: Record<string, ValidationStatus> = {};

		stagingArea.failedEmails
			.filter(filterStagedDomains)
			.forEach((item) => {
				newStatuses[item.email] = ValidationStatus.Failed;

				form.setValue(`failureReason_${item.email}`, item.failureReason);
			});

		stagingArea.pendingEmails
			.filter(filterStagedDomains)
			.forEach((item) => {
				newStatuses[item.email] = ValidationStatus.Pending;
			});

		stagingArea.finishedEmails
			.filter(filterStagedDomains)
			.forEach((item) => {
				newStatuses[item.email] = ValidationStatus.Finished;
			});

		setStatuses({
			...statuses,
			...newStatuses,
		});

		stopPolling();

		const stagingAreaEmails = stagingArea.pendingEmails.length
			+ stagingArea.finishedEmails.length
			+ stagingArea.failedEmails.length;

		if (stagingArea.pendingEmails.length > 0 || stagingAreaEmails !== stagedEmails.length) {
			startPolling(1000);
		}
	}

	function updateRoles(): void {
		if (!formRef.current) {
			return;
		}

		const values = formRef.current.getValues();

		const defaultRole = values['defaultRole'];

		stagedEmails.forEach((email) => {
			if (statuses[email] !== ValidationStatus.Failed) {
				formRef.current?.setValue(`role_${email}`, defaultRole);
			}
		});
	}

	const handleSubmit = React.useCallback(
		async (values) => {
			const invitations = stagedEmails
				.filter((email) => statuses[email] === ValidationStatus.Finished)
				.map((email) => ({
					email,
					role: values[`role_${email}`],
				}));

			await inviteNewUsersInBulk({
				variables: {
					accountId: accountId || -1,
					sessionId: sessionId || '',
					invitations,
				},
			});

			onContinueCallback(
				invitations.map((invitation) => invitation.email),
			);
		},
		[
			accountId,
			inviteNewUsersInBulk,
			onContinueCallback,
			sessionId,
			stagedEmails,
			statuses,
		],
	);

	let finishedEmails = 0;
	let pendingEmails = 0;

	stagedEmails.forEach((email) => {
		const status = statuses[email];

		if (status === ValidationStatus.Finished) {
			finishedEmails += 1;
		} else if (status === ValidationStatus.Pending) {
			pendingEmails += 1;
		}
	});

	const isSubmittable = finishedEmails > 0 && pendingEmails === 0;

	const validations = React.useMemo(
		() => {
			return {
				isSubmittable: [
					{
						rule: () => isSubmittable === true,
					} as any,
				],
			};
		},
		[
			isSubmittable,
		],
	);

	return (
		<MultiStepModalStep
			name="invite-multiple-members"
			sidebar={(
				<Sidebar
					accountId={accountId}
					addEmailsToStagingArea={addEmailsToStagingArea}
					isDisabled={isLoading || isContentDisabled}
					isMaxInvitationsLimitReached={isMaxInvitationsLimitReached}
					maxInvitations={accountUserRestrictions?.maximumInvitations ?? Infinity}
					sessionId={sessionId}
				/>
			)}
			title={(
				<FormattedMessage {...messages.title} />
			)}
		>
			<Form
				defaultDataHasChanged={true}
				defaultValues={{
					defaultRole: GraphQL.UserRole.Manager,
				}}
				ignoreFieldUnmounts={true}
				isDisabled={isLoading}
				key={isLoading ? 'loading' : 'ready'}
				onSuccess={handleSubmit}
				ref={formRef}
				validations={validations}
			>
				{({ values, isSubmitting }) => {
					return (
						<MarginsList>
							<DatatableLayout
								footerCTA={(
									<DatatableFooterBulkActionsLayout
										form={(
											<RoleSelectField
												name="defaultRole"
												size={RoleSelectFieldSize.Small}
											/>
										)}
										formWidth={165}
										message={(
											<ValidationStatusMessage
												stagedEmails={stagedEmails}
												values={values}
											/>
										)}
										messageWidth={391}
										submitButton={(
											<Button
												disabled={isSubmitting}
												onClick={updateRoles}
												size={ButtonSize.XXSmall}
												style={ButtonStyle.Link}
											>
												Apply to all
											</Button>
										)}
										submitButtonWidth={60}
									/>
								)}
							>
								<DatatableContainer
									overlay={(stagedEmails.length === 0 && <BlankSlateOverlay />)}
								>
									<Measurer>
										{({ containerWidth }) => (
											<FixedHeaderGrid
												bodyCellRenderer={(props) => (
													<BodyCellRenderer
														{...props}
														accountId={accountId}
														onDeleteClickCallback={removeEmailFromStagingArea}
														stagedEmails={stagedEmails}
														statuses={statuses}
														values={values}
													/>
												)}
												columnCount={4}
												columnWidth={columnWidth}
												headerCellRenderer={headerCellRenderer}
												headerHeight={40}
												height={320}
												rowCount={Math.max(stagedEmails.length, 7)}
												rowHeight={40}
												width={containerWidth}
											/>
										)}
									</Measurer>
								</DatatableContainer>
							</DatatableLayout>

							<ButtonsLayout
								layout={ButtonsLayoutType.Steps}
								usageContext={ButtonsLayoutUsageContext.InModals}
							>
								<CancelButton />

								<SubmitButton>
									Continue
								</SubmitButton>
							</ButtonsLayout>
						</MarginsList>
					);
				}}
			</Form>
		</MultiStepModalStep>
	);
};



type BodyCellProps = {
	accountId: CK.AccountId,
	columnIndex: number,
	onDeleteClickCallback: (email: string) => void,
	key: string,
	rowIndex: number,
	style: React.CSSProperties,
	stagedEmails: Array<string>,
	statuses: Record<string, ValidationStatus>,
	values: Record<string, string>,
};

const BodyCellRenderer: React.FC<BodyCellProps> = (props) => {
	const {
		accountId,
		columnIndex,
		onDeleteClickCallback,
		rowIndex,
		style,
		stagedEmails,
		statuses,
		values,
	} = props;

	let content;

	if (stagedEmails[rowIndex] !== undefined) {
		const email = getArrayItemAtSafeIndex(stagedEmails, rowIndex);
		const status = statuses[email];

		switch (columnIndex) {

			case Columns.Status: {
				if (status === ValidationStatus.Pending) {
					content = <Spinner size={SpinnerSize.XSmall} />;
				} else if (status === ValidationStatus.Failed) {
					const reason = values[`failureReason_${email}`];
					let popup: React.ReactNode = reason;

					if (reason === 'AlreadyInvitedIntoHomeAccount') {
						popup = <FormattedMessage {...errorMessages.alreadyInvited} />;
					} else if (reason === 'AlreadyPresentInHomeAccount') {
						popup = <FormattedMessage {...errorMessages.alreadyMember} />;
					} else if (reason === 'BlacklistedDomain') {
						popup = <FormattedMessage {...errorMessages.blacklisted} />;
					} else if (reason === 'NotWhitelistedDomain') {
						popup = (
							<EmailDomainNotWhitelistedErrorMessage
								accountId={accountId}
							/>
						);
					}

					content = (
						<Hint popup={popup}>
							<StatusFlag status={StatusFlagStatus.Critical} />
						</Hint>
					);
				} else if (status === ValidationStatus.Finished) {
					content = <StatusFlag status={StatusFlagStatus.Normal} />;
				}

				break;
			}

			case Columns.Email: {
				content = (
					<TextualCellValue
						value={email}
					/>
				);

				break;
			}

			case Columns.Role: {
				content = (
					<RoleSelectField
						name={`role_${email}`}
						size={RoleSelectFieldSize.Small}
					/>
				);
				break;
			}

			case Columns.Action:
				content = (
					<ButtonsGroup>
						<TableDeleteButton
							onClick={() => {
								onDeleteClickCallback(email);
							}}
						/>
					</ButtonsGroup>
				);
				break;

		}
	}

	return (
		<DatatableBodyCell
			cssStyle={style}
			key={`${rowIndex}_${columnIndex}`}
			rowIndex={rowIndex}
			separator={columnIndex !== 0 && columnIndex < 4}
			size={DatatableBodyCellSize.Small}
		>
			{content}
		</DatatableBodyCell>
	);
};



type HeaderCellProps = {
	columnIndex: number,
};

const headerCellRenderer: React.FC<HeaderCellProps> = ({ columnIndex }) => {
	let label;
	let explanatoryTooltip;

	switch (columnIndex) {

		case Columns.Email:
			label = 'Team member';
			break;

		case Columns.Role:
			label = 'Role';
			break;

	}

	return (
		<DatatableHeaderCell
			key={'header_' + columnIndex}
			separator={columnIndex !== 0}
			size={DatatableHeaderCellSize.Small}
			width={columnWidth({ index: columnIndex })}
		>
			<TableLabel
				explanatoryTooltip={explanatoryTooltip}
				label={label}
			/>
		</DatatableHeaderCell>
	);
};



function columnWidth({ index }) {
	switch (index) {

		case Columns.Status:
			return 40;
		case Columns.Email:
			return 355;
		case Columns.Role:
			return 165;
		case Columns.Action:
			return 60;
		default:
			return 0;

	}
}



export default InviteMultipleMembersStep;
