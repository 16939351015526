/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarketingEmailSettingsFormQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type MarketingEmailSettingsFormQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly isAllowedToManageMonitoringSettings: boolean, readonly isSubscribedToFeatureAnnouncementEmails: boolean, readonly isSubscribedToNewsletterEmails: boolean, readonly isSubscribedToOnboardingEmails: boolean, readonly isSubscribedToServiceMessageEmails: boolean, readonly legacyId: string } | null };

export type UpdateUserMarketingEmailsPreferencesMutationVariables = GraphQL.Exact<{
  isSubscribedToFeatureAnnouncementEmails: GraphQL.Scalars['Boolean']['input'];
  isSubscribedToNewsletterEmails: GraphQL.Scalars['Boolean']['input'];
  isSubscribedToOnboardingEmails: GraphQL.Scalars['Boolean']['input'];
  isSubscribedToServiceMessageEmails: GraphQL.Scalars['Boolean']['input'];
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UpdateUserMarketingEmailsPreferencesMutation = { readonly __typename?: 'Mutation', readonly UpdateUserMarketingEmailsPreferences: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly isSubscribedToFeatureAnnouncementEmails: boolean, readonly isSubscribedToNewsletterEmails: boolean, readonly isSubscribedToOnboardingEmails: boolean, readonly isSubscribedToServiceMessageEmails: boolean, readonly legacyId: string } | null } } };


export const MarketingEmailSettingsFormDocument = gql`
    query MarketingEmailSettingsForm($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    id
    isAllowedToManageMonitoringSettings
    isSubscribedToFeatureAnnouncementEmails
    isSubscribedToNewsletterEmails
    isSubscribedToOnboardingEmails
    isSubscribedToServiceMessageEmails
    legacyId
  }
}
    `;

/**
 * __useMarketingEmailSettingsFormQuery__
 *
 * To run a query within a React component, call `useMarketingEmailSettingsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketingEmailSettingsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketingEmailSettingsFormQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useMarketingEmailSettingsFormQuery(baseOptions: Apollo.QueryHookOptions<MarketingEmailSettingsFormQuery, MarketingEmailSettingsFormQueryVariables> & ({ variables: MarketingEmailSettingsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MarketingEmailSettingsFormQuery, MarketingEmailSettingsFormQueryVariables>(MarketingEmailSettingsFormDocument, options);
      }
export function useMarketingEmailSettingsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MarketingEmailSettingsFormQuery, MarketingEmailSettingsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MarketingEmailSettingsFormQuery, MarketingEmailSettingsFormQueryVariables>(MarketingEmailSettingsFormDocument, options);
        }
export function useMarketingEmailSettingsFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MarketingEmailSettingsFormQuery, MarketingEmailSettingsFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MarketingEmailSettingsFormQuery, MarketingEmailSettingsFormQueryVariables>(MarketingEmailSettingsFormDocument, options);
        }
export type MarketingEmailSettingsFormQueryHookResult = ReturnType<typeof useMarketingEmailSettingsFormQuery>;
export type MarketingEmailSettingsFormLazyQueryHookResult = ReturnType<typeof useMarketingEmailSettingsFormLazyQuery>;
export type MarketingEmailSettingsFormSuspenseQueryHookResult = ReturnType<typeof useMarketingEmailSettingsFormSuspenseQuery>;
export type MarketingEmailSettingsFormQueryResult = Apollo.QueryResult<MarketingEmailSettingsFormQuery, MarketingEmailSettingsFormQueryVariables>;
export const UpdateUserMarketingEmailsPreferencesDocument = gql`
    mutation UpdateUserMarketingEmailsPreferences($isSubscribedToFeatureAnnouncementEmails: Boolean!, $isSubscribedToNewsletterEmails: Boolean!, $isSubscribedToOnboardingEmails: Boolean!, $isSubscribedToServiceMessageEmails: Boolean!, $legacyUserId: ID!) {
  UpdateUserMarketingEmailsPreferences(
    isSubscribedToFeatureAnnouncementEmails: $isSubscribedToFeatureAnnouncementEmails
    isSubscribedToNewsletterEmails: $isSubscribedToNewsletterEmails
    isSubscribedToOnboardingEmails: $isSubscribedToOnboardingEmails
    isSubscribedToServiceMessageEmails: $isSubscribedToServiceMessageEmails
    legacyUserId: $legacyUserId
  ) {
    query {
      user(legacyId: $legacyUserId) {
        id
        isSubscribedToFeatureAnnouncementEmails
        isSubscribedToNewsletterEmails
        isSubscribedToOnboardingEmails
        isSubscribedToServiceMessageEmails
        legacyId
      }
    }
  }
}
    `;
export type UpdateUserMarketingEmailsPreferencesMutationFn = Apollo.MutationFunction<UpdateUserMarketingEmailsPreferencesMutation, UpdateUserMarketingEmailsPreferencesMutationVariables>;

/**
 * __useUpdateUserMarketingEmailsPreferencesMutation__
 *
 * To run a mutation, you first call `useUpdateUserMarketingEmailsPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMarketingEmailsPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMarketingEmailsPreferencesMutation, { data, loading, error }] = useUpdateUserMarketingEmailsPreferencesMutation({
 *   variables: {
 *      isSubscribedToFeatureAnnouncementEmails: // value for 'isSubscribedToFeatureAnnouncementEmails'
 *      isSubscribedToNewsletterEmails: // value for 'isSubscribedToNewsletterEmails'
 *      isSubscribedToOnboardingEmails: // value for 'isSubscribedToOnboardingEmails'
 *      isSubscribedToServiceMessageEmails: // value for 'isSubscribedToServiceMessageEmails'
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUpdateUserMarketingEmailsPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMarketingEmailsPreferencesMutation, UpdateUserMarketingEmailsPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMarketingEmailsPreferencesMutation, UpdateUserMarketingEmailsPreferencesMutationVariables>(UpdateUserMarketingEmailsPreferencesDocument, options);
      }
export type UpdateUserMarketingEmailsPreferencesMutationHookResult = ReturnType<typeof useUpdateUserMarketingEmailsPreferencesMutation>;
export type UpdateUserMarketingEmailsPreferencesMutationResult = Apollo.MutationResult<UpdateUserMarketingEmailsPreferencesMutation>;
export type UpdateUserMarketingEmailsPreferencesMutationOptions = Apollo.BaseMutationOptions<UpdateUserMarketingEmailsPreferencesMutation, UpdateUserMarketingEmailsPreferencesMutationVariables>;