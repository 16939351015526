import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import AlertPagesActiveFilterBar from '~/components/app/AlertPagesActiveFilterBar';
import Backlink from '~/components/logic/links/Backlink';
import DateRangeLabel from '~/components/app/DateRangeLabel';
import ExportHeaderButton from '~/components/app/ExportHeaderButton';
import HeaderButtonsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderButtonsLayout';
import HeaderFilterLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderFilterLayout';
import HeaderOptionsLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderOptionsLayout';
import HeaderTitleWithNumericValue, {
	SIZE_SMALL as TITLE_SIZE_SMALL,
} from '~/components/atoms/screenLayouts/components/header/headerTitle/HeaderTitleWithNumericValue';
import HeaderTitleLayout from '~/components/atoms/screenLayouts/components/header/layouts/HeaderTitleLayout';
import ScreenHeader from '~/components/patterns/screens/basicScreen/header/ScreenHeader';

import useExportAlertPages from '~/hooks/useExportAlertPages';
import useViewportType from '~/hooks/useViewportType';



const messages = defineMessages({
	exportButton: {
		id: 'ui.exports.type.general.button.openDialog.label',
	},
});



type Props = {
	activeFilterClickCallback: () => void,
	alert: any,
	backlinkCallback: (() => void) | null,
	backlinkLabel: React.ReactNode,
	numberOfAlertPages: number | null,
	title: React.ReactNode,
};

const Header: React.FC<Props> = (props) => {
	const {
		activeFilterClickCallback,
		alert,
		backlinkCallback,
		backlinkLabel,
		numberOfAlertPages,
		title,
	} = props;

	const exportAlertPages = useExportAlertPages();
	const viewportType = useViewportType();

	const startDate = alert.has('opened_at') ? new Date(alert.get('opened_at')) : null;
	const endDate = alert.has('last_evolved_at') ? new Date(alert.get('last_evolved_at')) : null;

	return (
		<ScreenHeader>
			<HeaderTitleLayout>
				{backlinkCallback && backlinkLabel && (
					<Backlink
						callback={backlinkCallback}
						label={backlinkLabel}
					/>
				)}

				<HeaderTitleWithNumericValue
					size={TITLE_SIZE_SMALL}
					title={(
						<div>
							{startDate !== null && endDate !== null && (
								<DateRangeLabel
									endDate={endDate}
									startDate={startDate}
								/>
							)}
							<span> - </span>
							{title}
						</div>
					)}
					value={numberOfAlertPages}
				/>
			</HeaderTitleLayout>

			<HeaderFilterLayout compactView={viewportType.isSmall || viewportType.isMedium}>
				<AlertPagesActiveFilterBar
					onFilterClickCallback={activeFilterClickCallback}
				/>
			</HeaderFilterLayout>

			<HeaderOptionsLayout>
				<HeaderButtonsLayout>
					<ExportHeaderButton
						createExport={exportAlertPages}
						linkLabel={(
							<FormattedMessage {...messages.exportButton} />
						)}
					/>
				</HeaderButtonsLayout>
			</HeaderOptionsLayout>
		</ScreenHeader>
	);
};



export default React.memo(Header);
