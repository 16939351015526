/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageSegmentsQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PageSegmentsQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly pageSegments: ReadonlyArray<{ readonly __typename?: 'PageSegment', readonly id: CK.PageSegmentId, readonly name: CK.PageSegmentName }> } | null };


export const PageSegmentsDocument = gql`
    query PageSegments($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    pageSegments {
      id
      name
    }
  }
}
    `;

/**
 * __usePageSegmentsQuery__
 *
 * To run a query within a React component, call `usePageSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageSegmentsQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePageSegmentsQuery(baseOptions: Apollo.QueryHookOptions<PageSegmentsQuery, PageSegmentsQueryVariables> & ({ variables: PageSegmentsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageSegmentsQuery, PageSegmentsQueryVariables>(PageSegmentsDocument, options);
      }
export function usePageSegmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageSegmentsQuery, PageSegmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageSegmentsQuery, PageSegmentsQueryVariables>(PageSegmentsDocument, options);
        }
export function usePageSegmentsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PageSegmentsQuery, PageSegmentsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageSegmentsQuery, PageSegmentsQueryVariables>(PageSegmentsDocument, options);
        }
export type PageSegmentsQueryHookResult = ReturnType<typeof usePageSegmentsQuery>;
export type PageSegmentsLazyQueryHookResult = ReturnType<typeof usePageSegmentsLazyQuery>;
export type PageSegmentsSuspenseQueryHookResult = ReturnType<typeof usePageSegmentsSuspenseQuery>;
export type PageSegmentsQueryResult = Apollo.QueryResult<PageSegmentsQuery, PageSegmentsQueryVariables>;