import {
	EMPTY,
} from '~/localization/languages/constants';



export default <const>{
	'issues.analytics/analytics_missing.affectedPages': `Pages without analytics tracking`,
	'issues.analytics/analytics_missing.instruction': `Every page should contain <b>analytics</b> tracking (like Google Analytics or Adobe Analytics).`,
	'issues.analytics/analytics_missing.learnWhy': `<p>You can’t improve what you don’t measure. Analytics software helps you track the behaviour and goal conversions of your visitors to <b>optimize for usability and conversions</b>.</p>`,
	'issues.analytics/analytics_missing.title.broken': `Analytics is missing`,
	'issues.analytics/analytics_missing.title.ok': `Analytics is present`,
	'issues.analytics/analytics_missing.title.unknown': `Analytics presence is still unknown`,
	'issues.analytics/visual_analytics_missing.affectedPages': `Pages without visual analytics tracking`,
	'issues.analytics/visual_analytics_missing.instruction': `Every page should contain <b>visual analytics</b> tracking (like HotJar or Mouseflow).`,
	'issues.analytics/visual_analytics_missing.learnWhy': `<p>You can’t improve what you don’t measure. Visual analytics software helps you track the interaction your visitors are having with your website to <b>optimize for usability and conversions</b>.</p>`,
	'issues.analytics/visual_analytics_missing.title.broken': `Visual analytics is missing`,
	'issues.analytics/visual_analytics_missing.title.ok': `Visual analytics is present`,
	'issues.analytics/visual_analytics_missing.title.unknown': `Visual Analytics presence is still unknown`,
	'issues.canonical_link/incorrectly_canonicalized.affectedPages': `Pages with an invalid canonical link`,
	'issues.canonical_link/incorrectly_canonicalized.caseDescription': `Page contains robot directives preventing indexing and a canonical link pointing to another page.`,
	'issues.canonical_link/incorrectly_canonicalized.inlineCaseDescription': `canonical to other page`,
	'issues.canonical_link/incorrectly_canonicalized.instruction': `Pages with <b>robots directives preventing indexing</b> should not contain a canonical link pointing to another page.`,
	'issues.canonical_link/incorrectly_canonicalized.learnWhy': `<p>If the page prevents indexing via <code>meta robots</code> or <code>X-Robots-Tag</code> directives, it should not contain a canonical link pointing to another page, as this <b>may pass the noindex directive on to the target of the canonical link</b>.</p>`,
	'issues.canonical_link/incorrectly_canonicalized.title.broken': `Off-page canonical link on non-indexable page`,
	'issues.canonical_link/incorrectly_canonicalized.title.ok': `Valid canonical link is present`,
	'issues.canonical_link/missing.affectedPages': `Pages without a canonical link`,
	'issues.canonical_link/missing.inlineCaseDescription': `canonical link missing`,
	'issues.canonical_link/missing.instruction': `<configuration>{required_on, select,
		all_pages {All pages}
		indexable_pages {Indexable pages}
		other {}
	}</configuration> should have a canonical link.`,
	'issues.canonical_link/missing.learnWhy': `<p>A canonical link <b>prevents duplicate content issues</b> by informing search engines to prefer one document over other identical or similar documents. Every page should have a canonical link, either self-referencing or pointing to another indexable page, to tell search engines which page to index.</p>`,
	'issues.canonical_link/missing.title.broken': `Canonical link is missing`,
	'issues.canonical_link/missing.title.notRequired': `Canonical link is not required`,
	'issues.canonical_link/missing.title.ok': `Canonical link is present`,
	'issues.canonical_link/points_to_unindexable.affectedPages': `Pages with an invalid canonical link target`,
	'issues.canonical_link/points_to_unindexable.caseDescription': `Page contains a canonical link pointing to a page which is not indexable.`,
	'issues.canonical_link/points_to_unindexable.inlineCaseDescription': `canonical to non-indexable page`,
	'issues.canonical_link/points_to_unindexable.instruction': `Canonical link should be <b>self-referencing</b> or point to an <b>indexable target</b>.`,
	'issues.canonical_link/points_to_unindexable.learnWhy': `<p>A canonical link should either point to the same page (<i>self-referencing</i>) or to a third page, as long as that page is indexable. Pointing to a non-indexable page <b>confuses search engines</b>.</p>`,
	'issues.canonical_link/points_to_unindexable.title.broken': `Canonical link target is not indexable`,
	'issues.canonical_link/points_to_unindexable.title.notRequired': `Valid canonical link target is not required`,
	'issues.canonical_link/points_to_unindexable.title.ok': `Valid canonical link target is present`,
	'issues.canonical_link/points_to_unindexable.title.unknown': `Valid canonical link target presence is still unknown`,
	'issues.canonical_link/too_many.affectedPages': `Pages with multiple canonical links`,
	'issues.canonical_link/too_many.caseDescription': `Page contains {count, plural,
		one {# canonical link}
		other {# canonical links}
	}.`,
	'issues.canonical_link/too_many.inlineCaseDescription': `too many canonical links`,
	'issues.canonical_link/too_many.instruction': `Pages should have <b>only one canonical link</b>.`,
	'issues.canonical_link/too_many.learnWhy': `<p>Having multiple canonical links on a page <b>confuses search engines</b>, as they don’t know <i>which</i> canonical link to pick. To avoid this, make sure every page has exactly one canonical link.</p>`,
	'issues.canonical_link/too_many.title.broken': `Multiple canonical links are present`,
	'issues.canonical_link/too_many.title.ok': `Single canonical link is present`,
	'issues.domain/hostname_noncanonical_present.caseDescription': `Canonical domain {canonical_hostname, select,
		www {contains WWW but the non-WWW}
		non_www {does not contain WWW but the WWW}
		other {}
	} variant does not redirect there.`,
	'issues.domain/hostname_noncanonical_present.instruction': `Pages on the <b>non-canonical domain</b> variant should redirect to the canonical domain.`,
	'issues.domain/hostname_noncanonical_present.learnWhy': `<p>To <b>consolidate links and traffic</b>, it’s important to redirect the non-canonical variant to the canonical hostname of your website. This means deciding which version is preferred (WWW or non-WWW) and redirecting the other one.</p>`,
	'issues.domain/hostname_noncanonical_present.title.broken': `{canonical_hostname, select,
		www {Non-WWW}
		non_www {WWW}
		other {}
	} variant is not redirected`,
	'issues.domain/hostname_noncanonical_present.title.ok': `{canonical_hostname, select,
		www {Non-WWW}
		non_www {WWW}
		other {}
	} variant is redirected`,
	'issues.domain/hostname_noncanonical_present.title.unknown': `Redirecting of {canonical_hostname, select,
		www {Non-WWW}
		non_www {WWW}
		other {}
	} variant is still unknown`,
	'issues.domain/https_available.instruction': `Websites should be <b>accessible via HTTPS</b> to secure their communication with visitors and potentially improve rankings.`,
	'issues.domain/https_available.learnWhy': `<p>Using HTTPS protects the communication between your website and visitors and is also a <b>minor ranking factor for search engines</b>.</p>`,
	'issues.domain/https_available.title.broken': `Website not available via HTTPS`,
	'issues.domain/https_available.title.ok': `Website available via HTTPS`,
	'issues.domain/https_certificate_invalid.caseDescription': `{certificatesCount, plural,
		one {SSL certificate found, but it is invalid.}
		other {Multiple SSL certificates found, but at least one is invalid.}
	}`,
	'issues.domain/https_certificate_invalid.instruction': `HTTPS certificates should be <b>valid</b>, cover the website's <b>hostname</b> and have a future <b>expiry date</b>.`,
	'issues.domain/https_certificate_invalid.learnWhy': `<p>Having an invalid HTTPS certificate causes the website to be inaccessible for visitors and search engines. Valid certificates cover the correct hostname (e.g. www.example.com), have a future expiry date and are correctly configured.</p>`,
	'issues.domain/https_certificate_invalid.title.broken': `HTTPS certificate is not valid`,
	'issues.domain/https_certificate_invalid.title.ok': `HTTPS certificate is valid`,
	'issues.domain/https_noncanonical_present.caseDescription': `{canonical_scheme, select,
		http {Website available via HTTP but its HTTPS variant does not redirect there.}
		https {Website available via HTTPS but its HTTP variant does not redirect there. Note that although HSTS is configured to cause most web browsers to perform a client-side redirect, this redirect is not valid for search engines.}
		other {}
	}`,
	'issues.domain/https_noncanonical_present.instruction': `Pages with a <b>non-canonical protocol</b> should redirect to the canonical variant.`,
	'issues.domain/https_noncanonical_present.learnWhy': `<p>To <b>consolidate links and traffic</b>, it’s important to redirect the non-canonical variant to the canonical domain when your website is available on multiple protocols (HTTP and HTTPS). This is particular important when the HTTPS protocol is canonical, as it enforces the use of HTTPS for all communication.</p>`,
	'issues.domain/https_noncanonical_present.title.broken': `{canonical_scheme, select,
		http {HTTPS}
		https {HTTP}
		other {}
	} variant is not redirected`,
	'issues.domain/https_noncanonical_present.title.ok': `{canonical_scheme, select,
		http {HTTPS}
		https {HTTP}
		other {}
	} variant is redirected`,
	'issues.domain/https_noncanonical_present.title.unknown': `Redirecting of {canonical_scheme, select,
		http {HTTPS}
		https {HTTP}
		other {}
	} variant is still unknown`,
	'issues.domain/soft_404s_present.caseDescription': `Conductor Website Monitoring tried various random URLs and detected soft-404s.`,
	'issues.domain/soft_404s_present.instruction': `<b>Non-existing pages</b> should return a 4xx status code.`,
	'issues.domain/soft_404s_present.learnWhy': `<p>Pages that don’t exist should communicate that to search engines and browsers by using a <code>4xx</code> status code (usually <code>404</code>). If this is not the case, search engines may think there are far more pages on the website, which <b>can cause duplicate content and crawl budget issues</b>. Conductor Website Monitoring checks this by randomly requesting non-existing URLs.</p>`,
	'issues.domain/soft_404s_present.title.broken': `Soft-404s present`,
	'issues.domain/soft_404s_present.title.ok': `Soft-404s not present`,
	'issues.h1/duplicate.affectedPages': `Pages with non-unique H1 headings`,
	'issues.h1/duplicate.caseDescription': `H1 heading is the same as on {urls_count, plural,
		one {1 other page}
		other {# other pages}
	}.`,
	'issues.h1/duplicate.inlineCaseDescription': `duplicate`,
	'issues.h1/duplicate.instruction': `H1 headings of <b>pages that can cause duplicate content issues</b> should be unique.`,
	'issues.h1/duplicate.learnWhy': `<p>Indexable pages that are not part of a pagination sequence or hreflang group should have a unique H1 heading. This is to <b>avoid confusing search engines</b> in deciding which page to display for the keywords you incorporate.</p>`,
	'issues.h1/duplicate.title.broken': `H1 heading is not unique`,
	'issues.h1/duplicate.title.ok': `H1 heading is unique`,
	'issues.h1/incorrect_length.affectedPages': `Pages with an incorrect H1 heading length`,
	'issues.h1/incorrect_length.caseDescription': `H1 heading is {type, select,
		too_long {{extraCharacters, plural,
			one {# character}
			other {# characters}
		} too long}
		too_short {{missingCharacters, plural,
			one {# character}
			other {# characters}
		} too short}
		other {}
	}.`,
	'issues.h1/incorrect_length.inlineCaseDescription': `{type, select,
		too_long {too long}
		too_short {too short}
		other {}
	}`,
	'issues.h1/incorrect_length.instruction': `H1 headings should be <configuration>{minChars, plural,
		=0 {{maxChars, number} characters or less}
		other {between {minChars, number} and {maxChars, number} characters}
	}</configuration>.`,
	'issues.h1/incorrect_length.learnWhy': `<p>Having an H1 heading that is too short or too long makes it difficult for your visitors to quickly grasp the topic of the page. Keeping the H1 heading at the right length <b>ensures optimal readability</b>.</p>`,
	'issues.h1/incorrect_length.title.broken': `H1 heading {type, select,
		too_long {is too long}
		too_short {is too short}
		other {length is incorrect}
	}`,
	'issues.h1/incorrect_length.title.ok': `H1 heading has correct length`,
	'issues.h1/levels_skipped.affectedPages': `Pages with a skipped heading level`,
	'issues.h1/levels_skipped.caseDescription': `Heading levels don't follow an ordered sequence.`,
	'issues.h1/levels_skipped.instruction': `Heading levels on <configuration>{required_on, select,
		all_pages {all pages}
		indexable_pages {indexable pages}
		other {}
	}</configuration> should <b>follow an ordered sequence</b>.`,
	'issues.h1/levels_skipped.learnWhy': `<p>Heading levels that follow an ordered sequence improve the navigation experience on the page and help your visitors understand the page structure faster.</p>`,
	'issues.h1/levels_skipped.title.broken': `Heading level is skipped`,
	'issues.h1/levels_skipped.title.notRequired': `Heading levels are not required to follow an ordered sequence`,
	'issues.h1/levels_skipped.title.ok': `Heading levels follow an ordered sequence`,
	'issues.h1/missing.affectedPages': `Pages without an H1 heading`,
	'issues.h1/missing.inlineCaseDescription': `missing`,
	'issues.h1/missing.instruction': `<configuration>{required_on, select,
		all_pages {All pages}
		indexable_pages {Indexable pages}
		other {}
	}</configuration> should have an H1 heading.`,
	'issues.h1/missing.learnWhy': `<p>H1 headings are a <b>ranking factor for search engines</b> as they use the H1 heading to determine what the page is about. On top of that, H1 headings help your visitors to quickly understand the topic of the page.</p>`,
	'issues.h1/missing.title.broken': `H1 heading is missing`,
	'issues.h1/missing.title.notRequired': `H1 heading is not required`,
	'issues.h1/missing.title.ok': `H1 heading is present`,
	'issues.h1/too_many.affectedPages': `Pages with multiple page H1 headings`,
	'issues.h1/too_many.caseDescription': `This page has {count, number} H1 headings.`,
	'issues.h1/too_many.inlineCaseDescription': `too many`,
	'issues.h1/too_many.instruction': `Pages should have only <b>one H1 heading</b>.`,
	'issues.h1/too_many.learnWhy': `<p>Having multiple H1 headings on a page makes it harder for search engines and visitors to quickly grasp the topic of the page. Having exactly one H1 heading per page <b>avoids confusion for both</b>.</p>`,
	'issues.h1/too_many.title.broken': `Multiple H1 headings are present`,
	'issues.h1/too_many.title.ok': `Single H1 heading is present`,
	'issues.hreflang/conflicting_targets.affectedPages': `Pages that have hreflang attribute values pointed to multiple targets`,
	'issues.hreflang/conflicting_targets.caseDescription': `Page contains {count, plural,
		one {# hreflang attribute value}
		other {# hreflang attribute values}
	} pointed to multiple targets.`,
	'issues.hreflang/conflicting_targets.instruction': `<b>Each audience</b> specified in the hreflang implementation should have <b>a single target</b>.`,
	'issues.hreflang/conflicting_targets.learnWhy': `To make it clear to search engines which variant of the page should be shown to a specific audience, the hreflang attributes specifying the same audience should have the same target.`,
	'issues.hreflang/conflicting_targets.title.broken': `Hreflang attribute value is pointed to multiple targets`,
	'issues.hreflang/conflicting_targets.title.ok': `Each hreflang attribute value is pointed to a single target`,
	'issues.hreflang/invalid_target.affectedPages': `Pages that have hreflang with invalid target`,
	'issues.hreflang/invalid_target.caseDescription': `Page contains {count, plural,
		one {# hreflang attribute}
		other {# hreflang attributes}
	} with an invalid target.`,
	'issues.hreflang/invalid_target.instruction': `The <b>target</b> of the hreflang attribute should be an absolute URL.`,
	'issues.hreflang/invalid_target.learnWhy': `<p>When the hreflang attribute has a missing target, search engines won’t know which variant of the page should be served to that specific audience. On top of that, to make sure that search engines interpret the target of the hreflang attribute correctly, it should be defined as an absolute URL.</p>`,
	'issues.hreflang/invalid_target.title.broken': `Invalid hreflang target present`,
	'issues.hreflang/invalid_target.title.ok': `Hreflang attributes have a valid target`,
	'issues.hreflang/invalid_value.affectedPages': `Pages that have hreflang with invalid hreflang attribute value`,
	'issues.hreflang/invalid_value.caseDescription': `Page contains {count, plural,
		one {# hreflang attribute}
		other {# hreflang attributes}
	} for an invalid language and/or region.`,
	'issues.hreflang/invalid_value.instruction': `An hreflang attribute should contain only a <b>valid language and/or region, or an x-default</b> value.`,
	'issues.hreflang/invalid_value.learnWhy': `<p>The hreflang attribute describes which localized variant of the page should be served to the various audiences you're targeting. For that, only the valid language and/or region combinations, or the <code>x-default</code> value can be used.</p>`,
	'issues.hreflang/invalid_value.title.broken': `Invalid hreflang attribute value present`,
	'issues.hreflang/invalid_value.title.ok': `Hreflang attributes have valid values`,
	'issues.hreflang/missing_self_reference.affectedPages': `Pages that have hreflang implemented but don't have a self-referencing hreflang`,
	'issues.hreflang/missing_self_reference.instruction': `Every hreflang implementation should contain a <b>self-referencing</b> hreflang.`,
	'issues.hreflang/missing_self_reference.learnWhy': `<p>The hreflang attribute describes which localized variant of the page should be served to the various audiences you're targeting. For that, the hreflang implementation needs to specify both the preferred audience for the page itself, and its translated variants.</p>`,
	'issues.hreflang/missing_self_reference.title.broken': `Self-referencing hreflang not present`,
	'issues.hreflang/missing_self_reference.title.ok': `Self-referencing hreflang is present`,
	'issues.hreflang/missing_specific_audience.affectedPages': `Pages that have hreflang implemented but don't contain any hreflang attribute for a language and/or region`,
	'issues.hreflang/missing_specific_audience.instruction': `Every hreflang implementation should contain <b>at least one</b> hreflang attribute that specifies a <b>language and/or region</b>.`,
	'issues.hreflang/missing_specific_audience.learnWhy': `<p>The hreflang attribute describes which localized variant of the page should be served to the various audiences you're targeting. Therefore, each hreflang implementation should have at least one hreflang attribute specifying a language and/or region.</p>`,
	'issues.hreflang/missing_specific_audience.title.broken': `Hreflang implementation does not contain any hreflang attribute specifying a language and/or region`,
	'issues.hreflang/missing_specific_audience.title.ok': `Hreflang implementation contains at least one hreflang attribute specifying a language and/or region`,
	'issues.hreflang/missing_x_default.affectedPages': `Pages that have hreflang implemented but don't contain an hreflang attribute with x-default value`,
	'issues.hreflang/missing_x_default.instruction': `Hreflang implementation should have an <b>x-default fallback value</b>.`,
	'issues.hreflang/missing_x_default.learnWhy': `<p>The hreflang attribute describes which localized variant of the page should be served to the various audiences you're targeting. To make sure the audiences for which there's no specified variant are also served with the right version of the page, define the fallback page variant using the hreflang attribute with the <code>x-default</code> value.</p>`,
	'issues.hreflang/missing_x_default.title.broken': `Hreflang attribute with x-default value is missing`,
	'issues.hreflang/missing_x_default.title.ok': `Hreflang attribute with x-default value is present`,
	'issues.images/alt_attribute.affectedPages': `Pages containing images without an alt attribute`,
	'issues.images/alt_attribute.caseDescription': `Page contains {count, plural,
		one {# image}
		other {# images}
	} without an alt attribute.`,
	'issues.images/alt_attribute.instruction': `Images should have an <code>alt</code> attribute.`,
	'issues.images/alt_attribute.learnWhy': `<p>The <code>alt</code> attribute <b>describes the contents of your images to search engines and screen readers</b> used by visually impaired people. This improves the findability of your images and also helps people with vision problems understand what the image is about.</p>`,
	'issues.images/alt_attribute.title.broken': `Images present without alt attribute`,
	'issues.images/alt_attribute.title.ok': `Every image has an alt attribute`,
	'issues.images/mixed_transport.affectedPages': `Pages available via HTTPS and containing images served over HTTP`,
	'issues.images/mixed_transport.caseDescription': `This page is served over HTTPS, but it contains {count, plural,
		one {# image}
		other {# images}
	} served over HTTP.`,
	'issues.images/mixed_transport.instruction': `Images on an HTTPS page should be <b>served over HTTPS</b>.`,
	'issues.images/mixed_transport.learnWhy': `<p>Pages served over HTTPS should have all assets served over HTTPS to prevent the unencrypted content from being accessed by man-in-the-middle attackers.</p>`,
	'issues.images/mixed_transport.title.broken': `Images served over incorrect transport`,
	'issues.images/mixed_transport.title.ok': `Every image served over correct transport`,
	'issues.lighthouse/cls.affectedPages': `Pages with a too large Cumulative Layout Shift`,
	'issues.lighthouse/cls.caseDescription': `Cumulative Layout Shift is {distanceFromThreshold, number, ::scale/0.01 .###} too large.`,
	'issues.lighthouse/cls.instruction': `Cumulative Layout Shift should be <b>{threshold, number, ::scale/0.01 .###}</b> or less.`,
	'issues.lighthouse/cls.learnWhy': `<p>Cumulative Layout Shift (CLS) measures the cumulative score of all unexpected layout shifts within the viewport that occur during a page’s lifecycle. A CLS of 0.1 or less indicates the page is <b>providing a good user experience</b>, and is considered "<i>good</i>" in Lighthouse.</p>`,
	'issues.lighthouse/cls.title.broken': `Cumulative Layout Shift is too large`,
	'issues.lighthouse/cls.title.ok': `Cumulative Layout Shift is good`,
	'issues.lighthouse/cls.title.unknown': `Cumulative Layout Shift is still unknown`,
	'issues.lighthouse/fcp.affectedPages': `Pages with a too long First Contentful Paint`,
	'issues.lighthouse/fcp.caseDescription': `First Contentful Paint takes {distanceFromThreshold, number, ::scale/0.001 .0} s too long.`,
	'issues.lighthouse/fcp.instruction': `First Contentful Paint should occur within <b>{threshold, number, ::scale/0.001 .0} seconds</b> since the page starts loading.`,
	'issues.lighthouse/fcp.learnWhy': `<p>First Contentful Paint (FCP) measures the time from when the page starts loading to when any part of that page’s content is rendered on the screen. An FCP of 1 second or less indicates the page is <b>providing a good user experience</b>, and is considered "<i>good</i>" in Lighthouse.</p>`,
	'issues.lighthouse/fcp.title.broken': ` First Contentful Paint takes too long`,
	'issues.lighthouse/fcp.title.ok': `First Contentful Paint is good`,
	'issues.lighthouse/fcp.title.unknown': `First Contentful Paint is still unknown`,
	'issues.lighthouse/lcp.affectedPages': `Pages with a too long Largest Contentful Paint`,
	'issues.lighthouse/lcp.caseDescription': `Largest Contentful Paint takes {distanceFromThreshold, number, ::scale/0.001 .0} s too long.`,
	'issues.lighthouse/lcp.instruction': `Largest Contentful Paint should occur within <b>{threshold, number, ::scale/0.001 .0} seconds</b> since the page starts loading.`,
	'issues.lighthouse/lcp.learnWhy': `<p>Largest Contentful Paint (LCP) measures the time from when the page starts loading to when the largest text block or image element is rendered on the screen. An LCP of 2.5 seconds or less indicates the page is <b>providing a good user experience</b>, and is considered "<i>good</i>" in Lighthouse.</p>`,
	'issues.lighthouse/lcp.title.broken': `Largest Contentful Paint takes too long`,
	'issues.lighthouse/lcp.title.ok': `Largest Contentful Paint is good`,
	'issues.lighthouse/lcp.title.unknown': `Largest Contentful Paint is still unknown`,
	'issues.lighthouse/performance.affectedPages': `Pages with a too low Performance score`,
	'issues.lighthouse/performance.caseDescription': `Performance score is {distanceFromThreshold, number, ::.} points too low.`,
	'issues.lighthouse/performance.instruction': `Pages should have the Performance score above <b>{threshold, number, ::.}</b>.`,
	'issues.lighthouse/performance.learnWhy': `<p>Performance score is a weighted average of six performance web vitals. A Performance score above 90 indicates the page is <b>providing a good user experience</b>, and is considered "<i>good</i>" in Lighthouse.</p>`,
	'issues.lighthouse/performance.title.broken': `Performance score is too low`,
	'issues.lighthouse/performance.title.ok': `Performance score is good`,
	'issues.lighthouse/performance.title.unknown': `Performance score is still unknown`,
	'issues.lighthouse/si.affectedPages': `Pages with a too high Speed Index`,
	'issues.lighthouse/si.caseDescription': `Speed Index is {distanceFromThreshold, number, ::scale/0.001 .0} s too high.`,
	'issues.lighthouse/si.instruction': `Speed Index should be <b>{threshold, number, ::scale/0.001 .0} seconds</b> or less.`,
	'issues.lighthouse/si.learnWhy': `<p>Speed Index (SI) measures how quickly the contents of a page are visibly populated during page load. An SI of 4.3 seconds or less indicates the page is <b>providing a good user experience</b>, and is considered "<i>good</i>" in Lighthouse.</p>`,
	'issues.lighthouse/si.title.broken': `Speed Index is too high`,
	'issues.lighthouse/si.title.ok': `Speed Index is good`,
	'issues.lighthouse/si.title.unknown': `Speed Index is still unknown`,
	'issues.lighthouse/tbt.affectedPages': `Pages with a too long Total Blocking Time`,
	'issues.lighthouse/tbt.caseDescription': `Total Blocking Time is {distanceFromThreshold, number, ::.} ms too long.`,
	'issues.lighthouse/tbt.instruction': `Total Blocking Time should be <b>{threshold, number, ::.} milliseconds</b> or less.`,
	'issues.lighthouse/tbt.learnWhy': `<p>Total Blocking Time (TBT) measures the total time in milliseconds between First Contentful Paint (FCP) and Time to Interactive (TTI) where the main thread is blocked long enough to make it unresponsive to user input. A TBT of 300 milliseconds or less indicates the page is <b>providing a good user experience</b>, and is considered "<i>good</i>" in Lighthouse.</p>`,
	'issues.lighthouse/tbt.title.broken': `Total Blocking Time is too long`,
	'issues.lighthouse/tbt.title.ok': `Total Blocking Time is good`,
	'issues.lighthouse/tbt.title.unknown': `Total Blocking Time is still unknown`,
	'issues.lighthouse/tti.affectedPages': `Pages with a too long Time to Interactive`,
	'issues.lighthouse/tti.caseDescription': `Time to Interactive is {distanceFromThreshold, number, ::scale/0.001 .0} s too long.`,
	'issues.lighthouse/tti.instruction': `Time To Interactive should be <b>{threshold, number, ::scale/0.001 .0} seconds</b> or less.`,
	'issues.lighthouse/tti.learnWhy': `<p>Time To Interactive (TTI) measures the time from when the page starts loading to when it’s fully interactive. A TTI of 3.8 seconds or less indicates the page is <b>providing a good user experience</b>, and is considered "<i>good</i>" in Lighthouse.</p>`,
	'issues.lighthouse/tti.title.broken': `Time To Interactive is too long`,
	'issues.lighthouse/tti.title.ok': `Time to Interactive is good`,
	'issues.lighthouse/tti.title.unknown': `Time to Interactive is still unknown`,
	'issues.links/broken.affectedPages': `Pages with broken links`,
	'issues.links/broken.caseDescription': `Page contains {count, plural,
		one {# broken link}
		other {# broken links}
	}.`,
	'issues.links/broken.instruction': `Links should point to <b>existing pages</b>.`,
	'issues.links/broken.learnWhy': `<p>A broken link is a dead end for search engines and visitors which <b>causes link authority flowing to these dead end pages to be lost, wastes crawl budget</b> and negatively impacts user experience.</p>`,
	'issues.links/broken.title.broken': `Broken links present`,
	'issues.links/broken.title.ok': `No broken links`,
	'issues.links/broken.title.unknown': `Broken links are still unknown`,
	'issues.links/redirected.affectedPages': `Pages with links to redirects`,
	'issues.links/redirected.caseDescription': `Page contains {count, plural,
		one {# link to a redirect}
		other {# links to redirects}
	}.`,
	'issues.links/redirected.instruction': `Links should point to <b>the final targets</b>, not to redirects.`,
	'issues.links/redirected.learnWhy': `<p>Links to redirects slow down search engines and visitors, as their requests need to go through an additional location before the content is displayed. This extra hop also <b>reduces the amount of link authority being passed on and wastes crawl budget</b>. Avoid using redirects as much as possible.</p>`,
	'issues.links/redirected.title.broken': `Links to redirects present`,
	'issues.links/redirected.title.ok': `No links to redirects`,
	'issues.links/redirected.title.unknown': `Links to redirects are still unknown`,
	'issues.links/to_canonicalized.affectedPages': `Pages with links to canonicalized pages`,
	'issues.links/to_canonicalized.caseDescription': `Page contains {count, plural,
		one {# link to a canonicalized page}
		other {# links to canonicalized pages}
	}.`,
	'issues.links/to_canonicalized.instruction': `Links on <configuration>{links_to_canonicalized_required_on, select,
		all_pages {all pages}
		search_engine_accessible_pages {search engine accessible pages}
		other {}
	}</configuration> should point to <b>pages with a self-referencing canonical</b>.`,
	'issues.links/to_canonicalized.learnWhy': `<p>Links to pages that in turn have a canonical link to a third page <b>give mixed signals to search engines</b>. To optimize your internal link flow, unifying your linking to pages with a self-referencing canonical is crucial.</p>`,
	'issues.links/to_canonicalized.title.broken': `Links to canonicalized pages present`,
	'issues.links/to_canonicalized.title.notRequired': `Links to non-canonicalized pages are not required`,
	'issues.links/to_canonicalized.title.ok': `No links to canonicalized pages`,
	'issues.links/to_canonicalized.title.unknown': `Links to canonicalized pages are still unknown`,
	'issues.meta_description/duplicate.affectedPages': `Pages with non-unique meta descriptions`,
	'issues.meta_description/duplicate.caseDescription': `Meta description is the same as on {urls_count, plural,
		one {1 other page}
		other {# other pages}
	}.`,
	'issues.meta_description/duplicate.inlineCaseDescription': `duplicate`,
	'issues.meta_description/duplicate.instruction': `Meta descriptions of <b>pages that can cause duplicate content issues</b> should be unique.`,
	'issues.meta_description/duplicate.learnWhy': `<p>Indexable pages that are not part of a pagination sequence or hreflang group should have a unique meta description. Doing so <b>helps search engines distinguish pages</b>, and increases your chances of search engines using the meta description.</p>`,
	'issues.meta_description/duplicate.title.broken': `Meta description is not unique`,
	'issues.meta_description/duplicate.title.ok': `Meta description is unique`,
	'issues.meta_description/incorrect_length.affectedPages': `Pages with an incorrect meta description length`,
	'issues.meta_description/incorrect_length.caseDescription': `Meta description is {unit, select,
		characters {{type, select,
			too_long {{extraCharacters, plural,
				one {# character}
				other {# characters}
			} too long}
			too_short {{missingCharacters, plural,
				one {# character}
				other {# characters}
			} too short}
			other {}
		}}
		pixels {{type, select,
			too_long {{extraPixels, plural,
				one {# pixel}
				other {# pixels}
			} too long}
			too_short {{missingPixels, plural,
				one {# pixel}
				other {# pixels}
			} too short}
			other {}
		}}
		other {}
	}.`,
	'issues.meta_description/incorrect_length.inlineCaseDescription': `{type, select,
		too_long {too long}
		too_short {too short}
		other {}
	}`,
	'issues.meta_description/incorrect_length.instruction': `Meta descriptions should be {unit, select,
		characters {{minChars, plural,
			=0 {<configuration>{maxChars, number} characters or less</configuration>}
			other {<configuration>between {minChars, number} and {maxChars, number} characters</configuration>}
		}}
		pixels {{minChars, plural,
			=0 {<configuration>{maxPixels, number} pixels or less</configuration> (approx. {maxChars, number} characters)}
			other {<configuration>between {minPixels, number} and {maxPixels, number} pixels</configuration> (approx. {minChars, number} and {maxChars, number} characters)}
		}}
		other {}
	}.`,
	'issues.meta_description/incorrect_length.learnWhy': `<p>If a meta description is too short or too long, <b>search engines may decide not to use it</b> and generate their own description based on the page’s contents. The result of their guesswork is generally quite poor, causing your page’s listing in the result pages to be sub-optimal.</p>`,
	'issues.meta_description/incorrect_length.title.broken': `Meta description {type, select,
		too_long {is too long}
		too_short {is too short}
		other {length is incorrect}
	}`,
	'issues.meta_description/incorrect_length.title.ok': `Meta description has correct length`,
	'issues.meta_description/missing.affectedPages': `Pages without a meta description`,
	'issues.meta_description/missing.inlineCaseDescription': `missing`,
	'issues.meta_description/missing.instruction': `<configuration>{required_on, select,
		all_pages {All pages}
		indexable_pages {Indexable pages}
		other {}
	}</configuration> should have a meta description.`,
	'issues.meta_description/missing.learnWhy': `<p>Meta descriptions are prominently featured in search engine result pages and thus are like advertising copy for your pages. A good meta description will <b>increase the click-through rate</b> of your website’s listed pages.</p>`,
	'issues.meta_description/missing.title.broken': `Meta description is missing`,
	'issues.meta_description/missing.title.notRequired': `Meta description is not required`,
	'issues.meta_description/missing.title.ok': `Meta description is present`,
	'issues.meta_description/too_many.affectedPages': `Pages with multiple meta descriptions`,
	'issues.meta_description/too_many.caseDescription': `This page has {count, number} meta descriptions.`,
	'issues.meta_description/too_many.inlineCaseDescription': `too many`,
	'issues.meta_description/too_many.instruction': `Pages should have only <b>one meta description</b>.`,
	'issues.meta_description/too_many.learnWhy': `<p>Having multiple meta descriptions on a page sends mixed signals to search engines. They won’t know which meta description to use, and <b>may choose to ignore your meta descriptions altogether</b>, causing your page’s listing in the result pages to be sub-optimal.</p>`,
	'issues.meta_description/too_many.title.broken': `Multiple meta descriptions are present`,
	'issues.meta_description/too_many.title.ok': `Single meta description is present`,
	'issues.open_graph/description_incorrect_length.affectedPages': `Pages with an incorrect Open Graph description length`,
	'issues.open_graph/description_incorrect_length.caseDescription': `Open Graph description is {type, select,
		too_long {{extraCharacters, plural,
			one {# character}
			other {# characters}
		} too long}
		too_short {{missingCharacters, plural,
			one {# character}
			other {# characters}
		} too short}
		other {}
	}.`,
	'issues.open_graph/description_incorrect_length.inlineCaseDescription': `{type, select,
		too_long {too long}
		too_short {too short}
		other {}
	}`,
	'issues.open_graph/description_incorrect_length.instruction': `Open Graph descriptions should be <configuration>{minChars, plural,
		=0 {{maxChars, number} characters or less}
		other {between {minChars, number} and {maxChars, number} characters}
	}</configuration>.`,
	'issues.open_graph/description_incorrect_length.learnWhy': `<p>If the Open Graph description is too long Facebook will cut your description short, meaning that <b>your page’s snippet will be sub-optimal when someone shares it online</b>.</p>`,
	'issues.open_graph/description_incorrect_length.title.broken': `Open Graph description {type, select,
		too_long {is too long}
		too_short {is too short}
		other {length is incorrect}
	}`,
	'issues.open_graph/description_incorrect_length.title.ok': `Open Graph description has correct length`,
	'issues.open_graph/description_missing.affectedPages': `Pages without an Open Graph description`,
	'issues.open_graph/description_missing.instruction': `Pages should have an Open Graph <b>description</b>.`,
	'issues.open_graph/description_missing.learnWhy': `<p>Open Graph markup allows you to gain control of the snippets that are shown when your pages are shared on Facebook, WhatsApp, Slack and other social media platforms. Having a great Open Graph description <b>increases the click-through rate</b> when your content is shared online.</p>`,
	'issues.open_graph/description_missing.title.broken': `Open Graph description is missing`,
	'issues.open_graph/description_missing.title.ok': `Open Graph description is present`,
	'issues.open_graph/image_missing.affectedPages': `Pages without an Open Graph image`,
	'issues.open_graph/image_missing.instruction': `Pages should have an Open Graph <b>image</b> definition.`,
	'issues.open_graph/image_missing.learnWhy': `<p>The Open Graph image property defines which image is displayed when your content is shared online. If you don’t define it, social media platforms <b>will try to pick an image from the page by themselves</b>. The result of their guesswork is generally quite poor, causing your page’s snippet to be sub-optimal.</p>`,
	'issues.open_graph/image_missing.title.broken': `Open Graph image is missing`,
	'issues.open_graph/image_missing.title.ok': `Open Graph image is present`,
	'issues.open_graph/title_incorrect_length.affectedPages': `Pages with an incorrect Open Graph title length`,
	'issues.open_graph/title_incorrect_length.caseDescription': `Open Graph title is {type, select,
		too_long {{extraCharacters, plural,
			one {# character}
			other {# characters}
		} too long}
		too_short {{missingCharacters, plural,
			one {# character}
			other {# characters}
		} too short}
		other {}
	}.`,
	'issues.open_graph/title_incorrect_length.inlineCaseDescription': `{type, select,
		too_long {too long}
		too_short {too short}
		other {}
	}`,
	'issues.open_graph/title_incorrect_length.instruction': `Open Graph titles should be <configuration>{minChars, plural,
		=0 {{maxChars, number} characters or less}
		other {between {minChars, number} and {maxChars, number} characters}
	}</configuration>.`,
	'issues.open_graph/title_incorrect_length.learnWhy': `<p>If the Open Graph title is too long Facebook will not display your Open Graph description or cut your title short. Either way, <b>your page’s snippet will be sub-optimal when someone shares it</b>.</p>`,
	'issues.open_graph/title_incorrect_length.title.broken': `Open Graph title {type, select,
		too_long {is too long}
		too_short {is too short}
		other {length is incorrect}
	}`,
	'issues.open_graph/title_incorrect_length.title.ok': `Open Graph title has correct length`,
	'issues.open_graph/title_missing.affectedPages': `Pages without an Open Graph title`,
	'issues.open_graph/title_missing.instruction': `Pages should have an Open Graph <b>title</b>.`,
	'issues.open_graph/title_missing.learnWhy': `<p>Open Graph markup allows you to gain control of the snippets that are shown when your pages are shared on Facebook, WhatsApp, Slack and other social media platforms. Having a great Open Graph title <b>increases the click-through rate</b> when your content is shared online.</p>`,
	'issues.open_graph/title_missing.title.broken': `Open Graph title is missing`,
	'issues.open_graph/title_missing.title.ok': `Open Graph title is present`,
	'issues.open_graph/url_missing.affectedPages': `Pages without an Open Graph URL`,
	'issues.open_graph/url_missing.instruction': `Pages should have an Open Graph <b>URL</b> definition.`,
	'issues.open_graph/url_missing.learnWhy': `<p>The Open Graph URL property is a required property in the Open Graph standard and used to <b>attribute Likes and Shares to the correct (canonical) URL</b>.</p>`,
	'issues.open_graph/url_missing.title.broken': `Open Graph URL is missing`,
	'issues.open_graph/url_missing.title.ok': `Open Graph URL is present`,
	'issues.robot_directives/conflicting.affectedPages': `Pages with conflicting robot directives`,
	'issues.robot_directives/conflicting.caseDescription': `Page contains {count, plural,
		one {# group}
		other {# groups}
	} of conflicting robot directives.`,
	'issues.robot_directives/conflicting.instruction': `Robot directives should provide <b>clear, non-conflicting instructions</b> to search engines.`,
	'issues.robot_directives/conflicting.learnWhy': `When conflicting robot directives are present, search engines usually respect the more restrictive directive. Implementation of conflicting robot directives indicates that a specific directives were intended, but some of them are now being ignored by search engines.`,
	'issues.robot_directives/conflicting.title.broken': `Robot directives give conflicting directives to search engines`,
	'issues.robot_directives/conflicting.title.ok': `Robot directives give clear directives to search engines`,
	'issues.robot_directives/invalid.affectedPages': `Pages with invalid robot directives`,
	'issues.robot_directives/invalid.caseDescription': `Page contains {count, plural,
		one {# invalid robot directive}
		other {# invalid robot directives}
	}.`,
	'issues.robot_directives/invalid.instruction': `All robot directives should be <b>valid</b>.`,
	'issues.robot_directives/invalid.learnWhy': `<p>Invalid robot directives are most likely ignored by search engines. Implementation of invalid directives indicates that a specific directive was intended, but is now being ignored by search engines.</p>`,
	'issues.robot_directives/invalid.title.broken': `Invalid robot directives present`,
	'issues.robot_directives/invalid.title.ok': `All robot directives are valid`,
	'issues.robot_directives/unsupported.affectedPages': `Pages with valid robot directives unsupported by targeted search engine`,
	'issues.robot_directives/unsupported.caseDescription': `Page contains {count, plural,
		one {# robot directive}
		other {# robot directives}
	} which, though valid, {count, plural,
		one {is}
		other {are}
	} not supported by the targeted search engine.`,
	'issues.robot_directives/unsupported.instruction': `All robot directives should be <b>supported by the search engine</b> they give instructions to.`,
	'issues.robot_directives/unsupported.learnWhy': `<p>If a search engine doesn't support an implemented robot directive, it will most likely ignore it. Implementation of such directives indicates that a specific directive was intended, but is now being ignored by the search engine.</p><p>Make sure to use only the directives that the search engine you're instructing can understand.</p>`,
	'issues.robot_directives/unsupported.title.broken': `Valid but unsupported robot directives to a search engine present`,
	'issues.robot_directives/unsupported.title.ok': `All valid robot directives are supported by the instructed search engine`,
	'issues.robots_txt/assets_disallowed.caseDescription': `The robots.txt file contains {count, plural,
		one {# directive}
		other {# directives}
	} blocking access to assets.`,
	'issues.robots_txt/assets_disallowed.instruction': `The robots.txt file should not block <b>access to assets</b>.`,
	'issues.robots_txt/assets_disallowed.learnWhy': `<p>Assets are JavaScript and CSS files referenced in the HTML source which browsers use to display the page. Search engines may also rely on these files to render the page, so blocking access to these assets <b>makes it hard for search engines to get the full contents of the page</b>.</p>`,
	'issues.robots_txt/assets_disallowed.title.broken': `Some assets blocked from search engines`,
	'issues.robots_txt/assets_disallowed.title.ok': `All assets accessible to search engines`,
	'issues.robots_txt/crawldelay_present.caseDescription': `The robots.txt file contains {count, number} crawl-delay {count, plural,
		one {directive}
		other {directives}
	}.`,
	'issues.robots_txt/crawldelay_present.instruction': `The robots.txt file should not contain any <code>crawl-delay</code> directives.`,
	'issues.robots_txt/crawldelay_present.learnWhy': `<p>The <code>crawl-delay</code> directive instructs some search engines to slow down their crawling, <b>which causes new content and content updates to be picked up later</b>. This is undesired, as you want search engines to pick up on changes to your website as quickly as possible.</p>`,
	'issues.robots_txt/crawldelay_present.title.broken': `Crawl-delay directive present`,
	'issues.robots_txt/crawldelay_present.title.ok': `Contains no crawl-delay directives`,
	'issues.robots_txt/inaccessible.caseDescription': `The robots.txt file cannot be accessed.`,
	'issues.robots_txt/inaccessible.instruction': `Websites should have an <b>accessible robots.txt</b> file.`,
	'issues.robots_txt/inaccessible.learnWhy': `<p>The robots.txt file <b>tells search engines how to best crawl your website</b>. This can be used for various things, such as keeping search engines out of unwanted parts of the website to avoid wasting crawl budget.</p>`,
	'issues.robots_txt/inaccessible.title.broken': `Robots.txt is not accessible`,
	'issues.robots_txt/inaccessible.title.ok': `Robots.txt is accessible`,
	'issues.robots_txt/inaccessible.title.unknown': `Robots.txt accessibility is still unknown`,
	'issues.robots_txt/invalid_directive.caseDescription': `The robots.txt file contains {count, number} invalid {count, plural,
		one {directive}
		other {directives}
	}.`,
	'issues.robots_txt/invalid_directive.instruction': `The robots.txt file should not contain invalid <b>directives</b>.`,
	'issues.robots_txt/invalid_directive.learnWhy': `<p>Directives are invalid when they <b>have no impact on search engine behavior</b>. A common example of this are directives that don’t start with a forward slash or asterisk, as those directives can’t match any URL.</p>`,
	'issues.robots_txt/invalid_directive.title.broken': `Invalid directives present`,
	'issues.robots_txt/invalid_directive.title.ok': `All directives are valid`,
	'issues.robots_txt/invalid_syntax.caseDescription': `The robots.txt file contains {count, plural,
		one {# line}
		other {# lines}
	} with invalid syntax.`,
	'issues.robots_txt/invalid_syntax.instruction': `The robots.txt file should not contain invalid <b>syntax</b>.`,
	'issues.robots_txt/invalid_syntax.learnWhy': `<p>Invalid syntax refers to lines that <b>contain syntax not supported by the robots.txt protocol</b>. A common example of this are lines not containing a colon.</p>`,
	'issues.robots_txt/invalid_syntax.title.broken': `Invalid syntax present`,
	'issues.robots_txt/invalid_syntax.title.ok': `Syntax is valid`,
	'issues.robots_txt/noncanonical_present.caseDescription': `{count, plural,
		one {There is a robots.txt file available on # non-canonical domain variant}
		other {There are robots.txt files available on # non-canonical domain variants}
	}.`,
	'issues.robots_txt/noncanonical_present.instruction': `Only the <b>canonical domain</b> should have a robots.txt file.`,
	'issues.robots_txt/noncanonical_present.learnWhy': `<p>Having robots.txt files also available on non-canonical domain variants <b>causes problems when directives start to conflict</b>. Unless you know what you’re doing, it’s best to only keep the robots.txt file on the canonical domain.</p>`,
	'issues.robots_txt/noncanonical_present.title.broken': `Present on non-canonical variants`,
	'issues.robots_txt/noncanonical_present.title.ok': `Present on canonical variant only`,
	'issues.robots_txt/xmlsitemap_reference_inaccessible.caseDescription': `The robots.txt file references an inaccessible XML sitemap.`,
	'issues.robots_txt/xmlsitemap_reference_inaccessible.instruction': `The robots.txt file should <b>reference an accessible XML sitemap</b>.`,
	'issues.robots_txt/xmlsitemap_reference_inaccessible.learnWhy': EMPTY,
	'issues.robots_txt/xmlsitemap_reference_inaccessible.title.broken': `Referenced XML sitemap is not accessible`,
	'issues.robots_txt/xmlsitemap_reference_inaccessible.title.ok': `Referenced XML sitemap is accessible`,
	'issues.robots_txt/xmlsitemap_reference_missing.instruction': `The robots.txt file should <b>reference an XML sitemap</b>.`,
	'issues.robots_txt/xmlsitemap_reference_missing.learnWhy': `<p>Referencing the XML sitemap in the robots.txt file <b>makes it easier for search engines to find your XML sitemap</b>. This is especially important if your XML sitemap is not in a default location.</p>`,
	'issues.robots_txt/xmlsitemap_reference_missing.title.broken': `XML sitemap reference is missing`,
	'issues.robots_txt/xmlsitemap_reference_missing.title.ok': `XML sitemap reference is present`,
	'issues.robots_txt/xmlsitemap_reference_relative.caseDescription': `The robots.txt file references the XML sitemap using a relative URL.`,
	'issues.robots_txt/xmlsitemap_reference_relative.instruction': `The robots.txt file should reference an XML sitemap <b>using an absolute URL</b>.`,
	'issues.robots_txt/xmlsitemap_reference_relative.learnWhy': `<p>The XML sitemap reference in the robots.txt file should be done using an absolute URL, so make sure to include the domain name and protocol (HTTP or HTTPS). This <b>avoids ambiguity over the exact location</b> of the XML sitemap.</p>`,
	'issues.robots_txt/xmlsitemap_reference_relative.title.broken': `XML sitemap referenced with relative URL`,
	'issues.robots_txt/xmlsitemap_reference_relative.title.ok': `XML sitemap referenced with absolute URL`,
	'issues.schema_org/errors.affectedPages': `Pages that have errors in the schema.org implementation`,
	'issues.schema_org/errors.caseDescription': `The schema.org implementation on this page contains {count, plural,
		one {# error}
		other {# errors}
	} that violate the Schema.org or Google structured data guidelines.`,
	'issues.schema_org/errors.instruction': `A schema.org implementation should <b>respect the Schema.org and Google structured data guidelines</b>.`,
	'issues.schema_org/errors.learnWhy': `<p>Only a schema.org implemented in a way that respects the Google structured data guidelines can be used for displaying the enhanced snippets in Google Search results.</p>`,
	'issues.schema_org/errors.title.broken': `Schema.org implementation has an invalid structure`,
	'issues.schema_org/errors.title.ok': `Schema.org implementation has a valid structure`,
	'issues.schema_org/invalid_json.affectedPages': `Pages that have schema script elements with invalid JSON-LD`,
	'issues.schema_org/invalid_json.caseDescription': `Page contains {count, plural,
		one {# script element}
		other {# script elements}
	} with invalid JSON object.`,
	'issues.schema_org/invalid_json.instruction': `All schema script elements implemented using JSON-LD need to have a <b>parsable JSON object</b>.`,
	'issues.schema_org/invalid_json.learnWhy': `<p>When a schema script element has an invalid JSON object, search engines can't parse it and will not understand the structured data implemented by that script.</p>`,
	'issues.schema_org/invalid_json.title.broken': `Schema element with invalid JSON-LD object present`,
	'issues.schema_org/invalid_json.title.ok': `All schema elements have valid JSON-LD object`,
	'issues.title/duplicate.affectedPages': `Pages with non-unique page title`,
	'issues.title/duplicate.caseDescription': `Page title is the same as on {urls_count, plural,
		one {1 other page}
		other {# other pages}
	}.`,
	'issues.title/duplicate.inlineCaseDescription': `duplicate`,
	'issues.title/duplicate.instruction': `Page titles of <b>pages that can cause duplicate content issues</b> should be unique.`,
	'issues.title/duplicate.learnWhy': `<p>Indexable pages that are not part of a pagination sequence or hreflang group should have a unique page title. This is to <b>avoid confusing search engines</b> in deciding which page to display for the keywords you incorporate.</p>`,
	'issues.title/duplicate.title.broken': `Page title is not unique`,
	'issues.title/duplicate.title.ok': `Page title is unique`,
	'issues.title/incorrect_length.affectedPages': `Pages with incorrect page title length`,
	'issues.title/incorrect_length.caseDescription': `Page title is {unit, select,
		characters {{type, select,
			too_long {{extraCharacters, plural,
				one {# character}
				other {# characters}
			} too long}
			too_short {{missingCharacters, plural,
				one {# character}
				other {# characters}
			} too short}
			other {}
		}}
		pixels {{type, select,
			too_long {{extraPixels, plural,
				one {# pixel}
				other {# pixels}
			} too long}
			too_short {{missingPixels, plural,
				one {# pixel}
				other {# pixels}
			} too short}
			other {}
		}}
		other {}
	}.`,
	'issues.title/incorrect_length.inlineCaseDescription': `{type, select,
		too_long {too long}
		too_short {too short}
		other {}
	}`,
	'issues.title/incorrect_length.instruction': `Page titles should be {unit, select,
		characters {{minChars, plural,
			=0 {<configuration>{maxChars, number} characters or less</configuration>}
			other {<configuration>between {minChars, number} and {maxChars, number} characters</configuration>}
		}}
		pixels {{minChars, plural,
			=0 {<configuration>{maxPixels, number} pixels or less</configuration> (approx. {maxChars, number} characters)}
			other {<configuration>between {minPixels, number} and {maxPixels, number} pixels</configuration> (approx. {minChars, number} and {maxChars, number} characters)}
		}}
		other {}
	}.`,
	'issues.title/incorrect_length.learnWhy': `<p>If a page title is too short, search engines may decide not to use it and generate a different, less optimal title instead. If a page title is too long, search engines may cut the page title or, again, generate a different one. Either way, <b>your page’s listing in the result pages will be sub-optimal</b>.</p>`,
	'issues.title/incorrect_length.title.broken': `Page title {type, select,
		too_long {is too long}
		too_short {is too short}
		other {length is incorrect}
	}`,
	'issues.title/incorrect_length.title.ok': `Page title has correct length`,
	'issues.title/missing.affectedPages': `Pages without a page title`,
	'issues.title/missing.inlineCaseDescription': `missing`,
	'issues.title/missing.instruction': `<configuration>{required_on, select,
		all_pages {All pages}
		indexable_pages {Indexable pages}
		other {}
	}</configuration> should have a page title.`,
	'issues.title/missing.learnWhy': `<p>Page titles are a very important <b>ranking factor for search engines</b> as they use the page title to determine what the page is about. On top of that, page titles are prominently featured in search engine result pages and are displayed in your visitors’ browser tabs.</p>`,
	'issues.title/missing.title.broken': `Page title is missing`,
	'issues.title/missing.title.notRequired': `Page title is not required`,
	'issues.title/missing.title.ok': `Page title is present`,
	'issues.title/too_many.affectedPages': `Pages with multiple page titles`,
	'issues.title/too_many.caseDescription': `This page has {count, number} page titles.`,
	'issues.title/too_many.inlineCaseDescription': `too many`,
	'issues.title/too_many.instruction': `Pages should have only <b>one page title</b>.`,
	'issues.title/too_many.learnWhy': `<p>Having multiple page titles on a page sends mixed signals to search engines. They won’t know which page title to use and may <b>fail to properly determine what the page is about</b>.</p>`,
	'issues.title/too_many.title.broken': `Multiple page titles are present`,
	'issues.title/too_many.title.ok': `Single page title is present`,
	'issues.twitter_cards/description_incorrect_length.affectedPages': `Pages with an incorrect Twitter Card description length`,
	'issues.twitter_cards/description_incorrect_length.caseDescription': `Twitter Cards description is {type, select,
		too_long {{extraCharacters, plural,
			one {# character}
			other {# characters}
		} too long}
		too_short {{missingCharacters, plural,
			one {# character}
			other {# characters}
		} too short}
		other {}
	}.`,
	'issues.twitter_cards/description_incorrect_length.inlineCaseDescription': `{type, select,
		too_long {too long}
		too_short {too short}
		other {}
	}`,
	'issues.twitter_cards/description_incorrect_length.instruction': `Twitter Cards descriptions should be <configuration>{minChars, plural,
		=0 {{maxChars, number} characters or less}
		other {between {minChars, number} and {maxChars, number} characters}
	}</configuration>.`,
	'issues.twitter_cards/description_incorrect_length.learnWhy': `<p>If the Twitter Cards description is too long, Twitter will cut your description short, meaning that <b>your page’s snippet will be sub-optimal when someone shares it online</b>.</p>`,
	'issues.twitter_cards/description_incorrect_length.title.broken': `Twitter Cards description {type, select,
		too_long {is too long}
		too_short {is too short}
		other {length is incorrect}
	}`,
	'issues.twitter_cards/description_incorrect_length.title.ok': `Twitter Cards description has correct length`,
	'issues.twitter_cards/description_missing.affectedPages': `Pages without a Twitter Cards description`,
	'issues.twitter_cards/description_missing.instruction': `Pages should have a Twitter Cards <b>description</b>.`,
	'issues.twitter_cards/description_missing.learnWhy': `<p>Twitter Cards markup allows you to gain control of the snippets that are shown when your pages are shared on Twitter. Having a great Twitter Cards description <b>increases the click-through rate</b> when your content is shared online.</p>`,
	'issues.twitter_cards/description_missing.title.broken': `Twitter Cards description is missing`,
	'issues.twitter_cards/description_missing.title.ok': `Twitter Cards description is present`,
	'issues.twitter_cards/image_missing.affectedPages': `Pages without a Twitter Card image`,
	'issues.twitter_cards/image_missing.instruction': `Pages should have a Twitter Cards <b>image</b>.`,
	'issues.twitter_cards/image_missing.learnWhy': `<p>The Twitter Cards image property defines which image is displayed when your content is shared on Twitter. If you don’t define it, <b>Twitter will try to pick an image from the page by themselves</b>. The result of their guesswork is generally quite poor, causing your page’s snippet to be sub-optimal.</p>`,
	'issues.twitter_cards/image_missing.title.broken': `Twitter Cards image is missing`,
	'issues.twitter_cards/image_missing.title.ok': `Twitter Cards image is present`,
	'issues.twitter_cards/site_missing.affectedPages': `Pages without a Twitter Card site property`,
	'issues.twitter_cards/site_missing.instruction': `Pages should have a Twitter Cards <b>site property</b>.`,
	'issues.twitter_cards/site_missing.learnWhy': `<p>The Twitter Cards site property <b>defines the relation between your website and your Twitter account</b>.</p>`,
	'issues.twitter_cards/site_missing.title.broken': `Twitter Cards site property is missing`,
	'issues.twitter_cards/site_missing.title.ok': `Twitter Cards site property is present`,
	'issues.twitter_cards/title_incorrect_length.affectedPages': `Pages with an incorrect Twitter Card title length`,
	'issues.twitter_cards/title_incorrect_length.caseDescription': `Twitter Cards title is {type, select,
		too_long {{extraCharacters, plural,
			one {# character}
			other {# characters}
		} too long}
		too_short {{missingCharacters, plural,
			one {# character}
			other {# characters}
		} too short}
		other {}
	}.`,
	'issues.twitter_cards/title_incorrect_length.inlineCaseDescription': `{type, select,
		too_long {too long}
		too_short {too short}
		other {}
	}`,
	'issues.twitter_cards/title_incorrect_length.instruction': `Twitter Cards titles should be <configuration>{minChars, plural,
		=0 {{maxChars, number} characters or less}
		other {between {minChars, number} and {maxChars, number} characters}
	}</configuration>.`,
	'issues.twitter_cards/title_incorrect_length.learnWhy': `<p>If the Twitter Cards title is too long Twitter will cut your title short, meaning that <b>your page’s snippet will be sub-optimal when someone shares it online</b>.</p>`,
	'issues.twitter_cards/title_incorrect_length.title.broken': `Twitter Cards title {type, select,
		too_long {is too long}
		too_short {is too short}
		other {length is incorrect}
	}`,
	'issues.twitter_cards/title_incorrect_length.title.ok': `Twitter Cards title has correct length`,
	'issues.twitter_cards/title_missing.affectedPages': `Pages without a Twitter Card title`,
	'issues.twitter_cards/title_missing.instruction': `Pages should have a Twitter Cards <b>title</b>.`,
	'issues.twitter_cards/title_missing.learnWhy': `<p>Twitter Cards markup allows you to gain control of the snippets that are shown when your pages are shared on Twitter. Having a great Twitter title <b>increases the click-through rate</b> when your content is shared online.</p>`,
	'issues.twitter_cards/title_missing.title.broken': `Twitter Cards title is missing`,
	'issues.twitter_cards/title_missing.title.ok': `Twitter Cards title is present`,
	'issues.twitter_cards/type_invalid.affectedPages': `Pages without a valid Twitter Card type value`,
	'issues.twitter_cards/type_invalid.caseDescription': `Twitter Cards type has an invalid value.`,
	'issues.twitter_cards/type_invalid.instruction': `Twitter Cards type value should be <code>summary</code>, <code>summary_large_image</code>, <code>app</code> or <code>player</code>.`,
	'issues.twitter_cards/type_invalid.learnWhy': `<p>The Twitter Cards type property defines which type of card you want to display when your page is shared on Twitter. <b>If you don’t define a valid value, Twitter will not display any Twitter Card.</b></p>`,
	'issues.twitter_cards/type_invalid.title.broken': `Twitter Cards type value is not valid`,
	'issues.twitter_cards/type_invalid.title.ok': `Twitter Cards type value is valid`,
	'issues.twitter_cards/type_missing.affectedPages': `Pages without a Twitter Card type`,
	'issues.twitter_cards/type_missing.instruction': `Pages should have a Twitter Cards <b>type</b>.`,
	'issues.twitter_cards/type_missing.learnWhy': `<p>The Twitter Cards type property defines which type of card you want to display when your page is shared on Twitter. <b>If you don’t define it, Twitter will not display any Twitter Card.</b></p>`,
	'issues.twitter_cards/type_missing.title.broken': `Twitter Cards type is not present`,
	'issues.twitter_cards/type_missing.title.ok': `Twitter Cards type is present`,
	'issues.xml_sitemap/incorrectly_missing.affectedPages': `Pages missing from XML sitemap`,
	'issues.xml_sitemap/incorrectly_missing.instruction': `<b>Indexable, non-paginated pages</b> should be present in the XML sitemap.`,
	'issues.xml_sitemap/incorrectly_missing.learnWhy': `<p>All pages that are <b>indexable and not part of a pagination sequence</b> should be included in the XML sitemap. This ensures that search engines can easily find all your content and stay up-to-date with content changes.</p>`,
	'issues.xml_sitemap/incorrectly_missing.title.broken': `Page missing from XML sitemap`,
	'issues.xml_sitemap/incorrectly_missing.title.ok': `Page present in XML sitemap`,
	'issues.xml_sitemap/incorrectly_missing.title.unknown': `Page presence in XML sitemap is still unknown`,
	'issues.xml_sitemap/incorrectly_present.affectedPages': `Pages not absent from XML sitemap`,
	'issues.xml_sitemap/incorrectly_present.caseDescription': `Page should not be in any XML sitemap, but is included in {count, plural,
		one {# XML sitemap}
		other {# XML sitemaps}
	}.`,
	'issues.xml_sitemap/incorrectly_present.instruction': `<b>Non-indexable or paginated pages</b> should be absent from the XML sitemap.`,
	'issues.xml_sitemap/incorrectly_present.learnWhy': `<p>All non-indexable pages and pages that are part of a pagination sequence <b>should be excluded from the XML sitemap</b>. Including those pages in the XML sitemap gives mixed signals to search engines and causes them to waste crawl budget.</p>`,
	'issues.xml_sitemap/incorrectly_present.title.broken': `Page not absent from XML sitemap`,
	'issues.xml_sitemap/incorrectly_present.title.ok': `Page absent from XML sitemap`,
	'issues.xml_sitemap/incorrectly_present.title.unknown': `Page presence in XML sitemap is still unknown`,
	'issues.xml_sitemap/filesize_limit.caseDescription': `Website contains {count, number} XML {count, plural,
		one {sitemap}
		other {sitemaps}
	} with an uncompressed file size of more than 50MB.`,
	'issues.xml_sitemap/filesize_limit.instruction': `Individual XML sitemaps should have an <b>uncompressed file size of 50MB</b> or less.`,
	'issues.xml_sitemap/filesize_limit.learnWhy': `<p>XML sitemaps that exceed 50MB <b>may get rejected by search engines or only partially read</b>. To work around this problem, you can split the XML sitemap into separate sitemaps and reference them in an XML sitemap index.</p>`,
	'issues.xml_sitemap/filesize_limit.title.broken': `XML sitemap exceeds file size limit`,
	'issues.xml_sitemap/filesize_limit.title.ok': `All XML sitemaps are within file size limit`,
	'issues.xml_sitemap/inaccessible.caseDescription': `Website contains {count, number} not accessible XML {count, plural,
		one {sitemap}
		other {sitemaps}
	}.`,
	'issues.xml_sitemap/inaccessible.instruction': `XML sitemaps should be <b>accessible</b>.`,
	'issues.xml_sitemap/inaccessible.learnWhy': `<p>If XML sitemaps are inaccessible, <b>search engines won’t be able to read their contents</b>, which prevents them from quickly discovering all the pages on your website and staying up-to-date with changes.</p>`,
	'issues.xml_sitemap/inaccessible.title.broken': `XML sitemap is not accessible`,
	'issues.xml_sitemap/inaccessible.title.ok': `All XML sitemaps are accessible`,
	'issues.xml_sitemap/invalid_contents.caseDescription': `Website contains {count, number} XML {count, plural,
		one {sitemap}
		other {sitemaps}
	} with invalid contents.`,
	'issues.xml_sitemap/invalid_contents.instruction': `XML sitemaps should not contain invalid <b>content</b>.`,
	'issues.xml_sitemap/invalid_contents.learnWhy': `<p>Lines with invalid contents in XML sitemaps (as defined in the XML sitemap protocol) may <b>get skipped or incorrectly interpreted by search engines</b>.</p>`,
	'issues.xml_sitemap/invalid_contents.title.broken': `XML sitemap contains invalid content`,
	'issues.xml_sitemap/invalid_contents.title.ok': `All XML sitemap content is valid`,
	'issues.xml_sitemap/invalid_structure.caseDescription': `Website contains {count, number} XML {count, plural,
		one {sitemap}
		other {sitemaps}
	} with structure or syntax errors.`,
	'issues.xml_sitemap/invalid_structure.instruction': `XML sitemaps should follow the structure and syntax defined in the <b>XML sitemap protocol</b>.`,
	'issues.xml_sitemap/invalid_structure.learnWhy': `<p>XML sitemaps with invalid structure or syntax (as defined in the XML sitemap protocol) are <b>unreadable for search engines</b>, which prevents them from quickly discovering all the pages on your website and staying up-to-date with changes.</p>`,
	'issues.xml_sitemap/invalid_structure.title.broken': `XML sitemap has an invalid structure`,
	'issues.xml_sitemap/invalid_structure.title.ok': `All XML sitemaps have a valid structure`,
	'issues.xml_sitemap/invalid_structure_all.caseDescription': `Website contains {count, number} XML {count, plural,
		one {sitemap}
		other {sitemaps}
	} with structure or syntax errors.`,
	'issues.xml_sitemap/invalid_structure_all.instruction': `XML sitemaps should follow the structure and syntax defined in the <b>XML sitemap protocol</b>.`,
	'issues.xml_sitemap/invalid_structure_all.learnWhy': `<p>XML sitemaps with invalid structure or syntax (as defined in the XML sitemap protocol) are <b>unreadable for search engines</b>, which prevents them from quickly discovering all the pages on your website and staying up-to-date with changes.</p>`,
	'issues.xml_sitemap/invalid_structure_all.title.broken': `XML sitemap is not readable`,
	'issues.xml_sitemap/invalid_structure_all.title.ok': `Readable XML sitemap is present`,
	'issues.xml_sitemap/missing.caseDescription': `XML sitemaps cannot be found or accessed.`,
	'issues.xml_sitemap/missing.instruction': `Websites should have an accessible XML sitemap.`,
	'issues.xml_sitemap/missing.learnWhy': `<p>XML sitemaps are incredibly useful for search engines to <b>quickly discover all the pages</b> on your website and stay up-to-date with changes, as it provides them with a single overview of all available content.</p>`,
	'issues.xml_sitemap/missing.title.broken': `XML sitemap is missing`,
	'issues.xml_sitemap/missing.title.ok': `XML sitemap is present`,
	'issues.xml_sitemap/orphaned.caseDescription': `Website contains {count, number} XML {count, plural,
		one {sitemap}
		other {sitemaps}
	} which are not in a default location nor referenced.`,
	'issues.xml_sitemap/orphaned.instruction': `XML sitemaps should be in the <b>default location or referenced</b> from the robots.txt file or an XML sitemap index.`,
	'issues.xml_sitemap/orphaned.learnWhy': `<p>Unreferenced XML sitemaps (also called orphaned XML sitemaps) are <b>unfindable for search engines</b>, which means they can’t use them to quickly discover all the pages and stay up-to-date on changes. XML sitemaps should be referenced from the robots.txt file, by being in a default location or by being included in an XML sitemap index.</p>`,
	'issues.xml_sitemap/orphaned.title.broken': `XML sitemap is orphaned`,
	'issues.xml_sitemap/orphaned.title.ok': `All XML sitemaps are discoverable`,
	'issues.xml_sitemap/urlcount_limit.caseDescription': `Website contains {count, number} XML {count, plural,
		one {sitemap}
		other {sitemaps}
	} with more than {maxUrls, number} URLs.`,
	'issues.xml_sitemap/urlcount_limit.instruction': `Individual XML sitemaps should contain no more than <b>{maxUrls, number} URLs</b>.`,
	'issues.xml_sitemap/urlcount_limit.learnWhy': `<p>XML sitemaps that exceed 50,000 URLs <b>may get rejected by search engines or only partially read</b>. To work around this problem, you can split the XML sitemap into separate sitemaps and reference them in an XML sitemap index.</p>`,
	'issues.xml_sitemap/urlcount_limit.title.broken': `XML sitemap exceeds URL count limit`,
	'issues.xml_sitemap/urlcount_limit.title.ok': `All XML sitemaps are within URL count limit`,
	'issues.canonical_link_incorrectly_canonicalized.detailsLabel': `Canonical Link`,
	'issues.categories.analytics.affectedPagesTitle': `Pages with analytics issues`, //6579
	'issues.categories.analytics.configurationButton': `Configure Analytics issues`,
	'issues.categories.analytics.ignoringButton': `Ignore Analytics issues`,
	'issues.categories.analytics.title': `Analytics`, //6579
	'issues.categories.canonical_link.affectedPagesTitle': `Pages with canonical link issues`, //6396
	'issues.categories.canonical_link.configurationButton': `Configure Canonical link issues`,
	'issues.categories.canonical_link.ignoringButton': `Ignore Canonical link issues`,
	'issues.categories.canonical_link.title': `Canonical Link`, //6396
	'issues.categories.domain.configurationButton': `Configure Domain issues`,
	'issues.categories.domain.ignoringButton': `Ignore Domain issues`,
	'issues.categories.domain.title': `Domain`,
	'issues.categories.h1.affectedPagesTitle': `Pages with content heading issues`,
	'issues.categories.h1.configurationButton': `Configure Content headings issues`,
	'issues.categories.h1.ignoringButton': `Ignore Content headings issues`,
	'issues.categories.h1.title': `Content Headings`,
	'issues.categories.hreflang.affectedPagesTitle': `Pages with hreflang issues`,
	'issues.categories.hreflang.configurationButton': `Configure Hreflang issues`,
	'issues.categories.hreflang.ignoringButton': `Ignore Hreflang issues`,
	'issues.categories.hreflang.notApplicable.description': `Hreflang is not implemented`,
	'issues.categories.hreflang.notApplicable.instructionsIntroduction': `If you want to implement hreflang, make sure to follow these best practices:`,
	'issues.categories.hreflang.title': `Hreflang`,
	'issues.categories.images.affectedPagesTitle': `Pages with image issues`,
	'issues.categories.images.configurationButton': `Configure Images issues`,
	'issues.categories.images.ignoringButton': `Ignore Images issues`,
	'issues.categories.images.title': `Images`,
	'issues.categories.lighthouse.affectedPagesTitle': `Pages with Conductor Website Monitoring Lighthouse Web Vitals issues`,
	'issues.categories.lighthouse.configurationButton': `Ignore Conductor Website Monitoring Lighthouse Web Vitals issues`,
	'issues.categories.lighthouse.ignoringButton': `Ignore Conductor Website Monitoring Lighthouse Web Vitals issues`,
	'issues.categories.lighthouse.notApplicable.description': `Conductor Website Monitoring Lighthouse Web Vitals is not evaluated`,
	'issues.categories.lighthouse.notApplicable.instructionsIntroduction': `Once Lighthouse Monitoring is enabled, Conductor Website Monitoring will start checking whether your pages follow these best practices:`,
	'issues.categories.lighthouse.title': `Conductor Website Monitoring Lighthouse Web Vitals`,
	'issues.categories.links.affectedPagesTitle': `Pages with link issues`,
	'issues.categories.links.configurationButton': `Configure Links issues`,
	'issues.categories.links.ignoringButton': `Ignore Links issues`,
	'issues.categories.links.title': `Links`,
	'issues.categories.meta_description.affectedPagesTitle': `Pages with meta description issues`,
	'issues.categories.meta_description.configurationButton': `Configure Meta description issues`,
	'issues.categories.meta_description.ignoringButton': `Ignore Meta description issues`,
	'issues.categories.meta_description.title': `Meta description`,
	'issues.categories.open_graph.affectedPagesTitle': `Pages with Open Graph issues`,
	'issues.categories.open_graph.configurationButton': `Configure Open Graph issues`,
	'issues.categories.open_graph.ignoringButton': `Ignore Open Graph issues`,
	'issues.categories.open_graph.title': `Open Graph`,
	'issues.categories.robot_directives.affectedPagesTitle': `Pages with robot directive issues`,
	'issues.categories.robot_directives.configurationButton': `Configure Robot directives issues`,
	'issues.categories.robot_directives.ignoringButton': `Ignore Robot directives issues`,
	'issues.categories.robot_directives.notApplicable.description': `No robot directives are present`,
	'issues.categories.robot_directives.notApplicable.instructionsIntroduction': `When adding robot directives make sure to follow these best practices:`,
	'issues.categories.robot_directives.title': `Robot directives`,
	'issues.categories.robots_txt.configurationButton': `Configure Robots.txt issues`,
	'issues.categories.robots_txt.ignoringButton': `Ignore Robots.txt issues`,
	'issues.categories.robots_txt.title': `Robots.txt`,
	'issues.categories.schema_org.affectedPagesTitle': `Pages with schema.org issues`,
	'issues.categories.schema_org.configurationButton': `Configure Schema.org issues`,
	'issues.categories.schema_org.ignoringButton': `Ignore Schema.org issues`,
	'issues.categories.schema_org.notApplicable.description': `No schema.org implemented using JSON-LD is present`,
	'issues.categories.schema_org.notApplicable.instructionsIntroduction': `When implementing schema.org make sure to follow these best practices:`,
	'issues.categories.schema_org.title': `Schema.org`,
	'issues.categories.title.affectedPagesTitle': `Pages with page title issues`,
	'issues.categories.title.configurationButton': `Configure Page title issues`,
	'issues.categories.title.ignoringButton': `Ignore Page title issues`,
	'issues.categories.title.title': `Page title`,
	'issues.categories.twitter_cards.affectedPagesTitle': `Pages with Twitter Card issues`,
	'issues.categories.twitter_cards.configurationButton': `Configure Twitter Cards issues`,
	'issues.categories.twitter_cards.ignoringButton': `Ignore Twitter Cards issues`,
	'issues.categories.twitter_cards.title': `Twitter Cards`,
	'issues.categories.web_vitals_origin_summary.configurationButton': `Configure Web Vitals Origin Summary issues`,
	'issues.categories.web_vitals_origin_summary.ignoringButton': `Ignore Web Vitals Origin Summary issues`,
	'issues.categories.web_vitals_origin_summary.notApplicable.description': `Web Vitals Origin Summary is not evaluated`,
	'issues.categories.web_vitals_origin_summary.notApplicable.instructionsIntroduction': `This feature is not included in your subscription. When your subscription includes this feature, Conductor Website Monitoring will start checking whether your origin Web Vitals follow these best practices:`,
	'issues.categories.web_vitals_origin_summary.title': `Web Vitals Origin Summary`,
	'issues.categories.xml_sitemap.affectedPagesTitle': `Pages with XML sitemap issues`, //6723
	'issues.categories.xml_sitemap.configurationButton': `Configure XML Sitemap issues`,
	'issues.categories.xml_sitemap.ignoringButton': `Ignore XML Sitemap issues`,
	'issues.categories.xml_sitemap.title': `XML Sitemap`,
	'issues.indexable_canonical_target.detailsLabel': `Target URL`, //6396
	'issues.length_h1.detailsLabel': `H1 Heading`,
	'issues.length_metaDescription.detailsLabel': `Meta Description`,
	'issues.length_title.detailsLabel': `Page Title`,
	'issues.links.internal': `Internal`, //10916
	'issues.links.internal.message': `Overview displays links within the website only.`, //10916
	'issues.links_3xx.affectedLinksTitle': `{text__internal} links to redirects`, //10916
	'issues.links_4xx.affectedLinksTitle': `{text__internal} broken links`, //10916
	'issues.max_count_h1.detailsLabel': `H1 Heading`,
	'issues.max_count_metaDescription.detailsLabel': `Meta Description`,
	'issues.max_count_title.detailsLabel': `Page Title`,
	'issues.open_graph_description_incorrect_length.detailsLabel': `Open Graph description`, //6170
	'issues.open_graph_title_incorrect_length.detailsLabel': `Open Graph title`, //6170
	'issues.platform.domain.title': `Domain`,
	'issues.platform.robots_txt.title': `Robots.txt`,
	'issues.platform.xml_sitemap.title': `XML Sitemap`,
	'issues.twitter_cards_description_incorrect_length.detailsLabel': `Twitter Cards description`, //6170
	'issues.twitter_cards_title_incorrect_length.detailsLabel': `Twitter Cards title`, //6170
	'issues.unique_element_content_h1.detailsLabel': `H1 Heading`,
	'issues.unique_element_content_h1.pagesWithSameH1': `Other pages with the same H1 heading`,
	'issues.unique_element_content_metaDescription.detailsLabel': `Meta Description`,
	'issues.unique_element_content_metaDescription.pagesWithSameMetaDescription': `Other pages with the same meta description`,
	'issues.unique_element_content_title.detailsLabel': `Page Title`,
	'issues.unique_element_content_title.pagesWithSameTitle': `Other pages with the same title`,
	'issues.web_vitals_origin_summary/core_web_vitals.caseDescription': `Aggregate experience of real users visiting this website does not pass the Core Web Vitals assessment.`,
	'issues.web_vitals_origin_summary/core_web_vitals.instruction': `Websites should <b>pass the Core Web Vitals Assessment</b>.`,
	'issues.web_vitals_origin_summary/core_web_vitals.learnWhy': `<p>Passing the Core Web Vitals Assessment indicates the website is <b>providing a good user experience</b>. From May 2021, passing the Core Web Vitals Assessment is also a <b>ranking factor for Google Search on mobile</b>. Each Core Web Vital from the Chrome User Experience Report needs to provide a good user experience for the website to pass the Core Web Vitals Assessment.</p>`,
	'issues.web_vitals_origin_summary/core_web_vitals.title.broken': `Website does not pass the Core Web Vitals assessment`,
	'issues.web_vitals_origin_summary/core_web_vitals.title.ok': `Website passes the Core Web Vitals assessment`,
	'issues.web_vitals_origin_summary/core_web_vitals.title.unknown': `Core Web Vitals assessment is still unknown`,
	'issues.web_vitals_origin_summary/cumulative_layout_shift.caseDescription': `Cumulative Layout Shift (CLS) is {cumulativeLayoutShiftScore, number, ::scale/0.01 .##} larger than recommended.`,
	'issues.web_vitals_origin_summary/cumulative_layout_shift.instruction': `Cumulative Layout Shift should be <b>0.1</b> or less.`,
	'issues.web_vitals_origin_summary/cumulative_layout_shift.learnWhy': `<p>Cumulative Layout Shift (CLS) reflects <b>visual stability</b>. It measures the cumulative score of all unexpected layout shifts within the viewport that occur during a page’s lifecycle. Since it is a <b>Core Web Vital metric</b>, it has an impact on the Core Web Vitals Assessment which is a ranking factor for Google Search on mobile from May 2021.</p>`,
	'issues.web_vitals_origin_summary/cumulative_layout_shift.title.broken': `Cumulative Layout Shift is too large`,
	'issues.web_vitals_origin_summary/cumulative_layout_shift.title.ok': `Cumulative Layout Shift is good`,
	'issues.web_vitals_origin_summary/cumulative_layout_shift.title.unknown': `Cumulative Layout Shift is still unknown`,
	'issues.web_vitals_origin_summary/first_contentful_paint.caseDescription': `First Contentful Paint (FCP) takes {firstContentfulPaint, number, ::scale/0.001 .0} seconds longer than recommended.`,
	'issues.web_vitals_origin_summary/first_contentful_paint.instruction': `First Contentful Paint should occur within <b>1.8 second</b> since the page starts loading.`,
	'issues.web_vitals_origin_summary/first_contentful_paint.learnWhy': `<p>First Contentful Paint (FCP) reflects <b>loading</b> performance. It measures the time from when a page starts loading to when any part of that page’s content is rendered on the screen. While LCP measures when the page's all main contents have finished loading, FCP measures the time until any content is loaded at all. FCP is not a Core Web Vital metric, but having a fast FCP is important, as it <b>reassures users that something is happening</b>.</p>`,
	'issues.web_vitals_origin_summary/first_contentful_paint.title.broken': `First Contentful Paint takes too long`,
	'issues.web_vitals_origin_summary/first_contentful_paint.title.ok': `First Contentful Paint is good`,
	'issues.web_vitals_origin_summary/first_contentful_paint.title.unknown': `First Contentful Paint is still unknown`,
	'issues.web_vitals_origin_summary/interaction_to_next_paint.caseDescription': `Interaction to Next Paint (INP) takes {interactionToNextPaint, number, ::.} milliseconds longer than recommended.`,
	'issues.web_vitals_origin_summary/interaction_to_next_paint.instruction': `Interaction to Next Paint should be shorter than <b>200 milliseconds</b>.`,
	'issues.web_vitals_origin_summary/interaction_to_next_paint.learnWhy': `<p>Interaction to Next Paint (INP) reflects <b>interactivity and responsiveness</b>. It measures responsiveness to user interactions by observing the latency of all click, tap, and keyboard interactions that occur throughout the lifespan of a user's visit to a page. Since it is a <b>Core Web Vital metric</b>, it has an impact on the Core Web Vitals Assessment which is a ranking factor for Google Search on mobile from May 2021.</p>`,
	'issues.web_vitals_origin_summary/interaction_to_next_paint.title.broken': `Interaction to Next Paint is too long`,
	'issues.web_vitals_origin_summary/interaction_to_next_paint.title.ok': `Interaction to Next Paint is good`,
	'issues.web_vitals_origin_summary/interaction_to_next_paint.title.unknown': `Interaction to Next Paint is still unknown`,
	'issues.web_vitals_origin_summary/largest_contentful_paint.caseDescription': `Largest Contentful Paint (LCP) takes {largestContentfulPaint, number, ::scale/0.001 .0} seconds longer than recommended.`,
	'issues.web_vitals_origin_summary/largest_contentful_paint.instruction': `Largest Contentful Paint should occur within <b>2.5 seconds</b> since the page starts loading.`,
	'issues.web_vitals_origin_summary/largest_contentful_paint.learnWhy': `<p>Largest Contentful Paint (LCP) reflects a <b>perceived load speed</b>. It measures the time from when the page starts loading to when the largest text block or image element is rendered on the screen. Since it is a <b>Core Web Vital metric</b>, it has an impact on the Core Web Vitals Assessment which is a ranking factor for Google Search on mobile from May 2021.</p>`,
	'issues.web_vitals_origin_summary/largest_contentful_paint.title.broken': `Largest Contentful Paint takes too long`,
	'issues.web_vitals_origin_summary/largest_contentful_paint.title.ok': `Largest Contentful Paint is good`,
	'issues.web_vitals_origin_summary/largest_contentful_paint.title.unknown': `Largest Contentful Paint is still unknown`,
};
