import React from 'react';

import CK from '~/types/contentking';

import useWebsiteCustomElementDefinitions from '~/hooks/useWebsiteCustomElementDefinitions';
import useWebsiteEnrichmentFieldDefinitions from '~/hooks/useWebsiteEnrichmentFieldDefinitions';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	DATA_TYPE_BOOLEAN,
	DATA_TYPE_DATE,
	DATA_TYPE_INTEGER,
	DATA_TYPE_NUMBER,
	DATA_TYPE_STRING,
} from '~/model/customElements';

import {
	PagesColumnsCategory,
	getColumnCategory,
} from '~/model/pagesColumns';

import {
	FILTER_TYPE_BOOLEAN,
	FILTER_TYPE_BOOLEAN_OR_NA,
	FILTER_TYPE_CUSTOM_DAYS_AGO,
	FILTER_TYPE_CUSTOM_NUMBER,
	FILTER_TYPE_CUSTOM_STRING,
	FILTER_TYPE_DAYS_AGO,
	FILTER_TYPE_MILLISECONDS,
	FILTER_TYPE_MONTHS,
	FILTER_TYPE_NUMBER,
	FILTER_TYPE_PERCENTAGE,
	FILTER_TYPE_PSEUDO_ENUM,
	FILTER_TYPE_SECONDS,
	FILTER_TYPE_STRING,
	FILTER_TYPE_STRING_NON_NULLABLE,
} from '~/model/pagesColumnsFiltering';

import matchAndReturn from '~/utilities/matchAndReturn';



function useGetFilterType() {
	const websiteId = useWebsiteId();

	const customElementDefinitions = useWebsiteCustomElementDefinitions(websiteId);
	const enrichmentFieldDefinitions = useWebsiteEnrichmentFieldDefinitions(websiteId);

	return React.useCallback(
		(columnName: CK.PagesColumn) => {
			const columnCategory = getColumnCategory(columnName);

			if (columnCategory === PagesColumnsCategory.CustomElements) {
				const customElementDefinition = customElementDefinitions.getByColumn(columnName);

				if (customElementDefinition === null) {
					return null;
				}

				return matchAndReturn(customElementDefinition.dataType, {
					[DATA_TYPE_BOOLEAN]: FILTER_TYPE_PSEUDO_ENUM,
					[DATA_TYPE_DATE]: FILTER_TYPE_CUSTOM_DAYS_AGO,
					[DATA_TYPE_INTEGER]: FILTER_TYPE_CUSTOM_NUMBER,
					[DATA_TYPE_NUMBER]: FILTER_TYPE_CUSTOM_NUMBER,
					[DATA_TYPE_STRING]: FILTER_TYPE_CUSTOM_STRING,
				});
			}

			if (columnCategory === PagesColumnsCategory.EnrichmentFields) {
				const enrichmentFieldDefinition = enrichmentFieldDefinitions.getByColumn(columnName);

				if (enrichmentFieldDefinition === null) {
					return null;
				}

				return matchAndReturn(enrichmentFieldDefinition.dataType, {
					[DATA_TYPE_BOOLEAN]: FILTER_TYPE_PSEUDO_ENUM,
					[DATA_TYPE_DATE]: FILTER_TYPE_CUSTOM_DAYS_AGO,
					[DATA_TYPE_NUMBER]: FILTER_TYPE_CUSTOM_NUMBER,
					[DATA_TYPE_STRING]: FILTER_TYPE_CUSTOM_STRING,
				});
			}

			if (
				columnName === CK.PagesCommonColumn.AnalyticsServices
				|| columnName === CK.PagesCommonColumn.SchemaOrgTypes
				|| columnName === CK.PagesCommonColumn.TagManagers
				|| columnName === CK.PagesCommonColumn.VisualAnalyticsServices
			) {
				return FILTER_TYPE_PSEUDO_ENUM;
			}

			if (
				columnName === CK.PagesCommonColumn.CanonicalType
				|| columnName === CK.PagesCommonColumn.ChangeType
				|| columnName === CK.PagesCommonColumn.Health
				|| columnName === CK.PagesCommonColumn.IsDisallowedInRobotsTxt
				|| columnName === CK.PagesCommonColumn.Relevance
				|| columnName === CK.PagesCommonColumn.Segments
				|| columnName === CK.PagesCommonColumn.StatusCode
				|| columnName === CK.PagesCommonColumn.Type
			) {
				return columnName;
			}

			if (
				columnName === CK.PagesCommonColumn.CanonicalUrl
				|| columnName === CK.PagesCommonColumn.H1
				|| columnName === CK.PagesCommonColumn.HreflangLanguage
				|| columnName === CK.PagesCommonColumn.LinkAmp
				|| columnName === CK.PagesCommonColumn.LinkNext
				|| columnName === CK.PagesCommonColumn.LinkPrev
				|| columnName === CK.PagesCommonColumn.MetaDescription
				|| columnName === CK.PagesCommonColumn.MobileVariant
				|| columnName === CK.PagesCommonColumn.OpenGraphDescription
				|| columnName === CK.PagesCommonColumn.OpenGraphImage
				|| columnName === CK.PagesCommonColumn.OpenGraphTitle
				|| columnName === CK.PagesCommonColumn.OpenGraphType
				|| columnName === CK.PagesCommonColumn.OpenGraphUrl
				|| columnName === CK.PagesCommonColumn.Redirect
				|| columnName === CK.PagesCommonColumn.Title
				|| columnName === CK.PagesCommonColumn.TwitterCard
				|| columnName === CK.PagesCommonColumn.TwitterDescription
				|| columnName === CK.PagesCommonColumn.TwitterImage
				|| columnName === CK.PagesCommonColumn.TwitterSite
				|| columnName === CK.PagesCommonColumn.TwitterTitle
			) {
				return FILTER_TYPE_STRING;
			}

			if (
				columnName === CK.PagesCommonColumn.Domain
				|| columnName === CK.PagesCommonColumn.Url
				|| columnName === CK.PagesCommonColumn.UrlFull
			) {
				return FILTER_TYPE_STRING_NON_NULLABLE;
			}

			if (
				columnName === CK.PagesCommonColumn.LfaBingDesktopLastVisit
				|| columnName === CK.PagesCommonColumn.LfaBingLastVisit
				|| columnName === CK.PagesCommonColumn.LfaBingMobileLastVisit
				|| columnName === CK.PagesCommonColumn.LfaGoogleDesktopLastVisit
				|| columnName === CK.PagesCommonColumn.LfaGoogleLastVisit
				|| columnName === CK.PagesCommonColumn.LfaGoogleMobileLastVisit
				|| columnName === CK.PagesCommonColumn.TimeFound
			) {
				return FILTER_TYPE_DAYS_AGO;
			}

			if (
				columnName === CK.PagesCommonColumn.AaBounceRate
				|| columnName === CK.PagesCommonColumn.GaBounceRate
				|| columnName === CK.PagesCommonColumn.GscCtr
				|| columnName === CK.PagesCommonColumn.GaEngagementRate
			) {
				return FILTER_TYPE_PERCENTAGE;
			}

			if (
				columnName === CK.PagesCommonColumn.AaAverageTimeSpentOnSite
				|| columnName === CK.PagesCommonColumn.GaAverageTime
				|| columnName === CK.PagesCommonColumn.LighthouseFirstContentfulPaint
				|| columnName === CK.PagesCommonColumn.LighthouseTimeToInteractive
				|| columnName === CK.PagesCommonColumn.LighthouseSpeedIndex
				|| columnName === CK.PagesCommonColumn.LighthouseLargestContentfulPaint
				|| columnName === CK.PagesCommonColumn.GaAverageEngagementTime
			) {
				return FILTER_TYPE_SECONDS;
			}

			if (
				columnName === CK.PagesCommonColumn.LighthouseTotalBlockingTime
			) {
				return FILTER_TYPE_MILLISECONDS;
			}

			if (
				columnName === CK.PagesCommonColumn.AaPageViews
				|| columnName === CK.PagesCommonColumn.AaRevenue
				|| columnName === CK.PagesCommonColumn.AaUniqueVisitors
				|| columnName === CK.PagesCommonColumn.GaPageValue
				|| columnName === CK.PagesCommonColumn.GaPageViews
				|| columnName === CK.PagesCommonColumn.GaUniquePageViews
				|| columnName === CK.PagesCommonColumn.GscClicks
				|| columnName === CK.PagesCommonColumn.GscImpressions
				|| columnName === CK.PagesCommonColumn.GscPosition
				|| columnName === CK.PagesCommonColumn.LighthousePerformance
				|| columnName === CK.PagesCommonColumn.LighthouseCumulativeLayoutShift
				|| columnName === CK.PagesCommonColumn.NumberOfHreflangs
				|| columnName === CK.PagesCommonColumn.NumberOfIncomingInternalCanonicals
				|| columnName === CK.PagesCommonColumn.NumberOfIncomingInternalLinks
				|| columnName === CK.PagesCommonColumn.NumberOfIncomingInternalRedirects
				|| columnName === CK.PagesCommonColumn.NumberOfOutgoingExternalLinks
				|| columnName === CK.PagesCommonColumn.NumberOfOutgoingInternalLinks
				|| columnName === CK.PagesCommonColumn.SchemaOrgNumberOfTypes
				|| columnName === CK.PagesCommonColumn.TimeServerResponse
				|| columnName === CK.PagesCommonColumn.UrlDepth
				|| columnName === CK.PagesCommonColumn.GaScreenPageViews
				|| columnName === CK.PagesCommonColumn.GaActiveUsers
			) {
				return FILTER_TYPE_NUMBER;
			}

			if (
				columnName === CK.PagesCommonColumn.LfaBingDesktopFrequency
				|| columnName === CK.PagesCommonColumn.LfaBingFrequency
				|| columnName === CK.PagesCommonColumn.LfaBingMobileFrequency
				|| columnName === CK.PagesCommonColumn.LfaGoogleDesktopFrequency
				|| columnName === CK.PagesCommonColumn.LfaGoogleFrequency
				|| columnName === CK.PagesCommonColumn.LfaGoogleMobileFrequency
			) {
				return FILTER_TYPE_MONTHS;
			}

			if (
				columnName === CK.PagesCommonColumn.IsInSitemap
				|| columnName === CK.PagesCommonColumn.IsIndexable
				|| columnName === CK.PagesCommonColumn.IsLinked
				|| columnName === CK.PagesCommonColumn.IsSecured
			) {
				return FILTER_TYPE_BOOLEAN;
			}

			if (
				columnName === CK.PagesCommonColumn.IsIndexableDueToMetaRobots
				|| columnName === CK.PagesCommonColumn.IsIndexableDueToXRobotsTag
			) {
				return FILTER_TYPE_BOOLEAN_OR_NA;
			}

			return null;
		},
		[
			customElementDefinitions,
			enrichmentFieldDefinitions,
		],
	);
}



export default useGetFilterType;
