import React from 'react';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import AttachedElement from '~/components/patterns/structuredValues/AttachedElement';
import AttachedIcon, {
	AttachedIconPosition,
} from '~/components/patterns/structuredValues/AttachedIcon';
import AttributesGroupIcon, {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import BlankSlate from '~/components/patterns/messages/embedded/BlankSlate';
import BorderedBox from '~/components/patterns/boxes/BorderedBox';
import {
	linkExternal,
} from '~/components/logic/Copy';
import DatePeriodLabel from '~/components/app/DatePeriodLabel';
import DefinitionTerm from '~/components/patterns/structuredValues/definitionTerms/DefinitionTerm';
import DefinitionTermsList from '~/components/patterns/structuredValues/definitionTerms/DefinitionTermsList';
import DistributionBarChart from '~/components/patterns/charts/charts/DistributionBarChart';
import Grid, {
	GridGapsSize,
} from '~/components/patterns/structures/Grid';
import HelpHint from '~/components/patterns/hints/HelpHint';
import MarginBox from '~/components/atoms/boxes/MarginBox';
import PremiumFeatureSituation, {
	PremiumFeatureSituationStyle,
} from '~/components/app/PremiumFeatureSituation';
import SquareSkeleton from '~/components/patterns/loaders/SquareSkeleton';
import WebVitalName from '~/components/app/WebVitalName';
import WebVitalDistributionBarChart from '~/components/app/WebVitalDistributionBarChart';
import WebVitalFormatter from '~/components/app/WebVitalFormatter';
import WebVitalsMetric from '~/components/patterns/webVitals/WebVitalsMetric';
import WebVitalsStatusIndicator, {
	WebVitalsStatusIndicatorStatus,
} from '~/components/patterns/webVitals/WebVitalsStatusIndicator';
import WebVitalsValue, {
	WebVitalsValueStatus,
} from '~/components/patterns/webVitals/WebVitalsValue';

import {
	usePlatformWebVitalsOriginSummaryBlockQuery,
} from './WebVitalsOriginSummaryBlock.gql';

import useAccountEnabledFeatures from '~/hooks/useAccountEnabledFeatures';
import usePlatformQuery from '~/hooks/usePlatformQuery';
import useWebsiteAccountId from '~/hooks/useWebsiteAccountId';
import useWebsiteHasWebVitalsOriginSummary from '~/hooks/useWebsiteHasWebVitalsOriginSummary';

import {
	WebVital,
} from '~/model/webVitals';



const messages = defineMessages({
	cannotBeAssessedTooltip: {
		id: 'ui.platform.webVitalsOriginSummary.cannotBeAssessedTooltip',
	},
	coreWebVitalsAssessment: {
		id: 'ui.platform.webVitalsOriginSummary.coreWebVitalsAssessment',
	},
	insufficientData: {
		id: 'ui.platform.webVitalsOriginSummary.insufficientData',
	},
	noOriginDataAvailable: {
		id: 'ui.platform.webVitalsOriginSummary.noOriginDataAvailable',
	},
	title: {
		id: 'ui.platform.webVitalsOriginSummary.title',
	},
	verdictCannotBeAssessed: {
		id: 'ui.platform.webVitalsOriginSummary.coreWebVitalsAssessment.verdict.cannotBeAssessed',
	},
	verdictFail: {
		id: 'ui.platform.webVitalsOriginSummary.coreWebVitalsAssessment.verdict.fail',
	},
	verdictPass: {
		id: 'ui.platform.webVitalsOriginSummary.coreWebVitalsAssessment.verdict.pass',
	},
});



type Props = {
	websiteId: CK.WebsiteId,
};

const WebVitalsOriginSummaryBlock: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const websiteAccountId = useWebsiteAccountId(websiteId);
	const accountEnabledFeatures = useAccountEnabledFeatures(websiteAccountId);

	const isFeatureEnabled = accountEnabledFeatures !== null
		? accountEnabledFeatures.includes(GraphQL.AccountFeature.WebVitalsOriginSummary)
		: null;

	const hasWebVitals = useWebsiteHasWebVitalsOriginSummary(
		websiteId,
		{
			skip: isFeatureEnabled !== false,
		},
	);

	const {
		data,
		loading,
	} = usePlatformQuery(
		usePlatformWebVitalsOriginSummaryBlockQuery,
		websiteId,
		{
			skip: isFeatureEnabled !== true,
		},
	);

	const dateRange = data?.platformData?.webVitalsOriginSummaryData?.dateRange;
	const coreWebVitalsAssessment = data?.platformData?.webVitalsOriginSummaryData?.coreWebVitalsAssessment;

	const webVitals = [
		[WebVital.FCP, data?.platformData?.webVitalsOriginSummaryData?.firstContentfulPaint],
		[WebVital.LCP, data?.platformData?.webVitalsOriginSummaryData?.largestContentfulPaint],
		[WebVital.CLS, data?.platformData?.webVitalsOriginSummaryData?.cumulativeLayoutShiftScore],
		[WebVital.INP, data?.platformData?.webVitalsOriginSummaryData?.interactionToNextPaint],
	] as const;

	const noOriginDataAvailable = !loading && data?.platformData?.webVitalsOriginSummaryData === null;

	if (!isFeatureEnabled && !hasWebVitals) {
		return null;
	}

	return (
		<PremiumFeatureSituation
			featureName={GraphQL.AccountFeature.WebVitalsOriginSummary}
			hideIfUnattainable={true}
			style={PremiumFeatureSituationStyle.Ribbon}
		>
			{({ premiumAnnotation }) => (
				<MarginBox>
					<BorderedBox
						headerActionElements={premiumAnnotation}
						headerIcon={(
							<AttributesGroupIcon
								color="#964fa5"
								size={28}
								type={AttributesGroupIconType.WebVitals}
							/>
						)}
						headerLabel={(
							<FormattedMessage {...messages.title} />
						)}
						headerSublabel={
							dateRange ? (
								<DatePeriodLabel
									since={new Date(dateRange.since)}
									until={new Date(dateRange.until)}
								/>
							) : loading ? (
								<span>&nbsp;</span>
							) : (
								null
							)
						}
						paddingSize={3}
					>
						{isFeatureEnabled && noOriginDataAvailable && (
							<BlankSlate>
								<FormattedMessage
									{...messages.noOriginDataAvailable}
									values={{
										linkArticle: linkExternal('https://www.conductor.com/academy/core-web-vitals/#4-why-is-there-no-field-data-for-my-url-or-origin-summary'),
									}}
								/>
							</BlankSlate>
						)}

						{isFeatureEnabled && !noOriginDataAvailable && (
							<DefinitionTermsList>
								<DefinitionTerm
									description={
										loading ? (
											<SquareSkeleton
												height={19}
												maxWidth={60}
											/>
										) : coreWebVitalsAssessment === GraphQL.CoreWebVitalsAssessment.Passed ? (
											<WebVitalsValue status={WebVitalsValueStatus.Good}>
												<FormattedMessage {...messages.verdictPass} />
											</WebVitalsValue>
										) : coreWebVitalsAssessment === GraphQL.CoreWebVitalsAssessment.Failed ? (
											<WebVitalsValue status={WebVitalsValueStatus.Poor}>
												<FormattedMessage {...messages.verdictFail} />
											</WebVitalsValue>
										) : coreWebVitalsAssessment === GraphQL.CoreWebVitalsAssessment.CannotBeAssessed ? (
											<AttachedElement
												element={(
													<HelpHint
														message={(
															<FormattedMessage {...messages.cannotBeAssessedTooltip} />
														)}
													/>
												)}
											>
												<WebVitalsValue status={WebVitalsValueStatus.Unknown}>
													<FormattedMessage {...messages.verdictCannotBeAssessed} />
												</WebVitalsValue>
											</AttachedElement>
										) : null
									}
									term={(
										<AttachedIcon
											icon={(
												<BasicIcon
													size={20}
													type={BasicIconType.CoreWebVitals}
												/>
											)}
											iconPosition={AttachedIconPosition.Suffix}
										>
											<FormattedMessage {...messages.coreWebVitalsAssessment} />
										</AttachedIcon>
									)}
								/>
								<Grid
									columnsCount={2}
									gapsSize={GridGapsSize.Large}
									oneColumnOnSmall={true}
								>
									{webVitals.map(([type, data]) => {
										let status: WebVitalsStatusIndicatorStatus | undefined = undefined;

										if (data?.verdict === GraphQL.WebVitalVerdict.Fast) {
											status = WebVitalsStatusIndicatorStatus.Good;
										} else if (data?.verdict === GraphQL.WebVitalVerdict.Average) {
											status = WebVitalsStatusIndicatorStatus.NeedsImprovement;
										} else if (data?.verdict === GraphQL.WebVitalVerdict.Slow) {
											status = WebVitalsStatusIndicatorStatus.Poor;
										}

										return (
											<WebVitalsMetric
												key={type}
												label={<WebVitalName type={type} />}
												statusIndicator={<WebVitalsStatusIndicator status={status} />}
												value={(
													loading ? (
														<SquareSkeleton
															height={15}
															width={30}
														/>
													) : data ? (
														<WebVitalFormatter
															type={type}
															value={data.percentile}
															verdict={data.verdict}
														/>
													) : (
														null
													)
												)}
											>
												{loading ? (
													<SquareSkeleton height={15} />
												) : data ? (
													<WebVitalDistributionBarChart
														maximumFast={data.maximumFast}
														maximumGood={data.maximumGood}
														percentageFast={data.percentageFast}
														percentageGood={data.percentageGood}
														percentageSlow={data.percentageSlow}
														type={type}
													/>
												) : (
													<DistributionBarChart
														data={[{
															color: '#e7ebee',
															value: 100,
															label: (
																<FormattedMessage
																	{...messages.insufficientData}
																	values={{
																		webVital: type,
																	}}
																/>
															),
														}]}
													/>
												)}
											</WebVitalsMetric>
										);
									})}
								</Grid>
							</DefinitionTermsList>
						)}
					</BorderedBox>
				</MarginBox>
			)}
		</PremiumFeatureSituation>
	);
};



export default WebVitalsOriginSummaryBlock;
