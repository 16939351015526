import {
	List,
} from 'immutable';



function createNow() {
	return Date.now() / 1000;
}



export function createCoverage() {
	return new List([]);
}



export function coverSpan(coverage, coverFrom, coverSpan, expiresAt = Infinity) {
	return coverage.withMutations((mutableCoverage) => {
		for (let i = 0; i < coverSpan; i++) {
			mutableCoverage.set(coverFrom + i, expiresAt);
		}
	});
}



export function isCovered(coverage, point, tolerance = 0) {
	const now = createNow();

	if (tolerance === 0) {
		return coverage.get(point) >= now;
	}

	for (let i = point - tolerance; i < point + (tolerance * 2); i++) {
		if (coverage.get(i) >= now) {
			return true;
		}
	}

	return false;
}



export function getPatch(coverage, coverFrom, coverSpan) {
	let since = null;
	let until = null;

	for (let i = 0; i < coverSpan; i++) {
		if (!isCovered(coverage, coverFrom + i)) {
			since = coverFrom + i;

			break;
		}
	}

	for (let i = coverFrom + coverSpan - 1; i >= coverFrom; i--) {
		if (!isCovered(coverage, i)) {
			until = i;

			break;
		}
	}

	if (since === null) {
		since = coverFrom;
	}

	if (until === null) {
		return {
			coverFrom: since,
			coverSpan: 0,
			isEdge: false,
			coverCoefficient: 0,
		};
	}

	const half = Math.floor(coverSpan / 2);
	let coverCoefficient = 0;

	for (let i = 0; i < coverSpan; i++) {
		if (!isCovered(coverage, coverFrom + i)) {
			if (i >= half) {
				coverCoefficient++;
			} else {
				coverCoefficient--;
			}
		}
	}

	return {
		coverFrom: since,
		coverSpan: until - since + 1,
		isEdge: (since === coverFrom) || (until === (coverFrom + coverSpan - 1)),
		coverCoefficient: coverCoefficient / coverSpan,
	};
}
