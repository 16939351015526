import classNames from 'classnames';
import React from 'react';

import DepthLayer from '~/components/patterns/utils/DepthLayer';



type Props = {
	children: React.ReactNode,
	conductorNavigation?: React.ReactNode,
	header: React.ReactNode,
	logo?: React.ReactNode,
	monochrome?: boolean,
	profile: React.ReactNode,
	userNavigation?: React.ReactNode,
	userNavigationDropdown?: React.ReactNode,
	websitesNavigation?: React.ReactNode,
	websitesNavigationDropdown?: React.ReactNode,
};



const ScreenSidebarLayout: React.FC<Props> = (props) => {
	const {
		children,
		conductorNavigation,
		header,
		logo,
		monochrome,
		profile,
		userNavigation,
		userNavigationDropdown,
		websitesNavigation,
		websitesNavigationDropdown,
	} = props;

	const componentClasses = classNames({
		'sidebar': true,
		'sidebar--conductor': conductorNavigation !== undefined,
		'sidebar--monochrome': monochrome,
	});

	return (
		<DepthLayer depthJump={1500}>
			<div className={componentClasses}>
				{logo && (
					<div
						className="sidebar__logo"
						itemScope={true}
						itemType="http://schema.org/Organization"
						role="banner"
					>
						{logo}
					</div>
				)}
				<div className="sidebar__header">
					{header}
				</div>
				{websitesNavigation && (
					<div className="sidebar__websites-nav">
						{websitesNavigation}
					</div>
				)}
				{websitesNavigationDropdown && (
					<div className="sidebar__websites-nav-dropdown-target">
						{websitesNavigationDropdown}
					</div>
				)}
				<div className="sidebar__content js-scrollable">
					{children}
				</div>
				{userNavigation && (
					<div className="sidebar__user-nav">
						{userNavigation}
					</div>
				)}
				{userNavigationDropdown && (
					<div className="sidebar__user-nav-dropdown-target">
						{userNavigationDropdown}
					</div>
				)}
				<div className="sidebar__profile">
					{profile}
				</div>
				{conductorNavigation && (
					<div className="sidebar__conductor">
						{conductorNavigation}
					</div>
				)}
			</div>
		</DepthLayer>
	);
};



export default ScreenSidebarLayout;
