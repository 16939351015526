/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountPremiumTrialOfferQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountPremiumTrialOfferQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId, readonly premiumTrialOffer: { readonly __typename?: 'AccountPremiumTrialOffer', readonly durationInDays: number, readonly plan: GraphQL.AccountPlan } | null } | null };


export const AccountPremiumTrialOfferDocument = gql`
    query AccountPremiumTrialOffer($accountId: AccountId!) {
  account(id: $accountId) {
    id
    premiumTrialOffer {
      durationInDays
      plan
    }
  }
}
    `;

/**
 * __useAccountPremiumTrialOfferQuery__
 *
 * To run a query within a React component, call `useAccountPremiumTrialOfferQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPremiumTrialOfferQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPremiumTrialOfferQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountPremiumTrialOfferQuery(baseOptions: Apollo.QueryHookOptions<AccountPremiumTrialOfferQuery, AccountPremiumTrialOfferQueryVariables> & ({ variables: AccountPremiumTrialOfferQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountPremiumTrialOfferQuery, AccountPremiumTrialOfferQueryVariables>(AccountPremiumTrialOfferDocument, options);
      }
export function useAccountPremiumTrialOfferLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountPremiumTrialOfferQuery, AccountPremiumTrialOfferQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountPremiumTrialOfferQuery, AccountPremiumTrialOfferQueryVariables>(AccountPremiumTrialOfferDocument, options);
        }
export function useAccountPremiumTrialOfferSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountPremiumTrialOfferQuery, AccountPremiumTrialOfferQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountPremiumTrialOfferQuery, AccountPremiumTrialOfferQueryVariables>(AccountPremiumTrialOfferDocument, options);
        }
export type AccountPremiumTrialOfferQueryHookResult = ReturnType<typeof useAccountPremiumTrialOfferQuery>;
export type AccountPremiumTrialOfferLazyQueryHookResult = ReturnType<typeof useAccountPremiumTrialOfferLazyQuery>;
export type AccountPremiumTrialOfferSuspenseQueryHookResult = ReturnType<typeof useAccountPremiumTrialOfferSuspenseQuery>;
export type AccountPremiumTrialOfferQueryResult = Apollo.QueryResult<AccountPremiumTrialOfferQuery, AccountPremiumTrialOfferQueryVariables>;