import React from 'react';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import ButtonsLayout from '~/components/patterns/buttons/ButtonsLayout';
import CancelButton from '~/components/app/CancelButton';
import CheckboxField from '~/components/atoms/forms/components/CheckboxField';
import DisplayPart from '~/components/atoms/forms/basis/DisplayPart';
import EditableFormWrapper from '~/components/atoms/forms/basis/EditableFormWrapper';
import EditablePart from '~/components/atoms/forms/basis/EditablePart';
import Form from '~/components/atoms/forms/basis/Form';
import FormRow from '~/components/atoms/forms/basis/FormRow';
import FormRows from '~/components/atoms/forms/basis/FormRows';
import SaveSubmitButton from '~/components/app/SaveSubmitButton';
import SelectField from '~/components/atoms/forms/components/SelectField';
import StaticText from '~/components/atoms/forms/components/StaticText';
import WhenAccountActionAllowed from '~/components/app/WhenAccountActionAllowed';

import useAccountIsOutOfBand from '~/hooks/useAccountIsOutOfBand';
import useAccountPhase from '~/hooks/useAccountPhase';
import useAccountPropertiesQuery from '~/hooks/useAccountPropertiesQuery';

import {
	useAdministerAccountSalesSettingsMutation,
	useSalesSettingsFormQuery,
} from './SalesSettingsForm.gql';



function formatAccountLogoSignificance(accountLogoSignificance: GraphQL.AccountAdminLogoSignificance | null) {
	if (accountLogoSignificance === GraphQL.AccountAdminLogoSignificance.Regular) {
		return 'Regular';
	} else if (accountLogoSignificance === GraphQL.AccountAdminLogoSignificance.Big) {
		return 'Big';
	} else if (accountLogoSignificance === GraphQL.AccountAdminLogoSignificance.Huge) {
		return 'Huge';
	}

	return '';
}

function formatOfferADemo(bookDemoOffering: GraphQL.AccountAdminBookDemoOffering | null) {
	if (bookDemoOffering === GraphQL.AccountAdminBookDemoOffering.Auto) {
		return 'Automatic';
	} else if (bookDemoOffering === GraphQL.AccountAdminBookDemoOffering.Booked) {
		return 'Booked';
	} else if (bookDemoOffering === GraphQL.AccountAdminBookDemoOffering.No) {
		return 'No';
	} else if (bookDemoOffering === GraphQL.AccountAdminBookDemoOffering.Yes) {
		return 'Yes';
	}

	return '';
}



type Props = {
	accountId: CK.AccountId,
};

const SalesSettingsForm: React.FC<Props> = (props) => {
	const {
		accountId,
	} = props;

	const accountIsOutOfBand = useAccountIsOutOfBand(accountId);
	const accountPhase = useAccountPhase(accountId);

	const { data } = useAccountPropertiesQuery(
		useSalesSettingsFormQuery,
		accountId,
	);

	const bookDemoOffering = data?.account?.adminSettings?.bookDemoOffering ?? null;
	const isImportant = data?.account?.adminSettings?.isImportant ?? null;
	const isInteresting = data?.account?.adminSettings?.isInteresting ?? null;
	const isSignupOptimized = data?.account?.isSignupOptimized ?? null;
	const isTrialBarDisplayed = data?.account?.isTrialBarDisplayed ?? null;
	const logoSignificance = data?.account?.adminSettings?.logoSignificance ?? null;
	const pqlScore = data?.account?.adminSettings?.pqlScore ?? null;
	const sqlScore = data?.account?.adminSettings?.sqlScore ?? null;

	const [administerAccountSalesSettings] = useAdministerAccountSalesSettingsMutation();

	const handleSubmit = React.useCallback(
		async (values) => {
			if (accountPhase === null) {
				return;
			}

			if (accountPhase !== GraphQL.AccountPhase.Trial) {
				values.bookDemoOffering = null;
				values.isInteresting = null;
				values.isTrialBarDisplayed = null;
			}

			await administerAccountSalesSettings({
				variables: {
					accountId,
					...values,
				},
			});
		},
		[
			accountId,
			accountPhase,
			administerAccountSalesSettings,
		],
	);

	return (
		<WhenAccountActionAllowed
			accountId={accountId}
			action={GraphQL.ActionWithAccount.ManageInternals}
			showMessage={false}
		>
			{({ isAllowed }) => (
				<EditableFormWrapper
					isAllowed={isAllowed}
					isForAdmins={true}
					title="Sales"
				>
					<DisplayPart>
						<FormRows>
							<FormRow label="SQL score">
								<StaticText>
									{sqlScore}
								</StaticText>
							</FormRow>

							{accountPhase === GraphQL.AccountPhase.Trial && (
								<FormRow label="PQL score">
									<StaticText>
										{pqlScore}
									</StaticText>
								</FormRow>
							)}

							{accountPhase === GraphQL.AccountPhase.Trial && (
								<FormRow label="Interesting account">
									<StaticText focusTarget="isInteresting">
										{isInteresting === null ? '' : isInteresting ? 'yes' : 'no'}
									</StaticText>
								</FormRow>
							)}

							<FormRow label="Logo significance">
								<StaticText focusTarget="logoSignificance">
									{formatAccountLogoSignificance(logoSignificance)}
								</StaticText>
							</FormRow>

							{accountPhase === GraphQL.AccountPhase.Trial && (
								<FormRow label="Show trial bar">
									<StaticText focusTarget="isTrialBarDisplayed">
										{isTrialBarDisplayed === null ? '' : isTrialBarDisplayed ? 'yes' : 'no'}
									</StaticText>
								</FormRow>
							)}

							{accountPhase === GraphQL.AccountPhase.Trial && accountIsOutOfBand === false && (
								<FormRow label="Offer a demo">
									<StaticText focusTarget="bookDemoOffering">
										{formatOfferADemo(bookDemoOffering)}
									</StaticText>
								</FormRow>
							)}

							<FormRow label="Force sync to CRM">
								<StaticText focusTarget="isImportant">
									{isImportant === null ? '' : isImportant ? 'yes' : 'no'}
								</StaticText>
							</FormRow>

							<FormRow label="Optimize account size">
								<StaticText focusTarget="isSignupOptimized">
									{isSignupOptimized === null ? '' : isSignupOptimized ? 'yes' : 'no'}
								</StaticText>
							</FormRow>
						</FormRows>
					</DisplayPart>

					<EditablePart>
						<Form
							defaultValues={{
								bookDemoOffering,
								isImportant,
								isInteresting,
								isSignupOptimized,
								isTrialBarDisplayed,
								logoSignificance,
							}}
							onSuccess={handleSubmit}
						>
							{() => (
								<>
									<FormRows>
										<FormRow label="SQL score">
											<StaticText>
												{sqlScore}
											</StaticText>
										</FormRow>

										{accountPhase === GraphQL.AccountPhase.Trial && (
											<FormRow label="PQL score">
												<StaticText>
													{pqlScore}
												</StaticText>
											</FormRow>
										)}

										{accountPhase === GraphQL.AccountPhase.Trial && (
											<FormRow
												htmlFor="isInteresting"
												label="Interesting account"
											>
												<CheckboxField
													label="yes"
													name="isInteresting"
													width={false}
												/>
											</FormRow>
										)}

										<FormRow
											htmlFor="logoSignificance"
											label="Logo significance"
										>
											<SelectField
												name="logoSignificance"
												options={[
													{
														label: formatAccountLogoSignificance(GraphQL.AccountAdminLogoSignificance.Regular),
														name: 'regular',
													},
													{
														label: formatAccountLogoSignificance(GraphQL.AccountAdminLogoSignificance.Big),
														name: 'big',
													},
													{
														label: formatAccountLogoSignificance(GraphQL.AccountAdminLogoSignificance.Huge),
														name: 'huge',
													},
												]}
											/>
										</FormRow>

										{accountPhase === GraphQL.AccountPhase.Trial && (
											<FormRow
												htmlFor="isTrialBarDisplayed"
												label="Show trial bar"
											>
												<CheckboxField
													label="yes"
													name="isTrialBarDisplayed"
													width={false}
												/>
											</FormRow>
										)}

										{accountPhase === GraphQL.AccountPhase.Trial && accountIsOutOfBand === false && (
											<FormRow
												htmlFor="bookDemoOffering"
												label="Offer a demo"
											>
												<SelectField
													name="bookDemoOffering"
													options={[
														{
															label: formatOfferADemo(GraphQL.AccountAdminBookDemoOffering.Yes),
															name: 'yes',
														},
														{
															label: formatOfferADemo(GraphQL.AccountAdminBookDemoOffering.No),
															name: 'no',
														},
														{
															label: formatOfferADemo(GraphQL.AccountAdminBookDemoOffering.Auto),
															name: 'auto',
														},
														{
															label: formatOfferADemo(GraphQL.AccountAdminBookDemoOffering.Booked),
															name: 'booked',
														},
													]}
												/>
											</FormRow>
										)}

										<FormRow
											htmlFor="isImportant"
											label="Force sync to CRM"
										>
											<CheckboxField
												label="yes"
												name="isImportant"
												width={false}
											/>
										</FormRow>

										<FormRow
											htmlFor="isSignupOptimized"
											label="Optimize account size"
										>
											<CheckboxField
												label="yes"
												name="isSignupOptimized"
												width={false}
											/>
										</FormRow>
									</FormRows>

									<ButtonsLayout>
										<CancelButton />
										<SaveSubmitButton />
									</ButtonsLayout>
								</>
							)}
						</Form>
					</EditablePart>
				</EditableFormWrapper>
			)}
		</WhenAccountActionAllowed>
	);
};



export default SalesSettingsForm;
