/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PlatformCanonicalDomainQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type PlatformCanonicalDomainQuery = { readonly __typename?: 'Query', readonly platformData: { readonly __typename?: 'PlatformData', readonly canonicalDomain: string | null, readonly id: CK.ID } | null };


export const PlatformCanonicalDomainDocument = gql`
    query PlatformCanonicalDomain($websiteId: WebsiteId!) {
  platformData(websiteId: $websiteId) {
    canonicalDomain
    id
  }
}
    `;

/**
 * __usePlatformCanonicalDomainQuery__
 *
 * To run a query within a React component, call `usePlatformCanonicalDomainQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlatformCanonicalDomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlatformCanonicalDomainQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function usePlatformCanonicalDomainQuery(baseOptions: Apollo.QueryHookOptions<PlatformCanonicalDomainQuery, PlatformCanonicalDomainQueryVariables> & ({ variables: PlatformCanonicalDomainQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlatformCanonicalDomainQuery, PlatformCanonicalDomainQueryVariables>(PlatformCanonicalDomainDocument, options);
      }
export function usePlatformCanonicalDomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlatformCanonicalDomainQuery, PlatformCanonicalDomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlatformCanonicalDomainQuery, PlatformCanonicalDomainQueryVariables>(PlatformCanonicalDomainDocument, options);
        }
export function usePlatformCanonicalDomainSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PlatformCanonicalDomainQuery, PlatformCanonicalDomainQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PlatformCanonicalDomainQuery, PlatformCanonicalDomainQueryVariables>(PlatformCanonicalDomainDocument, options);
        }
export type PlatformCanonicalDomainQueryHookResult = ReturnType<typeof usePlatformCanonicalDomainQuery>;
export type PlatformCanonicalDomainLazyQueryHookResult = ReturnType<typeof usePlatformCanonicalDomainLazyQuery>;
export type PlatformCanonicalDomainSuspenseQueryHookResult = ReturnType<typeof usePlatformCanonicalDomainSuspenseQuery>;
export type PlatformCanonicalDomainQueryResult = Apollo.QueryResult<PlatformCanonicalDomainQuery, PlatformCanonicalDomainQueryVariables>;