/* eslint-disable */
import GraphQL from '../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResponseBodySnapshotQueryVariables = GraphQL.Exact<{
  timestamp: GraphQL.Scalars['Timestamp']['input'];
  urlId: GraphQL.Scalars['UrlId']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type ResponseBodySnapshotQuery = { readonly __typename?: 'Query', readonly responseSnapshot: { readonly __typename?: 'ResponseSnapshot', readonly id: CK.ID | null, readonly isResponseBodyTracked: boolean, readonly src: { readonly __typename?: 'ResponseSnapshotCode', readonly isTooLarge: boolean, readonly longestLineCharacters: number | null, readonly rows: ReadonlyArray<string> | null } | null } | null };


export const ResponseBodySnapshotDocument = gql`
    query ResponseBodySnapshot($timestamp: Timestamp!, $urlId: UrlId!, $websiteId: WebsiteId!) {
  responseSnapshot(
    timeReceivedAt: $timestamp
    urlId: $urlId
    websiteId: $websiteId
  ) {
    id
    isResponseBodyTracked
    src {
      isTooLarge
      longestLineCharacters
      rows
    }
  }
}
    `;

/**
 * __useResponseBodySnapshotQuery__
 *
 * To run a query within a React component, call `useResponseBodySnapshotQuery` and pass it any options that fit your needs.
 * When your component renders, `useResponseBodySnapshotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResponseBodySnapshotQuery({
 *   variables: {
 *      timestamp: // value for 'timestamp'
 *      urlId: // value for 'urlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useResponseBodySnapshotQuery(baseOptions: Apollo.QueryHookOptions<ResponseBodySnapshotQuery, ResponseBodySnapshotQueryVariables> & ({ variables: ResponseBodySnapshotQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResponseBodySnapshotQuery, ResponseBodySnapshotQueryVariables>(ResponseBodySnapshotDocument, options);
      }
export function useResponseBodySnapshotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResponseBodySnapshotQuery, ResponseBodySnapshotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResponseBodySnapshotQuery, ResponseBodySnapshotQueryVariables>(ResponseBodySnapshotDocument, options);
        }
export function useResponseBodySnapshotSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ResponseBodySnapshotQuery, ResponseBodySnapshotQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ResponseBodySnapshotQuery, ResponseBodySnapshotQueryVariables>(ResponseBodySnapshotDocument, options);
        }
export type ResponseBodySnapshotQueryHookResult = ReturnType<typeof useResponseBodySnapshotQuery>;
export type ResponseBodySnapshotLazyQueryHookResult = ReturnType<typeof useResponseBodySnapshotLazyQuery>;
export type ResponseBodySnapshotSuspenseQueryHookResult = ReturnType<typeof useResponseBodySnapshotSuspenseQuery>;
export type ResponseBodySnapshotQueryResult = Apollo.QueryResult<ResponseBodySnapshotQuery, ResponseBodySnapshotQueryVariables>;