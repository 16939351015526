import React from 'react';
import {
	useSelector,
} from 'react-redux';

import GraphQL from '~/types/graphql';

import useAffectedLinksFilter from '~/hooks/useAffectedLinksFilter';
import useCountAffectedLinks from '~/hooks/useCountAffectedLinks';
import useCreateExport from '~/hooks/useCreateExport';
import useWebsiteId from '~/hooks/useWebsiteId';

import {
	sortBySelector,
} from '~/state/affectedLinks/selectors';

import {
	issuesOverviewIssueSelector,
} from '~/state/ui/content/selectors';



function useExportAffectedLinks() {
	const issueName = useSelector(issuesOverviewIssueSelector);
	const websiteId = useWebsiteId();

	const affectedLinksCount = useCountAffectedLinks(issueName, websiteId, true);
	const createExport = useCreateExport(websiteId);
	const filter = useAffectedLinksFilter(issueName, true);
	const sortBy = useSelector(sortBySelector);

	return React.useCallback(
		async () => {
			const exportData = await createExport({
				criteria: {
					filter,
					sortBy,
				},
				type: GraphQL.ExportType.AffectedLinks,
			});

			return {
				...exportData,
				isLarge: affectedLinksCount.total >= 200000,
			};
		},
		[
			affectedLinksCount,
			createExport,
			filter,
			sortBy,
		],
	);
}



export default useExportAffectedLinks;
