/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserViaLegacyIdWebsiteAccessPermissionsQueryVariables = GraphQL.Exact<{
  legacyUserId: GraphQL.Scalars['ID']['input'];
}>;


export type UserViaLegacyIdWebsiteAccessPermissionsQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websiteAccess: ReadonlyArray<{ readonly __typename?: 'UserWebsiteAccess', readonly id: CK.ID, readonly managedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly managedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess> | null, readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId } }> }> } | null };

export type UserViaUniqueIdWebsiteAccessPermissionsQueryVariables = GraphQL.Exact<{
  uniqueUserId: GraphQL.Scalars['UserId']['input'];
}>;


export type UserViaUniqueIdWebsiteAccessPermissionsQuery = { readonly __typename?: 'Query', readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly memberships: ReadonlyArray<{ readonly __typename?: 'AccountMembership', readonly account: { readonly __typename?: 'Account', readonly id: CK.AccountId }, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string }, readonly websiteAccess: ReadonlyArray<{ readonly __typename?: 'UserWebsiteAccess', readonly id: CK.ID, readonly managedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly managedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess> | null, readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId } }> }> } | null };

export type WebsiteUserAccessPermissionsQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsiteUserAccessPermissionsQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly userAccess: ReadonlyArray<{ readonly __typename?: 'UserWebsiteAccess', readonly id: CK.ID, readonly managedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly managedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActions: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess>, readonly permittedActionsForKingdomAdmin: ReadonlyArray<GraphQL.ActionWithUserWebsiteAccess> | null, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string } }> } | null };


export const UserViaLegacyIdWebsiteAccessPermissionsDocument = gql`
    query UserViaLegacyIdWebsiteAccessPermissions($legacyUserId: ID!) {
  user(legacyId: $legacyUserId) {
    id
    legacyId
    memberships {
      account {
        id
      }
      user {
        id
        legacyId
      }
      websiteAccess {
        id
        managedActions
        managedActionsForKingdomAdmin
        permittedActions
        permittedActionsForKingdomAdmin
        website {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useUserViaLegacyIdWebsiteAccessPermissionsQuery__
 *
 * To run a query within a React component, call `useUserViaLegacyIdWebsiteAccessPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserViaLegacyIdWebsiteAccessPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserViaLegacyIdWebsiteAccessPermissionsQuery({
 *   variables: {
 *      legacyUserId: // value for 'legacyUserId'
 *   },
 * });
 */
export function useUserViaLegacyIdWebsiteAccessPermissionsQuery(baseOptions: Apollo.QueryHookOptions<UserViaLegacyIdWebsiteAccessPermissionsQuery, UserViaLegacyIdWebsiteAccessPermissionsQueryVariables> & ({ variables: UserViaLegacyIdWebsiteAccessPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserViaLegacyIdWebsiteAccessPermissionsQuery, UserViaLegacyIdWebsiteAccessPermissionsQueryVariables>(UserViaLegacyIdWebsiteAccessPermissionsDocument, options);
      }
export function useUserViaLegacyIdWebsiteAccessPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserViaLegacyIdWebsiteAccessPermissionsQuery, UserViaLegacyIdWebsiteAccessPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserViaLegacyIdWebsiteAccessPermissionsQuery, UserViaLegacyIdWebsiteAccessPermissionsQueryVariables>(UserViaLegacyIdWebsiteAccessPermissionsDocument, options);
        }
export function useUserViaLegacyIdWebsiteAccessPermissionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserViaLegacyIdWebsiteAccessPermissionsQuery, UserViaLegacyIdWebsiteAccessPermissionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserViaLegacyIdWebsiteAccessPermissionsQuery, UserViaLegacyIdWebsiteAccessPermissionsQueryVariables>(UserViaLegacyIdWebsiteAccessPermissionsDocument, options);
        }
export type UserViaLegacyIdWebsiteAccessPermissionsQueryHookResult = ReturnType<typeof useUserViaLegacyIdWebsiteAccessPermissionsQuery>;
export type UserViaLegacyIdWebsiteAccessPermissionsLazyQueryHookResult = ReturnType<typeof useUserViaLegacyIdWebsiteAccessPermissionsLazyQuery>;
export type UserViaLegacyIdWebsiteAccessPermissionsSuspenseQueryHookResult = ReturnType<typeof useUserViaLegacyIdWebsiteAccessPermissionsSuspenseQuery>;
export type UserViaLegacyIdWebsiteAccessPermissionsQueryResult = Apollo.QueryResult<UserViaLegacyIdWebsiteAccessPermissionsQuery, UserViaLegacyIdWebsiteAccessPermissionsQueryVariables>;
export const UserViaUniqueIdWebsiteAccessPermissionsDocument = gql`
    query UserViaUniqueIdWebsiteAccessPermissions($uniqueUserId: UserId!) {
  user(uniqueId: $uniqueUserId) {
    id
    legacyId
    memberships {
      account {
        id
      }
      user {
        id
        legacyId
      }
      websiteAccess {
        id
        managedActions
        managedActionsForKingdomAdmin
        permittedActions
        permittedActionsForKingdomAdmin
        website {
          id
        }
      }
    }
  }
}
    `;

/**
 * __useUserViaUniqueIdWebsiteAccessPermissionsQuery__
 *
 * To run a query within a React component, call `useUserViaUniqueIdWebsiteAccessPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserViaUniqueIdWebsiteAccessPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserViaUniqueIdWebsiteAccessPermissionsQuery({
 *   variables: {
 *      uniqueUserId: // value for 'uniqueUserId'
 *   },
 * });
 */
export function useUserViaUniqueIdWebsiteAccessPermissionsQuery(baseOptions: Apollo.QueryHookOptions<UserViaUniqueIdWebsiteAccessPermissionsQuery, UserViaUniqueIdWebsiteAccessPermissionsQueryVariables> & ({ variables: UserViaUniqueIdWebsiteAccessPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserViaUniqueIdWebsiteAccessPermissionsQuery, UserViaUniqueIdWebsiteAccessPermissionsQueryVariables>(UserViaUniqueIdWebsiteAccessPermissionsDocument, options);
      }
export function useUserViaUniqueIdWebsiteAccessPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserViaUniqueIdWebsiteAccessPermissionsQuery, UserViaUniqueIdWebsiteAccessPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserViaUniqueIdWebsiteAccessPermissionsQuery, UserViaUniqueIdWebsiteAccessPermissionsQueryVariables>(UserViaUniqueIdWebsiteAccessPermissionsDocument, options);
        }
export function useUserViaUniqueIdWebsiteAccessPermissionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserViaUniqueIdWebsiteAccessPermissionsQuery, UserViaUniqueIdWebsiteAccessPermissionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserViaUniqueIdWebsiteAccessPermissionsQuery, UserViaUniqueIdWebsiteAccessPermissionsQueryVariables>(UserViaUniqueIdWebsiteAccessPermissionsDocument, options);
        }
export type UserViaUniqueIdWebsiteAccessPermissionsQueryHookResult = ReturnType<typeof useUserViaUniqueIdWebsiteAccessPermissionsQuery>;
export type UserViaUniqueIdWebsiteAccessPermissionsLazyQueryHookResult = ReturnType<typeof useUserViaUniqueIdWebsiteAccessPermissionsLazyQuery>;
export type UserViaUniqueIdWebsiteAccessPermissionsSuspenseQueryHookResult = ReturnType<typeof useUserViaUniqueIdWebsiteAccessPermissionsSuspenseQuery>;
export type UserViaUniqueIdWebsiteAccessPermissionsQueryResult = Apollo.QueryResult<UserViaUniqueIdWebsiteAccessPermissionsQuery, UserViaUniqueIdWebsiteAccessPermissionsQueryVariables>;
export const WebsiteUserAccessPermissionsDocument = gql`
    query WebsiteUserAccessPermissions($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    userAccess {
      id
      managedActions
      managedActionsForKingdomAdmin
      permittedActions
      permittedActionsForKingdomAdmin
      user {
        id
        legacyId
      }
    }
  }
}
    `;

/**
 * __useWebsiteUserAccessPermissionsQuery__
 *
 * To run a query within a React component, call `useWebsiteUserAccessPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsiteUserAccessPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsiteUserAccessPermissionsQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsiteUserAccessPermissionsQuery(baseOptions: Apollo.QueryHookOptions<WebsiteUserAccessPermissionsQuery, WebsiteUserAccessPermissionsQueryVariables> & ({ variables: WebsiteUserAccessPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsiteUserAccessPermissionsQuery, WebsiteUserAccessPermissionsQueryVariables>(WebsiteUserAccessPermissionsDocument, options);
      }
export function useWebsiteUserAccessPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsiteUserAccessPermissionsQuery, WebsiteUserAccessPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsiteUserAccessPermissionsQuery, WebsiteUserAccessPermissionsQueryVariables>(WebsiteUserAccessPermissionsDocument, options);
        }
export function useWebsiteUserAccessPermissionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsiteUserAccessPermissionsQuery, WebsiteUserAccessPermissionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsiteUserAccessPermissionsQuery, WebsiteUserAccessPermissionsQueryVariables>(WebsiteUserAccessPermissionsDocument, options);
        }
export type WebsiteUserAccessPermissionsQueryHookResult = ReturnType<typeof useWebsiteUserAccessPermissionsQuery>;
export type WebsiteUserAccessPermissionsLazyQueryHookResult = ReturnType<typeof useWebsiteUserAccessPermissionsLazyQuery>;
export type WebsiteUserAccessPermissionsSuspenseQueryHookResult = ReturnType<typeof useWebsiteUserAccessPermissionsSuspenseQuery>;
export type WebsiteUserAccessPermissionsQueryResult = Apollo.QueryResult<WebsiteUserAccessPermissionsQuery, WebsiteUserAccessPermissionsQueryVariables>;