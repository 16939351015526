/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportBlockedIntercomMutationVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type ReportBlockedIntercomMutation = { readonly __typename?: 'Mutation', readonly ReportBlockedIntercom: { readonly __typename?: 'BasicMutationResult', readonly query: { readonly __typename?: 'Query', readonly ping: string } } };


export const ReportBlockedIntercomDocument = gql`
    mutation ReportBlockedIntercom {
  ReportBlockedIntercom {
    query {
      ping
    }
  }
}
    `;
export type ReportBlockedIntercomMutationFn = Apollo.MutationFunction<ReportBlockedIntercomMutation, ReportBlockedIntercomMutationVariables>;

/**
 * __useReportBlockedIntercomMutation__
 *
 * To run a mutation, you first call `useReportBlockedIntercomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportBlockedIntercomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportBlockedIntercomMutation, { data, loading, error }] = useReportBlockedIntercomMutation({
 *   variables: {
 *   },
 * });
 */
export function useReportBlockedIntercomMutation(baseOptions?: Apollo.MutationHookOptions<ReportBlockedIntercomMutation, ReportBlockedIntercomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportBlockedIntercomMutation, ReportBlockedIntercomMutationVariables>(ReportBlockedIntercomDocument, options);
      }
export type ReportBlockedIntercomMutationHookResult = ReturnType<typeof useReportBlockedIntercomMutation>;
export type ReportBlockedIntercomMutationResult = Apollo.MutationResult<ReportBlockedIntercomMutation>;
export type ReportBlockedIntercomMutationOptions = Apollo.BaseMutationOptions<ReportBlockedIntercomMutation, ReportBlockedIntercomMutationVariables>;