/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PageDetailSourceModeQueryVariables = GraphQL.Exact<{ [key: string]: never; }>;


export type PageDetailSourceModeQuery = { readonly __typename?: 'Query', readonly authenticatedSession: { readonly __typename?: 'AuthenticatedSession', readonly isImpersonated: boolean, readonly kingdomAdmin: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly personalTweaks: { readonly __typename?: 'UserPersonalTweaks', readonly pageDetailSourceMode: GraphQL.PageDetailSourceMode } | null } | null, readonly user: { readonly __typename?: 'User', readonly id: string, readonly legacyId: string, readonly personalTweaks: { readonly __typename?: 'UserPersonalTweaks', readonly pageDetailSourceMode: GraphQL.PageDetailSourceMode } | null } } | null };


export const PageDetailSourceModeDocument = gql`
    query PageDetailSourceMode {
  authenticatedSession {
    isImpersonated
    kingdomAdmin {
      id
      legacyId
      personalTweaks {
        pageDetailSourceMode
      }
    }
    user {
      id
      legacyId
      personalTweaks {
        pageDetailSourceMode
      }
    }
  }
}
    `;

/**
 * __usePageDetailSourceModeQuery__
 *
 * To run a query within a React component, call `usePageDetailSourceModeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePageDetailSourceModeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePageDetailSourceModeQuery({
 *   variables: {
 *   },
 * });
 */
export function usePageDetailSourceModeQuery(baseOptions?: Apollo.QueryHookOptions<PageDetailSourceModeQuery, PageDetailSourceModeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PageDetailSourceModeQuery, PageDetailSourceModeQueryVariables>(PageDetailSourceModeDocument, options);
      }
export function usePageDetailSourceModeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageDetailSourceModeQuery, PageDetailSourceModeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PageDetailSourceModeQuery, PageDetailSourceModeQueryVariables>(PageDetailSourceModeDocument, options);
        }
export function usePageDetailSourceModeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PageDetailSourceModeQuery, PageDetailSourceModeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PageDetailSourceModeQuery, PageDetailSourceModeQueryVariables>(PageDetailSourceModeDocument, options);
        }
export type PageDetailSourceModeQueryHookResult = ReturnType<typeof usePageDetailSourceModeQuery>;
export type PageDetailSourceModeLazyQueryHookResult = ReturnType<typeof usePageDetailSourceModeLazyQuery>;
export type PageDetailSourceModeSuspenseQueryHookResult = ReturnType<typeof usePageDetailSourceModeSuspenseQuery>;
export type PageDetailSourceModeQueryResult = Apollo.QueryResult<PageDetailSourceModeQuery, PageDetailSourceModeQueryVariables>;