import classNames from 'classnames';
import React from 'react';



export enum NewTableCellSize {
	Default = 'default',
	Small = 'small',
}

export enum NewTableCellType {
	Body = 'body',
	Header = 'header',
}



type Props = {
	centered?: boolean,
	children?: React.ReactNode,
	highlighted?: boolean,
	size?: NewTableCellSize,
	type?: NewTableCellType,
};

const NewTableCell: React.FC<Props> = (props) => {
	const {
		centered = false,
		children,
		highlighted = false,
		size = NewTableCellSize.Default,
		type = NewTableCellType.Body,
	} = props;

	const cellClasses = classNames({
		'new-table__cell': true,
		'new-table__cell--centered': centered,
		'new-table__cell--highlighted': highlighted,
		[`new-table__cell--${type}`]: true,
		[`new-table__cell--${size}`]: size !== NewTableCellSize.Default,
	});

	return (
		<div className={cellClasses}>
			{children}
		</div>
	);
};



export default NewTableCell;
