/* eslint-disable */
import GraphQL from '../../../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdobeAnalyticsBlockQueryVariables = GraphQL.Exact<{
  legacyUrlId: GraphQL.Scalars['Int']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type AdobeAnalyticsBlockQuery = { readonly __typename?: 'Query', readonly lookupPageByLegacyId: { readonly __typename?: 'Page', readonly id: CK.UrlId, readonly adobeAnalyticsMissingDataReason: GraphQL.PageThirdPartyDataMissingReason | null, readonly adobeAnalyticsData: { readonly __typename?: 'PageAdobeAnalyticsData', readonly averageTimeSpentOnSite: CK.DurationInSeconds | null, readonly bounceRate: CK.Percentage | null, readonly pageViews: number | null, readonly uniqueVisitors: number | null, readonly dateRange: { readonly __typename?: 'DateRange', readonly since: CK.DateYMD, readonly until: CK.DateYMD }, readonly revenue: { readonly __typename?: 'MoneyAmount', readonly currency: string, readonly value: number } | null } | null } | null };


export const AdobeAnalyticsBlockDocument = gql`
    query AdobeAnalyticsBlock($legacyUrlId: Int!, $websiteId: WebsiteId!) {
  lookupPageByLegacyId(legacyUrlId: $legacyUrlId, websiteId: $websiteId) {
    id
    adobeAnalyticsData {
      averageTimeSpentOnSite
      bounceRate
      dateRange {
        since
        until
      }
      pageViews
      revenue {
        currency
        value
      }
      uniqueVisitors
    }
    adobeAnalyticsMissingDataReason
  }
}
    `;

/**
 * __useAdobeAnalyticsBlockQuery__
 *
 * To run a query within a React component, call `useAdobeAnalyticsBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdobeAnalyticsBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdobeAnalyticsBlockQuery({
 *   variables: {
 *      legacyUrlId: // value for 'legacyUrlId'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useAdobeAnalyticsBlockQuery(baseOptions: Apollo.QueryHookOptions<AdobeAnalyticsBlockQuery, AdobeAnalyticsBlockQueryVariables> & ({ variables: AdobeAnalyticsBlockQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdobeAnalyticsBlockQuery, AdobeAnalyticsBlockQueryVariables>(AdobeAnalyticsBlockDocument, options);
      }
export function useAdobeAnalyticsBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdobeAnalyticsBlockQuery, AdobeAnalyticsBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdobeAnalyticsBlockQuery, AdobeAnalyticsBlockQueryVariables>(AdobeAnalyticsBlockDocument, options);
        }
export function useAdobeAnalyticsBlockSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdobeAnalyticsBlockQuery, AdobeAnalyticsBlockQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdobeAnalyticsBlockQuery, AdobeAnalyticsBlockQueryVariables>(AdobeAnalyticsBlockDocument, options);
        }
export type AdobeAnalyticsBlockQueryHookResult = ReturnType<typeof useAdobeAnalyticsBlockQuery>;
export type AdobeAnalyticsBlockLazyQueryHookResult = ReturnType<typeof useAdobeAnalyticsBlockLazyQuery>;
export type AdobeAnalyticsBlockSuspenseQueryHookResult = ReturnType<typeof useAdobeAnalyticsBlockSuspenseQuery>;
export type AdobeAnalyticsBlockQueryResult = Apollo.QueryResult<AdobeAnalyticsBlockQuery, AdobeAnalyticsBlockQueryVariables>;