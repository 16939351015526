/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebsitePagesCountQueryVariables = GraphQL.Exact<{
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type WebsitePagesCountQuery = { readonly __typename?: 'Query', readonly website: { readonly __typename?: 'Website', readonly id: CK.WebsiteId, readonly pagesCount: number } | null };


export const WebsitePagesCountDocument = gql`
    query WebsitePagesCount($websiteId: WebsiteId!) {
  website(id: $websiteId) {
    id
    pagesCount
  }
}
    `;

/**
 * __useWebsitePagesCountQuery__
 *
 * To run a query within a React component, call `useWebsitePagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebsitePagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebsitePagesCountQuery({
 *   variables: {
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useWebsitePagesCountQuery(baseOptions: Apollo.QueryHookOptions<WebsitePagesCountQuery, WebsitePagesCountQueryVariables> & ({ variables: WebsitePagesCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebsitePagesCountQuery, WebsitePagesCountQueryVariables>(WebsitePagesCountDocument, options);
      }
export function useWebsitePagesCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebsitePagesCountQuery, WebsitePagesCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebsitePagesCountQuery, WebsitePagesCountQueryVariables>(WebsitePagesCountDocument, options);
        }
export function useWebsitePagesCountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebsitePagesCountQuery, WebsitePagesCountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebsitePagesCountQuery, WebsitePagesCountQueryVariables>(WebsitePagesCountDocument, options);
        }
export type WebsitePagesCountQueryHookResult = ReturnType<typeof useWebsitePagesCountQuery>;
export type WebsitePagesCountLazyQueryHookResult = ReturnType<typeof useWebsitePagesCountLazyQuery>;
export type WebsitePagesCountSuspenseQueryHookResult = ReturnType<typeof useWebsitePagesCountSuspenseQuery>;
export type WebsitePagesCountQueryResult = Apollo.QueryResult<WebsitePagesCountQuery, WebsitePagesCountQueryVariables>;