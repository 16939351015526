/* eslint-disable */
import GraphQL from '../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FilteredPagesQueryVariables = GraphQL.Exact<{
  after: GraphQL.InputMaybe<GraphQL.Scalars['Base64JsonObject']['input']>;
  ascending: GraphQL.Scalars['Boolean']['input'];
  before: GraphQL.InputMaybe<GraphQL.Scalars['Base64JsonObject']['input']>;
  columns: ReadonlyArray<GraphQL.Scalars['String']['input']> | GraphQL.Scalars['String']['input'];
  filter: GraphQL.Scalars['Array']['input'];
  limit: GraphQL.Scalars['Int']['input'];
  offset: GraphQL.Scalars['Int']['input'];
  sort: GraphQL.Scalars['String']['input'];
  websiteId: GraphQL.Scalars['WebsiteId']['input'];
}>;


export type FilteredPagesQuery = { readonly __typename?: 'Query', readonly filteredPages: { readonly __typename?: 'FilteredPagesConnection', readonly distributions: Record<string, any>, readonly totalCount: number, readonly edges: ReadonlyArray<{ readonly __typename?: 'FilteredPagesConnectionEdge', readonly cursor: any, readonly loadedAt: number, readonly node: { readonly __typename?: 'FilteredPagesConnectionNode', readonly data: Record<string, any>, readonly id: CK.ID, readonly legacyId: number | null } } | null> } | null };


export const FilteredPagesDocument = gql`
    query FilteredPages($after: Base64JsonObject, $ascending: Boolean!, $before: Base64JsonObject, $columns: [String!]!, $filter: Array!, $limit: Int!, $offset: Int!, $sort: String!, $websiteId: WebsiteId!) {
  filteredPages(
    after: $after
    ascending: $ascending
    before: $before
    columns: $columns
    filter: $filter
    limit: $limit
    offset: $offset
    sort: $sort
    websiteId: $websiteId
  ) {
    distributions
    edges {
      cursor
      loadedAt @client
      node {
        data
        id
        legacyId
      }
    }
    totalCount
  }
}
    `;

/**
 * __useFilteredPagesQuery__
 *
 * To run a query within a React component, call `useFilteredPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredPagesQuery({
 *   variables: {
 *      after: // value for 'after'
 *      ascending: // value for 'ascending'
 *      before: // value for 'before'
 *      columns: // value for 'columns'
 *      filter: // value for 'filter'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sort: // value for 'sort'
 *      websiteId: // value for 'websiteId'
 *   },
 * });
 */
export function useFilteredPagesQuery(baseOptions: Apollo.QueryHookOptions<FilteredPagesQuery, FilteredPagesQueryVariables> & ({ variables: FilteredPagesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilteredPagesQuery, FilteredPagesQueryVariables>(FilteredPagesDocument, options);
      }
export function useFilteredPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilteredPagesQuery, FilteredPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilteredPagesQuery, FilteredPagesQueryVariables>(FilteredPagesDocument, options);
        }
export function useFilteredPagesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FilteredPagesQuery, FilteredPagesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FilteredPagesQuery, FilteredPagesQueryVariables>(FilteredPagesDocument, options);
        }
export type FilteredPagesQueryHookResult = ReturnType<typeof useFilteredPagesQuery>;
export type FilteredPagesLazyQueryHookResult = ReturnType<typeof useFilteredPagesLazyQuery>;
export type FilteredPagesSuspenseQueryHookResult = ReturnType<typeof useFilteredPagesSuspenseQuery>;
export type FilteredPagesQueryResult = Apollo.QueryResult<FilteredPagesQuery, FilteredPagesQueryVariables>;