/* eslint-disable */
import GraphQL from '../../types/graphql';

import CK from '~/types/contentking';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AccountIntegrationsFormQueryVariables = GraphQL.Exact<{
  accountId: GraphQL.Scalars['AccountId']['input'];
}>;


export type AccountIntegrationsFormQuery = { readonly __typename?: 'Query', readonly account: { readonly __typename?: 'Account', readonly cmsApiToken: string | null, readonly enrichmentApiToken: string | null, readonly id: CK.AccountId, readonly isEnrichmentApiTermsOfUseAccepted: boolean, readonly isReportingApiTermsOfUseAccepted: boolean, readonly reportingApiToken: string | null } | null };


export const AccountIntegrationsFormDocument = gql`
    query AccountIntegrationsForm($accountId: AccountId!) {
  account(id: $accountId) {
    cmsApiToken
    enrichmentApiToken
    id
    isEnrichmentApiTermsOfUseAccepted
    isReportingApiTermsOfUseAccepted
    reportingApiToken
  }
}
    `;

/**
 * __useAccountIntegrationsFormQuery__
 *
 * To run a query within a React component, call `useAccountIntegrationsFormQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountIntegrationsFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountIntegrationsFormQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useAccountIntegrationsFormQuery(baseOptions: Apollo.QueryHookOptions<AccountIntegrationsFormQuery, AccountIntegrationsFormQueryVariables> & ({ variables: AccountIntegrationsFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AccountIntegrationsFormQuery, AccountIntegrationsFormQueryVariables>(AccountIntegrationsFormDocument, options);
      }
export function useAccountIntegrationsFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AccountIntegrationsFormQuery, AccountIntegrationsFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AccountIntegrationsFormQuery, AccountIntegrationsFormQueryVariables>(AccountIntegrationsFormDocument, options);
        }
export function useAccountIntegrationsFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AccountIntegrationsFormQuery, AccountIntegrationsFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AccountIntegrationsFormQuery, AccountIntegrationsFormQueryVariables>(AccountIntegrationsFormDocument, options);
        }
export type AccountIntegrationsFormQueryHookResult = ReturnType<typeof useAccountIntegrationsFormQuery>;
export type AccountIntegrationsFormLazyQueryHookResult = ReturnType<typeof useAccountIntegrationsFormLazyQuery>;
export type AccountIntegrationsFormSuspenseQueryHookResult = ReturnType<typeof useAccountIntegrationsFormSuspenseQuery>;
export type AccountIntegrationsFormQueryResult = Apollo.QueryResult<AccountIntegrationsFormQuery, AccountIntegrationsFormQueryVariables>;