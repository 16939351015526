import React from 'react';
import {
	produce,
} from 'immer';
import {
	FormattedMessage,
	defineMessages,
} from 'react-intl';
import {
	useDispatch,
} from 'react-redux';

import type CK from '~/types/contentking';
import GraphQL from '~/types/graphql';

import {
	AttributesGroupIconType,
} from '~/components/patterns/icons/AttributesGroupIcon';
import ColumnsCategoriesList from '~/components/patterns/columnsConfigurator/ColumnsCategoriesList';
import ColumnSetSetupColumns from './ColumnSetSetupColumns';

import useCurrentColumnsSetup, {
	type ColumnsSetup,
} from '~/hooks/useCurrentColumnsSetup';
import usePremiumFeatureSituation from '~/hooks/usePremiumFeatureSituation';
import useWebsiteLogFileAnalysisSetup from '~/hooks/useWebsiteLogFileAnalysisSetup';
import useWebsiteIntegrations from '~/hooks/useWebsiteIntegrations';

import {
	setColumns,
} from '~/actions/teams/views';

import {
	PagesColumnsCategory,
	flattenColumnsSetup,
} from '~/model/pagesColumns';

import matchAndReturn from '~/utilities/matchAndReturn';



const messages = defineMessages({
	[PagesColumnsCategory.AdobeAnalytics]: {
		id: 'ui.contentOverview.category.adobeAnalytics',
	},
	[PagesColumnsCategory.Content]: {
		id: 'ui.contentOverview.category.content',
	},
	[PagesColumnsCategory.Conversions]: {
		id: 'ui.contentOverview.category.conversions',
	},
	[PagesColumnsCategory.CustomElements]: {
		id: 'ui.contentOverview.category.customElements',
	},
	[PagesColumnsCategory.EnrichmentFields]: {
		id: 'ui.contentOverview.category.enrichmentFields',
	},
	[PagesColumnsCategory.Fundamentals]: {
		id: 'ui.contentOverview.category.fundamentals',
	},
	[PagesColumnsCategory.GoogleAnalytics]: {
		id: 'ui.contentOverview.category.google_analytics',
	},
	[PagesColumnsCategory.GoogleSearchConsole]: {
		id: 'ui.contentOverview.category.google_search_console',
	},
	[PagesColumnsCategory.Indexability]: {
		id: 'ui.contentOverview.category.indexability',
	},
	[PagesColumnsCategory.Lighthouse]: {
		id: 'ui.contentOverview.category.lighthouse',
	},
	[PagesColumnsCategory.Relations]: {
		id: 'ui.contentOverview.category.relations',
	},
	[PagesColumnsCategory.SchemaOrg]: {
		id: 'ui.contentOverview.category.schemaOrg',
	},
	[PagesColumnsCategory.SearchEngineActivity]: {
		id: 'ui.contentOverview.category.searchEngineActivity',
	},
	[PagesColumnsCategory.Social]: {
		id: 'ui.contentOverview.category.social',
	},
});



const Categories = {
	[PagesColumnsCategory.Fundamentals]: {
		name: PagesColumnsCategory.Fundamentals,
		icon: AttributesGroupIconType.Fundamentals,
		label: <FormattedMessage {...messages[PagesColumnsCategory.Fundamentals]} />,
	},
	[PagesColumnsCategory.AdobeAnalytics]: {
		name: PagesColumnsCategory.AdobeAnalytics,
		icon: AttributesGroupIconType.AdobeAnalytics,
		label: <FormattedMessage {...messages[PagesColumnsCategory.AdobeAnalytics]} />,
	},
	[PagesColumnsCategory.Content]: {
		name: PagesColumnsCategory.Content,
		icon: AttributesGroupIconType.Content,
		label: <FormattedMessage {...messages[PagesColumnsCategory.Content]} />,
	},
	[PagesColumnsCategory.Conversions]: {
		name: PagesColumnsCategory.Conversions,
		icon: AttributesGroupIconType.Conversions,
		label: <FormattedMessage {...messages[PagesColumnsCategory.Conversions]} />,
	},
	[PagesColumnsCategory.CustomElements]: {
		name: PagesColumnsCategory.CustomElements,
		icon: AttributesGroupIconType.CustomElements,
		label: <FormattedMessage {...messages[PagesColumnsCategory.CustomElements]} />,
	},
	[PagesColumnsCategory.EnrichmentFields]: {
		name: PagesColumnsCategory.EnrichmentFields,
		icon: AttributesGroupIconType.EnrichmentFields,
		label: <FormattedMessage {...messages[PagesColumnsCategory.EnrichmentFields]} />,
	},
	[PagesColumnsCategory.GoogleAnalytics]: {
		name: PagesColumnsCategory.GoogleAnalytics,
		icon: AttributesGroupIconType.GoogleAnalytics,
		label: <FormattedMessage {...messages[PagesColumnsCategory.GoogleAnalytics]} />,
	},
	[PagesColumnsCategory.GoogleSearchConsole]: {
		name: PagesColumnsCategory.GoogleSearchConsole,
		icon: AttributesGroupIconType.GoogleSearchConsole,
		label: <FormattedMessage {...messages[PagesColumnsCategory.GoogleSearchConsole]} />,
	},
	[PagesColumnsCategory.Indexability]: {
		name: PagesColumnsCategory.Indexability,
		icon: AttributesGroupIconType.Indexability,
		label: <FormattedMessage {...messages[PagesColumnsCategory.Indexability]} />,
	},
	[PagesColumnsCategory.Lighthouse]: {
		name: PagesColumnsCategory.Lighthouse,
		icon: AttributesGroupIconType.Lighthouse,
		label: <FormattedMessage {...messages[PagesColumnsCategory.Lighthouse]} />,
	},
	[PagesColumnsCategory.Relations]: {
		name: PagesColumnsCategory.Relations,
		icon: AttributesGroupIconType.Relations,
		label: <FormattedMessage {...messages[PagesColumnsCategory.Relations]} />,
	},
	[PagesColumnsCategory.SchemaOrg]: {
		name: PagesColumnsCategory.SchemaOrg,
		icon: AttributesGroupIconType.SchemaOrg,
		label: <FormattedMessage {...messages[PagesColumnsCategory.SchemaOrg]} />,
	},
	[PagesColumnsCategory.SearchEngineActivity]: {
		name: PagesColumnsCategory.SearchEngineActivity,
		icon: AttributesGroupIconType.SearchEngineActivity,
		label: <FormattedMessage {...messages[PagesColumnsCategory.SearchEngineActivity]} />,
	},
	[PagesColumnsCategory.Social]: {
		name: PagesColumnsCategory.Social,
		icon: AttributesGroupIconType.Social,
		label: <FormattedMessage {...messages[PagesColumnsCategory.Social]} />,
	},
} as const;



type Props = {
	websiteId: CK.WebsiteId,
};

const ColumnSetSetup: React.FC<Props> = (props) => {
	const {
		websiteId,
	} = props;

	const dispatch = useDispatch();
	const currentColumnsSetup = useCurrentColumnsSetup();
	const websiteIntegrations = useWebsiteIntegrations(websiteId);

	const isAdobeAnalyticsIntegrationEnabled = websiteIntegrations.isEnabled(GraphQL.WebsiteIntegrationType.AdobeAnalytics);

	const {
		isFeatureAttainable: isLogFileAnalysisFeatureAttainable,
		isFeatureEnabled: isLogFileAnalysisFeatureEnabled,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LogFileAnalysis);

	const {
		isFeatureAttainable: isCustomElementsFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.CustomElements_2);

	const {
		isFeatureAttainable: isEnrichmentFieldsFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.EnrichmentFields_5);

	const {
		isFeatureAttainable: isLighthouseMonitoringFeatureAttainable,
	} = usePremiumFeatureSituation(GraphQL.AccountFeature.LighthouseMonitoring);

	const {
		hasAvailableLogSources,
		isLogFileAnalysisEnabled,
	} = useWebsiteLogFileAnalysisSetup(websiteId);

	const showSearchEngineActivityCategory = (
		isLogFileAnalysisEnabled
		|| (isLogFileAnalysisFeatureAttainable && hasAvailableLogSources)
		|| (isLogFileAnalysisFeatureEnabled && hasAvailableLogSources)
	);

	const handleCategoriesSort = React.useCallback(
		(categories: Array<{ name: PagesColumnsCategory }>) => {
			const nextColumnsSetup = produce(currentColumnsSetup, (draft) => {
				draft.sort((a, b) => {
					const aIndex = categories.findIndex((category) => category.name === a.name);
					const bIndex = categories.findIndex((category) => category.name === b.name);

					return aIndex - bIndex;
				});

				return draft;
			});

			dispatch(
				setColumns(
					flattenColumnsSetup(nextColumnsSetup),
				),
			);
		},
		[
			currentColumnsSetup,
			dispatch,
		],
	);

	const handleCategoryUpdate = React.useCallback(
		(category: ColumnsSetup[number]) => {
			const nextColumnsSetup = produce(currentColumnsSetup, (draft) => {
				const categoryIndex = draft.findIndex((currentCategory) => currentCategory.name === category.name);

				if (draft[categoryIndex]) {
					draft[categoryIndex] = category;
				}
			});

			dispatch(
				setColumns(
					flattenColumnsSetup(nextColumnsSetup),
				),
			);
		},
		[
			currentColumnsSetup,
			dispatch,
		],
	);

	const categories = React.useMemo(
		() => {
			return currentColumnsSetup
				.filter((categorySetup) => {
					if (
						(categorySetup.name === PagesColumnsCategory.AdobeAnalytics && !isAdobeAnalyticsIntegrationEnabled)
						|| (categorySetup.name === PagesColumnsCategory.CustomElements && !isCustomElementsFeatureAttainable)
						|| (categorySetup.name === PagesColumnsCategory.EnrichmentFields && !isEnrichmentFieldsFeatureAttainable)
						|| (categorySetup.name === PagesColumnsCategory.Lighthouse && !isLighthouseMonitoringFeatureAttainable)
						|| (categorySetup.name === PagesColumnsCategory.SearchEngineActivity && !showSearchEngineActivityCategory)
					) {
						return false;
					}

					return true;
				})
				.map((categorySetup) => {
					const category = matchAndReturn(categorySetup.name, Categories);

					return {
						...category,
						children: (
							<ColumnSetSetupColumns
								categorySetup={categorySetup}
								onUpdate={handleCategoryUpdate}
								websiteId={websiteId}
							/>
						),
					};
				});
		},
		[
			currentColumnsSetup,
			handleCategoryUpdate,
			isAdobeAnalyticsIntegrationEnabled,
			isCustomElementsFeatureAttainable,
			isEnrichmentFieldsFeatureAttainable,
			isLighthouseMonitoringFeatureAttainable,
			showSearchEngineActivityCategory,
			websiteId,
		],
	);

	return (
		<ColumnsCategoriesList
			categories={categories}
			onSort={handleCategoriesSort}
			unsortableCategories={[
				PagesColumnsCategory.Fundamentals,
			]}
		/>
	);
};



export default ColumnSetSetup;
