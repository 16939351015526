import React from 'react';

import BasicIcon, {
	BasicIconType,
} from '~/components/patterns/icons/BasicIcon';
import Button, {
	ButtonStyle,
} from '~/components/patterns/buttons/Button';
import FileExportForm from '~/components/atoms/forms/forms/FileExportForm';
import PopupButton, {
	PopupButtonSize,
	PopupButtonStyle,
} from '~/components/patterns/buttons/PopupButton';

import useViewportType from '~/hooks/useViewportType';

import {
	getRouterState,
} from '~/routing/router';
import goTo from '~/routing/goTo';



type Props = {
	createExport: () => Promise<{
		exportDownloadUrl: string,
		exportFilename: string,
		exportId: number,
		isLarge: boolean,
	}>,
	linkLabel: React.ReactNode,
};

const ExportHeaderButton: React.FC<Props> = (props) => {
	const {
		createExport,
		linkLabel,
	} = props;

	const popupButtonRef = React.useRef<PopupButton | null>(null);
	const viewportType = useViewportType();

	const handleClosePopup = React.useCallback(
		() => {
			popupButtonRef.current?._handleClosePopup();
		},
		[
			popupButtonRef,
		],
	);

	const handleOpenMobileExport = React.useCallback(
		(e) => {
			e.preventDefault();
			const routerState = getRouterState();
			goTo(e, `${routerState.name}.export`, routerState.params);
		},
		[],
	);

	if (viewportType.isSmall) {
		return (
			<Button
				icon={(
					<BasicIcon type={BasicIconType.Export} />
				)}
				onClick={handleOpenMobileExport}
				style={ButtonStyle.Hollow}
			/>
		);
	}

	return (
		<PopupButton
			buttonIcon={(
				<BasicIcon type={BasicIconType.Export} />
			)}
			label={linkLabel}
			popupZIndex={1990}
			ref={popupButtonRef}
			size={PopupButtonSize.Small}
			style={PopupButtonStyle.Hollow}
			uppercase={true}
		>
			<FileExportForm
				createExport={createExport}
				currentLocale="en-US"
				onCancelCallback={handleClosePopup}
			/>
		</PopupButton>
	);
};



export default ExportHeaderButton;
